import * as Types from './constants';

export const fetchVoucherDetails = (voucherCode, psCurrency, token) => {
  return {
    type: Types.FETCH_VOUCHER_DETAILS,
    voucherCode,
    psCurrency,
    token
  };
};

export const setAppliedVoucher = (value) => {
  return {
    type: Types.SET_APPLIED_VOUCHER,
    value,
  };
};

export const setApplyingVoucher = (value) => {
  return {
    type: Types.SET_APPLYING_VOUCHER,
    value,
  };
};