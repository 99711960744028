import { ofType } from 'redux-observable';
import { mergeMap, flatMap, catchError } from 'rxjs/operators';
import { push } from 'connected-react-router'

import * as Types from '../actions/constants';
import * as AuthActions from '../actions/auth';
import * as UserActions from '../actions/user';
import * as PaymentActions from '../actions/payment';
import * as ParkingSpotActions from '../actions/parkingSpot';
import { BACKEND_API } from './hosts';
import API from '../../lib/api';
import { removeLoaderForItem } from '../actions/config';
import { enqueueSnackbar } from '../actions/global';

const sanitize = (entry) => {
  const sanitizedEntry = Object.assign({}, entry);
  Object.keys(sanitizedEntry).forEach(
    (key) => (sanitizedEntry[key] === undefined || sanitizedEntry[key] === null || sanitizedEntry[key] === "") && delete sanitizedEntry[key]
  );
  return sanitizedEntry;
};

export const fetchAuthByRefreshToken = action$ =>
  action$.pipe(
    ofType(Types.FETCH_AUTH_BY_REFRESH_TOKEN),
    mergeMap((action) => {
      return API.post('/users/jwt_tokens', {}, { host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            AuthActions.setAuth({
              token: response.headers["access-token"],
              expiry: response.headers["expire-at"],
            }),
            UserActions.setUser({
              id: response.data.id,
              fullName: response.data.full_name,
              name: response.data.first_name,
              surname: response.data.last_name,
              email: response.data.email,
              phone: response.data.phone,
              userType: response.data.type,
              verified: response.data.verified,
              photo: response.data.profile_pic_url,
              completedProfile: response.data.completed_profile,
              status: response.data.status,
              locale: response.data.locale
            }),
            push(action.path ? action.path : "/dashboard"),
            removeLoaderForItem("token"),
          ];
        }),
        catchError(error => {
          console.log(`Could not refresh token: ${error.message}`);
          const actions = [
            AuthActions.setAuth({
              token: null,
              expiry: null,
            }),
            UserActions.setUser({
              id: null,
              fullName: null,
              name: null,
              surname: null,
              email: null,
              userType: null,
              photo: null,
              updatingProfilePic: false,
              hostDetails: {},
              address: {},
              parkingSpots: [],
            }),
            removeLoaderForItem("token"),
          ];

          if (action.layout !== "onboarding" && action.layout !== "public") {
            actions.push(
              push('/search')
            );
          }

          return actions;
        })
      )
    })
  );

export const signUp = action$ =>
  action$.pipe(
    ofType(Types.SIGN_UP),
    mergeMap((action) => {
      const payload = {
        user: {
          first_name: action.payload.name,
          last_name: action.payload.surname,
          email: action.payload.email,
          password: action.payload.password,
          type: action.payload.userType,
          locale: action.payload.locale,
        },
      };

      if (action.payload.id) {
        payload.tag = action.payload.id;
      }
      if (action.payload.referralCode) {
        payload.referral_code = action.payload.referralCode;
      }
      if (action.payload.utmSource) {
        payload.user.utm_source = action.payload.utmSource;
      }

      if (action.payload.userType === "Host" && action.mode === "v2") {
        const detail = {
          company_name: "",
          currency: "CHF",
        };

        const address = sanitize({
          line1: action.payload.companyStreet,
          street_number: action.payload.companyStreetNumber,
          zip: action.payload.companyZip,
          city: action.payload.companyCity,
          country: action.payload.companyCountry,
        });

        const spotParams = sanitize({
            name: action.payload.psName,
            price: action.payload.price,
            capacity: action.payload.capacity,
            behaviour: action.payload.behaviour,
            host_type: action.payload.hostType,
        });
        if (action.payload.phone) {
          payload.user.phone = action.payload.phone;
        }
        if(Object.keys(address).length) {
          payload.user["address_attributes"] = address;
        }
        if(Object.keys(spotParams).length) {
          payload.user["meta_data"] = {
            spot_params: spotParams
          }
        }
        if(Object.keys(detail).length) {
          payload.user["host_detail_attributes"] = detail;
        }
      }

      return API.post('/users/registrations', payload, { host: BACKEND_API }).pipe(
        flatMap((response) => {
          const psFormPath = response.data.parking_spots?.length ? `/ps/${response.data.parking_spots[0]}/edit?product_tour_id=339965` : "/ps/new?product_tour_id=339965"
          const redirectPath =
            response.data.phone &&
            response.data.phone.match(/^[\+]?[0-9]{4,15}$/im) &&
            response.data.address &&
            response.data.address.city &&
            response.data.address.country &&
            response.data.address.line1 &&
            response.data.address.zip &&
            response.data.address.street_number
            ? psFormPath : "/completeProfile";
          return [
            AuthActions.setAuth({
              token: response.headers["access-token"],
              expiry: response.headers["expire-at"],
            }),
            UserActions.setUser({
              id: response.data.id,
              fullName: response.data.full_name,
              name: response.data.first_name,
              surname: response.data.last_name,
              email: response.data.email,
              userType: response.data.type,
              photo: response.data.profile_pic_url,
              phone: response.data.phone,
              completedProfile: response.data.completed_profile,
              completeAddress: response.data.complete_address,
              parkingSpots: response.data.parking_spots,
            }),
            UserActions.setUserType(action.payload.userType),
            push(action.path ? `${redirectPath}${action.path}` : redirectPath),
            UserActions.setRegisteringUser(false),
            ParkingSpotActions.setMobileBottomPanel(!!action.path)
          ];
        }),
        catchError(error => {
          console.log(`Could not sign up: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            UserActions.setRegisteringUser(false),
          ];
        })
      )
    })
  );

export const login = action$ =>
  action$.pipe(
    ofType(Types.LOGIN),
    mergeMap((action) => {
      return API.post('/users/sessions', {
        user: {
          email: action.payload.email,
          password: action.payload.password,
        }
      }, { host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            AuthActions.setAuth({
              token: response.headers["access-token"],
              expiry: response.headers["expire-at"],
            }),
            UserActions.setUser({
              id: response.data.id,
              fullName: response.data.full_name,
              name: response.data.first_name,
              surname: response.data.last_name,
              email: response.data.email,
              userType: response.data.type,
              phone: response.data.phone,
              photo: response.data.profile_pic_url,
              completedProfile: response.data.completed_profile,
              verified: response.data.verified
            }),
            push(action.path ? action.path : "/dashboard"),
            UserActions.setLoggingInUser(false),
            ParkingSpotActions.setMobileBottomPanel(!!action.path)
          ];
        }),
        catchError(error => {
          console.log(`Could not login: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            UserActions.setLoggingInUser(false),
          ];
        })
      )
    })
  );

export const logout = action$ =>
  action$.pipe(
    ofType(Types.LOGOUT),
    mergeMap((action) => {
      return API.delete('users/sessions/sign_out', {}, { token: action.token, host: BACKEND_API }).pipe(
        flatMap(() => {
          return [
            AuthActions.setAuth({
              token: null,
              expiry: null,
            }),
            UserActions.setUser({
              id: null,
              fullName: null,
              name: null,
              surname: null,
              email: null,
              userType: null,
              photo: null,
              updatingProfilePic: false,
              hostDetails: {},
              address: {},
              parkingSpots: [],
            }),
            // ParkingSpotActions.initParkingSpotState(), TODO: Add back once search page is able to fetch location upon being redirected to
            PaymentActions.setPaymentDetails(null),
            UserActions.setLoggingOutUser(false),
            push("/"),
          ];
        }),
        catchError(error => {
          console.log(`Could not logout: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            UserActions.setLoggingOutUser(false),
          ];
        })
      )
    })
  );

export const recoverPassword = action$ =>
  action$.pipe(
    ofType(Types.RECOVER_PASSWORD),
    mergeMap((action) => {
      return API.post('/users/passwords/recovery', {
        user: {
          email: action.email,
        }
      }, { host: BACKEND_API }).pipe(
        flatMap(() => {
          return action.subHost ? [
            AuthActions.setPasswordResetRequestSent(true),
            enqueueSnackbar({
              message: {
                text: action.successText,
                variant: "success",
              },
              options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
              },
            }),
          ]
          :
          [
            AuthActions.setPasswordResetRequestSent(true),
          ];
        }),
        catchError(error => {
          console.log(`Could not request for new password: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const resetPassword = action$ =>
  action$.pipe(
    ofType(Types.RESET_PASSWORD),
    mergeMap((action) => {
      return API.post('/users/passwords/reset', {
        user: {
          reset_password_token: action.payload.passwordResetToken,
          password: action.payload.password,
        }
      }, { host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            enqueueSnackbar({
              message: {
                text: action.successText,
                variant: "success",
              },
              options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
              },
            }),
            push("/login"),
            UserActions.setUpdatingUserPassword(false),
          ];
        }),
        catchError(error => {
          console.log(`Could not reset password: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            UserActions.setUpdatingUserPassword(false),
          ];
        })
      )
    })
  );

export const changePassword = action$ =>
  action$.pipe(
    ofType(Types.CHANGE_PASSWORD),
    mergeMap((action) => {
      return API.post('/users/passwords/change_password', {
        user: {
          current_password: action.payload.currentPassword,
          password: action.payload.newPassword,
        }
      }, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            AuthActions.setAuth({
              token: response.headers["access-token"],
              expiry: response.headers["expire-at"],
            }),
            push("/dashboard"),
            UserActions.setUpdatingUserPassword(false),
          ];
        }),
        catchError(error => {
          console.log(`Could not change password: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            UserActions.setUpdatingUserPassword(false),
          ];
        })
      )
    })
  );
