import { SvgIcon } from "@material-ui/core";
import React from "react";

function HandAndCoins(props) {
  return (
    <SvgIcon {...props} viewBox='0 0 33 32'>
      <path
        d="M12.94 15.3327H15.8333C17.4246 15.3327 18.9508 15.9648 20.076 17.09C21.2012 18.2153 21.8333 19.7414 21.8333 21.3327H12.4987L12.5 22.666H23.1667V21.3327C23.1625 19.914 22.7526 18.5261 21.9853 17.3327H25.8333C27.0937 17.3323 28.3283 17.6893 29.3941 18.3621C30.4598 19.0349 31.313 19.996 31.8547 21.134C28.7013 25.2953 23.596 27.9993 17.8333 27.9993C14.152 27.9993 11.0333 27.2127 8.50001 25.8327V13.4273C10.1222 13.6597 11.654 14.3171 12.94 15.3327V15.3327ZM7.16668 25.3327C7.16668 25.6863 7.0262 26.0254 6.77615 26.2755C6.5261 26.5255 6.18697 26.666 5.83334 26.666H3.16668C2.81305 26.666 2.47392 26.5255 2.22387 26.2755C1.97382 26.0254 1.83334 25.6863 1.83334 25.3327V13.3327C1.83334 12.9791 1.97382 12.6399 2.22387 12.3899C2.47392 12.1398 2.81305 11.9993 3.16668 11.9993H5.83334C6.18697 11.9993 6.5261 12.1398 6.77615 12.3899C7.0262 12.6399 7.16668 12.9791 7.16668 13.3327V25.3327ZM24.5 6.66602C25.5609 6.66602 26.5783 7.08744 27.3284 7.83759C28.0786 8.58773 28.5 9.60515 28.5 10.666C28.5 11.7269 28.0786 12.7443 27.3284 13.4944C26.5783 14.2446 25.5609 14.666 24.5 14.666C23.4391 14.666 22.4217 14.2446 21.6716 13.4944C20.9214 12.7443 20.5 11.7269 20.5 10.666C20.5 9.60515 20.9214 8.58773 21.6716 7.83759C22.4217 7.08744 23.4391 6.66602 24.5 6.66602ZM15.1667 2.66602C16.2275 2.66602 17.245 3.08744 17.9951 3.83759C18.7452 4.58773 19.1667 5.60515 19.1667 6.66602C19.1667 7.72688 18.7452 8.7443 17.9951 9.49444C17.245 10.2446 16.2275 10.666 15.1667 10.666C14.1058 10.666 13.0884 10.2446 12.3382 9.49444C11.5881 8.7443 11.1667 7.72688 11.1667 6.66602C11.1667 5.60515 11.5881 4.58773 12.3382 3.83759C13.0884 3.08744 14.1058 2.66602 15.1667 2.66602V2.66602Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default HandAndCoins;
