import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useForm, Controller } from "react-hook-form";
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FieldWithoutLabel from '../formFieldWithoutLabel';
import CloseIcon from '@material-ui/icons/Close';

import {
  setReviewSubmissionDialogOpen,
  postReview,
} from '../../../data/actions/parkingSpot';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    paddingTop: "1.6rem",
    borderBottom: "1px solid #E7F0F0"
  },
  dialogTitleRoot: {
    '& h2': {
      fontWeight: "500",
      fontSize: theme.typography.h3.fontSize,
    },
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
  },
  radioFormControl: {
    flex: 1,
  },
  textarea: {
    resize: "none",
    height: "10rem",
    [theme.breakpoints.down(370)]: {
      width: "calc(100vw - 8rem)",
      fontSize: "1.0rem"
    },
    [theme.breakpoints.between(370, 'sm')]:{
      width: "66vw",
      fontSize: "1.0rem"
    },
    [theme.breakpoints.up('sm')]: {
      width: "28rem",
      fontSize: "0.9rem"
    },
    maxWidth: "100%",
    border: "1px solid #ccc",
    borderRadius: "0.6rem",
    padding: "0.8rem",
    fontSize: "0.9rem"
  },
  starSelected: {
    color: "#f0d050",
  },
  starUnselected: {
    color: "#999",
  },
  noMargin: {
    margin: 0,
    paddingRight: "0.5rem",
  },
  dialogActions: {
    paddingBottom: "1.8rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },
  reviewTextArea: {
    [theme.breakpoints.down(370)]: {
      width: "calc(100vw - 8rem)",
      fontSize: "1.0rem"
    },
    [theme.breakpoints.between(370, 'sm')]:{
      width: "66vw",
      fontSize: "1.0rem"
    },
    [theme.breakpoints.up('sm')]: {
      width: "28rem",
      fontSize: "0.9rem"
    },
    maxWidth: "100%"
  },
  submitButton:{
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem"
  }
}));

const defaultValues = {
  reviewContent: "",
};

const ReviewDialog = ({
  postReview,
  bookingId,
  token,
  parkingSpotId,
  open,
  onClose,
  onPost
}) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { handleSubmit, control, watch } = useForm({ defaultValues });
  const [ reviewStars, setReviewStars ] = useState(5);

  const handleStars = (stars) => {
    setReviewStars(stars);
  }

  const { t } = useTranslation();

  return (
    <Dialog open={!!open} onClose={onClose} aria-labelledby="review-submission-form-dialog-title">
      <Box px={1}>
        <DialogTitle
          id="review-submission-form-dialog-title"
          className={classes.dialogTitle}
          classes={{
            root: classes.dialogTitleRoot,
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">{t("review-box.title")}</Typography>
            <Button variant="outlined" size="small" onClick={onClose}>
              <CloseIcon flex="auto" color="primary" />
            </Button>
          </Box>
        </DialogTitle>

        <FormControl component="fieldset" className={classes.radioFormControl}>
          <Controller
            as={
              <Box px={3} pt={0} pb={0.5} width="100%">
                <FieldWithoutLabel
                  type="text"
                  name="review"
                  placeholder={ t("review-box.description") }
                  multiline
                  control={control}
                  required
                  className={classes.reviewTextArea}
                  watch={watch}
                />
              </Box>
            }
            name="reviewContent"
            defaultValue=""
            control={control}
          />
        </FormControl>

        <Box display="flex" flexDirection={(isMobile) ? "column" : "row"} alignItems="center" pb={(isMobile) ? 0 : 1}>
          <Box px={3} pb={2.5} pt={isMobile ? 0 : 2.625} display="flex" alignItems="center" alignSelf="flex-start" flex="auto" >
            <DialogContentText className={classes.noMargin}>
              {t("review-box.stars")}:
            </DialogContentText>
            <StarIcon className={(reviewStars < 1) ? (classes.starUnselected) : (classes.starSelected)} onClick={()=>{handleStars(1)}} />
            <StarIcon className={(reviewStars < 2) ? (classes.starUnselected) : (classes.starSelected)} onClick={()=>{handleStars(2)}} />
            <StarIcon className={(reviewStars < 3) ? (classes.starUnselected) : (classes.starSelected)} onClick={()=>{handleStars(3)}} />
            <StarIcon className={(reviewStars < 4) ? (classes.starUnselected) : (classes.starSelected)} onClick={()=>{handleStars(4)}} />
            <StarIcon className={(reviewStars < 5) ? (classes.starUnselected) : (classes.starSelected)} onClick={()=>{handleStars(5)}} />
          </Box>
          <Box alignSelf="flex-end" width={isMobile ? "100%" : "initial"}>
            <DialogActions className={classes.dialogActions}>
              <Box alignSelf="end" width={isMobile ? "100%" : "initial"}>
                <Button variant="contained" fullWidth disabled={!bookingId} onClick={(reviewStars > 0) && handleSubmit((data) => {
                  postReview({
                    description: data.reviewContent,
                    rating: reviewStars,
                    parking_spot_id: parkingSpotId,
                    booking_id: bookingId
                  }, token);
                  setReviewStars(0);
                  if (onPost){
                    onPost();
                  }
                })} color="primary" className={classes.submitButton}>
                  <Typography variant="button">
                    {t("review-box.btn-submit")}
                  </Typography>
                </Button>
              </Box>
            </DialogActions>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps, {
  setReviewSubmissionDialogOpen,
  postReview,
})(ReviewDialog);
