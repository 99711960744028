import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { closeSnackbar } from '../../../data/actions/global';

export default connect(null, { closeSnackbar })(({ closeSnackbar, customKey }) => {
  return (
    <Button onClick={() => closeSnackbar(customKey)}>
      Dismiss
    </Button>
  )
});
