import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";

function ConfirmDialog({
  title,
  description,
  open,
  onClose,
  onConfirm,
  confirmText,
  cancelText,
}) {
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography style={{
        padding: "1.5rem"
      }}>
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>
      <DialogContent style={{
        padding: "0 1.5rem",
        overflowY: "unset",
      }}>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions style={{
        padding: "1.5rem",
        flexDirection: isMobile ? "column" : "row"
      }}>
        <Button variant="outlined" onClick={onClose} fullWidth={isMobile}>
          {cancelText}
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="primary"
          autoFocus
          fullWidth={isMobile}
          style={ isMobile ? {
            marginTop: "1.5rem"
          } : {
            marginLeft: "1.5rem"
          }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
