import * as Types from './constants';

export const fetchCars = (token) => {
  return {
    type: Types.FETCH_CARS,
    token,
  };
};

export const setCars = (payload) => {
  return {
    type: Types.SET_CARS,
    payload,
  };
};

export const putCar = (token, id, car) => {
  return {
    type: Types.PUT_CAR,
    token,
    id,
    car,
  };
};

export const postCar = (token, car) => {
  return {
    type: Types.POST_CAR,
    token,
    car,
  };
};

export const deleteCar = (token, id) => {
  return {
    type: Types.DELETE_CAR,
    token,
    id,
  };
};

export const setCarEditable = (id, editable) => {
  return {
    type: Types.SET_CAR_EDITABLE,
    id,
    editable
  };
};

export const updatePrimaryCar = (token, id) => {
  return {
    type: Types.UPDATE_PRIMARY_CAR,
    token,
    id,
  };
};
