import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Divider, Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useForm, Controller } from "react-hook-form";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import OutlinedFormField from '../../common/outlinedFormField';
import { recoverPassword } from '../../../data/actions/auth';
import { useStyles } from '../common/styles';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { setPasswordResetRequestSent } from '../../../data/actions/auth';

const defaultValues = {
  email: "",
};

const ForgotPassword = ({ recoverPassword, setPasswordResetRequestSent, passwordResetRequestSent }) => {
  const classes = useStyles();
  const { handleSubmit, control } = useForm({ defaultValues });
  const { t } = useTranslation();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onSubmit = (data) => {
    recoverPassword(data.email);
  };

  return (
    <Card className={classes.onboardingCard}>
      <Box px={3} py={3} display="flex" className={classes.onboardingTitle}>
        <Typography variant="h4" color="textPrimary">
          { t('on-boarding.email-to-recover') }
        </Typography>
      </Box>
      <CardContent style={{padding: "0.125rem 1.5rem 1.5rem"}} className={classes.onboardingContent}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          { !passwordResetRequestSent ?
            <OutlinedFormField label={ t('common.labels.email') } name="email" type="email" control={control} required />
            :
            <Box mt={2}>
              <Typography variant="h3" style={{fontWeight: "500"}}>{ t('on-boarding.password-reset-request-sent') }</Typography>
            </Box>
          }
          <Box pt={3} display="flex" justifyContent="space-between" alignItems="center">
            <Button color='secondary' to="/login" component={Link} className={classes.secondaryButton}>
              <Typography variant="body1">{ t('on-boarding.click-to-login') }</Typography>
            </Button>
            <Button variant="contained" disabled={passwordResetRequestSent} color="primary" type="submit" className={classes.actionBox}>
              { t('common.btn.continue') }
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  )
};

const mapStateToProps = (state) => {
  return {
    passwordResetRequestSent: state.auth.passwordResetRequestSent,
  };
};

export default connect(mapStateToProps, { recoverPassword, setPasswordResetRequestSent })(ForgotPassword);
