import initialState from '../store/initialState';
import * as Types from '../actions/constants';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_FINANCIALS:
      return Object.assign({}, state, { paymentData: action.payload });
    case Types.SET_GUEST_PAYMENT_DETAILS:
      return Object.assign({}, state, { guestPaymentDetails: action.payload });
    case Types.SET_WITHDRAWING:
      return Object.assign({}, state, { withdrawing: action.value });
    case Types.SET_STRIPE_STATUS:
      return Object.assign({}, state, { stripeStatus: action.payload });
    case Types.SET_PAYMENT_DETAILS:
      return Object.assign({}, state, { paymentDetails: action.payload });
    case Types.RESET_HOST_PAYMENT_DETAILS:
      return Object.assign({}, state, {
        resetHostPaymentDetails: {
          ...(state.resetHostPaymentDetails || {}),
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: null,
          data: null,
        },
      });
    case Types.RESET_HOST_PAYMENT_DETAILS_FULFILLED:
      return Object.assign({}, state, {
        resetHostPaymentDetails: {
          ...(state.resetHostPaymentDetails || {}),
          isLoading: false,
          isSuccess: !action.error,
          isError: !!action.error,
          error: action.error || null,
          data: action.data || null,
        },
      });
    case Types.RESET_HOST_PAYMENT_DETAILS_INITIAL_DATA:
      return Object.assign({}, state, {
        resetHostPaymentDetails: {
          ...(state.resetHostPaymentDetails || {}),
          isLoading: false,
          isSuccess: false,
          isError: false,
          error: null,
          data: null,
        },
      });
    case Types.SET_FETCHING_FINANCIALS_RECEIPT:
      return Object.assign({}, state, { fetchingFinancialsReceipt: {id: action.id, status: action.payload, printCSV: action.printCSV} });
    case Types.SET_CARD_PAYMENT_FAILED:
      return Object.assign({}, state, { cardPaymentFailed: action.value });
    case Types.SET_PAYMENT_SECRET:
      return Object.assign({}, state, { paymentSecret: action.value });
    case Types.SET_REPAYMENT_FAILED_DIALOG_OPEN:
      return Object.assign({}, state, { repaymentFailedDialogOpen: action.value });
    case Types.SET_FILE:
      return Object.assign({}, state, { files: {
        ...state.files,
        [action.documentType]: action.payload }
      });
    case Types.FETCH_GUEST_PAYMENTS:
      return Object.assign({}, state, { guests: {
        ...state.guests,
        payments: {
          ...state.guests.payments,
          isLoading: true,
          isSuccess: false,
          isError: false,
        }
      }});
    case Types.FETCH_GUEST_PAYMENTS_FULFILLED:
      return Object.assign({}, state, { guests: {
        ...state.guests,
        payments: {
          isLoading: false,
          data: action.data,
          isSuccess: !!action.data,
          isError: !action.data || !!action.error,
        }
      }});
    case Types.FETCH_GUEST_WITHDRAWALS:
      return Object.assign({}, state, { guests: {
        ...state.guests,
        withdrawals: {
          ...state.guests.withdrawals,
          fetch: {
            isLoading: true,
            isSuccess: false,
            isError: false,
          }
        }
      }});
    case Types.FETCH_GUEST_WITHDRAWALS_FULFILLED:
      return Object.assign({}, state, { guests: {
        ...state.guests,
        withdrawals: {
          ...state.guests.withdrawals,
          fetch: {
            isLoading: false,
            data: action.data,
            isSuccess: !!action.data,
            isError: !action.data || !!action.error,
          }
        }
      }});
    case Types.WITHDRAW_GUEST_USER_BALANCE:
      return Object.assign({}, state, { guests: {
        ...state.guests,
        withdrawals: {
          ...state.guests.withdrawals,
          post: {
            isLoading: true,
            isSuccess: false,
            isError: false,
          }
        }
      }});
    case Types.WITHDRAW_GUEST_USER_BALANCE_FULFILLED:
      return Object.assign({}, state, { guests: {
        ...state.guests,
        withdrawals: {
          ...state.guests.withdrawals,
          post: {
            isLoading: false,
            data: action.data,
            isSuccess: !!action.data,
            isError: !action.data || !!action.error,
          }
        }
      }});
    default:
      return state;
  };
};
