import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserPhotoIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
  profilePicture: {
    objectFit: "cover",
    width: "5.375rem",
    height: "5.375rem",
    marginRight: "1.4rem",
    borderRadius: "1.0rem",
    [theme.breakpoints.up('sm')]:{
      width: "4.0rem",
      height: "4.0rem",
      marginRight: "1.0rem",
      borderRadius: "0.75rem",
    }
  },
  placeholder: {
    color: theme.palette.secondary.main,
    background: theme.palette.secondary.light,
    width: "5.375rem",
    height: "5.375rem",
    padding: "0.75rem",
    marginRight: "1.4rem",
    borderRadius: "1.0rem",
    [theme.breakpoints.up('sm')]:{
      width: "4.0rem",
      height: "4.0rem",
      padding: "0.5rem",
      marginRight: "1.0rem",
      borderRadius: "0.75rem",
    }
  },
  profilePictureReviewer: {
    objectFit: "cover",
    width: "4.25rem",
    height: "4.25rem",
    marginRight: "0.9rem",
    borderRadius: "0.875rem",
    [theme.breakpoints.up('sm')]:{
      width: "3.125rem",
      height: "3.125rem",
      borderRadius: "0.75rem",
      marginRight: "0.6rem",
    }
  },
  placeholderReviewer: {
    color: theme.palette.secondary.main,
    background: theme.palette.secondary.light,
    width: "4.25rem",
    height: "4.25rem",
    padding: "0.675rem",
    marginRight: "0.9rem",
    borderRadius: "0.875rem",
    [theme.breakpoints.up('sm')]:{
      width: "3.125rem",
      height: "3.125rem",
      padding: "0.375rem",
      borderRadius: "0.75rem",
      marginRight: "0.6rem",
    }
  },
}));

const ProfilePictureWithPlaceholder = ({ src, type }) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  return (
    <div>
      {!loaded ? (
        <UserPhotoIcon className={(type !== "review") ? classes.placeholder : classes.placeholderReviewer} />
      ) : null}
      <img
        src={src}
        style={!loaded ? { visibility: 'hidden', display: "none" } : {visibility: 'inherit', display: "inherit"}}
        onLoad={() => setLoaded(true)}
        className={(type !== "review") ? classes.profilePicture : classes.profilePictureReviewer}
        alt="profile"
      />
    </div>
  )
};

export default ProfilePictureWithPlaceholder;
