import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { ReactComponent as CamigoLogo } from "../../../assets/images/SVG/camigo_logo_grey_min.svg";

const CamigoPartnerBox = ({ size, camigoLink }) => {
  const { t } = useTranslation();

  const useDesktopStyles = makeStyles((theme) => ({
    box: {
      background: `${theme.palette.primary.mainVeryLight}`,
      margin: "1em -16px -16px",
      padding: theme.spacing(2),
    },
    iconWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      background: "#fff",
      marginLeft: "auto",
      width: "min-content",
      borderRadius: 10,
      padding: 3,
    },
    camigoIcon: {
      width: 80,
      height: "auto",
    },
    link: {
      color: theme.palette.primary.main,
    },
  }));

  const useMobileStyles = makeStyles((theme) => ({
    box: {
      position: "relative",
      border: `2px solid ${theme.palette.secondary.light}`,
      borderRadius: 8,
      padding: theme.spacing(2),
      margin: `${theme.spacing(1)}px 0`,
    },
    iconWrapper: {
      position: "absolute",
      bottom: -18,
      right: 20,
      paddingLeft: 4,
      background: "#fff",
    },
    camigoIcon: {
      width: 100,
      height: "auto",
    },
    link: {
      color: theme.palette.primary.main,
    },
  }));

  const classesDesktop = useDesktopStyles();
  const classesMobile = useMobileStyles();

  const renderMobile = () => (
    <>
      <Box className={classesMobile.box}>
        <Typography variant="body2">
          {t("check-in-box.camigo-partner-integration.body-1")}{" "}
          <a
            href={camigoLink}
            target="_blank"
            rel="noreferrer"
            className={classesMobile.link}
          >
            {t("check-in-box.camigo-partner-integration.link-text-1")}
          </a>
        </Typography>
        <Box className={classesMobile.iconWrapper}>
          <CamigoLogo className={classesMobile.camigoIcon} />
        </Box>
      </Box>
    </>
  );

  const renderDesktop = () => (
    <>
      <Box className={classesDesktop.box}>
        <Typography variant="body2" className={classesDesktop.textContent}>
          {t("check-in-box.camigo-partner-integration.body-1")}{" "}
          <a
            href={camigoLink}
            target="_blank"
            rel="noreferrer"
            className={classesDesktop.link}
          >
            {t("check-in-box.camigo-partner-integration.link-text-1")}
          </a>
        </Typography>
        <Box className={classesDesktop.iconWrapper}>
          <CamigoLogo className={classesDesktop.camigoIcon} />
        </Box>
      </Box>
    </>
  );

  return size === "mobile" ? renderMobile() : renderDesktop();
};

export default CamigoPartnerBox;
