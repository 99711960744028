import { ofType } from 'redux-observable';
import { mergeMap, flatMap, catchError, concatMap } from 'rxjs/operators';
import { push } from 'connected-react-router'

import * as Types from '../actions/constants';
import * as PaymentActions from '../actions/payment';
import * as UserActions from '../actions/user';

import { BACKEND_API } from './hosts';
import API from '../../lib/api';
import { STRIPE_PUBLISHABLE_KEY } from '../../publicKeys';
import { removeLoader, removeLoaderForItem, addLoaderForItem } from '../actions/config';

export const fetchGuestPaymentDetails = action$ =>
  action$.pipe(
    ofType(Types.FETCH_GUEST_PAYMENT_DETAILS),
    mergeMap((action) => {
      return API.get('/guests/users/payment_details', { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            PaymentActions.setGuestPaymentDetails(response.data.payment_details),
            removeLoader()
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch guest payment details: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            removeLoader()
          ];
        })
      )
    })
  );

export const fetchFinancials = action$ =>
  action$.pipe(
    ofType(Types.FETCH_FINANCIALS),
    mergeMap((action) => {
      return API.get(action.requestUrl ? action.requestUrl : '/hosts/withdrawals', { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            PaymentActions.setFinancials(response.data),
            removeLoader(),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch payment data: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            removeLoader(),
          ];
        })
      )
    })
  );

export const fetchGuestWithdrawals = (action$) =>
  action$.pipe(
    ofType(Types.FETCH_GUEST_WITHDRAWALS),
    mergeMap((action) => {
      return API.get("/guests/withdrawals", {
        token: action.token,
        host: BACKEND_API,
      }).pipe(
        concatMap((response) => {
          console.log("fetch guest withdrawals", { response });
          return [
            PaymentActions.fetchGuestWithdrawalsFulfilled(response.data, null),
          ];
        }),
        catchError((error) => {
          console.log(`Could not fetch guest payment data:`, { error });
          return [
            // ...API.notificationsForErrorResponse(error),
            PaymentActions.fetchGuestWithdrawalsFulfilled(null, error),
          ];
        })
      );
    })
  );

export const withdrawGuestUserBalance = (action$) =>
  action$.pipe(
    ofType(Types.WITHDRAW_GUEST_USER_BALANCE),
    mergeMap((action) => {
      return API.post(
        "/guests/withdrawals/withdraw",
        {},
        { token: action.token, host: BACKEND_API }
      ).pipe(
        concatMap((response) => {
          return [
            PaymentActions.withdrawGuestUserBalanceFulfilled(
              response.data,
              null
            ),
            PaymentActions.fetchGuestPayments(action.token),
            PaymentActions.fetchGuestWithdrawals(action.token),
          ];
        }),
        catchError((error) => {
          console.log(`Unable to withdraw balance right now: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            PaymentActions.withdrawGuestUserBalanceFulfilled(null, error),
          ];
        })
      );
    })
  );

export const postWithdrawBalance = action$ =>
  action$.pipe(
    ofType(Types.POST_WITHDRAW_BALANCE),
    mergeMap((action) => {
      return API.post('/hosts/withdrawals/withdraw', {}, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            PaymentActions.setWithdrawing(false),
            PaymentActions.setFinancials(response.data),
          ];
        }),
        catchError(error => {
          console.log(`Unable to withdraw balance right now: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            PaymentActions.setWithdrawing(false),
          ];
        })
      )
    })
  );

export const postPaymentSchedule = action$ =>
  action$.pipe(
    ofType(Types.POST_PAYMENT_SCHEDULE),
    mergeMap((action) => {
      return API.post(`/hosts/withdrawals/update_payout_schedule`, {
        "withdrawal":{
          "payout_schedule": action.payoutSchedule,
        }
      }, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            PaymentActions.setFinancials(response.data)
          ];
        }),
        catchError(error => {
          console.log(`Could not update payout schedule: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const fetchStripeStatus = action$ =>
  action$.pipe(
    ofType(Types.FETCH_STRIPE_STATUS),
    mergeMap((action) => {
      return API.get('/hosts/users/connected_account_status', { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            PaymentActions.setStripeStatus(response.data),
            removeLoaderForItem("stripe-status"),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch stripe connected account status: ${error.message}`);
          return [
            // Note: Disable but reevaluate if UX for payment notifications changes
            // ...API.notificationsForErrorResponse(error),
            removeLoaderForItem("stripe-status"),
          ];
        })
      )
    })
  );

export const fetchSingleFinancialsReceipt = action$ =>
  action$.pipe(
    ofType(Types.FETCH_SINGLE_FINANCIALS_RECEIPT),
    mergeMap((action) => {
      return API.get(`/hosts/withdrawals/${action.id}/download`, { token: action.token, host: BACKEND_API }, true).pipe(
        flatMap((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const filename = `pns-withdrawal-${action.name}.pdf`;
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          return [
            PaymentActions.setFetchingFinancialsReceipt(null, false)
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch financials receipt: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            PaymentActions.setFetchingFinancialsReceipt(null, false)
          ];
        })
      )
    })
  );

export const fetchAllFinancialsReceipt = action$ =>
  action$.pipe(
    ofType(Types.FETCH_ALL_FINANCIALS_RECEIPT),
    mergeMap((action) => {
      return API.post(`/hosts/withdrawals/download_multiple${action.printCSV ? '?csv=true' : ''}`, {}, { token: action.token, host: BACKEND_API }, true).pipe(
        flatMap((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `pns-withdrawals-all.${!!action.printCSV ? 'csv' : 'pdf'}`);
          document.body.appendChild(link);
          link.click();
          return [
            PaymentActions.setFetchingFinancialsReceipt(null, false, false)
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch all financials receipt: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            PaymentActions.setFetchingFinancialsReceipt(null, false, false)
          ];
        })
      )
    })
  );

export const postPaymentConnect = action$ =>
  action$.pipe(
    ofType(Types.POST_PAYMENT_CONNECT),
    mergeMap((action) => {
      const params = {};
      if (!!action.businessType) {
        params.business_type = action.businessType
      }
      return API.patch(`/hosts/payments/connect`, params, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          window.location.replace(response.data.url)
          return [];
        }),
        catchError(error => {
          console.log(`Could not connect to stripe: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const guestPaymentConnect = (action$) =>
  action$.pipe(
    ofType(Types.GUEST_PAYMENT_CONNECT),
    mergeMap((action) => {
      return API.patch(
        `/guests/payments/connect`,
        action.country ? {
          country: action.country
        } : {},
        { token: action.token, host: BACKEND_API }
      ).pipe(
        concatMap((response) => {
          window.location.replace(response.data.url);
          return [];
        }),
        catchError((error) => {
          console.log(`Could not connect to stripe: ${error.message}`);
          return [...API.notificationsForErrorResponse(error)];
        })
      );
    })
  );

export const patchPaymentDetails = action$ =>
  action$.pipe(
    ofType(Types.PATCH_PAYMENT_DETAILS),
    mergeMap((action) => {
      return API.patch(`/hosts/payments`, {
      }, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            PaymentActions.setPaymentDetails(response.data),
            removeLoaderForItem("stripe-status"),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch payment details: ${error.message}`);
          return [
            // Note: Disable but reevaluate if UX for payment notifications changes
            // ...API.notificationsForErrorResponse(error),
            PaymentActions.setPaymentDetails(null),
            removeLoaderForItem("stripe-status"),
          ];
        })
      )
    })
  );

export const resetHostPaymentDetails = (action$) =>
  action$.pipe(
    ofType(Types.RESET_HOST_PAYMENT_DETAILS),
    mergeMap((action) => {
      return API.put(
        `/hosts/payments/reset`,
        {},
        { token: action.token, host: BACKEND_API }
      ).pipe(
        concatMap((response) => {
          return [
            PaymentActions.resetHostPaymentDetailsFulfilled(response.data),
            PaymentActions.patchPaymentDetails(action.token),
          ];
        }),
        catchError((error) => {
          return [
            ...API.notificationsForErrorResponse(error),
            PaymentActions.resetHostPaymentDetailsFulfilled(null, error),
          ];
        })
      );
    })
  );

export const fetchGuestPayments = (action$) =>
  action$.pipe(
    ofType(Types.FETCH_GUEST_PAYMENTS),
    mergeMap((action) => {
      return API.patch(
        `/guests/payments`,
        {},
        { token: action.token, host: BACKEND_API }
      ).pipe(
        concatMap((response) => {
          console.log("fetch guest payments", { response });
          return [PaymentActions.fetchGuestPaymentsFulfilled(response.data)];
        }),
        catchError((error) => {
          console.log(`Could not fetch payment details: ${{ error }}`);
          return [
            // ...API.notificationsForErrorResponse(error),
            PaymentActions.fetchGuestPaymentsFulfilled(null, error),
          ];
        })
      );
    })
  );

export const fetchPaymentSecret = action$ =>
  action$.pipe(
    ofType(Types.FETCH_PAYMENT_SECRET),
    mergeMap((action) => {
      return API.get(`/guests/payments/get_secret`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            PaymentActions.setPaymentSecret(response.data?.secret),
            removeLoaderForItem("stripe-secret"),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch secret: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            removeLoaderForItem("stripe-secret"),
          ];
        })
      )
    })
  );
