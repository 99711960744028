import React, { useEffect } from 'react';
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import './search.css';
import { useStyles } from '../common/styles';
import { setTooltipCardMobile } from '../../../data/actions/parkingSpot';
import { connect } from 'react-redux';
import { ReactComponent as MapPinSVG } from '../../../assets/images/map-pin.svg';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(0, 0, 0, 0)',
    border: 'none',
  },
  tooltipPlacementBottom: {
    margin: "0.125rem 0",
  },
}))(Tooltip);

const MapPinDynamic = ({children, color, spot, setTooltipCardMobile, tooltipCardMobile, isCheckedIn}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let isTablet = useMediaQuery(theme.breakpoints.down(960));

  const stopAllPropagation = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.nativeEvent.stopPropagation();
  }

  const [hovered, setHovered] = React.useState(false);
  const toggleHover = (event) => {
    setHovered(!hovered);
  }

  const [pressed, setPressed] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
    setPressed(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    if (tooltipCardMobile && tooltipCardMobile.spot && spot && spot === tooltipCardMobile.spot) return
    setTooltipCardMobile(spot, isCheckedIn, color);
  };

  useEffect(() => {
    if (isTablet && tooltipCardMobile && tooltipCardMobile.spot && spot && spot.id && spot.id !== tooltipCardMobile.spot.id && open) {
      handleTooltipClose();
    }
  }, [tooltipCardMobile])

  let pin;
  const commonPinClasses = [
    classes.pin,
    {
      [classes.bigPin]: pressed,
    },
  ];
  const commonPinProps = {
    onClick: !isTablet
      ? handleTooltipOpen
      : (event) => {
          handleTooltipOpen();
          stopAllPropagation(event);
        },
    onMouseEnter: !isTablet ? toggleHover : undefined,
    onMouseLeave: !isTablet ? toggleHover : undefined,
    onMouseDown: !isTablet ? () => setPressed(true) : undefined,
    onTouchStart: () => setPressed(true),
  };
  if (color === "green") {
    pin = (
      <MapPinSVG
        className={clsx(
          hovered ? classes.greenHoveredPin : classes.greenPin,
          ...commonPinClasses
        )}
        {...commonPinProps}
      />
    );
  } else if (color === "yellow") {
    pin = (
      <MapPinSVG
        className={clsx(
          hovered ? classes.yellowHoveredPin : classes.yellowPin,
          ...commonPinClasses
        )}
        {...commonPinProps}
      />
    );
  } else if (color === "red") {
    pin = (
      <MapPinSVG
        className={clsx(
          hovered ? classes.redHoveredPin : classes.redPin,
          ...commonPinClasses
        )}
        {...commonPinProps}
      />
    );
  } else {
    pin = (
      <MapPinSVG
        className={clsx(
          hovered ? classes.greyHoveredPin : classes.greyPin,
          ...commonPinClasses
        )}
        {...commonPinProps}
      />
    );
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box>
        <HtmlTooltip
          title={
            <React.Fragment>
              {!isMobile && children}
            </React.Fragment>
          }
          PopperProps={{
            disablePortal: true,
            popperOptions: {
              positionFixed: true,
            }
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          interactive
        >
          <div className={classes.pinContainer}>{ pin }</div>
        </HtmlTooltip>
      </Box>
    </ClickAwayListener>
  )
};

const mapStateToProps = (state) => {
  return {
    tooltipCardMobile: state.parkingSpot.tooltipCardMobile,
  }
};

export default connect(mapStateToProps, {setTooltipCardMobile})(MapPinDynamic);
