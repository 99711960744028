export const getAttributeName = (attribute) => {
  const attributeRaw = attribute.icon.substring(0, attribute.icon.length - 4)
  const attributeParts = attributeRaw.split("_")
  let category = null;
  let box = null;
  if (attributeParts[0] === "host"){
    category = "host-information-category";
    if (attributeParts[1] === "autarchy"){
      box = "autarchy";
    }
    else if (attributeParts[1] === "behaviour"){
      box = "behaviour";
    }
    else if (attributeParts[1] === "type"){
      box = "host";
    }
  }
  else if (attributeParts[0] === "place"){
    category = "place-properties-category";
    if (attributeParts[1] === "approach"){
      box = "approach";
    }
    else if (attributeParts[1] === "carsize"){
      box = "vehicle";
    }
    else if (attributeParts[1] === "season"){
      box = "season";
    }
    else if (attributeParts[1] === "terrain"){
      box = "terrain";
    }
  }
  else if (attributeParts[0] === "service"){
    category = "service-category";
    box = "services";
  }
  else if (attributeParts[0] === "surrounding"){
    category = "surrounding-category";
    box = "surroundings";
  }
  let content = attribute.text.replaceAll(" ", "-").toLowerCase();
  return `spot-attributes.${category}.box.${box}.box-content.${content}`
};
