import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import LinkIcon from "components/common/icons/LinkIcon";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  getReferralHistoryTableCellWidth,
} from "../utils/misc";
import ReferralStatusChip from "./ReferralStatusChip";
import { parsePhoneNumber } from "libphonenumber-js";
import moment from "moment";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    minWidth: ({ cellName }) =>
      `${getReferralHistoryTableCellWidth(cellName)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  tableCellHead: {
    ...theme.typography.h5,
    fontWeight: 500,
  },
  linkIcon: {
    color: theme.palette.text.primary,
  },
}));

function TableCell({ cellName, className, classes, ...props }) {
  const styles = useStyles({ cellName });
  return (
    <MuiTableCell
      className={clsx(styles.tableCell, className)}
      classes={{
        head: styles.tableCellHead,
        ...(classes || {}),
      }}
      {...props}
    />
  );
}

function ReferralsTable({ referrals, isLoading }) {
  const { t } = useTranslation();
  const history = useHistory();

  const classes = useStyles();
  return (
    <TableContainer component={Paper} elevation={0} variant="outlined">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell cellName="name">
              {t("referrals.history.table.name")}
            </TableCell>
            <TableCell cellName="email">
              {t("referrals.history.table.email")}
            </TableCell>
            <TableCell cellName="phoneNumber">
              {t("referrals.history.table.phoneNumber")}
            </TableCell>
            <TableCell cellName="date">
              {t("referrals.history.table.date")}
            </TableCell>
            <TableCell cellName="status">
              {t("referrals.history.table.status")}
            </TableCell>
            <TableCell cellName="link">
              {t("referrals.history.table.link")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading &&
            referrals.map((row) => {
              let phoneNumber = null
              try {
                phoneNumber = parsePhoneNumber(`+${row.phone}`).formatInternational()
              } catch {
                console.log("Phone number could not be parsed")
              }
              return (
                <TableRow key={row.id}>
                  <TableCell cellName="name" component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell cellName="email">{row.email}</TableCell>
                  <TableCell cellName="phoneNumber">
                    {phoneNumber || row.phone}
                  </TableCell>
                  <TableCell cellName="date">
                    {moment.utc(row.created_at).format("yyyy-MM-DD")}
                  </TableCell>
                  <TableCell cellName="status">
                    <Tooltip title={t("referrals.states.tooltip-" + row.status?.replace("_", "-"))}>
                      <Box>
                        <ReferralStatusChip label={row.status} />
                      </Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell cellName="link">
                    <IconButton
                      disabled={!row.first_parking_spot}
                      onClick={() =>
                        history.push(`/ps/${row.first_parking_spot}`)
                      }
                      size="small"
                      className={classes.linkIcon}
                    >
                      <LinkIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={6}>
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ReferralsTable;
