import { withStyles } from "@material-ui/styles";
import { Slider as MuiSlider } from "@material-ui/core";

const PriceSlider = withStyles((theme) => ({
  root: {
    height: "3px",
  },
  rail: {
    height: "inherit",
  },
  track: {
    height: "inherit",
    marginLeft: "-8px",
  },
  thumb: {
    width: "20px",
    height: "20px",
    background: "#F2F9FA",
    border: `2px solid ${theme.palette.primary.main}`,
    marginTop: "-8px",
    marginLeft: "-8px",
    "&:focus, &:hover": {
      boxShadow: "none",
    },
    "&::after": {
      display: "none",
    },
  },
  active: {
    boxShadow: "none",
  },
}))(MuiSlider);

export default PriceSlider;
