import React, { memo, Suspense, useCallback, useRef, useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ReactComponent as MenuIcon } from '../../../../assets/images/menu-icon.svg';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useIntercom } from 'react-use-intercom';

import {ReactComponent as SettingsMenuIcon} from "../../../../assets/images/settings-menu-icon.svg";
import {ReactComponent as SearchMenuIcon} from "../../../../assets/images/search-menu-icon.svg";
import {ReactComponent as SignInMenuIcon} from "../../../../assets/images/signin-menu-icon.svg";
import {ReactComponent as SignupMenuIcon} from "../../../../assets/images/signup-menu-icon.svg";
import {ReactComponent as SignOutMenuIcon} from "../../../../assets/images/sign-out-menu-icon.svg";
import {ReactComponent as IntercomIcon} from "../../../../assets/images/icons/intercom-chat.svg";
import NavButton from "../../buttons/navButton";
import styles from "./styles";
import { logout } from "../../../../data/actions/auth";
import { setLoggingOutUser } from "../../../../data/actions/user";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { ControlledMenu } from "@szhsin/react-menu";

import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { Button, ListItemIcon } from "@material-ui/core";
const useStyles = makeStyles((theme) => styles(theme));

const MenuWithDropdown = ({
  logout,
  token,
  push,
  currentURL,
  setLoggingOutUser,
  handleMenuState,
  menuState,
  toggleMenu,
  menuProps,
  user,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = !useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = useState("closed");
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [intercomData, setIntercomData] = useState(null);
  const { t } = useTranslation();
  const { boot, show, update } = useIntercom();
  const { userType, photo, fullName, email, phone, hostDetails, address } = user;

  const INTERCOM_APP_ID = 'js400j02';
  useEffect(() => {
    // Intercom settings config
    window.intercomSettings = {
      app_id: INTERCOM_APP_ID,
      custom_launcher_selector: '.intercom-launcher',
      hide_default_launcher: true
    };

    if (token) {
      setIntercomData({
        app_id: INTERCOM_APP_ID,
        name: fullName,
        email: email,
        user_type: userType,
        hide_default_launcher: true
      });
    } else {
      setIntercomData({
        app_id: INTERCOM_APP_ID,
      });
    }
  }, [token, userType, fullName, email])
  const bootWithProps = () => boot(intercomData);

  let urlParts = null;
  let queryString = null;
  if (currentURL && currentURL.length > 0) {
    urlParts = currentURL.split("/");
    if (urlParts && urlParts[1] && urlParts[1] === "ps") {
      queryString = `?tag=${urlParts[2]}`;
    }
  }

  const handleClick = useCallback(() => {
    handleMenuState((state) => (state === "closed" ? "open" : "closed"));
    toggleMenu();
  }, []);

  const handleSignout = () => {
    handleClick();
    setLoggingOutUser(true);
    logout(token);
  };

  const ref = useRef(null);

  return (
    <Suspense fallback="loading">
      <Button
        variant="outlined"
        ref={ref}
        disabled={menuState === "open"}
        classes={{
          disabled: classes.outlineButtonPressed
        }}
        aria-label="menu"
        onClick={handleClick}
        classes={{
          startIcon: classes.languageStartIcon,
        }}
        startIcon={menuState === "open" ? <CloseIcon color="primary" /> : <MenuIcon fill={theme.palette.primary.main} />}
      >
        {isDesktop && t("menu")}
      </Button>
      <ControlledMenu
        anchorRef={ref}
        onClose={handleClick}
        {...menuProps}
        align="end"
        menuClassName={classes.menuStyles}
        transition={true}>
        {
          (token != null) ?
          <React.Fragment>
            <MenuItem onClick={() => push("/dashboard")}>
              <ListItemIcon className={classes.menuIcon}>
                <SettingsMenuIcon />
              </ListItemIcon>
              <ListItemText primary={t("common.texts.dashboard")} />
            </MenuItem>
            <Divider />
            {process.env.REACT_APP_STAGE === "stage" && (
              <>
                <MenuItem onClick={() => push("/dashboard?error=true")}>
                  <ListItemIcon className={classes.menuIcon}>
                    <SettingsMenuIcon />
                  </ListItemIcon>
                  <ListItemText primary={"[DEV] Broken dashboard"} />
                </MenuItem>
                <Divider />
              </>
            )}
            <MenuItem onClick={() => push("/search")}>
              <ListItemIcon className={classes.menuIcon}>
                <SearchMenuIcon />
              </ListItemIcon>
              <ListItemText primary={t("menu-items.search-spots")} />
            </MenuItem>
            <Divider />
            <MenuItem onClick={bootWithProps} className="intercom-launcher">
              <ListItemIcon className={classes.menuIcon}>
                <IntercomIcon />
              </ListItemIcon>
              <ListItemText primary={t("menu-items.help")} />
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleSignout}>
              <ListItemIcon className={classes.menuIcon}>
                <SignOutMenuIcon />
              </ListItemIcon>
              <ListItemText primary={t("common.texts.sign-out")} />
            </MenuItem>
          </React.Fragment>
          :
          <React.Fragment>
            <MenuItem
              onClick={() => push(queryString ? `/login${queryString}` : "/login")}>
              <ListItemIcon className={classes.menuIcon}>
                <SignInMenuIcon />
              </ListItemIcon>
              <ListItemText primary={t("common.texts.sign-in")} />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() =>
                push(queryString ? `/signup-camper${queryString}` : "/signup-camper")
              }>
              <ListItemIcon className={classes.menuIcon}>
                <SignupMenuIcon />
              </ListItemIcon>
              <ListItemText primary={t("common.texts.sign-up")} />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => push("/")}>
              <ListItemIcon className={classes.menuIcon}>
                <SearchMenuIcon />
              </ListItemIcon>
              <ListItemText primary={t("menu-items.search-spots")} />
            </MenuItem>
            <Divider />
            <MenuItem onClick={bootWithProps} className="intercom-launcher">
              <ListItemIcon className={classes.menuIcon}>
                <IntercomIcon />
              </ListItemIcon>
              <ListItemText primary={t("menu-items.help")} />
            </MenuItem>
          </React.Fragment>
        }
      </ControlledMenu>
    </Suspense>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps, { logout, push, setLoggingOutUser })(
  memo(MenuWithDropdown)
);
