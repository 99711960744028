import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const useStyles = makeStyles((theme) => ({
  phoneContainer: {
    width: "100%",
    marginTop: "0.8rem",
    "& .special-label": {
      display: "none",
    },
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    display: "inline-block",
  },
  phoneInput: {
    width: "100% !important",
    color: `${theme.palette.text.primary} !important`,
    borderRadius: "0.75rem !important",
    fontSize: "14px !important",
    padding: "0.2rem 3.75rem 0.2rem 0.75rem !important",
    borderColor: `${theme.palette.secondary.light} !important`,
    transition: "none 0s ease 0s !important",
    boxShadow: "none !important",
    height: "2.8rem !important",
    "&:hover": {
      borderColor: `${theme.palette.secondary.dark} !important`,
    },
    "&:focus": {
      borderColor: `transparent !important`,
      outline: `2px solid ${theme.palette.primary.main} !important`,
      outlineOffset: "-1px !important",
    },
  },
  phoneDropdown: {
    width: "100% !important",
    right: 0,
  },
  phoneButton: {
    left: "auto",
    "&.open": {
      left: 0,
    },
    right: "0.0625rem",
    cursor: "text !important",
    "& > .selected-flag": {
      marginLeft: "auto",
      cursor: "pointer !important",
    },
    "&& .arrow": {
      right: "29px !important",
    },
  },
}));

function PhoneInput({ label, required, LabelProps = {}, ...rest }, ref) {
  const classes = useStyles();

  return (
    <div>
      <label>
        { label && (
          <Typography
            variant="h5"
            component="span"
            {...LabelProps}
            className={clsx(classes.label, LabelProps.className)}
          >
            {label} {required ? "*" : ""}
          </Typography>
        ) }
        <ReactPhoneInput
          {...rest}
          ref={ref}
          containerClass={clsx(classes.phoneContainer, rest.containerClass)}
          inputClass={clsx(classes.phoneInput, rest.inputClass)}
          buttonClass={clsx(classes.phoneButton, rest.buttonClass)}
          dropdownClass={clsx(classes.phoneDropdown, rest.dropdownClass)}
        />
      </label>
    </div>
  );
}

export default React.forwardRef(PhoneInput);
