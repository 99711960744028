import initialState from '../store/initialState';
import * as Types from '../actions/constants';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_ACTIVE_LANGUAGE:
      const languageConfig = {
        ...state.config,
        activeLanguage: action.language,
        languages: [
          ...(state.languages.filter(l => l.code === action.language)),
          ...(state.languages.filter(l => l.code !== action.language).sort((i,j) => i.index - j.index))
        ],
      }
      return Object.assign({}, state, { ...languageConfig });
    case Types.SET_APP_VERSION:
      return Object.assign({}, state, { appVersion: action.version });
    case Types.ADD_LOADER:
      return Object.assign({}, state, { loading: true });
    case Types.REMOVE_LOADER:
      return Object.assign({}, state, { loading: false });
    case Types.REMOVE_LOADER_FOR_PHOTOS:
      return Object.assign({}, state, { loadingPhotos: false });
    case Types.ADD_LOADER_FOR_ITEM:
      const addLoaderConfig = {
        ...state.config,
        waitingForToken: state.waitingForToken + 1
      }
      return Object.assign({}, state, { ...addLoaderConfig });
    case Types.ADD_LOADER_FOR_PHOTOS:
      return Object.assign({}, state, { loadingPhotos: true });
    case Types.REMOVE_LOADER_FOR_ITEM:
      const removeLoaderConfig = {
        ...state.config,
        waitingForToken: Math.max(0, state.waitingForToken - 1),
      }
      return Object.assign({}, state, { ...removeLoaderConfig });
    default:
      return state;
  };
};

export default reducer;
