import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Divider, Container, Typography, Box, Button } from '@material-ui/core';
import { Link } from "react-router-dom";

import { useStyles } from '../common/styles';
import TitleSection from '../../common/titleSection';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = ({}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://consent.cookiebot.com/a0b77cfb-7dd6-46d8-af9f-fc6d69d2a4ee/cd.js";
    script.async = true;
    script.id = "CookieDeclaration";
    script.type = "text/javascript";
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <Container maxWidth="md">
      <TitleSection
        routes={[{
          name: t("common.texts.dashboard"),
          path: "/dashboard",
        }]}
        title={ t("cookies.title") }
      />
      <Divider />
      <Box pt={5}>
        <script id="CookieDeclaration" src="https://consent.cookiebot.com/a0b77cfb-7dd6-46d8-af9f-fc6d69d2a4ee/cd.js" type="text/javascript" async></script>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
