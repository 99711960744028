import initialState from '../store/initialState';
import * as Types from '../actions/constants';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_APPLYING_VOUCHER:
      return Object.assign({}, state, { applyingVoucher: action.value });
    case Types.SET_APPLIED_VOUCHER:
      return Object.assign({}, state, { appliedVoucher: action.value });
    default:
      return state;
  };
};

export default reducer;
