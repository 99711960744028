import { InputAdornment, makeStyles, OutlinedInput } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "16px",
    border: "1px solid #E7F0F0",
    borderRadius: "12px",
    minWidth: "95px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    color: "#8E8E99",
  },
  input: {
    width: "100px",
    height: "52px",
    color: "#8E8E99",
    "& .MuiInputAdornment-root p": {
      color: "inherit",
    },
    "& .MuiInputBase-input": {
      marginRight: "-50px",
      lineHeight: "1em",
      height: "1em",
      padding: "0em 0em 0em 16px",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      marginBottom: "1rem",
      "& .MuiInputBase-input": {
        paddingTop: 0,
        marginBottom: "-26px",
      },
      "& .MuiOutlinedInput-adornedEnd": {
        paddingBottom: 0,
      },
    }
  },
}));

function PriceInput({ value, onChange, currencyCode = "CHF" }) {
  const classes = useStyles();
  return (
    <OutlinedInput
      type="number"
      value={value}
      onChange={onChange}
      className={classes.input}
      inputProps={{
        step: 5,
      }}
      endAdornment={
        <InputAdornment position="end" variant="outlined">
          {currencyCode}
        </InputAdornment>
      }
    />
  );
}

export default PriceInput;
