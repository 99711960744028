/* Disabled this eslint rule here because it reported false positives in one case. 
Can probably be resolved by getting more recently updated version of Eslint / the rule */
/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Button, Card, CircularProgress, Container, Divider, ListItemText, Menu, MenuItem, Step, StepConnector, StepLabel, Stepper, Tab, Tabs, TextField, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkIcon from '@material-ui/icons/Link';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import SwipeButton from 'react-swipezor';
import { useIntercom } from 'react-use-intercom';

import backgroundMobile from "../../../assets/images/camping-cta-mobile.png";
import background from "../../../assets/images/camping-cta.png";
import { ReactComponent as ChatIcon } from "../../../assets/images/chat-icon.svg";
import { ReactComponent as DialogIcon } from "../../../assets/images/dialog-icon.svg";
import { ReactComponent as HelpTooltipIcon } from "../../../assets/images/help-tooltip-icon.svg";
import { ReactComponent as CustomStepIcon } from "../../../assets/images/icons/custom-step-icon.svg";
import { ReactComponent as IconMobileStep1 } from "../../../assets/images/icons/step1-mobile.svg";
import { ReactComponent as IconStep1 } from "../../../assets/images/icons/step1.svg";
import { ReactComponent as IconMobileStep2 } from "../../../assets/images/icons/step2-mobile.svg";
import { ReactComponent as IconStep2 } from "../../../assets/images/icons/step2.svg";
import { ReactComponent as IconMobileStep3 } from "../../../assets/images/icons/step3-mobile.svg";
import { ReactComponent as IconStep3 } from "../../../assets/images/icons/step3.svg";
import { ReactComponent as IconMobileStep4 } from "../../../assets/images/icons/step4-mobile.svg";
import { ReactComponent as IconStep4 } from "../../../assets/images/icons/step4.svg";
import { ReactComponent as ShowAllIcon } from "../../../assets/images/leading-icon.svg";
import { ReactComponent as LocationPin } from "../../../assets/images/location-pin.svg";
import PaymentFailedIllustration from '../../../assets/images/payment_failed_illustration.svg';
import { ReactComponent as SliderCaretIcon } from '../../../assets/images/place_carsize_big.svg';
import roadIcon from '../../../assets/images/road-variant.svg';
import { ReactComponent as ShareIcon } from "../../../assets/images/share-icon.svg";
import { ReactComponent as StarIcon } from "../../../assets/images/star-icon.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/images/verified-icon.svg";
import { getSVGForIcon } from '../../../helpers/assets';
import { getAttributeName } from '../../../helpers/attributeMap';
import { getAttributes } from '../../../helpers/mainAttributes';
import { formatCountry, generateCacheBusterKey } from '../../../lib/utils';
import Carousel from '../../common/carousel';
import ProfilePictureWithPlaceholder from "../../common/profilePictureWithPlaceholder";
import "./swipeButton.css";

import {
  fetchCarsForGuests,
  fetchCurrentCheckIns,
  fetchParkingSpotDetails,
  fetchParkingSpotDetailsGuests,
  fetchPhotosByIdForGuests,
  fetchReviewForBooking,
  fetchReviewsByIdForGuests,
  fetchReviewsByIdForGuestsNextPage,
  postCheckIn,
  postCheckOut,
  postReview,
  resetReviews,
  setCarouselState,
  setCheckInDetailsDialogOpen,
  setCheckInDialogOpen,
  setCheckOutDialogOpen,
  setCheckingIn,
  setCheckingOut,
  setEnableReview,
  setMobileBottomPanel,
  setReviewDialogOpen,
  setReviewSubmissionDialogOpen
} from '../../../data/actions/parkingSpot';

import { setCardPaymentFailed } from '../../../data/actions/payment';

import { addLoader, addLoaderForItem, addLoaderForPhotos } from '../../../data/actions/config';
import { fetchVoucherDetails, setAppliedVoucher, setApplyingVoucher } from '../../../data/actions/voucher';
import Gallery from '../../common/gallery';
import ReviewDialog from '../../common/reviewDialog';

import * as Icons from '../../../assets/images/SVG';

import i18next from 'i18next';
import GygLogo from '../../../assets/images/gyg_logo.png';
import clickSoundFile from '../../../assets/sounds/drop.mp3';
import MissingDataPlaceholder from '../../common/missingDataPlaceholder';
import DetailsMap from './DetailsMap';
import CamigoPartnerBox from './camigoPartnerBox';

let wheelGradientRotation = 45;
let prevAngle = null;
let refAngle = null;
let firstTouch = true;

let voucherCode = null

const distanceBetween = (posA, posB) => {
  return Math.sqrt(Math.pow(posB[0] - posA[0], 2) + Math.pow(posB[1] - posA[1], 2))
}

const angleBetweenPointsTan2 = (det, dot) => {
  let angle = Math.atan2(det, dot) * 180 / Math.PI

  if (angle > 0) {
    return angle
  }
  return 360 + angle
 }

const dotProduct = (posA, posB) => {
  return (posA[0] * posB[0]) + (posA[1] * posB[1])
}

const crossProduct = (posA, posB) => {
  return (posA[0] * posB[1]) - (posA[1] * posB[0])
}

const clickSound = new Audio(clickSoundFile)

const StyledMenu = withStyles({
})((props) => (
  <Menu
    MenuListProps={{ disablePadding: true }}
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  customScrollBar: {
    "&::-webkit-scrollbar": {
      width: "2px",
      height: "2px",
    },

    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 1px theme.palette.primary.main",
      borderRadius: "1px",
    },
 
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      borderRadius: "1px",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.primary.main,
    }
  },
  stepConnectorAlternativeLabel: {
    left: "-50%",
    right: "50%",
  },
  stepConnectorLine: {
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      minHeight: "78px",
    }
  },
  stepConnectorVertical: {
    padding: "0rem"
  },
  tabTitle: {
    [theme.breakpoints.up("md")]: {
      lineHeight: "3rem",
      fontWeight: "normal",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      lineHeight: "2rem",
      fontWeight: "500",
    },
  },
  stepper: {
    padding: "0rem",
    [theme.breakpoints.up("md")]: {
      marginTop: "calc(3.4rem + 40px)",
      marginLeft: "-14.5%",
      marginRight: "-14.5%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "3.4rem",
    }
  },
  tabBody: {
    fontSize: "1rem",
    lineHeight: "2rem",
    fontWeight: "400 !important",
  },
  stepLabel: {
    position: "relative",
    fontSize: "1rem",
    lineHeight: "2rem",
    fontWeight: "400 !important",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: "-12px",
    },
  },
  stepLabelRoot: {
    position: "relative",
  },
  stepLabelFirst: {
    position: "relative",
    fontSize: "1rem",
    lineHeight: "2rem",
    fontWeight: "400 !important",
    [theme.breakpoints.up("md")]: {
      marginLeft: "calc(50% - 12px)",
      textAlign: "left !important",
    },
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: "0rem",
    },
  },
  stepLabelLast: {
    position: "relative",
    fontSize: "1rem",
    lineHeight: "2rem",
    fontWeight: "400 !important",
    [theme.breakpoints.up("md")]: {
      marginRight: "calc(50% - 12px)",
      textAlign: "right !important",
    },
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      bottom: "0rem",
    },
  },
  stepIcon: {
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      top: "-6rem",
      left: "calc(50% - 21px)",
    },
    [theme.breakpoints.down("sm")]: {
      top: "12px",
      left: "-5rem",
    }
  },
  stepIconFirst: {
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      top: "-6rem",
      left: "0rem",
    },
    [theme.breakpoints.down("sm")]: {
      top: "0px",
      left: "-5rem",
    }
  },
  stepIconLast: {
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      top: "-6rem",
      right: "0rem",
    },
    [theme.breakpoints.down("sm")]: {
      top: "0px",
      left: "-5rem",
    },
  },
  tooltipPopper: {
    zIndex: 9999,
  },
  reviewCaption: {
    marginLeft: "0.5em",
    marginTop: "0.1em",
  },
  caption2: {
    fontSize: "12px",
    fontWeight: "400 !important",
    lineHeight: "16px",
    letterSpacing: "0em",
  },
  socialMenuItem: {
    textDecoration: "none",
  },
  socialLinkIcon: {
    fontSize: "1rem",
    marginRight: "0.5rem"
  },
  textFieldWrapper: {
    marginTop: "1.5em",
    marginRight: "1em",
    width: "100%",
    "&:last-child": {
      marginRight: "0em",
    },
  },
  itemsWrapper: {
    maxWidth: "500px",
    width: "100%",
  },
  borderBtm: {
    borderBottom: "1px solid #E7F0F0",
  },
  borderBtmReview: {
    "&:not(:last-child)": {
      borderBottom: "1px solid #E7F0F0",
    },
  },
  attributesBox: {
    borderTop: "0.0625rem solid #E7F0F0",
    paddingTop: "1.5rem",
    [theme.breakpoints.down("md")]: {
      border: 0
    }
  },
  buttonXS: {
    minWidth: "32px",
    width: "2.5rem",
    height: "2.5rem",
    padding: 0,
    margin: "0.1875rem",
    [theme.breakpoints.up("sm")]: {
      minWidth: "32px",
      width: "3.0rem",
      height: "3.0rem",
      padding: 0,
      margin: "0.2rem",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "32px",
      width: "3.0rem",
      height: "3.0rem",
      padding: 0,
      margin: "0 0 0 0.5rem",
    }
  },
  boxIcons: {
    width: "1.3rem",
    height: "1.3rem",
    fill: theme.palette.primary.main,
  },
  cardIcons: {
    marginRight: "0px !important",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.0rem",
    }
  },
  helpIconMobile: {
    marginRight: "0px !important",
    fontSize: "1.125rem",
    marginTop: "0.25rem"
  },
  heading: {
    fontSize: "1.675rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.4rem",
    }
  },
  occupiedSpacesBar:{
    display: "inline-block",
    height: "0.3rem",
    backgroundColor: "#f7d156",
    borderTopRightRadius: "0.2rem",
    borderBottomRightRadius: "0.2rem",
  },
  occupiedSpacesBarFull:{
    display: "inline-block",
    height: "0.3rem",
    backgroundColor: "#f54646",
    borderRadius: "0.2rem"
  },
  unoccupiedSpacesBarUnavailable:{
    display: "inline-block",
    height: "0.3rem",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "0.2rem"
  },
  unoccupiedSpacesBar:{
    display: "inline-block",
    height: "0.3rem",
    backgroundColor: "#6bcc69",
    width:"40%",
    borderTopLeftRadius: "0.2rem",
    borderBottomLeftRadius: "0.2rem",
  },
  unoccupiedSpacesBarEmpty:{
    display: "inline-block",
    height: "0.3rem",
    backgroundColor: "#6bcc69",
    width:"40%",
    borderRadius: "0.2rem",
  },
  cardContent: {
    fontSize: "1.1rem",
    lineHeight: "1.6",
  },
  freeSpacePill: {
    display: "inline-block",
    border: "0.16rem solid #3a8a8f",
    borderRadius: "1.2rem",
    padding: "0.1rem 0.8rem",
    fontSize: "1.0rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "0.75rem",
      border: "0.1rem solid #3a8a8f",
      borderColor: theme.palette.primary.main,
      borderRadius: "0.875rem",
      padding: "0rem 0.625rem",
    }
  },
  placeholder: {
    fontSize: "1.1rem",
    color: "#bbb",
    paddingBottom: "1rem",
  },
  bottomCallToAction: {
    padding: "7rem 5.7rem",
    backgroundColor: "#151a3c",
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `linear-gradient(0deg, rgb(26 26 26 / 30%), rgb(0 0 0 / 30%)), url(${background})`,
    [theme.breakpoints.down("sm")]: {
      padding: "2rem",
      paddingBottom: "10rem",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `linear-gradient(0deg, rgb(26 26 26 / 10%), rgb(0 0 0 / 10%)), url(${backgroundMobile})`,
    }
  },
  bottomCallToActionText: {
    color: "#fff",
  },
  backdrop: {
    position: "fixed",
    width: "100%",
    height: "100vh",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.66)",
    display: "initial",
    transition: "background-color 150ms linear",
    zIndex: 9998,
  },
  mobileBottomPanelTitle: {
    fontSize: "5.25vw",
    [theme.breakpoints.up("mobile")]:{
      fontSize: "1.375rem",
    }
  },
  mobileBottomPanelAvailability: {
    fontSize: "3.625vw",
    [theme.breakpoints.up("mobile")]:{
      fontSize: "0.9rem",
    }
  },
  profilePicture: {
    width: "5.375rem",
    height: "5.375rem",
    marginRight: "1.4rem",
    borderRadius: "1.0rem",
    [theme.breakpoints.up("md")]:{
      width: "4.0rem",
      height: "4.0rem",
      marginRight: "1.0rem",
      borderRadius: "0.75rem",
    }
  },
  helpTooltipIcon:{
    fontSize: "1.625rem",
    marginLeft: "0.5rem",
    marginRight: "0.25rem",
    [theme.breakpoints.up("md")]:{
      fontSize: "1.0rem",
      marginLeft: "0.625rem",
      marginRight: "0.375rem",
    }
  },
  checkCircleIcon:{
    fontSize: "1.5rem",
    marginRight: "0.3rem",
    [theme.breakpoints.up("md")]:{
      fontSize: "1.0rem",
      marginRight: "0.25rem",  
    }
  },
  checkCircleIconBox:{
    padding: "0px",
    margin: "0px",
    lineHeight: "1.0",
  },
  gridIconText: {
    paddingBottom: "0.5rem",
    fontWeight: "500",
    textAlign: "center",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
  },
  profilePictureReviewer: {
    width: "4.25rem",
    height: "4.25rem",
    marginRight: "0.9rem",
    borderRadius: "0.875rem",
    [theme.breakpoints.up("md")]:{
      width: "3.125rem",
      height: "3.125rem",
      borderRadius: "0.75rem",
      marginRight: "0.6rem",
    }
  },
  imageIcon: {
    height: '100%'
  },
  iconRoot: {
    textAlign: 'center'
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      width: "62%",
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    }
  },
  column: {
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    }
  },
  reviewGridContainer:{
    [theme.breakpoints.up("md")]: {
      padding: "1.375rem 0 1.75rem",
    },
  },
  contactDesktop: {
    paddingTop: "0.5rem",
    margin: "0",
    paddingRight: "0.375rem",
  },
  radioFormControl: {
    flex: 1,
  },
  radio: {
    padding: "4px 4px 4px 9px !important",
  },
  dialogContentReview: {
    paddingTop: "0.75rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
  },
  dialogContent: {
    paddingTop: "0.75rem",
    paddingBottom: "0.5rem",
  },
  dialogTitle: {
    paddingTop: "1.6rem",
    borderBottom: "1px solid #E7F0F0"
  },

  dialogDaysSelectorContainer: {
    paddingTop: "1.6rem",
  },

  dialogDaysSelector: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    background: 'rgb(0, 115, 122)',
    borderRadius: '2em',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '1.25em',
    width: 'fit-content',
  },

  dialogDaysSelectorBtnActive: {
    margin: '0.5em',
    background: '#fff',
    height: '3em',
    width: '3em',
    borderRadius: '3em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  dialogDaysSelectorBtnInactive: {
    margin: '0.5em',
    height: '3em',
    width: '3em',
    borderRadius: '3em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    cursor: 'pointer',
  },

  borderBottom: {
    borderBottom: "1px solid #E7F0F0"
  },
  
  dialogTitleBorderBottom: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    borderBottom: "1px solid #E7F0F0",
  },
  dialogActions: {
    paddingBottom: "1.5rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },
  checkout: {
    color: "white",
  },
  textarea: {
    resize: "none",
    height: "10rem",
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      width: "62vw",
    },
    [theme.breakpoints.between(theme.breakpoints.values.mobile, 'sm')]:{
      width: "66vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "28rem",
    },
    maxWidth: "100%",
    border: "1px solid #ccc",
    borderRadius: "0.6rem",
    padding: "0.8rem",
    fontSize: "1.2rem"
  },
  starSelected: {
    color: "#f0d050",
  },
  starUnselected: {
    color: "#999",
  },
  noMargin: {
    margin: 0,
    paddingRight: "0.5rem",
  },
  semiBold: {
    fontWeight: "500 !important",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.0rem",
    }
  },
  dialogNoForm: {
    [theme.breakpoints.up("md")]: {
      width: "24rem",
    }
  },
  dialogTitleRoot: {
    '& h2': {
      fontWeight: "500",
      fontSize: theme.typography.h3.fontSize,
    },
    margin: "0.5rem 1.5rem",
    padding: "1rem 0 !important"
  },
  svgIcon: {
    display: 'flex',
    height: 'inherit',
    width: '1.75rem',
    fontSize: "1rem",
    textAlign: "center",
    "&& path:not(cls-2)": {
      fill: "#ccc",
      stroke: "none",
      strokeMiterlimit: "0",
      strokeWidth: "0"
    },
    overflow: "visible",
    "& .cls-4": {
      fill: "#fff !important",
      stroke: `#333 !important`,
      strokeMiterlimit: "0",
      strokeWidth: "4 !important"
    },
    [theme.breakpoints.down("md")]:{
      fontSize: "3.0rem",
    }
  },
  svgOutlinedIcon: {
    display: 'flex',
    height: 'inherit',
    width: '2.5rem',
    fontSize: "1rem",
    textAlign: "center",
    "&& path:not(cls-2)": {
      fill: "none",
      stroke: "#ccc",
      strokeMiterlimit: "0",
      strokeWidth: "0.2rem"
    },
    overflow: "visible",
    "& .cls-4": {
      fill: "#fff !important",
      stroke: `#333 !important`,
      strokeMiterlimit: "0",
      strokeWidth: "4 !important"
    },
    [theme.breakpoints.down("md")]:{
      fontSize: "3.0rem",
    }
  },
  svgIconEnabled: {
    display: 'flex',
    height: 'inherit',
    width: '1.75rem',
    fontSize: "1rem",
    textAlign: "center",
    "& path:not(cls-2)": {
      fill: theme.palette.secondary.dark,
      stroke: "none",
      strokeMiterlimit: "0",
      strokeWidth: "0"
    },
    overflow: "visible",
    "& .cls-4": {
      fill: "#fff !important",
      stroke: `#333 !important`,
      strokeMiterlimit: "0",
      strokeWidth: "4 !important"
    },
    [theme.breakpoints.down("md")]:{
      fontSize: "3.0rem",
    }
  },
  svgOutlinedIconEnabled: {
    display: 'flex',
    height: 'inherit',
    width: '2.5rem',
    fontSize: "1rem",
    textAlign: "center",
    overflow: "visible",
    [theme.breakpoints.down("md")]:{
      fontSize: "3.0rem",
    }
  },
  svgOutlinedIconEnabledRv: {
    display: 'flex',
    height: 'inherit',
    width: '1.75rem',
    fontSize: "1rem",
    textAlign: "center",
    "& path:not(cls-2)": {
      fill: theme.palette.secondary.dark,
      stroke: "none",
      strokeMiterlimit: "0",
      strokeWidth: "0"
    },
    [theme.breakpoints.down("md")]:{
      fontSize: "3.0rem",
    },
  },
  checkInBox: {
    position: "sticky",
    top: "calc(6.5rem + 1px)",
  },
  stateTax: {
    color: theme.palette.secondary.main,
  },
  buttonWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
  actionBox: {
    justifyContent: "flex-end",
  },
  buttonProgress: {
    color: theme.palette.primary.color,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  svgIconPioneer: {
    display: 'flex',
    height: 'inherit',
    width: '1.25rem',
    fontSize: "1rem",
    textAlign: "center",
    "& path:not(cls-2)": {
      fill: theme.palette.primary.main,
      stroke: "none",
      strokeMiterlimit: "0",
      strokeWidth: "0"
    },
    [theme.breakpoints.down("md")]:{
      fontSize: "3.0rem",
    },
    disabledButton: {
      background: "#E7F0F0"
    }
  },
  cardCheckedInChip: {
    width: "1.0rem",
    height: "1.0rem",
    minWidth: "1.0rem",
    minHeight: "1.0rem",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "1.0rem",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      width: "1.25rem",
      height: "1.25rem",
      minWidth: "1.25rem",
      minHeight: "1.25rem",
    }
  },
  checkIcon: {
    marginRight: "0px !important",
    fontSize: "0.875rem",
  },
  parkingSpotImage: {
    width: "100%",
    height: "30rem",
    objectFit: "cover",
    "&:not([src])": {
      visibility: "hidden",
    },
    [theme.breakpoints.down("sm")]: {
      height: "18rem",
    },
    [theme.breakpoints.down("xs")]: {
      flex: 1,
      height: "16rem",
    },
  },
  mobileAttributesBox: {
    height: "100%",
    width: "100%",
    zIndex: "9999",
    overflow: "scroll",
    background: "#fff",
    position: "fixed",
    top: 0,
    left: 0,
  },
  mobileAttributesTitle: {
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    borderBottom: `0.0625rem solid ${theme.palette.secondary.light}`,
    position: "fixed",
    width: "100%",
    background: "#fff"
  },
  mobileBottomPanelBox: {
    position: "fixed",
    width: "100%",
    borderTopLeftRadius: "1.0rem",
    borderTopRightRadius: "1.0rem",
    border:  `0.0625rem solid ${theme.palette.secondary.light}`,
    background: "#fff",
    bottom: 0,
    left: 0,
    zIndex: 10,
    maxHeight: "6.25rem",
    WebkitBoxShadow: `0 -0.25rem 0.25rem 0 ${theme.palette.secondary.dark}1`,
    MozBoxShadow: `0 -0.25rem 0.25rem 0 ${theme.palette.secondary.dark}1`,
    boxShadow: `0 -0.25rem 0.25rem 0 ${theme.palette.secondary.dark}1`,
    transition: `max-height 300ms cubic-bezier(.18,1.02,.88,.98)`,
  },
  mobileBottomPanelBoxBooking: {
    position: "fixed",
    width: "100%",
    borderTopLeftRadius: "1.0rem",
    borderTopRightRadius: "1.0rem",
    border:  `0.0625rem solid ${theme.palette.secondary.light}`,
    background: "#fff",
    bottom: 0,
    left: 0,
    zIndex: 10,
    maxHeight: "5.0625rem",
    WebkitBoxShadow: `0 -0.25rem 0.25rem 0 ${theme.palette.secondary.dark}1`,
    MozBoxShadow: `0 -0.25rem 0.25rem 0 ${theme.palette.secondary.dark}1`,
    boxShadow: `0 -0.25rem 0.25rem 0 ${theme.palette.secondary.dark}1`,
    transition: `max-height 300ms cubic-bezier(.18,1.02,.88,.98)`,
  },
  mobileBottomPanelBoxExpanded: {
    position: "fixed",
    width: "100%",
    borderTopLeftRadius: "1.0rem",
    borderTopRightRadius: "1.0rem",
    border:  `0.0625rem solid ${theme.palette.secondary.light}`,
    background: "#fff",
    bottom: 0,
    left: 0,
    zIndex: 9999,
    WebkitBoxShadow: `0 -0.25rem 0.25rem 0 ${theme.palette.secondary.dark}1`,
    MozBoxShadow: `0 -0.25rem 0.25rem 0 ${theme.palette.secondary.dark}1`,
    boxShadow: `0 -0.25rem 0.25rem 0 ${theme.palette.secondary.dark}1`,
    transition: `max-height 650ms cubic-bezier(.17,.67,.62,1.02)`,
  },
  checkInDetailsText: {
    fontSize: "3.0vw",
    [theme.breakpoints.up("mobile")]: {
      fontSize: "0.75rem"
    }
  },
  attributeName: {
    color: "#ccc",
  },
  checkInSliderText: {
    color: `${theme.palette.secondary.main}`
  },
  checkInSliderTextSmall: {
    color: `${theme.palette.secondary.main}`,
    padding: "1.625rem"
  },
  checkInSliderTextVerySmall: {
    color: `${theme.palette.secondary.main}`,
    padding: "1.5rem",
    fontSize: "0.625rem",
  },
  paymentFailedSVG: {
    height: "7rem",
    maxWidth: "100%"
  },
  paymentFailedDialog: {
    maxWidth: "23rem",
    padding: "0",
    paddingTop: "2.5rem",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  dialogTitlePaymentFailed: {
    paddingBottom: "2rem"
  },
  counterButton: {
    height: "3rem",
    border: "none"
  },
  counterTextField: {
    height: "3rem",
    paddingTop: "0.125rem",
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  },
  counterContainer: {
    border: "1px solid #E7F0F0",
    borderRadius: "0.75rem",
    width: "9rem",
    [theme.breakpoints.down(375)]: {
      width: "5.5rem"
    }
  },
  multilineTypography: {
    whiteSpace: "pre-wrap"
  },
  attributeIconSvg: {
    "&.campsite": {
      "& .cls-1": {
        fill: "#010028 !important"
      }
    }
  },
  mobileTitle: {
    [theme.breakpoints.down("sm")]:{
      lineHeight: '0.9em',
    }
  },
  detailsNotification: {
    background: "#FFA600",
    height: "4rem",
    borderRadius: "0.875rem",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]:{
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      height: "auto",
    }
  },
  notificationIcon: {
    color: "white",
    fontSize: "1.75rem",
    marginLeft: "1rem",
    marginTop: "-0.125rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.0rem",
      marginLeft: "0.75rem",
    }
  },
  notification: {
    color: "white",
    marginLeft: "0.675rem",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
      marginLeft: "0.75rem",
    }
  },
  sectionMb: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(8),
    }
  },
}));

const getTimeAMPM = () => {
  let currentdate = new Date();
  return `${currentdate.getUTCFullYear()}-${(currentdate.getUTCMonth()+1)}-${currentdate.getUTCDate()} ${(currentdate.getUTCHours() % 12)}:${currentdate.getUTCMinutes()} ${(currentdate.getUTCHours() >= 12) ? "PM" : "AM"}`
};

function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

const getNumberOfNights = (date, zone) => {
  let end = moment().tz(zone)
  if (end.hour() > 12 || (end.hour() === 12 && end.minute() > 0) || (end.hour() === 12 && end.minute() === 0 && end.second() > 0)){
    end.add(1, 'days');
  }
  end.hour(12);
  end.minute(0);
  end.second(0);

  let start = moment(date).tz(zone);
  if (start.hour() >= 4 && (start.hour() < 24 || start.hour() < 0)){
    start.hour(12);
    start.minute(0);
    start.second(0);
  } else {
    start.subtract(1, 'days');
    start.hour(12);
    start.minute(0);
    start.second(0);
  }
  let duration = moment.duration(end.diff(start));
  let days = duration.asDays();
  let roundedDays = Math.round(days);
  if (roundedDays < 1){
    roundedDays = 1;
  }
  return roundedDays;
}

const attributeMap = {
  "Host Information" : {
    rvParking: { category: "Host Information", boxTitle: "host", text: "RV Parking", icon:"host_type_rvpark.svg", showIfDisabled: false },
    campsiteSpot: { category: "Host Information", boxTitle: "host", text: "At Campsite", icon:"host_type_campsite.svg", showIfDisabled: false },
    privateSpot: { category: "Host Information", boxTitle: "host", text: "At Private house", icon:"host_type_privatehouse.svg", showIfDisabled: false },
    railwaySpot: { category: "Host Information", boxTitle: "host", text: "At Mountain Railway", icon:"host_type_cablecar.svg", showIfDisabled: false },
    restaurantParkingSpot: { category: "Host Information", boxTitle: "host", text: "At Restaurant Parking", icon:"host_type_restaurant.svg", showIfDisabled: false },
    farmhouseSpot: { category: "Host Information", boxTitle: "host", text: "At Farmhouse", icon:"host_type_farm.svg", showIfDisabled: false },
    natureSpot: { category: "Host Information", boxTitle: "host", text: "Nature spot", icon:"host_type_naturespot.svg", showIfDisabled: false },
    atMarina: { category: "Host Information", boxTitle: "host", text: "At Marina", icon:"host_type_marina.svg", showIfDisabled: false },
    campingBehaviour: { category: "Host Information", boxTitle: "behaviour", text: "Camping behaviour", icon:"host_behaviour_camping.svg", showIfDisabled: false },
    foldingChairBehaviour: { category: "Host Information", boxTitle: "behaviour", text: "Folding Chair behaviour", icon:"host_behaviour_foldingchair.svg", showIfDisabled: false },
    parkingBehaviour: { category: "Host Information", boxTitle: "behaviour", text: "Only Parking Behaviour", icon:"host_behaviour_parking.svg", showIfDisabled: false },
    onboardToiletRequired: { category: "Host Information", boxTitle: "autarchy", text: "Onboard Toilet required", icon:"host_autarchy.svg", showIfDisabled: false, },
    noOnboardToiletRequired: { category: "Host Information", boxTitle: "autarchy", text: "No Onboard Toilet required", icon:"host_autarchy_not.svg", showIfDisabled: false, },
  },

  "Place Properties" : {
    upTo6: { category: "Place Properties", boxTitle: "vehicle", text: "up to 6 meters", icon:"place_carsize_small.svg", showIfDisabled: false },
    upTo8: { category: "Place Properties", boxTitle: "vehicle", text: "up to 8 meters", icon:"place_carsize_big.svg", showIfDisabled: false },
    upTo12: { category: "Place Properties", boxTitle: "vehicle", text: "up to 12 meters", icon:"place_carsize_large.svg", showIfDisabled: false },
    caravan: { category: "Place Properties", boxTitle: "hide", text: "Caravan", icon:"place_carsize_caravan.svg", showIfDisabled: false },
    onlyOffroad: { category: "Place Properties", boxTitle: "vehicle", text: "Only offroad vehicles", icon:"place_carsize_offroad.svg", showIfDisabled: false },
    narrow: { category: "Place Properties", boxTitle: "approach", text: "Road Access Narrow < 2m", icon:"place_approach_narrow.svg", showIfDisabled: false },
    only4x4: { category: "Place Properties", boxTitle: "approach", text: "4x4 empfohlen", icon:"place_approach_4wd.svg", showIfDisabled: false },
    asphalt: { category: "Place Properties", boxTitle: "terrain", text: "Asphalt", icon:"place_terrain_asphalt.svg", showIfDisabled: false },
    gravel: { category: "Place Properties", boxTitle: "terrain", text: "Gravel", icon:"place_terrain_gravel.svg", showIfDisabled: false },
    grass: { category: "Place Properties", boxTitle: "terrain", text: "Gras", icon:"place_terrain_gras.svg", showIfDisabled: false },
    winter: { category: "Place Properties", boxTitle: "season", text: "Winter Place", icon:"place_season_snowflake.svg", showIfDisabled: false },
    summer: { category: "Place Properties", boxTitle: "hide", text: "Summer Place", icon:"place_season_snowflake.svg", showIfDisabled: false },
  },

  "Service" : {
    wcService: { category: "Service", boxTitle: "services", text: "WC", icon:"service_toilets.svg", showIfDisabled: true },
    powerSupplyService: { category: "Service", boxTitle: "services", text: "Power supply", icon:"service_electric.svg", showIfDisabled: true },
    freshWaterService: { category: "Service", boxTitle: "services", text: "Fresh water", icon:"service_freshwater.svg", showIfDisabled: true },
    showerService: { category: "Service", boxTitle: "services", text: "Shower", icon:"service_shower.svg", showIfDisabled: true },
    wifiService: { category: "Service", boxTitle: "services", text: "Wifi", icon:"service_wifi.svg", showIfDisabled: true },
    cassetteDisposalService: { category: "Service", boxTitle: "services", text: "Toilet cassette disposal", icon:"service_blackwater.svg", showIfDisabled: true },
    waterDisposalService: { category: "Service", boxTitle: "services", text: "Grey water disposal", icon:"service_greywater.svg", showIfDisabled: true },
    wasterDisposalService: { category: "Service", boxTitle: "services", text: "Waste disposal", icon:"service_wastedisposal.svg", showIfDisabled: true },
    firePlaceService: { category: "Service", boxTitle: "services", text: "Fire place", icon:"service_fireplace.svg", showIfDisabled: true },
    farmShopService: { category: "Service", boxTitle: "services", text: "Farm shop", icon:"service_farmshop.svg", showIfDisabled: true },
    camperDinnerService: { category: "Service", boxTitle: "services", text: "Camper dinner", icon:"service_camperdinner.svg", showIfDisabled: true },
    laundryService: { category: "Service", boxTitle: "services", text: "Laundry", icon:"service_laundry.svg", showIfDisabled: true },
  },

  "Surrounding" : {
    nearRestaurant: { category: "Surrounding", boxTitle: "surroundings", text: "Near Restaurant", icon:"surrounding_restaurant.svg", showIfDisabled: true },
    protectedArea: { category: "Surrounding", boxTitle: "surroundings", text: "Protected Area", icon:"surrounding_protected_area.svg", showIfDisabled: true },
    nearShopping: { category: "Surrounding", boxTitle: "surroundings", text: "Near Shopping", icon:"surrounding_nearshopping.svg", showIfDisabled: true },
    nearPlayground: { category: "Surrounding", boxTitle: "surroundings", text: "Near Playground", icon:"surrounding_playground.svg", showIfDisabled: true },
    dogFriendly: { category: "Surrounding", boxTitle: "surroundings", text: "Dog Friendly", icon:"surrounding_dogfriendly.svg", showIfDisabled: true },
    nearMountains: { category: "Surrounding", boxTitle: "surroundings", text: "Near Mountains", icon:"surrounding_nearmountains.svg", showIfDisabled: true },
    nearWater: { category: "Surrounding", boxTitle: "surroundings", text: "Near Waters", icon:"surrounding_nearwaters.svg", showIfDisabled: true },
    nearSki: { category: "Surrounding", boxTitle: "surroundings", text: "Near Ski Slopes", icon:"surrounding_skiarea.svg", showIfDisabled: true },
    hikingArea: { category: "Surrounding", boxTitle: "surroundings", text: "Hiking Area", icon:"surrounding_hikingarea.svg", showIfDisabled: true },
    bicycleSurrounding: { category: "Surrounding", boxTitle: "surroundings", text: "Bicycle", icon:"surrounding_bicyle.svg", showIfDisabled: true },
  },
};

const defaultValues = {
  car: "",
};

const fetchDetailsForTab = (claimLink, activeTab, translate, classes, isMobile, tag) => {
  switch (activeTab) {
    case "connect":
      return (
        <Box pt={isMobile ? 0 : 2}>
          <Box display="flex" my={2} justifyContent="space-between">
            <Typography variant="h2" className={classes.tabTitle}>{translate("details.connect-title")}</Typography>
            { !isMobile && (
              <Button variant="contained" color="primary"
                className={classes.checkout}
                component="a"
                href={claimLink}
              >
                { translate("common.btn.check-in.claimable") }
              </Button>
            ) }
          </Box>
          <Stepper className={classes.stepper} alternativeLabel={ !isMobile } orientation={ isMobile ? "vertical" : "horizontal" } connector={
            <StepConnector
              classes={{
                root: classes.stepConnector,
                line: classes.stepConnectorLine,
                vertical: classes.stepConnectorVertical,
                alternativeLabel: classes.stepConnectorAlternativeLabel,
              }}
            />
          }>
            <Step>
              <StepLabel classes={{
                root: classes.stepLabelRoot,
                label: classes.stepLabelFirst,
              }} StepIconComponent={CustomStepIcon}>
                {isMobile ?
                  <IconMobileStep1 className={classes.stepIconFirst} /> :
                  <IconStep1 className={classes.stepIconFirst} />
                }
                {translate("details.connect-step1")}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel classes={{
                root: classes.stepLabelRoot,
                label: classes.stepLabel,
              }} StepIconComponent={CustomStepIcon}>
                {isMobile ?
                  <IconMobileStep2 className={classes.stepIcon} /> :
                  <IconStep2 className={classes.stepIcon} />
                }
                {translate("details.connect-step2")}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel classes={{
                root: classes.stepLabelRoot,
                label: classes.stepLabel,
              }} StepIconComponent={CustomStepIcon}>
                {isMobile ?
                  <IconMobileStep3 className={classes.stepIcon} /> :
                  <IconStep3 className={classes.stepIcon} />
                }
                {translate("details.connect-step3")}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel classes={{
                root: classes.stepLabelRoot,
                label: classes.stepLabelLast,
              }} StepIconComponent={CustomStepIcon}>
                {isMobile ?
                  <IconMobileStep4 className={classes.stepIconLast} /> :
                  <IconStep4 className={classes.stepIconLast} />
                }
                {translate("details.connect-step4")}
              </StepLabel>
            </Step>
          </Stepper>
          { isMobile && (
            <Box mt={4}>
              <Button variant="contained" color="primary" fullWidth={true}
                className={classes.checkout}
                component="a"
                href={claimLink}
              >
                { translate("common.btn.check-in.claimable") }
              </Button>
            </Box>
          ) }
        </Box>
      )
    case "parknsleep":
      return (
        <Box pt={isMobile ? 0 : 2}>
          <Box display="flex" mt={2}>
            <Typography variant="h2" className={classes.tabTitle}>{translate("details.parknsleep-title")}</Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body" className={classes.tabBody}>
              <Trans i18nKey="details.parknsleep-body-1" />
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body" className={classes.tabBody}>
              <Trans i18nKey="details.parknsleep-body-2" />
            </Typography>
          </Box>
          <Box mt={4}>
            <Button variant="contained" color="primary" fullWidth={isMobile}
              className={classes.checkout}
              component="a"
              href={claimLink}
            >
              { translate("common.btn.check-in.claimable") }
            </Button>
          </Box>
        </Box>
      )
    case "free":
      return (
        <Box pt={isMobile ? 0 : 2}>
          <Box display="flex" mt={2}>
            <Typography variant="h2" className={classes.tabTitle}>{translate("details.free-title")}</Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body" className={classes.tabBody}>
              <Trans i18nKey="details.free-body-1" />
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body" className={classes.tabBody}>
              <Trans i18nKey="details.free-body-2" />
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body" className={classes.tabBody}>
              <Trans i18nKey="details.free-body-3" />
            </Typography>
          </Box>
          <Box mt={4}>
            <Button variant="contained" color="primary" fullWidth={isMobile}
              className={classes.checkout}
              component="a"
              href={claimLink}
            >
              { translate("common.btn.check-in.claimable") }
            </Button>
          </Box>
        </Box>
      )
    case "integration":
      return (
        <Box pt={isMobile ? 0 : 2}>
          <Box display="flex" mt={2}>
            <Typography variant="h2" className={classes.tabTitle}>{translate("details.integration-title")}</Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body" className={classes.tabBody}>
              <Trans i18nKey="details.integration-body-1" />
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body" className={classes.tabBody}>
              <Trans i18nKey="details.integration-body-2" />
            </Typography>
          </Box>
          <Box mt={4}>
            <Button variant="contained" color="primary" fullWidth={isMobile}
              className={classes.checkout}
              component="a"
              href={claimLink}
            >
              { translate("common.btn.check-in.claimable") }
            </Button>
          </Box>
        </Box>
      )
    default:
      return null
  }
}

// TODO Refactor: create a separate component for all of the `section` elements
const ParkingSpotDetails = ({
  activeLanguage,
  addLoader,
  addLoaderForItem,
  addLoaderForPhotos,
  appliedVoucher,
  applyingVoucher,
  cardPaymentFailed,
  cars,
  checkInDetailsDialogOpen,
  checkInDialogOpen,
  checkingIn,
  checkingOut,
  checkOutDialogOpen,
  completedProfile,
  currentBookingId,
  currentCheckIns,
  enableReview,
  fetchCarsForGuests,
  fetchCurrentCheckIns,
  fetchParkingSpotDetails,
  fetchParkingSpotDetailsGuests,
  fetchPhotosByIdForGuests,
  fetchReviewForBooking,
  fetchReviewsByIdForGuests,
  fetchReviewsByIdForGuestsNextPage,
  fetchVoucherDetails,
  gallery,
  isLoggingOut,
  mobileBottomPanel,
  pagy,
  parkingSpot,
  parkingSpotsDummy,
  photos,
  postCheckIn,
  postCheckOut,
  postReview,
  profilePicture,
  resetReviews,
  reviewDialogOpen,
  reviewForBooking,
  reviews,
  reviewSubmissionDialogOpen,
  setAppliedVoucher,
  setApplyingVoucher,
  setCardPaymentFailed,
  setCarouselState,
  setCheckInDetailsDialogOpen,
  setCheckInDialogOpen,
  setCheckingIn,
  setCheckingOut,
  setCheckOutDialogOpen,
  setEnableReview,
  setMobileBottomPanel,
  setReviewDialogOpen,
  setReviewSubmissionDialogOpen,
  token,
  user,
  userType,
  verified,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  let isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  let isSm = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  let isSmallMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobile));
  let isVerySmallMobile = useMediaQuery(theme.breakpoints.down(350));
  let isSmallDesktop = useMediaQuery(theme.breakpoints.down(1200));
  const { tag } = useParams();
  const [isEnableReviewLoginDialogOpen, setIsEnableReviewLoginDialogOpen] = useState(enableReview);
  const [guestProfileIncompleteDialogOpen, setGuestProfileIncompleteDialogOpen] = useState(false);
  const [paymentFailedDialogOpen, setpaymentFailedDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("connect");
  const [numberOfDays, setNumberOfDays] = useState(1);
  const [daysSelectionDialogOpen, setDaysSelectionDialogOpen] = useState(false);
  const [daysSelectionWheelOpen, setDaysSelectionWheelOpen] = useState(false);
  const galleryPhotos = parkingSpot && photos.id === parkingSpot.id ? photos.response : {};
  const checkinPossibleOn = [];

  if (parkingSpot && parkingSpot.availability_options) {
    const day = new Date();
    const available_on = parkingSpot.availability_options.map((item) => item.text)
    
    for(let i = 1; i <= 7; i++){
      const dayName = day.toLocaleDateString('en-US', { weekday: 'long' });
      if(available_on.includes(dayName)) {
        checkinPossibleOn.push(dayName)
        day.setDate(day.getDate() + 1)
      } else {
        break;
      }
    }
  }

  let tourId = "";

  switch (activeLanguage.toLowerCase()) {
    case "de":
      tourId = "350299"
      break;
    case "es":
      tourId = "350301"
      break;
    case "fr":
      tourId = "350302"
      break;
    case "it":
      tourId = "350303"
      break;
    default:
      break;
  }

  const handleWheelTouchEnd = () => {
    refAngle = null
    firstTouch = true
  }

  const handleWheelTouchEvent = (e) => {
    
    const inner = document.querySelector('#wheel-inner')
    const difference = Math.round((360 / checkinPossibleOn.length) * 100)

    const outerBounds = e.target.getBoundingClientRect()
    const touchX = e.targetTouches[0].clientX
    const touchY = e.targetTouches[0].clientY

    const innerBounds = inner.getBoundingClientRect()
    const innerRadius = innerBounds.width / 2
    const outerRadius = outerBounds.width / 2

    const touchPosition = [touchX, touchY]
    const center = [outerBounds.left + outerRadius, outerBounds.top + outerRadius]
    const reference = [outerBounds.left + outerRadius, outerBounds.top]

    const centerToRefPoint = [center[0] - reference[0], center[1] - reference[1]]
    const centerToTouchPosition = [center[0] - touchPosition[0], center[1] -  touchPosition[1]]

    const mouseDistanceFromInnerCenter = distanceBetween(center, touchPosition)

    if (mouseDistanceFromInnerCenter < outerRadius && mouseDistanceFromInnerCenter > innerRadius) {

      const currentAngle = Math.round(angleBetweenPointsTan2(crossProduct(centerToRefPoint, centerToTouchPosition), dotProduct(centerToRefPoint, centerToTouchPosition)) * 100)

      if (!refAngle) {
        refAngle = currentAngle
      }
  
      if ( prevAngle < currentAngle) {

        if (!firstTouch) {
          wheelGradientRotation += (currentAngle - prevAngle) / 100
          e.target.parentElement.style.transform = `rotate(${wheelGradientRotation}deg)`;
        }

        if (currentAngle < refAngle || currentAngle - refAngle > difference * 2) {
          refAngle = currentAngle
        }

        if (currentAngle - refAngle > difference && numberOfDays < checkinPossibleOn.length && currentAngle - prevAngle < 5000) {
          clickSound.play()
          setNumberOfDays(numberOfDays + 1)
          refAngle = null
        }

      }
  
      if ( prevAngle > currentAngle) {

        if(!firstTouch) {
          wheelGradientRotation -= (prevAngle - currentAngle) / 100
          e.target.parentElement.style.transform = `rotate(${wheelGradientRotation}deg)`;
        }

        if (refAngle < currentAngle || refAngle - currentAngle > difference * 2) {
          refAngle = currentAngle
        }

        if (refAngle - currentAngle > difference && numberOfDays > 1 && prevAngle - currentAngle < 5000) {
          clickSound.play()
          setNumberOfDays(numberOfDays - 1)
          refAngle = null
        }
      }
  
      prevAngle = currentAngle
      firstTouch = false
    }
  }

  const showExternal = !!activeLanguage && tourId !== ""
  const claimLink = showExternal ? `https://www.parknsleep.de/gs/preview.php?nr=${tag}&lang=${activeLanguage.toUpperCase()}&product_tour_id=${tourId}` : `/signup-host?id=${tag}`

  useEffect(() => {
    if (cardPaymentFailed){
      setCheckInDialogOpen(false);
      setpaymentFailedDialogOpen(true);
    }
    else {
      setpaymentFailedDialogOpen(false);
    }
  }, [cardPaymentFailed])

  const { control } = useForm({ defaultValues });
  const [booking] = currentCheckIns.filter(checkin => checkin.parking_spot.tag == tag);

  useEffect(() => {
    if (user && user.status === "suspended"){
      setpaymentFailedDialogOpen(true);
    }
  }, [user])

  const returnIcon = (iconName, color="primary") => {
    const icon = getSVGForIcon(iconName);
    return icon && <SvgIcon component={icon} className={clsx(classes.attributeIconSvg, iconName.startsWith("host_type_campsite") ? "campsite" : "")} style={ color === "secondary" ? {width: "2.0rem", height: "2.0rem", color: "#CCC"} : {width: "2.0rem", height: "2.0rem"}} viewBox={iconName.startsWith("host_type_campsite") ? "-2 -5 78 78" : "0 0 24 24"} />
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const navMenuOpen = Boolean(anchorEl);

  const handleShareButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShareButtonClose = (event) => {
    setAnchorEl(null);
  };

  const handleReviewsNextPage = () => {
    if (!!pagy && pagy.page_url !== pagy.last_url && parkingSpot && parkingSpot.id){
      fetchReviewsByIdForGuestsNextPage(pagy.next_url, parkingSpot.id);
      fetchParkingSpotDetails(tag);
    }
  }

  function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals).toFixed(decimals);
  }
  let occupiedPercentage, unoccupiedPercentage;
  let availableParkingSpaces = (!!parkingSpot && !!parkingSpot.available_today && !!parkingSpot.spot_active) ? (parkingSpot.capacity - parkingSpot.occupied) : 0;
  let tourismTaxTypePerPerson = (!!parkingSpot && parkingSpot.tourism_tax_type === "per_person") ? true : false;
  const [checkInText, setCheckInText] = useState("");
  const [unavailableToday, setUnavailableToday] = useState(false);
  const [mobileAttributesOpen, setMobileAttributesOpen] = useState(false);
  const [isFullReviewDialogOpen, setIsFullReviewDialogOpen] = useState(false);
  const [isAlreadyReviewedDialogOpen, setIsAlreadyReviewedDialogOpen] = useState(false);
  const [attributeDialogOpen, setAttributeDialogOpen] = useState(false);
  const [fullReviewDialog, setFullReviewDialog] = useState(null);
  const [isNightSelected, setIsNightSelected] = useState(false);
  const [resetSwipeButton, setResetSwipeButton] = useState(0);
  const [checkinPersonsCount, setCheckinPersonsCount] = useState(1);
  const [cacheBuster, setCacheBuster] = useState(null);
  const [intercomData, setIntercomData] = useState(null);
  const { boot } = useIntercom();

  const isCamigoPartnerBoxShown = !!parkingSpot?.camigo_link;
  
  const INTERCOM_APP_ID = 'js400j02';
  useEffect(() => {
    // Intercom settings config
    window.intercomSettings = {
      app_id: INTERCOM_APP_ID,
      custom_launcher_selector: '.intercom-launcher',
      hide_default_launcher: true
    };

    if (token) {
      setIntercomData({
        app_id: INTERCOM_APP_ID,
        name: user.fullName,
        email: user.email,
        user_type: userType,
        hide_default_launcher: true
      });
    } else {
      setIntercomData({
        app_id: INTERCOM_APP_ID,
      });
    }
  }, [token, userType, user.fullName, user.email])
  const bootWithProps = () => boot(intercomData);

  useEffect(() => {
    if (!cacheBuster) {
      setCacheBuster(generateCacheBusterKey());
    }
  }, []);

  let grossPrice = parkingSpot && Number(parkingSpot.price) + Number(parkingSpot.tax_amount);

  const handleFullReviewDialog = (review) => {
    setFullReviewDialog(review);
    setIsFullReviewDialogOpen(true);
  }

  const handleCheckInDialogClose = () => {
    setCheckInDialogOpen(false);
    setIsNightSelected(false);
    setResetSwipeButton(resetSwipeButton + 1);
    
  }
  const handleCheckOutDialogClose = () => {
    setCheckOutDialogOpen(false);
    setIsNightSelected(false);
    setResetSwipeButton(resetSwipeButton + 1);
  }



  useEffect(() => {
    if (!isLoggingOut) {
      addLoader();
      setCarouselState("default", "", true);
      return () => {
        setCarouselState("default", "", true);
      }
    }
  }, []);

  // TODO: break it down into multiple `useEffect`s and remove the extra deps
  useEffect(() => {
    if (!isLoggingOut) {
      addLoaderForItem("parkingspots");
      if (token != null && parkingSpot && parkingSpot.tag === tag && userType==="Guest"){
        fetchParkingSpotDetailsGuests(token, parkingSpot.id);
      }
      else if (token != null) {
        fetchParkingSpotDetails(tag, token);
      }
      else {
        fetchParkingSpotDetails(tag);
      }
      if (parkingSpot && parkingSpot.id) {
        addLoaderForItem("reviews");
        fetchReviewsByIdForGuests(parkingSpot.id, tag);
        addLoaderForPhotos();
        fetchPhotosByIdForGuests(parkingSpot.id, tag);
        let ghostBehaviour = !parkingSpot.host;
        if (parkingSpot.parking_spot_category_options){
          let [behaviour1] = parkingSpot.parking_spot_category_options.filter(cat => cat.text.replaceAll(" ", "-").toLowerCase() === "only-parking-behaviour");
          let [behaviour2] = parkingSpot.parking_spot_category_options.filter(cat => cat.text.replaceAll(" ", "-").toLowerCase() === "folding-chair-behaviour");
          let [behaviour3] = parkingSpot.parking_spot_category_options.filter(cat => cat.text.replaceAll(" ", "-").toLowerCase() === "camping-behaviour");
          let [autarchy] = parkingSpot.parking_spot_category_options.filter(cat => cat.text.replaceAll(" ", "-").toLowerCase() === "onboard-toilet-required");
          let days = parkingSpot.parking_spot_category_options.filter(cat => (cat.text.replaceAll(" ", "-").toLowerCase().substring(cat.text.replaceAll(" ", "-").toLowerCase().length - 3) === "day"));
          if (!!autarchy){
            if (!!ghostBehaviour) {
              setCheckInText(`${t("check-in-box.short-description.claimable-behaviour")}`);
            }
            else if (!!behaviour1){
              setCheckInText(`${t("check-in-box.short-description.behaviour-1")} ${t("check-in-box.short-description.autarchy-y")}`);
            }
            else if (!!behaviour2){
              setCheckInText(`${t("check-in-box.short-description.behaviour-2")} ${t("check-in-box.short-description.autarchy-y")}`);
            }
            else if (!!behaviour3){
              setCheckInText(`${t("check-in-box.short-description.behaviour-3")} ${t("check-in-box.short-description.autarchy-y")}`);
            }
            else {
              setCheckInText(`${t("check-in-box.short-description.behaviour-1")}`);
            }
          }
          else {
            if (!!ghostBehaviour) {
              setCheckInText(`${t("check-in-box.short-description.claimable-behaviour")}`);
            }
            else if (!!behaviour1){
              setCheckInText(`${t("check-in-box.short-description.behaviour-1")}`);
            }
            else if (!!behaviour2){
              setCheckInText(`${t("check-in-box.short-description.behaviour-2")}`);
            }
            else if (!!behaviour3){
              setCheckInText(`${t("check-in-box.short-description.behaviour-3")}`);
            }
            else {
              setCheckInText(`${t("check-in-box.short-description.behaviour-1")}`);
            }
          }
          if (days.length > 0){
            let today = new Date();
            let day = today.getDay();
            switch(day){
              case 0:
                day = "sunday";
                break;
              case 1:
                day = "monday";
                break;
              case 2:
                day = "tuesday";
                break;
              case 3:
                day = "wednesday";
                break;
              case 4:
                day = "thursday";
                break;
              case 5:
                day = "friday";
                break;
              case 6:
                day = "saturday";
                break;
              default:
                day = "none";
                break;
            }
            let [isDay] = days.filter(d => d.text.replaceAll(" ", "-").toLowerCase() === day)
            setUnavailableToday((isDay == undefined) ? true : false);
          }
          else {
            setUnavailableToday(true);
          }
        }
      }
    }
  }, [
    fetchParkingSpotDetails,
    fetchParkingSpotDetailsGuests,
    fetchCurrentCheckIns,
    fetchCarsForGuests,
    token,
    (parkingSpot && parkingSpot.id),
    tag,
    fetchPhotosByIdForGuests,
    fetchReviewsByIdForGuests,
    t("check-in-box.short-description.behaviour-1"),
    t("check-in-box.short-description.behaviour-2"),
    t("check-in-box.short-description.behaviour-3"),
    t("check-in-box.short-description.autarchy-y"),
  ]);

  useEffect(() => {
    return () => resetReviews()
  }, [resetReviews])
  useEffect(() => {
    if (userType === "Guest" && !isLoggingOut) {
      fetchCurrentCheckIns(token);
      fetchCarsForGuests(token);
    }
  }, [fetchCarsForGuests, fetchCurrentCheckIns, isLoggingOut, token, userType]);

  useEffect(() => {
    if (userType === "Guest" && enableReview && currentBookingId && reviewForBooking!==null && reviewForBooking.id==null && !isLoggingOut) {
      setReviewSubmissionDialogOpen(true)
    }
    else if (userType === "Guest" && enableReview && currentBookingId && reviewForBooking!==null && reviewForBooking.id!==null && !isLoggingOut) {
      setIsAlreadyReviewedDialogOpen(true)
    }
    else if (userType === "Guest" && enableReview && currentBookingId && reviewForBooking===null && !isLoggingOut) {
      fetchReviewForBooking(token, currentBookingId);
    }
    else if (!!token) {
      setEnableReview(false);
    }
  }, [currentBookingId, enableReview, fetchReviewForBooking, isLoggingOut, reviewForBooking, setEnableReview, setReviewSubmissionDialogOpen, token, userType]);

  const timeLeftTill = (date, zone) => {
    const currentTime = moment().tz(zone);
    let hours = moment(date).tz(zone).diff(currentTime, 'hours') ;
    let mins = moment(date).tz(zone).diff(currentTime, 'minutes') - (hours*60);
    const hoursSubString = `${hours}${hours > 1 ? t("common.btn.time.hours") : t("common.btn.time.hour")}`;
    const minsSubString = `${mins}${mins > 1 ? t("common.btn.time.mins") : t("common.btn.time.min")}`;
    const time = `${!!hours ? hoursSubString : ""} ${!!mins ? minsSubString : ""} `;
    return time;
  };

  const contactMailTo = (email = parkingSpot.host?.email) => {
    if (email !== undefined) {
      const mailto =
        `mailto:${email}?subject=ParknSleep | ${parkingSpot.name}`;
      window.location.href = mailto;
    }
  }

  const contactTel = (tele = parkingSpot.host?.phone) => {
    if (tele !== undefined) {
      const tel =
        `tel:${tele}`;
      window.location.href = tel;
    }
  }

  const getPrimaryCar = () => {
    const [primary] = cars.filter(c => c.is_primary === true);
    return primary;
  }

  const getAllAttributes = () => {
    return (
      <Box>
      {
        Object.entries(attributeMap).map(category => {
          return (
            <Box pt={(isMobile) ? 2 : 0} pb={(isMobile) ? 2 : 2} key={category[0]}>
              <Typography variant="h4">
                {t(`spot-attributes.${category[0].replaceAll(" ", "-").toLowerCase()}-category.title`)}:
              </Typography>
              <Box px={1} pt={isMobile ? 2 : 1.5}>
                {
                  Object.entries(category[1]).map(item => {
                    let color = "secondary";
                    let boxTitle = item[1].boxTitle;
                    let icon = item[1].icon;
                    parkingSpot.parking_spot_category_options.forEach(op => {
                      if (op.text.toLowerCase() === item[1].text.toLowerCase() || item[1].color === "primary"){
                        color="primary";
                      }
                    });
                    return ((color === "primary" || item[1].showIfDisabled === true) && (item[1].boxTitle !== "hide")) ? (
                      <Box py={1} display="flex" alignItems="center">
                        { returnIcon(icon, color) }
                        <Box ml={1.75}>
                          <Typography variant="h5" className={color === "secondary" ? classes.attributeName : null }>
                            {t(`spot-attributes.${category[0].replaceAll(" ", "-").toLowerCase()}-category.box.${boxTitle}.box-content.${item[1].text.replaceAll(" ", "-").toLowerCase()}`)}
                          </Typography>
                          <Typography variant="caption" color={"secondary"} className={color === "secondary" ? classes.attributeName : null } style={{fontSize: "0.8rem"}}>
                            {t(`spot-attributes.${category[0].replaceAll(" ", "-").toLowerCase()}-category.box.${boxTitle}.box-content.${item[1].text.replaceAll(" ", "-").toLowerCase()}-subtext`)}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Box display="none" />
                    )
                  })
                }
              </Box>
            </Box>
          )
        })
      }
      </Box>
    )
  }

  const getNextCheckOutTime = (bookingDate, zone) => {
    const currentdate = moment().tz(zone);
    let date = moment(currentdate).tz(zone);
    let bookingDateObj = moment(bookingDate).tz(zone);

    if (bookingDateObj.date() === currentdate.date()) {
      if (currentdate.hour() >= 4){
        date.date(currentdate.date() + 1);
      };
    } else {
      if (currentdate.hour() >= 12){
        date.date(currentdate.date() + 1);
      };
    }

    date.hour(12);
    date.minute(0);
    date.second(0);
    date.millisecond(0);
    return date;
  }

  if (parkingSpot == undefined) { return(<Box display="none" />); }

  else {
    occupiedPercentage = `${parkingSpot.occupied/parkingSpot.capacity * 100}%`;
    unoccupiedPercentage = `${100 - (parkingSpot.occupied/parkingSpot.capacity * 100)}%`;
    let galleryImages = []

    if (galleryPhotos.gallery_images) {
      galleryImages = [
        galleryPhotos.main_photo_medium_url || galleryPhotos.main_photo_thumbnail_url || galleryPhotos.main_photo_url,
        ...(galleryPhotos.gallery_images.map(img => img.medium_url || img.thumbnail_url || img.service_url)),
      ].filter(img => img);
    }

    if (galleryImages.length === 0) {
      galleryImages.push(background);
    }

    const carouselImages = (imgs) => {
      let img_array = [];
      if (imgs && imgs.length) {
        imgs.forEach(img => {
          img_array.push(<img src={img} className={classes.parkingSpotImage} alt="" />)
        })
      }

      return img_array;
    }

    const lat = parkingSpot && parkingSpot.geolocation ? parseFloat(parkingSpot.geolocation.substring(parkingSpot.geolocation.lastIndexOf(" ") + 1, parkingSpot.geolocation.lastIndexOf(")"))) : 0;
    const lng = parkingSpot && parkingSpot.geolocation ? parseFloat(parkingSpot.geolocation.substring(parkingSpot.geolocation.indexOf("(") + 1, parkingSpot.geolocation.lastIndexOf(" "))) : 0;

    const smallDesktopTitleTag = isSmallDesktop ? "h4" : "h3";
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        {
          !parkingSpot.host && cacheBuster &&
          <Box key={cacheBuster} display="none">
            <img src={`https://www.google-analytics.com/collect?v=1&;tid=UA-198820811-1&;cid=555&;aip=1&;t=event&;ec=1_landingpage&;ea=1_landingpage&;el=${tag}&;dp=&;dt=&;z=${cacheBuster}`} />
          </Box>
        }
        { isMobile && 
          <Carousel 
            galleryDialogProps={{
              fullScreen: isSm,
            }} 
            items={carouselImages(galleryImages)} label="details" wrapper={true} />
        }
        <Container maxWidth={isMobile ? "initial" : "lg"} >
        { !isMobile && <Gallery images={galleryImages} fetchCarouselImages={carouselImages}/> }
        <Box display="flex" flexDirection="column">
          {
            !parkingSpot.host && (
              <>
                <Box className={classes.detailsNotification} mt={isMobile ? 5 : 4} alignItems="center" display="flex">
                  <ErrorOutlineIcon className={classes.notificationIcon} />
                  <Typography variant={isMobile ? "body2" : "subtitle"} className={classes.notification}>{ t("host-dashboard.notification.ps-claimable") }</Typography>
                </Box>
                { isMobile ? (
                  <Box mt={3}>
                    <Tabs
                      value={activeTab}
                      variant="scrollable"
                      onChange={(event, newValue) => {
                        setActiveTab(newValue)
                      }}
                      textColor="primary"
                      indicatorColor="primary"
                    >
                      <Tab value="connect" label={t("details.connect")} />
                      <Tab value="parknsleep" label={t("details.parknsleep")} />
                      <Tab value="free" label={t("details.free")} />
                      <Tab value="integration" label={t("details.integration")} />
                    </Tabs>
                    {
                      fetchDetailsForTab(claimLink, activeTab, t, classes, isMobile, tag)
                    }
                  </Box>
                ) : (
                  <Card style={{padding: "2rem", marginTop: "2rem", border: "2px solid #FD9800"}}>
                    <Tabs
                      value={activeTab}
                      variant="scrollable"
                      onChange={(event, newValue) => {
                        setActiveTab(newValue)
                      }}
                      textColor="primary"
                      indicatorColor="primary"
                    >
                      <Tab value="connect" label={t("details.connect")} />
                      <Tab value="parknsleep" label={t("details.parknsleep")} />
                      <Tab value="free" label={t("details.free")} />
                      <Tab value="integration" label={t("details.integration")} />
                    </Tabs>
                    {
                      fetchDetailsForTab(claimLink, activeTab, t, classes, isMobile, tag)
                    }
                  </Card>
                ) }
              </>
            )
          }
          <Box pt={isMobile ? 5 : 4} display="flex" justifyContent="space-between">
            <Box className={classes.column}>
            { (isMobile) ?
              (
                <Box>
                  <Box className={classes.borderBtm} pt={0} pb={3}>
                    <Box px={0}>
                      <Box>
                        <Box pb={1}>
                          <Typography variant="h1" className={classes.mobileTitle}>
                              {parkingSpot.name}
                          </Typography>
                        </Box>
                        {
                          (!!parkingSpot.location) &&
                          <Box display="flex" flexWrap="wrap">
                            <Box display="flex" alignItems="center" mr={2} mb={1}>
                              <StarIcon />
                              <Typography variant="caption">
                                {parkingSpot.review_count > 0 ? (Math.round(parkingSpot.avg_rating * 10) / 10).toFixed(1) : "N/A"}
                              </Typography>
                              <Typography variant="caption" style={{color: theme.palette.secondary.main, padding: "0 1rem" }}>
                                {`•`}
                              </Typography>
                              <Typography variant="caption" style={{color: theme.palette.secondary.main }}>
                                {formatCountry(parkingSpot.location)}
                              </Typography>
                            </Box>
                            <Box style={{
                              padding: "0.25rem 1rem",
                              borderRadius: "16px",
                              background: "#F2F9FA",
                            }} mb={1}>
                              <Typography variant="caption" color="primary">
                                {parkingSpot.tag}
                              </Typography>
                            </Box>
                          </Box>
                        }
                        { (!parkingSpot.spot_active || !parkingSpot.available_today) &&
                        <Box pb={1} ml={8}>
                          <Typography variant="caption" style={{color: '#FF0000' }}>
                              {((!parkingSpot.spot_active && Math.ceil(((new Date()) - Date.parse(parkingSpot.spot_active_updated_at)) / (1000 * 3600 * 24)) <= 7) || !parkingSpot.available_today) ? t('common.texts.closed-today') : t('common.texts.closed-off-season') }
                          </Typography>
                        </Box>
                        }
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )
              : 
              (
                <Box px={0}>
                  <Box pb={1} display="flex" alignItems="flex-start">
                    <Box flex="auto">
                      <Typography variant="h1">
                          {parkingSpot.name}
                      </Typography>
                      {
                        (!!parkingSpot.location) &&
                        <Box pt={1} display="flex" alignItems="center">
                          <StarIcon />
                          <Typography variant="caption">
                            {parkingSpot.review_count > 0 ? (Math.round(parkingSpot.avg_rating * 10) / 10).toFixed(1) : "N/A"}
                          </Typography>
                          <Typography variant="caption" style={{color: theme.palette.secondary.main, padding: "0 1rem" }}>
                            {`•`}
                          </Typography>
                          <Typography variant="caption" style={{color: theme.palette.secondary.main }}>
                            {formatCountry(parkingSpot.location)}
                          </Typography>
                        </Box>
                      }
                      { (!parkingSpot.spot_active || !parkingSpot.available_today) &&
                        <Box pb={1} ml={8}>
                          <Typography variant="caption" style={{color: '#FF0000' }}>
                            {((!parkingSpot.spot_active && Math.ceil(((new Date()) - Date.parse(parkingSpot.spot_active_updated_at)) / (1000 * 3600 * 24)) <= 7) || !parkingSpot.available_today) ? t('common.texts.closed-today') : t('common.texts.closed-off-season') }
                          </Typography>
                        </Box>
                        }
                    </Box>
                    <Box display="flex" alignItems="end" justifyContent="center" pt={0.875}>
                      {
                        (!!booking) && (
                          <Box mx={1} display="flex" alignItems="center" justifyContent="center" minWidth={isMobile ? "10rem" : "6.5rem"} minHeight={"1.675rem"} style={{
                            padding: "0.1875rem 0.5rem 0.1875rem",
                            borderRadius: "1.0rem",
                            backgroundColor: theme.palette.primary.main
                          }}>
                            <Typography variant="h6" style={{color: "white"}}>{t("common.texts.checked-in")}</Typography>
                          </Box>
                        )
                      }
                      <Box style={{
                        padding: "0.25rem 1rem",
                        borderRadius: "16px",
                        background: "#F2F9FA",
                      }}>
                        <Typography variant="caption" color="primary">
                          {parkingSpot.tag}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )
            }
              <section className={classes.sectionMb}>
                <Box className={classes.attributesBox} pb={3}>
                  <Grid container spacing={2}>
                    {
                      getAttributes(parkingSpot) && getAttributes(parkingSpot).map(attribute => (
                        <Grid item xs={12} md={6}>
                          {
                            !isMobile ? (
                              <Card elevation={0}>
                                <Box pl={2} pr={1} py={1} display="flex" alignItems="center">
                                { attribute && returnIcon(attribute.icon) }
                                  <Box ml={1.75}>
                                    <Typography variant="h5" style={{paddingBottom: "0.125rem"}}>{ attribute ? t(getAttributeName(attribute)) : "" }</Typography>
                                    <Typography color={"secondary"} className={classes.caption2}>{ attribute ? t(`${getAttributeName(attribute)}-subtext`) : "" }</Typography>
                                  </Box>
                                </Box>
                              </Card>
                            ) : (
                              <Box py={0.5} display="flex" alignItems="center">
                              { attribute && (!attribute.color ? returnIcon(attribute.icon) : returnIcon(attribute.icon, attribute.color)) }
                                <Box ml={1.75}>
                                  <Typography variant="h5" style={{paddingBottom: "0.125rem"}}>{ attribute ? t(getAttributeName(attribute)) : "" }</Typography>
                                  <Typography color={"secondary"} className={classes.caption2}>{ attribute ? t(`${getAttributeName(attribute)}-subtext`) : "" }</Typography>
                                </Box>
                              </Box>
                            )
                          }
                        </Grid>
                      ))
                    }
                  </Grid>
                </Box>
                <Button variant="outlined" fullWidth={(!isMobile) ? false : true} onClick={() => isMobile ? setMobileAttributesOpen(true) : setAttributeDialogOpen(true)} startIcon={<ShowAllIcon fill={theme.palette.primary.main}/>}>
                  <Typography variant="button">
                    { t("details.show-all-attributes") }
                  </Typography>
                </Button>
              </section>
              <section className={classes.sectionMb}>
                <Box className={classes.borderBtm} pb={1} mb={2}>
                  <Typography variant="h3">
                    {t("details.description")}
                  </Typography>
                </Box>
                <Typography variant="body1" className={classes.multilineTypography}>
                  {parkingSpot.description || t("common.texts.description-default")}
                </Typography>
              </section>
 
              {!!parkingSpot.host && (
                  <section className={classes.sectionMb}>
                    <Box pb={3}>
                      <Box className={classes.borderBtm} pb={1}>
                        <Typography variant="h3">
                          { t("common.texts.host") }
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box display="flex" alignItems="flex-start">
                        <ProfilePictureWithPlaceholder src={parkingSpot.host?.profile_pic_thumbnail_url ? parkingSpot.host?.profile_pic_thumbnail_url : parkingSpot.host?.profile_pic_url} type="host" />
                        <Box>
                          <Typography variant="h3" className={classes.semiBold}>
                            {parkingSpot.host?.company_name ? parkingSpot.host?.company_name : parkingSpot.host?.full_name}
                          </Typography>
                          <Box display="flex" alignItems="center">
                            <Box className={classes.checkCircleIconBox} >
                              <VerifiedIcon fill={theme.palette.primary.main} className={classes.checkCircleIcon} />
                            </Box>
                            <Typography color="secondary" variant="h4">
                              { t("details.verified-user") }
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box py={3}>
                        { parkingSpot.host?.bio ? (
                          <Typography variant="body1">
                            {parkingSpot.host?.bio}
                          </Typography>
                        ) : <MissingDataPlaceholder /> }
                      </Box>
                      <Box pt={1}>
                        {
                          (token == null)
                          ?
                          (
                            <Button variant="outlined" disabled fullWidth={(!isMobile) ? false : true} startIcon={<ChatIcon />}>
                              <Box pl={1}>
                                <Typography variant="button">
                                  {t("details.contact-button-public")}
                                </Typography>
                              </Box>
                            </Button>
                          )
                          : (userType === "Guest" && !!booking) ?
                          (
                            <Button variant="outlined" fullWidth={(!isMobile) ? false : true} onClick={() => {
                              if (isMobile && parkingSpot.host?.phone != undefined){
                                contactTel();
                              }
                              else {
                                contactMailTo(parkingSpot.contact_email ? parkingSpot.contact_email : parkingSpot.host?.email);
                              }
                              }} startIcon={<ChatIcon />}>
                              <Typography variant="button">
                                { t("details.contact-button") }
                              </Typography>
                            </Button>
                          )
                          :
                          (
                            <Button variant="outlined" disabled fullWidth={(!isMobile) ? false : true} startIcon={<ChatIcon />}>
                              <Box pl={1}>
                                <Typography variant="button">
                                  { t("details.contact-button") }
                                </Typography>
                              </Box>
                            </Button>
                          )
                        }
                      </Box>
                    </Box>
                  </section>
               )}
            </Box>
            
            { (!isMobile) ? (
              <Box width="32%">
                <Box className={classes.checkInBox}>
                  <Card>
                    <Box>
                      <Box px={2} py={2} display="flex" alignItems="center" justifyContent="flex-start" className={classes.borderBtm}>
                        <Box flex="auto">
                          <Box maxWidth="14rem">
                            {
                              !!booking ? (
                                <Typography variant={smallDesktopTitleTag} >
                                  {booking.currency} {round((Number(booking.price) + Number(booking.tax_amount)), 2)} { t("common.texts.day")}
                                </Typography>
                              ) : (
                                <Typography variant={smallDesktopTitleTag} >
                                  {parkingSpot.currency} {round(grossPrice, 2)} { t("common.texts.day")}
                                </Typography>
                              )
                            }
                          </Box>
                          {
                            (parkingSpot.tax_amount > 0) &&
                            <Box maxWidth="16rem" pt={0.25}>
                              <Typography variant="body2" className={classes.stateTax}>
                                { t("details.includes") } {parkingSpot.currency} {Number(parkingSpot.tax_amount).toFixed(2)} { t("details.state-tax") } { (!!parkingSpot && parkingSpot.tourism_tax_type === "per_person") ? t("details.per-person") : t("details.flat-rate")}
                              </Typography>
                            </Box>
                          }
                        </Box>
                        { !!lat && !!lng &&
                          <Box minWidth={"7rem"}>
                            <Button
                              variant="outlined"
                              size="small"
                              className={classes.buttonXS}
                              onClick={handleShareButtonClick}
                            >
                              <ShareIcon className={classes.boxIcons} />
                            </Button>
                            <StyledMenu
                              anchorEl={anchorEl}
                              keepMounted
                              open={navMenuOpen}
                              onClose={handleShareButtonClose}
                            >
                              <a
                                className={classes.socialMenuItem}
                                href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                                target="_blank"
                              >
                                <MenuItem>
                                  <FacebookIcon className={classes.socialLinkIcon} />
                                  <ListItemText primary="Facebook" />
                                </MenuItem>
                              </a>
                              <a className={classes.socialMenuItem} >
                                <MenuItem onClick={() => {navigator.clipboard.writeText(window.location.href)}}>
                                  <LinkIcon className={classes.socialLinkIcon} />
                                  <ListItemText primary="Copy URL"/>
                                </MenuItem>
                              </a>
                            </StyledMenu>
                            <Button
                              variant="outlined"
                              className={classes.buttonXS}
                              size="small"
                              href={`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`}
                              target="_blank"
                            >
                              <LocationPin className={classes.boxIcons} />
                            </Button>
                          </Box>
                        }
                      </Box>
                      <Box px={2} py={2}>
                        <Box display={(!parkingSpot.enabled && !!parkingSpot.host) ? "none" : "inherit"}>
                          <Box display="flex" >
                            <Box flex="auto">
                              <Typography variant="h6">
                                { t("parking-spot.availability") }
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant="h6">
                                {!parkingSpot.spot_active ? `0/${parkingSpot.capacity}` : `${availableParkingSpaces}/${parkingSpot.capacity}`}
                              </Typography>
                            </Box>
                          </Box>
                          <Box width="100%">
                            {
                              (!!parkingSpot.available_today && !!parkingSpot.spot_active) ?
                              (unoccupiedPercentage === "100%") ? (
                                <span className={classes.unoccupiedSpacesBarEmpty} style={{width: unoccupiedPercentage}}/>
                              ) : (
                                <Box>
                                  <span className={(!!parkingSpot.available_today) ? classes.unoccupiedSpacesBar : classes.unoccupiedSpacesBarUnavailable} style={{width: unoccupiedPercentage}}/>
                                  <span className={(parkingSpot.capacity === parkingSpot.occupied) ? classes.occupiedSpacesBarFull : classes.occupiedSpacesBar} style={{width: occupiedPercentage}} />
                                </Box>
                              )
                              :
                              (
                                <span className={classes.unoccupiedSpacesBarUnavailable} style={{width: "100%"}}/>
                              )
                            }
                          </Box>
                        </Box>
                        {
                          !!booking && (
                            booking.single_night_stay ? (
                              <Box py={1}>
                                <Typography variant="body2">
                                  { t("check-in-box.check-out.single-night-line-1a") } { timeLeftTill(getNextCheckOutTime(new Date(booking.checkin), booking.time_zone), booking.time_zone) }{ t("check-in-box.check-out.single-night-line-1b") }
                                </Typography>
                              </Box>
                            ) : (
                              <Box py={1}>
                                <Typography variant="body2">
                                  {/* { t("check-in-box.check-out.multiple-night-line-1a") } { timeLeftTill(getNextCheckOutTime(new Date(booking.checkin), booking.time_zone), booking.time_zone) }{ t("check-in-box.check-out.multiple-night-line-1b") } */}
                                </Typography>
                              </Box>
                            )
                          )
                        }
                        {
                          (!booking) &&
                          <Box py={1}>
                            <Typography variant="body1">
                              {
                                availableParkingSpaces < 1 || !parkingSpot.enabled || unavailableToday ? t("check-in-box.not-available") : t("check-in-box.is-available")
                              }
                            </Typography>
                          </Box>
                        }
                        <Box pt={1}>
                          { (userType !== "Host" && userType !== "Guest" && userType !== "SubHost") ?
                          (
                            !!parkingSpot.host ? (
                              ( !!parkingSpot.ppc_link ? (
                                      <>
                                        <Button variant="contained" color="primary" fullWidth={true}
                                                className={classes.checkout}
                                                href={`${parkingSpot.ppc_link}`}
                                                target="_blank"
                                        >
                                          { t("common.btn.check-in.desktop") }
                                        </Button>
                                      </>
                                      ) : (
                                      <>
                                        <Button variant="contained" color="secondary" fullWidth={true}
                                                className={classes.checkout}
                                                component={Link}
                                                to={`/login?tag=${tag}`}
                                        >
                                          { t("common.btn.check-in.public") }
                                        </Button>
                                        {isCamigoPartnerBoxShown && <CamigoPartnerBox size="desktop" camigoLink={parkingSpot.camigo_link} />}
                                      </>
                                  )
                              )
                            ) : (
                              <Button variant="contained" color="primary" fullWidth={true}
                                className={classes.checkout}
                                component="a"
                                href={claimLink}
                              >
                                { t("common.btn.check-in.claimable") }
                              </Button>
                            )
                          )
                          :
                          booking ? (
                            <Button variant="contained" style={{backgroundColor: "#06002C"}} fullWidth={true}
                              className={classes.checkout}
                              disabled={userType === "Host" || userType === "SubHost"}
                              onClick={() => setCheckOutDialogOpen(true)}
                            >
                              { t("common.btn.check-out.desktop") }
                            </Button>
                          ) : (
                            <React.Fragment>
                              { (!!parkingSpot.ppc_link) ? (
                                  <Button variant="contained" color="primary" fullWidth={true}
                                          className={classes.checkout}
                                          href={`${parkingSpot.ppc_link}`}
                                          target="_blank"
                                  >
                                    {t("common.btn.check-in.desktop")}
                                  </Button>
                                ) : (
                                  <>
                                    <Button variant="contained" color="primary" fullWidth={true}
                                            disabled={userType === "Host" || userType === "SubHost" || !parkingSpot.enabled || availableParkingSpaces < 1 || (user && user.status === "suspended") || cardPaymentFailed}
                                            onClick={() => (userType === "Guest" && getPrimaryCar()) || grossPrice == 0 ?
                                                (() => {
                                                  setDaysSelectionDialogOpen(true)
                                                  setNumberOfDays(1)
                                                  setCheckinPersonsCount(1)
                                                })():
                                                setGuestProfileIncompleteDialogOpen(true)
                                            }
                                    >
                                      {
                                        (token == null)
                                            ? t("common.btn.check-in.public")
                                            : t("common.btn.check-in.desktop")
                                      }

                                    </Button>
                                  </>
                                )
                              }
                              {
                                (userType === "Host" || userType === "SubHost" || !parkingSpot.enabled || availableParkingSpaces < 1 || (user && user?.status === "suspended") || cardPaymentFailed) &&
                                <Box mt={2}>
                                  {
                                    (userType === "Host" || userType === "SubHost") &&
                                    <Typography variant="body1" className={classes.stateTax} style={{textAlign:"center"}}>
                                      { t("check-in-box.short-description.disabled-host") }
                                    </Typography>
                                  }
                                  {
                                    ((user && user.status === "suspended") || cardPaymentFailed) &&
                                    <Typography variant="body1" className={classes.stateTax} style={{textAlign:"center"}}>
                                      { t("check-in-box.short-description.disabled-pending-payment") }
                                    </Typography>
                                  }
                                  {
                                    !parkingSpot.enabled &&
                                    <Typography variant="body1" className={classes.stateTax} style={{textAlign:"center"}}>
                                      { t("check-in-box.short-description.disabled-parking-spot") }
                                    </Typography>
                                  }
                                  {
                                    (availableParkingSpaces < 1 && !unavailableToday) &&
                                    <Typography variant="body1" className={classes.stateTax} style={{textAlign:"center"}}>
                                      { t("check-in-box.short-description.disabled-availability") }
                                    </Typography>
                                  }
                                  {
                                    (unavailableToday) &&
                                    <Typography variant="body1" className={classes.stateTax} style={{textAlign:"center"}}>
                                      { t("check-in-box.short-description.disabled-unavailable-today") }
                                    </Typography>
                                  }
                                </Box>
                              }
                              {isCamigoPartnerBoxShown && <CamigoPartnerBox size="desktop" camigoLink={parkingSpot.camigo_link} />}
                            </React.Fragment>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                  <a
                  className={classes.socialMenuItem}
                  href="#"
                  target="_blank"
                  onClick={bootWithProps} 
                >
                  <Box px={1} py={1} display="flex" className="intercom-launcher" alignItems="center" justifyContent="center">
                    <Box className={classes.contactDesktop}>
                      <DialogIcon fill={theme.palette.primary.main} className={classes.cardIcons} />
                    </Box>
                    <Typography variant="h4" color="textPrimary">
                      { t("common.btn.check-in.problem") }
                    </Typography>
                  </Box>
                </a>
                </Box>
              </Box>
            ) : ( "" ) }
    
          </Box>
        </Box>
        
        <Box display="flex" flexDirection="column" >
          <section className={classes.sectionMb}>
            <Box>
              <Box pb={2} display="flex" flexDirection={(isMobile) ? "column" : "row"} alignItems={(isMobile) ? "start" : "center"}> 
                <Typography variant="h3" >
                  { t("map") }
                </Typography>
              </Box>

              <Box pr={0}>
                <Box pt={1} pb={3} width="100%">
                  <DetailsMap lat={lat} lng={lng} spot={parkingSpot} />
                </Box>
                <Button
                  variant="outlined"
                  fullWidth={(!isMobile) ? false : true}
                  href={`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`}
                  target="_blank"
                  startIcon={<LocationPin />}
                >
                  <Typography variant="button">
                    {t("google-maps-route")}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </section>
            { parkingSpot.activity_partners.length > 0 &&
            <section className={classes.sectionMb}>
              <Typography variant="h3">
                {t("common.texts.activities")}
              </Typography>
              <Box>
              { parkingSpot.activity_partners.map((partner) => {
                  return (
                    <Box marginBottom="4rem">
                      <Box style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      margin: "0.5rem",
                      }}>
                        <Typography variant="caption">
                          Partner
                        </Typography>
                        <div>
                          <img src={partner.logo} style={{width:"3.25rem", height: "3.25rem"}} alt={partner.name} />
                        </div>
                      </Box>
                      <Box
                        className={ classes.customScrollBar }
                        style={isSm ? {
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      } : {
                        display: "flex",
                        flexDirection: "row",
                        overflowX: "scroll",
                        maxHeight: "24rem",
                      }}>
                      {partner.offers.map((offer) => {
                        return (
                          <div style={isSm ? {
                            justifySelf: "stretch",
                            borderRadius: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            margin: "0.5rem",
                            cursor: "pointer",
                            gap: "1rem",
                            backgroundColor: "#fff"
                          } : {
                            justifySelf: "stretch",
                            borderRadius: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            minWidth: "calc(50% - 1rem)",
                            margin: "0.5rem",
                            cursor: "pointer",
                            gap: "1rem",
                            backgroundColor: "#fff"
                          }}
                          onClick={()=> {
                            window.open(offer.link, "_blank");
                          }}
                          >
                            <div 
                            style={isSm ? {
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              borderRadius: "1rem",
                              overflow: "hidden",
                              aspectRatio: "16/9",
                            } : { 
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              borderRadius: "1rem",
                              overflow: "hidden",
                              height: "100%",
                              
                            }}>
                              <img src={offer.cover_image} style={{ width: "100%" , height: "100%", objectFit: "cover", borderRadius: "1rem", }} alt={offer.caption} />
                            </div>
                            <Typography variant="caption" style={{
                              fontWeight: "bold",
                              padding: "0.5rem",
                              marginBottom: "1rem"
                            }} >
                              {offer.caption}
                            </Typography>
                          </div>
                        )
                      })}
                      </Box>
                      
                    </Box>
                    

                  )
              })}
              </Box>
            </section>
            }
          <section className={classes.sectionMb}>
            <Box display="flex" alignItems="center">
              <StarIcon />
              <Typography variant="h3" pl={2}>
                {parkingSpot.review_count > 0 ? (Math.round(parkingSpot.avg_rating * 10) / 10).toFixed(1) : "N/A"} ({parkingSpot.review_count} {t("reviews")})
              </Typography>
            </Box>

            <Box py={(isMobile && reviews && reviews.length > 0) ? 2 : 0} mt={(isMobile && reviews && reviews.length > 0) ? 3 : 0}>
              { reviews && reviews.length > 0 ? (
                <Grid container spacing={4} className={classes.reviewGridContainer}>
                { (reviews.length > 0) &&
                  reviews.map(review => {
                    return (!isMobile && review.guest !== undefined) ? (
                      <Grid item sm={6}>
                        <Card elevation={0}>
                          <Box height={"13.5rem"} p={3}>
                            <Box key={review.id}>
                              <Box display="flex" alignItems="center">
                                <ProfilePictureWithPlaceholder src={review.guest.profile_pic_thumbnail_url ? review.guest.profile_pic_thumbnail_url : review.guest.profile_pic_url} type="review" />
                                <Box flex="auto">
                                  <Typography variant="h4">
                                    {review.guest.full_name}
                                  </Typography>
                                  <Box display={!!review.guest.pioneer ? "flex" : "none"} alignItems="center">
                                    <Box className={classes.checkCircleIconBox} >
                                      <SvgIcon component={Icons.AwardPioneer} className={classes.svgIconPioneer} viewBox="-5 0 150 150"/>
                                    </Box>
                                    <Typography color="secondary" variant="h6">
                                      Scout
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box style={{
                                  position: "relative",
                                }}>
                                  <Box style={{
                                    position: "absolute",
                                    top: "-2rem",
                                    right: "0",
                                    width: "10rem",
                                    textAlign: "right",
                                  }}>
                                    <span style={{
                                    color: "rgb(153, 153, 153)"
                                    }}>
                                      {(new Date(review.created_at)).toLocaleDateString(i18next.language || 'en', {
                                        year: '2-digit',
                                        month: 'short'
                                      })}
                                      </span>
                                  </Box>
                                  <Box display="flex" alignItems="center">
                                    <StarIcon />
                                    <Typography variant="caption" className={classes.reviewCaption}>
                                      {review.rating}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Box pt={3} pb={1}>
                                <Typography variant="body1" style={{
                                  height: "4.125rem",
                                  overflow: "hidden"
                                }}>
                                  {truncateString(review.description, 120)}
                                </Typography>
                                {
                                  (review.description !== truncateString(review.description, 120)) &&
                                  <Box pt={1} onClick={() => handleFullReviewDialog(review)}>
                                    <Typography variant="button" style={{color: `${theme.palette.primary.main}`, fontWeight: 500, cursor:"pointer"}}>
                                      {t("common.texts.show-more")}
                                    </Typography>
                                  </Box>
                                }
                              </Box>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                    ) : (
                      (review.guest !== undefined) ?
                      <Box key={review.id} mt={1} mb={4} mx={2} className={classes.borderBtmReview} width="100%">
                        <Box display="flex" alignItems="center" mx={0.25}>
                          <ProfilePictureWithPlaceholder src={review.guest.profile_pic_thumbnail_url ? review.guest.profile_pic_thumbnail_url : review.guest.profile_pic_url} type="review" />
                          <Box flex="auto">
                            <Typography variant="h4">
                              { review.guest.full_name.length > 15 ? `${review.guest.full_name.substring(0, 15)}...` : review.guest.full_name }
                            </Typography>
                            <Box display={!!review.guest.pioneer ? "flex" : "none"} alignItems="center" height="1.25rem">
                              <Box className={classes.checkCircleIconBox}>
                                <SvgIcon component={Icons.AwardPioneer} className={classes.svgIconPioneer} viewBox="-5 0 150 150"/>
                              </Box>
                              <Typography color="secondary" variant="h6">
                                Scout
                              </Typography>
                            </Box>
                          </Box>
                          <Box style={{
                              position: "relative",
                            }}>
                              <Box style={{
                                position: "absolute",
                                top: "-2rem",
                                right: "0",
                                width: "10rem",
                                textAlign: "right",
                                
                              }}>
                                <span style={{
                                  color: "rgb(153, 153, 153)"
                                }}>
                                   {(new Date(review.created_at)).toLocaleDateString(i18next.language || 'en', {
                                        year: '2-digit',
                                        month: 'short'
                                      })}
                                </span>
                              </Box>
                              <Box display="flex" alignItems="center">
                                <Box>
                                  <StarIcon />
                                </Box>
                                <Typography variant="caption" className={classes.reviewCaption}>
                                  {review.rating}
                                </Typography>
                              </Box>
                            </Box>
                        </Box>
                        <Box pt={3.5} pb={4.5} mx={0.25}>
                          <Typography variant="body1">
                            {truncateString(review.description, 120)}
                          </Typography>
                          {
                            (review.description !== truncateString(review.description, 120)) &&
                            <Box pt={1.5} onClick={() => handleFullReviewDialog(review)}>
                              <Typography variant="button" style={{color: `${theme.palette.primary.main}`, fontSize: "0.875rem",fontWeight: 500, cursor:"pointer"}}>
                                {t("common.texts.show-more")}
                              </Typography>
                            </Box>
                          }
                        </Box>
                      </Box>
                      :
                      <Box display="none" />
                    )
                  })
                }
                </Grid>)
              : (<Box py={3}><MissingDataPlaceholder /></Box>)
            }
            </Box>
            <Box>
            {
              (!!pagy && pagy.page_url !== pagy.last_url) ? (
                <Button variant="outlined" fullWidth={(!isMobile) ? false : true} onClick={handleReviewsNextPage} startIcon={<ShowAllIcon fill={theme.palette.primary.main}/>}>
                  <Typography variant="button">
                    { t("review-box.more-reviews") }
                  </Typography>
                </Button>
              ) : (
                <Button variant="outlined" disabled fullWidth={(!isMobile) ? false : true} onClick={handleReviewsNextPage} startIcon={<ShowAllIcon fill={theme.palette.primary.main}/>}>
                  <Typography variant="button">
                    { t("no-more-reviews") }
                  </Typography>
                </Button>
              )
            }
            </Box>
          </section>

          { !isMobile && attributeDialogOpen &&
            <Dialog open={attributeDialogOpen} onClose={
              () => setAttributeDialogOpen(false)
            } aria-labelledby="attribute-dialog-title" >
              <Box mx={0} mb={isMobile ? 2 : 0} minWidth={!isMobile ? "30rem" : "initial"}>
                <DialogTitle
                  id="attribute-dialog-title"
                  className={classes.dialogTitleBorderBottom}
                  classes={{
                    root: classes.dialogTitleRoot,
                  }}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h2">{t("spot-attributes.title")}</Typography>
                    <Button variant="outlined" size="small" onClick={() => {setAttributeDialogOpen(false); setReviewDialogOpen(null)}}>
                      <CloseIcon flex="auto" color="primary" />
                    </Button>
                  </Box>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                  { getAllAttributes() }
                </DialogContent>
              </Box>
            </Dialog>
          }
          {
            isMobile && mobileAttributesOpen && (
              <Box className={classes.mobileAttributesBox} position="relative" pb={2}>
                <Box px={3} display="flex" justifyContent="space-between" alignItems="center" className={classes.mobileAttributesTitle}>
                  <Typography variant="h2">{t("spot-attributes.title")}</Typography>
                  <CloseIcon flex="auto" style={isMobile ? {cursor: "pointer", color: "#999", fontSize:"1.375rem", marginTop: "0.25rem"} : {cursor: "pointer", color: "#999", fontSize:"1.25rem", marginTop: "0.1rem"}} onClick={() => {setMobileAttributesOpen(false)}} />
                </Box>
                <Box px={3} marginTop="5.25rem">
                  { getAllAttributes() }
                </Box>
              </Box>
            )
          }
          {
            isMobile && (
              <Box className={(mobileBottomPanel && !enableReview) ? classes.mobileBottomPanelBoxExpanded : !!booking ? classes.mobileBottomPanelBoxBooking : classes.mobileBottomPanelBox}>
                {
                  (!mobileBottomPanel || enableReview) ? (
                    <Box px={2} pt={1.875} pb={30} display="flex" alignItems="center">
                      <Box flex="auto">
                        {
                          !booking ? (
                            <Typography variant="h5" className={classes.mobileBottomPanelTitle} >
                              {parkingSpot.currency} {round(grossPrice, 2)} { t("common.texts.day") }
                            </Typography>
                          ) : (
                            <Box display="flex">
                              <Box display="flex" className={classes.cardCheckedInChip} justifyContent="center" alignItems="center" mr={1} mt={0.125}>
                                <CheckIcon className={classes.checkIcon} />
                              </Box>
                              <Typography variant="h4" >
                                { t("common.texts.checked-in") }
                              </Typography>
                            </Box>
                          )
                        }
                        {
                          !booking ?
                            <React.Fragment>
                              <Box width="100%" mt={0.25} pr={2}>
                              {
                                (!!parkingSpot.available_today && !!parkingSpot.spot_active) ?
                                (unoccupiedPercentage === "100%") ? (
                                  <span className={classes.unoccupiedSpacesBarEmpty} style={{width: unoccupiedPercentage}}/>
                                ) : (
                                  <Box>
                                    <span className={(!!parkingSpot.available_today) ? classes.unoccupiedSpacesBar : classes.unoccupiedSpacesBarUnavailable} style={{width: unoccupiedPercentage}}/>
                                    <span className={(parkingSpot.capacity === parkingSpot.occupied) ? classes.occupiedSpacesBarFull : classes.occupiedSpacesBar} style={{width: occupiedPercentage}} />
                                  </Box>
                                )
                                :
                                (
                                  <span className={classes.unoccupiedSpacesBarUnavailable} style={{width: "100%"}}/>
                                )
                              }
                            </Box>
                            <Box mb={0.75}>
                              <Typography variant="h6" color={"secondary"} className={classes.mobileBottomPanelAvailability}>
                                {t("parking-spot.availability")}: {availableParkingSpaces}/{parkingSpot.capacity}
                              </Typography>
                            </Box>
                          </React.Fragment>
                          :
                          <Box mt={0.75}>
                            {
                              booking && !booking.single_night_stay ?
                              <Typography variant="body1" color={"secondary"} className={classes.checkInDetailsText}>
                                { moment(booking.checkin).zone(booking.checkin).format('DD.MM.YYYY') } - { moment(booking.checkin).add(booking.number_of_nights, 'days').zone(booking.checkin).format('DD.MM.YYYY') } ({ booking.number_of_nights } { booking.number_of_nights > 1 ? t("details.nights") : t("details.night") })
                              </Typography>
                              :
                              <Typography variant="body1" color={"secondary"} className={classes.checkInDetailsText}>
                                { t("details.auto-checkout") } { timeLeftTill(getNextCheckOutTime(new Date(booking.checkin), booking.time_zone), booking.time_zone) }
                              </Typography>
                            }
                          </Box>
                        }
                      </Box>
                      <Box ml={0.125}>
                        {
                          !booking ? ((userType === "Guest" && parkingSpot.enabled && availableParkingSpaces > 0) || token == undefined) ?
                          <Button variant="contained" color="primary" className={classes.checkout} style={{padding: "0.5rem 1rem 0.5rem"}} disabled={userType === "Host" || userType === "SubHost"} onClick={() => setMobileBottomPanel(true)}>
                            <Typography variant="button">
                              { t("details.to-check-in") }
                            </Typography>
                          </Button>
                          :
                          <Button variant="contained" className={classes.checkout} style={{padding: "0.5rem 1rem 0.5rem", background: `${theme.palette.secondary.light}`}} onClick={() => setMobileBottomPanel(true)}>
                            <Typography variant="button" color={"secondary"}>
                              { t("details.to-check-in") }
                            </Typography>
                          </Button>
                          :
                          <Button variant="contained" className={classes.checkout} style={{padding: "0.5rem 1rem 0.5rem", background: "#06002C"}} disabled={userType === "Host" || userType === "SubHost"} onClick={() => setMobileBottomPanel(true)}>
                            <Typography variant="button">
                              { t("details.to-check-out") }
                            </Typography>
                          </Button>
                        }
                      </Box>
                    </Box>
                  ) : (
                    <Box px={2} pt={2.25} pb={8} position="relative" height="100%">
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        {
                          !booking ?
                            <Typography className={classes.mobileBottomPanelTitle} style={{height: "1.875rem"}}>
                              {parkingSpot.currency} {round(grossPrice, 2)} { t("common.texts.day") }
                            </Typography>
                          :
                            <Typography variant="h4">
                              {t("details.checked-in-with")} <b>{ getPrimaryCar() && getPrimaryCar().name }</b>
                            </Typography>
                        }
                        <CloseIcon flex="auto" style={isMobile ? {cursor: "pointer", color: "#999", fontSize:"1.375rem", marginTop: "0.125rem", marginRight: "0.25rem"} : {cursor: "pointer", color: "#999", fontSize:"1.25rem", marginTop: "0.1rem"}} onClick={() => {setMobileBottomPanel(false)}} />
                      </Box>
                      {
                        (parkingSpot.tax_amount > 0 && !booking) &&
                        <Box pt={0.125}>
                          <Typography variant="body2" color={"secondary"}>
                            { t("details.includes") } {parkingSpot.currency} {Number(parkingSpot.tax_amount).toFixed(2)} { t("details.state-tax") } { (!!parkingSpot && parkingSpot.tourism_tax_type === "per_person") ? t("details.per-person") : t("details.flat-rate")}
                          </Typography>
                        </Box>
                      }
                      <Box pt={!booking ? 0 : 1.75}>
                      {
                        !booking && (
                          <React.Fragment>
                            <Box width="100%" mt={1} mb={0.25}>
                              {
                                (!!parkingSpot.available_today && !!parkingSpot.spot_active) ?
                                (unoccupiedPercentage === "100%") ? (
                                  <span className={classes.unoccupiedSpacesBarEmpty} style={{width: unoccupiedPercentage}}/>
                                ) : (
                                  <Box>
                                    <span className={(!!parkingSpot.available_today) ? classes.unoccupiedSpacesBar : classes.unoccupiedSpacesBarUnavailable} style={{width: unoccupiedPercentage}}/>
                                    <span className={(parkingSpot.capacity === parkingSpot.occupied) ? classes.occupiedSpacesBarFull : classes.occupiedSpacesBar} style={{width: occupiedPercentage}} />
                                  </Box>
                                )
                                :
                                (
                                  <span className={classes.unoccupiedSpacesBarUnavailable} style={{width: "100%"}}/>
                                )
                              }
                            </Box>
                            <Box mb={0.75}>
                              <Typography variant="body2">
                                {t("parking-spot.availability")}: {availableParkingSpaces}/{parkingSpot.capacity}
                              </Typography>
                            </Box>
                          </React.Fragment>
                        )
                      }
                        <Box py={1} pt={1.5}>
                          { (userType !== "Host" && userType !== "Guest" && userType !== "SubHost") ?
                            (
                              (!!parkingSpot.ppc_link) ? (
                                  <Button variant="contained" color="primary" fullWidth={true}
                                          className={classes.checkout}
                                          href={`${parkingSpot.ppc_link}`}
                                          target="_blank"
                                  >
                                    {t("common.btn.check-in.desktop")}
                                  </Button>
                              ) : (
                                  !!parkingSpot.host ? (
                                    <Button variant="contained" color="primary" fullWidth={true}
                                      className={classes.checkout}
                                      component={Link}
                                      to={`/login?tag=${tag}`}
                                    >
                                      { t("common.btn.check-in.public") }
                                    </Button>
                                  ) : (
                                    <Button variant="contained" color="primary" fullWidth={true}
                                      className={classes.checkout}
                                      component="a"
                                      href={claimLink}
                                    >
                                      { t("common.btn.check-in.claimable") }
                                    </Button>
                                  )
                              )
                            )
                            :
                            booking ? (
                              isMobile ? (
                                <Box position="relative">
                                  <SwipeButton
                                    mainText={ isSmallMobile ? (
                                      <Typography variant="body1" style={{color: theme.palette.secondary.main, paddingLeft: "1.625rem"}}>
                                        {t("common.btn.check-out.mobile")}
                                      </Typography>
                                      ) : (
                                      <Typography variant="subtitle1" style={{color: theme.palette.secondary.main}}>
                                        {t("common.btn.check-out.mobile")}
                                      </Typography>
                                      )
                                    }
                                    classList="swipe-button-root-checkout"
                                    caretClassList="swipe-button-caret-checkout"
                                    overlayClassList="swipe-button-overlay-checkout"
                                    caret={<SliderCaretIcon width={"inherit"} height={24} viewBox="0 0 120 120" />}
                                    onSwipeDone={() => { setCheckOutDialogOpen(true)}}
                                    reset={resetSwipeButton}
                                  />
                                  <Box position="absolute" style={{
                                    background: "white",
                                    top: "50%",
                                    right: "6%",
                                    transform: "translateY(-50%)",
                                    height: "1.375rem",
                                    width: "1.375rem",
                                    borderRadius: "0.25rem"
                                  }}>
                                    <img src={roadIcon} alt="" style={{
                                    width: "1.6875rem",
                                    height: "1.6875rem",
                                    paddingBottom: "0.25rem"
                                  }} />
                                  </Box>
                                </Box>
                              ) : (
                                <Button variant="contained" color="secondary" fullWidth={true}
                                  className={classes.checkout}
                                  disabled={userType === "Host" || userType === "SubHost"}
                                  onClick={() => setCheckOutDialogOpen(true)}
                                >
                                  { t("common.btn.check-out.desktop") }
                                </Button>
                              )
                            ) : (
                              <React.Fragment>
                                {
                                  (!!parkingSpot.ppc_link) ? (
                                      <Button variant="contained" color="primary" fullWidth={true}
                                              className={classes.checkout}
                                              href={`${parkingSpot.ppc_link}`}
                                              target="_blank"
                                      >
                                        {t("common.btn.check-in.desktop")}
                                      </Button>
                                  ) : (
                                  (isMobile && userType === "Guest" && parkingSpot.enabled && availableParkingSpaces > 0 && user && user.status !== "suspended") && !cardPaymentFailed ?
                                    (
                                      (!!parkingSpot.ppc_link) ? (
                                          <Button variant="contained" color="primary" fullWidth={true}
                                                  className={classes.checkout}
                                                  href={`${parkingSpot.ppc_link}`}
                                                  target="_blank"
                                          >
                                            {t("common.btn.check-in.desktop")}
                                          </Button>
                                      ) : (
                                      <Box position="relative">
                                        <SwipeButton
                                          mainText={ isSmallMobile ? (
                                            <Typography variant="body1" style={{color: "white", paddingLeft: "1.625rem"}}>
                                              {t("common.btn.check-in.mobile")}
                                            </Typography>
                                            ) : (
                                            <Typography variant="subtitle1" style={{color: "white"}}>
                                              {t("common.btn.check-in.mobile")}
                                            </Typography>
                                            )
                                          }
                                          classList="swipe-button-root"
                                          caretClassList="swipe-button-caret"
                                          overlayClassList="swipe-button-overlay"
                                          caret={<SliderCaretIcon width={"inherit"} height={24} viewBox="0 0 120 120" />}
                                          onSwipeDone={() => (userType === "Guest" && getPrimaryCar()) || grossPrice == 0 ?
                                          (() => {
                                                setDaysSelectionWheelOpen(true)
                                                setNumberOfDays(1)
                                                setCheckinPersonsCount(1)
                                              })() :
                                            setGuestProfileIncompleteDialogOpen(true)
                                          }
                                          reset={resetSwipeButton}
                                        />
                                        <Box position="absolute" style={{
                                          background: "white",
                                          top: "50%",
                                          right: "6%",
                                          transform: "translateY(-50%)",
                                          height: "1.375rem",
                                          width: "1.375rem",
                                          borderRadius: "0.25rem"
                                        }}>
                                          <LocalParkingIcon style={{
                                          color: theme.palette.primary.main,
                                          fontSize: "1.375rem",
                                          padding: "0.0625rem 0 0.0625rem 0.0625rem"
                                        }} />
                                        </Box>
                                      </Box>
                                      )
                                    ) : (
                                    (token == null)
                                      ?
                                      <Button variant="contained" color="primary" fullWidth={true}
                                        disabled={userType === "Host" || userType === "SubHost" || !parkingSpot.enabled || availableParkingSpaces < 1 || (user && user.status === "suspended") || cardPaymentFailed}
                                        onClick={() => (userType === "Guest" && getPrimaryCar()) || grossPrice == 0 ?
                                        setDaysSelectionDialogOpen(true) :
                                          setGuestProfileIncompleteDialogOpen(true)
                                        }
                                      >
                                        t("common.btn.check-in.public")
                                      </Button>
                                      :
                                      <Box position="relative">
                                        <SwipeButton
                                          mainText={
                                            <Typography variant={isSmallMobile ? "body1" : "subtitle1"} className={isVerySmallMobile ? classes.checkInSliderTextVerySmall : isSmallMobile ? classes.checkInSliderTextSmall : classes.checkInSliderText}>
                                              {
                                                (userType === "Host" || userType === "SubHost") ?
                                                  t("check-in-box.short-description.disabled-host")
                                                :
                                                ((user && user.status === "suspended") || cardPaymentFailed) ?
                                                  t("check-in-box.short-description.disabled-pending-payment")
                                                :
                                                (!parkingSpot.enabled) ?
                                                  t("check-in-box.short-description.disabled-parking-spot")
                                                :
                                                (unavailableToday) ?
                                                  t("check-in-box.short-description.disabled-unavailable-today")
                                                :
                                                (availableParkingSpaces < 1 && !unavailableToday) ?
                                                  t("check-in-box.short-description.disabled-availability")
                                                :
                                                t("details.currently-unavailable")
                                              }
                                            </Typography>
                                          }
                                          classList="swipe-button-root-unavailable"
                                          caretClassList="swipe-button-caret-unavailable"
                                          overlayClassList="swipe-button-overlay-unavailable"
                                          caret={<SliderCaretIcon width={"inherit"} height={24} viewBox="0 0 120 120" />}
                                          reset={resetSwipeButton}
                                        />
                                        <Box position="absolute" style={{
                                          background: `${theme.palette.secondary.main}`,
                                          top: "50%",
                                          right: "6%",
                                          transform: "translateY(-50%)",
                                          height: "1.375rem",
                                          width: "1.375rem",
                                          borderRadius: "0.25rem"
                                        }}>
                                          <LocalParkingIcon style={{
                                          color: "#E7F0F0",
                                          fontSize: "1.375rem",
                                          padding: "0.0625rem 0 0.0625rem 0.0625rem"
                                        }} />
                                        </Box>
                                      </Box>
                                    )
                                  )
                                }
                              </React.Fragment>
                            )
                          }
                        </Box>
                        {
                          !!booking && (
                            <Box mt={1.5}>
                              <Typography variant="h4">
                                 {t("details.remaining-time")}: <b>{(() => {
                                  const endTime = moment().zone(booking.checkin).add(booking.number_of_nights, 'days').set({hour:12,minute:0,second:0,millisecond:0})
                                  const duration = moment.duration(endTime.diff(moment().zone(booking.checkin)))

                                  return `${duration.days() > 0 ? duration.days() + `${duration.days() === 1 ? ` ${t("details.day")} ` : ` ${t("details.days")} `}` : ''}${duration.hours() > 0 ? duration.hours() + 'h ' : ''}${duration.minutes() > 0 ? duration.minutes() + ' Min' : ''}`
                                 })()
                                 }</b>
                              </Typography>
                               <Typography variant="caption"  style={{ color: "#8E8E99"}}>
                                 {t("details.amount-due-on-checkout")}: <b>{booking.currency} {booking.total_amount}</b>
                              </Typography>
                            </Box>
                          )
                        }
                        <Box pt={1.25}>
                          {
                            !!booking ? (
                              booking.single_night_stay ? (
                                <Typography variant="body1">
                                  { t("check-in-box.check-out.single-night-line-1a") } { timeLeftTill(getNextCheckOutTime(new Date(booking.checkin), booking.time_zone), booking.time_zone) }{ t("check-in-box.check-out.single-night-line-1b") }
                                </Typography>
                              ) : (
                                <Typography variant="body1">
                                  {/* { t("check-in-box.check-out.multiple-night-line-1a") } { timeLeftTill(getNextCheckOutTime(new Date(booking.checkin), booking.time_zone), booking.time_zone) }{ t("check-in-box.check-out.multiple-night-line-1b") } */}
                                </Typography>
                              )) : (
                            <Typography variant="body1">
                              {
                                availableParkingSpaces < 1 || !parkingSpot.enabled || !parkingSpot.spot_active || unavailableToday ? t("check-in-box.not-available") : t("check-in-box.is-available")
                              }
                            </Typography>
                          )}
                        </Box>
                        {isCamigoPartnerBoxShown && <CamigoPartnerBox size="mobile" camigoLink={parkingSpot.camigo_link} />}
                      </Box>
                      <a
                          className={classes.socialMenuItem}
                          href="#"
                          target="_blank"
                        >
                          <Box className="intercom-launcher" style={{borderTop: `0.0625rem solid ${theme.palette.secondary.light}`, bottom: 0}} position="absolute" width="100%" bottom={0} left={0} display="flex" py={1.25} mb={0.5} justifyContent="center" alignItems="center">
                            <Box mr={1.25}>
                              <DialogIcon fill={theme.palette.primary.main} className={classes.helpIconMobile} />
                            </Box>
                            <Typography variant="h4" color="textPrimary">
                              { t("common.btn.check-in.problem") }
                            </Typography>
                          </Box>
                        </a>
                    </Box>
                  )
                }
              </Box>
            )
          }
          { fullReviewDialog &&
            <Dialog open={isFullReviewDialogOpen} onClose={
              () => setIsFullReviewDialogOpen(false)
            } aria-labelledby="full-review-dialog-title" >
              <Box>
                <DialogTitle
                  id="full-review-dialog-title"
                  className={classes.dialogTitleBorderBottom}
                  classes={{
                    root: classes.dialogTitleRoot,
                  }}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h2">User Review</Typography>
                    <Button variant="outlined" size="small" onClick={() => {setIsFullReviewDialogOpen(false); setReviewDialogOpen(null)}}>
                      <CloseIcon flex="auto" color="primary" />
                    </Button>
                  </Box>
                </DialogTitle>

                <DialogContent className={classes.dialogContentReview}>
                  {
                    (isMobile) ?
                    (
                      <Box mt={isMobile ? 3 : 0}>
                        <Box key={fullReviewDialog.id}>
                          <Box display="flex" alignItems="center">
                            <ProfilePictureWithPlaceholder src={fullReviewDialog.guest.profile_pic_thumbnail_url ? fullReviewDialog.guest.profile_pic_thumbnail_url : fullReviewDialog.guest.profile_pic_url} type="review" />
                            <Box flex="auto">
                              <Typography variant="h4" className={classes.semiBold}>
                                {fullReviewDialog.guest.full_name}
                              </Typography>
                              <Box display={!!fullReviewDialog.guest.pioneer ? "flex" : "none"} alignItems="center">
                                <Box className={classes.checkCircleIconBox} >
                                  <SvgIcon component={Icons.AwardPioneer} className={classes.svgIconPioneer} viewBox="-5 0 150 150"/>
                                </Box>
                                <Typography color="secondary" variant="caption">
                                  Scout
                                </Typography>
                              </Box>
                            </Box>
                            <Box display="flex" alignItems="center">
                              <StarIcon />
                              <Typography variant="caption" className={classes.reviewCaption}>
                                {fullReviewDialog.rating}
                              </Typography>
                            </Box>
                          </Box>
                          <Box pt={3} pb={1}>
                            <Typography variant="body1">
                              {fullReviewDialog.description}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        <Card elevation={0}>
                          <Box p={3}>
                            <Box key={fullReviewDialog.id}>
                              <Box display="flex" alignItems="center">
                                <ProfilePictureWithPlaceholder src={fullReviewDialog.guest.profile_pic_thumbnail_url ? fullReviewDialog.guest.profile_pic_thumbnail_url : fullReviewDialog.guest.profile_pic_url} type="review" />
                                <Box flex="auto">
                                  <Typography variant="h4">
                                    {fullReviewDialog.guest.full_name}
                                  </Typography>
                                  <Box display={!!fullReviewDialog.guest.pioneer ? "flex" : "none"} alignItems="center">
                                    <Box className={classes.checkCircleIconBox} >
                                      <SvgIcon component={Icons.AwardPioneer} className={classes.svgIconPioneer} viewBox="-5 0 150 150"/>
                                    </Box>
                                    <Typography color="secondary" variant="caption">
                                      Scout
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box display="flex" alignItems="center">
                                  <StarIcon />
                                  <Typography variant="caption">
                                    {fullReviewDialog.rating}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box pt={3} pb={1}>
                                <Typography variant="body1">
                                  {fullReviewDialog.description}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Card>
                      </Box>
                    )
                  }
                </DialogContent>
              </Box>
            </Dialog>
          }

          { reviewForBooking && reviewForBooking.id &&
            <Dialog open={isAlreadyReviewedDialogOpen} onClose={
              () => {setIsAlreadyReviewedDialogOpen(false); setEnableReview(false)}
            } aria-labelledby="already-reviewed-dialog-title" >
              <Box mb={isMobile ? 2 : 4}>
                <DialogTitle
                  id="already-reviewed-dialog-title"
                  className={classes.dialogTitleBorderBottom}
                  classes={{
                    root: classes.dialogTitleRoot,
                  }}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    { t("review-box.already-done") }
                    <CloseIcon flex="auto" style={isMobile ? {cursor: "pointer", color: "#999", fontSize:"1.375rem", marginTop: "0.125rem", marginLeft: "0.5rem"} : {cursor: "pointer", color: "#999", fontSize:"1.25rem", marginTop: "0.1rem"}} onClick={() => {setIsAlreadyReviewedDialogOpen(false); setEnableReview(false)}} />
                  </Box>
                </DialogTitle>

                <DialogContent className={classes.dialogContent} style={ isSmallMobile ? {width: "80vw"} : (isMobile) ? {minWidth: "20rem"} : {minWidth: "24rem"}}>
                  {
                    (isMobile) ?
                    (
                      <Box mt={isMobile ? 3 : 0} mb={1}>
                        <Box key={reviewForBooking.id}>
                          <Box display="flex" alignItems="center">
                            <Box flex="auto">
                              <Typography variant="h6" className={classes.semiBold}>
                                {reviewForBooking.guest.full_name}
                              </Typography>
                              <Box display={!!reviewForBooking.guest.pioneer ? "flex" : "none"} alignItems="center">
                                <Box className={classes.checkCircleIconBox} >
                                  <SvgIcon component={Icons.AwardPioneer} className={classes.svgIconPioneer} viewBox="-5 0 150 150"/>
                                </Box>
                                <Typography color="secondary" variant="h6">
                                  Scout
                                </Typography>
                              </Box>
                            </Box>
                            <Box display="flex" alignItems="center">
                              <StarIcon />
                              <Typography variant="body2" className={classes.semiBold}>
                                {reviewForBooking.rating}
                              </Typography>
                            </Box>
                          </Box>
                          <Box pt={3} pb={1}>
                            <Typography variant="body2">
                              {reviewForBooking.description}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box mt={1}>
                        <Card elevation={0}>
                          <Box p={3}>
                            <Box key={reviewForBooking.id}>
                              <Box display="flex" alignItems="center">
                                <Box flex="auto">
                                  <Typography variant="h6" className={classes.semiBold}>
                                    {reviewForBooking.guest.full_name}
                                  </Typography>
                                  <Box display={!!reviewForBooking.guest.pioneer ? "flex" : "none"} alignItems="center">
                                    <Box className={classes.checkCircleIconBox} >
                                      <SvgIcon component={Icons.AwardPioneer} className={classes.svgIconPioneer} viewBox="-5 0 150 150"/>
                                    </Box>
                                    <Typography color="secondary" variant="h6">
                                      Scout
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box display="flex" alignItems="center">
                                  <StarIcon />
                                  <Typography variant="body2" className={classes.semiBold}>
                                    {reviewForBooking.rating}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box pt={3} pb={1}>
                                <Typography variant="body2">
                                  {reviewForBooking.description}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Card>
                      </Box>
                    )
                  }
                </DialogContent>
              </Box>
            </Dialog>
          }

          { (enableReview && !token && userType !== "Guest") &&
            <Dialog open={isEnableReviewLoginDialogOpen} onClose={() => {
              setIsEnableReviewLoginDialogOpen(false);
              setEnableReview(false);
            }} aria-labelledby="login-dialog-title" >
              <Box mb={isMobile ? 2 : 4} width={!isMobile ? "19rem" : "initial"}>
                <DialogTitle
                  id="login-dialog-title"
                  className={classes.dialogTitle}
                  classes={{
                    root: classes.dialogTitleRoot,
                  }}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    {t("details.sign-in-to-review")}
                    <CloseIcon flex="auto" style={isMobile ? {cursor: "pointer", color: "#999", fontSize:"1.375rem", marginTop: "0.25rem"} : {cursor: "pointer", color: "#999", fontSize:"1.25rem", marginTop: "0.1rem"}} onClick={() => {setIsEnableReviewLoginDialogOpen(false); setEnableReview(false);}} />
                  </Box>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                  {
                    isMobile ?
                    <Box pb={1}>
                      <Typography variant="body2">
                        {t("details.sign-in-to-review-description")}
                      </Typography>
                    </Box>
                    :
                    <DialogContentText>
                      <Typography variant="body2">
                        {t("details.sign-in-to-review-description")}
                      </Typography>
                    </DialogContentText>
                  }
                  <Box pt={isMobile ? 2 : 1} pb={isMobile ? 1 : 0}>
                    <Button variant="contained" color="primary" fullWidth={true}
                      component={Link}
                      to={`/login?tag=${tag}`}
                      onClick={() => {setEnableReview(true)}}
                    >
                      <Typography variant="button">
                        {t("common.texts.sign-in")}
                      </Typography>
                    </Button>
                  </Box>
                </DialogContent>
              </Box>
            </Dialog>
          }

            <Dialog open={guestProfileIncompleteDialogOpen} onClose={
              () => {
                setGuestProfileIncompleteDialogOpen(false);
                handleCheckInDialogClose();
              }
            } aria-labelledby="guest-profile-incomplete-dialog-title" >
              <Box px={1} className={classes.dialogNoForm}>
                <DialogTitle
                  id="guest-profile-incomplete-dialog-title"
                  className={classes.dialogTitle}
                  classes={{
                    root: classes.dialogTitleRoot,
                  }}
                >
                  {t("details.guest-profile-incomplete")}
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                  <DialogContentText>
                    <Typography variant="body2">
                      { t("details.guest-profile-incomplete-description") }
                    </Typography>
                  </DialogContentText>
                </DialogContent>

                <DialogActions className={classes.dialogActions}>
                  <Box width="100%" flex="auto">
                    <Button variant="contained" color="primary" fullWidth={true} component={Link} to={`/completePaymentDetails?tag=${tag}`}>
                      <Typography variant="button">
                        {t("details.complete-profile")}
                      </Typography>
                    </Button>
                  </Box>
                </DialogActions>
              </Box>
            </Dialog>

          { parkingSpot && parkingSpot.id && (
            <Box>
              {/* Review confirmation dialog */}
              { booking ? (
                <Dialog open={checkOutDialogOpen} onClose={
                  () => handleCheckOutDialogClose()
                } aria-labelledby="check-out-form-dialog-title" >
                  <Box px={1} className={classes.dialogNoForm}>
                    <DialogTitle
                      id="check-out-form-dialog-title"
                      className={classes.dialogTitle}
                      classes={{
                        root: classes.dialogTitleRoot,
                      }}
                    >
                      Check-Out
                    </DialogTitle>

                    <DialogContent className={classes.dialogContent}>
                      <DialogContentText>
                        <Typography variant="body2">
                          { (() => {
                            const bookedForNights = Number(booking.number_of_nights)
                            const refundableDays = bookedForNights - getNumberOfNights(booking.checkin, booking.time_zone)

                            const refund_amount = refundableDays * parseFloat(booking.charged_amount) / bookedForNights

                            const info = () => {
                              if (refund_amount > 0) {
                                if (!!booking?.voucher && booking.voucher.voucher_type === 'percent' && booking.voucher.value === 100) {
                                  return t('common.btn.check-out.confirm-checkout-refund-tax-only')
                                }

                                return `${t("common.btn.check-out.confirm-checkout-line1")} ${booking.currency} ${refund_amount.toFixed(2)} ${t("common.btn.check-out.confirm-checkout-line2")}`
                              }

                              return `${t("common.btn.check-out.confirm-checkout-no-refund")}`
                            }

                            return info()
                          })()}
                        </Typography>
                      </DialogContentText>
                    </DialogContent>

                    <DialogActions className={classes.dialogActions}>
                      <Box width="50%">
                        <Box className={classes.buttonWrapper}>
                          <Button variant="contained" color="primary" onClick={
                            () => {
                              setCheckingOut(true);
                              postCheckOut(booking.id, getTimeAMPM(), token, tag);
                              setIsNightSelected(false);
                              setResetSwipeButton(resetSwipeButton + 1);
                            }
                          } disabled={checkingOut} fullWidth={true}>
                            <Typography variant="button">
                              {t("common.btn.yes")}
                            </Typography>
                          </Button>
                          { checkingOut && <CircularProgress size={24} className={classes.buttonProgress} /> }
                        </Box>
                      </Box>
                      <Box width="50%" ml={1}>
                        <Button variant="outlined" onClick={
                          () => {
                            handleCheckOutDialogClose();
                          }
                        } fullWidth={true}>
                          <Typography variant="button">
                            {t("common.btn.no")}
                          </Typography>
                        </Button>
                      </Box>
                    </DialogActions>
                  </Box>
                </Dialog>
              )
              : (
                <React.Fragment>
                  <Dialog open={daysSelectionDialogOpen} onClose={
                    () => {
                      setDaysSelectionDialogOpen(false)
                      setResetSwipeButton(resetSwipeButton + 1);
                      }
                    } >
                    <Box>
                      <DialogTitle
                          id="check-in-form-dialog-title"
                          className={classes.dialogDaysSelectorContainer}
                          classes={{
                            root: classes.dialogTitleRoot,
                          }}
                        >
                        {t("check-in-wheel.title")}
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          className={classes.dialogDaysSelector}
                        >
                          {
                            checkinPossibleOn.map((day, index) => 
                            <Box className={numberOfDays === index + 1 ? classes.dialogDaysSelectorBtnActive : classes.dialogDaysSelectorBtnInactive} onClick={() => {
                              setNumberOfDays(index+1)
                            }} >
                              <p>{index + 1}</p>
                            </Box> 
                            )
                           }
                          
                        </Box>
                        <DialogContentText >
                          <Typography variant="body2" style={{ marginTop: "2rem"}}>
                            {t('check-in-wheel.disclaimer')}
                          </Typography>
                        </DialogContentText>
                      </DialogContent>
                      <Divider classes={{root: classes.divider}} style={{margin: "1rem"}} />
                      <DialogActions className={classes.dialogActions}>
                        <Box width="50%" position="relative">
                          <Button variant="outlined"
                            fullWidth
                            className={classes.checkout}
                            style={{color: '#000'}}
                            onClick={() => {
                              setDaysSelectionDialogOpen(false)
                            }}
                          >
                            {t('check-in-wheel.btn-back')}
                          </Button>
                        </Box>
                        <Box width="50%" position="relative">
                          <Button variant="contained" color="primary" fullWidth
                            className={classes.checkout}
                            onClick={() => {
                              setDaysSelectionDialogOpen(false)
                              setCheckInDetailsDialogOpen(true)
                              // setCheckInDialogOpen(true)
                            }}
                          >
                            {t('check-in-wheel.btn-continue')}
                          </Button>
                        </Box>
                      </DialogActions>
                    </Box>
                  </Dialog>
                  <Dialog open={checkInDetailsDialogOpen}
                  onClose={() => {
                    setCheckInDetailsDialogOpen(false)
                    setResetSwipeButton(resetSwipeButton + 1);
                    setAppliedVoucher(null);
                    voucherCode = null;
                  }}>
                    <Box px={1} maxWidth="24rem">
                      <DialogTitle
                          id="check-in-form-dialog-title"
                          className={classes.dialogTitle}
                          classes={{
                            root: classes.dialogTitleRoot,
                          }}
                        >
                          {t("check-in-box.dialog.title")}
                        </DialogTitle>
                        <DialogContent>
                          
                            <Typography variant="body2">
                              {t("check-in-box.dialog.line-1a")} <strong>{getPrimaryCar() && getPrimaryCar().name}</strong> {t("check-in-box.dialog.line-1b")}
                            </Typography>
                          
                          <Box >
                            <Box mb={1}>
                              {/* <Divider classes={{root: classes.divider}} style={{marginTop: "1rem", marginBottom: "1rem"}} /> */}
                              {
                                !!parkingSpot && (
                                (tourismTaxTypePerPerson && parkingSpot.tourism_tax_type === "per_person") && (
                                  <Box display="flex" flexDirection="column">
                                    <Box mb={0.5} mt={2}>
                                      <Typography variant="body2">
                                        <strong>{t("check-in-box.dialog.people-count")}</strong>
                                      </Typography>
                                    </Box>
                                    <Box mt={2} display="flex" alignItems="center">
                                      <div style={{flex: "auto"}}>
                                        <Box display="flex" justifyContent="center" alignItems="center" className={classes.counterContainer}>
                                          <Button variant="outlined" className={classes.counterButton} onClick={() => {
                                            if (checkinPersonsCount > 1) {
                                              setCheckinPersonsCount(checkinPersonsCount - 1);
                                            }
                                          }}>
                                            <Typography variant="h3">-</Typography>
                                          </Button>
                                          <TextField id="persons" type="text" disabled value={
                                            (() => checkinPersonsCount < 1 ? 1 : checkinPersonsCount)()} name="persons_count" control={control}
                                            inputProps={{ inputMode: 'numeric', pattern: '[1-9]*', style: {color: '#000'}}}
                                            onChange={(e) => {
                                              let value = parseInt(e.target.value, 10);
                                              if(!Number.isNaN(value)) {
                                                setCheckinPersonsCount(value < 1 ? 1 : value);
                                              }else {
                                                setCheckinPersonsCount('');
                                              }
                                            }}
                                            required
                                            className={classes.counterTextField}
                                          />
                                          <Button variant="outlined" className={classes.counterButton} onClick={() => {
                                            setCheckinPersonsCount(checkinPersonsCount + 1);
                                          }}>
                                            <Typography variant="h3" color="primary">+</Typography>
                                          </Button>
                                        </Box>
                                      </div>
                                      <Typography variant="body2">+ {
                                        (Math.round((parkingSpot.tax_amount * checkinPersonsCount * numberOfDays + Number.EPSILON) * 100) / 100).toFixed(2)
                                      } {parkingSpot.currency}</Typography>
                                      <Tooltip title={t("check-in-box.dialog.tourism-tax")} enterTouchDelay={0} arrow placement='bottom' classes={{
                                        popper: classes.tooltipPopper
                                      }}>
                                        <HelpTooltipIcon fill={theme.palette.primary.main} className={classes.helpTooltipIcon} />
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                  
                                ))
                              }
                              
                              {/* <Divider classes={{root: classes.divider}} style={{marginTop: "1rem"}} /> */}
                              <Box mt={2}>
                                <DialogContentText>
                                  <Typography variant="body2">
                                    {(() => {
                                      if (numberOfDays > 1) {
                                        return (
                                          <p>
                                          {numberOfDays} {t("check-in-box.dialog.nights-of")}: <strong>{parkingSpot.name}</strong>
                                          </p>
                                        )
                                      }
                                        return (
                                          <p>
                                          {numberOfDays} {t("check-in-box.dialog.night-of")}: <strong>{parkingSpot.name}</strong>
                                          </p>
                                        )
                                    }
                                    )()}
                                    
                                  </Typography>
                                </DialogContentText>
                                {(
                                  <div style={{ borderTop: "1px solid #E7F0F0", marginTop: "14", marginBottom: "14"}}>
                                    <Box mb={0.5} mt={2}>
                                      <Typography variant="body2">
                                        <strong> {t("check-in-box.dialog.voucher-text")}</strong>
                                      </Typography>
                                      <Box mt={0.5} mb={0.5} display="flex"
                                      style={{
                                        flexDirection: 'row',
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        gap: "1rem",
                                      }}>
                                        <Box style={{
                                              width: 'fit-content',
                                              flexGrow: 1
                                            }}>
                                          <TextField
                                            fullWidth
                                            id="voucher"
                                            type="text"
                                            name="voucher_code"
                                            variant="outlined"
                                            onChange={(e) => {
                                              voucherCode = e.target.value
                                            }}
                                          />
                                        </Box>
                                        
                                        {(() => (
                                          <Button variant="contained" color={applyingVoucher ? 'secondary' : 'primary'} disabled={applyingVoucher ? true : false}
                                          style={{
                                            backgroundColor: applyingVoucher ? theme.palette.secondary.main: theme.palette.primary.main,
                                            color: "white",
                                            paddingTop: "0.75rem",
                                            paddingBottom: "0.75rem",
                                            flexGrow: 1,
                                            flexShrink: 0,
                                          }}
                                          onClick={() => {
                                            if (voucherCode && voucherCode.trim() !== '') {
                                              setApplyingVoucher(true)
                                              fetchVoucherDetails(voucherCode, parkingSpot.currency, token)
                                            } else {
                                              setAppliedVoucher(null)
                                              voucherCode = null;
                                            }
                                          }}
                                          
                                          >
                                            <Typography variant="button">
                                              {t("check-in-box.dialog.voucher-btn")}
                                            </Typography>
                                            { applyingVoucher && <CircularProgress size={24} className={classes.buttonProgress} style={{'color': '#fff'}} /> }
                                        </Button>
                                        ))()}
                                        
                                      </Box>
                                      { !!appliedVoucher && 
                                        <Typography variant="body2" mb="0" style={{
                                        display: 'inline-block',
                                        color: theme.palette.primary.main,
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        setAppliedVoucher(null)
                                      }}
                                      > {t("common.texts.remove")}
                                      </Typography>
                                      }
                                    </Box>
                                  </div>
                                )}
                                { (() => {
                                    const price = round(parkingSpot.price * numberOfDays, 2)
                                    const tourismTax = round((parkingSpot.tax_amount * checkinPersonsCount * numberOfDays), 2)
                                    const discount = round((() => !!appliedVoucher ? appliedVoucher.voucher_type === 'percent' ? parseFloat(parkingSpot.price * numberOfDays * appliedVoucher.value / 100) : parseFloat(appliedVoucher.value) : 0)(), 2)
                                    const chargeAbleAmount = parseFloat(price) + parseFloat(tourismTax) - parseFloat(discount)
                                    const totalAmount = round(chargeAbleAmount > 0 ? chargeAbleAmount : 0, 2)
                                  return (
                                    <div>
                                      <Box mb={1} style={ (!!parkingSpot && parkingSpot.tourism_tax_type === "per_person") ? {borderTop: "1px solid #E7F0F0", borderBottom: "1px solid #E7F0F0", marginTop: "14px"} : {borderBottom: "1px solid #E7F0F0"}} display="flex" flexDirection="column">
                                        <Box display="flex" pt={ (!!parkingSpot && parkingSpot.tourism_tax_type === "per_person") ? 2.5 : 1.5 } pb={0.875}>
                                          <Typography variant="body2" style={{flex:"auto"}}>{t("spot-attributes.pricing.price")}</Typography>
                                          <Typography variant="body2">{parkingSpot.currency} {price}</Typography>
                                        </Box>
                                        <Box display="flex" mb={1}>
                                          <Typography variant="body2" style={{flex:"auto"}}>{t("check-in-box.dialog.tourism-tax")}</Typography>
                                          <Typography variant="body2">{parkingSpot.currency} {tourismTax}</Typography>
                                        </Box>
                                        
                                        <Box display="flex" pb={2.5}>
                                            <Typography variant="body2" style={{flex:"auto"}}>{t("check-in-box.dialog.voucher-discount")}</Typography>
                                            <Typography variant="body2">{parkingSpot.currency} - {discount}</Typography>
                                        </Box>
                                        
                                      </Box>
                                      <Box display="flex" pb={1.5} pt={1}>
                                        <Typography variant="body2" style={{flex:"auto"}}><strong>{t("check-in-box.dialog.summary")}</strong></Typography>
                                        <Typography variant="body2"><strong>{`${parkingSpot.currency}
                                        ${totalAmount}`}</strong></Typography>
                                      </Box>
                                    </div>
                              )})()}
                        
                              </Box>
                            </Box>
                            <Divider classes={{root: classes.divider}} />
                              <Box mt={2}>
                                <p>
                                  {t('check-in-box.dialog.disclaimer')}
                                </p>
                              </Box>
                          </Box>
                        </DialogContent>

                      </Box>
                      <DialogActions className={classes.dialogActions} style={{
                              padding: 0,
                              margin: 0,
                            }}>
                              <Box style={{
                                margin: 0,
                                padding: 0,
                                width: "50%",
                              }} >
                                <Button variant="outlined"
                                  style={{
                                    borderRadius: 0,
                                  }}
                                  onClick={
                                  () => {
                                    setCheckInDetailsDialogOpen(false)
                                    setResetSwipeButton(resetSwipeButton + 1);
                                    isMobile ? setDaysSelectionWheelOpen(true) : setDaysSelectionDialogOpen(true)
                                    setAppliedVoucher(null)
                                    voucherCode = null;
                                  }
                                } fullWidth={true}>
                                  <Typography variant="button">
                                    {t("check-in-wheel.btn-back")}
                                  </Typography>
                                </Button>
                              </Box>
                              <Box style={{
                                margin: 0,
                                padding: 0,
                                width: "50%",
                                position: 'relative',
                              }} >
                                <Button variant="outlined" color={checkingIn ? 'secondary' : 'primary'} fullWidth disabled={checkingIn ? true : false}
                                  style={{
                                    borderRadius: 0,
                                    backgroundColor: checkingIn ? theme.palette.secondary.main: theme.palette.primary.main,
                                    outlineColor: 'grey',
                                    color: "white",
                                  }}
                                  onClick={() => {
                                    if (!checkingIn) {
                                      setCheckingIn(true);
                                      postCheckIn({
                                        checkin: getTimeAMPM(),
                                        price: grossPrice,
                                        parking_spot_id: parkingSpot.id,
                                        car_id: getPrimaryCar().id,
                                        persons_count: checkinPersonsCount,
                                        number_of_nights: numberOfDays,
                                        voucher_id: appliedVoucher?.id,
                                        utm_source: Cookies.get('utm_source')
                                      }, token)
                                      setResetSwipeButton(resetSwipeButton + 1);
                                      voucherCode = null;
                                    }
                                  }}
                                 >
                                  <Typography variant="button">
                                    {t("check-in-box.dialog.check-in-btn")}
                                  </Typography>
                                </Button>
                                { checkingIn && <CircularProgress size={24} className={classes.buttonProgress} style={{'color': '#fff'}} /> }
                              </Box>
                        </DialogActions>
                  </Dialog>
                  {/* Check-In dialog */}
                 
                </React.Fragment>
              ) }

              {/* days wheel selector */}

              <Dialog open={daysSelectionWheelOpen} onClose={
                    () => {
                      setDaysSelectionWheelOpen(false)
                      setResetSwipeButton(resetSwipeButton + 1);
                      }
                    } >
                <Box>
                  <DialogTitle
                    id="check-in-form-dialog-title"
                    className={classes.dialogDaysSelectorContainer}
                    classes={{
                      root: classes.dialogTitleRoot,
                    }}
                  >
                  {t("check-in-wheel.title")}
                  </DialogTitle>
                  {/* <Divider classes={{root: classes.divider}} style={{margin: "1rem"}} /> */}
                  <div style={{
                    position: "relative",
                    margin: "auto",
                    width: "24em",
                    height: "24em",
                    alignItems: "center",
                    overflow: "hidden",
                    touchAction: "none",
                  }}>
                    <div style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                      width: "24em",
                      height: "24em",
                      transformOrigin: "center",
                      transform: `rotate(${wheelGradientRotation}deg)`,
                      }}
                    >
                      {
                        checkinPossibleOn.map((day, index) => {
                            const rotation = (360 / checkinPossibleOn.length) * index
                            return ( 
                            <div
                              style = {{
                                display: "flex",
                                position: "absolute",
                                height: "11em",
                                width: "1px",
                                margin: "auto",
                                top: 0,
                                left: 0,
                                bottom: "11em",
                                right: 0,
                                transformOrigin: "bottom center",
                                transform: `rotate(${rotation}deg)`,
                                zIndex: 3,
                              }}>
                              <div
                                style={{
                                  background: "#fff",
                                  width: "100%",
                                  height: "4em",
                                  margin: 0,
                                  padding: 0,
                                }}
                              ></div>
                            </div>
                          )})
                      }
                      <div
                      style={{
                        width: "24em",
                        height: "24em",
                        background: `linear-gradient(0deg, rgba(0,138,148,1) 0%, rgba(123,246,255,1) 50%, rgba(0,138,148,1) 100%)`,
                        borderRadius: "100%",
                        position: "absolute",
                        }}
                        onTouchEnd = {() => {
                          handleWheelTouchEnd()
                        }}
                        onTouchMove={(e) => {
                          handleWheelTouchEvent(e)
                        }}
                        >

                      </div>
                    </div>
                    <div 
                        id="wheel-inner"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "12em",
                          height: "12em",
                          background: "#fff",
                          borderRadius: "100%",
                          zIndex: 2,
                          textAlign: "center",
                          padding: "0.5em",
                          position: "absolute",
                          top: "25%",
                          left: "25%",
                        }}>
                          <Typography variant="body2" style={{fontSize: "3rem", lineHeight: "1.5rem"}}>
                          {`${numberOfDays}`} <br/>
                            <span style={{ fontSize: '0.75rem',}}>
                              {`${numberOfDays > 1 ? t('check-in-wheel.nights') : t('check-in-wheel.night') }`}
                            </span> 
                          </Typography>

                          {(() => {
                            if(numberOfDays === checkinPossibleOn.length) {
                              return(
                                <p style={{position: "absolute", color: "red", bottom: "1em"}}>
                                  {t("check-in-wheel.limit-reached")}
                                </p>
                              )
                            }
                          })()}
                        </div>
                  </div>
                  <Box mx="1.5rem">
                    <Typography variant="body2" style={{marginBottom: 0, marginTop: "1.5rem"}}>
                      {(() => {
                        let date = new Date()
                        date = new Date(date.setDate(date.getDate() + numberOfDays))
                        const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
                        return (
                          <p marginBottom="0">{t("check-in-wheel.stay-until")} {date.toLocaleDateString("en-DE", options).split('/').join('.')} {`12:00`} </p>
                        )
                      })() }
                    </Typography>
                    <p style={{
                      marginBottom: "1rem",
                      marginTop: 0,
                    }}>
                      {t("check-in-wheel.disclaimer")}
                    </p>
                  </Box>
                  <DialogActions className={classes.dialogActions}
                    style={{
                      padding: 0,
                      margin: 0,
                  }}>
                    <Box style={{
                      margin: 0,
                      padding: 0,
                      width: "50%",
                    }}>
                      <Button variant="outlined"
                                  style={{
                                    borderRadius: 0,
                                  }}
                        fullWidth
                        onClick={() => {
                          setDaysSelectionWheelOpen(false)
                          setResetSwipeButton(resetSwipeButton + 1);
                        }}
                      >
                        {t('check-in-wheel.btn-back')}
                      </Button>
                    </Box>
                    <Box style={{
                      margin: 0,
                      padding: 0,
                      width: "50%",
                    }}>
                      <Button variant="outlined" color="primary" fullWidth
                        style={{
                          borderRadius: 0,
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                        }}
                        className={classes.checkout}
                        onClick={() => {
                          setDaysSelectionWheelOpen(false)
                          setCheckInDetailsDialogOpen(true)
                          // setCheckInDialogOpen(true)
                        }}
                      >
                        {t('check-in-wheel.btn-continue')}
                      </Button>
                    </Box>
                  </DialogActions>
                </Box>
              </Dialog>

              {/* Review dialog */}
              <Dialog open={reviewDialogOpen} onClose={
                () => setReviewDialogOpen(false)
              } aria-labelledby="review-form-dialog-title" >
                <Box px={1} className={classes.dialogNoForm}>
                  <DialogTitle
                    id="review-form-dialog-title"
                    className={classes.dialogTitle}
                    classes={{
                      root: classes.dialogTitleRoot,
                    }}
                  >
                    {t("review-box.confirm-review")}
                  </DialogTitle>
                  <DialogContent className={classes.dialogContent}>
                    <DialogContentText>
                      <Typography variant="body2">
                        { t("common.btn.review.line-1") }
                      </Typography>
                      <Typography variant="body2">
                        { t("common.btn.review.confirm") }
                      </Typography>
                    </DialogContentText>
                  </DialogContent>

                  <DialogActions className={classes.dialogActions}>
                    <Box minWidth="40%" flex="auto">
                      <Button variant="contained" color="primary" fullWidth={true} onClick={
                        () => {
                          setReviewDialogOpen(false);
                          setReviewSubmissionDialogOpen(true);
                        }
                      }>
                        <Typography variant="button">
                          {t("review-box.btn-confirmation")}
                        </Typography>
                      </Button>
                    </Box>
                    <Box minWidth="48%" maxWidth="50%" ml={1}>
                      <Button variant="outlined" fullWidth={true} onClick={
                        () => setReviewDialogOpen(false)
                      } fullWidth={true}>
                        <Typography variant="button">
                          {t("review-box.btn-confirmation-cancel")}
                        </Typography>
                      </Button>
                    </Box>
                  </DialogActions>
                </Box>
              </Dialog>

              {/* Review submission dialog */}
              <ReviewDialog
                open={reviewSubmissionDialogOpen}
                bookingId={currentBookingId}
                parkingSpotId={parkingSpot.id}
                onClose={() => {
                  setReviewSubmissionDialogOpen(false);
                  setEnableReview(false);
                  setMobileBottomPanel(false);
                }}
                onPost={() => {
                  setEnableReview(false);
                  setMobileBottomPanel(false);
                }}
              />
            </Box>
          )}

          {/* payment failed dialogue */}

          <Dialog open={paymentFailedDialogOpen} onClose={
              () => {
                setpaymentFailedDialogOpen(false); 
                setCardPaymentFailed(false)
              }} aria-labelledby="payment-failed-dialog-title" >
            <Box px={1} className={classes.paymentFailedDialog}>
              <img src={PaymentFailedIllustration} alt="payment_failed_illustration" className={classes.paymentFailedSVG} />
                <DialogTitle
                  id="payment-failed-dialog-title"
                  className={classes.dialogTitlePaymentFailed}
                  classes={{
                    root: classes.dialogTitleRoot,
                  }}
                >
                  <Typography variant="h2" style={{textAlign: "center"}}>
                      {t("details.payment-failed-dialog-title-line1")}
                  </Typography>
                  <Typography variant="h2" style={{textAlign: "center"}}>
                      {t("details.payment-failed-dialog-title-line2")}
                  </Typography>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                  <DialogContentText>
                    <Typography variant="body2" style={{textAlign: "center"}}>
                      {t("details.payment-failed-dialog-content-line1")}
                    </Typography>
                    <Typography variant="body2" style={{textAlign: "center"}}>
                      {t("details.payment-failed-dialog-content-line2")}
                    </Typography>
                  </DialogContentText>
                </DialogContent>

                <DialogActions style={{paddingBottom: "1rem"}} className={classes.dialogActions}>
                  <Box width="100%">
                    <Box className={classes.buttonWrapper}>
                      <Button variant="contained" color="primary" fullWidth={true} component={Link} to={`/payment`}>
                        <Typography variant="button">
                          {t("details.payment-failed-dialog-button")}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </DialogActions>
            </Box>
          </Dialog>

        </Box>
        </Container>

        { !!mobileBottomPanel && !!isMobile && !enableReview && (
          <Box className={classes.backdrop} onClick={() => {setMobileBottomPanel(false)}}></Box>
        )}

        <Box width="100%" className={classes.bottomCallToAction} display="flex">
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="h2" className={classes.bottomCallToActionText}>
              { t("campers-parking-app")}
            </Typography>
            <Typography variant="h2" className={classes.bottomCallToActionText}>
              { t("enjoy-reservation")}
            </Typography>
            <Box py={2}>
              <Typography variant="body1" className={classes.bottomCallToActionText}>
                { t("cta-social")}
              </Typography>
            </Box>
            <Box pt={2} display="flex" width="auto">
              <Button variant="contained" color="primary" type="submit" component={Link} to={"/"}>
                { t("menu-items.search-spots") }
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.user,
    userType: state.user.userType,
    completedProfile: state.user.completedProfile,
    verified: state.user.verified,
    currentCheckIns: state.parkingSpot.currentCheckIns,
    cars: state.parkingSpot.carsForGuests,
    currentBookingId: state.parkingSpot.currentBookingId,
    reviewForBooking: state.parkingSpot.reviewForBooking,
    checkOutDialogOpen: state.parkingSpot.checkOutDialogOpen,
    checkInDialogOpen: state.parkingSpot.checkInDialogOpen,
    reviewDialogOpen: state.parkingSpot.reviewDialogOpen,
    reviewSubmissionDialogOpen: state.parkingSpot.reviewSubmissionDialogOpen,
    parkingSpot: state.parkingSpot.parkingSpotDetails,
    reviews: [...state.parkingSpot.reviews.response.reviews, ...state.parkingSpot.moreReviews.reviews],
    pagy: (state.parkingSpot.moreReviews.pagy === null) ? state.parkingSpot.reviews.response.pagy : state.parkingSpot.moreReviews.pagy,
    profilePicture: state.parkingSpot.profilePicturePlaceholder,
    checkingOut: state.parkingSpot.checkingOut,
    checkingIn: state.parkingSpot.checkingIn,
    checkInDetailsDialogOpen: state.parkingSpot.checkInDetailsDialogOpen,
    isLoggingOut: state.user.loggingOutUser,
    mobileBottomPanel: state.parkingSpot.mobileBottomPanel,
    enableReview: state.parkingSpot.enableReview,
    cardPaymentFailed: state.payment.cardPaymentFailed,
    photos: state.parkingSpot.photos,
    activeLanguage: state.config.activeLanguage,
    applyingVoucher: state.voucher.applyingVoucher,
    appliedVoucher: state.voucher.appliedVoucher,
  };
};

export default connect(mapStateToProps, {
  fetchParkingSpotDetails,
  fetchParkingSpotDetailsGuests,
  fetchCurrentCheckIns,
  fetchCarsForGuests,
  setCheckOutDialogOpen,
  postCheckOut,
  setCheckingOut,
  setCheckingIn,
  setApplyingVoucher,
  setAppliedVoucher,
  fetchVoucherDetails,
  setCardPaymentFailed,
  setCheckInDialogOpen,
  setCheckInDetailsDialogOpen,
  postCheckIn,
  setReviewDialogOpen,
  setReviewSubmissionDialogOpen,
  postReview,
  fetchPhotosByIdForGuests,
  fetchReviewsByIdForGuests,
  fetchReviewsByIdForGuestsNextPage,
  setCarouselState,
  setMobileBottomPanel,
  setEnableReview,
  addLoader,
  fetchReviewForBooking,
  resetReviews,
  addLoaderForPhotos,
  addLoaderForItem,
})(ParkingSpotDetails);
