import {
  Box,
  Container,
  Divider,
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  useTheme,
  useMediaQuery,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import TitleSection from "components/common/titleSection";
import { ReactComponent as EditIconSVG } from "assets/images/icons/edit-icon.svg";
import { ReactComponent as ResetPasswordIconSVG } from "assets/images/icons/reset-password-icon.svg";
import { ReactComponent as RemoveIconSVG } from "assets/images/icons/remove-icon.svg";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useStyles } from "components/dashboard/common/styles";
import { useDispatch } from "react-redux";
import { recoverPassword } from "data/actions/auth";
import Pagination from "components/common/pagination";
import MissingDataPlaceholder from "components/common/missingDataPlaceholder";
import { authTokenSelect } from "data/selectors/auth";
import {
  deleteSubHost,
  fetchSubHosts,
  postNewSubHost,
  setFetchingSubHosts,
  updateSubHost,
} from "data/actions/user";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import FormField from "components/common/formField";
import OutlinedFormField from 'components/common/outlinedFormField';

const defaultValues = {
  newSubHostFullName: "",
  newSubHostEmail: "",
  updateSubHostFullName: "",
  updateSubHostEmail: "",
};

function ParkingControllers() {
  const { t } = useTranslation();

  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const subHosts = useSelector((state) => state.user.subHosts.sub_hosts);
  const subHostsPagy = useSelector((state) => state.user.subHosts.pagy);
  const token = useSelector(authTokenSelect);
  const user = useSelector((state) => state.user);
  const lastPagyUrl = useSelector((state) => state.global.lastPagyUrl);
  const fetchingSubHosts = user?.fetchingSubHosts;
  const userType = user?.userType;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuId, setopenMenuId] = React.useState(null);
  const [newSubUserDialog, setNewSubUserDialog] = React.useState(false);
  const [editSubUserDialog, setEditSubUserDialog] = React.useState(false);
  const [updateSubHostId, setUpdateSubHostId] = React.useState(null);
  const [removeSubHostDetails, setRemoveSubHostDetails] = React.useState(null);
  const [removeSubUserDialog, setRemoveSubUserDialog] = React.useState(false);

  React.useEffect(() => {
    if (userType === "Host") {
      dispatch(setFetchingSubHosts(true));
      dispatch(fetchSubHosts(token));
    }
  }, [token, userType]);

  const { handleSubmit, control, setValue } = useForm({ defaultValues });

  const handleClickMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setopenMenuId(id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setopenMenuId(null);
  };

  const onSubmitNewSubHost = (data) => {
    const [firstName, lastName] = data.newSubHostFullName.split(' ')
    dispatch(setFetchingSubHosts(true));
    dispatch(
      postNewSubHost(token, {
        host_id: user.id,
        full_name: data.newSubHostFullName,
        first_name: firstName,
        last_name: lastName,
        email: data.newSubHostEmail,
      })
    );
    setNewSubUserDialog(false);
  };

  const onSubmitUpdateSubHost = (data) => {
    if (updateSubHostId) {
      dispatch(setFetchingSubHosts(true));
      dispatch(
        updateSubHost(
          token,
          updateSubHostId,
          {
            host_id: user.id,
            full_name: data.updateSubHostFullName,
            email: data.updateSubHostEmail,
          },
          lastPagyUrl
        )
      );
    }
    setEditSubUserDialog(false);
    setUpdateSubHostId(null);
  };

  const handleDelete = () => {
    if (removeSubHostDetails) {
      dispatch(setFetchingSubHosts(true));
      dispatch(deleteSubHost(token, removeSubHostDetails.id));
    }
    setRemoveSubUserDialog(false);
    setRemoveSubHostDetails(null);
  };

  return (
    <Container maxWidth="md">
      <TitleSection
        routes={[
          {
            name: t("common.texts.dashboard"),
            path: "/dashboard",
          },
        ]}
        title={t("security.sub-accounts")}
      />
      <Divider />
      <Box pt={4}>
        <Box
          display={isMobile ? "initial" : "flex"}
          alignItems="flex-start"
          pb={2}
        >
          <Box flex="auto">
            <Typography variant="h3">
              {t("security.manage-sub-accounts")}
            </Typography>
          </Box>
          <Box py={isMobile ? 2 : 0}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={!!isMobile}
              onClick={() => setNewSubUserDialog(true)}
            >
              {t("security.new-sub-account")}
            </Button>
          </Box>
        </Box>

        <Box mt={isMobile ? 2 : 0}>
          {subHosts && subHosts.length > 0 ? (
            <TableContainer
              component={Paper}
              elevation={0}
              className={classes.tableContainer}
            >
              <Table
                className={classes.table}
                aria-label="parked cars table"
                elevation={0}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "10rem" }}>
                      <Typography variant="h5" style={{ fontWeight: 500 }}>
                        {t("common.labels.name")}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: "12rem" }}>
                      <Typography variant="h5" style={{ fontWeight: 500 }}>
                        {t("common.labels.email")}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: "7rem" }}>
                      <Typography variant="h5" style={{ fontWeight: 500 }}>
                        {t("security.role")}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: "7rem" }}>
                      <Typography variant="h5" style={{ fontWeight: 500 }}>
                        {t("security.created-at")}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" style={{ width: "4rem" }}>
                      {/*<ActionsWheelSVG className={classes.actionsWheel} />*/}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subHosts.map((row, index) => (
                    <TableRow>
                      <TableCell>
                        <Typography variant="h4">{row.full_name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h4">{row.email}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h4">
                          {row.role || "Admin"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h4">
                          {`${
                            row.created_at
                              ? moment(row.created_at).format("DD.MM.YYYY")
                              : "N/A"
                          }`}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <div>
                          <Button
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            className={classes.actionsButton}
                            onClick={(event) => handleClickMenu(event, row.id)}
                          >
                            <MoreVertIcon style={{ fontSize: "medium" }} />
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenuId === row.id}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                              style: {
                                paddingBottom: 0,
                                paddingTop: 0,
                              },
                            }}
                            PaperProps={{
                              style: {
                                borderRadius: "12px",
                                padding: 0,
                                marginTop: "1em",
                                overflow: "hidden",
                                minWidth: "unset",
                                boxShadow:
                                  "0 3px 7px rgb(0 0 0 / 13%), 0 0.6px 2px rgb(0 0 0 / 10%)",
                              },
                            }}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => {
                                handleCloseMenu();
                                setEditSubUserDialog(true);
                                setUpdateSubHostId(row.id);
                              }}
                            >
                              <EditIconSVG className={classes.menuItemIcon} />
                              {t("common.btn.edit")}
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => {
                                handleCloseMenu();
                                dispatch(
                                  recoverPassword(
                                    row.email,
                                    true,
                                    t("security.password-reset-email-sent")
                                  )
                                );
                              }}
                            >
                              <ResetPasswordIconSVG
                                className={classes.menuItemIcon}
                              />
                              {t("security.reset-password")}
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              className={classes.menuItemRemove}
                              onClick={() => {
                                handleCloseMenu();
                                setRemoveSubHostDetails({
                                  id: row.id,
                                  name: row.full_name,
                                });
                                setRemoveSubUserDialog(true);
                              }}
                            >
                              <RemoveIconSVG className={classes.menuItemIcon} />
                              {t("security.remove")}
                            </MenuItem>
                          </Menu>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={5} align="right">
                      <Pagination
                        pagy={subHostsPagy}
                        token={token}
                        epic={(...args) => dispatch(fetchSubHosts(...args))}
                        setLoader={(...args) =>
                          dispatch(setFetchingSubHosts(...args))
                        }
                        query={null}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <MissingDataPlaceholder />
          )}
        </Box>
      </Box>
      {/* New sub user dialog */}
      <Box>
        <Dialog
          open={newSubUserDialog}
          onClose={() => setNewSubUserDialog(false)}
          aria-labelledby="new-sub-user-dialog"
        >
          <Box px={1} className={classes.subUserNewDialog}>
            <DialogTitle
              id="new-sub-user-dialog-title"
              className={clsx(
                classes.dialogTitle,
                classes.newSubUserDialogTitle
              )}
              classes={{
                root: classes.dialogTitleRoot,
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h2">
                  {t("security.create-new-sub-account")}
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setNewSubUserDialog(false);
                  }}
                >
                  <CloseIcon flex="auto" color="primary" />
                </Button>
              </Box>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <Box>
                <Box pb={2.5}>
                  <Typography variant="h4">
                    {t("security.profile-info")}
                  </Typography>
                </Box>
                <form
                  onSubmit={handleSubmit(onSubmitNewSubHost)}
                  className={classes.root}
                  autoComplete="off"
                  autoCorrect="off"
                >
                  <Box display="flex" alignItems="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      mb={0.25}
                      width="100%"
                    >
                      <Box width="100%" pr={0}>
                        <OutlinedFormField
                          label={ t("common.labels.full-name") }
                          name="newSubHostFullName"
                          type="text"
                          control={control}
                          required />
                      </Box> 
                      <Box width="100%" pl={0} mt={2.5}>
                        <OutlinedFormField
                          type="text"
                          label={t("common.labels.email")}
                          name="newSubHostEmail"
                          control={control}
                          required
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box flex="auto" mt={2.5} ml={0} display="flex">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setNewSubUserDialog(false);
                      }}
                      fullWidth
                    >
                      <Typography variant="button">
                        {t("common.btn.cancel")}
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      style={{ marginLeft: "1rem" }}
                      disabled={fetchingSubHosts}
                    >
                      <Typography variant="button">
                        {t("common.btn.save-changes")}
                      </Typography>
                    </Button>
                  </Box>
                </form>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>

      {/* Edit sub user dialog */}
      <Box>
        <Dialog
          open={editSubUserDialog}
          onClose={() => {
            setEditSubUserDialog(false);
            setUpdateSubHostId(null);
          }}
          aria-labelledby="new-sub-user-dialog"
          onEntered={() => {
            if (userType === "Host" && updateSubHostId) {
              let userToBeUpdatedIndex = subHosts.findIndex(
                (sub) => sub.id === updateSubHostId
              );
              setValue(
                "updateSubHostFullName",
                subHosts[userToBeUpdatedIndex].full_name
              );
              setValue(
                "updateSubHostEmail",
                subHosts[userToBeUpdatedIndex].email
              );
            }
          }}
        >
          <Box px={1} className={classes.subUserNewDialog}>
            <DialogTitle
              id="new-sub-user-dialog-title"
              className={clsx(
                classes.dialogTitle,
                classes.newSubUserDialogTitle
              )}
              classes={{
                root: classes.dialogTitleRoot,
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h2">
                  {t("security.edit-sub-account")}
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setEditSubUserDialog(false);
                    setUpdateSubHostId(null);
                  }}
                >
                  <CloseIcon flex="auto" color="primary" />
                </Button>
              </Box>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <Box>
                <Box pb={2.5}>
                  <Typography variant="h4">
                    {t("security.profile-info")}
                  </Typography>
                </Box>
                <form
                  onSubmit={handleSubmit(onSubmitUpdateSubHost)}
                  className={classes.root}
                  autoComplete="off"
                  autoCorrect="off"
                >
                  <Box display="flex" alignItems="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      mb={0.25}
                      width="100%"
                    >
                      <Box width="100%" pr={0}>
                      <OutlinedFormField
                          type="text"
                          label={t("common.labels.full-name")}
                          name="updateSubHostFullName"
                          control={control}
                          required
                        />
                      </Box>
                      <Box width="100%" pl={0} mt={2.5}>
                        <OutlinedFormField
                          type="text"
                          label={t("common.labels.email")}
                          name="updateSubHostEmail"
                          control={control}
                          required
                          disabled
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box flex="auto" mt={2.5} ml={0} display="flex">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setEditSubUserDialog(false);
                        setUpdateSubHostId(null);
                      }}
                      fullWidth
                    >
                      <Typography variant="button">
                        {t("common.btn.cancel")}
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      style={{ marginLeft: "1rem" }}
                      disabled={fetchingSubHosts}
                    >
                      <Typography variant="button">
                        {t("common.btn.save-changes")}
                      </Typography>
                    </Button>
                  </Box>
                </form>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>

      {/* Remove sub user dialog */}
      <Box>
        <Dialog
          open={removeSubUserDialog}
          onClose={() => {
            setRemoveSubUserDialog(false);
            setRemoveSubHostDetails(null);
          }}
          aria-labelledby="new-sub-user-dialog"
        >
          <Box px={1} className={classes.subUserNewDialog}>
            <DialogTitle
              id="new-sub-user-dialog-title"
              className={clsx(
                classes.dialogTitle,
                classes.newSubUserDialogTitle
              )}
              classes={{
                root: classes.dialogTitleRoot,
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h2">
                  {t("security.delete-sub-account")}
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setRemoveSubUserDialog(false);
                    setRemoveSubHostDetails(null);
                  }}
                >
                  <CloseIcon flex="auto" color="primary" />
                </Button>
              </Box>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <Box>
                <Box>
                  <Typography variant="body2">
                    {t("security.sub-user-remove-text-a")}{" "}
                    <strong>
                      {removeSubHostDetails && removeSubHostDetails.name}
                    </strong>
                    ? {t("security.sub-user-remove-text-b")}
                  </Typography>
                </Box>
                <Box
                  flex="1"
                  mt={2.5}
                  ml={1}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#f54646",
                      color: "white",
                      width: "10rem",
                    }}
                    onClick={handleDelete}
                    disabled={fetchingSubHosts}
                  >
                    <Typography variant="button">
                      {t("common.btn.delete")}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>
    </Container>
  );
}

export default ParkingControllers;
