import React, { useEffect, useState } from 'react';
import { useStyles } from '../common/styles';
import { Divider, Container, Box, Typography, Chip, Checkbox, Button, Card } from '@material-ui/core';
import TitleSection from '../../common/titleSection';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import MissingDataPlaceholder from '../../common/missingDataPlaceholder';
import { fetchParkingSpotCars, postParkedCarCheck, setParkedCarCheckDiabled } from '../../../data/actions/parkingSpot';
import { addLoader } from '../../../data/actions/config';
import moment from 'moment';
import { ReactComponent as CaretDownSVG } from '../../../assets/images/dropdown-arrow.svg';

const ParkedCars = ({ token, userType, parkedCars,setParkedCarCheckDiabled, postParkedCarCheck , fetchParkingSpotCars, addLoader }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  let { id } = useParams();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    addLoader();
    fetchParkingSpotCars(id, token, userType === "SubHost");
  }, [addLoader, fetchParkingSpotCars, id, token]);

  const [expanded, setExpanded] = useState({});

  return (
    <Container maxWidth="md">
      <TitleSection
        routes={[{
          name: t("common.texts.dashboard"),
          path: "/dashboard",
        }, {
          name: t("parking-spot.title"),
          path: "/ps",
        }]}
        title={ t("parking-spot.parked-cars.title") }
      />
      <Divider />
      <Box display="flex" pt={isMobile ? 3 : 8} flexDirection="column">
        <Box display="flex" pb={isMobile ? 1 : 0}>
          <Typography variant="h3">
            { t("parking-spot.parked-cars.currently-parked") }
          </Typography>
          <Chip label={`${parkedCars.booked} / ${parkedCars.capacity}`} classes={{label: classes.chipLabel}} variant="outlined" className={classes.chip} />
        </Box>
        <Box mt={2}>
          {
            parkedCars && parkedCars.cars && parkedCars.cars.length > 0 ?
            (
                <React.Fragment>
                    <Box marginLeft="-1.5rem">
                        { parkedCars.cars.map((row, index) => (
                            <Card style={{marginTop: "1.5rem"}} className={`${classes.parkedCarsCard} ${!!row.verified ? classes.parkedCarsVerified : ""}`} elevation={0} key={`car-${row.id}`}>
                                <Box p={3} display="flex" flexDirection="column" >
                                    <Box mb={2}>
                                        <Typography variant="body1" className={classes.parkedCarsCardLabel}>{ t("payment.verified") }:</Typography>
                                        <Checkbox color="primary" style={{padding: '0px 6px 4px 2px'}} disabled={row.isDisabled} defaultChecked={!!row.verified} onClick={()=> {
                                            setParkedCarCheckDiabled(row.id, true);
                                            postParkedCarCheck(token, row.id, !row.verified, id, userType === "SubHost");
                                        }}/>
                                    </Box>

                                    <Box mb={2} className={classes.parkedCarsCardContentBox}>
                                        <Typography variant="body1" className={classes.parkedCarsCardLabel}>{ t("parking-spot.parked-cars.check-in-time") }:</Typography>
                                        <Typography variant="body1" className={classes.parkedCarsContent}>{moment(row.checkin).zone(row.checkin).format('DD.MM.YYYY - HH:mm') + " " + row.time_zone} </Typography>
                                    </Box>
                                    <Box mb={2} className={classes.parkedCarsCardContentBox}>
                                        <Typography variant="body1" className={classes.parkedCarsCardLabel}>{ t("car.plate") }:</Typography>
                                        <Typography variant="body1" className={classes.parkedCarsContent}>{row.number_plate}</Typography>
                                    </Box>
                                    <Box mb={expanded[row.id] ? 2 : 0} className={classes.parkedCarsCardButtonBox} onClick={() => setExpanded({
                                      ...expanded,
                                      [row.id]: !(expanded[row.id])
                                    })}>
                                      <Typography variant="body1" color="primary" className={classes.parkedCarsCardButton}>
                                        { t("parking-spot.parked-cars.view-more-info") } <CaretDownSVG className={expanded[row.id] ? classes.expanded : ""} />
                                      </Typography>
                                    </Box>
                                    {expanded[row.id] && (
                                      <>
                                        <Box mb={2} className={classes.parkedCarsCardContentBox}>
                                          <Typography variant="body1" className={classes.parkedCarsCardLabel}>
                                            { t("common.labels.name") }:
                                          </Typography>
                                          <Typography variant="body1" className={classes.parkedCarsContent}>{row.full_name}</Typography>
                                        </Box>
                                        <Box mb={2} className={classes.parkedCarsCardContentBox}>
                                          <Typography variant="body1" className={classes.parkedCarsCardLabel}>
                                            { t("common.labels.phone") }:
                                          </Typography>
                                          <Typography variant="body1" className={classes.parkedCarsContent}>{row.phone}</Typography>
                                        </Box>
                                        <Box className={classes.parkedCarsCardContentBox}>
                                          <Typography variant="body1" className={classes.parkedCarsCardLabel}>
                                            { t("common.labels.email") }:
                                          </Typography>
                                          <Typography variant="body1" className={classes.parkedCarsContent}>{row.email}</Typography>
                                        </Box>
                                      </>
                                    )}
                                </Box>
                            </Card>
                        ))}
                    </Box>
                </React.Fragment>
              // !isMobile ? (
              // <TableContainer component={Paper} elevation={0} className={classes.tableContainer}>
              //   <Table className={classes.table} aria-label="parked cars table" elevation={0}>
              //     <TableHead>
              //       <TableRow>
              //         <TableCell><Typography variant="h5" style={{fontWeight: 500}}>#</Typography></TableCell>
              //         <TableCell><Typography variant="h5" style={{fontWeight: 500}}>{ t("parking-spot.parked-cars.check-in-time") }</Typography></TableCell>
              //         <TableCell><Typography variant="h5" style={{fontWeight: 500}}>{ t("car.plate") }</Typography></TableCell>
              //         <TableCell style={{width: "13rem"}}><Typography variant="h5" style={{fontWeight: 500}}>{ t("common.labels.full-name") }</Typography></TableCell>
              //         <TableCell><Typography variant="h5" style={{fontWeight: 500}}>{ t("common.labels.phone") }</Typography></TableCell>
              //         <TableCell align="center"><Typography variant="h5" style={{fontWeight: 500}}>{ t("payment.verified") }</Typography></TableCell>
              //       </TableRow>
              //     </TableHead>
              //     <TableBody>
              //       {parkedCars.cars.map((row, index) => (
              //         <TableRow key={`car-${row.id}`}>
              //           <TableCell component="th" scope="row">
              //             <Typography variant="h5">
              //               {index + 1}
              //             </Typography>
              //           </TableCell>
              //           <TableCell>
              //             <Typography variant="h5">
              //               {moment(row.checkin).format('DD.MM.YYYY - HH:mm')}
              //             </Typography>
              //           </TableCell>
              //           <TableCell>
              //             <Typography variant="h5">
              //               {row.number_plate}
              //             </Typography>
              //           </TableCell>
              //           <TableCell>
              //             <Typography variant="h5">
              //               {row.full_name}
              //             </Typography>
              //           </TableCell>
              //           <TableCell>
              //             <Typography variant="h5">
              //               {row.phone}
              //             </Typography>
              //           </TableCell>
              //           <TableCell align="center">
              //             <Checkbox color="primary" disabled={row.isDisabled} defaultChecked={!!row.verified} onClick={()=> {
              //               setParkedCarCheckDiabled(row.id, true);
              //               postParkedCarCheck(token, row.id, !row.verified, id, userType === "SubHost");
              //             }}/>
              //           </TableCell>
              //         </TableRow>
              //       ))}
              //     </TableBody>
              //   </Table>
              // </TableContainer>
              // )
              // :
              // (
              //   <Box>
              //     {
              //     parkedCars.cars.map((row, index) => (
              //       <Box mb={3}>
              //         <Card elevation={0} key={`car-${row.id}`}>
              //           <Box px={3} py={4}>
              //             <Box width="100%" mb={3}>
              //               <Box>
              //                 <Typography variant="h6" className={classes.cardHeading}>
              //                   # {index + 1}
              //                 </Typography>
              //               </Box>
              //             </Box>
              //             <Box width="100%" mb={3}>
              //               <Box>
              //                 <Typography variant="h6" className={classes.cardHeading}>
              //                   { t("parking-spot.parked-cars.check-in-time") }
              //                 </Typography>
              //               </Box>
              //               <Box>
              //                 <Typography variant="h6">
              //                   {moment(row.checkin).format('DD.MM.YYYY - HH:mm')}
              //                 </Typography>
              //               </Box>
              //             </Box>
              //             <Box width="100%" mb={3}>
              //               <Box>
              //                 <Typography variant="h6" className={classes.cardHeading}>
              //                   { t("car.plate") }
              //                 </Typography>
              //               </Box>
              //               <Box>
              //                 <Typography variant="h6">
              //                   {row.number_plate}
              //                 </Typography>
              //               </Box>
              //             </Box>
              //             <Box width="100%" mb={3}>
              //               <Box>
              //                 <Typography variant="h6" className={classes.cardHeading}>
              //                   { t("common.labels.full-name") }
              //                 </Typography>
              //               </Box>
              //               <Box>
              //                 <Typography variant="h6">
              //                   {row.full_name}
              //                 </Typography>
              //               </Box>
              //             </Box>
              //             <Box width="100%" mb={2}>
              //               <Box>
              //                 <Typography variant="h6" className={classes.cardHeading}>
              //                   { t("common.labels.phone") }
              //                 </Typography>
              //               </Box>
              //               <Box>
              //                 <Typography variant="h6">
              //                   {row.phone}
              //                 </Typography>
              //               </Box>
              //             </Box>
              //             <Box width="100%" display="flex" mb={2} alignItems="center">
              //               <Box width="50%">
              //                 <Typography variant="h6" className={classes.cardHeading}>
              //                   { t("payment.verified") }
              //                 </Typography>
              //               </Box>
              //               <Box width="50%" pl={2}>
              //                 <Checkbox color="primary" disabled={row.isDisabled} defaultChecked={!!row.verified} onClick={()=> {
              //                   setParkedCarCheckDiabled(row.id, true);
              //                   postParkedCarCheck(token, row.id, !row.verified, id, userType === "SubHost");
              //                 }}/>
              //               </Box>
              //             </Box>
              //           </Box>
              //         </Card>
              //       </Box>
              //     ))}
              //   </Box>
              // )
            ) : (<MissingDataPlaceholder/>)
          }
        </Box>
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userType: state.user.userType,
    parkedCars: state.parkingSpot.parkedCars,
  }
};

export default connect(mapStateToProps, { fetchParkingSpotCars,setParkedCarCheckDiabled, postParkedCarCheck ,addLoader })(ParkedCars);
