import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Divider, Box, CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useForm, Controller } from "react-hook-form";
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import OutlinedFormField from '../../common/outlinedFormField';
import { login, setPasswordResetRequestSent } from '../../../data/actions/auth';
import { useStyles } from '../common/styles';
import { useTranslation } from 'react-i18next';
import { setLoggingInUser } from '../../../data/actions/user';

import clsx from 'clsx';

const defaultValues = {
  email: "",
  password: "",
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = ({ login, setLoggingInUser, loggingInUser, setPasswordResetRequestSent, ...rest }) => {
  const classes = useStyles();
  const { handleSubmit, control } = useForm({ defaultValues });
  const { t } = useTranslation();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const query = useQuery();

  React.useEffect(() => {
    setPasswordResetRequestSent(null)
  }, [])

  const onSubmit = (data) => {
    setLoggingInUser(true);
    const searchParts = (rest.location.search.split("="))
    if (searchParts && searchParts[0] && searchParts[0] === "?tag" && searchParts[1] && searchParts[1].length > 0) {
      login(data, `/ps/${searchParts[1]}`);
    } else if (!!query.get('redirect')) {
      login(data, query.get('redirect'));
    }
    else {
      login(data);
    }
  };

  return (
    <Card className={classes.onboardingCard}>
      <Box display="flex" className={classes.onboardingTitle}>
        <Typography variant="h4" color="textPrimary">
          { t('common.texts.sign-in') }
        </Typography>
      </Box>
      <CardContent className={classes.onboardingContent}>
        <Box>
          <Typography variant="body2">
            { t('on-boarding.disclaimer') }
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.root} autoComplete="off">
          <OutlinedFormField label={ t('common.labels.email') } name="email" type="email" control={control} required />
          <OutlinedFormField label={ t('common.labels.password') } name="password" type="password" control={control} required />
          <Box className={classes.buttonWrapper} width="100%">
            <Button variant="contained" color="primary" type="submit" disabled={loggingInUser} fullWidth>
              { t('common.texts.sign-in') }
            </Button>
            { loggingInUser && <CircularProgress size={24} className={classes.buttonProgress} /> }
          </Box>
          <Box pt={1.5} display="flex" alignItems="center" justifyContent="space-between">
            <Button color='secondary' to={(rest.location.search.length > 0) ? `/signup-camper${rest.location.search}` : "/signup-camper"} component={Link} className={classes.registerButton}>
              <Typography variant="body2">{ t('on-boarding.click-to-register') }</Typography>
            </Button>
            <Button color='secondary' to="/forgotPassword" component={Link} className={classes.forgotPasswordButton}>
              <Typography variant="body2">{ t('on-boarding.forgot-password') }</Typography>
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  )
};

const mapStateToProps = (state) => {
  return {
    loggingInUser: state.user.loggingInUser,
  };
};

export default connect(mapStateToProps, { login, setLoggingInUser, setPasswordResetRequestSent })(Login);
