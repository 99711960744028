import * as Types from './constants';

export const changeI18nLanguage = (i18n, language) => {
  return {
    type: Types.CHANGE_I18N_LANGUAGE,
    i18n,
    language,
  };
};

export const setActiveLanguage = (language) => {
  return {
    type: Types.SET_ACTIVE_LANGUAGE,
    language
  };
};

export const setAppVersion = (version) => {
  return {
    type: Types.SET_APP_VERSION,
    version,
  };
};

export const addLoaderForItem = (item) => {
  return {
    type: Types.ADD_LOADER_FOR_ITEM,
    item
  };
};

export const removeLoaderForItem = (item) => {
  return {
    type: Types.REMOVE_LOADER_FOR_ITEM,
    item
  };
};

export const addLoader = (item) => {
  return {
    type: Types.ADD_LOADER,
  };
};

export const addLoaderForPhotos = () => {
  return {
    type: Types.ADD_LOADER_FOR_PHOTOS,
  };
};

export const removeLoader = (item) => {
  return {
    type: Types.REMOVE_LOADER,
  };
};

export const removeLoaderForPhotos = (item) => {
  return {
    type: Types.REMOVE_LOADER_FOR_PHOTOS,
  };
};
