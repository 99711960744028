let url = "";

if (process.env.REACT_APP_STAGE === "prod") {
  url = "https://api.parknsleep.app";
} else if (process.env.REACT_APP_STAGE === "stage") {
  url = "https://staging-api.parknsleep.app";
} else {
  url = "http://localhost:3001";
}

export const BACKEND_API = url;