import {ReactComponent as FlagEN} from "../../../assets/images/flag-en.svg";
import {ReactComponent as FlagDE} from "../../../assets/images/flag-de.svg";
import {ReactComponent as FlagFR} from "../../../assets/images/flag-fr.svg";
import {ReactComponent as FlagIT} from "../../../assets/images/flag-it.svg";
import {ReactComponent as FlagPL} from "../../../assets/images/flag-pl.svg";
import {ReactComponent as FlagES} from "../../../assets/images/flag-es.svg";
import {ReactComponent as FlagPT} from "../../../assets/images/flag-pt.svg";
import {ReactComponent as FlagNL} from "../../../assets/images/flag-nl.svg";
import {ReactComponent as FlagSV} from "../../../assets/images/flag-sv.svg";
import {ReactComponent as FlagCS} from "../../../assets/images/flag-cs.svg";

const flags = (language) => {
  switch (language) {
    case "en":
      return <FlagEN />
    case "de":
      return <FlagDE />
    case "fr":
      return <FlagFR />
    case "it":
      return <FlagIT />
    case "pl":
      return <FlagPL />
    case "es":
      return <FlagES />
    case "pt":
      return <FlagPT />
    case "nl":
      return <FlagNL />
    case "sv":
      return <FlagSV />
    case "cs":
      return <FlagCS />
    default:
      return null;
  }
}

export default flags;
