import { Box, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import BalanceCard from "components/common/balanceCard/BalanceCard";
import { guestWithdrawalsQuerySelect } from "data/selectors/payment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { amountForCurrency } from "../utils/misc";

const useStyles = makeStyles((theme) => ({
  balanceCardItem: {
    width: "100%",
  },
}));

function GuestBalanceGrid() {
  const classes = useStyles();
  const { t } = useTranslation();
  const balanceQueryResult = useSelector(guestWithdrawalsQuerySelect);
  if (
    !balanceQueryResult ||
    !balanceQueryResult.data ||
    balanceQueryResult.isLoading ||
    balanceQueryResult.isError
  ) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  const balanceStatus = balanceQueryResult.data.balance_status;

  return (
    <Grid container spacing={3}>
      <Grid className={classes.balanceCardItem} item sm={12} md={4}>
        <BalanceCard
          title={t("financial.pending-balance")}
          description={t("financial.pending-balance-sub-text")}
          amount={amountForCurrency(
            balanceStatus?.pending_balance || 0,
            balanceStatus?.currency || ""
          )}
        />
      </Grid>
      <Grid className={classes.balanceCardItem} item sm={12} md={4}>
        <BalanceCard
          title={t("financial.available-balance")}
          description={t("financial.available-balance-sub-text")}
          amount={amountForCurrency(
            balanceStatus?.available_balance || 0,
            balanceStatus?.currency || ""
          )}
        />
      </Grid>
      <Grid className={classes.balanceCardItem} item sm={12} md={4}>
        <BalanceCard
          title={t("financial.overall-balance-card")}
          description={t("financial.overall-balance-sub-text")}
          amount={amountForCurrency(
            balanceStatus?.pending_balance + balanceStatus.available_balance ||
              0,
            balanceStatus?.currency || ""
          )}
        />
      </Grid>
    </Grid>
  );
}

export default GuestBalanceGrid;
