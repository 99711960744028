import {
  Box,
  Card,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useStyles } from "components/dashboard/common/styles";
import React from "react";

function BalanceCard({ title, description, amount }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card elevation={0} className={classes.financialsCard}>
      <Box>
        <Box mt={1} py={2} px={3} height={isMobile ? "9rem" : "8rem"}>
          <Box pb={1}>
            <Typography variant="h3" style={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">{description}</Typography>
          </Box>
        </Box>
        <Box
          py={1.25}
          px={1.5}
          className={classes.financialsCardAmountContainer}
        >
          <Typography variant="h3" className={classes.financialsCardAmount}>
            {amount}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default BalanceCard;
