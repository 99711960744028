import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Container, CircularProgress } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useStripe, useElements, CardNumberElement, Elements} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStyles } from '../common/styles';
import LabelledFormField from '../../common/formFieldWithSeparateLabel';
import TitleSection from '../../common/titleSection';
import PaymentFields from '../../common/paymentFields';
import { updateGuest, fetchGuestProfile, setUpdatingUser } from '../../../data/actions/user';
import { addLoaderForItem } from '../../../data/actions/config';
import { fetchGuestPaymentDetails, setCardPaymentFailed, setRepaymentFailedDialogOpen, fetchPaymentSecret } from '../../../data/actions/payment';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import poweredByStripe from '../../../assets/images/powered-by-stripe.svg';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { addLoader } from '../../../data/actions/config';
import { enqueueSnackbar } from '../../../data/actions/global';

import { STRIPE_PUBLISHABLE_KEY } from '../../../publicKeys';

const defaultValues = {
  cardName: "",
};

const Payment = ({ user, token, updateGuest, guestPaymentDetails, fetchGuestProfile, fetchGuestPaymentDetails, cardPaymentFailed, setCardPaymentFailed, setUpdatingUser, updatingUser, addLoader, repaymentFailedDialogOpen, setRepaymentFailedDialogOpen, enqueueSnackbar, paymentSecret, fetchPaymentSecret, addLoaderForItem }) => {
  const stripe = useStripe();
  const classes = useStyles();
  const elements = useElements();
  const [change, setChange] = useState(false);
  const attached = Boolean(user.guestDetails && user.guestDetails.stripe_payment_method_id);
  const { handleSubmit, control } = useForm({ defaultValues });
  const { t } = useTranslation();
  const [addCardPaymentDialogOpen, setaddCardPaymentDialogOpen] = useState(false);

  useEffect(() => {
    if (guestPaymentDetails && guestPaymentDetails.status === "suspended"){
      setCardPaymentFailed(true);
    }
    else {
      setCardPaymentFailed(false);
    }
  }, [guestPaymentDetails])

  useEffect(() => {
    return () => {
      setRepaymentFailedDialogOpen(false);
      setCardPaymentFailed(false);
    }
  }, []);

  useEffect(() => {
    if (user.userType === "Guest") {
      fetchGuestProfile(token);
      addLoader();
      fetchGuestPaymentDetails(token);
    }
  }, [user.userType, fetchGuestProfile, fetchGuestPaymentDetails, token]);

  useEffect(() => {
    if (user.userType === "Guest") {
      addLoaderForItem("stripe-secret");
      fetchPaymentSecret(token);
    }
  }, [user.userType, fetchPaymentSecret, addLoaderForItem, token]);

  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onSubmit = async (data) => {

    if (!stripe || !elements) {
      return;
    }

    if (!paymentSecret) {
      enqueueSnackbar({
        message: {
          text: t("payment.add-method-error"),
          variant: "error",
        },
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      })
      return
    }

    setRepaymentFailedDialogOpen(false);

    const result = await stripe.confirmCardSetup(
      paymentSecret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: { name: data.cardName }
        }
      }
    )

    if (result?.error) {
      enqueueSnackbar({
        message: {
          text: t("payment.add-method-error"),
          variant: "error",
        },
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      })
    } else {
      enqueueSnackbar({
        message: {
          text: t("payment.add-method-success"),
          variant: "success",
        },
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
        },
      })
      setUpdatingUser(true);
      updateGuest({
        guestDetailId: user.guestDetails.id,
        stripePaymentMethodId: result?.setupIntent?.payment_method?.id,
      }, token, null, "/dashboard")
    }
    if (user.userType === "Guest") {
      addLoaderForItem("stripe-secret");
      fetchPaymentSecret(token);
    }
    setaddCardPaymentDialogOpen(false);
  };

  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TitleSection
          routes={[{
            name: t("common.texts.dashboard"),
            path: "/dashboard",
          }]}
          title={ t("payment.title") }
        />
        <Box display={isMobile ? "none" : "initial"}>
          <img src={poweredByStripe} alt="Powered by Stripe" className={classes.titleLogo} style={{
            width: "7rem"
          }}/>
        </Box>
      </Box>
      <Divider />
      <Box className={classes.itemsWrapper}>
        <Box mt={3} display={!isMobile ? "none" : "flex"}>
          <img src={poweredByStripe} alt="Powered by Stripe" className={classes.titleLogo} style={{
            width: "7rem"
          }}/>
        </Box>
        <form>
          <PaymentFields
            onboarding={false}
            attached={attached}
            last4={guestPaymentDetails.last4}
            exp_month={guestPaymentDetails.exp_month}
            exp_year={guestPaymentDetails.exp_year}
            setaddCardPaymentDialogOpen={setaddCardPaymentDialogOpen}
            dontShowChangeCard={true}
            paymentDetails={guestPaymentDetails}
            cardPaymentFailed={cardPaymentFailed}
          />
        </form>
        <Box pt={3} display="flex">
          <Button variant="contained" color="primary" onClick={() => {setaddCardPaymentDialogOpen(true)}}>
            { attached ? t("payment.change-card") : t("payment.add-card") }
          </Button>
        </Box>
      </Box>

      {/* Add Card Payment Dialog */}
      <Dialog open={addCardPaymentDialogOpen} onClose={
        () => {
          setaddCardPaymentDialogOpen(false);
        }}
      aria-labelledby="payment-add-dialog-title" className={classes.addPaymentDialog} onEnter={() => setChange(true)}>

        <Box>
          <DialogTitle
            id="payment-add-dialog-title"
            className={classes.dialogTitleBorderBottom}
            classes={{
              root: classes.dialogTitleRoot,
            }}
          >
            <Typography variant="h2">
              { t("payment.add-payment-method") }
            </Typography>
          </DialogTitle>

          <DialogContent className={classes.dialogContent} style={{ paddingBottom: 0 }}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" style={{overflow: "hidden"}}>
              <Box width="100%" pr='0'>
                <form>
                  <Box mt="-1.6rem">
                    <LabelledFormField label={ t("payment.fields.card-name") } name="cardName" control={control} />
                  </Box>
                  <PaymentFields
                    onboarding={false}
                    attached={attached}
                    last4={guestPaymentDetails.last4}
                    exp_month={guestPaymentDetails.exp_month}
                    exp_year={guestPaymentDetails.exp_year}
                    change={change}
                    setChange={setChange}
                    dontShowChangeCard={true}
                  />
                </form>
                { repaymentFailedDialogOpen && (
                  <Box pt={1} pl={0.5}>
                    <Typography variant="body2" style={{color: "#f54646"}}>{t("payment.payment-failed")}</Typography>
                  </Box>
                )}
              </Box>
              {/*{!isMobile &&*/}
              {/*  <Box width="50%">*/}
              {/*    <SvgIcon component={PaymentAddCard} viewBox="-2 5 500 278" className={classes.paymentCardSvg} />*/}
              {/*  </Box>*/}
              {/*}*/}
            </Box>
          </DialogContent>

          <Box className={classes.addPaymentBtn} mb={1}>
            <Box width="50%" className={classes.buttonWrapper} style={{margin: 0}}>
              <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary" fullWidth disabled={updatingUser}>
                <Typography variant="button">
                  {t("common.btn.done")}
                </Typography>
              </Button>
              { updatingUser && <CircularProgress size={24} className={classes.buttonProgress} /> }
            </Box>
            <Box pl={1} width="50%" className={classes.buttonWrapper} style={{margin: 0}}>
              <Button variant="outlined" fullWidth onClick={()=> setaddCardPaymentDialogOpen(false)}>
                <Typography variant="button">
                  {t("common.btn.cancel")}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.token,
    guestPaymentDetails: state.payment.guestPaymentDetails,
    cardPaymentFailed: state.payment.cardPaymentFailed,
    updatingUser: state.user.updatingUser,
    repaymentFailedDialogOpen: state.payment.repaymentFailedDialogOpen,
    paymentSecret: state.payment.paymentSecret,
  };
};

const StripeWrapper = (props) => {
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

  const options = {
    fonts: [
      {
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap"
      }
    ],
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <Payment {...props} />
    </Elements>
  )
}

export default connect(mapStateToProps, { updateGuest, fetchGuestProfile, fetchGuestPaymentDetails, setCardPaymentFailed, setUpdatingUser, addLoader, setRepaymentFailedDialogOpen, enqueueSnackbar, fetchPaymentSecret, addLoaderForItem })(StripeWrapper);
