import { SvgIcon } from "@material-ui/core";
import React from "react";

function LinkIcon() {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path
        d="M19.072 14.8287C18.6809 15.2193 18.0471 15.2189 17.6565 14.8277C17.2661 14.4368 17.2663 13.8034 17.657 13.4127L18.3638 12.706C18.8314 12.2424 19.2029 11.6911 19.4568 11.0836C19.7108 10.4761 19.8423 9.8244 19.8437 9.16595C19.8452 8.5075 19.7166 7.85525 19.4652 7.24665C19.2139 6.63804 18.8449 6.08507 18.3793 5.61947C17.9137 5.15387 17.3607 4.78483 16.7521 4.53351C16.1435 4.2822 15.4913 4.15357 14.8328 4.15502C14.1744 4.15646 13.5227 4.28794 12.9152 4.54192C12.3077 4.79589 11.7563 5.16736 11.2928 5.63499L10.5854 6.34286C10.1951 6.7334 9.56218 6.73362 9.17164 6.34336C8.78082 5.95281 8.78093 5.31928 9.17189 4.92886L9.87977 4.22199C11.1926 2.90917 12.9732 2.17163 14.8298 2.17163C16.6864 2.17163 18.4669 2.90917 19.7798 4.22199C21.0926 5.53481 21.8301 7.31538 21.8301 9.17199C21.8301 11.0286 21.0926 12.8092 19.7798 14.122L19.072 14.8287ZM14.8276 17.6569C15.2186 18.0473 15.2187 18.6808 14.8279 19.0714L14.1208 19.778C12.8079 21.0908 11.0274 21.8283 9.17077 21.8283C7.31416 21.8283 5.53359 21.0908 4.22077 19.778C2.90795 18.4652 2.17041 16.6846 2.17041 14.828C2.17041 12.9714 2.90795 11.1908 4.22077 9.87799L4.92764 9.17161C5.31869 8.78084 5.95251 8.78118 6.34314 9.17236C6.73348 9.56325 6.73326 10.1965 6.34264 10.5871L5.63577 11.294C5.16814 11.7575 4.79667 12.3089 4.5427 12.9164C4.28872 13.5239 4.15724 14.1756 4.1558 14.834C4.15435 15.4925 4.28298 16.1447 4.53429 16.7533C4.78561 17.3619 5.15465 17.9149 5.62025 18.3805C6.08585 18.8461 6.63882 19.2152 7.24743 19.4665C7.85603 19.7178 8.50828 19.8464 9.16673 19.845C9.82518 19.8435 10.4769 19.712 11.0844 19.4581C11.6919 19.2041 12.2432 18.8326 12.7068 18.365L13.4144 17.6574C13.8046 17.2672 14.4372 17.2669 14.8276 17.6569ZM14.1203 8.46446C14.511 8.07374 15.1445 8.07374 15.5352 8.46446C15.926 8.85521 15.926 9.48874 15.5352 9.87946L9.87879 15.5351C9.48826 15.9255 8.85518 15.9256 8.46454 15.5353C8.07369 15.1447 8.07358 14.5112 8.46429 14.1205L14.1203 8.46446Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default LinkIcon;
