import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";
import SaveIcon from "@material-ui/icons/Save";
import { useStyles as useCommonStyles } from "components/dashboard/common/styles";
import FrequencyBarChart from "./FrequencyBarChart";
import PriceSlider from "./PriceSlider";
import PriceInput from "./PriceInput";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPriceRangeFilter, setPrice } from "data/actions/parkingSpot";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';

function roundDownToNearest5(num) {
  return Math.floor(num / 5) * 5;
}

function roundUpToNearest5(num) {
  return Math.ceil(num / 5) * 5;
}

const useStyles = makeStyles((theme) => ({
  histogram: {
    margin: "auto",
  },
  slider: {
    marginTop: "-8px",
  },
  dialogContent: {
    padding: theme.spacing(4),
  },
  dialogTitleRoot: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    '& h2': {
      fontWeight: "500",
      fontSize: theme.typography.h3.fontSize,
    }
  },
  fixedWidthBox: {
    minWidth: "95px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    }
  },
  reset: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
    }
  },
  icon: {
    "& svg": {
      fill: theme.palette.common.white,
    },
  },
}));

function PriceFilterDialog({ filter, open, onClose }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const priceRangeFilter = useSelector(
    (state) => state.parkingSpot.priceRangeFilter
  );
  const price = useSelector(
    (state) => state.parkingSpot.price
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (priceRangeFilter) {
      dispatch(setPrice([priceRangeFilter.from, priceRangeFilter.to]));
    } else if (filter) {
      dispatch(setPrice([0, 100]));
    }
  }, [priceRangeFilter?.from, priceRangeFilter?.to]);

  const updatePrice = () => {
    dispatch(
      setPriceRangeFilter({
        from: price[0],
        to: price[1],
      })
    );
    onClose();
  };

  const resetPrice = () => {
    dispatch(setPrice([0, 100]));
    dispatch(setPriceRangeFilter(null));
  };

  const handlePriceChange = (event, newValue) => {
    dispatch(setPrice(newValue));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle
        className={commonClasses.dialogTitleBorderBottom}
        classes={{
          root: classes.dialogTitleRoot,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{t("price-filter")}</Typography>
          <Button variant="outlined" size="small" onClick={onClose}>
            <CloseIcon flex="auto" color="primary" />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.histogram}>
          <FrequencyBarChart
            range={{
              min: 0,
              max: 100,
            }}
            data={filter.price_filters}
          />
          <PriceSlider
            className={classes.slider}
            value={price}
            onChange={handlePriceChange}
            step={5}
            min={0}
            max={100}
          />
        </Box>
        {price?.length && (
          <>
            <Box mt={1} mb={3} display="flex" justifyContent="center">
              <Box
                display="flex"
                justifyContent="center"
                mb={isMobile ? 1 : 0}
              >
                <Typography variant="body1">
                 {price[0]} €
                </Typography>
                <Typography variant="body1">
                  &nbsp;{t("parking-spot.to")}&nbsp;
                </Typography>
                <Typography variant="body1">
                  {price[1] === 100 ? `${price[1]}+` : price[1]} €
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" flexDirection={isMobile ? "column" : "row"}>
              <div className={classes.fixedWidthBox}>
                <Button
                  classes={{
                    startIcon: classes.icon,
                  }}
                  startIcon={<SaveIcon color="inherit" />}
                  color="primary"
                  variant="contained"
                  onClick={updatePrice}
                  fullWidth={true}
                >
                  {t("save")}
                </Button>
              </div>
              <div className={`${classes.fixedWidthBox} ${classes.reset}`}>
                <Link component="button" onClick={resetPrice}>
                  <Typography variant="button">{t("reset")}</Typography>
                </Link>
              </div>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default PriceFilterDialog;
