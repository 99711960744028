import React from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Divider, Container, Typography, Box, Button } from "@material-ui/core";
import FormField from "../../common/formField";
import { useStyles } from "../common/styles";
import TitleSection from "../../common/titleSection";
import { changePassword } from "../../../data/actions/auth";
import { useTranslation } from "react-i18next";

const defaultValues = {
  currentPassword: "",
  newPassword: "",
};

const Security = ({ token, changePassword }) => {
  const classes = useStyles();
  const { handleSubmit, control } = useForm({ defaultValues });
  const { t } = useTranslation();

  const onSubmit = (data) => {
    changePassword(data, token);
  };

  return (
    <Container maxWidth="md">
      <TitleSection
        routes={[
          {
            name: t("common.texts.dashboard"),
            path: "/dashboard",
          },
        ]}
        title={t("security.title")}
      />
      <Divider />

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.root}
        autoComplete="off"
      >
        <Box
          display="flex"
          pt={4}
          flexDirection="column"
          className={classes.itemsWrapper}
        >
          <Box>
            <Typography variant="h3">{t("security.reset-password")}</Typography>
          </Box>
          <FormField
            label={t("security.current-password")}
            name="currentPassword"
            type="password"
            control={control}
            required
          />
          <FormField
            label={t("security.new-password")}
            name="newPassword"
            type="password"
            control={control}
            required
          />
        </Box>
        <Box pt={2}>
          <Typography variant="body1" color="secondary">
            {t("security.validation-help-text")}
          </Typography>
        </Box>
        <Box pt={3} display="flex">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.primaryButton}
          >
            {t("common.btn.save-changes")}
          </Button>
        </Box>
      </form>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps, {
  changePassword,
})(Security);
