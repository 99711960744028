import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  formGroup: {
    flexDirection: "row",
  },
  label: {
    fontSize: "0.75rem",
    color: theme.palette.secondary.dark,
  },
  formControlLabel: {
    width: "48%",
    [theme.breakpoints.up("md")]: {
      width: "24%",
    },
    "&.fullWidth": {
      width: "100%",
    },
  },
  formLabel: {
    fontWeight: 500,
    fontSize: "0.75rem",
    padding: "0.5em 0em",
  },
  formDescription: {
    fontWeight: 400,
    fontSize: "0.75rem",
    padding: "0.75rem 0rem 0.5rem",
  }
}));
