const styles = (theme) => ({
  appBar: {
    borderBottom: "1px solid #E7F0F0",
    backgroundColor: "#FFF",
    zIndex: 1400,
    position: "fixed",
    top: "0",
  },
  toolbar: {
    // minHeight: "5.5rem",
    [theme.breakpoints.down("md")]: {
      "&.MuiToolbar-gutters": {
        padding: "0.75rem 1.5rem !important"
      }
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "4.5rem",
    },
    color: theme.palette.primary.main,
    justifyContent: "space-between",
    "&.MuiToolbar-gutters": {
      paddingTop: "0.75rem !important",
      paddingBottom: "0.75rem !important"
    }
  },
  toolbarSearch: {
    // minHeight: "5.5rem",
    [theme.breakpoints.down("sm")]: {
      minHeight: "4.5rem",
    },
    color: theme.palette.primary.main,
    justifyContent: "space-between",
    paddingLeft: "2.0rem !important",
    paddingRight: "2.0rem !important",
    paddingTop: "0.75rem !important",
    paddingBottom: "0.75rem !important",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5rem !important",
      paddingRight: "1.5rem !important",
    }
  },
  title: {
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "125px",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  titleLogo: {
    width: "100%",
  },
  languageMenuItem: {
    padding: "14px 16px",
    "& svg": {
      marginRight: "8px",
      height: "16px",
      width: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      "& svg": {
        marginRight: "0px",
      },
    }
  },
  popoverExtendedSpace: {
    "& .MuiPaper-root": {
      marginTop: "21px",
    }
  },
  popover: {
    pointerEvents: "auto",
    top: "-1.3rem !important",
    "& .MuiPaper-root": {
      marginTop: "17px",
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiPaper-root": {
        width: "auto !important",
        minWidth: "1rem !important",
        maxWidth: "3.75rem !important",
      }
    },
    [theme.breakpoints.between("600", "sm")]: {
      "& .MuiPaper-root": {
        width: "auto !important",
        minWidth: "1rem !important",
        maxWidth: "3.75rem !important"
      }
    }
  },
  languageStartIcon: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
    }
  },
  optFlags: {
    fontSize: "20px",
    marginRight: "8px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
    }
  },
  optLanguages: {
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "0.01em"
  },
  searchBox: {
    position: "relative",
    zIndex: 99,
    [theme.breakpoints.down('sm')]: {
      marginTop: "1.5rem",
    },
  },
  geo: {
    [theme.breakpoints.up("md")]: {
      height: "46px",
    },
    flex: "auto",
  },
  geoInput: {
    height: "46px",
    [theme.breakpoints.down('sm')]: {
      height: "2.7rem",
    },
    flex: "auto",
    fontSize: "14px",
    color: "#0A0924",
    alignItems: "center",
    border: "1px solid #E7F0F0",
    borderRadius: "12px",
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    transition: "box-shadow 50ms ease-in",
    "&:focus": {
      outline: "none !important",
      boxShadow: `0 0 0.0625rem 0.125rem ${theme.palette.primary.main}`,
      transition: "box-shadow 100ms ease-out",
    },
    "&:hover": {
      backgroundColor: "#F2F9FA"
    }
  },
  geoInputEdit: {
    height: "2.8rem",
    [theme.breakpoints.down('xs')]: {
      height: "3.0rem",
    },
    flex: "auto",
    alignItems: "center",
    border: "1px solid #ddd",
    borderRadius: "0.75rem",
    fontFamily: theme.typography.fontFamily,
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    "&:focus": {
      outline: "none",
    }
  },
  geoSuggests: {
    backgroundColor: "#fff",
    color: theme.palette.text.secondary,
    cursor: "pointer",
    borderRadius: "1rem",
    boxShadow: "0px 3px 3px 2px rgb(0 0 0 / 5%)",
    border: "1px solid #E7F0F0",
    padding: 0,
    overflow: "hidden",
    [theme.breakpoints.down('xs')]: {
      position: "absolute",
      width: "90vw",
    },
  },
  geoSuggestsEdit: {
    backgroundColor: "#fff",
    borderRadius: "0.8rem",
    boxShadow: "0px 3px 3px 2px rgb(0 0 0 / 5%)",
    border: "1px solid #E7F0F0",
    padding: 0,
    margin: "0.7rem 0rem",
    overflow: "hidden",
    zIndex: 999,
    position: "relative",
    // height: "19rem",
    [theme.breakpoints.down('xs')]: {
      position: "absolute",
      width: "90vw",
      // height: "24rem",
    },
  },
  geoSuggestsHidden: {
    display: "none",
  },
  geoSuggestItem: {
    alignItems: "center",
    display: "flex",
    listStyle: "none",
    padding: "0.8rem 0.6rem",
    fontSize: "0.7rem",
    borderBottom: "1px solid #E7F0F0",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  geoSuggestItemActive: {
    backgroundColor: "#E7F0F0",
    listStyle: "none",
    padding: "0.8rem 0.6rem",
    fontSize: "0.7rem",
    borderBottom: "1px solid #E7F0F0",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  filterChips: {
    flexWrap: "wrap",
  },
  filterChip: {
    display: "flex",
    alignItems: "center",
    padding: "1em",
    backgroundColor: "#F2F9FA",
    borderRadius: "12px",
    marginRight: "1em",
    marginTop: "1em",
    "& svg": {
      height: "1rem",
      width: "1rem",
      fill: theme.palette.primary.main,
    }
  },
  filterChipIcon: {
    marginRight: "1em",
  },
  resultIcon: {
    marginRight: "0.5rem",
    height: "1rem",
    width: "1rem",
  },
  searchContainerIcon: {
    fill: theme.palette.primary.main,
    fontSize: "1.25rem",
    position: "absolute",
    width: "1rem",
    height: "1rem",
    top: "0.9rem",
    [theme.breakpoints.down("sm")]: {
      top: "0.75rem",
    },
    right: "0.7rem",
    cursor: "pointer"
  },
  socialMenuItem: {
    textDecoration: "none",
  },
  socialLinkIcon: {
    fontSize: "1rem",
    marginRight: "0.5rem"
  },
  buttonXS: {
    minWidth: "32px",
    width: "2.5rem",
    height: "2.5rem",
    padding: 0,
    margin: "0.1875rem",
    [theme.breakpoints.up("sm")]: {
      minWidth: "32px",
      width: "3.0rem",
      height: "3.0rem",
      padding: 0,
      margin: "0.2rem",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "32px",
      width: "3.0rem",
      height: "3.0rem",
      padding: 0,
      marginLeft: "0.2rem",
    }
  },
  cardIcons: {
    marginRight: "0px !important",
    fontSize: "1.25rem",
    fill: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      fontSize: "1.0rem",
    }
  },
  cardIntercomChatIcon: {
    height: "24px !important",
    width: "24px !important",
    marginRight: "0px !important",
    fontSize: "1.25rem",
    fill: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0rem",
      height: "16px !important",
      width: "16px !important",
    }
  },
  backIcon: {
    marginRight: "0px !important",
    fontSize: "1.0rem",
    color: theme.palette.secondary.dark,
  },
  helpIconMobile: {
    marginRight: "0px !important",
    fontSize: "1.125rem",
    marginTop: "0.25rem"
  },
  previousSearch: {
    paddingLeft: "0.25rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "10rem",
    [theme.breakpoints.up("mobile")]: {
      width: "15rem"
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      maxWidth: "25rem"
    }
  },
  shareMenu: {
    "& .MuiPopover-paper": {
      width: "100% !important"
    }
  },
  headerButtonsContainer: {
    [theme.breakpoints.up("sm")]: {
      height: "46px",
      "& button": {
        height: "46px"
      },
      "& >div": {
        height: "46px"
      },
      "& a": {
        height: "46px"
      }
    },
  }
});

export default styles;
