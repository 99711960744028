import React, { useEffect } from "react";
import {
  Divider,
  Container,
  Box,
  Typography,
  Card,
  Button,
  Tooltip,
  CardActionArea,
} from "@material-ui/core";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SettingsIcon from "@material-ui/icons/Settings";
import { Link } from "react-router-dom";
import ConfirmDialog from "components/common/confirmDialog";
import { useStyles } from "../common/styles";
import TitleSection from "../../common/titleSection";
import Switch from "../../common/switch";
import { connect } from "react-redux";
import {
  fetchParkingSpots,
  enableParkingSpot,
  disableParkingSpot,
  setGeolocationEditParkingSpot,
  setAddressEditParkingSpot,
  setParkingSpotDetails,
} from "../../../data/actions/parkingSpot"; 
import MissingDataPlaceholder from "../../common/missingDataPlaceholder";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";

const ParkingSpots = ({
  token,
  user,
  userType,
  parkingSpots,
  fetchParkingSpots,
  enableParkingSpot,
  disableParkingSpot,
  setGeolocationEditParkingSpot,
  setAddressEditParkingSpot,
  setParkingSpotDetails,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let isSmallMobile = useMediaQuery(theme.breakpoints.down(370));
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const [activeParkingSpot, setActiveParkingSpot] = React.useState("");

  const handleConfirmDialogOpen = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleConfirmAction = () => {
    disableParkingSpot(activeParkingSpot, token);
    handleConfirmDialogClose();
  };

  useEffect(() => {
    setParkingSpotDetails(null, true);
  }, []);

  useEffect(() => {
    if (!!userType) {
      fetchParkingSpots(token, userType);
    }
  }, [fetchParkingSpots, userType, token]);

  useEffect(() => {
    setGeolocationEditParkingSpot(null);
    setAddressEditParkingSpot([]);
  }, [setGeolocationEditParkingSpot, setAddressEditParkingSpot]);

  const handleEnableDisable = (event, id) => {
    if (event.currentTarget.checked) {
      enableParkingSpot(id, token);
    } else {
      setActiveParkingSpot(id);
      handleConfirmDialogOpen();
    }
  };

  return (
    <>
      <ConfirmDialog
        open={isConfirmDialogOpen}
        onClose={handleConfirmDialogClose}
        title={t("parking-spot.disable-dialog.title")}
        description={t("parking-spot.disable-dialog.description")}
        confirmText={t("parking-spot.disable-dialog.btn-yes")}
        cancelText={t("parking-spot.disable-dialog.btn-no")}
        onConfirm={handleConfirmAction}
      />
      <Container maxWidth="md">
        <Box display="flex">
          <TitleSection
            routes={[
              {
                name: t("common.texts.dashboard"),
                path: "/dashboard",
              },
            ]}
            title={t("parking-spot.title")}
          />
          {userType === "Host" && (
            <Box display="flex" justifyContent="flex-end" flex={1}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                {!isMobile && (
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/ps/new"
                  >
                    <Typography variant="button">
                      {t("parking-spot.title-create")}
                    </Typography>
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Box>
        <Divider />
        <Box
          display="flex"
          flexWrap="wrap"
          mt={2}
          marginLeft="-1.5rem"
          marginRight="-1.5rem"
          pt={isMobile ? 3 : 1}
          flexDirection="row"
        >
          {parkingSpots.map((spot) => {
            return (
              <Box
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                my={1}
                component={Card}
                elevation={0}
                key={`parking-spot-row-${spot.id}`}
                className={classes.checkinCardWrapper}
              >
                <CardActionArea
                  className={classes.cardAction}
                  disableRipple
                  style={{ backgroundColor: "transparent", cursor: "default" }}
                >
                  <Box className={classes.parkingSpotCardLinkInner}>
                    <Box className={classes.checkinCardPictureWrapper}>
                      <img
                        className={classes.checkinCardPicture}
                        src={spot.main_photo_thumbnail_url}
                        alt="spot thumbnail"
                      />
                    </Box>
                    <Box className={classes.parkingSpotListDetails}>
                      <Box display="flex" alignItems="center" mb={0}>
                        <Typography
                          variant="h3"
                          flex="auto"
                          className={classes.psCardTitle}
                        >
                          {spot.name}
                        </Typography>
                        {userType === "Host" && (
                          <Tooltip
                            title={
                              spot.verified
                                ? (spot.enabled
                                  ? (!!spot.spot_active) ? t("parking-spot.spot-active") : t("parking-spot.spot-paused")
                                  : t("parking-spot.disabled-by-admin"))
                                : t("parking-spot.not-verified")
                            }
                            placement="top"
                            arrow
                          >
                            <Box pl={1}>
                              <Switch
                                className={classes.switch}
                                checked={(!!spot.spot_active) & spot.enabled & spot.verified}
                                onChange={(event) =>
                                  handleEnableDisable(event, spot.id)
                                }
                              />
                            </Box>
                          </Tooltip>
                        )}
                      </Box>
                      <Box className={classes.parkingSpotListActions}>
                        <Box
                          width={"100%"}
                          flex="1 1 auto"
                          pb={2}
                          pt={isMobile ? 0 : 2}
                        >
                          <Button
                            variant="outlined"
                            component={Link}
                            className={clsx(classes.listButton, "large")}
                            to={`ps/${spot.id}/cars`}
                            fullWidth={true}
                          >
                            <InsertChartIcon
                              className={classes.buttonIconPsList}
                            />
                            {t("parking-spot.parked-cars.title")}
                          </Button>
                        </Box>
                        <Box
                          display="flex"
                          minWidth={isSmallMobile ? "100%" : "43%"}
                          flex="1"
                          pb={isSmallMobile ? 2 : 0}
                          mr={
                            userType === "Host" ? (isMobile ? "1.0rem" : 2) : 0
                          }
                        >
                          <Box width="100%">
                            <Button
                              variant="outlined"
                              className={classes.listButton}
                              component={Link}
                              to={`/ps/${spot.tag}`}
                              target="_blank"
                              fullWidth={isMobile ? true : false}
                            >
                              <VisibilityIcon
                                className={classes.buttonIconPsList}
                              />
                              {t("common.btn.view")}
                            </Button>
                          </Box>
                        </Box>
                        {userType === "Host" && (
                          <Box
                            minWidth={isSmallMobile ? "100%" : "43%"}
                            flex="1"
                          >
                            <Box width={isMobile ? "100%" : "inherit"}>
                              <Button
                                variant="outlined"
                                component={Link}
                                className={classes.listButton}
                                to={`/ps/${spot.tag}/edit`}
                                fullWidth={isMobile ? true : false}
                                style={{ marginRight: "0" }}
                              >
                                <SettingsIcon
                                  className={classes.buttonIconPsList}
                                />
                                {t("common.btn.edit")}
                              </Button>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </CardActionArea>
              </Box>
            );
          })}
          {parkingSpots.length === 0 && (
            <Box width="100%" mx={3}>
              <MissingDataPlaceholder />
            </Box>
          )}
          {isMobile && userType === "Host" && (
            <Box width="100%" py={3} px={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                component={Link}
                to="/ps/new"
              >
                { t("parking-spot.title-create") }
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    parkingSpots: state.parkingSpot.parkingSpots,
    user: state.user,
    userType: state.user.userType,
  };
};

export default connect(mapStateToProps, {
  fetchParkingSpots,
  setGeolocationEditParkingSpot,
  setAddressEditParkingSpot,
  enableParkingSpot,
  disableParkingSpot,
  setParkingSpotDetails,
})(ParkingSpots);
