import { ofType } from 'redux-observable';
import { mergeMap, flatMap, concatMap, catchError, takeUntil } from 'rxjs/operators';
import { push } from 'connected-react-router'

import * as Types from '../actions/constants';
import { BACKEND_API } from './hosts';
import API from '../../lib/api';
import * as ParkingSpotActions from '../actions/parkingSpot';
import * as PaymentActions from '../actions/payment';
import * as VoucherActions from '../actions/voucher';
import { removeLoader, setAppVersion, removeLoaderForItem, removeLoaderForPhotos } from '../actions/config';

export const postParkingSpot = action$ =>
  action$.pipe(
    ofType(Types.POST_PARKING_SPOT),
    mergeMap((action) => {
      return API.post('/hosts/parking_spots', action.payload, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          const redirections = [];
          if (action.finish) {
            redirections.push(push("/ps"))
          } else {
            redirections.push(push(`/ps/${response.data.tag}/edit?step=${action.activeTab}`))
          }
          return [
            ParkingSpotActions.setParkingSpot(null),
            ParkingSpotActions.setCreatingParkingSpot(false),
            ParkingSpotActions.setContinuing(false),
            ParkingSpotActions.setFinishing(false),
            ...redirections,
          ];
        }),
        catchError(error => {
          console.log(`Could not create parking spot: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            ParkingSpotActions.setCreatingParkingSpot(false),
            ParkingSpotActions.setContinuing(false),
            ParkingSpotActions.setFinishing(false),
          ];
        })
      )
    })
  );

export const patchParkingSpot = action$ =>
  action$.pipe(
    ofType(Types.PATCH_PARKING_SPOT),
    mergeMap((action) => {
      return API.patch(`/hosts/parking_spots/${action.id}`, action.payload, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          const redirections = [];
          if (action.finish) {
            redirections.push(push("/ps"))
          } else {
            redirections.push(push(`/ps/${response.data.tag}/edit?step=${action.activeTab}`))
          }
          return [
            ParkingSpotActions.setParkingSpot(null),
            ParkingSpotActions.setUpdatingParkingSpot(false),
            ParkingSpotActions.setContinuing(false),
            ParkingSpotActions.setFinishing(false),
            ...redirections,
          ];
        }),
        catchError(error => {
          console.log(`Could not update parking spot: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            ParkingSpotActions.setUpdatingParkingSpot(false),
            ParkingSpotActions.setContinuing(false),
            ParkingSpotActions.setFinishing(false),
          ];
        })
      )
    })
  );

export const fetchParkingSpotAttributes = action$ =>
  action$.pipe(
    ofType(Types.FETCH_PARKING_SPOT_ATTRIBUTES),
    mergeMap((action) => {
      return API.get('/users/parking_spot_categories', { host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setParkingSpotAttributes(response.data),
            ParkingSpotActions.setFetchingParkingSpotAttributes(false),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch parking spot attributes: ${error.message}`);
          return [
            ParkingSpotActions.setFetchingParkingSpotAttributes(false),
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const fetchParkingSpot = action$ =>
  action$.pipe(
    ofType(Types.FETCH_PARKING_SPOT),
    mergeMap((action) => {
      return API.get(`/hosts/parking_spots/${action.id}`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setParkingSpot(response.data),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch parking spot: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const fetchParkingSpotCars = action$ =>
  action$.pipe(
    ofType(Types.FETCH_PARKING_SPOT_CARS),
    mergeMap((action) => {
      return API.get(action.subHost ? `/sub_hosts/parking_spots/${action.id}/parked_cars` : `/hosts/parking_spots/${action.id}/parked_cars`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setParkingSpotCars(action.id, response.data),
            removeLoader()
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch parked cars: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            removeLoader(),
            push("/")
          ];
        })
      )
    })
  );

export const fetchParkingSpots = action$ =>
  action$.pipe(
    ofType(Types.FETCH_PARKING_SPOTS),
    mergeMap((action) => {
      return API.get(action.userType==="Host" ? "/hosts/parking_spots" : "/sub_hosts/parking_spots", { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          const data = [...(response.data)];
          data.sort((spotA, spotB) => (spotB.name > spotA.name) ? 1 : -1);
          return [
            ParkingSpotActions.setParkingSpots(data),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch parking spots: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const postGalleryImage = action$ =>
  action$.pipe(
    ofType(Types.POST_GALLERY_IMAGE),
    mergeMap((action) => {
      return API.post(`/hosts/parking_spots/${action.id}/add_gallery_image`, action.photo, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.addGalleryImage(response.data),
            ParkingSpotActions.decrementGalleryImageLoadingCounter(),
          ];
        }),
        catchError(error => {
          console.log(`Could not update profile picture: ${error.message}`);
          return [
            ParkingSpotActions.decrementGalleryImageLoadingCounter(),
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const fetchParkingSpotsForGuests = action$ =>
  action$.pipe(
    ofType(Types.FETCH_PARKING_SPOTS_FOR_GUESTS),
    mergeMap((action) => {
      return API.get("/guests/parking_spots", { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setParkingSpots(response.data),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch parking spots: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const fetchReviewsByIdForGuests = action$ =>
  action$.pipe(
    ofType(Types.FETCH_REVIEWS_BY_ID_FOR_GUESTS),
    mergeMap((action) => {
      return API.get(`/users/parking_spots/${action.tag}/reviews`, { host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setReviewsById(action.id, response.data),
            removeLoaderForItem("reviews"),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch reviews: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            removeLoaderForItem("reviews"),
          ];
        })
      )
    })
  );

export const fetchPhotosByIdForGuests = action$ =>
  action$.pipe(
    ofType(Types.FETCH_PHOTOS_BY_ID_FOR_GUESTS),
    mergeMap((action) => {
      return API.get(`/users/parking_spots/${action.tag}/photos`, { host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setPhotosById(action.id, response.data),
            removeLoaderForPhotos(),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch photos: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            removeLoaderForPhotos(),
          ];
        })
      )
    })
  );

export const fetchReviewsByIdForGuestsNextPage = action$ =>
  action$.pipe(
    ofType(Types.FETCH_REVIEWS_BY_ID_FOR_GUESTS_NEXT_PAGE),
    mergeMap((action) => {
      return API.get(`${action.url}`, { host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setReviewsByIdPush(action.id, response.data),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch more reviews: ${error.message}`);
          return [
          ];
        })
      )
    })
  );

export const fetchCurrentCheckIns = action$ =>
  action$.pipe(
    ofType(Types.FETCH_CURRENT_CHECK_INS),
    mergeMap((action) => {
      return API.get(`/guests/bookings/current_checkin`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setCurrentCheckIns(response.data),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch current check ins: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const fetchPastCheckIns = action$ =>
  action$.pipe(
    ofType(Types.FETCH_PAST_CHECK_INS),
    mergeMap((action) => {
      return API.get(`/guests/bookings/past_checkin`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setPastCheckIns(response.data),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch past check ins: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const fetchCarsForGuests = action$ =>
  action$.pipe(
    ofType(Types.FETCH_CARS_FOR_GUESTS),
    mergeMap((action) => {
      return API.get(`/guests/cars`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setCarsForGuests(response.data),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch cars for guests: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const enableParkingSpot = action$ =>
  action$.pipe(
    ofType(Types.ENABLE_PARKING_SPOT),
    mergeMap((action) => {
      return API.get(`/hosts/parking_spots/${action.id}/spot_activate`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap(() => {
          return [
            ParkingSpotActions.fetchParkingSpots(action.token, "Host"),
          ];
        }),
        catchError(error => {
          console.log(`Could not enable parking spot: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const disableParkingSpot = action$ =>
  action$.pipe(
    ofType(Types.DISABLE_PARKING_SPOT),
    mergeMap((action) => {
      return API.get(`/hosts/parking_spots/${action.id}/spot_deactivate`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap(() => {
          return [
            ParkingSpotActions.fetchParkingSpots(action.token, "Host"),
          ];
        }),
        catchError(error => {
          console.log(`Could not disable parking spot: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const postCheckIn = action$ =>
  action$.pipe(
    ofType(Types.POST_CHECK_IN),
    mergeMap((action) => {
      const booking = {
        "checkin": action.booking.checkin,
        "price": action.booking.price,
        "parking_spot_id": action.booking.parking_spot_id,
        "car_id": action.booking.car_id,
        "number_of_nights": action.booking.number_of_nights,
        "persons_count": action.booking.persons_count
      }
      if (action.booking.voucher_id) {
        booking["voucher_id"] = action.booking.voucher_id
      }
      if (action.booking.utm_source) {
        booking["utm_source"] = action.booking.utm_source
      }
      return API.post(`/guests/bookings/check_in`, {
        "booking": booking
      },
      { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return (response.data.status === 402) ? [
            PaymentActions.setCardPaymentFailed(true),
            ParkingSpotActions.fetchParkingSpotDetails(response.data.parking_spot.tag, action.token),
            ParkingSpotActions.setCheckingIn(false),
            ParkingSpotActions.setCheckInDetailsDialogOpen(false),
            VoucherActions.setAppliedVoucher(null),
          ] : [
            ParkingSpotActions.fetchCurrentCheckIns(action.token),
            ParkingSpotActions.setCheckInDialogOpen(false),
            ParkingSpotActions.fetchParkingSpotDetails(response.data.parking_spot.tag, action.token),
            ParkingSpotActions.setCheckingIn(false),
            ParkingSpotActions.setCheckInDetailsDialogOpen(false),
            VoucherActions.setAppliedVoucher(null),

          ];
        }),
        catchError(error => {
          console.log(`Could not check in: ${error.message}`);
          return (error.response.status === 402) ? [
            PaymentActions.setCardPaymentFailed(true),
            ParkingSpotActions.setCheckingIn(false),
            ParkingSpotActions.setCheckInDetailsDialogOpen(false),
          ] : [
            ...API.notificationsForErrorResponse(error),
            ParkingSpotActions.setCheckingIn(false),
          ];
        })
      )
    })
  );

export const postCheckOut = action$ =>
  action$.pipe(
    ofType(Types.POST_CHECK_OUT),
    mergeMap((action) => {
      return API.post(`/guests/bookings/${action.id}/check_out`, {
        "booking":{
          "checkout": action.time,
        }
      },
      { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return (response.data.status === 402) ? [
            PaymentActions.setCardPaymentFailed(true),
            ParkingSpotActions.fetchCurrentCheckIns(action.token),
            ParkingSpotActions.fetchParkingSpotDetails(action.parkingSpotTag, action.token),
            ParkingSpotActions.setCheckingOut(false),
          ] : [
            ParkingSpotActions.fetchCurrentCheckIns(action.token),
            ParkingSpotActions.setReviewDialogOpen(true),
            ParkingSpotActions.setCurrentBookingId(action.id),
            ParkingSpotActions.setCheckOutDialogOpen(false),
            ParkingSpotActions.fetchParkingSpotDetails(action.parkingSpotTag, action.token),
            ParkingSpotActions.setCheckingOut(false),
          ];
        }),
        catchError(error => {
          console.log(`Could not check out: ${error.message}`);
          return (error.response.status === 402) ? [
            PaymentActions.setCardPaymentFailed(true),
            ParkingSpotActions.fetchCurrentCheckIns(action.token),
            ParkingSpotActions.fetchParkingSpotDetails(action.parkingSpotTag, action.token),
            ParkingSpotActions.setCheckingOut(false),
          ] : [
            ...API.notificationsForErrorResponse(error),
            ParkingSpotActions.setCheckingOut(false),
          ];
        })
      )
    })
  );

export const deleteGalleryImage = action$ =>
  action$.pipe(
    ofType(Types.DELETE_GALLERY_IMAGE),
    mergeMap((action) => {
      return API.delete(`/hosts/parking_spots/${action.id}/remove_gallery_image`, {
        img_id: action.photoId,
      }, { token: action.token, host: BACKEND_API }).pipe(
        flatMap(() => {
          return [
            ParkingSpotActions.removeGalleryImage(action.photoId),
          ];
        }),
        catchError(error => {
          console.log(`Could not remove profile picture: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const postReview = action$ =>
  action$.pipe(
    ofType(Types.POST_REVIEW),
    mergeMap((action) => {
      return API.post(`/guests/reviews`, {
        "review": action.review,
      },
      { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.fetchPastCheckIns(action.token),
            ParkingSpotActions.setReviewSubmissionDialogOpen(false),
            ParkingSpotActions.setBookingReviewSubmissionDialogOpen(action.review.booking_id, false),
            ParkingSpotActions.setMoreReviewsById({ reviews: [], pagy: null }),
          ];
        }),
        catchError(error => {
          console.log(`Could not post review: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const fetchParkingSpotDetailsGuests = action$ =>
  action$.pipe(
    ofType(Types.FETCH_PARKING_SPOT_DETAILS_FOR_GUESTS),
    mergeMap((action) => {
      return API.get(`/guests/parking_spots/${action.id}`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setParkingSpotDetails(response.data),
            removeLoader(),
            removeLoaderForItem("parkingspots"),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch parking spot details: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            push("/"),
            removeLoader(),
            removeLoaderForItem("parkingspots"),
          ];
        })
      )
    })
  );

  export const fetchParkingSpotDetails = action$ =>
  action$.pipe(
    ofType(Types.FETCH_PARKING_SPOT_DETAILS_FOR_USERS),
    mergeMap((action) => {
      const options = { host: BACKEND_API }
      if (!!action.token) {
        options.token = action.token
      }
      return API.get(`/users/parking_spots/${action.tag}`, options).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setParkingSpotDetails(response.data),
            removeLoader(),
            removeLoaderForItem("parkingspots"),
            setAppVersion(response.headers["app-version"] || "1"),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch parking spot details: ${error.message}`);
          return [
            // ...API.notificationsForErrorResponse(error),
            removeLoader(),
            removeLoaderForItem("parkingspots"),
            push("/")
          ];
        })
      )
    })
  );

export const searchParkingSpots = action$ =>
  action$.pipe(
    ofType(Types.SEARCH_PARKING_SPOTS),
    mergeMap((action) => {
      return API.post(action.url ? action.url : `/users/parking_spots/search_ids`, {
        search: {
          parking_spot_category_option_ids:
            Object.entries(action.options).filter(([key, value]) => value).map(([key, value]) => key),
          location: action.location,
          price: action.price,
        }
      }, { host: BACKEND_API }).pipe(
        flatMap((response) => {
          return !action.redirectToSearch ? [
            ParkingSpotActions.setSearchTimestamp(),
            ParkingSpotActions.setFilteredParkingSpots(response.data),
            ParkingSpotActions.pruneParkingSpotDetailsSearch(),
            ParkingSpotActions.setSearchResultsLoader(),
            removeLoader(),
            ParkingSpotActions.setIsSearching(false),
            setAppVersion(response.headers["app-version"] || "1"),
          ] : [
            ParkingSpotActions.initializeParkingSpotDetailsSearch(),
            setAppVersion(response.headers["app-version"] || "1"),
            push(action.redirectToSearch)
          ];
        }),
        catchError(error => {
          console.log(`Could not search for parking spots: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            removeLoader(),
            ParkingSpotActions.setIsSearching(false),
          ];
        }),
        takeUntil(action$.pipe(
          ofType(Types.CANCEL_SEARCH)
        ))
      )
    })
  );

export const fetchPriceFilters = (action$) =>
  action$.pipe(
  ofType(Types.FETCH_PRICE_FILTERS),
  mergeMap((action) => {
    return API.post("/users/parking_spots/price_filters", {
      search: {
        parking_spot_category_option_ids:
          Object.entries(action.options).filter(([key, value]) => value).map(([key, value]) => key),
        location: action.location,
      },
    }, {
      host: BACKEND_API,
    }).pipe(
      concatMap((response) => {
        return [ParkingSpotActions.fetchPriceFiltersFulfilled(response.data)];
      }),
      catchError((error) => {
        return [
          ...API.notificationsForErrorResponse(error),
          ParkingSpotActions.fetchPriceFiltersFulfilled(null, error),
        ];
      })
    );
  })
);

export const fetchBookingsHost = action$ =>
  action$.pipe(
    ofType(Types.FETCH_BOOKINGS_HOST),
    mergeMap((action) => {
      return API.get((action.requestUrl) ? action.requestUrl : action.subHost ? `/sub_hosts/bookings` : `/hosts/bookings`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setBookingsHost(response.data),
            removeLoader(),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch bookings: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            removeLoader(),
          ];
        })
      )
    })
  );

export const fetchFilteredBookingsHost = action$ =>
  action$.pipe(
    ofType(Types.FETCH_FILTERED_BOOKINGS_HOST),
    mergeMap((action) => {
      return API.post((action.requestUrl) ? action.requestUrl : action.subHost ? `/sub_hosts/bookings/filter` : `/hosts/bookings/filter`, {...action.query} , { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setBookingsHost(response.data),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch filtered bookings: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const fetchSingleBookingReceipt = action$ =>
  action$.pipe(
    ofType(Types.FETCH_SINGLE_BOOKING_RECEIPT),
    mergeMap((action) => {
      return API.get(action.subHost ? `/sub_hosts/bookings/${action.id}/download` : `/hosts/bookings/${action.id}/download`, { token: action.token, host: BACKEND_API }, true).pipe(
        flatMap((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const filename = `pns-${action.name.replaceAll(" ", "-").toLowerCase()}.pdf`;
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          return [
            ParkingSpotActions.setFetchingBookingsReceipt(null, false)
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch booking receipt: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            ParkingSpotActions.setFetchingBookingsReceipt(null, false)
          ];
        })
      )
    })
  );

export const fetchMultipleBookingsReceipt = action$ =>
  action$.pipe(
    ofType(Types.FETCH_MULTIPLE_BOOKINGS_RECEIPT),
    mergeMap((action) => {
      return API.post(action.subHost ? `/sub_hosts/bookings/download_multiple${action.printCSV ? '?csv=true' : ''}` : `/hosts/bookings/download_multiple${action.printCSV ? '?csv=true' : ''}`, {...action.query} , { token: action.token, host: BACKEND_API }, true).pipe(
        flatMap((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `pns-multiple-bookings.${!!action.printCSV ? 'csv' : 'pdf'}`);
          document.body.appendChild(link);
          link.click();
          return [
            ParkingSpotActions.setFetchingBookingsReceipt(null, false, false)
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch bookings receipt: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            ParkingSpotActions.setFetchingBookingsReceipt(null, false, false)
          ];
        })
      )
    })
  );

  export const fetchReviewForBooking = action$ =>
  action$.pipe(
    ofType(Types.FETCH_REVIEW_FOR_BOOKING),
    mergeMap((action) => {
      return API.get(`/guests/bookings/${action.id}/review`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setReviewForBooking(response.data),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch review for booking: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

  export const pushToRoot = action$ =>
  action$.pipe(
    ofType(Types.PUSH_TO_ROOT),
    mergeMap((action) => {
      return[push("/")];
    })
  );

  export const fetchParkingSpotDetailsSearch = action$ =>
  action$.pipe(
    ofType(Types.FETCH_PARKING_SPOT_DETAILS_SEARCH),
    mergeMap((action) => {
      return API.get(`/users/parking_spots/${action.tag}/detail`, { host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setParkingSpotDetailsSearch(action.tag, response.data),
            ParkingSpotActions.decrementSearchResultsLoader()
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch parking spot details for search results: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        }),
        takeUntil(action$.pipe(
          ofType(Types.CANCEL_SEARCH)
        ))
      )
    })
  );

  export const fetchUserLocation = action$ =>
  action$.pipe(
    ofType(Types.FETCH_USER_LOCATION),
    mergeMap((action) => {
      return API.post(`/users/location`, {}, { host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.setUserLocation(response.data),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch user location: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const postParkedCarCheck = action$ =>
  action$.pipe(
    ofType(Types.POST_PARKED_CAR_CHECK),
    mergeMap((action) => {
      return API.post(action.subHost ? `/sub_hosts/bookings/${action.id}/verify` : `/hosts/bookings/${action.id}/verify`, {verified: action.value}, {token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            ParkingSpotActions.fetchParkingSpotCars(action.parkingSpotId, action.token, action.subHost)
          ];
        }),
        catchError(error => {
          console.log(`Could not check parked car: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );
