import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { ApplePay, GooglePay, MasterCard, Visa } from "assets/images/SVG";
import AppleIcon from "../../../assets/images/apple-store.png";
import logo from "../../../assets/images/logo.svg";
import tcs from "../../../assets/images/tcs.svg";
import CustomYoutubeIcon from "../../../assets/images/youtube.svg";

const useStyles = makeStyles((theme) => ({
  footerLinks: {
    padding: "2rem 0px",
    [theme.breakpoints.up("sm")]: {
      padding: "4rem 0px 2rem 0px",
    },
  },
  footerLink: {
    textDecoration: "none",
  },
  title: {
    width: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  titleLogo: {
    width: "100%",
  },
  listTitle: {
    color: theme.palette.secondary.main,
  },
  listItem: {
    padding: "0px",
    color: theme.palette.secondary.dark,
  },
  socialIcons: {
    fontSize: "1.675rem",
    color: theme.palette.primary.main,
    width: "1em",
    height: "1em",
  },
  tcsIcon: {
    height: "1.675rem",
    marginRight: "0.7rem",
  },
  tcsText: {
    marginTop: "-0.3rem",
  },
  detailsPage: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "5.75rem",
      paddingTop: "0.75rem",
    },
  },
  notDetailsPage: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0rem",
    },
  },
  appButton: {
    width: "70%",
    maxWidth: "22rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  appButtonLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
    width: "45%",
    maxWidth: "160px",
    color: "#fff",
    margin: "20px 10px",
    textAlign: "left",
    borderRadius: "5px",
    textDecoration: "none",
    fontFamily: "Lucida Grande, sans-serif",
    fontSize: "10px",
    textTransform: "uppercase",
    backgroundColor: "#101010",
    transition: "background-color 0.25s linear",
  },
  paymentWrapper: {
    gridColumnGap: "8px",
    gridRowGap: "8px",
    flexFlow: "wrap",
    justifyContent: "flex-start",
    display: "flex",
    marginRight: "0.5em",
  },
}));

const Footer = (expanded, ...rest) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const urlParts = location.pathname.split("/");
  const isDetailsPage =
    urlParts &&
    urlParts.length > 1 &&
    urlParts[urlParts.length - 2] === "ps" &&
    !isNaN(urlParts[urlParts.length - 1])
      ? true
      : false;
  const intercomLinksLang = (() => {
    if (i18n.language === "en" || i18n.language === "it" || i18n.language === "pl") {
      return "en";
    } else {
      return i18n.language;
    }
  })();

  return (
    <React.Fragment>
      <Divider />
      <Container
        maxWidth={!expanded ? "md" : "lg"}
        className={isDetailsPage ? classes.detailsPage : classes.notDetailsPage}
      >
        <Grid container spacing={3} className={classes.footerLinks}>
          <Grid item xs={12} md={6}>
            <Box flexGrow="1">
              <Box className={classes.title} component={Link} to="/dashboard">
                <img src={logo} alt="logo" className={classes.titleLogo} />
              </Box>
            </Box>
            <Box>
              <Box className={classes.appButton}>
                <Box width="50%">
                  <a href="https://apps.apple.com/app/parknsleep/id1640351324">
                    <img alt="Available on Apple Store" width="100%" src={AppleIcon} />
                  </a>
                </Box>

                <Box width="50%" marginLeft="0.5rem">
                  <a href="https://play.google.com/store/apps/details?id=com.parknsleep.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <img
                      alt="Get it on Google Play"
                      width="100%"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    />
                  </a>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography variant="body2" className={classes.listTitle}>
              {t("footer.links.title")}
            </Typography>
            <List dense={true}>
              <ListItem className={classes.listItem}>
                <a
                  href="https://www.parknsleep.eu/parking-app-for-campers"
                  className={classes.footerLink}
                >
                  <ListItemText primary={t("footer.links.download-app")} />
                </a>
              </ListItem>
              <ListItem className={classes.listItem}>
                <a href="https://www.parknsleep.eu/" className={classes.footerLink}>
                  <ListItemText primary={t("footer.links.home")} />
                </a>
              </ListItem>
              <ListItem className={classes.listItem}>
                <a href="https://www.parknsleep.eu/host" className={classes.footerLink}>
                  <ListItemText primary={t("footer.links.host")} />
                </a>
              </ListItem>
              <ListItem className={classes.listItem}>
                <a href="https://www.parknsleep.eu/tourism" className={classes.footerLink}>
                  <ListItemText primary={t("footer.links.community")} />
                </a>
              </ListItem>
              <ListItem className={classes.listItem}>
                <a href="https://www.parknsleep.eu/cms/blog" className={classes.footerLink}>
                  <ListItemText primary={t("footer.links.blog")} />
                </a>
              </ListItem>
              <ListItem className={classes.listItem}>
                <a href="https://www.parknsleep.eu/check-in-point" className={classes.footerLink}>
                  <ListItemText primary={t("footer.links.media")} />
                </a>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Box component={Link} to={"/cookies"} className={classes.footerLink}>
                  <ListItemText primary={t("footer.links.cookies")} />
                </Box>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography variant="body2" className={classes.listTitle}>
              {t("footer.help.title")}
            </Typography>
            <List dense={true}>
              <ListItem className={classes.listItem}>
                <a
                  href={`https://intercom.help/parknsleep/${intercomLinksLang}/collections/3257191-uber-parkn-sleep`}
                  className={classes.footerLink}
                >
                  <ListItemText primary={t("footer.help.general")} />
                </a>
              </ListItem>
              <ListItem className={classes.listItem}>
                <a
                  href={`https://intercom.help/parknsleep/${intercomLinksLang}/collections/3257192-fur-camper`}
                  className={classes.footerLink}
                >
                  <ListItemText primary={t("footer.help.camper")} />
                </a>
              </ListItem>
              <ListItem className={classes.listItem}>
                <a
                  href={`https://intercom.help/parknsleep/${intercomLinksLang}/collections/3257193-fur-gastgeber`}
                  className={classes.footerLink}
                >
                  <ListItemText primary={t("footer.help.host")} />
                </a>
              </ListItem>
              <ListItem className={classes.listItem}>
                <a
                  href={`https://intercom.help/parknsleep/${intercomLinksLang}/articles/5807662-nutzungsbedingungen`}
                  className={classes.footerLink}
                >
                  <ListItemText primary={t("footer.help.terms")} />
                </a>
              </ListItem>
              <ListItem className={classes.listItem}>
                <a
                  href={`https://intercom.help/parknsleep/${intercomLinksLang}/articles/5807660-datenschutzerklarung`}
                  className={classes.footerLink}
                >
                  <ListItemText primary={t("footer.links.privacy-policy")} />
                </a>
              </ListItem>
              <ListItem className={classes.listItem}>
                <a
                  href={`https://intercom.help/parknsleep/${intercomLinksLang}/articles/6001599-impressum`}
                  className={classes.footerLink}
                >
                  <ListItemText primary={t("footer.links.legal-notice")} />
                </a>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography variant="body2" className={classes.listTitle}>
              {t("footer.contact")}
            </Typography>
            <List dense={true}>
              <ListItem className={classes.listItem}>
                <a href="mailto:support@parknsleep.app" className={classes.footerLink}>
                  <ListItemText primary="support@parknsleep.app" />
                </a>
              </ListItem>
              <Box display="flex" alignItems="center">
                <Box>
                  <a href="https://www.facebook.com/parknsleep" target="_blank" rel="noreferrer">
                    <FacebookIcon className={classes.socialIcons} />
                  </a>
                </Box>
                <Box pl={1}>
                  <a
                    href="https://www.instagram.com/parknsleep.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon className={classes.socialIcons} />
                  </a>
                </Box>
                <Box pl={1}>
                  <a
                    href="https://www.youtube.com/channel/UCv1xR10nCV41XiGKj9YZzHA/featured"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={CustomYoutubeIcon} alt="yt" className={classes.socialIcons} />
                  </a>
                </Box>
              </Box>
            </List>
            <Box mt="1em">
              <Typography variant="body2" className={classes.listTitle}>
                {t("footer.payment-methods")}
              </Typography>
            </Box>
            <List dense={true}>
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <Box className={classes.paymentWrapper}>
                  <a
                    href={`https://intercom.help/parknsleep/${intercomLinksLang}/articles/7882481`}
                    className={classes.footerLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Visa />
                  </a>
                </Box>
                <Box className={classes.paymentWrapper}>
                  <a
                    href={`https://intercom.help/parknsleep/${intercomLinksLang}/articles/7882481`}
                    className={classes.footerLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <MasterCard />
                  </a>
                </Box>
                <Box className={classes.paymentWrapper}>
                  <a
                    href={`https://intercom.help/parknsleep/${intercomLinksLang}/articles/7882481`}
                    className={classes.footerLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ApplePay />
                  </a>
                </Box>
                <Box className={classes.paymentWrapper}>
                  <a
                    href={`https://intercom.help/parknsleep/${intercomLinksLang}/articles/7882481`}
                    className={classes.footerLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <GooglePay />
                  </a>
                </Box>
              </Box>
            </List>
          </Grid>
        </Grid>
        <Box display="flex">
          <Box pb={4} flex="auto">
            <Box display="flex" alignItems="center">
              <Box>
                <img src={tcs} alt="tcs" className={classes.tcsIcon} />
              </Box>
              <Typography variant="body2" className={classes.tcsText}>
                Partner
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Footer;
