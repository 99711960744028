import * as Types from './constants';

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;

  return {
    type: Types.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = key => ({
  type: Types.CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = key => ({
  type: Types.REMOVE_SNACKBAR,
  key,
});

export const setLastPagyUrl = url => ({
  type: Types.SET_LAST_PAGY_URL,
  url,
});
