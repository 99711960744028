import React, { useState, useEffect } from 'react';
import { Divider, Container, Box, Typography, Card, Button, ButtonBase, CardActionArea, Tabs, Tab, Tooltip } from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SettingsIcon from '@material-ui/icons/Settings';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

import { useStyles } from '../common/styles';
import { withStyles } from '@material-ui/styles';
import TitleSection from '../../common/titleSection';
import Switch from '../../common/switch';
import { connect } from 'react-redux';
import ReviewDialog from '../../common/reviewDialog';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import clsx from 'clsx';

import {
  fetchCurrentCheckIns,
  fetchPastCheckIns,
  setBookingReviewSubmissionDialogOpen,
} from '../../../data/actions/parkingSpot';
import MissingDataPlaceholder from '../../common/missingDataPlaceholder';
import { push } from 'connected-react-router';

function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals).toFixed(decimals);
}

const getNumberOfNights = (date, zone) => {
  let end = moment().tz(zone)
  if (end.hour() > 12 || (end.hour() === 12 && end.minute() > 0) || (end.hour() === 12 && end.minute() === 0 && end.second() > 0)){
    end.add(1, 'days');
  }
  end.hour(12);
  end.minute(0);
  end.second(0);

  let start = moment(date).tz(zone);
  if (start.hour() >= 4 && (start.hour() < 24 || start.hour() < 0)){
    start.hour(12);
    start.minute(0);
    start.second(0);
  } else {
    start.subtract(1, 'days');
    start.hour(12);
    start.minute(0);
    start.second(0);
  }
  let duration = moment.duration(end.diff(start));
  let days = duration.asDays();
  let roundedDays = Math.round(days);
  if (roundedDays < 1){
    roundedDays = 1;
  }
  return roundedDays;
}

const StyledTooltip = withStyles({
  popper: {
    top: "160px !important",
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width:1279.99px)']: {
      top: "140px !important"
    }
  },
})(Tooltip);

const WithTooltip = ({ showTooltip, tooltipText, children }) =>
  showTooltip ? (
    <StyledTooltip title={tooltipText} enterTouchDelay={0} placement='top'>
      {children}
    </StyledTooltip>
  ) : (
    children
  );

const CheckIns = ({ token, currentCheckIns, pastCheckIns, fetchCurrentCheckIns, fetchPastCheckIns, bookingReviewSubmissionDialogOpen, setBookingReviewSubmissionDialogOpen }) => {
  const classes = useStyles();
  const [tab, setTab] = useState("current");
  const { t } = useTranslation();
  const records = tab === "current" ? currentCheckIns : pastCheckIns;
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchCurrentCheckIns(token);
    fetchPastCheckIns(token);
  }, [fetchCurrentCheckIns, fetchPastCheckIns, token]);

  return (
    <Container maxWidth="md">
      <Box display="flex">
        <TitleSection
          routes={[{
            name: t("common.texts.dashboard"),
            path: "/dashboard",
          }]}
          title={ t("check-ins.sub-title") }
        />
      </Box>
      <Divider />
      <Box display="flex" pt={isMobile ? 3 : 8} flexDirection="column">
        <Tabs
          value={tab}
          onChange={(event, newValue) => setTab(newValue)}
          textColor="primary"
          indicatorColor="primary"
          className={clsx(classes.dashboardTabs, classes.largerTabs)}
        >
          <Tab value="current" label={ t('check-ins.current') } className={classes.largerTab} />
          <Tab value="past" label={ t('check-ins.past') } className={classes.largerTab} />
        </Tabs>
        <Box display="flex" flexWrap="wrap" mt={2} marginLeft="-1.5rem" marginRight="-1.5rem">
          {records.map((booking) => {
            return (
              <WithTooltip showTooltip={booking.parking_spot && booking.parking_spot.deleted} tooltipText={ t("check-ins.deleted") }>
                <Box display="flex" flexDirection={isMobile ? "column" : "row"} my={1} component={Card} elevation={0} key={`parking-spot-row-${booking.id}`} className={classes.checkinCardWrapper}>
                  <CardActionArea
                    className={classes.cardAction}
                    component={Link}
                    to={`/ps/${booking.parking_spot && booking.parking_spot.tag}`}
                    disabled={booking.parking_spot && booking.parking_spot.deleted}
                  >
                    <Box className={classes.checkinCardLinkInner}>
                      <Box className={classes.checkinCardPictureWrapper}>
                        {
                          booking.parking_spot.main_photo_thumbnail_url ?
                          <img className={classes.checkinCardPicture} src={booking.parking_spot.main_photo_thumbnail_url} alt="spot thumbnail" />
                          :
                          <Box className={classes.checkinCardPicture} style={{background: "#E7F0F0"}} />
                        }
                      </Box>
                      <Box className={tab === "past" ? classes.pastCheckinDetails : classes.checkinDetails}>
                        <Box>
                          <Typography variant="h3">
                            {booking.parking_spot.name}
                          </Typography>
                        </Box>
                        <Box className={tab == "past" ? classes.pastCheckinDetailsAttributes : classes.checkinDetailsAttributes}>
                          <Box>
                            <Typography variant="body1" className={classes.smallBodyText}>
                              { t('common.labels.in') }: {moment(booking.checkin).zone(booking.checkin).format('DD.MM.YYYY - HH:mm') + " " + booking.time_zone}
                            </Typography>
                          </Box>
                          {(tab === "past") && (
                            <Box>
                              <Typography variant="body1" className={classes.smallBodyText}>
                                { t('common.labels.out') }: {moment(booking.checkout).zone(booking.checkout).format('DD.MM.YYYY - HH:mm') + " " + booking.time_zone}
                              </Typography>
                            </Box>
                          )}
                          <Box>
                            <Typography variant="body1" className={classes.mediumBodyText}>
                                PAR/{(booking.parking_spot && booking.parking_spot.tag) || "N/A"}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="body1" className={clsx(classes.strongText, classes.mediumBodyText)}>
                              {booking.currency || ""} {tab === "current" ? booking.price && booking.tax_amount && booking.checkin && (round(Number(booking.price) + (Number(booking.tax_amount) / Number(booking.number_of_nights)), 2) * Number(booking.number_of_nights)).toFixed(2) : booking.total_amount && round(booking.total_amount, 2)}
                            </Typography>
                          </Box>
                          {tab === "past" && !booking["has_review?"] && (
                            <Box width="100%">
                              <a className={classes.addReviewLink} onMouseDown={event => {
                                event.preventDefault();
                                event.stopPropagation();
                              }} onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setBookingReviewSubmissionDialogOpen(booking.id, true)
                              } }>
                                <EditIcon className={classes.addReviewLinkIcon} style={booking.parking_spot && booking.parking_spot.deleted ? {fill: "#CCC"} : {}} />
                                <Typography variant="body1" className={classes.mediumBodyText} style={booking.parking_spot && booking.parking_spot.deleted ? {color: "#CCC"} : {}}>
                                  { t("review-box.btn-add") }
                                </Typography>
                              </a>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </CardActionArea>
                  <ReviewDialog
                    open={bookingReviewSubmissionDialogOpen[booking.id]}
                    bookingId={booking.id}
                    parkingSpotId={booking.parking_spot.id}
                    onClose={() => setBookingReviewSubmissionDialogOpen(booking.id, false)}
                  />
                </Box>
              </WithTooltip>
            );
          })}
        </Box>
        { records.length === 0 && (
          <Box mt={2}>
            <MissingDataPlaceholder />
          </Box>
        ) }
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    currentCheckIns: state.parkingSpot.currentCheckIns,
    pastCheckIns: state.parkingSpot.pastCheckIns,
    bookingReviewSubmissionDialogOpen: state.parkingSpot.bookingReviewSubmissionDialogOpen,
  };
};

export default connect(mapStateToProps, {
  fetchCurrentCheckIns,
  fetchPastCheckIns,
  setBookingReviewSubmissionDialogOpen,
})(CheckIns);
