import { makeStyles } from '@material-ui/core/styles';
import customPlaceholder from "../../../assets/images/custom-placeholder.png";

export const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontSize: "1em",
  },
  loader: {
    margin: "auto",
  },
  preview: {
    border: "1px dashed #ddd",
    boxShadow: "none",
    display: "flex",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: "12px",
    width:  "100%",
    height: "140px",
    flexDirection: "column",
    justifyContent: "center",
    "&.custom": {
      height: "200px",
      width: "200px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxHeight: "340px",
        maxWidth: "340px",
      },
    },
    "&.large": {
      height: "250px",
    },
    "&.multiple": {
      height: "auto",
      minHeight: "140px",
      justifyContent: "space-between",
      flexDirection: "row",
      flexWrap: "wrap",
      paddingBottom: "1rem",
      padding: "2rem",
      [theme.breakpoints.up("sm")]: {
        paddingBottom: "0rem",
        padding: "1rem",
      },
    },
    "&.successIcon": {
      height: "250px",
    },
  },
  imageWrapper: {
    "&.custom": {
      height: "100%",
      width: "100%",
    },
    "&.large": {
      width: "90%",
      height: "80%",
    },
    "&.single": {
      margin: "auto",
    },
    "&.multiple": {
      [theme.breakpoints.up("sm")]: {
        width: "19%",
        paddingTop: "19%",
      },
      position: "relative",
      width: "47%",
      paddingTop: "47%",
      marginBottom: "1rem",
    },
  },
  photoIcon: {
    width: "5rem",
    height: "5rem",
    padding: "1rem",
    fontSize: "4.5rem",
    color: "#aaa",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "12px",
    "&.large": {
      backgroundColor: "#fff",
      border: "1px dashed #ddd",
      width: "100%",
      height: "100%",
      padding: "5rem 12.5rem !important",
    },
    "&.multiple": {
      backgroundColor: "#fff",
      border: "1px dashed #ddd",
      padding: "0rem",
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      width: "100%",
      height: "100%",
    },
    "&.successIcon": {
      backgroundColor: "#fff",
      border: "1px dashed #ddd",
      width: "100%",
      height: "100%",
      padding: "5rem 12rem !important",
      color: theme.palette.primary.main
    },
  },
  imageIcon: {
    fontSize: "1.0rem",
    width: "1.0rem",
    height: "1.0rem",
    marginRight: "0.25rem !important"
  },
  uploadedImage: {
    width: "5rem",
    height: "5rem",
    borderRadius: "12px",
    "&.custom": {
      width: "100%",
      height: "100%",
    },
    "&.large": {
      width: "100%",
      height: "100%",
    },
    "&.multiple": {
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      width: "100%",
      height: "100%",
    },
  },
  cancelIcon: {
    color: "#fff",
    position: "absolute",
    top: "0.2em",
    right: "0.2em",
    cursor: "pointer",
  },
  imageButtonWrapper: {
    [theme.breakpoints.down("xs")]: {
      flex: "1 1 auto",
    }
  },
  customImageButtonWrapper: {
    width: "100%",
  },
  profilePictureWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0.75rem",
    backgroundColor: "#F2F9FA",
    color: "#008A94",
    fontWeight: 400,
    outline: "none",
    transition: "border .24s ease-in-out",
    width: "100%",
    padding: "2.75rem 1rem !important",
  },
  profilePicturePreview: {
    border: "none",
    boxShadow: "none",
    display: "flex",
    borderRadius: "0.75rem",
    width:  "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F2F9FA",
    height: "10.5rem",
    margin: "1.25rem 0",
  },
  customPreview: {
    display: "flex",
    margin: "1rem 0rem",
    backgroundImage: `url(${customPlaceholder})`,
    width: "200px",
    height: "200px",
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    //   // height: "100%",
    //   maxHeight: "340px",
    //   maxWidth: "340px",
    // },
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
}));
