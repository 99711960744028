import { ofType } from 'redux-observable';
import { mergeMap, flatMap, catchError } from 'rxjs/operators';
import { push } from 'connected-react-router'

import * as Types from '../actions/constants';
import { BACKEND_API } from './hosts';
import API from '../../lib/api';
import * as CarsActions from '../actions/cars';
import { removeLoader } from '../actions/config';

export const fetchCars = action$ =>
  action$.pipe(
    ofType(Types.FETCH_CARS),
    mergeMap((action) => {
      return API.get(`/guests/cars`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            CarsActions.setCars(response.data),
            removeLoader()
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch cars: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const putCar = action$ =>
  action$.pipe(
    ofType(Types.PUT_CAR),
    mergeMap((action) => {
      return API.put(`/guests/cars/${action.id}`, {"car": action.car}, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            CarsActions.fetchCars(action.token),
          ];
        }),
        catchError(error => {
          console.log(`Could not update car: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const postCar = action$ =>
  action$.pipe(
    ofType(Types.POST_CAR),
    mergeMap((action) => {
      return API.post(`/guests/cars`, {"car": action.car}, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            CarsActions.fetchCars(action.token),
          ];
        }),
        catchError(error => {
          console.log(`Could not create car: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const deleteCar = action$ =>
  action$.pipe(
    ofType(Types.DELETE_CAR),
    mergeMap((action) => {
      return API.delete(`/guests/cars/${action.id}`, {}, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            CarsActions.fetchCars(action.token),
          ];
        }),
        catchError(error => {
          console.log(`Could not delete car: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const updatePrimaryCar = action$ =>
  action$.pipe(
    ofType(Types.UPDATE_PRIMARY_CAR),
    mergeMap((action) => {
      return API.get(`/guests/cars/${action.id}/set_primary`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            CarsActions.fetchCars(action.token),
          ];
        }),
        catchError(error => {
          console.log(`Could not set car as primary: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );
