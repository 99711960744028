import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  caption2: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0em",
  },
  legalNotesContainer: {
    position: "absolute",
    bottom: "0.8rem",
    left: "50%",
    transform: "translateX(-50%)",
  },
  legalNotesLink: {
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    cursor: "pointer",
    transition: "all 0.3s ease",
  },
  payoutDropdown: {
    height: '3rem',
    minWidth: 150,
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    fontWeight:200,
    borderStyle:'solid',
    borderWidth: 1,
    borderRadius: 12,
    paddingLeft: 14,
    paddingTop: 14,
    paddingBottom: 14,
    borderColor: '#008a94',
  },
  payoutDropdownMenuItem: {
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
  },
  pinCardImage: {
    width: "10rem",
    height: "10rem",
    [theme.breakpoints.down(350)]: {
      width: "9rem",
      height: "9rem",
    },
  },
  pinPriceWrapper: {
    marginTop: "0.25rem",
    [theme.breakpoints.down(350)]: {
      marginTop: "0rem",
    },
  },
  pinCardDetails: {
    width: "calc(100% - 11rem)",
    [theme.breakpoints.down(350)]: {
      width: "calc(100% - 10rem)",
    }
  },
  taxRadio: {
    fontSize: "18px",
  },
  addReviewLink: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      fontWeight: "400",
      textDecoration: "underline",
    }
  },
  addReviewLinkIcon: {
    marginRight: "0.5rem",
    fill: theme.palette.primary.main,
    width: "1rem",
    height: "1rem",
  },
  checkinCardWrapper: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    padding: "1rem 1.5rem",
    border: "none !important",
  },
  strongText: {
    fontWeight: "600",
  },
  smallBodyText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  mediumBodyText: {
    [theme.breakpoints.between(1280, 1420)]: {
      fontSize: "13px",
    },
  },
  checkinCardLinkInner: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  switch: {
    margin: "0px !important",
  },
  psCardTitle: {
    flex: "auto",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    }
  },
  parkingSpotCardLinkInner: {
    // height: "100%",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "initial",
    },
  },
  checkinDetailsAttributes: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& > div:first-child": {
      width: "100%",
      marginBottom: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-around",
      "& > div": {
        width: "50%",
      },
    },
  },
  parkingSpotListActions: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
      marginRight: "1.5rem",
      "& > div:first-child": {
        width: "100%",
        marginRight: "0rem",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      },
      "& > div:last-child": {
        marginRight: "0rem",
      },
    },
  },
  pastCheckinDetailsAttributes: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& > div": {
      width: "30%",
    },
    "& > div:first-child": {
      width: "100%",
      marginBottom: "1rem",
    },
    "& > div:nth-child(2)": {
      width: "100%",
      marginBottom: "1rem",
    },
    "& > div:nth-child(4)": {
      width: "25%",
    },
    "& > div:last-child": {
      width: "35%",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-around",
      "& > div": {
        display: "flex",
        justifyContent: "center",
        width: "50%",
        marginBottom: "0.5rem",
      },
      "& > div:first-child": {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginBottom: "1rem",
      },
      "& > div:nth-child(2)": {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginBottom: "1rem",
      },
      "& > div:nth-child(4)": {
        display: "flex",
        justifyContent: "center",
        width: "50%",
      },
      "& > div:last-child": {
        display: "flex",
        justifyContent: "center",
        width: "50%",
      },
    },
  },
  checkinCardPictureWrapper: {
    maxWidth: "13.75rem",
    maxHeight: "16rem",
    padding: "1.5rem",
    height: "13.75rem",
    flex: "1",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "15rem",
      maxWidth: "100%",
      maxHeight: "15rem",
      paddingBottom: "0.875rem !important",
      flex: "unset"
    },
    [theme.breakpoints.between(450, theme.breakpoints.values.md)]: {
      maxHeight: "16rem",
    },
    [theme.breakpoints.down(450)]: {
      maxHeight: "13.5rem",
    },
    [theme.breakpoints.down(350)]: {
      maxHeight: "12rem",
    }
  },
  checkinCardPicture: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "12px",
  },
  checkinDetails: {
    display: "flex",
    padding: "2rem 2rem 2rem 0rem",
    [theme.breakpoints.down("md")]: {
      padding: "0rem 1rem 1rem 1rem",
      textAlign: "center",
    },
    flex: "1",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  pastCheckinDetails: {
    display: "flex",
    padding: "1.5rem 2rem 1.5rem 0rem",
    [theme.breakpoints.down("md")]: {
      padding: "0rem 1rem 1rem 1rem",
      textAlign: "center",
    },
    flex: "1",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  parkingSpotListDetails: {
    display: "flex",
    flex: 1,
    padding: "1.5rem 1.5rem 1.5rem 0rem",
    [theme.breakpoints.down("md")]: {
      padding: "0rem 1.5rem 1.5rem 1.5rem",
      textAlign: "center",
    },
    // flex: "1",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  dashboardTabs: {
    maxWidth: "35rem",
  },
  fliterBoxOptions: {
    width: "100%",
    display: "flex",
    marginTop: "1rem",
    flexWrap: "wrap",
  },
  filterIconBox: {
    cursor: "pointer",
    width: "11rem",
    height: "8rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFF",
    justifyContent: "center",
    border: "1px solid #eee",
    marginRight: "1.5rem",
    marginBottom: "1.5rem",
    borderRadius: "12px",
    color: "#010028 !important",
    "& svg": {
      width: "3.5rem",
      height: "2.5rem",
      fill: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      flex: "1",
      minWidth: "calc(50% - 2rem)",
      marginRight: "1rem",
      marginBottom: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      flex: "1",
      minWidth: "calc(100% - 2rem)",
      marginRight: "1rem",
      marginBottom: "1rem",
    },
    "&.campsiteIcon": {
      "& .cls-2": {
        stroke: theme.palette.primary.main
      }
    }
  },
  filterIconBoxFocused: {
    cursor: "pointer",
    width: "11rem",
    height: "8rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    justifyContent: "center",
    border: "1px solid #eee",
    marginRight: "1.5rem",
    marginBottom: "1.5rem",
    borderRadius: "12px",
    color: "#FFF !important",
    "& svg": {
      width: "3.5rem",
      height: "2.5rem",
      fill: "#FFF",
    },
    [theme.breakpoints.down("sm")]: {
      flex: "1",
      minWidth: "calc(50% - 2rem)",
      marginRight: "1rem",
      marginBottom: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      flex: "1",
      minWidth: "calc(100% - 2rem)",
      marginRight: "1rem",
      marginBottom: "1rem",
    }
  },
  filterText: {
    width: "80%",
    color: "inherit",
    marginTop: "0.5rem",
    textAlign: "center",
  },
  caption: {
    maxWidth: "500px",
    width: "100%",
    backgroundColor: "#F2F9FA",
    borderRadius: "0.8rem",
  },
  captionText: {
    paddingLeft: "1rem",
  },
  paperWidthSm: {
    margin: 0,
  },
  fieldRow: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  primaryButton: {
    [theme.breakpoints.down("xs")]: {
      flex: "1 1 auto",
    },
  },
  profileWrapper: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  uploadWrapper: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "200px",
      marginRight: "5rem",
    }
  },
  itemsWrapperMd: {
    maxWidth: "1000px",
    width: "100%",
  },
  itemsWrapperMax: {
    width: "100%",
  },
  itemsWrapper: {
    maxWidth: "500px",
    width: "100%",
  },
  buttonIcon: {
    fontSize: "1.3em",
    color: `${theme.palette.white} !important`,
  },
  buttonIconPsList: {
    fontSize: "1.3em",
    marginRight: "0.5rem",
    color: `${theme.palette.primary.main} !important`,
  },
  cardAction: {
    border: "1px solid #E7F0F0",
    borderRadius: "1rem",
  },
  listButton: {
    paddingLeft: "1em",
    paddingRight: "1em",
    width: "100%",
    marginRight: "0rem",
    "&.large":{
      marginRight: "0 !important"
    },
    [theme.breakpoints.down("md")]: {
      "&.large":{
        marginRight: "0 !important"
      }
    }
  },
  listViewButton: {
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  listButtonExtraPadding: {
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
  },
  table: {
    minWidth: 650,
    [theme.breakpoints.down("sm")]:
    {
      minWidth:"200px"
    }
  },
  tableContainer: {
    border: "1px solid #E7F0F0",
    borderRadius: "12px",
  },
  chip: {
    height: "auto !important",
    marginLeft: "1em !important",
    fontSize: `${theme.typography.caption.fontSize} !important`,
    color: `${theme.palette.secondary.dark} !important`,
    border: `0.16rem solid ${theme.palette.primary.light} !important`,
    borderRadius: "1.2rem",
    padding: "0rem 0.25rem !important",
    [theme.breakpoints.up('sm')]: {
      border: `0.125rem solid ${theme.palette.primary.light} !important`,
      borderRadius: "0.875rem",
      padding: "0rem 0.25rem !important",
    }
  },
  chipYellow: {
    height: "auto !important",
    marginLeft: "1em !important",
    fontSize: `${theme.typography.caption.fontSize} !important`,
    color: `${theme.palette.secondary.dark} !important`,
    border: `0.16rem solid #F7C963 !important`,
    borderRadius: "1.2rem",
    padding: "0rem 0.25rem !important",
    [theme.breakpoints.up('sm')]: {
      border: `0.125rem solid #F7C963 !important`,
      borderRadius: "0.875rem",
      padding: "0rem 0.25rem !important",
    }
  },
  chipFull: {
    height: "auto !important",
    marginLeft: "1em !important",
    fontSize: `${theme.typography.caption.fontSize} !important`,
    color: `${theme.palette.secondary.dark} !important`,
    border: `0.16rem solid #f54646 !important`,
    borderRadius: "1.2rem",
    padding: "0rem 0.25rem !important",
    [theme.breakpoints.up('sm')]: {
      border: `0.125rem solid #f54646 !important`,
      borderRadius: "0.875rem",
      padding: "0rem 0.25rem !important",
    }
  },
  chipUnavailable: {
    height: "auto !important",
    marginLeft: "1em !important",
    fontSize: `${theme.typography.caption.fontSize} !important`,
    color: `${theme.palette.secondary.dark} !important`,
    border: `0.16rem solid #8E8E99 !important`,
    borderRadius: "1.2rem",
    padding: "0rem 0.25rem !important",
    [theme.breakpoints.up('sm')]: {
      border: `0.125rem solid #8E8E99 !important`,
      borderRadius: "0.875rem",
      padding: "0rem 0.25rem !important",
    }
  },
  chipLabel: {
    padding: "0.2rem 0.5rem",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
  },
  dialogNoForm: {
    [theme.breakpoints.up('sm')]: {
      width: "21rem",
    }
  },
  dialogContent: {
    paddingTop: "1.5rem !important",
    paddingBottom: "1.5rem !important",
  },
  dialogTitle: {
    paddingTop: "1.6rem !important",
    paddingBottom: "0.75rem !important",
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    marginLeft: "1.5rem !important",
    marginRight: "1.5rem !important",
    borderBottom: "1px solid #E7F0F0"
  },
  dialogActions: {
    paddingBottom: "1.8rem !important",
    paddingLeft: "1.5rem !important",
    paddingRight: "1.5rem !important",
  },
  pillTab:{
    padding: "0.25rem 1.25rem 0.25rem",
    borderRadius: "1.75rem",
    marginRight: "0.75rem",
    borderColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]:{
      marginRight: 0,
      padding: "0.5rem 1.25rem 0.5rem",

    }
  },
  activePillTab: {
    padding: "0.25rem 1.25rem 0.25rem",
    marginRight: "0.75rem",
    borderColor: theme.palette.primary.main,
    borderRadius: "1.75rem",
    [theme.breakpoints.down("sm")]:{
      padding: "0.5rem 1.25rem 0.5rem",
      marginRight: 0,
    }
  },
  deleteIcon: {
    fontSize: "1.125rem",
    color: theme.palette.secondary.main,
  },
  deleteButton: {
    padding: "0rem",
    marginLeft: "0.25rem",
    maxWidth: "1.25rem",
    minWidth: "1.25rem",
    width: "1.25rem !important"
  },
  dialogTitleBorderBottom: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    borderBottom: "1px solid #E7F0F0",
  },
  dialogTitleRoot: {
    '& h2': {
      fontWeight: "500",
      fontSize: theme.typography.h3.fontSize,
    }
  },
  searchContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      position: "relative",
    },
  },
  mapContainerForm: {
    overflow: "hidden",
    borderRadius: "0.8rem",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  rounded: {
    borderRadius: "0.8rem",
  },
  mapDetailContainer: {
    overflow: "hidden",
    borderRadius: "0.8rem",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },
  scrollToDiv: {
    scrollMarginTop: "170px",
  },
  listBox: {
    height: "calc(100vh - 70px)",
    zIndex: 1000,
    backgroundColor: "#FFF",
    [theme.breakpoints.up('md')]: {
      flex: 1,
      overflow: "scroll",
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 161.6px - 1px)",
      position: "relative",
      display: "block",
    }
  },
  listBoxWithSingleResult: {
    height: "calc(100vh - 161.6px - 1px)",
    zIndex: 1000,
    backgroundColor: "#FFF",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      display: "block",
    }
  },
  listBoxWithResult: {
    minHeight: "calc(100vh - 70px)",
    zIndex: 1000,
    backgroundColor: "#FFF",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      display: "block",
    }
  },
  mapBoxDetails: {
    flex: 1,
  },
  mapBox: {
    height: "calc(100vh - 70px)",
    [theme.breakpoints.up("md")]: {
      flex: 1,
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      position: "absolute",
      bottom: "0",
      width: "100%"
    }
  },
  listContent: {
    [theme.breakpoints.up("md")]: {
      borderTop: "1px solid #E7F0F0",
    }
  },
  searchDividerSticky: {
    position: "sticky",
    top: "162px",
    zIndex: 1300,
  },
  searchDividerFiltersSticky: {
    top: "161px",
    zIndex: 1300,
    position: "sticky",
    borderBottom: "1px solid #E7F0F0",
    background: "white",
    height: "1px",
    width: "100%"
  },
  mobileFilters: {
    position: "sticky",
    width: "100%",
    zIndex: 1300,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "1.0rem !important",
      "&.map": {
        top: "0px"
      },
      "&.nomap": {
        top: "70px",
        borderBottom: "1px solid #E7F0F0"
      },
      "&.filterchips": {
        top: "69px"
      }
    }
  },
  desktopFilters: {

  },
  badgeClass: {
    top: "-1em !important",
    right: "-1em !important",
    fontSize: "0.6rem !important",
  },
  filterButton: {
    minWidth: "2.4rem",
    width: "2.4rem",
    height: "2.4rem",
    borderWidth: "1px",
    padding: "1.3rem",
    marginTop: "2.0rem",
    marginRight: "0.875rem",
    backgroundColor: "#fff",
    borderColor: theme.palette.secondary.light,
    "&:last-child": {
      marginRight: "0rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.5rem",
      marginBottom: "0.5rem",
    }
  },
  filterButtonIcon: {
    color: theme.palette.secondary.main,
    fill: theme.palette.primary.main,
    MsFlexNegative: "0",
    WebkitFlexShrink: "0",
    flexShrink: "0",
    marginRight: "0px !important",
  },
  filterChips: {
    flexWrap: "wrap",
  },
  filterChip: {
    display: "flex",
    alignItems: "center",
    padding: "1em",
    backgroundColor: "#F2F9FA",
    borderRadius: "12px",
    marginRight: "1em",
    marginTop: "1em",
    "& svg": {
      height: "1rem",
      width: "1rem",
      fill: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: "1em"
    }
  },
  inputDatePicker:{
    "& svg": {
      fill: theme.palette.primary.main,
    }
  },
  filterChipIcon: {
    marginRight: "1em",
    "&.campsiteIcon": {
      "& .cls-2": {
        stroke: theme.palette.primary.main
      }
    }
  },
  resultIcon: {
    marginRight: "0.5rem",
    height: "1rem",
    width: "1rem",
    "&.campsiteIcon": {
      "& .cls-2": {
        stroke: theme.palette.primary.main
      }
    }
  },
  searchContainerIcon: {
    fill: theme.palette.primary.main,
    fontSize: "1.25rem",
    position: "absolute",
    width: "1rem",
    height: "1rem",
    top: "1.1rem",
    [theme.breakpoints.down("sm")]: {
      top: "0.9rem",
    },
    right: "0.7rem",
    cursor: "pointer"
  },
  mapContainer: {
    height: "calc(100vh - 70px)",
  },
  mapStyles: {
    height: "100%",
    width: "25rem",
  },
  roomIcon: {
    fontSize: "2rem",
    color: theme.palette.primary.main,
    stroke: "white"
  },
  tooltipCardPicture: {
    objectFit: "cover",
    width: "100%",
    height: "10rem",
  },
  tooltipCardContent: {
  },
  tooltipCard: {
    margin: 0,
    padding: 0,
    width: "15rem",
  },
  resultItem: {
    cursor: "pointer",
    textDecoration: "none",
    "-webkit-tap-highlight-color": "transparent",
    color: "initial",
    background: "#fff",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  parkingSpotImageWrapper: {
    background: "#E7F0F0",
    borderRadius: "1rem",
    overflow: "hidden",
    height: "10rem",
    transform: "translateZ(0)",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flex: 2,
      minHeight: "15rem",
    },
    [theme.breakpoints.up("sm")]: {
      "& svg": {
        opacity: 0,
        transition: "opacity 250ms ease"
      },
      "&:hover svg": {
        opacity: 1,
        transition: "opacity 50ms ease"
      },
      "&:focus svg": {
        opacity: 1,
        transition: "opacity 50ms ease"
      }
    }
  },
  parkingSpotImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    "&:not([src])": {
      visibility: "hidden",
    },
    [theme.breakpoints.down("xs")]: {
      flex: 1,
    },
  },
  resultItemStar: {
    color: "#f0d050",
    fontSize: "1.1rem",
    marginRight: "0.25rem",
  },
  reviewInfoBox: {
    alignItems: "center",
  },
  resultItemInfo: {
    height: "100%",
  },
  singleLine: {
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  divider: {
    background: theme.palette.secondary.light,
  },
  resultItemSvgIcon: {
    width: "0.8rem",
    marginRight: "0.5rem",
    "&& path:not(cls-2)": {
      fill: theme.palette.primary.main,
      stroke: "none",
      strokeMiterlimit: "0",
      strokeWidth: "0"
    },
  },
  resultLineItemSvgIcon: {
    width: "0.8rem",
    marginRight: "0.5rem",
    "&& path:not(cls-2)": {
      fill: theme.palette.primary.main,
      stroke: "none",
      strokeMiterlimit: "0",
      strokeWidth: "0"
    },
    "&& line": {
      fill: theme.palette.primary.main,
      strokeWidth: "0.125rem"
    },
  },
  resultOutlinedItemSvgIcon: {
    width: "0.8rem",
    marginRight: "0.5rem",
    "&& path:not(cls-2)": {
      fill: "none",
      stroke: theme.palette.primary.main,
      strokeMiterlimit: "0",
      strokeWidth: "0.2rem"
    },
  },
  geo: {
    [theme.breakpoints.up("md")]: {
      height: "52px",
    },
    flex: "auto",
  },
  geoInput: {
    height: "52px",
    [theme.breakpoints.down('sm')]: {
      height: "2.7rem",
    },
    flex: "auto",
    alignItems: "center",
    border: "1px solid #E7F0F0",
    borderRadius: "12px",
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    transition: "box-shadow 50ms ease-in",
    "&:focus": {
      outline: "none !important",
      boxShadow: `0 0 0.0625rem 0.125rem ${theme.palette.primary.main}`,
      transition: "box-shadow 100ms ease-out",
    },
    "&:hover": {
      backgroundColor: "#F2F9FA"
    }
  },
  geoInputEdit: {
    height: "2.8rem",
    [theme.breakpoints.down('xs')]: {
      height: "3.0rem",
    },
    flex: "auto",
    alignItems: "center",
    border: "1px solid #ddd",
    borderRadius: "0.75rem",
    fontFamily: theme.typography.fontFamily,
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    "&:focus": {
      outline: "none",
    }
  },
  searchBox: {
    position: "relative",
    flex: 3,
    marginRight: "1rem",
    zIndex: 9999,
    [theme.breakpoints.down('sm')]: {
      marginTop: "1.5rem",
      marginBottom: "0.5rem",
    },
  },
  filterBox: {
    border: "1px solid #E7F0F0",
    borderRadius: "12px",
    display:"flex",
    alignItems:"center",
    flex: 1,
    height: "52px",
    position: "relative",
    padding: "0rem 1rem",
    cursor: "pointer",
    transition: "box-shadow 50ms ease-in",
    "&:hover": {
      backgroundColor: "#F2F9FA"
    }
  },
  filterBoxFocused: {
    border: "1px solid #E7F0F0",
    boxShadow: `0 0 0.0625rem 0.125rem ${theme.palette.primary.main}`,
    transition: "box-shadow 100ms ease-out",
    borderRadius: "12px",
    display:"flex",
    alignItems:"center",
    flex: 1,
    height: "52px",
    position: "relative",
    padding: "0rem 1rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F2F9FA"
    }
  },
  filterBoxCategoryWrapper: {
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: "-0.5rem",
    }
  },
  filterBoxCategory: {
    outline: "none",
    [theme.breakpoints.up("md")]: {
      width: "22rem",
      minWidth: "100%",
      marginRight: "0 !important",
      paddingTop: "0 !important",
      paddingBottom: "0rem !important"
    },
    display: "flex",
    flexDirection: "column",
    paddingLeft: "0rem",
    paddingRight: "0rem"
  },
  filterBoxCategoryName: {
    width: "100%",
    paddingTop: "0rem",
    paddingBottom: "0.25rem",
    [theme.breakpoints.up("md")]: {
      paddingBottom: "0.5rem",
    },
  },
  filterBoxCategoryNameAlt: {
    width: "100%",
    paddingTop: "0rem",
    paddingBottom: "0.25rem",
  },
  filterBoxMainCategory: {
    fontWeight: "500",
  },
  filterSubCategoryName: {
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.0675rem"
    }
  },
  filtersWrapper: {
    height: "30rem",
  },
  filterBoxFormControl: {
    flex: 1,
    minWidth: "40%",
    [theme.breakpoints.down('xs')]: {
      minWidth: "80%",
      flexDirection: "row-reverse",
      marginLeft: "0rem !important",
      marginRight: "0rem !important",
      justifyContent: "space-between",
    },
    display: "flex",
    fontSize: "0.6rem",
  },
  filterBoxCheckBox: {
    paddingRight: "0.1rem !important",
    [theme.breakpoints.down('xs')]: {
      paddingRight: "0rem !important",
    },
  },
  filterBoxDivider: {
    width: "100%",
    marginBottom: "1rem",
    marginTop: "0.25rem",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "0.5rem",
      marginTop: "0.25rem",
    },
  },
  filterBoxLabel: {
    whiteSpace: "break-spaces",
  },
  geoSuggests: {
    backgroundColor: "#fff",
    cursor: "pointer",
    borderRadius: "1rem",
    boxShadow: "0px 3px 3px 2px rgb(0 0 0 / 5%)",
    border: "1px solid #E7F0F0",
    padding: 0,
    overflow: "hidden",
    [theme.breakpoints.down('xs')]: {
      position: "absolute",
      width: "calc(100vw - 3rem)"
    },
  },
  geoSuggestsEdit: {
    backgroundColor: "#fff",
    borderRadius: "0.8rem",
    boxShadow: "0px 3px 3px 2px rgb(0 0 0 / 5%)",
    border: "1px solid #E7F0F0",
    padding: 0,
    margin: "0.7rem 0rem",
    overflow: "hidden",
    zIndex: 999,
    position: "relative",
    // height: "19rem",
    [theme.breakpoints.down('xs')]: {
      position: "absolute",
      width: "90vw",
      // height: "24rem",
    },
  },
  geoSuggestsHidden: {
    display: "none",
  },
  geoSuggestItem: {
    alignItems: "center",
    display: "flex",
    listStyle: "none",
    padding: "0.8rem 0.6rem",
    fontSize: "0.7rem",
    borderBottom: "1px solid #E7F0F0",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  geoSuggestItemActive: {
    backgroundColor: "#E7F0F0",
    listStyle: "none",
    padding: "0.8rem 0.6rem",
    fontSize: "0.7rem",
    borderBottom: "1px solid #E7F0F0",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  container: {
    minHeight: "100%",
  },
  pane: {
    minHeight: "100%",
  },
  pinContainer: {
    webkitTransform: 'translateZ(0px)',
    position: "relative",
    width: '56px',
    height: '56px',
    transform: 'translate(-50%, -50%)',
  },
  pin: {
    webkitTransform: 'translateZ(0px)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(1)',
    width: '48px',
    height: '48px',
    transition: 'transform 0.2s',
  },
  bigPin: {
    // scale is 56px approx
    transform: 'translate(-50%, -50%) scale(1.166)',
  },
  greenPin: {
    fill: "#83BE6E",
  },
  yellowPin: {
    fill: "#F7C963",
  },
  redPin: {
    fill: "#f54646",
  },
  greyPin: {
    fill: "#8E8E99",
  },
  greenHoveredPin: {
    fill: "#72A660",
  },
  yellowHoveredPin: {
    fill: "#DEB459",
  },
  redHoveredPin: {
    fill: "#f42e2e",
  },
  greyHoveredPin: {
    fill: theme.palette.secondary.main,
  },
  starIcon: {
    color: "#f0d050",
    fontSize: "1.675rem",
    marginRight: "0.2rem",
    [theme.breakpoints.up('sm')]:{
      fontSize: "1.15rem",
    }
  },
  mobileCardStarIcon: {
    color: "#f0d050",
    fontSize: "1.375rem",
    marginRight: "0.2rem",
    [theme.breakpoints.up('sm')]:{
      fontSize: "1.15rem",
    }
  },
  freeSpacePill: {
    display: "inline-block",
    border: "0.16rem solid #3a8a8f",
    borderColor: theme.palette.primary.light,
    borderRadius: "1.2rem",
    padding: "0.1rem 0.8rem",
    fontSize: "0.875rem",
    [theme.breakpoints.up('sm')]: {
      fontSize: "0.675rem",
      border: "0.125rem solid #3a8a8f",
      borderColor: theme.palette.primary.light,
      borderRadius: "0.875rem",
      padding: "0.125rem 0.5rem 0.05rem",
    }
  },
  freeSpacePillYellow: {
    display: "inline-block",
    border: "0.16rem solid #f7c963",
    borderColor: "#f7c963",
    borderRadius: "1.2rem",
    padding: "0.1rem 0.8rem",
    fontSize: "0.875rem",
    [theme.breakpoints.up('sm')]: {
      fontSize: "0.675rem",
      border: "0.125rem solid #f7c963",
      borderColor: "#f7c963",
      borderRadius: "0.875rem",
      padding: "0.125rem 0.5rem 0.05rem",
    }
  },
  freeSpacePillRed: {
    display: "inline-block",
    border: "0.16rem solid #f54646",
    borderColor: "#f54646",
    borderRadius: "1.2rem",
    padding: "0.1rem 0.8rem",
    fontSize: "0.875rem",
    [theme.breakpoints.up('sm')]: {
      fontSize: "0.675rem",
      border: "0.125rem solid #f54646",
      borderColor: "#f54646",
      borderRadius: "0.875rem",
      padding: "0.125rem 0.5rem 0.05rem",
    }
  },
  freeSpacePillFull: {
    display: "inline-block",
    border: "0.16rem solid #f54646",
    borderColor: "#f54646",
    borderRadius: "1.2rem",
    padding: "0.1rem 0.8rem",
    fontSize: "0.875rem",
    [theme.breakpoints.up('sm')]: {
      fontSize: "0.675rem",
      border: "0.125rem solid #f54646",
      borderColor: "#f54646",
      borderRadius: "0.875rem",
      padding: "0.125rem 0.5rem 0.05rem",
    }
  },
  freeSpacePillUnavailable: {
    display: "inline-block",
    border: "0.16rem solid #3a8a8f",
    borderColor: theme.palette.secondary.main,
    borderRadius: "1.2rem",
    padding: "0.1rem 0.8rem",
    fontSize: "0.875rem",
    [theme.breakpoints.up('sm')]: {
      fontSize: "0.675rem",
      border: "0.125rem solid #3a8a8f",
      borderColor: theme.palette.secondary.main,
      borderRadius: "0.875rem",
      padding: "0.125rem 0.5rem 0.05rem",
    }
  },
  buttonXS: {
    minWidth: "1.75rem",
    width: "2.2rem",
    height: "1.4rem",
    borderRadius: "1.0rem",
    padding: 0,
    margin: 0,
  },
  checkIcon: {
    marginRight: "0px !important",
    fontSize: "0.875rem",
  },
  mobileFilterBox: {
    height: "100%",
    width: "100%",
    zIndex: "9999",
    overflow: "scroll",
    background: "#fff",
    position: "fixed",
    top: 0,
    left: 0,
  },
  mobileFilterBoxClose: {
    width: "100%",
    textAlign: "right",
    padding: "1rem",
  },
  mobileCardContainer: {
    bottom: "0rem",
    left: "0rem",
    width: "calc(100vw - 3rem)",
    margin: "1.0rem 1.5rem",
  },
  mobileCardPicture: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    borderRadius: "12px",
    background: "#E7F0F0",
    position: "absolute",
    zIndex: 2,
    "&.show": {
      opacity: 1.0,
      transition: "opacity 400ms"
    },
    "&.hide": {
      opacity: 0.0,
    }
  },
  mobileCard: {
    margin: 0,
    padding: 0,
    width: "100%",
    height: "100%"
  },
  mobileCardTag: {
    backgroundColor: "#F2F9FA",
    borderRadius: "12px",
    padding: "4px !important",
    maxHeight: "28px"
  },
  cardHeading: {
    fontWeight: "500",
  },
  reviewButton: {
    padding: "0.5rem 0.75rem"
  },
  buttonWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    position: 'relative',
    [theme.breakpoints.down("sm")]:{
      width: "100%",
    },
  },
  buttonProgress: {
    color: theme.palette.primary.color,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  countrySelect: {
    width: "100%",
    marginTop: "0.8rem",
  },
  actionBox: {
    justifyContent: "flex-end",
  },
  overallBalance: {
    [theme.breakpoints.up("sm")]:{
      fontSize: "1.125rem",
      fontWeight: "400"
    }
  },
  telCodeSelect: {
    width:"8rem",
    marginRight: "0.5rem",
    marginLeft: "0.5rem",
    marginTop: "1.375rem"
  },
  resetFilters:{
    padding: "0.375rem 0.75rem",
    borderRadius: "1rem",
    borderWidth: "1px !important",
    zIndex: "9999",
    [theme.breakpoints.up("md")]:{
      position: "absolute"
    }
  },
  resetFilterBox:{
    position: "sticky",
    top: 0,
    borderBottom: `1px solid`,
    borderBottomColor: theme.palette.divider,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  financialsCard: {
    height: "100%"
  },
  accountsCard: {
    flex: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      minWidth: "50%",
      '&:last-child': {
        maxWidth: "50%",
      }
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%"
    }
  },
  accountsCardContent: {
    padding: theme.spacing(2),
  },
  statusChip: {
    alignItems: "center",
    paddingLeft: "1em",
    paddingRight: "1em",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
    backgroundColor: "#F2F9FA",
    borderRadius: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  paymentAttrText: {
    marginRight: "0.8em",
  },
  paymentDetailsActionsContainer: {
    display: "flex",
    flexDirection: "column",
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(2),
        marginBottom: 0
      }
    }
  },
  paymentDetailsActionsDivider: {
    [theme.breakpoints.up("sm")]: {
      display: 'none !important'
    }
  },
  cardBodyText: {
    color: "#0A0924",
    marginTop: theme.spacing(2),
  },
  cardBodyLink: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    display: "block",
    color: "#0A0924",
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  financialsCardAmount: {
    textAlign: "right",
    color: `${theme.palette.secondary.light}`
  },
  financialsCardAmountContainer: {
    background: theme.palette.primary.main
  },
  formDescription: {
    fontWeight: 400,
    fontSize: "0.8rem",
    padding: "0.5rem 0rem 0rem",
  },
  carNumberPlate: {
    color: `${theme.palette.secondary.main}`,
  },
  carPrimaryContainer: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    background: `${theme.palette.primary.main}`,
    borderRadius: "1.0rem",
    marginRight: "0.5rem",
    minWidth: "8.25rem",
    padding: "0.25rem 0.625rem 0.25rem 0.5rem",
    [theme.breakpoints.down("md")]:{
      padding: "0.25rem 0.375rem 0.25rem 0.5rem",
      marginRight: "0.5rem",
      minWidth: "7.75rem",
    },
    [theme.breakpoints.down("xs")]:{
      padding: "0.25rem 0.5rem 0.25rem 0.5rem",
      minWidth: "8.0rem",
      marginTop: "0.25rem",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  carPrimaryStarIcon: {
    color: `${theme.palette.secondary.light}`,
    fontSize: "1.25rem",
    [theme.breakpoints.up("md")]:{
      fontSize: "0.875rem",
    }
  },
  carPrimaryText: {
    color: `${theme.palette.secondary.light}`,
    fontSize: "0.625rem",
  },
  carButton: {
    minWidth: "2.75rem",
    width: "2.75rem",
    height: "2.75rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "0.75rem"
  },
  carButtonIcon: {
    fontSize: "1.25rem",
    margin: 0,
    padding: 0
  },
  carNewDialog: {
    [theme.breakpoints.up('sm')]: {
      width: "28rem",
    }
  },
  carNewPrimaryUnchecked: {
    color: `${theme.palette.secondary.main}`,
    fontSize: "1.75rem",
    [theme.breakpoints.up("md")]:{
      fontSize: "1.125rem",
      cursor: "pointer",
    }
  },
  carNewPrimaryChecked: {
    color: `${theme.palette.primary.main}`,
    fontSize: "1.75rem",
    [theme.breakpoints.up("md")]:{
      fontSize: "1.125rem",
      cursor: "pointer",
    }
  },
  checkedInChip: {
    minWidth: "6.25rem",
    minHeight: "1.75rem",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "1.0rem",
    color: "white",
    marginLeft: "0.5rem",
    padding: "0.1875rem 0.5rem 0.1875rem",
  },
  cardCheckedInChip: {
    width: "1.5rem",
    height: "1.5rem",
    minWidth: "1.5rem",
    minHeight: "1.5rem",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "1.0rem",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      width: "1.75rem",
      height: "1.75rem",
      minWidth: "1.75rem",
      minHeight: "1.75rem",
    }
  },
  phoneInput: {
    width: "100% !important",
    color: `${theme.palette.text.primary} !important`,
    borderRadius: "0.75rem !important",
    fontSize: "14px !important",
    padding: "0.2rem 3.75rem 0.2rem 0.75rem !important",
    borderColor: `${theme.palette.secondary.light} !important`,
    transition: "none 0s ease 0s !important",
    boxShadow: "none !important",
    height: "2.8rem !important",
  },
  phoneContainer: {
    width: "100%",
    marginTop: "0.8rem",
    "& .special-label": {
      display: "none",
    }
  },
  phoneButton: {
    right: "0.0625rem",
    "&& .arrow": {
      right: "29px !important",
    }
  },
  phoneDropdown: {
    right: 0,
    width: "500px !important",
    [theme.breakpoints.down("xs")]: {
      width: "85vw !important",
    },
  },
  titlePlaceholder: {
    width: "100%",
    height: "1.25rem",
    borderRadius: "0.5rem",
    // background: "#E7F0F0"
  },
  locationPlaceholder: {
    width: "40%",
    height: "0.875rem",
    borderRadius: "0.5rem",
    // background: "#E7F0F0",
    marginTop: "0.5rem"
  },
  iconsPlaceholder: {
    width: "65%",
    height: "1.0rem",
    borderRadius: "0.5rem",
    // background: "#E7F0F0",
    marginTop: "0.75rem"
  },
  reviewPlaceholder: {
    width: "15%",
    height: "1.25rem",
    borderRadius: "0.5rem",
    // background: "#E7F0F0"
  },
  availabilityPlaceholder: {
    width: "30%",
    height: "1.25rem",
    borderRadius: "0.5rem",
    // background: "#E7F0F0"
  },
  parkingSpotImageWrapperPlaceholder: {
    // background: "#E7F0F0",
    borderRadius: "1rem",
    overflow: "hidden",
    height: "10rem",
    transform: "translateZ(0)",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flex: 2,
      minHeight: "15rem",
    }
  },
  parkingSpotImagePlaceholder: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      flex: 1,
    },
  },
  resultTitle: {
    color: "#000",
  },
  resultCard: {
    position: "absolute",
    visibility: "hidden",
    opacity: 0,
    top: 0,
    left: 0,
    width: "100%",
    '&:hover': {
      "& $resultTitle": {
        color: theme.palette.primary.main,
      }
    }
  },
  resultCardVisible: {
    visibility: "visible",
    opacity: 1,
    transition: "visibility 0s linear 0s, opacity 700ms"
  },
  resultCardInvisible: {
    visibility: "hidden",
    opacity: 0,
  },
  mapLoaderContainer: {
    background: "#fff",
    position: "absolute",
    top: "2.5rem",
    left: "50%",
    transform: "translateX(-50%)",
    borderRadius: "0.5rem",
    height: "2.2rem",
    width: "4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 0.5rem 0.75rem 0 rgba(0,0,0,0.1)",
    [theme.breakpoints.down("sm")]: {
      top: "5.5rem",
    }
  },
  mapLoader: {
    margin: 0,
    height: "1.625rem"
  },
  mapSearchCheckboxContainer: {
    background: "#fff",
    position: "absolute",
    top: "2.5rem",
    left: "50%",
    transform: "translateX(-50%)",
    borderRadius: "8px",
    height: "2.2rem",
    padding: "0.3125rem 0.625rem 0.3125rem 0.5rem !important",
    whiteSpace: "nowrap",
    boxShadow: "0 0.5rem 0.75rem 0 rgba(0,0,0,0.1)",
    [theme.breakpoints.down("sm")]: {
      top: "5.5rem",
    }
  },
  mapSearchCheckbox: {
    marginRight: "0.3rem",
    padding: "0 0.25rem 0 0rem !important",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.3125rem 0 0.0625rem !important",
    },
    "& svg": {
      width: "1.2rem",
      height: "1.2rem",
    }
  },
  mapSearchHereButton: {
    padding: "0 0.25rem 0 0rem !important",
    margin: "0.125rem 0rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.3125rem 0 0.0625rem !important",
      fontSize: "1.625rem"
    }
  },
  mapSearchHereBox: {
    cursor: "pointer"
  },
  printButton: {
    minWidth: "2.5rem !important",
    minHeight: "2.5rem !important",
    padding: "0.375rem 0.75rem"
  },
  printButtonAll: {
    width: "3rem !important",
    minWidth: "2.7rem !important",
    height: "3rem !important",
    minHeight: "2.7rem !important",
    padding: "0.375rem 0.75rem",
    [theme.breakpoints.down("sm")]: {
      width: "2.8rem !important",
    minWidth: "2.8rem !important",
    height: "2.8rem !important",
    minHeight: "2.8rem !important"
    }
  },
  printIcon: {
    fontSize: "1.375rem !important",
    color: `${theme.palette.primary.main} !important`,
    position: "relative",
  },
  printProgress: {
    color: theme.palette.primary.color,
    position: 'absolute',
    top: '22%',
    left: '25%',
    margin: 0,
    "& svg": {
      marginRight: "0 !important"
    }
  },
  printProgressAll: {
    color: theme.palette.primary.color,
    position: 'absolute',
    top: '24%',
    left: '26%',
    margin: 0,
    "& svg": {
      marginRight: "0 !important"
    },
  },
  manualTaxFieldBox: {
    "& div": {
      margin: "0 !important"
    }
  },
  taxChoiceSubtext: {
    marginLeft: "1.5rem",
    marginBottom: "0.5rem",
    paddingTop: "0 !important"
  },
  withdrawHistoryColumn: {
    [theme.breakpoints.down(375)]: {
      width: "40vw",
      maxWidth: "40vw",
      overflow: "hidden",
      textOverflow: " "
    },
    [theme.breakpoints.between(375, 600)]: {
      width: "50vw",
      maxWidth: "50vw",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  mapLocationButtonContainer: {
    background: "#fff",
    position: "absolute",
    bottom: "2rem",
    right: "1.75rem",
    transform: "translate(-50%, -50%)",
    borderRadius: "0.5rem",
    height: "2.625rem",
    width: "2.625rem",
    padding: "0.5625rem !important",
    cursor: "pointer",
    whiteSpace: "nowrap",
    boxShadow: "0 0.5rem 0.75rem 0 rgba(0,0,0,0.1)",
    [theme.breakpoints.down("sm")]: {
      bottom: "initial",
      top: "2.875rem !important",
      right: "6.75rem",
      boxShadow: "none",
      border: "0.0625rem solid #E7F0F0",
      height: "2.71875rem",
      width: "2.71875rem",
    }
  },
  mapLocationButtonSelected: {
    color: `${theme.palette.primary.main} !important`
  },
  mapLocationButton: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.625rem"
    }
  },
  parkingSpotResultDetailsWrapper: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "67.74%"
    }
  },
  mapCenterDot: {
    width: "0.75rem !important",
    height: "0.75rem !important",
    borderRadius: "1.5rem",
    border: "0.1rem solid white",
    background: theme.palette.primary.main,
    boxShadow: `0rem 0.01rem 0.05rem 0.375rem ${theme.palette.primary.main}40`
  },
  dialogContentAddPayment: {
    padding: "0 !important",
  },
  addPaymentBtn: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0rem 24px 1rem !important",
  },
  addPaymentDialogTitle: {
    fontSize: "1rem",
    padding: "1.25rem 0 0.25rem !important",
  },
  securityTabs: {
    marginTop: "2rem",
    width: "28rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  newSubUserDialogTitle: {
    borderBottom: "0.0625rem solid #E7F0F0",
    padding: "24px 0px 14px !important"
  },
  subUserNewDialog: {
    [theme.breakpoints.up('sm')]: {
      width: "32rem",
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  actionsButton: {
    minWidth: "initial",
    width: "initial",
    padding: "0.375rem 0.375rem",
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    }
  },
  moreHorizIcon: {
    fontSize: "medium",
    color: theme.palette.primary.main
  },
  menuItem: {
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    }
  },
  menuItemRemove: {
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
    '&:hover': {
      backgroundColor: theme.palette.secondary.warn,
    }
  },
  menuItemIcon: {
    marginRight: '0.75rem'
  },
  actionsWheel: {
    verticalAlign: "middle",
  },
  addPaymentDialog: {
    "& .MuiDialog-paperWidthSm": {
      width: "32rem !important",
      maxWidth: "48rem !important"
    }
  },
  paymentCardSvg: {
    width: "100%",
    height: "13.5rem"
  },
  paymentFailedSVG: {
    height: "7rem",
    maxWidth: "100%"
  },
  paymentFailedDialog: {
    maxWidth: "23rem",
    padding: "0",
    paddingTop: "2.5rem",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  dialogTitlePaymentFailed: {
    paddingBottom: "2rem"
  },
  clickable: {
    cursor: "pointer"
  },
  largerTabs: {
    "&.MuiTabs-root": {
      [theme.breakpoints.down("sm")]: {
        maxHeight: "3.25rem !important",
        minHeight: "3.25rem !important",
        padding: "0.1875rem"
      },
    },
  },
  largerTab: {
    "&.MuiTab-root": {
      [theme.breakpoints.down("sm")]: {
        maxHeight: "2.875rem !important",
        minHeight: "2.875rem !important",
      },
    },
  },
  parkedCarsCard: {
    marginLeft: "1.5rem",
    maxWidth: "33%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    }
  },
  parkedCarsCardContentBox: {
    display: "flex",
    fontSize: "1rem",
  },
  parkedCarsCardLabel: {
    fontWeight: 600,
    marginRight: "0.5rem",
    display: "inline-block",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
  },
  parkedCarsCardButtonBox: {
    fontSize: "1rem",
    cursor: "pointer",
  },
  parkedCarsCardButton: {
    marginRight: "0.5rem",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
  },
  parkedCarsContent: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
  },
  parkedCarsVerified: {
    background: "#018a941a",
  },
  expanded: {
    transform: "rotate(180deg)",
  }
}));
