import * as Types from './constants';

export const updateHost = (payload, token, tag, path) => {
  return {
    type: Types.UPDATE_HOST,
    payload,
    token,
    tag,
    path
  };
};

export const updateGuest = (payload, token, tag, path) => {
  return {
    type: Types.UPDATE_GUEST,
    payload,
    token,
    tag,
    path
  };
};

export const setUserType = (userType) => {
  return {
    type: Types.SET_USER_TYPE,
    userType,
  };
};

export const postProfilePic = (photo, token) => {
  return {
    type: Types.POST_PROFILE_PIC,
    photo,
    token,
  };
};

export const setProfilePic = (photo) => {
  return {
    type: Types.SET_PROFILE_PIC,
    photo,
  };
};

export const setUpdatingProfilePic = (status) => {
  return {
    type: Types.SET_UPDATING_PROFILE_PIC,
    status,
  };
};

export const setRegisteringUser = (status) => {
  return {
    type: Types.SET_REGISTERING_USER,
    status,
  };
};

export const setLoggingInUser = (status) => {
  return {
    type: Types.SET_LOGGING_IN_USER,
    status,
  };
};

export const setLoggingOutUser = (status) => {
  return {
    type: Types.SET_LOGGING_OUT_USER,
    status,
  };
};

export const setUpdatingUser = (status) => {
  return {
    type: Types.SET_UPDATING_USER,
    status,
  };
};

export const setUpdatingUserPassword = (status) => {
  return {
    type: Types.SET_UPDATING_USER_PASSWORD,
    status,
  };
};

export const setUser = (payload) => {
  return {
    type: Types.SET_USER,
    payload,
  };
};

export const fetchHostProfile = (token) => {
  return {
    type: Types.FETCH_HOST_PROFILE,
    token,
  };
};

export const fetchGuestProfile = (token) => {
  return {
    type: Types.FETCH_GUEST_PROFILE,
    token,
  };
};

export const updateHostLocale = (payload, token) => {
  return {
    type: Types.UPDATE_HOST_LOCALE,
    payload,
    token,
  };
};

export const updateGuestLocale = (payload, token) => {
  return {
    type: Types.UPDATE_GUEST_LOCALE,
    payload,
    token,
  };
};

export const setIsNewProfile = (value) => {
  return {
    type: Types.SET_IS_NEW_PROFILE,
    value,
  };
};

export const fetchSubHosts = (token, requestUrl) => {
  return {
    type: Types.FETCH_SUB_HOSTS,
    token,
    requestUrl
  };
};

export const setSubHosts = (payload) => {
  return {
    type: Types.SET_SUB_HOSTS,
    payload,
  };
};

export const postNewSubHost = (token, payload) => {
  return {
    type: Types.POST_NEW_SUB_HOST,
    token,
    payload,
  };
};

export const deleteSubHost = (token, id) => {
  return {
    type: Types.DELETE_SUB_HOST,
    token,
    id,
  };
};

export const updateSubHost = (token, id, payload, url) => {
  return {
    type: Types.UPDATE_SUB_HOST,
    token,
    id,
    payload,
    url
  };
};

export const setFetchingSubHosts = (value) => {
  return {
    type: Types.SET_FETCHING_SUB_HOSTS,
    value
  };
};

export const updateSubHostProfile = (token, payload) => {
  return {
    type: Types.UPDATE_SUB_HOST_PROFILE,
    token,
    payload
  };
};

export const updateSubHostLocale = (token, payload) => {
  return {
    type: Types.UPDATE_SUB_HOST_LOCALE,
    token,
    payload
  };
};
