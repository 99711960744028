import initialState from '../store/initialState';
import * as Types from '../actions/constants';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_AUTH:
      if (action.payload.token !== state.token || action.payload.expiry !== state.expiry) {
        return Object.assign({}, state, { ...action.payload });
      }
      return state;
    case Types.SET_PASSWORD_RESET_REQUEST_SENT:
      return Object.assign({}, state, { passwordResetRequestSent: action.value });
    default:
      return state;
  };
};
