import { SvgIcon } from "@material-ui/core";
import React from "react";

function Referral(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.2418 14.0949C13.6923 14.1657 14 14.5706 14 15.0266V21C14 21.5523 13.5523 22 13 22H4.99975C4.44756 22 3.99345 21.5507 4.06213 21.0028C4.17189 20.1274 4.42601 19.2738 4.8163 18.4774C5.35368 17.3809 6.13494 16.4219 7.10022 15.674C8.0655 14.9261 9.18918 14.4091 10.3852 14.1626C11.3276 13.9684 12.2953 13.9462 13.2418 14.0949ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM18 17V15.7454C18 14.8796 19.0254 14.4229 19.669 15.0021L22.1741 17.2567C22.6155 17.6539 22.6155 18.3461 22.1741 18.7433L19.669 20.9979C19.0254 21.5771 18 21.1204 18 20.2546V19H16C15.4477 19 15 18.5523 15 18C15 17.4477 15.4477 17 16 17H18Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default Referral;
