import { Box, Card, makeStyles, Typography, Select, MenuItem } from "@material-ui/core";
import RightArrowIcon from "components/common/icons/RightArrowIcon";
import React, { useState } from "react";
import Link from "components/common/link/Link";
import {ReactComponent as DropdownArrow} from "../../../../assets/images/dropdown-arrow.svg";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  links: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "0.3rem",
    height: "2.79rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    }
  },
  countrySelect: {
    width: "100%",
    marginTop: "0.3em",
  },
  title: {
    marginBottom: "0.5em",
    lineHeight: "1em",
  },
  mb3: {
    marginBottom: "0.5em",
  },
  linkEm: {
    lineHeight: "1em",
    color: theme.palette.secondary.main,
    transition: theme.transitions.create(["color", "background-color"], {
      duration: theme.transitions.duration.short,
    }),
    "&:hover": {
      color: theme.palette.secondary.dark,
      textDecoration: "none",
    },
  },
  link: {
    display: "flex",
    lineHeight: "1em",
    alignItems: "center",
    "& span": {
      marginRight: theme.spacing(1),
    },
    transition: theme.transitions.create(["color", "background-color"], {
      duration: theme.transitions.duration.short,
    }),
    "&:hover": {
      color: theme.palette.primary.dark,
      textDecoration: "none",
    },
  },
  linkTypography: {
    ...theme.typography.button2,
  },
  linkIcon: {
    fontSize: 16,
  },
  card: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "3rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem",
    },
    [theme.breakpoints.up("sm")]: {
      flex: "1 1 60%",
    },
  },
  img: {
    display: "block",
    width: "100%",
    height: 233,
    objectFit: "cover",
    objectPosition: "left",
    [theme.breakpoints.up("sm")]: {
      flex: "1 1 40%",
      maxHeight: 400,
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
      maxHeight: "unset",
      maxWidth: 500,
    },
  },
}));

function AccountCard({ title, image, description, action }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [country, setCountry] = useState(null);
  return (
    <Card className={classes.card}>
      <Box>
        <img className={classes.img} src={image} alt={title} />
      </Box>
      <Box className={classes.cardContent}>
        <Typography gutterBottom variant="h3" component="h3" className={classes.title}>
          {title}
        </Typography>
        <Typography
          className={classes.mb3}
          variant="body1"
          color="textPrimary"
          component="p"
        >
          {description}
        </Typography>
        {!country ? (
          <Select
            className={classes.countrySelect}
            IconComponent={(props) => (<DropdownArrow {...props}/>)}
            value={country}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return <span>{t("countries.select-to-proceed")}</span>;
              }
              return t(`countries.${selected.replace(/\s/g, '').toLowerCase()}`)
            }}
            inputProps={{
              onChange: (e) => {
                setCountry(e.target?.value)
              }
            }}
            variant= "outlined"
            required
            MenuProps={{
              style: {
                maxHeight: 280,
              }
            }}
          >
            <MenuItem disabled value="">
              <em>{t("countries.select-to-proceed")}</em>
            </MenuItem>
            <MenuItem value="Switzerland">{t("countries.switzerland")}</MenuItem>
            <MenuItem value="Germany">{t("countries.germany")}</MenuItem>
            <MenuItem value="Austria">{t("countries.austria")}</MenuItem>
            <MenuItem value="Australia">{t("countries.australia")}</MenuItem>
            <MenuItem value="France">{t("countries.france")}</MenuItem>
            <MenuItem value="Italy">{t("countries.italy")}</MenuItem>
            <MenuItem value="Liechtenstein">{t("countries.liechtenstein")}</MenuItem>
            <MenuItem value="Belgium">{t("countries.belgium")}</MenuItem>
            <MenuItem value="Malta">{t("countries.malta")}</MenuItem>
            <MenuItem value="Bulgaria">{t("countries.bulgaria")}</MenuItem>
            <MenuItem value="Netherlands">{t("countries.netherlands")}</MenuItem>
            <MenuItem value="Denmark">{t("countries.denmark")}</MenuItem>
            <MenuItem value="Poland">{t("countries.poland")}</MenuItem>
            <MenuItem value="Estonia">{t("countries.estonia")}</MenuItem>
            <MenuItem value="Portugal">{t("countries.portugal")}</MenuItem>
            <MenuItem value="Finland">{t("countries.finland")}</MenuItem>
            <MenuItem value="Romania">{t("countries.romania")}</MenuItem>
            <MenuItem value="Sweden">{t("countries.sweden")}</MenuItem>
            <MenuItem value="Greece">{t("countries.greece")}</MenuItem>
            <MenuItem value="Slovakia">{t("countries.slovakia")}</MenuItem>
            <MenuItem value="Ireland">{t("countries.ireland")}</MenuItem>
            <MenuItem value="Slovenia">{t("countries.slovenia")}</MenuItem>
            <MenuItem value="Spain">{t("countries.spain",)}</MenuItem>
            <MenuItem value="Czech Republic">{t("countries.czechrepublic")}</MenuItem>
            <MenuItem value="Latvia">{t("countries.latvia")}</MenuItem>
            <MenuItem value="Hungary">{t("countries.hungary")}</MenuItem>
            <MenuItem value="Lithuania">{t("countries.lithuania")}</MenuItem>
            <MenuItem value="Cyprus">{t("countries.cyprus")}</MenuItem>
            <MenuItem value="Luxembourg">{t("countries.luxembourg")}</MenuItem>
            <MenuItem value="United Kingdom">{t("countries.unitedkingdom")}</MenuItem>
            <MenuItem value="Norway">{t("countries.norway")}</MenuItem>
            <MenuItem value="United States">{t("countries.unitedstates")}</MenuItem>
          </Select>
        ) : (
          <Box className={classes.links}>
            <Link
              component="button"
              onClick={() => action.onClick(country)}
              className={classes.link}
              TypographyClasses={{
                root: classes.linkTypography,
              }}
            >
              <span>{action.text}</span>{" "}
              <RightArrowIcon className={classes.linkIcon} />
            </Link>
            <Link
              component="button"
              onClick={() => {
                setCountry(null)
              }}
              className={classes.linkEm}
              TypographyClasses={{
                root: classes.linkTypography,
              }}
            >
              <em>Click here to change country ({country} selected)</em>
            </Link>
          </Box>
        )}
      </Box>
    </Card>
  );
}

export default AccountCard;
