import React, { useEffect, useState } from 'react';
import { Divider, Container, Box, Typography, Card, Chip, Checkbox, Button, CircularProgress, Tooltip } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { connect, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import PrintIcon from '@mui/icons-material/PictureAsPdf';
import SheetIcon from '@mui/icons-material/InsertDriveFile';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Pagination from '../../common/pagination'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';

import { useStyles } from '../common/styles';
import TitleSection from '../../common/titleSection';
import { fetchFinancials, postWithdrawBalance, setWithdrawing, fetchSingleFinancialsReceipt, fetchAllFinancialsReceipt, setFetchingFinancialsReceipt, patchPaymentDetails, postPaymentSchedule } from '../../../data/actions/payment';
import { addLoader, addLoaderForItem } from '../../../data/actions/config';
import MissingDataPlaceholder from '../../common/missingDataPlaceholder';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';



const round = (value, decimals = 2) => {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals).toFixed(decimals);
}

const amountForCurrency = (amount, currency) => {
  return currency + " +" + round (amount, 2);
}

const truncatePayoutNumber = (str, isSmallMobile, isNormalMobile) => {
  let len = 8;
  if (!isSmallMobile && !!isNormalMobile){
    len = 12;
  }
  else if (!isSmallMobile && !isNormalMobile){
    return str;
  }
  let truncatedStr = str.substring(0, len);
  return truncatedStr + "...";
}

const Financials = ({ token, iban, paymentData, fetchFinancials, postWithdrawBalance, setWithdrawing, withdrawing, addLoader, fetchSingleFinancialsReceipt, fetchAllFinancialsReceipt, setFetchingFinancialsReceipt, fetchingFinancialsReceipt, paymentDetails, postPaymentSchedule, push }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [payoutDialogueOpen, setPayoutDialogueOpen] = React.useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let isSmallMobile = useMediaQuery(theme.breakpoints.down(375));
  let isNormalMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const connected = !!paymentDetails?.business_type;
  const [payoutScheduleValue, setPayoutScheduleValue] = React.useState(paymentData.payout_schedule)

  useEffect(() => {
    dispatch(addLoaderForItem("stripe-status"));
    dispatch(patchPaymentDetails(token));
  }, [token]);

  const handleClickOpen = () => {
    if (connected) {
      setOpen(true);
    } else {
      push('/payment-details')
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closePayoutDialogue = () => {
    setPayoutDialogueOpen(false);
  };

  const handleSubmitForWithdrawRequest = () => {
    setWithdrawing(true);
    postWithdrawBalance(token);
    setOpen(false);
  }

  useEffect(() => {
    addLoader();
  }, []);

  useEffect(() => {
    fetchFinancials(token)
  }, [fetchFinancials, token]);

  const { t } = useTranslation();

  const handlePrintAll = () => {
    setFetchingFinancialsReceipt("all", true);
    fetchAllFinancialsReceipt(token);
  }

  const handlePrintAllCSV = () => {
    setFetchingFinancialsReceipt("all", true, true);
    fetchAllFinancialsReceipt(token, true);
  }

  const updatePayoutSchedule = () => {
    if (paymentData.payout_schedule !== payoutScheduleValue) {
      postPaymentSchedule(payoutScheduleValue, token)
    }
    // setDropdownValue(e.target.value)
  }

  const fetchingReceipt = fetchingFinancialsReceipt.id === "all" && fetchingFinancialsReceipt.status;
  const pdfDisabled = !!(fetchingFinancialsReceipt.id === "all" && fetchingFinancialsReceipt.status && !fetchingFinancialsReceipt.printCSV);
  const csvDisabled = !!(fetchingFinancialsReceipt.id === "all" && fetchingFinancialsReceipt.status && fetchingFinancialsReceipt.printCSV);



  return (
    <Container maxWidth="md">
      <Dialog
          open={payoutDialogueOpen}
          onClose={closePayoutDialogue}
          aria-labelledby="form-dialog-title"
          classes={{
            paperWidthSm: classes.paperWidthSm,
          }}
          style={{
            padding: '1em',
          }}
        >
          <DialogTitle
            className={classes.dialogTitle}
            classes={{
              root: classes.dialogTitleRoot,
            }}
          >
            <Typography variant="h3">
              { t('financial.payout-schedule.popup-title') }
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              <Typography variant="body1">
                { t('financial.payout-schedule.popup-text') }
              </Typography>
              <RadioGroup row aria-label="type">
                <Box style={{borderBottom: "1px solid #E7F0F0", marginBottom: "0.5rem", marginTop: "1rem", paddingBottom: "0.5625rem"}}>
                  <Box display="flex" flexDirection="column">
                    <FormControlLabel value="true" control={<Radio color="primary" checked={payoutScheduleValue === 'manual'} className={classes.radio} onClick={() => {
                      setPayoutScheduleValue('manual')
                    }} />} label={
                      <Typography variant="body2" style={{paddingLeft: "0.25rem"}}>
                        <strong>{t("financial.payout-schedule.manual-payout")}</strong>
                      </Typography>
                    } />
                    <Box mb={1} ml={3.75}>
                      <Typography variant="body2">
                        {t("financial.payout-schedule.manual-payout-subtext")}
                      </Typography>
                    </Box>
                    <FormControlLabel value="false" control={<Radio color="primary" checked={payoutScheduleValue === 'automatic'} className={classes.radio} onClick={() => {
                      setPayoutScheduleValue('automatic')
                    }} />} label={
                      <Typography variant="body2" style={{paddingLeft: "0.25rem"}}><strong>{t("financial.payout-schedule.automatic-payout")}</strong></Typography>
                    } />
                    <Box mb={1} ml={3.75}>
                      <Typography variant="body2">
                        {t("financial.payout-schedule.automatic-payout-subtext")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </RadioGroup>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Box flex="auto" mr={1}>
              <Button variant="contained" color="primary" onClick={() => {
                updatePayoutSchedule()
                closePayoutDialogue()
              }} fullWidth={true} >
                <Typography variant="button">
                  { t('common.btn.confirm') }
                </Typography>
              </Button>
            </Box>
            <Box flex="auto">
              <Button variant="outlined" color="primary" onClick={closePayoutDialogue} fullWidth={true}>
                <Typography variant="button">
                  { t('common.btn.cancel') }
                </Typography>
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      <Box display="flex">
        <TitleSection
          routes={[{
            name: t("common.texts.dashboard"),
            path: "/dashboard",
          }]}
          title={ t("financial.title") }
        />
        <Box display="flex" justifyContent="flex-end" flex={1}>
          {
            !isMobile &&
          <Box display="flex" flexDirection="column" justifyContent="center">
            {
              (paymentData !== undefined) ?
                (
                <Box display="flex" flexDirection="column" alignItems="flex-end" height="100%">
                  <Box display="flex">
                    <Box position="relative" ml={1.5}>
                      <Button variant="outlined" size="small" className={classes.printButtonAll} type="submit" onClick={handlePrintAll} disabled={csvDisabled || pdfDisabled}>
                        {
                          (!fetchingReceipt || csvDisabled) &&
                          <PrintIcon className={classes.printIcon}/>
                        }
                      </Button>
                      {
                        pdfDisabled &&
                        <CircularProgress size={22} className={classes.printProgressAll} />
                      }
                    </Box>
                    <Box position="relative" ml={1.5} mr={1.5}>
                      <Button variant="outlined" size="small" className={classes.printButtonAll} type="submit" onClick={handlePrintAllCSV} disabled={csvDisabled || pdfDisabled}>
                        {
                          (!fetchingReceipt || pdfDisabled) &&
                          <SheetIcon className={classes.printIcon} />
                        }
                      </Button>
                      {
                        csvDisabled &&
                        <CircularProgress size={22} className={classes.printProgressAll} />
                      }
                    </Box>
                  {/* <Tooltip title={t("financial.btn-disabled")}>
                    <Box display="flex">
                      <Button variant="contained" size="small" disabled>
                        { t('financial.btn-withdraw-balance') }
                      </Button>
                    </Box>
                  </Tooltip> */}
                  {
                    paymentData.balance_status &&
                    (
                      <Button variant="contained" size="small" color="primary" onClick={handleClickOpen}>
                        { t('financial.btn-withdraw-balance') }
                      </Button>
                    )
                  }
                  {/* <Box position="relative" ml={1.5}>
                    <Button variant="outlined" size="small" className={classes.printButtonAll} type="submit" onClick={handlePrintAll} disabled={!!(fetchingFinancialsReceipt.id === "all" && fetchingFinancialsReceipt.status)}>
                      {
                        !(fetchingFinancialsReceipt.id === "all" && fetchingFinancialsReceipt.status) &&
                        <PrintIcon className={classes.printIcon}/>
                      }
                    </Button>
                    {
                      !!(fetchingFinancialsReceipt.id === "all" && fetchingFinancialsReceipt.status) &&
                      <CircularProgress size={22} className={classes.printProgressAll} />
                    }
                  </Box> */}
                  </Box>
                  <Typography variant="p" style={
                    {
                      fontWeight: 500,
                      paddingTop: '1em',
                      cursor: 'pointer',
                      color: '#999',
                      }
                    }
                    onClick={() => {
                      setPayoutDialogueOpen(true)
                      setPayoutScheduleValue(paymentData.payout_schedule)
                      }}
                    >
                     {paymentData.payout_schedule ?
                      paymentData.payout_schedule === 'automatic' ? `${t('financial.payout-schedule.payout-options')} (${t('financial.payout-schedule.monthly')})` : `${t('financial.payout-schedule.payout-options')} (${t('financial.payout-schedule.manual')})`
                      :
                      'Set payout schedule'}
                  </Typography>
                </Box>
                ) : (
                <Box display="none" />
              )
            }
          </Box>
          }
        </Box>
      </Box>
      <Divider />
      <Box display="flex" pt={isMobile ? 3 : 8} flexDirection="column">
        {
          isMobile &&
          <Box display="flex" flexDirection="column" justifyContent="center" pb={5}>
            {
              (paymentData !== undefined) ?
                <Box display="flex">
                  {/* <Tooltip title={t("financial.btn-disabled")}>
                    <Box display="flex" width="100%">
                      <Button variant="contained" size="small" fullWidth={true} disabled>
                        { t('financial.btn-withdraw-balance') }
                      </Button>
                    </Box>
                  </Tooltip> */}
                  {
                  paymentData.balance_status && 
                    (
                      <Button variant="contained" color="primary" fullWidth={true} onClick={handleClickOpen}>
                        { t('financial.btn-withdraw-balance') }
                      </Button>
                    )
                  }
                  <Box position="relative" ml={1.5}>
                    <Button variant="outlined" size="small" className={classes.printButtonAll} type="submit" onClick={handlePrintAll} disabled={!!(fetchingFinancialsReceipt.id === "all" && fetchingFinancialsReceipt.status)}>
                      {
                        !(fetchingFinancialsReceipt.id === "all" && fetchingFinancialsReceipt.status) &&
                        <PrintIcon className={classes.printIcon}/>
                      }
                    </Button>
                    {
                      !!(fetchingFinancialsReceipt.id === "all" && fetchingFinancialsReceipt.status) &&
                      <CircularProgress size={22} className={classes.printProgressAll} />
                    }
                  </Box>
                </Box>
                :
              (
                <Box display="none" />
              )
            }
            <Typography variant="p" style={
                    {
                      fontWeight: 500,
                      fontSize: '1.25em',
                      paddingTop: '1em',
                      cursor: 'pointer',
                      color: '#999',
                      alignSelf: 'center',
                      }
                    }
                    onClick={() => {
                      setPayoutDialogueOpen(true)
                      setPayoutScheduleValue(paymentData.payout_schedule)
                      }}
                    >
                     {paymentData.payout_schedule ?
                      paymentData.payout_schedule === 'automatic' ? `${t('financial.payout-schedule.payout-options')} (${t('financial.payout-schedule.monthly')})` : `${t('financial.payout-schedule.payout-options')} (${t('financial.payout-schedule.manual')})`
                      :
                      'Set payout schedule'}
                  </Typography>
          </Box>
        }
        <Box display="flex" flexDirection={isMobile ? "column" : "row"} width="100%" justifyContent="space-between">
          <Box width={isMobile ? "100%" : "31.5%"} height="100%" mb={isMobile ? 3 : 0}>
            <Card elevation={0} className={classes.financialsCard}>
              <Box>
                <Box mt={1} py={2} px={3} height={isMobile ? "9rem" : "8rem"}>
                  <Box pb={1}>
                    <Typography variant="h3" style={{fontWeight: 500}}>
                      { t('financial.pending-balance') }
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      { t('financial.pending-balance-sub-text') }
                    </Typography>
                  </Box>
                </Box>
                <Box py={1.25} px={1.5} className={classes.financialsCardAmountContainer}>
                  <Typography variant="h3" className={classes.financialsCardAmount}>
                    {amountForCurrency((paymentData.balance_status && paymentData.balance_status.pending_balance) || 0, paymentData.balance_status?.currency || "")}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Box>
          <Box width={isMobile ? "100%" : "31.5%"} height="100%" mb={isMobile ? 3 : 0}>
            <Card elevation={0} className={classes.financialsCard}>
              <Box display="flex" flexDirection="column" height="100%">
                <Box mt={1} py={2} px={3} height={isMobile ? "9rem" : "8rem"}>
                  <Box pb={1}>
                    <Typography variant="h3" style={{fontWeight: 500}}>
                      { t('financial.available-balance') }
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      { t('financial.available-balance-sub-text') }
                    </Typography>
                  </Box>
                </Box>
                <Box justifySelf="flex-end" py={1.25} px={1.5} className={classes.financialsCardAmountContainer}>
                  <Typography variant="h3" className={classes.financialsCardAmount}>
                    {amountForCurrency((paymentData.balance_status && paymentData.balance_status.available_balance) || 0, paymentData.balance_status?.currency || "")}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Box>
          <Box width={isMobile ? "100%" : "31.5%"} height="100%">
            <Card elevation={0} className={classes.financialsCard}>
              <Box>
                <Box mt={1} py={2} px={3} height={isMobile ? "9rem" : "8rem"}>
                  <Box pb={1}>
                    <Typography variant="h3" style={{fontWeight: 500}}>
                      { t('financial.overall-balance-card') }
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      { t('financial.overall-balance-sub-text') }
                    </Typography>
                  </Box>
                </Box>
                <Box py={1.25} px={1.5} className={classes.financialsCardAmountContainer}>
                  <Typography variant="h3" className={classes.financialsCardAmount}>
                    {amountForCurrency((paymentData.balance_status && paymentData.balance_status.pending_balance + paymentData.balance_status.available_balance) || 0, paymentData.balance_status?.currency || "")}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>
      <Box display="flex" pt={8} flexDirection="column">
        <Box display="flex">
          <Typography variant="h3">
            { t('financial.payout-history') }
          </Typography>
        </Box>
        <Box mt={2}>
        { paymentData && paymentData.payout_history && paymentData.payout_history.length > 0 ? (
          <React.Fragment>
          <TableContainer style={{borderBottomRightRadius: "0", borderBottomLeftRadius: "0", borderBottom: "0"}} component={Paper} elevation={0} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="parked cars table" elevation={0}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.withdrawHistoryColumn}><Typography variant="h5" style={{fontWeight: 500}}>{ t('financial.withdraw-history') }</Typography></TableCell>
                  <TableCell align="center" style={{width: "6rem"}}><Typography variant="h5" style={{fontWeight: 500}}>{ t('financial.status') }</Typography></TableCell>
                  <TableCell align="center" style={{width: "8rem"}}><Typography variant="h5" style={{fontWeight: 500}}>{ t('financial.balance') }</Typography></TableCell>
                  <TableCell align="center" style={{width: "4rem"}}><Typography variant="h5" style={{fontWeight: 500}}>{ t('bookings.print') }</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentData.payout_history.map((row, index) => (
                  <TableRow key={`parking-spot-balance-${index}`}>
                    <TableCell className={classes.withdrawHistoryColumn}>
                      <Typography variant="h5">
                        {moment(row.date).format('DD.MM.YYYY')} - #{truncatePayoutNumber(row.payout_number, isSmallMobile, isNormalMobile)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="h5">
                        {row.status || "N/A"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="h5">
                        {row.currency.toUpperCase()} {round(row.amount)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Box position="relative">
                        <Button variant="outlined" size="small" className={classes.printButton} onClick={() => {setFetchingFinancialsReceipt(row.id, true); fetchSingleFinancialsReceipt(token, row.id, row.payout_number)}} disabled={!!(fetchingFinancialsReceipt.id === row.id && fetchingFinancialsReceipt.status)}>
                          {
                            !(fetchingFinancialsReceipt.id === row.id && fetchingFinancialsReceipt.status) &&
                            <PrintIcon className={classes.printIcon}/>
                          }
                        </Button>
                        {
                          !!(fetchingFinancialsReceipt.id === row.id && fetchingFinancialsReceipt.status) &&
                          <CircularProgress size={22} className={classes.printProgress} />
                        }
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer style={{borderTopRightRadius: "0", borderTopLeftRadius: "0"}} component={Paper} elevation={0} className={classes.tableContainer}>
          <Table className={classes.table} elevation={0}>
            <TableBody>
              <TableRow>
                <TableCell align="right">
                  <Pagination pagy={paymentData.pagy} token={token} epic={fetchFinancials} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        </React.Fragment>
        ) : <MissingDataPlaceholder /> }
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{
          paperWidthSm: classes.paperWidthSm,
        }}
      >
        <DialogTitle
          className={classes.dialogTitle}
          classes={{
            root: classes.dialogTitleRoot,
          }}
        >
          <Typography variant="h3">
            { t('financial.withdraw-confirmation') }
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            <Typography variant="body1">
              { t('financial.pop-up.confirmation-msg') }
            </Typography>
            <Typography variant="body1">
              { iban }
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Box flex="auto" mr={1}>
            <Button variant="contained" color="primary" onClick={handleSubmitForWithdrawRequest} fullWidth={true} disabled={withdrawing}>
              <Typography variant="button">
                { t('financial.pop-up.btn-withdraw') }
              </Typography>
            </Button>
            { withdrawing && <CircularProgress size={24} className={classes.buttonProgress} /> }
          </Box>
          <Box flex="auto">
            <Button variant="outlined" color="primary" onClick={handleClose} fullWidth={true}>
              <Typography variant="button">
                { t('common.btn.cancel') }
              </Typography>
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    iban: state.user.hostDetails.iban,
    paymentData: state.payment.paymentData,
    withdrawing: state.payment.withdrawing,
    fetchingFinancialsReceipt: state.payment.fetchingFinancialsReceipt,
    paymentDetails: state.payment.paymentDetails,
  }
};

export default connect(mapStateToProps, { fetchFinancials, postWithdrawBalance, setWithdrawing, addLoader, fetchSingleFinancialsReceipt, fetchAllFinancialsReceipt, setFetchingFinancialsReceipt, postPaymentSchedule, push })(Financials);
