import { Button, Badge } from "@material-ui/core";
import React from "react";
import PriceFilterDialog from "./PriceFilterDialog";
import { useStyles } from "components/dashboard/common/styles";
import { ReactComponent as PriceFilterIcon } from "assets/images/price-icon.svg";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function PriceFilterButton() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const priceFilter = useSelector((state) => state.parkingSpot.priceFilters);
  const priceRangeFilter = useSelector((state) => state.parkingSpot.priceRangeFilter);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {!!priceFilter && (
        <PriceFilterDialog
          filter={priceFilter}
          open={open}
          onClose={handleClose}
        />
      )}
      <Button
        variant="outlined"
        size="small"
        style={{ position: "relative", marginTop: 0 }}
        className={classes.filterButton}
        onClick={handleClickOpen}
        disabled={!priceFilter}
      >
        <Badge badgeContent={!!priceRangeFilter ? 1 : 0} classes={{
          badge: classes.badgeClass,
        }} color="error">
          <PriceFilterIcon className={classes.filterButtonIcon} />
        </Badge>
      </Button>
    </>
  );
}

export default PriceFilterButton;
