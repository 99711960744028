import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  placeholder: {
    width: "100%",
    padding: "4rem",
    background: "#E7F0F0",
    borderRadius: "0.8rem",
    textAlign: "center",
  },
}));

const MissingDataPlaceholder = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.placeholder}>
      <Typography variant="body2">
        { t("common.placeholders.no-data") }
      </Typography>
    </Box>
  );
};

export default MissingDataPlaceholder;
