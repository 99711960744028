import * as Types from './constants';

export const postParkingSpot = (payload, token, activeTab, finish) => {
  return {
    type: Types.POST_PARKING_SPOT,
    payload,
    token,
    activeTab,
    finish,
  };
};

export const patchParkingSpot = (id, payload, token, activeTab, finish) => {
  return {
    type: Types.PATCH_PARKING_SPOT,
    id,
    payload,
    token,
    activeTab,
    finish,
  };
};

export const fetchParkingSpot = (id, token) => {
  return {
    type: Types.FETCH_PARKING_SPOT,
    id,
    token,
  };
};

export const fetchParkingSpotCars = (id, token, subHost) => {
  return {
    type: Types.FETCH_PARKING_SPOT_CARS,
    id,
    token,
    subHost
  };
};

export const fetchParkingSpots = (token, userType) => {
  return {
    type: Types.FETCH_PARKING_SPOTS,
    token,
    userType
  };
};

export const fetchParkingSpotsForGuests = (token) => {
  return {
    type: Types.FETCH_PARKING_SPOTS_FOR_GUESTS,
    token,
  };
};

export const deleteGalleryImage = (id, photoId, token) => {
  return {
    type: Types.DELETE_GALLERY_IMAGE,
    token,
    id,
    photoId,
  };
};

export const fetchParkingSpotAttributes = () => {
  return {
    type: Types.FETCH_PARKING_SPOT_ATTRIBUTES
  };
};

export const setParkingSpot = (payload) => {
  return {
    type: Types.SET_PARKING_SPOT,
    payload,
  };
};

export const setParkingSpots = (payload) => {
  return {
    type: Types.SET_PARKING_SPOTS,
    payload,
  };
};

export const initParkingSpotState = (payload) => {
  return {
    type: Types.INIT_PARKING_SPOT_STATE,
    payload,
  };
};

export const setParkingSpotAttributes = (payload) => {
  return {
    type: Types.SET_PARKING_SPOT_ATTRIBUTES,
    payload,
  };
};

export const setParkingSpotCars = (id, payload) => {
  return {
    type: Types.SET_PARKING_SPOT_CARS,
    id,
    payload,
  };
};

export const setCheckingIn = (value) => {
  return {
    type: Types.SET_CHECKING_IN,
    value,
  };
};

export const setCheckInDetailsDialogOpen = (value) => {
  return {
    type: Types.SET_CHECKING_IN_DETAILS_DIALOG_OPEN,
    value,
  };
};

export const setCheckingOut = (value) => {
  return {
    type: Types.SET_CHECKING_OUT,
    value,
  };
};

export const setFetchingParkingSpotAttributes = (value) => {
  return {
    type: Types.SET_FETCHING_PARKING_SPOT_ATTRIBUTES,
    value,
  };
};

export const setCreatingParkingSpot = (status) => {
  return {
    type: Types.SET_CREATING_PARKING_SPOT,
    status,
  };
};

export const setUpdatingParkingSpot = (status) => {
  return {
    type: Types.SET_UPDATING_PARKING_SPOT,
    status,
  };
};

export const postGalleryImage = (id, photo, token) => {
  return {
    type: Types.POST_GALLERY_IMAGE,
    id,
    photo,
    token,
  };
};

export const addGalleryImage = (payload) => {
  return {
    type: Types.ADD_GALLERY_IMAGE,
    payload,
  };
};

export const removeGalleryImage = (id) => {
  return {
    type: Types.REMOVE_GALLERY_IMAGE,
    id,
  };
};

export const incrementGalleryImageLoadingCounter = () => {
  return {
    type: Types.INCREMENT_GALLERY_IMAGE_LOADING_COUNTER,
  };
};

export const decrementGalleryImageLoadingCounter = () => {
  return {
    type: Types.DECREMENT_GALLERY_IMAGE_LOADING_COUNTER,
  };
};

export const fetchReviewsByIdForGuests = (id, tag) => {
  return {
    type: Types.FETCH_REVIEWS_BY_ID_FOR_GUESTS,
    id,
    tag,
  };
};

export const fetchPhotosByIdForGuests = (id, tag) => {
  return {
    type: Types.FETCH_PHOTOS_BY_ID_FOR_GUESTS,
    id,
    tag,
  };
};

export const fetchReviewsByIdForGuestsNextPage = (url, id) => {
  return {
    type: Types.FETCH_REVIEWS_BY_ID_FOR_GUESTS_NEXT_PAGE,
    url,
    id
  };
};

export const setReviewsById = (id, payload) => {
  return {
    type: Types.SET_REVIEWS_BY_ID,
    id,
    payload,
  };
};
export const setPhotosById = (id, payload) => {
  return {
    type: Types.SET_PHOTOS_BY_ID,
    id,
    payload,
  };
};

export const resetReviews = () => {
  return {
    type: Types.RESET_REVIEWS,
  };
};

export const setReviewsByIdPush = (id, payload) => {
  return {
    type: Types.SET_REVIEWS_BY_ID_PUSH,
    id,
    payload,
  };
};

export const setMoreReviewsById = (payload, id = "-1") => {
  return {
    type: Types.SET_MORE_REVIEWS_BY_ID,
    id,
    payload,
  };
};

export const fetchCurrentCheckIns = (token) => {
  return {
    type: Types.FETCH_CURRENT_CHECK_INS,
    token,
  };
};

export const setCurrentCheckIns = (payload) => {
  return {
    type: Types.SET_CURRENT_CHECK_INS,
    payload,
  };
};

export const setIsSearching = (value) => {
  return {
    type: Types.SET_IS_SEARCHING,
    value,
  };
};

export const fetchPastCheckIns = (token) => {
  return {
    type: Types.FETCH_PAST_CHECK_INS,
    token,
  };
};

export const setPastCheckIns = (payload) => {
  return {
    type: Types.SET_PAST_CHECK_INS,
    payload,
  };
};

export const fetchCarsForGuests = (token) => {
  return {
    type: Types.FETCH_CARS_FOR_GUESTS,
    token,
  };
};

export const setCarsForGuests = (payload) => {
  return {
    type: Types.SET_CARS_FOR_GUESTS,
    payload,
  };
};

export const enableParkingSpot = (id, token) => {
  return {
    type: Types.ENABLE_PARKING_SPOT,
    id,
    token,
  };
};

export const disableParkingSpot = (id, token) => {
  return {
    type: Types.DISABLE_PARKING_SPOT,
    id,
    token,
  };
};

export const postCheckIn = (booking, token) => {
  return {
    type: Types.POST_CHECK_IN,
    booking,
    token,
  };
};

export const setIsCheckedIn = (parking_spot_id, checkin) => {
  return {
    type: Types.SET_IS_CHECKED_IN,
    parking_spot_id,
    checkin,
  };
}

export const postCheckOut = (id, time, token, parkingSpotTag) => {
  return {
    type: Types.POST_CHECK_OUT,
    id,
    time,
    token,
    parkingSpotTag,
  };
};

export const postReview = (review, token) => {
  return {
    type: Types.POST_REVIEW,
    review,
    token,
  };
};

export const fetchParkingSpotDetailsGuests = (token, id) => {
  return {
    type: Types.FETCH_PARKING_SPOT_DETAILS_FOR_GUESTS,
    token,
    id
  };
};

export const fetchParkingSpotDetails = (tag, token) => {
  return {
    type: Types.FETCH_PARKING_SPOT_DETAILS_FOR_USERS,
    tag,
    token
  };
};

export const setCurrentBookingId = (currentBookingId) => {
  return {
    type: Types.SET_CURRENT_BOOKING_ID,
    currentBookingId,
  };
}


export const setCheckOutDialogOpen = (value) => {
  return {
    type: Types.SET_CHECK_OUT_DIALOG_OPEN,
    value,
  };
}


export const setCheckInDialogOpen = (value) => {
  return {
    type: Types.SET_CHECK_IN_DIALOG_OPEN,
    value,
  };
}


export const setReviewDialogOpen = (value) => {
  return {
    type: Types.SET_REVIEW_DIALOG_OPEN,
    value,
  };
}


export const setReviewSubmissionDialogOpen = (value) => {
  return {
    type: Types.SET_REVIEW_SUBMISSION_DIALOG_OPEN,
    value,
  };
}

export const setBookingReviewSubmissionDialogOpen = (id, value) => {
  return {
    type: Types.SET_BOOKING_REVIEW_SUBMISSION_DIALOG_OPEN,
    id,
    value,
  };
}

export const setParkingSpotDetails = (payload, setToEmpty = false) => {
  return {
    type: Types.SET_PARKING_SPOT_DETAILS,
    payload,
    setToEmpty
  };
}

export const searchParkingSpots = (options, location, price, url, redirectToSearch = false) => {
  return {
    type: Types.SEARCH_PARKING_SPOTS,
    options,
    price,
    location,
    url,
    redirectToSearch,
  };
}
export const fetchPriceFilters = (options, location) => ({
  options,
  location,
  type: Types.FETCH_PRICE_FILTERS,
})
export const setPrice = (payload) => ({
  type: Types.SET_PRICE,
  payload,
})
export const fetchPriceFiltersFulfilled = (data, error) => ({
  type: Types.FETCH_PRICE_FILTERS_FULFILLED,
  payload: {
    data,
    error,
  }
})
export const setPriceRangeFilter = (payload) => ({
  type: Types.SET_PRICE_RANGE_FILTER,
  payload,
})
export const setCategoryOptionFilters = (options) => {
  return {
    type: Types.SET_CATEGORY_OPTION_FILTERS,
    options,
  };
}
export const setLocationFilters = (location) => {
  return {
    type: Types.SET_LOCATION_FILTERS,
    location,
  };
}
export const setFilteredParkingSpots = (parkingSpots) => {
  return {
    type: Types.SET_FILTERED_PARKING_SPOTS,
    parkingSpots,
  };
}
export const setTooltipCardMobile = (spot, isCheckedIn, color) => {
  return {
    type: Types.SET_TOOLTIP_CARD_MOBILE,
    spot,
    isCheckedIn,
    color
  };
}
export const setGeolocationEditParkingSpot = (geolocation) => {
  return {
    type: Types.SET_GEOLOCATION_EDIT_PARKING_SPOT,
    geolocation,
  };
}
export const setAddressEditParkingSpot = (address) => {
  return {
    type: Types.SET_ADDRESS_EDIT_PARKING_SPOT,
    address,
  };
}
export const fetchBookingsHost = (token, requestUrl, subHost) => {
  return {
    type: Types.FETCH_BOOKINGS_HOST,
    token,
    requestUrl,
    subHost
  };
}
export const fetchFilteredBookingsHost = (token, requestUrl, query, subHost) => {
  return {
    type: Types.FETCH_FILTERED_BOOKINGS_HOST,
    token,
    query,
    requestUrl,
    subHost
  };
}
export const fetchSingleBookingReceipt = (token, id, name, subHost) => {
  return {
    type: Types.FETCH_SINGLE_BOOKING_RECEIPT,
    token,
    id,
    name,
    subHost
  };
}
export const fetchMultipleBookingsReceipt = (token, query, subHost, printCSV=false) => {
  return {
    type: Types.FETCH_MULTIPLE_BOOKINGS_RECEIPT,
    token,
    query,
    subHost,
    printCSV
  };
}
export const setBookingsHost = (payload) => {
  return {
    type: Types.SET_BOOKINGS_HOST,
    payload,
  };
}
export const setFetchingBookingsReceipt = (id, payload, printCSV=false) => {
  return {
    type: Types.SET_FETCHING_BOOKINGS_RECEIPT,
    id,
    payload,
    printCSV,
  };
}
export const addCarouselState = (key, value) => {
  return {
    type: Types.ADD_CAROUSEL_STATE,
    key,
    value,
  };
}
export const setCarouselState = (key, value, clear) => {
  return {
    type: Types.SET_CAROUSEL_STATE,
    key,
    value,
    clear
  };
}
export const setSearchStringGlobal = (payload) => {
  return {
    type: Types.SET_SEARCH_STRING,
    payload,
  };
}
export const setMobileBottomPanel = (value) => {
  return {
    type: Types.SET_MOBILE_BOTTOM_PANEL,
    value,
  };
}
export const setEnableReview = (value) => {
  return {
    type: Types.SET_ENABLE_REVIEW,
    value,
  };
}
export const pushToRoot = () => {
  return {
    type: Types.PUSH_TO_ROOT
  };
}
export const setShowMap = (value) => {
  return {
    type: Types.SET_SHOW_MAP,
    value
  };
}
export const setFocusedSpot = (value) => {
  return {
    type: Types.SET_FOCUSED_SPOT,
    value
  };
}
export const fetchParkingSpotDetailsSearch = (tag) => {
  return {
    type: Types.FETCH_PARKING_SPOT_DETAILS_SEARCH,
    tag
  };
};
export const setParkingSpotDetailsSearch = (tag, payload) => {
  return {
    type: Types.SET_PARKING_SPOT_DETAILS_SEARCH,
    tag,
    payload
  };
};
export const initializeParkingSpotDetailsSearch = (payload) => {
  return {
    type: Types.INITIALIZE_PARKING_SPOT_DETAILS_SEARCH,
    payload
  };
};
export const pruneParkingSpotDetailsSearch = () => {
  return {
    type: Types.PRUNE_PARKING_SPOT_DETAILS_SEARCH
  };
};
export const setSearchTimestamp = () => {
  return {
    type: Types.SET_SEARCH_TIMESTAMP
  };
};
export const fetchReviewForBooking = (token, id) => {
  return {
    type: Types.FETCH_REVIEW_FOR_BOOKING,
    token,
    id
  };
}
export const setReviewForBooking = (payload) => {
  return {
    type: Types.SET_REVIEW_FOR_BOOKING,
    payload
  };
}
export const setSearchMapBounds = (value) => {
  return {
    type: Types.SET_SEARCH_MAP_BOUNDS,
    value
  };
}
export const cancelSearch = () => {
  return {
    type: Types.CANCEL_SEARCH,
  };
}
export const setExternalSearchLocation = (value) => {
  return {
    type: Types.SET_EXTERNAL_SEARCH_LOCATION,
    value
  };
}
export const fetchUserLocation = () => {
  return {
    type: Types.FETCH_USER_LOCATION
  };
}
export const setUserLocation = (value) => {
  return {
    type: Types.SET_USER_LOCATION,
    value
  };
}
export const setSearchResultsLoader = (value) => {
  return {
    type: Types.SET_SEARCH_RESULTS_LOADER,
    value
  };
}
export const decrementSearchResultsLoader = () => {
  return {
    type: Types.DECREMENT_SEARCH_RESULTS_LOADER
  };
}
export const setCenterMarkerCoords = (value) => {
  return {
    type: Types.SET_CENTER_MARKER_COORDS,
    value,
  };
}
export const setSearchMapCenterAndZoom = (value) => {
  return {
    type: Types.SET_SEARCH_MAP_CENTER_AND_ZOOM,
    value,
  };
}
export const postParkedCarCheck = (token, id, value, parkingSpotId, subHost) => {
  return {
    type: Types.POST_PARKED_CAR_CHECK,
    token,
    id,
    value,
    parkingSpotId,
    subHost
  };
}
export const setParkedCarCheckDiabled = (id, value) => {
  return {
    type: Types.SET_PARKED_CAR_CHECK_DISABLED,
    id,
    value
  };
}
export const setContinuing = (value) => {
  return {
    type: Types.SET_CONTINUING,
    value
  };
}
export const setFinishing = (value) => {
  return {
    type: Types.SET_FINISHING,
    value
  };
}