import React from "react";
import { connect } from "react-redux";
import { ReactComponent as DropdownArrow } from "assets/images/dropdown-arrow.svg";
import {
  ListItemIcon,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import flags from "../flags";
import InputLabel from "../inputLabel/InputLabel";
import CheckIcon from "components/common/icons/CheckIcon";
import clsx from "clsx";

function getLanguageNameByCode(code) {
  switch (code) {
    case "en":
      return "English";
    case "de":
      return "German";
    case "fr":
      return "French";
    case "it":
      return "Italian";
    case "pl":
      return "Polish";
    case "es":
      return "Spanish";
    case "pt":
      return "Portuguese";
    case "nl":
      return "Dutch";
    case "sv":
      return "Swedish";
    case "cs":
      return "Czech";
    default:
      return null;
  }
}

const useStyles = makeStyles((theme) => ({
  select: {
    "& .MuiSelect-root": {
      display: "flex",
      alignItems: "center",
      "& .MuiListItemIcon-root:last-child": {
        display: "none",
      },
    },
  },
  icon: {
    minWidth: "auto",
    marginRight: theme.spacing(1),
  },
  endIcon: {
    marginLeft: "auto",
    minWidth: "auto",
    marginRight: 0,
  },
}));

function LanguageSelect({
  languageCodes,
  label,
  required,
  selectProps = {},
  name,
  onChange,
  onBlur,
  value,
  ...props
}) {
  const classes = useStyles();

  const languages = React.useMemo(() => {
    return languageCodes.map(({ code }) => {
      return {
        code,
        name: getLanguageNameByCode(code),
        flag: flags(code),
      };
    });
  }, [languageCodes]);

  return (
    <div>
      { label &&
        <InputLabel bottomGutter required={required} id="LanguageSelect">
          {label}
        </InputLabel>
      }
      <Select
        IconComponent={(props) => <DropdownArrow {...props} />}
        variant="outlined"
        fullWidth
        labelId="LanguageSelect"
        {...selectProps}
        className={clsx(classes.select, selectProps.className)}
        inputProps={{
          ...(selectProps.inputProps || {}),
          name,
          onChange,
          onBlur,
          value,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              // ! .MuiPopover-paper have margin-top property
              marginTop: 0,
            },
          },
        }}
      >
        {languages.map((language) => (
          <MenuItem key={language.code} value={language.code}>
            <ListItemIcon className={classes.icon}>
              {language.flag}
            </ListItemIcon>
            <Typography component="span">{language.name}</Typography>
            {language.code === value && (
              <ListItemIcon className={classes.endIcon}>
                <CheckIcon color="primary" fontSize="small" />
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    languageCodes: state.config.emailLanguages,
    // activeLanguageCode: state.config.activeLanguage,
  };
};

export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(LanguageSelect);
