import * as Icons from '../assets/images/SVG';

export const getSVGForIcon = (icon) => {
  switch (icon) {
    case 'service_wifi.svg':
      return Icons.ServiceWifi;
    case 'place_carsize_big.svg':
      return Icons.PlaceCarsizeBig;
    case 'place_carsize_large.svg':
      return Icons.PlaceCarsizeLarge;
    case 'service_shower.svg':
      return Icons.ServiceShower;
    case 'award_pioneer.svg':
      return Icons.AwardPioneer;
    case 'place_carsize_caravan.svg':
      return Icons.PlaceCarsizeCaravan;
    case 'service_toilets.svg':
      return Icons.ServiceToilets;
    case 'host_autarchy.svg':
      return Icons.HostAutarchy;
    case 'host_autarchy_not.svg':
      return Icons.HostAutarchyNot;
    case 'place_carsize_offroad.svg':
      return Icons.PlaceCarsizeOffroad;
    case 'service_wastedisposal.svg':
      return Icons.ServiceWasteDisposal;
    case 'host_behaviour_camping.svg':
      return Icons.HostBehaviourCamping;
    case 'place_carsize_small.svg':
      return Icons.PlaceCarsizeSmall;
    case 'host_behaviour_foldingchair.svg':
      return Icons.HostBehaviourFoldingchair;
    case 'place_season_snowflake.svg':
      return Icons.PlaceSeasonSnowflake;
    case 'surrounding_bicyle.svg':
      return Icons.SurroundingBicycle;
    case 'host_behaviour_parking.svg':
      return Icons.HostBehaviourParking;
    case 'place_terrain_asphalt.svg':
      return Icons.PlaceTerrainAsphalt;
    case 'surrounding_dogfriendly.svg':
      return Icons.SurroundingDogfriendly;
    case 'host_type_cablecar.svg':
      return Icons.HostTypeCablecar;
    case 'place_terrain_gras.svg':
      return Icons.PlaceTerrainGras;
    case 'surrounding_hikingarea.svg':
      return Icons.SurroundingHikingarea;
    case 'host_type_campsite.svg':
      return Icons.HostTypeCampsite;
    case 'place_terrain_gravel.svg':
      return Icons.PlaceTerrainGravel;
    case 'surrounding_nearmountains.svg':
      return Icons.SurroundingNearmountains;
    case 'host_type_farm.svg':
      return Icons.HostTypeFarm;
    case 'service_blackwater.svg':
      return Icons.ServiceBlackwater;
    case 'surrounding_nearshopping.svg':
      return Icons.SurroundingNearshopping;
    case 'host_type_naturespot.svg':
      return Icons.HostTypeNaturespot;
    case 'service_camperdinner.svg':
      return Icons.ServiceCamperdinner;
    case 'surrounding_nearwaters.svg':
      return Icons.SurroundingNearwaters;
    case 'host_type_privatehouse.svg':
      return Icons.HostTypePrivatehouse;
    case 'service_electric.svg':
      return Icons.ServiceElectric;
    case 'surrounding_playground.svg':
      return Icons.SurroundingPlayground;
    case 'host_type_restaurant.svg':
      return Icons.HostTypeRestaurant;
    case 'service_farmshop.svg':
      return Icons.ServiceFarmshop;
    case 'surrounding_protected_area.svg':
      return Icons.SurroundingProtectedArea;
    case 'surrounding_protected area.svg':
      return Icons.SurroundingProtectedArea;
    case 'host_type_rvpark.svg':
      return Icons.HostTypeRvpark;
    case 'service_fireplace.svg':
      return Icons.ServiceFireplace;
    case 'surrounding_restaurant.svg':
      return Icons.SurroundingRestaurant;
    case 'place_approach_4wd.svg':
      return Icons.PlaceApproach4wd;
    case 'service_freshwater.svg':
      return Icons.ServiceFreshwater;
    case 'surrounding_skiarea.svg':
      return Icons.SurroundingSkiarea;
    case 'place_approach_narrow.svg':
      return Icons.PlaceApproachNarrow;
    case 'service_greywater.svg':
      return Icons.ServiceGreywater;
    case 'service_laundry.svg':
      return Icons.ServiceLaundry;
    case 'host_type_marina.svg':
      return Icons.HostTypeMarina
    default:
      return;
  };
};
