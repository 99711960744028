import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Tabs, Tab, Box, CircularProgress } from '@material-ui/core';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import { useStripe, useElements, Elements, CardNumberElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { addLoaderForItem } from '../../../data/actions/config';
import { updateHost, updateGuest, postProfilePic, setUpdatingProfilePic, fetchGuestProfile, fetchHostProfile, setUpdatingUser } from '../../../data/actions/user';
import { fetchGuestPaymentDetails, fetchPaymentSecret } from '../../../data/actions/payment';
import { useStyles } from '../common/styles';
import { useTranslation } from 'react-i18next';
import PaymentFields from '../../common/paymentFields';

import poweredByStripe from '../../../assets/images/powered-by-stripe.svg';

import LabelledFormField from '../../common/formFieldWithSeparateLabel';
import { enqueueSnackbar } from '../../../data/actions/global';

import { STRIPE_PUBLISHABLE_KEY } from '../../../publicKeys';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CompletePaymentDetails = ({
  user,
  userType,
  token,
  fetchGuestProfile,
  fetchGuestPaymentDetails,
  guestPaymentDetails,
  updateGuest,
  updatingUser,
  setUpdatingUser,
  enqueueSnackbar,
  paymentSecret,
  fetchPaymentSecret,
  addLoaderForItem,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [change, setChange] = useState(false);
  const {hostDetails } = user;
  const attached =
    userType === "Guest" &&
    user.guestDetails &&
    Boolean(user.guestDetails.stripe_payment_method_id);
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultValues = (values) => {
    return userType==="Host" ? {
      iban: hostDetails.iban || "",
    }
    :
    {
      cardName: "",
    };
  };
  const { handleSubmit, control, reset } = useForm({ defaultValues: defaultValues() });

  useEffect(() => {
    if (userType === "Guest") {
      fetchGuestProfile(token);
    }
  }, [userType, fetchGuestProfile, token]);

  useEffect(() => {
    if (user.userType === "Guest") {
      addLoaderForItem("stripe-secret");
      fetchPaymentSecret(token);
    }
  }, [user.userType, fetchPaymentSecret, addLoaderForItem, token]);

  const { t } = useTranslation();

  useEffect(() => {
    reset({ ...(defaultValues()) });
  }, [reset]);

  let query = useQuery();
  const tag = query.get("tag")

  const onSubmit = async (data) => {
    if (!stripe || !elements) {
      return;
    }

    let pathString = null;
    if (tag) {
      pathString = `/ps/${tag}`
    }
    if (userType === "Guest") {
      if (!paymentSecret) {
        enqueueSnackbar({
          message: {
            text: t("payment.add-method-error"),
            variant: "error",
          },
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
        return
      }

      setUpdatingUser(true);

      const card = elements.getElement(CardNumberElement);

      if (!card && !data.cardName && attached) {
        history.push(pathString || query.get("redirect") || "/dashboard");
        return;
      }

      const result = await stripe.confirmCardSetup(
        paymentSecret,
        {
          payment_method: {
            card: card,
            billing_details: { name: data.cardName }
          }
        }
      )
      if (result?.error) {
        enqueueSnackbar({
          message: {
            text: t("payment.add-method-error"),
            variant: "error",
          },
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
        setUpdatingUser(false);
      } else {
        enqueueSnackbar({
          message: {
            text: t("payment.add-method-success"),
            variant: "success",
          },
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
        updateGuest({
          guestDetailId: user.guestDetails.id,
          stripePaymentMethodId: result?.setupIntent?.payment_method?.id
        }, token, null, pathString || query.get("redirect") || "/dashboard")
      }
    }
  };

  const [activeTab, setActiveTab] = useState("Payment");

  return (
    <Card className={classes.onboardingCard}>
      <Box display="flex" alignItems="center" className={classes.completeProfileTitle}>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
          textColor="primary"
          indicatorColor="primary"
          className={classes.completeProfileTabs}
        >
          <Tab value="Credentials" label={t("on-boarding.credentials")} component={Link} to="/completeProfile" />
          <Tab value="Payment" label={t("on-boarding.payment")} />
        </Tabs>
      </Box>
      <CardContent className={classes.completeProfileContent}>
        { userType === "Guest" &&
          <form onSubmit={handleSubmit(onSubmit)} className={classes.root} autoComplete="off">
            <Box display="flex" flexDirection="column" mb={3} pt={1.5}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h4">
                  { t('payment.card-details') }
                </Typography>
                <img src={poweredByStripe} alt="Powered by Stripe" className={classes.titleLogo} style={{
                  width: "6.5rem"
                }}/>
              </Box>
              <Box pt={2}>
                <Typography variant="body1">
                  { t('payment.complete-payment-details.sub-text') }
                </Typography>
              </Box>
            </Box>
            {(!attached || change) && <LabelledFormField label={ t("payment.fields.card-name") } name="cardName" control={control} required />}
            <PaymentFields
              onboarding={true}
              attached={attached}
              last4={guestPaymentDetails.last4}
              exp_month={guestPaymentDetails.exp_month}
              exp_year={guestPaymentDetails.exp_year}
              change={change}
              setChange={setChange}
            />
            <Box pt={3} display="flex" className={classes.actionBox} flexDirection={isMobile ? "column" : "row"} >
              <Box flex="auto" alignSelf="center">
                <Typography variant="subtitle2" className={classes.userAgreement}>
                  { t('on-boarding.user-agreement') } <a className={classes.userAgreementLink} href="https://parknsleep.zendesk.com/hc/de/articles/360019728597-Nutzungsbedingungen">{ t('on-boarding.terms-and-conditions') }</a> { t('on-boarding.and') } <Link className={classes.userAgreementLink} to={"/cookies"}>{ t('on-boarding.privacy-statement') }</Link>
                </Typography>
              </Box>
              <Box width="10rem" ml={isMobile ? 0 : 1.5} mt={isMobile ? 1 : 0}>
                <Button variant="outlined" component={Link} to={tag ? `/ps/${tag}` : "/dashboard"} fullWidth={true} className={classes.primaryButton}>
                  { t('common.btn.skip') }
                </Button>
              </Box>
            </Box>
            <Box pt={3} className={classes.progressButtonWrapper}>
              <Button 
                variant="contained" 
                color="primary" 
                type="submit" 
                fullWidth={true} 
                disabled={(userType === "Guest" && user.guestDetails && !user.guestDetails.id) || updatingUser} 
                className={classes.primaryButton}
              >
                { t('payment.complete-payment-details.finish') }
              </Button>
              { updatingUser && <CircularProgress size={24} className={classes.buttonProgress} style={{marginTop: "0rem"}} /> }
            </Box>
          </form>
        }
      </CardContent>
    </Card>
  )
};

const StripeWrapper = (props) => {
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

  const options = {
    fonts: [
      {
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap"
      }
    ],
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CompletePaymentDetails {...props} />
    </Elements>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userType: state.user.userType,
    token: state.auth.token,
    guestPaymentDetails: state.payment.guestPaymentDetails,
    updatingProfilePic: state.user.updatingProfilePic,
    updatingUser: state.user.updatingUser,
    paymentSecret: state.payment.paymentSecret,
  };
};

export default connect(mapStateToProps, {
  updateHost,
  updateGuest,
  postProfilePic,
  setUpdatingProfilePic,
  fetchGuestProfile,
  fetchGuestPaymentDetails,
  fetchHostProfile,
  setUpdatingUser,
  enqueueSnackbar,
  fetchPaymentSecret,
  addLoaderForItem,
})(StripeWrapper);
