import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Divider, Box, CircularProgress, Tabs, Tab } from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import OutlinedFormField from '../../common/outlinedFormField';
import { signUp, setPasswordResetRequestSent } from '../../../data/actions/auth';
import { useStyles } from '../common/styles';
import { useTranslation } from 'react-i18next';
import { setRegisteringUser, setIsNewProfile } from '../../../data/actions/user';
import { push } from 'connected-react-router';
import { generateCacheBusterKey } from '../../../lib/utils';
import LanguageSelector from '../../common/languageSelector';
import Cookies from 'js-cookie';

const defaultValues = {
  name: "",
  surname: "",
  email: "",
  password: "",
  userType: "",
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SignUp = ({ signUp, setRegisteringUser, registeringUser, setPasswordResetRequestSent, setIsNewProfile, mode, push, activeLanguage, ...rest }) => {
  const classes = useStyles();
  const query = useQuery();
  const fullName = query.get('full-name') || query.get('full_name') || ''
  const firstName = query.get('first-name') || query.get('first_name') || ''
  const lastName = query.get('last-name') || query.get('last_name') || ''
  const { handleSubmit, control, watch, getValues } = useForm({
    defaultValues: {
      ...defaultValues,
      name: firstName || '',
      surname: lastName || '',
      email: query.get('email') || '',
      password: query.get('password') || '',
    }
  });
  const { t } = useTranslation();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeTab, setActiveTab] = useState(null)
  const [cacheBuster, setCacheBuster] = useState(null);
  const watchAllFields = watch();

  React.useEffect(() => {
    setPasswordResetRequestSent(null)
  }, [])

  React.useEffect(() => {
    if (activeTab === "Host" && !!query.get('first_name') && !!query.get('email') && !!query.get('password') && watchAllFields.name && watchAllFields.email && watchAllFields.password) {
      onSubmit({})
    }
  }, [activeTab, watchAllFields.name, watchAllFields.surname, watchAllFields.email, watchAllFields.password]);

  React.useEffect(() => {
    setActiveTab(rest.location.pathname === "/signup-camper" ? "Camper" : "Host")
  }, [])

  const onSubmit = (payload) => {
    let data = {
      ...payload,
      ...(getValues())
    };
    data["userType"] = activeTab === "Host" ? "Host" : "Guest";
     if (query.get('lang')) {
      data["locale"] = query.get('lang').toLowerCase()
    } else {
      data["locale"] =  activeLanguage;
    }

    const psID = query.get('id');
    const referral_code = query.get('referral_code');
    const utmSource = Cookies.get('utm_source')

    if (mode === "v2" && activeTab === "Host") {
      const phone = query.get('phone');
      const companyStreet = query.get('street');
      const companyStreetNumber = query.get('street_nr');
      const companyZip = query.get('zip');
      const companyCity = query.get('city');
      const companyCountry = query.get('country');
      const psName = query.get('ps_name');
      const price = query.get('price');
      const capacity = query.get('capacity');
      const behaviour = query.get('behaviour');
      const hostType = query.get('host_type');

      data = {
        ...data,
        phone,
        companyStreet,
        companyStreetNumber,
        companyZip,
        companyCity,
        companyCountry,
        psName,
        price,
        capacity,
        behaviour,
        hostType,
        utmSource,
      }
    }

    if (!!psID) {
      data["id"] = psID;
    }

    if (!!referral_code) {
      data["referralCode"] = referral_code;
    }

    if (!!utmSource) {
      data["utmSource"] = utmSource;
    }

    setRegisteringUser(true);
    setIsNewProfile(true);
    const searchParts = (rest.location.search.split("="))

    if (
      (searchParts && searchParts[0] && searchParts[0] === "?tag" && searchParts[1] && searchParts[1].length > 0) ||
      !!query.get('id') ||
      !!query.get('redirect') ||
      !!query.get('product_tour_id')
    ) {
      signUp(data, rest.location.search, mode);
    }
    else {
      signUp(data, null, mode);
    }
  };

  useEffect(() => {
    if (!cacheBuster) {
      setCacheBuster(generateCacheBusterKey());
    }
  }, []);

  return (
    <Card className={classes.onboardingCard}>
      {
        activeTab === "Host" && !!query.get('id') && cacheBuster &&
        <Box key={cacheBuster} display="none">
          <img src={`https://www.google-analytics.com/collect?v=1&tid=UA-198820811-1&cid=555&aip=1&t=event&ec=2_signup-host&ea=2_signup-host&el=${query.get('id')}&;dp=&;dt=&;z=${cacheBuster}`} />
        </Box>
      }
      <Box display="flex" flexDirection="column" className={classes.onboardingTitle} style={{borderBottom: 'none'}}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" color="textPrimary">
            { t('account.title') }
          </Typography>
          <LanguageSelector extended={true} />
        </Box>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => {
            setActiveTab(newValue)
            push(newValue === "Camper" ? `/signup-camper${rest.location.search}` : `/signup-host${mode === "v2" ? "-v2" : ""}${rest.location.search}`)
          }}
          textColor="primary"
          indicatorColor="primary"
          className={classes.signupTabs}
        >
          <Tab value="Camper" label={t("common.texts.camper")} />
          <Tab value="Host" label={t("common.texts.host")} />
        </Tabs>
      </Box>
      <CardContent className={classes.onboardingContent} style={{paddingTop: "0.625rem"}}>
        <Box>
          <Typography variant="body2">
          { activeTab === 'Camper' && t('on-boarding.guest.signUp.subtext') }
          { activeTab === 'Host' && t('on-boarding.host.signUp.subtext') }
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.root} autoComplete="off">
          <OutlinedFormField type="text" label={ t('common.labels.name') } name="name" control={control} required />
          <OutlinedFormField type="text" label={ t('common.labels.surname') } name="surname" control={control} required />
          <OutlinedFormField label={ t('common.labels.email') } name="email" type="email" control={control} required />
          <OutlinedFormField label={ t('common.labels.password') } name="password" type="password" control={control} required />
          <Box display="flex" flexDirection="row-reverse" width="100%" pt={0.5}>
            <Typography variant="subtitle2" color="secondary">{ t('common.labels.password-length') }: 10</Typography>
          </Box>
          <Box width="100%" pt={1.75}>
            <Typography variant="subtitle2" className={classes.userAgreement}>
              { t('on-boarding.user-agreement') } <a target="_blank" className={classes.userAgreementLink} href="https://intercom.help/parknsleep/de/articles/5807662-nutzungsbedingungen">{ t('on-boarding.terms-and-conditions') }</a> { t('on-boarding.and') } <a target="_blank" className={classes.userAgreementLink} href="https://intercom.help/parknsleep/de/articles/5807660-datenschutzerklarung">{ t('on-boarding.privacy-statement') }</a> { t('on-boarding.user-agreement-end') }
            </Typography>
          </Box>
          <Box pt={2.25} width="100%" className={classes.progressButtonWrapper}>
            <Button variant="contained" color="primary" type="submit" disabled={!activeTab || registeringUser} fullWidth>
              { activeTab === "Camper" ? t('common.btn.continue-camper') : t('common.btn.continue-host') }
            </Button>
            { registeringUser && <CircularProgress size={24} className={classes.buttonProgress} style={{marginTop: "0rem"}} /> }
          </Box>
          <Box width="100%" py={2.375} display="flex" justifyContent="center" alignItems="center" overflow="hidden">
            <Divider className={classes.halfDivider} />
            <Typography variant="subtitle2" color="secondary" className={classes.noWrapText}>{ t('on-boarding.already-have-account') }</Typography>
            <Divider className={classes.halfDivider} />
          </Box>
          <Box width="100%">
            <Button variant="outlined" fullWidth to={(rest.location.search.length > 0) ? `/login${rest.location.search}` : "/login"} component={Link} >
              { t('common.btn.log-in-here') }
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  )
};

const mapStateToProps = (state) => {
  return {
    registeringUser: state.user.registeringUser,
    activeLanguage: state.config.activeLanguage,
  };
};

export default connect(mapStateToProps, { signUp, setRegisteringUser, setPasswordResetRequestSent, setIsNewProfile, push })(SignUp);
