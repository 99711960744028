import React, { useState, useEffect } from 'react';
import { Box, Button, Card, useTheme, useMediaQuery, Divider, Typography, Chip, SvgIcon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../common/styles';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { setFocusedSpot, fetchParkingSpotDetailsSearch } from '../../../data/actions/parkingSpot';
import { getSVGForIcon } from '../../../helpers/assets';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Carousel from '../../common/carousel';
import { Link, useLocation } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import StarIcon from '@material-ui/icons/Star';
import AnimatedBg from "react-animated-bg";
import { getClassForSVG } from '.';
import clsx from 'clsx';
import { ReactComponent as PinSVG } from '../../../assets/images/location-pin.svg';
import { getAttributes } from "../../../helpers/mainAttributes";
import { formatCountry } from '../../../lib/utils';

function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals).toFixed(decimals);
}

const ResultItem = ({ tag, divider, isDesktop, push, checkIns, focus, showMap, setFocusedSpot, cardData, fetchParkingSpotDetailsSearch, resultsTimestamp, searchTimestamp }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const resultItemRef = React.useRef(null);
  let isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  let color;

  useEffect(() => {
    if (searchTimestamp === resultsTimestamp && !cardData[`${tag}`]) {
      setTimeout(() => fetchParkingSpotDetailsSearch(tag), 500);
    }
  }, [fetchParkingSpotDetailsSearch, tag, resultsTimestamp])

  const [currentCardData, setCurrentCardData] = useState(null);

  useEffect(() => {
    setCurrentCardData(cardData ? cardData[`${tag}`] ? cardData[`${tag}`] : null : null)
  }, [cardData, tag])

  useEffect(() => {
    if (!showMap && focus) {
      resultItemRef.current.scrollIntoView();
    }
  }, [focus, setFocusedSpot]);

  let result_images = [];
  let booking = null;

  if (currentCardData && currentCardData.tag === tag) {
    [booking] = checkIns.filter(checkin => checkin.parking_spot.tag === currentCardData.tag);

    if (!!currentCardData.available_today && !!currentCardData.spot_active){
      if (currentCardData.capacity > 0 && (currentCardData.occupied/currentCardData.capacity) < 0.66){
        color = "green";
      }
      else if (currentCardData.capacity > 0 && (currentCardData.occupied/currentCardData.capacity) < 1){
        color = "yellow";
      }
      else {
        color = "red";
      }
    }
    else{
      color = "grey";
    }

    const gallery_images = () => {
      let img_array = [];
      (currentCardData.gallery_images && currentCardData.gallery_images.length > 0) &&
        currentCardData.gallery_images.forEach(img => {
          img_array.push(<LazyLoadImage height={isMobile ? "15rem" : "10rem"} style={ isMobile ? {height: "15rem"} : {height: "10rem"}} src={img.thumbnail_url ? img.thumbnail_url : img.service_url} className={classes.parkingSpotImage} threshold={200} />)
        })
      return img_array;
    }

    result_images = [
      <LazyLoadImage height={isMobile ? "15rem" : "10rem"} style={ isMobile ? {height: "15rem"} : {height: "10rem"}} src={currentCardData.main_photo_thumbnail_url ? currentCardData.main_photo_thumbnail_url : currentCardData.main_photo_url} className={classes.parkingSpotImage} threshold={200} />,
      ...gallery_images()
    ];
  }

  let grossPrice = currentCardData && Number(currentCardData.price) + Number(currentCardData.tax_amount);

  return (
    <React.Fragment>
      <Box position="relative" visibility={currentCardData ? "hidden" : "visible"} key={`result-item-${tag}`}>
        <Box py={2} pr={isDesktop ? 2 : 3} pl={isDesktop ? 0 : 3} display="flex" className={classes.resultItem}>
          <Box className={classes.parkingSpotImageWrapperPlaceholder} position="relative" flex={1}>
            <AnimatedBg colors={["#E7F0F0", "#d5d5d5"]} duration={1.0} delay={0.1} timingFunction="linear" className={classes.parkingSpotImagePlaceholder}>
              <div />
            </AnimatedBg>
          </Box>
          <Box pl={isMobile ? 0 : 2} py={2} display="flex" flexDirection="column" flex={2}>
            <Box display="flex" flexDirection="column" justifyContent="space-between" className={classes.resultItemInfo}>
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column">
                  <AnimatedBg colors={["#E7F0F0", "#d5d5d5"]} duration={1.0} delay={0.1} timingFunction="linear" className={classes.titlePlaceholder}>
                    <div />
                  </AnimatedBg>
                  <AnimatedBg colors={["#E7F0F0", "#d5d5d5"]} duration={1.0} delay={0.1} timingFunction="linear" className={classes.locationPlaceholder}>
                    <div />
                  </AnimatedBg>
                  <AnimatedBg colors={["#E7F0F0", "#d5d5d5"]} duration={1.0} delay={0.1} timingFunction="linear" className={classes.iconsPlaceholder}>
                    <div />
                  </AnimatedBg>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" pt={3}>
                <AnimatedBg colors={["#E7F0F0", "#d5d5d5"]} duration={1.0} delay={0.1} timingFunction="linear" className={classes.reviewPlaceholder}>
                  <div />
                </AnimatedBg>
                <AnimatedBg colors={["#E7F0F0", "#d5d5d5"]} duration={1.0} delay={0.1} timingFunction="linear" className={classes.availabilityPlaceholder}>
                  <div />
                </AnimatedBg>
              </Box>
            </Box>
          </Box>
        </Box>
        { divider && !isMobile && (
          <Box m={2}>
            <Divider classes={{root: classes.divider}} />
          </Box>
        )}

        <div ref={resultItemRef} className={clsx(classes.resultCard, currentCardData ? classes.resultCardVisible: classes.resultCardInvisible)}>
          <Box component={Link} py={2} pr={isDesktop ? 2 : 3} pl={isDesktop ? 0 : 3} display="flex" className={classes.resultItem} to={currentCardData ? `/ps/${currentCardData.tag}` : null}>
            <Box className={classes.parkingSpotImageWrapper} position="relative" flex={1}>
              {
                (result_images.length > 1) ? (
                  <Carousel items={result_images} label={currentCardData && currentCardData.name.replace(/\s+/g, '-').toLowerCase()} />
                )
                : (
                  <LazyLoadImage height={isMobile ? "15rem" : "10rem"} style={ isMobile ? {height: "15rem"} : {height: "10rem"}} src={currentCardData ? currentCardData.main_photo_thumbnail_url ? currentCardData.main_photo_thumbnail_url : currentCardData.main_photo_url: null} className={classes.parkingSpotImage} threshold={200} />
                )
              }
            </Box>
            <Box pl={isMobile ? 0 : 2} py={1} display="flex" flexDirection="column" flex={2} className={classes.parkingSpotResultDetailsWrapper}>
              <Box display="flex" flexDirection="column" justifyContent="space-between" className={classes.resultItemInfo}>
                <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                  <Box display="flex" alignItems="center">
                    <Box flex="auto" width="8rem">
                      <Typography variant={"h3"} className={clsx(classes.singleLine, classes.resultTitle)}>
                        {currentCardData && currentCardData.name}
                      </Typography>
                    </Box>
                    {!isMobile ? (
                      <Box display={!!booking ? "flex" : "none"} className={classes.checkedInChip} justifyContent="center" alignItems="center">
                        <Typography variant="h6" style={{color: "white"}}>{t("common.texts.checked-in")}</Typography>
                      </Box>
                    ) : (
                      <Box display={!!booking ? "flex" : "none"} className={classes.cardCheckedInChip} justifyContent="center" alignItems="center" ml={1}>
                        <CheckIcon className={classes.checkIcon} />
                      </Box>
                    )}
                  </Box>
                  {
                    (currentCardData && !!currentCardData.location) &&
                    <Box display="flex" alignItems="center">
                      <PinSVG fill="#8E8E99"/>
                      <Typography variant="caption" style={{color: theme.palette.secondary.main, marginLeft: "0.5em" }} className={classes.singleLine}>
                        {formatCountry(currentCardData.location)}
                      </Typography>
                    </Box>
                  }
                  <Box marginLeft="0.1rem" className={classes.singleLine} py={isMobile ? 1 : 0} display="flex">
                    {currentCardData && getAttributes(currentCardData, true) && getAttributes(currentCardData, true).map((option) => {
                      const Icon = getSVGForIcon(option.icon);
                      return !!Icon && <Icon key={option.id} fill={theme.palette.primary.main} className={clsx(classes.resultIcon, option.icon.startsWith("host_type_campsite") ? "campsiteIcon" : "")}/>
                    })}
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" className={classes.reviewInfoBox}>
                      <StarIcon className={classes.resultItemStar} />
                      <Typography variant="caption">
                        { currentCardData ? currentCardData.review_count > 0 ? (Math.round(currentCardData.avg_rating * 10) / 10).toFixed(1) : "N/A" : "N/A" }
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Box display="flex" alignItems="center">
                        {currentCardData && (
                          <Typography variant="h5">
                            {round(grossPrice, 2)} {currentCardData.currency} { t("common.texts.day") }
                          </Typography>
                        )}
                      </Box>
                      {
                        currentCardData && (
                        (!!currentCardData.available_today && !!currentCardData.spot_active)
                        ?
                        <Chip label={`${currentCardData.capacity - currentCardData.occupied} / ${currentCardData.capacity}`} classes={{label: classes.chipLabel}} variant="outlined" className={(currentCardData.occupied === currentCardData.capacity) ? classes.chipFull : (color === "green") ? classes.chip : classes.chipYellow} />
                        :
                        <Chip label={`0 / ${currentCardData.capacity}`} classes={{label: classes.chipLabel}} variant="outlined" className={classes.chipUnavailable} />
                        )
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          { divider && !isMobile && (
            <Box m={2}>
              <Divider classes={{root: classes.divider}} />
            </Box>
          )}
        </div>
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userType: state.user.userType,
    currentCheckIns: state.parkingSpot.currentCheckIns,
    cardData: state.parkingSpot.parkingSpotDetailsSearch.cardData,
    searchTimestamp: state.parkingSpot.searchTimestamp,
  }
};

export default connect(mapStateToProps, {
  push,
  setFocusedSpot,
  fetchParkingSpotDetailsSearch
})(ResultItem);
