import { combineEpics } from 'redux-observable';

import * as AuthEpics from './auth';
import * as UserEpics from './user';
import * as ConfigEpics from './config';
import * as ParkingSpotEpics from './parkingSpot';
import * as CarsEpics from './cars';
import * as PaymentEpics from './payment';
import * as ReferralEpics from './referral';
import * as VoucherEpics from './voucher'

export default combineEpics(
  AuthEpics.fetchAuthByRefreshToken,
  AuthEpics.signUp,
  AuthEpics.login,
  AuthEpics.logout,
  AuthEpics.resetPassword,
  AuthEpics.recoverPassword,
  AuthEpics.changePassword,
  UserEpics.updateHost,
  UserEpics.updateHostLocale,
  UserEpics.updateGuest,
  UserEpics.updateGuestLocale,
  UserEpics.postProfilePic,
  UserEpics.fetchHostProfile,
  UserEpics.fetchGuestProfile,
  UserEpics.fetchSubHosts,
  UserEpics.postNewSubHost,
  UserEpics.deleteSubHost,
  UserEpics.updateSubHost,
  UserEpics.updateSubHostProfile,
  UserEpics.updateSubHostLocale,
  ParkingSpotEpics.postParkingSpot,
  ParkingSpotEpics.patchParkingSpot,
  ParkingSpotEpics.fetchParkingSpotAttributes,
  ParkingSpotEpics.fetchParkingSpot,
  ParkingSpotEpics.fetchParkingSpotCars,
  ParkingSpotEpics.fetchParkingSpots,
  ParkingSpotEpics.postGalleryImage,
  ParkingSpotEpics.deleteGalleryImage,
  ConfigEpics.changeI18nLanguage,
  PaymentEpics.fetchFinancials,
  PaymentEpics.fetchGuestPaymentDetails,
  PaymentEpics.postWithdrawBalance,
  PaymentEpics.postPaymentSchedule,
  PaymentEpics.fetchStripeStatus,
  PaymentEpics.fetchSingleFinancialsReceipt,
  PaymentEpics.fetchAllFinancialsReceipt,
  PaymentEpics.postPaymentConnect,
  PaymentEpics.patchPaymentDetails,
  PaymentEpics.fetchPaymentSecret,
  PaymentEpics.guestPaymentConnect,
  PaymentEpics.fetchGuestWithdrawals,
  PaymentEpics.fetchGuestPayments,
  PaymentEpics.resetHostPaymentDetails,
  PaymentEpics.withdrawGuestUserBalance,
  ParkingSpotEpics.fetchParkingSpotsForGuests,
  ParkingSpotEpics.fetchPhotosByIdForGuests,
  ParkingSpotEpics.fetchReviewsByIdForGuests,
  ParkingSpotEpics.fetchReviewsByIdForGuestsNextPage,
  ParkingSpotEpics.fetchCurrentCheckIns,
  ParkingSpotEpics.fetchPriceFilters,
  ParkingSpotEpics.fetchPastCheckIns,
  ParkingSpotEpics.fetchCarsForGuests,
  ParkingSpotEpics.postCheckIn,
  ParkingSpotEpics.postCheckOut,
  ParkingSpotEpics.postReview,
  ParkingSpotEpics.fetchParkingSpotDetails,
  ParkingSpotEpics.fetchParkingSpotDetailsGuests,
  ParkingSpotEpics.searchParkingSpots,
  ParkingSpotEpics.enableParkingSpot,
  ParkingSpotEpics.disableParkingSpot,
  ParkingSpotEpics.fetchBookingsHost,
  ParkingSpotEpics.fetchFilteredBookingsHost,
  ParkingSpotEpics.fetchSingleBookingReceipt,
  ParkingSpotEpics.fetchMultipleBookingsReceipt,
  ParkingSpotEpics.fetchReviewForBooking,
  ParkingSpotEpics.pushToRoot,
  ParkingSpotEpics.fetchParkingSpotDetailsSearch,
  ParkingSpotEpics.fetchUserLocation,
  ParkingSpotEpics.postParkedCarCheck,
  CarsEpics.fetchCars,
  CarsEpics.putCar,
  CarsEpics.postCar,
  CarsEpics.deleteCar,
  CarsEpics.updatePrimaryCar,
  ReferralEpics.fetchReferralsEpic,
  ReferralEpics.createReferralsEpic,
  VoucherEpics.fetchVoucherDetails,
);
