import * as Types from "./constants";

export const fetchReferrals = (token) => {
  return {
    type: Types.FETCH_REFERRALS,
    token,
  };
};

export const fetchReferralsSuccess = (data) => {
  return {
    type: Types.FETCH_REFERRALS_SUCCESS,
    data,
  };
};

export const fetchReferralsFailure = () => {
  return {
    type: Types.FETCH_REFERRALS_FAILURE,
  };
};

export const createReferral = (token, data) => {
  return {
    type: Types.CREATE_REFERRAL,
    token,
    data,
  };
};

export const createReferralSuccess = (data) => {
  return {
    type: Types.CREATE_REFERRAL_SUCCESS,
    data,
  };
};

export const createReferralFailure = () => {
  return {
    type: Types.CREATE_REFERRAL_FAILURE,
  };
};

export const createReferralReset = () => {
  return {
    type: Types.CREATE_REFERRAL_RESET,
  };
};
