import React, { useState } from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Box, Typography, Button, useMediaQuery } from '@material-ui/core';
import Carousel from '../carousel';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  imageGallery: {
    objectFit: "cover",
    width: "100%",
    [theme.breakpoints.up("md")]:{
      height: "30rem",
    },
    [theme.breakpoints.down("md")]:{
      height: "17rem",
    },
  },
  galleryContainer:{
    [theme.breakpoints.up("md")]:{
      height: "30rem",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]:{
      height: "18rem",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]:{
      height: "16rem",
      width: "100%",
    },
  },
  imgWrapperExpanded: {
    paddingTop: "6rem",
  },
  imageGalleryExpanded: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  aspectRatioBox: {
    background: "#fff",
    width: "80%",
    "&::before": {
      content: '""',
      width: "1px",
      marginLeft: "-1px",
      float: "left",
      height: "0",
      paddingTop: "591.44px / 1127.34px * 100%",
    },
    "&::after": {
      content: '""',
      display: "table",
      clear: "both",
    },
  },
  galleryButtonBackExpanded: {
    cursor: "pointer",
    position: "absolute !important",
    left: "4%",
    top: "50%",
    transform: "translateY(-50%)",
    color: theme.palette.secondary.main,
    fontSize: "1.5rem",
  },
  galleryButtonForwardExpanded: {
    cursor: "pointer",
    position: "absolute !important",
    left: "95%",
    top: "50%",
    transform: "translateY(-50%)",
    color: theme.palette.secondary.main,
    fontSize: "1.5rem",
  },
  galleryButtonClose: {
    cursor: "pointer",
    position: "absolute !important",
    left: "95%",
    top: "10%",
    transform: "translateY(-50%)",
    color: theme.palette.secondary.main,
    fontSize: "1.8rem",
  },
  galleryButtonExpand: {
    display: "none",
    [theme.breakpoints.up("md")]:{
      display: "flex",
      left: "78%",
      top: "86%",
      transform: "translateY(-50%)",
      color: "white",
      fontSize: "1.0rem",
      cursor: "pointer",
      fontSize: "1.3rem",
      background: "#fff",
      width: "3rem",
      borderRadius: "1rem",
      padding: "0.2rem",
      justifyContent: "center",
      position: "absolute !important",
    },
  },
  galleryButtonExpandIcon: {
    color: theme.palette.secondary.main,
    marginLeft: "-2px",
  },
  galleryContainerExpanded: {
    display: "flex",
    justifyContent: "center",
    height: "calc(100% - 6rem)",
  },
  galleryWrapper: {
    zIndex: "1999",
    backgroundColor: "#fff",
  },
  galleryLabel: {
    padding: "1.8rem",
  }
}));

const Gallery = ({ images, fetchCarouselImages, carouselProps = {} }) => {
  const classes = useStyles();
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [open, setOpen] = useState(false);

  if (images && images.length) {
    if( open) {
      return (
        <Box position="fixed" top={0} left={0} bottom={0} right={0} className={classes.galleryWrapper}>
          <Box className={classes.galleryContainerExpanded}>
            <Box className={classes.imgWrapperExpanded}>
              <img className={classes.imageGalleryExpanded} src={images[galleryIndex]} alt="Gallery images" />
            </Box>
            <ArrowBackIosIcon className={classes.galleryButtonBackExpanded} onClick={() => {
              setGalleryIndex((images.length + galleryIndex - 1) % images.length);
            }} />
            <ArrowForwardIosIcon className={classes.galleryButtonForwardExpanded} onClick={() => {
              setGalleryIndex((images.length + galleryIndex + 1) % images.length);
            }} />
            <CancelIcon className={classes.galleryButtonClose} onClick={() => {
              setOpen(false);
            }} />
          </Box>
          <Box display="flex" justifyContent="center" className={classes.galleryLabel}>
            <Typography variant="body2">{galleryIndex+1}/{images.length}</Typography>
          </Box>
        </Box>
      )
    }
    return (
      <Box position="relative" className={classes.galleryContainer}>
        <Carousel
          {...carouselProps}
          items={fetchCarouselImages(images)}
          label="details"
          wrapper={true}
          showControls={true}
        />
      </Box>
    );
  }
  return null;
}

export default Gallery;
