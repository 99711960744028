import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const NavButton = withStyles((theme) => ({
  root: {
    border: '1px solid',
    borderColor: '#E7F0F0',
    borderRadius: "2em",
    padding: "0.65em 1.10em",
    textTransform: "none",
    fontWeight: 400,
    color: theme.palette.grey[800],
    backgroundColor: "#fff",
    '&:hover': {
      backgroundColor: "#fff",
    },
  },
}))(Button);

export default NavButton;
