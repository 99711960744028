import { SvgIcon } from "@material-ui/core";
import React from "react";

function Account(props) {
  return (
    <SvgIcon {...props} viewBox='0 0 32 32'>
      <path
        d="M16.9548 18.676C17.4583 18.6975 17.8333 19.1279 17.8333 19.6319V28.334C17.8333 28.8863 17.3856 29.334 16.8333 29.334H6.83325C6.281 29.334 5.82844 28.8854 5.88008 28.3355C5.98994 27.1658 6.29252 26.02 6.7777 24.945C7.40028 23.5656 8.30928 22.3344 9.4443 21.3333C10.5793 20.3322 11.9144 19.5841 13.3608 19.1387C14.5257 18.78 15.7413 18.6242 16.9548 18.676ZM16.5 17.334C12.08 17.334 8.50001 13.754 8.50001 9.33398C8.50001 4.91398 12.08 1.33398 16.5 1.33398C20.92 1.33398 24.5 4.91398 24.5 9.33398C24.5 13.754 20.92 17.334 16.5 17.334ZM24.224 26.5526L28.2305 22.5462C28.6209 22.1557 29.2539 22.1556 29.6444 22.5459L30.1164 23.0175C30.5072 23.408 30.5073 24.0414 30.1167 24.432L24.9311 29.6175C24.5406 30.0081 23.9074 30.0081 23.5169 29.6175L20.2167 26.3173C19.8261 25.9267 19.8262 25.2934 20.2169 24.9029L20.6889 24.4313C21.0795 24.041 21.7124 24.0411 22.1029 24.4315L24.224 26.5526Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default Account;
