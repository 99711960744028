import React, { useState, useEffect, useRef } from 'react';
import { Divider, Container, Box, Typography, Card, Button, TextField, useTheme, useMediaQuery, CircularProgress, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Tabs, Tab } from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import ImageIcon from '@material-ui/icons/Image';
import {
  useParams, useLocation, useHistory
} from "react-router-dom";

import { useStyles } from '../common/styles';
import TitleSection from '../../common/titleSection';
import CheckboxFieldset from '../../common/checkboxFieldset';
import FieldWithoutLabel from '../../common/formFieldWithoutLabel';
import { PhotoFormSection } from '../../common/imageUploader';
import { useTranslation } from 'react-i18next';
import { postParkingSpot, patchParkingSpot, fetchParkingSpotAttributes, fetchParkingSpotDetails, postGalleryImage, incrementGalleryImageLoadingCounter, deleteGalleryImage, setGeolocationEditParkingSpot, setAddressEditParkingSpot, setCreatingParkingSpot, setContinuing, setFinishing, setUpdatingParkingSpot } from '../../../data/actions/parkingSpot';
import DismissButton from '../../common/dismissButton';
import { enqueueSnackbar } from '../../../data/actions/global';
import FormMap from './formMap'
import Geosuggest from 'react-geosuggest';
import { pushToRoot } from '../../../data/actions/parkingSpot';
import { addLoader } from '../../../data/actions/config';
import clsx from 'clsx';
import { useIntercom } from 'react-use-intercom';

const attributeMap = {
  wcService: { category: "Service", text: "WC" },
  powerSupplyService: { category: "Service", text: "Power supply" },
  freshWaterService: { category: "Service", text: "Fresh water" },
  showerService: { category: "Service", text: "Shower" },
  wifiService: { category: "Service", text: "Wifi" },
  cassetteDisposalService: { category: "Service", text: "Toilet cassette disposal" },
  waterDisposalService: { category: "Service", text: "Grey water disposal" },
  wasterDisposalService: { category: "Service", text: "Waste disposal" },
  firePlaceService: { category: "Service", text: "Fire place" },
  farmShopService: { category: "Service", text: "Farm shop" },
  camperDinnerService: { category: "Service", text: "Camper dinner" },
  laundryService: { category: "Service", text: "Laundry" },

  rvParking: { category: "Place Type", text: "RV Parking" },
  campsiteSpot: { category: "Place Type", text: "At Campsite" },
  privateSpot: { category: "Place Type", text: "At Private house" },
  railwaySpot: { category: "Place Type", text: "At Mountain Railway" },
  restaurantParkingSpot: { category: "Place Type", text: "At Restaurant Parking" },
  farmhouseSpot: { category: "Place Type", text: "At Farmhouse" },
  natureSpot: { category: "Place Type", text: "Nature spot" },
  atMarina: { category: "Place Type", text: "At marina" },

  onboardToiletRequired: { category: "Autarchy", text: "Onboard Toilet required" },
  noOnboardToiletRequired: { category: "Autarchy", text: "No Onboard Toilet required" },

  asphalt: { category: "Terrain", text: "Asphalt" },
  gravel: { category: "Terrain", text: "Gravel" },
  grass: { category: "Terrain", text: "Gras" },

  narrow: { category: "Approach", text: "Road Access Narrow < 2m" },
  only4x4: { category: "Approach", text: "4x4 empfohlen" },

  upTo6: { category: "Car size", text: "up to 6 meters" },
  upTo8: { category: "Car size", text: "up to 8 meters" },
  upTo12: { category: "Car size", text: "up to 12 meters" },
  caravan: { category: "Car size", text: "Caravan" },
  onlyOffroad: { category: "Car size", text: "Only offroad vehicles" },

  campingBehaviour: { category: "Behaviour", text: "Camping behaviour" },
  foldingChairBehaviour: { category: "Behaviour", text: "Folding Chair behaviour" },
  parkingBehaviour: { category: "Behaviour", text: "Only Parking Behaviour" },

  winter: { category: "Season", text: "Winter Place" },
  summer: { category: "Season", text: "Summer Place" },

  nearRestaurant: { category: "Surrounding", text: "Near Restaurant" },
  protectedArea: { category: "Surrounding", text: "Protected Area" },
  nearShopping: { category: "Surrounding", text: "Near Shopping" },
  nearPlayground: { category: "Surrounding", text: "Near Playground" },
  dogFriendly: { category: "Surrounding", text: "Dog Friendly" },
  nearMountains: { category: "Surrounding", text: "Near Mountains" },
  nearWater: { category: "Surrounding", text: "Near Waters" },
  nearSki: { category: "Surrounding", text: "Near Ski Slopes" },
  hikingArea: { category: "Surrounding", text: "Hiking Area" },
  bicycleSurrounding: { category: "Surrounding", text: "Bicycle" },

  // TODO: Update if added to backend
  // calm: { category: "Gurantee", text: "calm" },
  // plane: { category: "Gurantee", text: "plane" },
  // safe: { category: "Gurantee", text: "safe" },

  alreadyOperational: { category: "Binary Options", text: "Another system is already operational" },
  winterFriendly: { category: "Binary Options", text: "Parking spot is winter friendly" },
  toiletAvailable: { category: "Binary Options", text: "Parking spot has a toilet that can be used" },

  monday: { category: "Availability", text: "Monday" },
  tuesday: { category: "Availability", text: "Tuesday" },
  wednesday: { category: "Availability", text: "Wednesday" },
  thursday: { category: "Availability", text: "Thursday" },
  friday: { category: "Availability", text: "Friday" },
  saturday: { category: "Availability", text: "Saturday" },
  sunday: { category: "Availability", text: "Sunday" },
};

const multipleFor = (category, data) => {
  const categoriesWithMultiple = data.filter(cat => cat.multiple).map(cat => cat.name);
  return categoriesWithMultiple.includes(category);
};

const initialValues = (values = {}) => {
  return {
    name: values.name || "",
    description: values.description || "",
    checkinInformation: values.checkinInformation || "",
    pricing: values.pricing || "",
    taxType: values.taxType || false,
    taxAmount: values.taxAmount || "",
    parkingSpaceSpots: values.parkingSpaceSpots || "",
    contactEmail: values.contactEmail || "",

    wcService: values.wcService || false,
    powerSupplyService: values.powerSupplyService || false,
    freshWaterService: values.freshWaterService || false,
    showerService: values.showerService || false,
    wifiService: values.wifiService || false,
    cassetteDisposalService: values.cassetteDisposalService || false,
    waterDisposalService: values.waterDisposalService || false,
    wasterDisposalService: values.wasterDisposalService || false,
    firePlaceService: values.firePlaceService || false,
    farmShopService: values.farmShopService || false,
    camperDinnerService: values.camperDinnerService || false,
    laundryService: values.laundryService || false,

    rvParking: values.rvParking || false,
    campsiteSpot: values.campsiteSpot || false,
    privateSpot: values.privateSpot || false,
    railwaySpot: values.railwaySpot || false,
    restaurantParkingSpot: values.restaurantParkingSpot || false,
    farmhouseSpot: values.farmhouseSpot || false,
    natureSpot: values.natureSpot || false,
    atMarina: values.atMarina || false,

    onboardToiletRequired: values.onboardToiletRequired || false,

    narrow: values.narrow || false,
    only4x4: values.only4x4 || false,

    asphalt: values.asphalt || false,
    gravel: values.gravel || false,
    grass: values.grass || false,

    upTo6: values.upTo6 || false,
    upTo8: values.upTo8 || false,
    upTo12: values.upTo12 || false,
    caravan: values.caravan || false,
    onlyOffroad: values.onlyOffroad || false,

    campingBehaviour: values.campingBehaviour || false,
    foldingChairBehaviour: values.foldingChairBehaviour || false,
    parkingBehaviour: values.parkingBehaviour || false,

    winter: values.winter || false,
    summer: values.summer || false,

    nearRestaurant: values.nearRestaurant || false,
    protectedArea: values.protectedArea || false,
    nearShopping: values.nearShopping || false,
    nearPlayground: values.nearPlayground || false,
    dogFriendly: values.dogFriendly || false,
    nearMountains: values.nearMountains || false,
    nearWater: values.nearWater || false,
    nearSki: values.nearSki || false,
    hikingArea: values.hikingArea || false,
    bicycleSurrounding: values.bicycleSurrounding || false,

    // calm: values.calm || false,
    // plane: values.plane || false,
    // safe: values.safe || false,

    alreadyOperational: values.alreadyOperational || false,
    winterFriendly: values.winterFriendly || false,
    toiletAvailable: values.toiletAvailable || false,

    monday: values.monday || true,
    tuesday: values.tuesday || true,
    wednesday: values.wednesday || true,
    thursday: values.thursday || true,
    friday: values.friday || true,
    saturday: values.saturday || true,
    sunday: values.sunday || true,
  };
};

const parkingSpotToFormValues = (parkingSpot, parkingSpotAttributesMap) => {
  function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals).toFixed(decimals);
  }
  if (parkingSpot && parkingSpotAttributesMap) {
    const values = {
      name: parkingSpot.name || "",
      description: parkingSpot.description || "",
      checkinInformation: parkingSpot.checkin_info || "",
      pricing: round(parkingSpot.price, 2) || "",
      taxAmount: round(parkingSpot.tax_amount, 2) || "",
      taxType: parkingSpot.tax_type || false,
      tourismTaxType: parkingSpot.tourism_tax_type || false,
      parkingSpaceSpots: parkingSpot.capacity || "",
      contactEmail: parkingSpot.contact_email || "",
    };

    Object.keys(attributeMap).forEach((key) => {
      values[key] = !!parkingSpot.parking_spot_category_options.find((option) =>
        option.id === parkingSpotAttributesMap[attributeMap[key].category][attributeMap[key].text]
      )
    })

    return values;
  }
  return {};
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ParkingSpotForm = ({ parkingSpot, postParkingSpot, patchParkingSpot, token, parkingSpotAttributes, parkingSpotAttributesMap, fetchingParkingSpotAttributes, fetchParkingSpotAttributes, fetchParkingSpotDetails, postGalleryImage, incrementGalleryImageLoadingCounter, updatingGalleryImage, deleteGalleryImage, setGeolocationEditParkingSpot, location, enqueueSnackbar, setCreatingParkingSpot, setContinuing, setFinishing, setUpdatingParkingSpot, updatingParkingSpot, creatingParkingSpot, pushToRoot, user, addLoader, continuing, finishing }) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let isSmallMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [mainImage, setMainImage] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);
  const { t } = useTranslation();
  let defaultValues = initialValues();
  let { id } = useParams();
  const history = useHistory()
  const query = useQuery();
  const {
    boot,
    startTour,
    shutdown,
  } = useIntercom();
  const tourId = query.get("product_tour_id");

  const { handleSubmit, control, reset, setValue, getValue, getValues, watch } = useForm({ defaultValues });

  const [taxChoice, setTaxChoice] = useState(null);
  const handleTaxChoice = (data) => {
    setTaxChoice(data);
  }

  const [manualTaxChoice, setManualTaxChoice] = useState("flat");
  const handleManualTaxChoice = (data) => {
    setManualTaxChoice(data);
  }

  const handleGalleryUpload = (data) => {
    setGalleryImages(data);
  };

  const handleMainImageUpload = (data) => {
    setMainImage(data[0]);
  };

  useEffect(() => {
    if (tourId) {
      boot()
      startTour(tourId);
    }
    return () => {
      shutdown()
    }
  }, [tourId, startTour, boot]);

  useEffect(() => {
    if (id && token && id !== "new") {
      addLoader();
      fetchParkingSpotDetails(id, token);
    }

    if (!parkingSpot || parkingSpot.id !== id){
      setAddressEditParkingSpot([]);
      setGeolocationEditParkingSpot(null);
    }
  }, [])

  const step = query.get('step');

  useEffect(() => {
    if (["main", "pricing", "additional", "attributes"].includes(step)) {
      setActiveTab(step);
      window.scrollTo(0, 0);
    }
  }, [step])

  useEffect(() => {
    if (parkingSpot && parkingSpot.host && parkingSpot.tag && parkingSpot.tag == id && (user && ((user.userType && user.userType !== "Host") || parkingSpot.host.id !== user.id))){
      pushToRoot();
    }
    if (parkingSpot && parkingSpot.tag == id) {
      const formValues = parkingSpotToFormValues(parkingSpot, parkingSpotAttributesMap)
      if (formValues && formValues.name) {
        reset({ ...formValues });
        if (taxChoice === null || taxChoice === "auto"){
          if (formValues.taxType === "no"){
            setTaxChoice("zero");
          } else if (formValues.taxType === "system"){
            setTaxChoice("auto");
          } else if (formValues.taxType === "custom"){
            setTaxChoice("specify");
            setManualTaxChoice(formValues.tourismTaxType);
          }
        }
      }
    }
    else {
      setTaxChoice("auto");
    }

    if (id && token && id !== "new") {
      if (parkingSpot && parkingSpot.tag === id && parkingSpot.geolocation !== null){
        setGeolocationEditParkingSpot(parkingSpot.geolocation);
      }
    }

    if (!parkingSpotAttributesMap) {
      fetchParkingSpotAttributes();
    }
    if ((location.geolocation == null ) && parkingSpot && parkingSpot.tag === id && parkingSpot.geolocation !== null) {
      setGeolocationEditParkingSpot(parkingSpot.geolocation);
    }
  }, [fetchParkingSpotDetails, fetchParkingSpotAttributes, id, token, parkingSpotToFormValues, JSON.stringify(parkingSpot), JSON.stringify(parkingSpotAttributesMap)])

  if(location.address !==null){
    setValue("address", location.address.formatted_address);
  }

  const appendIfExists = (attribute, data, path, category, option) => {
    if (parkingSpotAttributesMap[category][option]) {
      attribute &&
      data.append(`${path}[parking_spot_category_option_id]`, parkingSpotAttributesMap[category][option]);
    } else {
      console.log("Category option not available:");
      console.log("category", category);
      console.log("option", option);
    }
  };

  const nameRef = useRef(null);
  const descriptionRef = useRef(null);
  const checkinInformationRef = useRef(null);
  const pricingRef = useRef(null);
  const taxAmountRef = useRef(null);
  const spotsRef = useRef(null);

  const isMainSectionValid = () => {
    if (
      nameRef.current?.reportValidity() &&
      descriptionRef.current?.reportValidity() &&
      checkinInformationRef.current?.reportValidity()
    ) {
      if (!mainImage && !(parkingSpot && parkingSpot.tag == id && parkingSpot.main_photo_thumbnail_url)) {
        enqueueSnackbar({
          message: {
            text: "Main photo is required",
            variant: "error",
          },
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
        return false;
      }
      return true;
    }
    return false;
  };

  const isAdditionalSectionValid = (data) => {
    if (
      spotsRef.current?.reportValidity()
    ) {
      if (!location.geolocation && !(parkingSpot && parkingSpot.tag == id && parkingSpot.geolocation)) {
        enqueueSnackbar({
          message: {
            text: "Address is required",
            variant: "error",
          },
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
        return false;
      }
      return true;
    }
    return false;
  };

  const isPricingSectionValid = (data) => {
    return pricingRef.current?.reportValidity() && taxAmountRef.current?.reportValidity();
  };

  const onSubmitMain = (data, tab, finish) => {
    const formData = new FormData();
    formData.append("parking_spot[name]", data.name);
    formData.append("parking_spot[description]", data.description);
    formData.append("parking_spot[checkin_info]", data.checkinInformation);

    if (mainImage) {
      formData.append("parking_spot[main_photo]", mainImage);
    }

    if(isMainSectionValid()) {
      if(finish) {
        setFinishing(true);
      } else {
        setContinuing(true);
      }
      if (parkingSpot && parkingSpot.tag == id) {
        setUpdatingParkingSpot(true);
        patchParkingSpot(parkingSpot.id, formData, token, tab, finish)
      } else {
        [...galleryImages].forEach((file) => {
          formData.append(`parking_spot[gallery_images][]`, file);
        });
        setCreatingParkingSpot(true);
        postParkingSpot(formData, token, tab, finish);
      }
    }
  };

  const onSubmitPricing = (data, tab, finish) => {
    const formData = new FormData();
    let taxType = (taxChoice === "auto" ? "system" : (taxChoice === "specify" ? "custom" : "no"));
    let taxAmount = (taxChoice === "zero") ? 0 : data.taxAmount;

    formData.append("parking_spot[price]", data.pricing);
    formData.append("parking_spot[tax_type]", taxType);

    if (taxType === "custom"){
      formData.append("parking_spot[tourism_tax_type]", manualTaxChoice);
    }
    if (taxChoice !== "system") {
      formData.append("parking_spot[tax_amount]", taxAmount);
    }
    if (taxType === "system"){
      formData.append("parking_spot[tourism_tax_type]", "per_person");
    }

    if(isPricingSectionValid()) {
      if(finish) {
        setFinishing(true);
      } else {
        setContinuing(true);
      }
      if (parkingSpot && parkingSpot.tag == id) {
        setUpdatingParkingSpot(true);
        patchParkingSpot(parkingSpot.id, formData, token, tab, finish)
      } else {
        setCreatingParkingSpot(true);
        postParkingSpot(formData, token, tab, finish);
      }
    }
  };

  const onSubmitAdditional = (data, tab, finish) => {
    const formData = new FormData();
    formData.append("parking_spot[capacity]", data.parkingSpaceSpots);
    formData.append("parking_spot[contact_email]", data.contactEmail);

    // TODO: Update after location requirements are clear
    // formData.append("parking_spot[address]", data.address);

    if (location.geolocation) {
      formData.append("parking_spot[geolocation]", location.geolocation);
    }

    if(isAdditionalSectionValid()) {
      if(finish) {
        setFinishing(true);
      } else {
        setContinuing(true);
      }
      if (parkingSpot && parkingSpot.tag == id) {
        setUpdatingParkingSpot(true);
        patchParkingSpot(parkingSpot.id, formData, token, tab, finish)
      } else {
        setCreatingParkingSpot(true);
        postParkingSpot(formData, token, tab, finish);
      }
    }
  };

  const onSubmitAttributes = (data, tab, finish) => {
    const formData = new FormData();
    const attributesPath = "parking_spot[parking_spot_attributes_attributes][]";

    if (!data.onboardToiletRequired){
      data.noOnboardToiletRequired = true;
    }
    else {
      data.noOnboardToiletRequired = false;
    }
    Object.keys(attributeMap).forEach(key => appendIfExists(data[key], formData, attributesPath, attributeMap[key].category, attributeMap[key].text));

    if(finish) {
      setFinishing(true);
    } else {
      setContinuing(true);
    }
    if (parkingSpot && parkingSpot.tag == id) {
      setUpdatingParkingSpot(true);
      patchParkingSpot(parkingSpot.id, formData, token, tab, finish)
    } else {
      setCreatingParkingSpot(true);
      postParkingSpot(formData, token, tab, finish);
    }
  };

  const handleLocationChange = (suggestion_location) => {
    setGeolocationEditParkingSpot(`POINT(${suggestion_location.lng} ${suggestion_location.lat})`);
  }

  const disabled = (parkingSpot && parkingSpot.tag == id && updatingParkingSpot) || creatingParkingSpot || fetchingParkingSpotAttributes;
  const [activeTab, setActiveTab] = useState("main");


  const switchTo = (event, newTab) => {
    switch (newTab) {
      case "main":
        window.scrollTo(0, 0);
        setActiveTab(newTab);
        break;
      case "pricing":
        window.scrollTo(0, 0);
        setActiveTab(newTab);
        break;
      case "additional":
        window.scrollTo(0, 0);
        setActiveTab(newTab);
        break;
      case "attributes":
        window.scrollTo(0, 0);
        setActiveTab(newTab);
        break;
      default:
        break;
    };
  };

  const submitInterceptor = (finish) => {
    query.delete('step');
    history.replace({search: query.toString()});
    handleSubmit((data) => onSubmit(data, activeTab, finish))();
    return true;
  };

  const onSubmit = (data, tab, finish) => {
    switch (tab) {
      case "main":
        return onSubmitMain(data, "pricing", finish);
      case "pricing":
        return onSubmitPricing(data, "additional", finish);
        return "additional";
      case "additional":
        return onSubmitAdditional(data, "attributes", finish);
      case "attributes":
        return onSubmitAttributes(data, null, finish);
      default:
        return false;
    }
  };

  return (
    <Container maxWidth="md">
      <form>
        <Box display="flex">
          <TitleSection
            routes={[{
              name: t("common.texts.dashboard"),
              path: "/dashboard",
            }, {
              name: t("parking-spot.title"),
              path: "/ps",
            }]}
            title={ parkingSpot && parkingSpot.tag == id ? t("parking-spot.title-update") : t("parking-spot.title-create") }
          />
        </Box>
        <Divider />
        <Box pt={8}>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => switchTo(event, newValue)}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
          >
            <Tab value="main" label={t("parking-spot.form-section.main")} />
            <Tab value="pricing" label={t("parking-spot.form-section.pricing")} />
            <Tab value="additional" label={t("parking-spot.form-section.additional")} />
            <Tab value="attributes" label={t("parking-spot.form-section.attributes")} />
          </Tabs>
        </Box>
        <Box display="flex" pt={8} flexDirection="column" className={["main"].includes(activeTab) ? classes.itemsWrapperMd : (activeTab === "attributes" ? classes.itemsWrapperMax : classes.itemsWrapper)}>
          <Box style={{ display: activeTab === "main" ? "block" : "none" }}>
            <Box maxWidth="500px">
              <Typography variant="h3">{ t("common.labels.name") } *</Typography>
              <Typography variant="body1" className={classes.formDescription}>{ t("common.labels.name-sub-text") }</Typography>
              <FieldWithoutLabel
                ref={nameRef}
                name="name"
                placeholder={ t("common.placeholders.ps-name") }
                control={control}
                required
                type="text"
                limit={50}
              />
            </Box>
            <Box mt={6} maxWidth="500px">
              <Typography variant="h3">{t("common.labels.main-photo")} *</Typography>
              <PhotoFormSection
                uploadPath="parking_spot[main_photo]"
                uploadHandler={handleMainImageUpload}
                max={1}
                placeholderIcon={ImageIcon}
                largePreview={true}
                previewPhotos={parkingSpot && parkingSpot.tag == id && parkingSpot.main_photo_thumbnail_url ? [{
                  url: parkingSpot.main_photo_thumbnail_url,
                }] : []}
              />
            </Box>
            <Box mt={6}>
              <Typography variant="h3">Gallery (up to 10)</Typography>
              { parkingSpot && parkingSpot.tag == id ? (
                <PhotoFormSection
                  uploadPath="parking_spot[gallery_images]"
                  max={10}
                  previewPhotos={parkingSpot && (parkingSpot.gallery_images || []).map(p => {
                    return {
                      id: p.id,
                      url: p.thumbnail_url ? p.thumbnail_url : p.service_url,
                    }
                  })}
                  deleteAction={(photoId) => deleteGalleryImage(parkingSpot.id, photoId, token)}
                  postPhotoAction={(photo) => postGalleryImage(parkingSpot.id, photo, token)}
                  loading={updatingGalleryImage > 0}
                  setLoader={incrementGalleryImageLoadingCounter}
                  placeholderIcon={ImageIcon}
                />
              ) : (
                <PhotoFormSection
                  uploadPath="parking_spot[gallery_images]"
                  uploadHandler={handleGalleryUpload}
                  max={10}
                  previewPhotos={[]}
                  placeholderIcon={ImageIcon}
                />
              )}
            </Box>
            <Box mt={6}>
              <Typography variant="h3">{ t("common.labels.description") } *</Typography>
              <FieldWithoutLabel
                ref={descriptionRef}
                name="description"
                placeholder={ t("common.placeholders.ps-description") }
                multiline
                control={control}
                type="text"
                required
              />
            </Box>
            {/* // ! Hacky fix: Using pt because of the `multilineCounter` negative margin in `FieldWithoutLabel` */}
            <Box pt='10px' mt={6}>
              <Typography variant="h3">{ t("common.labels.checkin-information") }</Typography>
              <Typography variant="body1" className={classes.formDescription}>{ t("common.labels.checkin-info-details") }</Typography>
              <FieldWithoutLabel
                ref={checkinInformationRef}
                name="checkinInformation"
                multiline
                control={control}
                type="text"
              />
            </Box>
          </Box>
          <Box style={{ display: activeTab === "pricing" ? "block" : "none" }}>
            <Box>
              <Typography variant="h3">{ t("spot-attributes.pricing.title") } (24h) *</Typography>
              <Typography variant="body1" className={classes.formDescription}>{ t("common.labels.price-info-details") }</Typography>
              <FieldWithoutLabel
                ref={pricingRef}
                name="pricing"
                placeholder={ t("common.placeholders.type-pricing") }
                control={control}
                required
                step="any"
                type="number"
              />
            </Box>
            <Box pt={6}>
              <Typography variant="h3">{ t("spot-attributes.tax.title") } *</Typography>
              <Typography variant="body1" className={classes.formDescription}>{ t("spot-attributes.tax.radio-sub-text") }:</Typography>
              <Box mt={1}>
                <FormControl component="fieldset" style={{width: "100%"}}>
                  <RadioGroup
                    aria-label="tax"
                    name="controlled-tax-options-group"
                    value={taxChoice}
                    onChange={(e) => {handleTaxChoice(e.target.value)}}
                    required
                  >
                    <FormControlLabel value="auto" control={<Radio size="small" color="primary" required />} label={ t("spot-attributes.tax.auto") } classes={{ label: classes.taxRadio }} />
                    <Typography variant="body1" className={clsx(classes.formDescription, classes.taxChoiceSubtext)}>{ t("spot-attributes.tax.auto-sub-text") }</Typography>
                    <FormControlLabel value="specify" control={<Radio size="small" color="primary" required />} label={ t("spot-attributes.tax.specify") } classes={{ label: classes.taxRadio }} />
                    <Typography variant="body1" className={clsx(classes.formDescription, classes.taxChoiceSubtext)}>{ t("spot-attributes.tax.specify-sub-text") }</Typography>
                    <Box ml={2.75} mt={1} mb={1.5} display={taxChoice === "specify" ? "flex" : "none"} flexDirection="column" className={classes.manualTaxFieldBox}>
                      <RadioGroup
                        aria-label="tax"
                        name="manual-tax-options-group"
                        value={manualTaxChoice}
                        onChange={(e) => {handleManualTaxChoice(e.target.value)}}
                        required
                      >
                        <Box>
                          <FieldWithoutLabel
                            ref={taxAmountRef}
                            name="taxAmount"
                            placeholder={ t("common.placeholders.type-tax") }
                            control={control}
                            step="any"
                            type="number"
                            required={!!(taxChoice === "specify")}style={{paddingBottom: "1rem"}}
                          />
                        </Box>
                        <FormControlLabel value="flat" control={<Radio size="small" color="primary" required />} label={ t("spot-attributes.tax.flat") } classes={{ label: classes.taxRadio }} />
                        <FormControlLabel value="per_person" control={<Radio size="small" color="primary" required />} label={ t("spot-attributes.tax.per-person") } classes={{ label: classes.taxRadio }} />
                      </RadioGroup>
                    </Box>
                    <FormControlLabel value="zero" control={<Radio size="small" color="primary" required />} label={ t("spot-attributes.tax.zero") } classes={{ label: classes.taxRadio }} />
                    <Typography variant="body1" className={clsx(classes.formDescription, classes.taxChoiceSubtext)}>{ t("spot-attributes.tax.zero-sub-text") }</Typography>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box style={{ display: activeTab === "additional" ? "block" : "none" }}>
            <Box>
              <Typography variant="h3">{ t("parking-spot.form-section.parking-space-spots") } *</Typography>
              <Typography variant="body1" className={classes.formDescription}>{ t("parking-spot.form-section.parking-space-spots-sub-text") }</Typography>
              <FieldWithoutLabel
                ref={spotsRef}
                name="parkingSpaceSpots"
                placeholder={ t("common.placeholders.parked-capacity") }
                control={control}
                required
                type="number"
              />
            </Box>
            <Box pt={6}>
              <Typography variant="h3">{ t("address.title") } *</Typography>
              <Typography variant="body1" className={classes.formDescription}>{ t("parking-spot.form-section.address-sub-text") }</Typography>
              {
                <Box pt={2}>
                  <Geosuggest
                    placeDetailFields={[]}
                    placeholder="Location"
                    className={classes.geo}
                    initialValue={(location.address && location.address.length < 1 && location.address.formatted_address == null) ? "" : location.address.formatted_address}
                    inputClassName={classes.geoInputEdit}
                    suggestsClassName={classes.geoSuggestsEdit}
                    suggestsHiddenClassName={classes.geoSuggestsHidden}
                    suggestItemClassName={classes.geoSuggestItem}
                    suggestItemActiveClassName={classes.geoSuggestItemActive}
                    autoActivateFirstSuggest={true}
                    onSuggestSelect={(suggestion) => suggestion && suggestion.location && handleLocationChange(suggestion.location)}
                  />
                </Box>
              }
              <Box pt={2}>
                {
                  ((location && location.geolocation !== null)) &&
                  <FormMap geolocation={location.geolocation} />
                }
              </Box>
            </Box>
            <Box pt={6}>
              <Typography variant="h3">{ t("common.labels.email") }</Typography>
              <Typography variant="body1" className={classes.formDescription}>{ t("parking-spot.form-section.email-sub-text") }</Typography>
              <FieldWithoutLabel
                name="contactEmail"
                placeholder={ t("common.placeholders.ps-email") }
                control={control}
                type="text"
                limit={256}
              />
            </Box>
          </Box>
          <Box style={{ display: activeTab === "attributes" ? "block" : "none" }}>
            <Box>
              <Typography variant="h3">{ t("spot-attributes.host-information-category.title") }</Typography>
              <Box pt={3}>
                <CheckboxFieldset
                  reset={reset}
                  getValues={getValues}
                  multiple={multipleFor("Place Type", parkingSpotAttributes)}
                  groupLabel={ t("spot-attributes.host-information-category.box.host.box-title") + ': * ' }
                  description={t("spot-attributes.host-information-category.box.host.sub-text")}
                  fields={[{
                    name: "rvParking",
                    label: t("spot-attributes.host-information-category.box.host.box-content.rv-parking"),
                  }, {
                    name: "campsiteSpot",
                    label: t("spot-attributes.host-information-category.box.host.box-content.at-campsite"),
                  }, {
                    name: "privateSpot",
                    label: t("spot-attributes.host-information-category.box.host.box-content.at-private-house"),
                  }, {
                    name: "railwaySpot",
                    label: t("spot-attributes.host-information-category.box.host.box-content.at-mountain-railway"),
                  }, {
                    name: "restaurantParkingSpot",
                    label: t("spot-attributes.host-information-category.box.host.box-content.at-restaurant-parking"),
                  }, {
                    name: "farmhouseSpot",
                    label: t("spot-attributes.host-information-category.box.host.box-content.at-farmhouse"),
                  }, {
                    name: "natureSpot",
                    label: t("spot-attributes.host-information-category.box.host.box-content.nature-spot"),
                  }, {
                    name: "atMarina",
                    label: t("spot-attributes.host-information-category.box.host.box-content.at-marina"),
                  }]}
                  control={control}
                />
              </Box>
              <Box pt={3}>
                <CheckboxFieldset
                  reset={reset}
                  getValues={getValues}
                  multiple={multipleFor("Behaviour", parkingSpotAttributes)}
                  groupLabel={ t("spot-attributes.host-information-category.box.behaviour.box-title") + ': * ' }
                  description={t("spot-attributes.host-information-category.box.behaviour.sub-text")}
                  fields={[{
                    name: "campingBehaviour",
                    label: t("spot-attributes.host-information-category.box.behaviour.box-content.camping-behaviour"),
                  }, {
                    name: "foldingChairBehaviour",
                    label: t("spot-attributes.host-information-category.box.behaviour.box-content.folding-chair-behaviour"),
                  }, {
                    name: "parkingBehaviour",
                    label: t("spot-attributes.host-information-category.box.behaviour.box-content.only-parking-behaviour"),
                  }]}
                  control={control}
                />
              </Box>
              <Box pt={3}>
                <CheckboxFieldset
                  reset={reset}
                  getValues={getValues}
                  multiple={multipleFor("Autarchy", parkingSpotAttributes)}
                  groupLabel={ t("spot-attributes.host-information-category.box.autarchy.box-title") + ':' }
                  description={t("spot-attributes.host-information-category.box.autarchy.sub-text")}
                  fields={[{
                    name: "onboardToiletRequired",
                    label: t("spot-attributes.host-information-category.box.autarchy.box-content.onboard-toilet-required"),
                  }]}
                  control={control}
                />
              </Box>
            </Box>
            <Box pt={6}>
              <Typography variant="h3">{ t("spot-attributes.place-properties-category.title") }</Typography>
              <Box pt={3}>
                <CheckboxFieldset
                  reset={reset}
                  getValues={getValues}
                  multiple={multipleFor("Car size", parkingSpotAttributes)}
                  groupLabel={ t("spot-attributes.place-properties-category.box.vehicle.box-title") + ': * ' }
                  description={t("spot-attributes.place-properties-category.box.vehicle.sub-text")}
                  fields={[{
                    name: "upTo6",
                    label: t("spot-attributes.place-properties-category.box.vehicle.box-content.up-to-6-meters"),
                  }, {
                    name: "upTo8",
                    label: t("spot-attributes.place-properties-category.box.vehicle.box-content.up-to-8-meters"),
                  }, {
                    name: "upTo12",
                    label: t("spot-attributes.place-properties-category.box.vehicle.box-content.up-to-12-meters"),
                  }]}
                  control={control}
                />
              </Box>
              <Box pt={3}>
                <CheckboxFieldset
                  reset={reset}
                  getValues={getValues}
                  multiple={multipleFor("Approach", parkingSpotAttributes)}
                  groupLabel={ t("spot-attributes.place-properties-category.box.approach.box-title") + ':' }
                  description={t("spot-attributes.place-properties-category.box.approach.sub-text")}
                  fields={[{
                    name: "narrow",
                    label: t("spot-attributes.place-properties-category.box.approach.box-content.road-access-narrow-<-2m"),
                  }, {
                    name: "only4x4",
                    label: t("spot-attributes.place-properties-category.box.approach.box-content.4x4-empfohlen"),
                  }]}
                  control={control}
                />
              </Box>
              <Box pt={3}>
                <CheckboxFieldset
                  reset={reset}
                  getValues={getValues}
                  multiple={multipleFor("Terrain", parkingSpotAttributes)}
                  groupLabel={ t("spot-attributes.place-properties-category.box.terrain.box-title") + ': * ' }
                  description={t("spot-attributes.place-properties-category.box.terrain.sub-text")}
                  fields={[{
                    name: "asphalt",
                    label: t("spot-attributes.place-properties-category.box.terrain.box-content.asphalt"),
                  }, {
                    name: "gravel",
                    label: t("spot-attributes.place-properties-category.box.terrain.box-content.gravel"),
                  }, {
                    name: "grass",
                    label: t("spot-attributes.place-properties-category.box.terrain.box-content.gras"),
                  }]}
                  control={control}
                />
              </Box>
              <Box pt={3}>
                <CheckboxFieldset
                  reset={reset}
                  getValues={getValues}
                  multiple={multipleFor("Season", parkingSpotAttributes)}
                  groupLabel={ t("spot-attributes.place-properties-category.box.season.box-title") + ':' }
                  description={t("spot-attributes.place-properties-category.box.season.sub-text")}
                  fields={[{
                    name: "winter",
                    label: t("spot-attributes.place-properties-category.box.season.box-content.winter-place"),
                  }]}
                  control={control}
                />
              </Box>
            </Box>
            <Box pt={6}>
              <Typography variant="h3">{ t("spot-attributes.service-category.title") }</Typography>
              <Box pt={3}>
                <CheckboxFieldset
                  reset={reset}
                  getValues={getValues}
                  multiple={multipleFor("Service", parkingSpotAttributes)}
                  groupLabel={ t("spot-attributes.service-category.box.services.box-title") + ':' }
                  description={ t("spot-attributes.service-category.box.services.sub-text")}
                  fields={[{
                    name: "wcService",
                    label: t("spot-attributes.service-category.box.services.box-content.wc"),
                  }, {
                    name: "powerSupplyService",
                    label: t("spot-attributes.service-category.box.services.box-content.power-supply"),
                  }, {
                    name: "freshWaterService",
                    label: t("spot-attributes.service-category.box.services.box-content.fresh-water"),
                  }, {
                    name: "showerService",
                    label: t("spot-attributes.service-category.box.services.box-content.shower"),
                  }, {
                    name: "wifiService",
                    label: t("spot-attributes.service-category.box.services.box-content.wifi"),
                  }, {
                    name: "cassetteDisposalService",
                    label: t("spot-attributes.service-category.box.services.box-content.toilet-cassette-disposal"),
                  }, {
                    name: "waterDisposalService",
                    label: t("spot-attributes.service-category.box.services.box-content.grey-water-disposal"),
                  }, {
                    name: "wasterDisposalService",
                    label: t("spot-attributes.service-category.box.services.box-content.waste-disposal"),
                  }, {
                    name: "firePlaceService",
                    label: t("spot-attributes.service-category.box.services.box-content.fire-place"),
                  }, {
                    name: "farmShopService",
                    label: t("spot-attributes.service-category.box.services.box-content.farm-shop"),
                  }, {
                    name: "camperDinnerService",
                    label: t("spot-attributes.service-category.box.services.box-content.camper-dinner"),
                  }, {
                    name: "laundryService",
                    label: t("spot-attributes.service-category.box.services.box-content.laundry"),
                  }]}
                  control={control}
                />
              </Box>
            </Box>
            <Box pt={6}>
              <Typography variant="h3">{ t("spot-attributes.surrounding-category.title") }</Typography>
              <Box pt={3}>
                <CheckboxFieldset
                  reset={reset}
                  getValues={getValues}
                  multiple={multipleFor("Surrounding", parkingSpotAttributes)}
                  groupLabel={ t("spot-attributes.surrounding-category.box.surroundings.box-title") + ':' }
                  fields={[{
                    name: "nearRestaurant",
                    label: t("spot-attributes.surrounding-category.box.surroundings.box-content.near-restaurant"),
                  }, {
                    name: "protectedArea",
                    label: t("spot-attributes.surrounding-category.box.surroundings.box-content.protected-area"),
                  }, {
                    name: "nearShopping",
                    label: t("spot-attributes.surrounding-category.box.surroundings.box-content.near-shopping"),
                  }, {
                    name: "nearPlayground",
                    label: t("spot-attributes.surrounding-category.box.surroundings.box-content.near-playground"),
                  }, {
                    name: "dogFriendly",
                    label: t("spot-attributes.surrounding-category.box.surroundings.box-content.dog-friendly"),
                  }, {
                    name: "nearMountains",
                    label: t("spot-attributes.surrounding-category.box.surroundings.box-content.near-mountains"),
                  }, {
                    name: "nearWater",
                    label: t("spot-attributes.surrounding-category.box.surroundings.box-content.near-waters"),
                  }, {
                    name: "nearSki",
                    label: t("spot-attributes.surrounding-category.box.surroundings.box-content.near-ski-slopes"),
                  }, {
                    name: "hikingArea",
                    label: t("spot-attributes.surrounding-category.box.surroundings.box-content.hiking-area"),
                  }, {
                    name: "bicycleSurrounding",
                    label: t("spot-attributes.surrounding-category.box.surroundings.box-content.bicycle"),
                  }]}
                  control={control}
                />
              </Box>
            </Box>
            <Box pt={6}>
              <Typography variant="h3">{ t("spot-attributes.availability-category.box.availability.box-title") }</Typography>
              <Box pt={3}>
                <CheckboxFieldset
                  reset={reset}
                  getValues={getValues}
                  multiple={multipleFor("Availability", parkingSpotAttributes)}
                  description={t("spot-attributes.availability-category.box.availability.sub-text")}
                  fields={[{
                    name: "monday",
                    label: t("spot-attributes.availability-category.box.availability.box-content.monday"),
                  }, {
                    name: "tuesday",
                    label: t("spot-attributes.availability-category.box.availability.box-content.tuesday"),
                  }, {
                    name: "wednesday",
                    label: t("spot-attributes.availability-category.box.availability.box-content.wednesday"),
                  }, {
                    name: "thursday",
                    label: t("spot-attributes.availability-category.box.availability.box-content.thursday"),
                  }, {
                    name: "friday",
                    label: t("spot-attributes.availability-category.box.availability.box-content.friday"),
                  }, {
                    name: "saturday",
                    label: t("spot-attributes.availability-category.box.availability.box-content.saturday"),
                  }, {
                    name: "sunday",
                    label: t("spot-attributes.availability-category.box.availability.box-content.sunday"),
                  }]}
                  control={control}
                />
              </Box>
            </Box>
          </Box>
          <Box pt={3} display="flex" flexDirection={isSmallMobile ? "column" : "row"}>
            {
              activeTab !== "attributes" &&
              <Box className={classes.buttonWrapper}>
                <Button variant="contained" color="primary" id="continueButton" fullWidth onClick={(event) => submitInterceptor(false)} disabled={disabled}>
                  { t("common.btn.save-continue") }
                </Button>
                { continuing && <CircularProgress size={24} className={classes.buttonProgress} /> }
              </Box>
            }
            <Box className={classes.buttonWrapper} ml={isSmallMobile || activeTab === "attributes" ? 0 : 2}>
              <Button variant="outlined" id="finshButton" fullWidth onClick={(event) => submitInterceptor(true)} disabled={disabled}>
                { t("common.btn.save-exit") }
              </Button>
              { finishing && <CircularProgress size={24} className={classes.buttonProgress} /> }
            </Box>
          </Box>
        </Box>
      </form>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.user,
    updatingGalleryImage: state.parkingSpot.updatingGalleryImage,
    parkingSpot: state.parkingSpot.parkingSpotDetails,
    parkingSpotAttributes: state.parkingSpot.parkingSpotAttributes,
    parkingSpotAttributesMap: state.parkingSpot.parkingSpotAttributesMap,
    fetchingParkingSpotAttributes: state.parkingSpot.fetchingParkingSpotAttributes,
    location: state.parkingSpot.editParkingSpot,
    creatingParkingSpot: state.parkingSpot.creatingParkingSpot,
    updatingParkingSpot: state.parkingSpot.updatingParkingSpot,
    continuing: state.parkingSpot.continuing,
    finishing: state.parkingSpot.finishing,
  };
};

export default connect(mapStateToProps, { fetchParkingSpotAttributes, postParkingSpot, patchParkingSpot, fetchParkingSpotDetails, postGalleryImage, incrementGalleryImageLoadingCounter, deleteGalleryImage, enqueueSnackbar, setGeolocationEditParkingSpot, setCreatingParkingSpot, setContinuing, setFinishing, setUpdatingParkingSpot, pushToRoot, addLoader })(ParkingSpotForm);
