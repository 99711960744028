import React, { useState, useEffect } from 'react';
import { Box, Button, useTheme, useMediaQuery, Typography, SvgIcon} from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import { useStyles } from '../../common/styles';
import clsx from 'clsx';
import { ReactComponent as MapPinSVG } from '../../../../assets/images/map-pin.svg';

const MapPin = ({color, ...rest}) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let pinColor = null;
  if (color === "green"){
    pinColor = clsx(classes.greenPin, classes.pin);
  }
  else if (color === "yellow"){
    pinColor = clsx(classes.yellowPin, classes.pin);
  }
  else if (color === "red"){
    pinColor = clsx(classes.redPin, classes.pin);
  }
  else {
    pinColor = clsx(classes.greyPin, classes.pin);
  }

  return(
    <MapPinSVG className={pinColor} />
  )
};

const GeoMap = ({lat, lng, pinColor, ...rest}) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [center, setCenter] = useState([lat, lng]);
  const [zoom, setZoom] = useState(13);

  useEffect(() => {
    setCenter([lat, lng]);
  }, [lat, lng])

  const mapStyles = [
    {
      "featureType": "landscape.natural",
      "elementType": "labels.icon",
      "stylers": [
          {
            "visibility": "off"
          }
      ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#e9e9e9"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#deebd8"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#c4e5f3"
            },
            {
                "visibility": "on"
            }
        ]
    }
  ];

  return (
    <GoogleMapReact
      center={center}
      zoom={zoom}
      options={{styles: mapStyles, disableDefaultUI: true}}
    >
      <MapPin
        lat={lat}
        lng={lng}
        color={pinColor}
      />
    </GoogleMapReact>
  );
};

const DetailsMap = ({lat, lng, spot, ...rest}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  let pinColor = null;
  if (!!spot.available_today){
    if (spot.capacity > 0 && (spot.occupied/spot.capacity) < 0.66){
      pinColor = "green";
    }
    else if (spot.capacity > 0 && (spot.occupied/spot.capacity) < 1){
      pinColor = "yellow";
    }
    else {
      pinColor = "red";
    }
  }
  else{
    pinColor = "grey";
  }

  return(
    <Box display="flex" className={classes.mapDetailContainer}>
      <Box className={classes.mapBoxDetails} position="relative">
        <Box width='100%' height={isDesktop ? '32rem' : '24rem'}>
          <GeoMap lat={lat} lng={lng} pinColor={pinColor} />
        </Box>
      </Box>
    </Box>
  )
};

export default DetailsMap;
