import { SvgIcon } from "@material-ui/core";
import React from "react";

function CheckIcon(props) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <path
        d="M5.99998 10.78L3.68999 8.47002C3.43014 8.21018 3.00908 8.20943 2.74832 8.46836V8.46836C2.48625 8.72858 2.48551 9.15221 2.74665 9.41335L5.29288 11.9596C5.6834 12.3501 6.31656 12.3501 6.70709 11.9596L13.5299 5.1368C13.7895 4.87716 13.7895 4.45621 13.5299 4.19657V4.19657C13.2703 3.93703 12.8495 3.93693 12.5899 4.19635L5.99998 10.78Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default CheckIcon;
