import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useStyles } from "components/dashboard/common/styles";
import React from "react";
import { useTranslation } from "react-i18next";

function BalanceWithdrawConfirmDialog({
  open,
  onClose,
  onConfirm,
  iban,
  withdrawing,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paperWidthSm: classes.paperWidthSm,
      }}
    >
      <DialogTitle
        className={classes.dialogTitle}
        classes={{
          root: classes.dialogTitleRoot,
        }}
      >
        <Typography variant="h3">
          {t("financial.withdraw-confirmation")}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          <Typography variant="body1">
            {t("financial.pop-up.confirmation-msg")}
          </Typography>
          <Box mt={2}>
            <Typography variant="body1">**** **** **** *{iban.substring(0, 3)} {iban[iban.length -1]}</Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box mr={1} position="relative" flex="auto">
          <Button
            variant="contained"
            color="primary"
            onClick={onConfirm}
            fullWidth={true}
            disabled={withdrawing}
          >
            <Typography variant="button">
              {t("financial.pop-up.btn-withdraw")}
            </Typography>
          </Button>
          {withdrawing && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Box>
        <Box flex="auto">
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
            fullWidth={true}
          >
            <Typography variant="button">{t("common.btn.cancel")}</Typography>
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default BalanceWithdrawConfirmDialog;
