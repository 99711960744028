import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  photoPlaceholder: {
    height: "100%",
    borderRadius: "1rem",
  },
  galleryCols: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    background: "#fff",
    paddingTop: theme.spacing(4),
    height: "100%",
  },
  galleryColCompact: {
    "&:nth-last-child(2)": {
      paddingLeft: `${theme.spacing(4) * 2/3}px !important`,
    },
    "&:last-child": {
      paddingLeft: `${theme.spacing(4) * 2/3}px !important`,
    },
    "&:first-child": {
      paddingRight: `${theme.spacing(4) * 2/3}px !important`,
    },
  },
  galleryColMedium: {
    width: "50%",
  },
  galleryColSmall: {
    width: "25%",
  },
  galleryCol: {
    overflow: "hidden",
    "&:first-child": {
      height: "100%",
    },
    "&:not(:nth-last-child(2)):not(:last-child)": {
      paddingRight: theme.spacing(4)/2,
    },
    "&:nth-child(2n)": {
      paddingBottom: theme.spacing(4)/2,
    },
    "&:nth-child(2n+1):not(:first-child)": {
      paddingTop: theme.spacing(4)/2,
    },
    "&:not(:first-child)": {
      paddingLeft: theme.spacing(4)/2,
    },
    "&:not(:first-child):not(:nth-last-child(2)):not(:last-child)": {
      paddingLeft: `${theme.spacing(4)/3}px !important`,
      paddingRight: `${theme.spacing(4)/3}px !important`,
    },
    "& img": {
      borderRadius: "1rem",
      height: "100% !important",
    },
    height: "50%",
  },
  adornmentText: {
    marginTop: "0.125rem",
    marginRight: "0.5rem",
    fontSize: "12px",
  },
  dialogImages: {
    paddingLeft: "1.5rem !important",
    paddingRight: "1.5rem !important",
    paddingTop: "1.5rem !important",
    paddingBottom: "1.5rem !important",
    display: "flex",
    flexDirection: "column",
    "& img:not(:first-child)": {
      marginTop: "1.5rem",
    },
    "& img": {
      height: "auto",
      borderRadius: "0.8rem",
    }
  },
  dialogTitleBorderBottom: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    borderBottom: "1px solid #E7F0F0",
  },
  dialogTitleFullScreen: {
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: 0,
    zIndex: 999,
  },
  dialogTitleRoot: {
    '& h2': {
      fontWeight: "500",
      fontSize: theme.typography.h6.fontSize,
    }
  },
  galleryControlsDesktop: {
    position: "absolute !important",
    right: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      bottom: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      transform: "translateY(-50%)",
      right: "1.6875rem",
      top: "86%",
    },
  },
  galleryControls: {
    position: "absolute !important",
    top: "86%",
    transform: "translateY(-50%)",
    right: "5.7rem",
    [theme.breakpoints.down("sm")]: {
      right: "24px",
    },
  },
  backButton: {
    marginRight: "0.5rem",
  },
  forwardButton: {
    marginRight: "1rem",
  },
  expandButton: {
  },
  fieldRow: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  textField: {
    width: "100%",
  },
  textFieldWrapper: {
    marginTop: "1.5rem",
    marginRight: "1rem",
    width: "100%",
    "&:last-child": {
      marginRight: "0rem",
    },
  },
  textFieldWrapperNoMargin: {
    marginTop: "0rem",
    marginRight: "0rem",
    width: "100%",
    "&:last-child": {
      marginRight: "0rem",
    },
  },
  input: {
    backgroundColor: "#fff",
    border: "1px solid #E7F0F0",
    borderRadius: "12px",
    width: "100%",
    "&:hover": {
      backgroundColor: "#fff",
      border: "1px solid #E7F0F0",
      borderRadius: "12px",
    },
  },
  inputStripe: {
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "12px",
    "& > .StripeElement": {
      padding: "18.5px 14px 14px 14px !important",
    },
    width: "100%",
    "&:hover": {
      backgroundColor: "#fff",
      border: "none",
      borderRadius: "12px",
    },
  },
  focused: {
    backgroundColor: "#fff !important",
  },
  disabled: {
    backgroundColor: "transparent !important",
  },
  telCodeSelect: {
    width:"6rem",
    marginRight: "0.5rem",
    marginLeft: "0.5rem"
  },
  progressBottom: {
    color: theme.palette.grey[300],
  },
  progressTop: {
    position: "absolute",
    left: 0,
  },
  multilineCounter: {
    marginTop: "-2rem",
    marginRight: "0.8rem",
    marginBottom: "2.1rem",
  },
  galleryButtonBack: {
    cursor: "pointer",
    position: "absolute !important",
    left: "5%",
    top: "50%",
    transform: "translateY(-50%) translateX(-10%)",
    color: theme.palette.secondary.dark,
    fontSize: "1.25rem",
    border: `0.125rem solid ${theme.palette.secondary.light}`,
    borderRadius: "1.0rem",
    background: theme.palette.secondary.light,
    zIndex: 9,
    paddingLeft: "0.225rem",
    [theme.breakpoints.down("sm")]:{
      paddingLeft: "0.375rem",
      fontSize: "1.625rem",
      top: "51%",
      display: "none"
    },
  },
  galleryButtonForward: {
    cursor: "pointer",
    position: "absolute !important",
    right: "5%",
    top: "50%",
    transform: "translateY(-50%) translateX(10%)",
    color: theme.palette.secondary.dark,
    fontSize: "1.25rem",
    border: `0.125rem solid ${theme.palette.secondary.light}`,
    borderRadius: "1.0rem",
    background: theme.palette.secondary.light,
    zIndex: 9,
    paddingLeft: "0.1rem",
    paddingRight: "0.1rem",
    [theme.breakpoints.down("sm")]:{
      fontSize: "1.625rem",
      top: "51%",
      paddingLeft: "0.1875rem",
      paddingRight: "0.1875rem",
      display: "none"
    },
  },
  galleryCounter: {
    position: "absolute !important",
    right: "1.6875rem",
    transform: "translateY(50%)",
    bottom: "86%",
    color: "#fff",
    fontSize: "1.25rem",
    borderRadius: "1.0rem",
    background: theme.palette.secondary.dark,
    zIndex: 9,
    padding: "0.25rem 0.675rem",
    minWidth: "2.5625rem",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.0rem",
      right: "0.5rem",
      top: "0.5rem",
      padding: "0.125rem 0.375rem",
      minWidth: "2rem",
      display: "none"
    },
  },
  parkingSpotImageWrapper: {
    background: "#E7F0F0",
    width: "100%",
    overflow: "hidden",
    minHeight: "10rem",
    transform: "translateZ(0)",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flex: 2,
      minHeight: "15rem",
    },
  },
  controlledImageWrapper: {
    background: "#E7F0F0",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    minHeight: "10rem",
    transform: "translateZ(0)",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flex: 2,
      minHeight: "15rem",
    },
  },
  paymentFailedBubble: {
    background: "#f24243",
    marginLeft: "0.7rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderRadius: "6rem"
  },
  paymentFailedBubbleMobile: {
    background: "#f24243",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingTop: "0.1rem",
    paddingBottom: "0.2rem",
    borderRadius: "6rem",
    marginBottom: "0.7rem",
    maxWidth: "6.5rem"
  },
  refreshPaymentBtn: {
    marginLeft: "1rem",
    display: "flex",
    textDecoration: "none",
  },
  refreshPaymentBtnMobile: {
    display: "flex",
    textDecoration: "none",
  },
  refreshPaymentBtnText: {
    cursor: "pointer",
    "&:hover": {
      color: `${theme.palette.primary.main}`,
    },
  },
}));
