const styles = (theme) => ({
  outlineButtonPressed: {
    backgroundColor: "#DFF0F2",
    color: "#000 !important",
  },
  menuStyles: {
    borderRadius: "12px",
    padding: 0,
    marginTop: "1em",
    overflow: "hidden",
    minWidth: "unset",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.6rem",
      minWidth: "calc(100vw - 3rem)",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "1.6rem",
      minWidth: "calc(100vw - 3rem)",
    },
  },
  languageStartIcon: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
    }
  },
  menuIcon: {
    minWidth: "2.5rem",
  },
});

export default styles;
