import React from 'react';
import { Box, Typography, Card } from '@material-ui/core';
import { Controller } from "react-hook-form";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';

import { useStyles } from './styles';

const CheckboxFieldset = ({ groupLabel, multiple, singleColumn, fields, control, reset, getValues, description }) => {
  const classes = useStyles();
  return (
    <Card elevation={0} style={{
      borderBottom: "1px solid #E7F0F0",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      overflow: "hidden",
      borderRadius: "0",
    }}>
      <Box display="flex" pb={2}>
        <FormControl component="fieldset" className={classes.formControl}>
          {groupLabel && (
            <FormLabel component="legend">
              <Typography variant="body1" className={classes.formLabel}>
                { groupLabel }
              </Typography>
            </FormLabel>
          )}
          {description && (
            <Typography variant="body1" className={classes.formDescription}>
            { description }
            </Typography>
          )}
          <FormGroup className={classes.formGroup}>
            {fields.map((field, index) => {
              return (
                <FormControlLabel
                  className={clsx(classes.formControlLabel, singleColumn && "fullWidth")}
                  key={`checkbox-${field.name}-${index}`}
                  control={
                    <Controller
                      name={field.name}
                      control={control}
                      render={({ onChange, onBlur, value, ...rest }) => (
                        <Checkbox
                          disableRipple
                          onBlur={onBlur}
                          checked={value}
                          onChange={(e) => {
                            if (reset && !multiple) {
                              if (!getValues()[`${e.target.name}`]) {
                                reset({
                                  ...getValues(),
                                  ...(Object.fromEntries(
                                    fields.map(f => [f.name, (f.name === field.name)])
                                  ))
                                });
                              }
                              else{
                                reset({
                                  ...getValues(),
                                  ...(Object.fromEntries(
                                    fields.map(f => [f.name, false])
                                  ))
                                });
                              }
                            } else {
                              onChange(e.target.checked);
                            }
                          }}
                          {...rest}
                        />
                      )}
                    />
                  }
                  label={field.label}
                  classes={{label: classes.label}}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Box>
    </Card>
  );
};

export default CheckboxFieldset;
