const round = (value, decimals = 2) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(
    decimals
  );
};

export const amountForCurrency = (amount, currency) => {
  return currency + " +" + round(amount, 2);
};

const cellWidths = {
  name: 240,
  email: 240,
  phoneNumber: 226,
  date: 161,
  status: 183,
  link: 108,
};

export function getReferralHistoryTableCellWidth(cellName) {
  return cellWidths[cellName] || 0;
}

const referralStatusColors = {
  open: {
    backgroundColor: "#E9EAEC",
    color: "#8E8E99",
  },
  "payment_ready": {
    backgroundColor: "#DFECFF",
    color: "#00407A",
  },
  expired: {
    backgroundColor: "#FFF2F2",
    color: "#F24243",
  },
  success: {
    backgroundColor: "#E9FFDF",
    color: "#83BE6E",
  },
  pending: {
    backgroundColor: "#FFF5DF",
    color: "#F7C963",
  },
};

export function getReferralStatusColor(status) {
  return referralStatusColors[status?.toLowerCase()];
}
