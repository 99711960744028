import { ofType } from 'redux-observable';
import { mergeMap, flatMap, catchError } from 'rxjs/operators';

import * as Types from '../actions/constants';
import { BACKEND_API } from './hosts';
import API from '../../lib/api';
import * as VoucherActions from '../actions/voucher';


export const fetchVoucherDetails = action$ =>
action$.pipe(
  ofType(Types.FETCH_VOUCHER_DETAILS),
  mergeMap((action) => {
    return API.get(`/guests/vouchers/${action.voucherCode}/apply?ps_currency=${action.psCurrency}`, { token: action.token, host: BACKEND_API }).pipe(
      flatMap((response) => {
        return [
          VoucherActions.setApplyingVoucher(false),
          VoucherActions.setAppliedVoucher(response.data)
        ];
      }),
      catchError(error => {
        console.log(`Could not fetch voucher details: ${error.message}`);
        return [
          ...API.notificationsForErrorResponse(error),
          VoucherActions.setApplyingVoucher(false),
          VoucherActions.setAppliedVoucher(null)
        ];
      })
    )
  })
);