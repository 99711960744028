import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';
import Menu from '@material-ui/core/Menu';
import { Box, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import flags from '../flags';
import styles from './styles';
import MenuWithDropdown from './menu';
import logo from '../../../assets/images/logo.svg';
import Geosuggest from 'react-geosuggest';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as SearchIcon } from '../../../assets/images/search-icon.svg';
import { Link, useLocation, useHistory } from "react-router-dom";

import Typography from '@material-ui/core/Typography';
import NavButton from '../buttons/navButton';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkIcon from '@material-ui/icons/Link';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';

import {fetchHostProfile, fetchGuestProfile} from "../../../data/actions/user";
import { setExternalSearchLocation, setFocusedSpot, setFilteredParkingSpots } from '../../../data/actions/parkingSpot';
import { push } from 'connected-react-router';
import { useMenuState } from '@szhsin/react-menu';
import LanguageSelector from '../languageSelector';

const useStyles = makeStyles((theme) => styles(theme));
const INTERCOM_APP_ID = 'js400j02';

const StyledMenu = withStyles({
})((props) => (
  <Menu
    MenuListProps={{ disablePadding: true }}
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const Header = ({token,
  activeLanguage,
  languages,
  user,
  currentURL,
  expanded,
  locationFilters,
  setExternalSearchLocation,
  setFilteredParkingSpots,
  isDetailsPage=null,
  isSearchPage=null,
  parkingSpot,
  searchStringGlobal,
  push,
  setFocusedSpot,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  let isDesktopView = useMediaQuery(theme.breakpoints.up('md'));
  const [menuState, setMenuState] = useState("closed");
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const { toggleMenu, ...menuProps } = useMenuState({ transition: true });
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { userType } = user;

  let urlParts = null;
  let queryString = null; // Used to set login/signup button links with ps tag set in query params
  if (currentURL && currentURL.length > 0){
    urlParts = currentURL.split("/");
    if (urlParts && urlParts[1] && urlParts[1] === "ps"){
      queryString =  `?tag=${urlParts[2]}`
    }
  }

  useEffect(() => {
    if (token != null && userType === "Guest") {
      fetchGuestProfile(token);
    } else if (token != null && userType === "Host") {
      fetchHostProfile(token);
    }
  }, [userType, fetchGuestProfile, fetchHostProfile, token]);

  const [closeSearch, setCloseSearch] = useState(false);
  const [searchString, setSearchString] = useState(null);
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const navMobileMenuOpen = Boolean(mobileAnchorEl);

  const lat = parkingSpot && parkingSpot.geolocation ? parseFloat(parkingSpot.geolocation.substring(parkingSpot.geolocation.lastIndexOf(" ") + 1, parkingSpot.geolocation.lastIndexOf(")"))) : 0;
  const lng = parkingSpot && parkingSpot.geolocation ? parseFloat(parkingSpot.geolocation.substring(parkingSpot.geolocation.indexOf("(") + 1, parkingSpot.geolocation.lastIndexOf(" "))) : 0;

  const handleShareButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShareButtonClose = (event) => {
    setAnchorEl(null);
  };

  const handleMobileShareButtonClick = (event) => {
    setMobileAnchorEl(event.currentTarget);
  };

  const handleMobileShareButtonClose = (event) => {
    setMobileAnchorEl(null);
  };

  const geosuggestEl = React.useRef(null);
  const handleSuggestInputChange = (value) => {
    value && setSearchString(value);
    if (value === ""){
      setExternalSearchLocation(null);
      setCloseSearch(false);
    }
    else if (!closeSearch) {
      setCloseSearch(true);
    }
  }
  const handleLocationChange = (location, description) => {
    setExternalSearchLocation([location.lat, location.lng]);
    setFilteredParkingSpots({
      parking_spots: [],
      pagy: null
    });
    push(`/?search=${description ? description : searchString}`);
  };
  const handleCloseSearch = () => {
    setExternalSearchLocation(null);
    geosuggestEl.current.clear()
    setCloseSearch(false);
  }

  return (
    <>
      <AppBar position="static" color="transparent" className={classes.appBar}>
        {
          (!!isMobile && !!isDetailsPage) ? (
            <Toolbar className={classes.toolbar} width="100%">
              <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
                <Box display="flex" alignItems="center" maxWidth="50%" flex="auto" style={{cursor: "pointer"}} onClick={() => {
                  setFocusedSpot(urlParts[2]);
                  push(`/?search=${searchStringGlobal || ''}`);
                }}>
                  <ArrowBackIos className={classes.backIcon} />
                  <Typography variant={searchStringGlobal ? "body1" : "body2"} color={"secondary"} className={classes.previousSearch}>
                    {searchStringGlobal ? t("details.all-results-in") : t("details.back-to-search")} {!!searchStringGlobal && searchStringGlobal}
                  </Typography>
                </Box>
                { !!lat && !!lng &&
                  <Box display="flex" minWidth="5.75rem">
                    <Button variant="outlined" size="small" className={classes.buttonXS} onClick={handleMobileShareButtonClick}>
                      <ShareRoundedIcon className={classes.cardIcons} />
                    </Button>
                    <StyledMenu
                      anchorEl={mobileAnchorEl}
                      keepMounted
                      open={navMobileMenuOpen}
                      onClose={handleMobileShareButtonClose}
                      className={classes.shareMenu}
                    >
                      <a
                        className={classes.socialMenuItem}
                        href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                        target="_blank"
                      >
                        <MenuItem>
                          <FacebookIcon className={classes.socialLinkIcon} />
                          <ListItemText primary="Facebook"/>
                        </MenuItem>
                      </a>
                      <a className={classes.socialMenuItem} >
                        <MenuItem onClick={() => {navigator.clipboard.writeText(window.location.href)}}>
                          <LinkIcon className={classes.socialLinkIcon} />
                          <ListItemText primary="Copy URL"/>
                        </MenuItem>
                      </a>
                    </StyledMenu>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.buttonXS}
                      href={`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`}
                    >
                      <RoomRoundedIcon className={classes.cardIcons} />
                    </Button>
                  </Box>
                }
              </Box>
            </Toolbar>
          ) :
          <Toolbar className={(urlParts && (urlParts[1] === "" || urlParts[1] === "search")) ? classes.toolbarSearch : classes.toolbar}>
            <Box width="10.5rem">
              <Box className={classes.title} component={Link} to={"/"}>
                <img src={logo} alt="logo" className={classes.titleLogo} />
              </Box>
            </Box>
            {
              !isMobile && !isSearchPage &&
              <Box>
                <Box className={classes.searchBox} width="20rem">
                  <Geosuggest
                    placeholder={t("header-search-placeholder")}
                    className={classes.geo}
                    inputClassName={classes.geoInput}
                    suggestsClassName={classes.geoSuggests}
                    suggestsHiddenClassName={classes.geoSuggestsHidden}
                    suggestItemClassName={classes.geoSuggestItem}
                    suggestItemActiveClassName={classes.geoSuggestItemActive}
                    onChange={(value) => handleSuggestInputChange(value)}
                    autocomplete="off"
                    ref={geosuggestEl}
                    autoComplete="off"
                    fixtures={[
                      {label: 'Zürich, Schweiz'},
                      {label: 'Basel, Schweiz'},
                      {label: 'Bern, Schweiz'},
                      {label: 'Genf, Schweiz'},
                      {label: 'Luzern, Schweiz'},
                      {label: 'Lugano, Schweiz'},
                    ]}
                    onSuggestSelect={(suggestion) => suggestion && suggestion.location && handleLocationChange(suggestion.location, suggestion.description || suggestion.label)}
                  />
                  {
                    (closeSearch)
                    ? <CloseIcon className={classes.searchContainerIcon} onClick={() => {handleCloseSearch()}} />
                    : <SearchIcon className={classes.searchContainerIcon} />
                  }
                </Box>
              </Box>
            }

            <Box display="flex" alignItems="center" zIndex={1300} className={classes.headerButtonsContainer}>
              <LanguageSelector menuState={menuState} />
              {
                (token == null && !isMobile) ?
                (
                  <Box>
                    <Button variant="outlined" aria-label="menu" component={Link} to={queryString ? `/login${queryString}` : "/login"}>
                      { t("common.texts.sign-in") }
                    </Button>
                    <Button variant="contained" aria-label="menu" component={Link} to={queryString ? `/signup-camper${queryString}` : "/signup-camper"} style={{
                      background: theme.palette.primary.main,
                      color: "white",
                      marginLeft: "1rem"
                    }}>
                      { t("common.texts.sign-up") }
                    </Button>
                  </Box>
                )
                :
                <MenuWithDropdown currentURL={currentURL} menuState={menuState} toggleMenu={toggleMenu} menuProps={menuProps} handleMenuState={setMenuState} user={user} />
              }
            </Box>
          </Toolbar>
        }
      </AppBar>
      <Box pt={"70px"}></Box>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.token,
    activeLanguage: state.config.activeLanguage,
    languages: state.config.languages,
    updatingUser: state.user.updatingUser,
    locationFilters: state.parkingSpot.locationFilters,
    searchStringGlobal: state.parkingSpot.searchString,
    parkingSpot: state.parkingSpot.parkingSpotDetails,
  }
};

export default connect(mapStateToProps, { fetchHostProfile, fetchGuestProfile, setExternalSearchLocation, setFilteredParkingSpots, push, setFocusedSpot })(Header);
