import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Box,
  Typography,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import { Controller, useWatch } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  adornmentText: {
    marginTop: "0.125rem",
    marginRight: "0.5rem",
    fontSize: "12px",
  },
  textField: {
    width: "100%",
    "& input": {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      letterSpacing: "0em",
    },
    marginTop: "0.8rem",
  },
  textFieldWrapper: {
    marginTop: "1.6rem",
    marginRight: "1em",
    width: "100%",
    "&:last-child": {
      marginRight: "0em",
    },
  },
  progressBottom: {
    color: theme.palette.grey[300],
  },
  progressTop: {
    position: "absolute",
    left: 0,
  },
  multilineCounter: {
    marginTop: "-2rem",
    marginRight: "0.8rem",
    marginBottom: "2.1rem",
  },
}));

const FormField = ({
  name,
  type,
  required,
  limit,
  control,
  flex,
  label,
  controllerProps = {},
  ...rest
}) => {
  let textCap = null;
  if (type === "text") {
    if (limit) {
      textCap = limit;
    } else if (rest.multiline) {
      textCap = 1000;
    } else {
      textCap = 150;
    }
  }

  const classes = useStyles();
  const value = useWatch({
    control,
    name: name,
  });

  return (
    <Box position="relative" flex={flex} className={classes.textFieldWrapper}>
      <Typography variant="h5" style={{ fontWeight: "500" }}>
        {label} {required ? "*" : ""}
      </Typography>
      <Controller
        as={
          <TextField
            className={classes.textField}
            variant="outlined"
            InputProps={{
              ...(!!textCap && !rest.multiline
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box
                          position="relative"
                          display="flex"
                          flexDirection="row-reverse"
                          alignItems="center"
                          mt={rest.multiline ? 1 : 0}
                        >
                          <Box
                            position="relative"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <CircularProgress
                              variant="determinate"
                              size={20}
                              value={100}
                              className={classes.progressBottom}
                            />
                            <CircularProgress
                              variant="determinate"
                              size={20}
                              value={Number(
                                ((value ? value.length : 0) / textCap) * 100
                              )}
                              className={classes.progressTop}
                            />
                          </Box>
                          <Typography
                            variant="caption"
                            className={classes.adornmentText}
                          >{`${
                            value ? value.length : 0
                          } / ${textCap}`}</Typography>
                        </Box>
                      </InputAdornment>
                    ),
                  }
                : {}),
            }}
            {...(!!textCap ? { inputProps: { maxLength: textCap } } : {})}
            {...(!!type ? { type } : {})}
            required={required}
            {...rest}
          />
        }
        name={name}
        control={control}
        {...controllerProps}
      />
      {!!textCap && rest.multiline && (
        <Box
          position="relative"
          display="flex"
          flexDirection="row-reverse"
          alignItems="center"
          className={classes.multilineCounter}
        >
          <Box
            position="relative"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <CircularProgress
              variant="determinate"
              size={20}
              value={100}
              className={classes.progressBottom}
            />
            <CircularProgress
              variant="determinate"
              size={20}
              value={Number(((value ? value.length : 0) / textCap) * 100)}
              className={classes.progressTop}
            />
          </Box>
          <Typography variant="caption" className={classes.adornmentText}>{`${
            value ? value.length : 0
          } / ${textCap}`}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default FormField;
