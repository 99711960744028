import initialState from "../store/initialState";
import * as Types from "../actions/constants";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_REFERRALS:
      return Object.assign({}, state, {
        myReferrals: {
          ...(state.myReferrals || {}),
          isLoading: true,
        },
      });
    case Types.FETCH_REFERRALS_SUCCESS:
      return Object.assign({}, state, {
        myReferrals: {
          ...(state.myReferrals || {}),
          isLoading: false,
          data: action.data,
          isSuccess: true,
          isError: false,
        },
      });
    case Types.FETCH_REFERRALS_FAILURE:
      return Object.assign({}, state, {
        myReferrals: {
          ...(state.myReferrals || {}),
          isLoading: false,
          data: null,
          isError: true,
          isSuccess: false,
        },
      });
    case Types.CREATE_REFERRAL:
      return Object.assign({}, state, {
        createReferral: {
          ...(state.createReferral || {}),
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      });
    case Types.CREATE_REFERRAL_SUCCESS:
      return Object.assign({}, state, {
        createReferral: {
          ...(state.createReferral || {}),
          isLoading: false,
          isSuccess: true,
          isError: false,
          data: action.data,
        },
      });
    case Types.CREATE_REFERRAL_FAILURE:
      return Object.assign({}, state, {
        createReferral: {
          ...(state.createReferral || {}),
          isLoading: false,
          isSuccess: false,
          isError: true,
          data: null,
        },
      });
    case Types.CREATE_REFERRAL_RESET:
      return Object.assign({}, state, {
        createReferral: {
          ...(state.createReferral || {}),
          isLoading: false,
          isSuccess: false,
          isError: false,
          data: null,
        },
      });
    default:
      return state;
  }
}
