import React, { useState, useEffect } from 'react';
import { Divider, Container, Box, Typography, Select, MenuItem, Card, Button, TextField, CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@mui/icons-material/PictureAsPdf';
import SheetIcon from '@mui/icons-material/InsertDriveFile';
import { useForm, Controller } from "react-hook-form";

import { useStyles } from '../common/styles';
import TitleSection from '../../common/titleSection';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Pagination from '../../common/pagination';
import { addLoader } from '../../../data/actions/config';

import {
  fetchBookingsHost,
  fetchFilteredBookingsHost,
  fetchSingleBookingReceipt,
  fetchMultipleBookingsReceipt,
  setFetchingBookingsReceipt
} from '../../../data/actions/parkingSpot';
import MissingDataPlaceholder from '../../common/missingDataPlaceholder';
import { push } from 'connected-react-router';
import moment from 'moment';
import {ReactComponent as DropdownArrow} from "../../../assets/images/dropdown-arrow.svg";

function round(value, decimals = 2) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals).toFixed(decimals);
}

const initialValues = (values) => {
  return {
    parkingSpot: values.parkingSpot || "",
    startDate: values.startDate || "",
    endDate: values.endDate || "",
  }
};

const Bookings = ({ token, userType, fetchBookingsHost, fetchFilteredBookingsHost, bookings, addLoader, fetchSingleBookingReceipt, fetchMultipleBookingsReceipt, setFetchingBookingsReceipt, fetchingBookingsReceipt }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isFiltered, setIsFiltered] = useState(false);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    addLoader();
  }, []);

  useEffect(() => {
    fetchBookingsHost(token, false, userType === "SubHost");
    setIsFiltered(false);
  }, [fetchBookingsHost, token])

  const defaultValues = initialValues({
    parkingSpot: "All parking spots option",
    startDate: null,
    endDate: null,
  });

  const { handleSubmit, control, reset } = useForm({ defaultValues });
  const [ printAll, setPrintAll ] = useState(false);
  const [ printCSV, setPrintCSV ] = useState(false);

  const handlePrintAll = () => {
    setPrintAll(true);
  }

  const handlePrintAllCSV = () => {
    setPrintCSV(true);
    setPrintAll(true);
  }

  const onSubmit = (data) => {
    let query = {};
    if (data && data.parkingSpot && data.parkingSpot.length > 0 && data.parkingSpot !== "All parking spots option"){
      query = {
        parking_spot_id: data.parkingSpot,
        from_date: data.startDate,
        to_date: data.endDate
      }
    }
    else if (data){
      query = {
        from_date: data.startDate,
        to_date: data.endDate
      }
    }
    if (!printAll) {
      fetchFilteredBookingsHost(token, null, query, userType === "SubHost");
      setFilters(query);
      setIsFiltered(true);
    }
    else {
      setFetchingBookingsReceipt("all", true, printCSV);
      fetchMultipleBookingsReceipt(token, query, userType === "SubHost", printCSV);
      setPrintAll(false);
      setPrintCSV(false);
    }
  }

  const fetchingReceipt = fetchingBookingsReceipt.id === "all" && fetchingBookingsReceipt.status;
  const pdfDisabled = !!(fetchingBookingsReceipt.id === "all" && fetchingBookingsReceipt.status && !fetchingBookingsReceipt.printCSV);
  const csvDisabled = !!(fetchingBookingsReceipt.id === "all" && fetchingBookingsReceipt.status && fetchingBookingsReceipt.printCSV);

  return (
    <Container maxWidth="md">
      <Box display="flex">
        <TitleSection
          routes={[{
            name: t("common.texts.dashboard"),
            path: "/dashboard",
          }]}
          title={ t("bookings.title") }
        />
      </Box>
      <Divider />
      <Box display="flex" pt={isMobile ? 4 : 6} flexDirection="column">
        <Box mb={6}>
          <form width="100%" onSubmit={handleSubmit(onSubmit)} className={classes.root} autoComplete="off">
            <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
              <Box mb={isMobile ? 3 : 0} width={isMobile ? "100%" : "30%"}>
                <Typography variant="h3">
                  { t('bookings.filter-by-parking') }
                </Typography>
                <Box width={isMobile ? "100%" : "80%"}>
                  <Controller
                    as={
                      <Select
                        labelId="parking-spot-select-label"
                        className={classes.countrySelect}
                        defaultValue="All parking spots option"
                        variant= "outlined"
                        IconComponent={(props) => (<DropdownArrow {...props}/>)}
                      >
                        <MenuItem value="All parking spots option">All parking spots</MenuItem>
                        {
                          bookings && bookings.parking_spots && bookings.parking_spots.length > 0 &&
                          bookings.parking_spots.map((spot) => (
                            <MenuItem value={spot.id}>{spot.name}</MenuItem>
                          )
                        )}
                      </Select>
                    }
                    name="parkingSpot"
                    control={control}
                    defaultValue="All parking spots option"
                  />
                </Box>
              </Box>
              <Box mb={isMobile ? 3 : 0} width={isMobile ? "100%" : "50%"}>
                <Typography variant="h3">
                  { t('bookings.filter-by-date') }
                </Typography>
                <Box display="flex" flexDirection={isMobile ? "column" : "row"} mt={2}>
                  <Controller as={
                    <TextField
                      id="date"
                      label="From"
                      type="date"
                      className={classes.inputDatePicker}
                      style={isMobile ? { width: "100%" } : { width: "40%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant= "outlined"
                    />
                  } name="startDate" control={control} />
                  <Controller as={
                    <TextField
                      id="date"
                      label="To"
                      type="date"
                      className={classes.inputDatePicker}
                      style={isMobile ? { width: "100%", marginTop: "1rem" } : { width: "40%", marginLeft: "2rem" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant= "outlined"
                    />
                  } name="endDate" control={control} />
                </Box>
              </Box>
              <Box alignSelf={isMobile ? "initial" : "flex-end"} display="flex" style={!isMobile ? {marginLeft: "auto"} : {}}>
                <Box width={isMobile ? "100%" : "auto"} display="flex">
                  <Button variant="contained" color="primary" type="submit" fullWidth={true} style={{paddingTop: "0.75rem", paddingBottom: "0.75rem"}}>
                    { t('bookings.apply') }
                  </Button>
                  <Box position="relative" ml={1.5}>
                    <Button variant="outlined" size="small" className={classes.printButtonAll} type="submit" onClick={handlePrintAll} disabled={csvDisabled || pdfDisabled}>
                      {
                        (!fetchingReceipt || csvDisabled) &&
                        <PrintIcon className={classes.printIcon}/>
                      }
                    </Button>
                    {
                      pdfDisabled &&
                      <CircularProgress size={22} className={classes.printProgressAll} />
                    }
                  </Box>
                  <Box position="relative" ml={1.5}>
                    <Button variant="outlined" size="small" className={classes.printButtonAll} type="submit" onClick={handlePrintAllCSV} disabled={csvDisabled || pdfDisabled}>
                      {
                        (!fetchingReceipt || pdfDisabled) &&
                        <SheetIcon className={classes.printIcon} />
                      }
                    </Button>
                    {
                      csvDisabled &&
                      <CircularProgress size={22} className={classes.printProgressAll} />
                    }
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
        <Box>
          <Box display="flex">
            <Typography variant="h3">
              { t('bookings.title') }
            </Typography>
          </Box>
          {
            !isMobile ?
          <Box mt={2}>
            { bookings && bookings.bookings && bookings.bookings.length > 0 ? (
              <TableContainer style={{borderBottomRightRadius: "0", borderBottomLeftRadius: "0", borderBottom: "0"}} component={Paper} elevation={0} className={classes.tableContainer}>
                <Table className={classes.table} aria-label="parked cars table" elevation={0}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{width: "10rem"}}><Typography variant="h5" style={{fontWeight: 500}}>{ t('bookings.name') }</Typography></TableCell>
                      <TableCell style={{width: "12rem"}}><Typography variant="h5" style={{fontWeight: 500}}>{ t('bookings.parking-spot') }</Typography></TableCell>
                      <TableCell style={{width: "10rem"}}><Typography variant="h5" style={{fontWeight: 500}}>{ t('bookings.phone-number') }</Typography></TableCell>
                      <TableCell style={{width: "8rem"}}><Typography variant="h5" style={{fontWeight: 500}}>{ t('bookings.check-in') }</Typography></TableCell>
                      <TableCell style={{width: "8rem"}}><Typography variant="h5" style={{fontWeight: 500}}>{ t('bookings.check-out') }</Typography></TableCell>
                      <TableCell style={{width: "8rem"}}><Typography variant="h5" style={{fontWeight: 500}}>{ t('bookings.status') }</Typography></TableCell>
                      <TableCell align="right" style={{width: "8rem"}}><Typography variant="h5" style={{fontWeight: 500}}>{ t('bookings.balance') }</Typography></TableCell>
                      <TableCell align="right" style={{width: "8rem"}}><Typography variant="h5" style={{fontWeight: 500}}>{ t('bookings.platform-fee') }</Typography></TableCell>
                      <TableCell align="right" style={{width: "8rem"}}><Typography variant="h5" style={{fontWeight: 500}}>{ t('bookings.tax-amount') }</Typography></TableCell>
                      <TableCell align="right" style={{width: "8rem"}}><Typography variant="h5" style={{fontWeight: 500}}>{ t('bookings.profit') }</Typography></TableCell>
                      <TableCell align="center" style={{width: "4rem"}}><Typography variant="h5" style={{fontWeight: 500}}>{ t('bookings.print') }</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bookings.bookings.map((row, index) => (
                      <TableRow key={`booking-${index}`}>
                        <TableCell>
                          <Typography variant="h5">
                            {row.guest}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5">
                            {row.parking_spot}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5">
                            {row.phone_number}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5">
                            {moment(row.checkin).format('DD.MM.YYYY')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5">
                            {`${row.checkout ? moment(row.checkout).format('DD.MM.YYYY') : "Pending"}`}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5">
                            { t(`bookings.${row.state || 'complete'}`)}
                            {/*{`${row.state || "complete"}`}*/}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="h5">

                            {row.currency || ""} {row.total_amount && round(row.total_amount)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="h5">

                            {row.currency || ""} {row.platform_fee}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="h5">

                            {row.currency || ""} {row.tax_amount}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="h5">
                            { row.state == "payment_refund" ? (
                                (row.currency || "") + (" 0.0")
                              ) : (
                                (row.currency || "") + " " + (row.profit || "N/A")
                              )
                            }
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box position="relative">
                            <Button variant="outlined" size="small" className={classes.printButton} onClick={() => {setFetchingBookingsReceipt(row.id, true); fetchSingleBookingReceipt(token, row.id, row.parking_spot, userType === "SubHost")}} disabled={!!(fetchingBookingsReceipt.id === row.id && fetchingBookingsReceipt.status)}>
                              {
                                !(fetchingBookingsReceipt.id === row.id && fetchingBookingsReceipt.status) &&
                                <PrintIcon className={classes.printIcon}/>
                              }
                            </Button>
                            {
                              !!(fetchingBookingsReceipt.id === row.id && fetchingBookingsReceipt.status) &&
                              <CircularProgress size={22} className={classes.printProgress} />
                            }
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : <MissingDataPlaceholder /> }
            { bookings && bookings.bookings && bookings.bookings.length > 0 ? (
              <TableContainer style={{borderTopRightRadius: "0", borderTopLeftRadius: "0"}} component={Paper} elevation={0} className={classes.tableContainer}>
                <Table className={classes.table} aria-label="parked cars table" elevation={0}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                      </TableCell>
                      <TableCell align="right">
                        <Pagination pagy={bookings.pagy} token={token} epic={isFiltered ? fetchFilteredBookingsHost : fetchBookingsHost} query={isFiltered ? filters : null} />
                      </TableCell>
                    </TableRow>
                    <TableRow bgcolor="#E7F0F0">
                      <TableCell>
                        <Typography variant="h4">
                          {t("bookings.available-balance")}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="h4">
                          {`${bookings.currency || ""} +${round(bookings.available_balance || 0)}`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null }
          </Box>
          :
          <Box>
            { bookings && bookings.bookings && bookings.bookings.length > 0 ? (
            <React.Fragment>
              <Box>
                {bookings.bookings.map((row, index) => (
                  <Card style={{marginTop: "1.5rem"}} elevation={0} key={`booking-${index}`}>
                    <Box p={3}>
                      <Box mb={1}>
                        <Typography variant="body1" style={{fontWeight: 500}}>{ t('bookings.name') }</Typography>
                        <Typography variant="body1">{row.guest}</Typography>
                      </Box>
                      <Box mb={1}>
                        <Typography variant="body1" style={{fontWeight: 500}}>{ t('bookings.parking-spot') }</Typography>
                        <Typography variant="body1">{row.parking_spot}</Typography>
                      </Box>
                      <Box mb={1}>
                        <Typography variant="body1" style={{fontWeight: 500}}>{ t('bookings.phone-number') }</Typography>
                        <Typography variant="body1">{row.phone_number}</Typography>
                      </Box>
                      <Box mb={1}>
                        <Typography variant="body1" style={{fontWeight: 500}}>{ t('bookings.check-in') }</Typography>
                        <Typography variant="body1">{moment(row.checkin).format('DD.MM.YYYY')}</Typography>
                      </Box>
                      <Box mb={1}>
                        <Typography variant="body1" style={{fontWeight: 500}}>{ t('bookings.check-out') }</Typography>
                        <Typography variant="body1">
                          {`${row.checkout ? moment(row.checkout).format('DD.MM.YYYY') : "Pending"}`}
                        </Typography>
                      </Box>
                      <Box mb={1}>
                        <Typography variant="body1" style={{fontWeight: 500}}>{ t('bookings.balance') }</Typography>
                        <Typography variant="body1">{row.currency || ""} {row.total_amount && round(row.total_amount)}</Typography>
                      </Box>
                      <Box mb={1}>
                        <Typography variant="body1" style={{fontWeight: 500}}>{ t('bookings.platform-fee') }</Typography>
                        <Typography variant="body1">{row.currency || ""} {row.platform_fee}</Typography>
                      </Box>
                      <Box mb={1}>
                        <Typography variant="body1" style={{fontWeight: 500}}>{ t('bookings.tax-amount') }</Typography>
                        <Typography variant="body1">{row.currency || ""} {row.tax_amount}</Typography>
                      </Box>
                      <Box mb={1}>
                        <Typography variant="body1" style={{fontWeight: 500}}>{ t('bookings.profit') }</Typography>
                        <Typography variant="body1">{row.currency || ""} {row.profit}</Typography>
                      </Box>
                      <Box mb={1} display="flex" alignItems="center">
                        <Typography variant="body1" style={{fontWeight: 500}}>{ t('bookings.download') }</Typography>
                        <Box position="relative" ml={1}>
                          <Button variant="outlined" size="small" className={classes.printButton} onClick={() => {setFetchingBookingsReceipt(row.id, true); fetchSingleBookingReceipt(token, row.id, row.parking_spot, userType === "SubHost")}} disabled={!!(fetchingBookingsReceipt.id === row.id && fetchingBookingsReceipt.status)}>
                            {
                              !(fetchingBookingsReceipt.id === row.id && fetchingBookingsReceipt.status) &&
                              <PrintIcon className={classes.printIcon}/>
                            }
                          </Button>
                          {
                            !!(fetchingBookingsReceipt.id === row.id && fetchingBookingsReceipt.status) &&
                            <CircularProgress size={22} className={classes.printProgress} />
                          }
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                ))}
                <Box mt={2} display="flex" justifyContent="flex-end" alignItems="center">
                  <Pagination pagy={bookings.pagy} token={token} epic={isFiltered ? fetchFilteredBookingsHost : fetchBookingsHost} query={isFiltered ? filters : null} />
                </Box>
              </Box>
              <Card style={{marginTop: "1rem"}} elevation={0}>
                <Box py={3} px={2} display="flex" style={{background: "#E7F0F0"}}>
                  <Box flex="auto">
                    <Typography variant="h6" style={{fontWeight: 500}}>{t("bookings.available-balance")}</Typography>
                  </Box>
                  <Box alignSelf="flex-end">
                    <Typography variant="h6" style={{fontWeight: 500}}>{`${bookings.currency || ""} +${round(bookings.available_balance || 0)}`}</Typography>
                  </Box>
                </Box>
              </Card>
            </React.Fragment>
            ) : (<MissingDataPlaceholder />)}
          </Box>
          }
        </Box>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userType: state.user.userType,
    bookings: state.parkingSpot.bookingsHost && state.parkingSpot.bookingsHost.payload,
    fetchingBookingsReceipt: state.parkingSpot.fetchingBookingsReceipt
  };
};

export default connect(mapStateToProps, {
  fetchBookingsHost,
  fetchFilteredBookingsHost,
  addLoader,
  fetchSingleBookingReceipt,
  fetchMultipleBookingsReceipt,
  setFetchingBookingsReceipt
})(Bookings);
