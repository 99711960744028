import { ofType } from 'redux-observable';
import { mergeMap, flatMap, catchError } from 'rxjs/operators';
import { from } from 'rxjs';

import * as Types from '../actions/constants';
import * as ConfigActions from '../actions/config';

export const changeI18nLanguage = action$ =>
  action$.pipe(
    ofType(Types.CHANGE_I18N_LANGUAGE),
    mergeMap((action) => {
      const changeLanguagePromise = action.i18n.changeLanguage(action.language);
      return from(changeLanguagePromise).pipe(
        flatMap(() => {
          return [
            ConfigActions.setActiveLanguage(action.language),
          ];
        }),
        catchError(error => {
          console.log(`Could not change language: ${error.message}`);
          return [];
        })
      )
    })
  );
