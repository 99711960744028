import { Box, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import Geosuggest from "react-geosuggest";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as SearchIcon } from "assets/images/search-icon.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  geo: {},
  geoInputCommon: {
    height: "44px",
    flex: "auto",
    alignItems: "center",
    border: "1px solid #E7F0F0",
    borderRadius: "12px",
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: "3px",
    transition: "box-shadow 50ms ease-in",
    "&:focus": {
      outline: "none !important",
      boxShadow: `0 0 0.0625rem 0.125rem ${theme.palette.primary.main}`,
      transition: "box-shadow 100ms ease-out",
    },
  },
  geoInput: {
    height: "44px",
    flex: "auto",
    alignItems: "center",
    border: "1px solid #E7F0F0",
    borderRadius: "12px",
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    transition: "box-shadow 50ms ease-in",
    "&:focus": {
      outline: "none !important",
      boxShadow: `0 0 0.0625rem 0.125rem ${theme.palette.primary.main}`,
      transition: "box-shadow 100ms ease-out",
    },
  },
  geoInputEdit: {
    flex: "auto",
    alignItems: "center",
    border: "1px solid #ddd",
    borderRadius: "0.75rem",
    fontFamily: theme.typography.fontFamily,
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    "&:focus": {
      outline: "none",
    },
  },
  geoSuggestsCommon: {
    backgroundColor: "#fff",
    cursor: "pointer",
    borderRadius: "1rem",
    boxShadow: "0px 3px 3px 2px rgb(0 0 0 / 5%)",
    border: "1px solid #E7F0F0",
    padding: 0,
    position: "absolute",
    width: "100%",
    height: "153px",
    overflowY: "scroll",
    overflowX: "hidden",
    zIndex: 999,
  },
  geoSuggests: {
    backgroundColor: "#fff",
    cursor: "pointer",
    borderRadius: "1rem",
    boxShadow: "0px 3px 3px 2px rgb(0 0 0 / 5%)",
    border: "1px solid #E7F0F0",
    padding: 0,
    position: "absolute",
    width: "100%",
    height: "200px",
    overflowY: "scroll",
    overflowX: "hidden",
    zIndex: 999,
  },
  geoSuggestsEdit: {
    backgroundColor: "#fff",
    borderRadius: "0.8rem",
    boxShadow: "0px 3px 3px 2px rgb(0 0 0 / 5%)",
    border: "1px solid #E7F0F0",
    padding: 0,
    margin: "0.7rem 0rem",
    overflow: "hidden",
    zIndex: 999,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      width: "90vw",
    },
  },
  geoSuggestsHidden: {
    display: "none",
  },
  geoSuggestItem: {
    alignItems: "center",
    display: "flex",
    listStyle: "none",
    padding: "0.8rem 0.6rem",
    fontSize: "0.7rem",
    borderBottom: "1px solid #E7F0F0",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  geoSuggestItemActive: {
    backgroundColor: "#E7F0F0",
    listStyle: "none",
    padding: "0.8rem 0.6rem",
    fontSize: "0.7rem",
    borderBottom: "1px solid #E7F0F0",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  searchContainerIcon: {
    fill: theme.palette.primary.main,
    fontSize: "1.25rem",
    position: "absolute",
    width: "1rem",
    height: "1rem",
    top: "50%",
    right: "0.7rem",
    transform: "translateY(-50%)",
    cursor: "pointer",
  },
}));

function AutocompleteLocationInput({
  placeholder,
  initialValue,
  onSuggestSelect,
  fixtures,
  onInputChange,
}) {
  const classes = useStyles();
  const geosuggestEl = React.useRef(null);
  const [value, setValue] = React.useState(initialValue);

  const handleSuggestInputChange = (value) => {
    setValue(value);
    onInputChange(value);
  };

  const handleInputClear = () => {
    geosuggestEl.current.clear();
    setValue("");
    onInputChange("");
  };

  return (
    <Box className={classes.container}>
      <Geosuggest
        placeholder={placeholder}
        className={classes.geo}
        inputClassName={classes.geoInputCommon}
        suggestsClassName={classes.geoSuggestsCommon}
        suggestsHiddenClassName={classes.geoSuggestsHidden}
        suggestItemClassName={classes.geoSuggestItem}
        suggestItemActiveClassName={classes.geoSuggestItemActive}
        onChange={(value) => handleSuggestInputChange(value)}
        ref={geosuggestEl}
        autoComplete="off"
        fixtures={fixtures || [
          { label: "Zürich, Schweiz" },
          { label: "Basel, Schweiz" },
          { label: "Bern, Schweiz" },
          { label: "Genf, Schweiz" },
          { label: "Luzern, Schweiz" },
          { label: "Lugano, Schweiz" },
        ]}
        onSuggestSelect={(suggest) => {
          const newValue =
            (geosuggestEl.current && geosuggestEl.current.state.userInput) ||
            "";
          setValue(newValue);
          onSuggestSelect?.(suggest);
        }}
        initialValue={initialValue}
      />
      {!!value ? (
        <CloseIcon
          className={classes.searchContainerIcon}
          onClick={handleInputClear}
        />
      ) : (
        <SearchIcon className={classes.searchContainerIcon} />
      )}
    </Box>
  );
}

export default AutocompleteLocationInput;
