import { from } from 'rxjs';
import Axios from 'axios';
import { enqueueSnackbar } from '../data/actions/global';

import DismissButton from '../components/common/dismissButton';
import i18n from '../i18n';

export default class API {
  static headers(options) {
    let requestHeaders = {};
    if(options.token) {
      requestHeaders['Authorization'] = `Bearer ${options.token}`;
    }

    return requestHeaders;
  }

  static client(options, blob = false) {
    return !blob ? Axios.create({
      baseURL: options.host,
      timeout: options.timeout || 30000,
      headers: API.headers(options),
      withCredentials: true,
    }) :
    Axios.create({
      baseURL: options.host,
      timeout: options.timeout || 30000,
      headers: API.headers(options),
      responseType: "blob",
      withCredentials: true,
    });;
  }

  static get(url, options, blob = false) {
    const apiClient = API.client(options, blob);
    return from(apiClient.get(url));
  }

  static post(url, params, options, blob = false) {
    const apiClient = API.client(options, blob);
    const result = apiClient.post(url, params);
    return from(result);
  }

  static put(url, params, options) {
    const apiClient = API.client(options);
    return from(apiClient.put(url, params));
  }

  static delete(url, params, options) {
    const apiClient = API.client(options);
    return from(apiClient.delete(url, { data: params }));
  }

  static patch(url, params, options) {
    const apiClient = API.client(options);
    return from(apiClient.patch(url, params));
  }


  static notificationsForErrorResponse(error) {
    const errors = [];
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors.length
    ) {
      error.response.data.errors.forEach(e => {
        if (
          e.details &&
          e.details.length
        ) {
          e.details.forEach(detail => {
            errors.push(API.parseError(detail));
          })
        } else if (e.message) {
          errors.push(i18n.t(e.message));
        } else if (e.code) {
          errors.push(i18n.t(e.code));
        }
      })
    }

    if (errors.length === 0) {
      if (error.response && error.response.statusText) {
        errors.push(error.response.statusText);
      } else if (error.message === "Network Error") {
        errors.push(error.message);
      }
    }

    return errors.map(e => {
      return enqueueSnackbar({
        message: {
          text: e,
          variant: "error",
        },
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      })
    });
  }

  static parseError(detail) {
    if (detail.code === "taken" && detail.resource === "Referral" && detail.field === "email") {
      return i18n.t("error.custom.already-referred");
    } else if (detail.code === "referral_already_registered" && detail.resource === "Referral" && detail.field === "email") {
      return i18n.t("error.custom.referral-already-registered");
    } else if (detail.code === "blank" && detail.resource === "Referral" && detail.field === "phone") {
      return i18n.t("error.custom.blank-phone");
    } else if (detail.code === "blank" && detail.resource === "Referral" && detail.field === "language") {
      return i18n.t("error.custom.blank-language");
    } else if (i18n.isInitialized) {
      return `${i18n.t(`error.short-codes.${detail.code}`)} (${i18n.t(`error.resource-names.${detail.resource}`)} - ${i18n.t(`error.field-names.${detail.field}`)})`;
    } else {
      return `${detail.code} (${detail.resource} - ${detail.field})`;
    }
  }
}
