import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    height: "100vh",
    position: "fixed",
    background: "white",
    width: "100%",
    zIndex: "9999"
  },
  progress: {
    margin: "auto",
  }
}));

const Loader = ({loading}) => {
  const classes = useStyles();

  return (
    <Box display={!!loading ? "flex" : "none"} flexDirection="column" className={classes.root}>
      <CircularProgress className={classes.progress} />
    </Box>
  );
}

const mapStateToProps = ({ config: { waitingForToken, loading } }) => {
  return {
    loading: waitingForToken || loading,
  }
};

export default connect(mapStateToProps, null)(Loader);
