import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  flagIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(1),
  },
  caption2: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0em",
    color: "#8E8E99",
  },
  fieldRow: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  primaryButton: {
    [theme.breakpoints.down("xs")]: {
      flex: "1 1 auto",
    },
  },
  titleDisabled: {
    fontSize: "1.2em",
    lineHeight: "1.25em",
    fontWeight: 500,
    flex: 1,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]:{
      fontSize: "1.15rem",
    }
  },
  forwardIcon: {
    fontSize: "0.875rem",
    lineHeight: "1em",
    fontWeight: 400,
    flex: 1,
    color: theme.palette.secondary.main,
  },
  subTitle: {
    fontSize: "1em",
  },
  divider: {
    backgroundColor: theme.palette.secondary.light,
  },
  textField: {
    width: "100%",
  },
  textFieldWrapper: {
    marginTop: "1.5em",
    marginRight: "1em",
    width: "100%",
    "&:last-child": {
      marginRight: "0em",
    },
  },
  actionBox: {
    justifyContent: "flex-end",
  },
  secondaryButton: {
    paddingRight: "0em",
    paddingLeft: "0em",
    paddingTop: "0.5em",
    paddingBottom: "0em",
  },
  radioFormControl: {
    flex: 1,
  },
  forgotPasswordButton: {
    padding: "0 !important"
  },
  registerButton: {
    padding: "0 !important"
  },
  buttonWrapper: {
    marginTop: "1.5rem",
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.color,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  countrySelect: {
    width: "100%",
    marginTop: "0.8rem",
  },
  languageSelect: {
    width: "100%",
    borderColor: `${theme.palette.secondary.light} !important`,
  },
  pageNumber: {
    color: "white",
    background: theme.palette.primary.main,
    fontSize: "0.875rem",
    borderRadius: "2rem",
    width: "1.5rem",
    height: "1.5rem",
    textAlign: "center",
    fontWeight: 500,
    paddingTop: "0.1rem",
    marginRight: "0.875rem",
    [theme.breakpoints.down("sm")]:{
      fontSize: "1rem",
      width: "1.75rem",
      height: "1.75rem",
      paddingTop: "0.15rem"
    }
  },
  pageNumberDisabled: {
    color: "white",
    background: theme.palette.secondary.main,
    fontSize: "0.875rem",
    borderRadius: "2rem",
    width: "1.5rem",
    height: "1.5rem",
    textAlign: "center",
    fontWeight: 500,
    paddingTop: "0.1rem",
    marginRight: "0.875rem",
    [theme.breakpoints.down("sm")]:{
      fontSize: "1rem",
      width: "1.75rem",
      height: "1.75rem",
      paddingTop: "0.15rem",
    }
  },
  caption: {
    width: "100%",
    backgroundColor: "#F2F9FA",
    borderRadius: "0.8rem",
  },
  captionText: {
    paddingLeft: "1rem",
  },
  itemsWrapper: {
    width: "100%",
  },
  uploadBox: {
    width: "100%",
    border: `0.0625rem dashed #bbb`,
    borderRadius: "0.875rem",
    height: "8rem",
  },
  uploadBoxIcon: {
    color: theme.palette.secondary.main,
    fontSize: "2.5rem",
    padding: 0,
    margin: 0
  },
  uploadBoxIconChosen: {
    color: theme.palette.primary.main,
    fontSize: "2.5rem",
    padding: 0,
    margin: 0
  },
  imageIcon: {
    fontSize: "1em",
  },
  imageWrapper: {
    "&.large": {
      width: "90%",
      height: "80%",
    },
    "&.single": {
      margin: "auto",
    },
    "&.multiple": {
      [theme.breakpoints.up("sm")]: {
        width: "23%",
        paddingTop: "23%",
      },
      position: "relative",
      width: "47%",
      paddingTop: "47%",
      marginBottom: "1rem",
    },
  },
  phoneInput: {
    width: "100% !important",
    color: `${theme.palette.text.primary} !important`,
    borderRadius: "0.75rem !important",
    fontSize: "14px !important",
    padding: "0.5rem 3.75rem 0.5rem 0.75rem !important",
    borderColor: `${theme.palette.secondary.light} !important`,
    transition: "none 0s ease 0s !important",
    boxShadow: "none !important",
    height: "2.8rem !important",
    fontFamily: `${theme.typography.fontFamily}`,
    "&:focus": {
      borderColor: `${theme.palette.primary.main} !important`,
      borderWidth: "0.125rem"
    }
  },
  onboardingPhoneLabel: {
    fontWeight: 500
  },
  phoneContainer: {
    width: "100%",
    "& .special-label": {
      position: 'absolute !important',
      zIndex: 1,
      top: '0.1875rem !important',
      left: '0.75rem !important',
      display: 'block !important',
      padding: '0 !important',
      fontSize: '10px !important',
      whiteSpace: 'nowrap !important',
      color: '#aaa !important',
      [theme.breakpoints.down("sm")]: {
        top: '0.25rem !important',
      },
      visibility: "hidden"
    }
  },
  phoneButton: {
    right: "0.0625rem",
    "&& .arrow": {
      right: "29px !important",
    }
  },
  phoneDropdown: {
    right: 0,
    height: "200px",
    width: "500px !important",
    [theme.breakpoints.down("xs")]: {
      width: "85vw !important",
    },
  },
  phoneDropdownOnboarding: {
    right: 0,
    height: "200px",
    width: "22.75rem !important",
    [theme.breakpoints.down("xs")]: {
      width: "85vw !important",
    },
  },
  onboardingTitle: {
    borderBottom: "0.0625rem solid #E7F0F0",
    margin: "0 1.5rem",
    [theme.breakpoints.down("sm")]: {
      margin: "0 1rem",
    },
    padding: "1.375rem 0 0.5rem !important"
  },
  onboardingContent: {
    padding: "1.25rem 1.5rem 1.5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1.0rem 1.0rem 1.0rem",
    }
  },
  onboardingCard: {
    borderRadius: "1rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1rem",
      marginRight: "1rem"
    }
  },
  userAgreement: {
    fontWeight: 500
  },
  userAgreementLink: {
    color: theme.palette.primary.main,
    textDecoration: "none"
  },
  halfDivider: {
    backgroundColor: theme.palette.secondary.light,
    width: "100%"
  },
  noWrapText: {
    whiteSpace: "nowrap",
    width: "auto",
    padding: "0 0.625rem !important"
  },
  signupTabs: {
    marginTop: "1.375rem",
    padding: "0 !important",
  },
  completeProfileTabs: {
    margin: 0,
    width: "100%"
  },
  completeProfileTitle: {
    borderBottom: "0.0625rem solid #E7F0F0",
    margin: "0 1.0rem",
    padding: "1.5rem 0 0.75rem !important"
  },
  completeProfileContent: {
    overflowY: "scroll",
    padding: "1.5rem !important",
    [theme.breakpoints.down("sm")]: {
      padding: "1.0rem 1.0rem 1.0rem !important",
    }
  },
  buttonWrapperNoMarginBottom: {
    marginTop: "1.5rem",
    marginBottom: 0,
    position: 'relative',
  },
  progressButtonWrapper: {
    position: 'relative',
  },
}));
