import initialState from '../store/initialState';
import * as Types from '../actions/constants';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.INIT_PARKING_SPOT_STATE:
      return Object.assign({}, initialState.parkingSpot);
    case Types.SET_PARKING_SPOT_ATTRIBUTES:
      const mapping = {};
      action.payload.forEach(category => {
        mapping[category.name] = {}
        category.parking_spot_category_options.forEach(option => {
          mapping[category.name][option.text] = option.id;
        })
      });
      return Object.assign({}, state, {
        parkingSpotAttributes: action.payload,
        parkingSpotAttributesMap: mapping,
      });
    case Types.SET_PRICE_RANGE_FILTER:
      return Object.assign({}, state, {
        priceRangeFilter: action.payload,
      });
    case Types.FETCH_PRICE_FILTERS_FULFILLED:
      return Object.assign({}, state, {
        priceFilters: action.payload.data || null,
      });
    case Types.SET_PRICE:
      return Object.assign({}, state, {
        price: action.payload,
      });
    case Types.SET_FETCHING_PARKING_SPOT_ATTRIBUTES:
      return Object.assign({}, state, { fetchingParkingSpotAttributes: action.value });
    case Types.SET_CREATING_PARKING_SPOT:
      return Object.assign({}, state, { creatingParkingSpot: action.status });
    case Types.SET_UPDATING_PARKING_SPOT:
      return Object.assign({}, state, { updatingParkingSpot: action.status });
    case Types.POST_GALLERY_IMAGE:
      return Object.assign({}, state, { fetchingParkingSpotAttributes: action.value });
    case Types.ADD_GALLERY_IMAGE:
      if(action.payload) {
        const parkingSpotDetails = {
          ...state.parkingSpotDetails,
          gallery_images: [
            ...state.parkingSpotDetails.gallery_images,
            action.payload
          ],
        };
        return Object.assign({}, state, { parkingSpotDetails });
      }
    case Types.REMOVE_GALLERY_IMAGE:
      if(action.id) {
        const newParkingSpot = {
          ...state.parkingSpotDetails,
          gallery_images: [
            ...state.parkingSpotDetails.gallery_images.filter(img => img.id !== action.id),
          ],
        };
        return Object.assign({}, state, { parkingSpotDetails: newParkingSpot });
      }
    case Types.INCREMENT_GALLERY_IMAGE_LOADING_COUNTER:
      return Object.assign({}, state, { updatingGalleryImage: state.updatingGalleryImage + 1 });
    case Types.DECREMENT_GALLERY_IMAGE_LOADING_COUNTER:
      return Object.assign({}, state, { updatingGalleryImage: Math.max(state.updatingGalleryImage - 1, 0) });
    case Types.SET_PARKING_SPOT:
      return Object.assign({}, state, { currentParkingSpot: action.payload });
    case Types.SET_CHECK_OUT_DIALOG_OPEN:
      return Object.assign({}, state, { checkOutDialogOpen: action.value });
    case Types.SET_CHECK_IN_DIALOG_OPEN:
      return Object.assign({}, state, { checkInDialogOpen: action.value });
    case Types.SET_REVIEW_DIALOG_OPEN:
      return Object.assign({}, state, { reviewDialogOpen: action.value });
    case Types.SET_REVIEW_SUBMISSION_DIALOG_OPEN:
      return Object.assign({}, state, { reviewSubmissionDialogOpen: action.value });
    case Types.SET_BOOKING_REVIEW_SUBMISSION_DIALOG_OPEN:
      return Object.assign({}, state, { bookingReviewSubmissionDialogOpen: {
        ...state.bookingReviewSubmissionDialogOpen,
        [action.id]: action.value,
      }});
    case Types.SET_PARKING_SPOT_CARS:
      action.payload.parked_cars.map((car)=> {
        car.isDisabled = false
      })
      return Object.assign({}, state, { parkedCars: {
        id: action.id,
        cars: action.payload.parked_cars,
        capacity: action.payload.capacity,
        booked: action.payload.booked,
      }});
    case Types.SET_PARKED_CAR_CHECK_DISABLED:
      let carToBeDisabledIndex = state.parkedCars.cars.findIndex((car => car.id === action.id));
      let newParkedCarsObject = state.parkedCars;
      newParkedCarsObject.cars[carToBeDisabledIndex].isDisabled = action.value;
      return Object.assign({}, state, { parkedCars: { ...newParkedCarsObject }});
    case Types.SET_PARKING_SPOTS:
      return Object.assign({}, state, { parkingSpots: action.payload });
    case Types.SET_REVIEWS_BY_ID:
      return Object.assign({}, state, { reviews: {
        id: action.id,
        response: action.payload
      }});
    case Types.SET_PHOTOS_BY_ID:
      return Object.assign({}, state, { photos: {
        id: action.id,
        response: action.payload
      }});
    case Types.SET_MORE_REVIEWS_BY_ID:
      return Object.assign({}, state, { moreReviews: action.payload });
    case Types.SET_REVIEWS_BY_ID_PUSH:
      return Object.assign({}, state, { moreReviews: {
        reviews: [...state.moreReviews.reviews, ...action.payload.reviews],
        pagy: action.payload.pagy,
      }});
    case Types.RESET_REVIEWS: 
      return Object.assign({}, state, {
        reviews: {
          id: null,
          response: {
            reviews: [],
            pagy: null,
          },
        },
        moreReviews: {
          reviews: [],
          pagy: null,
        }
      })
    case Types.SET_CURRENT_CHECK_INS:
      return Object.assign({}, state, { currentCheckIns: action.payload });
    case Types.SET_PAST_CHECK_INS:
      return Object.assign({}, state, { pastCheckIns: action.payload });
    case Types.SET_CARS_FOR_GUESTS:
      return Object.assign({}, state, { carsForGuests: action.payload });
    case Types.SET_IS_CHECKED_IN:
      return Object.assign({}, state, { isCheckedIn: {
        parking_spot_id: action.parking_spot_id,
        checkIn: action.checkin,
      }});
    case Types.SET_CURRENT_BOOKING_ID:
      return Object.assign({}, state, { currentBookingId: action.currentBookingId });
    case Types.SET_PARKING_SPOT_DETAILS:
      let noOnboardToiletRequired = null;
      if (action.payload !== null) {
        if (action.payload.parking_spot_category_options && action.payload.parking_spot_category_options instanceof Array && action.payload.parking_spot_category_options.length > 0) {
          [noOnboardToiletRequired] = action.payload.parking_spot_category_options.filter(category => (category.text.replaceAll(" ", "-").toLowerCase() === "no-onboard-toilet-required"));
        }
      }
      let attributes = null;
      let details = action.payload;
      if (!noOnboardToiletRequired && action.payload !== null){
        attributes = [...action.payload.parking_spot_category_options, {icon: "host_autarchy.svg", text: "Onboard Toilet required"}];
        details.parking_spot_category_options = attributes;
      }
      return Object.assign({}, state, !(action.setToEmpty) ? { parkingSpotDetails: details } : { parkingSpotDetails: null });
    case Types.SET_CATEGORY_OPTION_FILTERS:
      return Object.assign({}, state, { categoryOptionFilters: action.options });
    case Types.SET_LOCATION_FILTERS:
      return Object.assign({}, state, { locationFilters: action.location });
    case Types.SET_CHECKING_OUT:
      return Object.assign({}, state, { checkingOut: action.value });
    case Types.SET_CHECKING_IN:
      return Object.assign({}, state, { checkingIn: action.value });
    case Types.SET_CHECKING_IN_DETAILS_DIALOG_OPEN:
      return Object.assign({}, state, { checkInDetailsDialogOpen: action.value });
    case Types.SET_FILTERED_PARKING_SPOTS:
      let filteredSpotsObject = action.parkingSpots;
      filteredSpotsObject.timestamp = state.searchTimestamp;
      return Object.assign({}, state, { filteredParkingSpots: filteredSpotsObject });
    case Types.SET_IS_SEARCHING:
      return Object.assign({}, state, { isSearching: action.value });
    case Types.SET_TOOLTIP_CARD_MOBILE:
      return Object.assign({}, state, { tooltipCardMobile: {
        spot: action.spot,
        spotId: action.spot?.tag,
        isCheckedIn: action.isCheckedIn,
        color: action.color
      }});
    case Types.SET_GEOLOCATION_EDIT_PARKING_SPOT:
      return Object.assign({}, state, { editParkingSpot: {
        geolocation: action.geolocation,
        address: state.editParkingSpot.address,
      }});
    case Types.SET_ADDRESS_EDIT_PARKING_SPOT:
      return Object.assign({}, state, { editParkingSpot: {
        geolocation: state.editParkingSpot.geolocation,
        address: action.address,
      }});
    case Types.SET_BOOKINGS_HOST:
      return Object.assign({}, state, { bookingsHost: {
        payload: action.payload
      }});
    case Types.SET_FETCHING_BOOKINGS_RECEIPT:
      return Object.assign({}, state, { fetchingBookingsReceipt: {id: action.id, status: action.payload, printCSV: action.printCSV} });
    case Types.ADD_CAROUSEL_STATE:
      let newCarouselState = {...state.carouselState};
      newCarouselState[action.key] = action.value;
      return Object.assign({}, state, { carouselState: newCarouselState});
    case Types.SET_CAROUSEL_STATE:
      let newCarousel = {...state.carouselState};
      if (action.clear){
        newCarousel = {};
      }
      else if (newCarousel[action.key] !== null){
        newCarousel[action.key] = action.value;
      }
      return Object.assign({}, state, { carouselState: newCarousel});
    case Types.SET_SEARCH_STRING:
      return Object.assign({}, state, { searchString: action.payload });
    case Types.SET_MOBILE_BOTTOM_PANEL:
      return Object.assign({}, state, { mobileBottomPanel: action.value });
    case Types.SET_ENABLE_REVIEW:
      return Object.assign({}, state, { enableReview: action.value });
    case Types.SET_SHOW_MAP:
      return Object.assign({}, state, { showMap: action.value });
    case Types.SET_FOCUSED_SPOT:
      return Object.assign({}, state, { focusedSpot: action.value });
    case Types.INITIALIZE_PARKING_SPOT_DETAILS_SEARCH:
      let cardDataInit = {};
      let pinDataInit = [];
      return Object.assign({}, state, { parkingSpotDetailsSearch: action.payload ? action.payload : {cardData: cardDataInit, pinData: pinDataInit} });
    case Types.PRUNE_PARKING_SPOT_DETAILS_SEARCH:
      let cardDataPrev = state.parkingSpotDetailsSearch.cardData;
      let cardDataNew = {};
      let pinDataNew = [];
      state.filteredParkingSpots.parking_spots.forEach((spot) => {
        if (cardDataPrev[`${spot}`]) {
          cardDataNew[`${spot}`] = cardDataPrev[`${spot}`];
          pinDataNew.push(cardDataPrev[`${spot}`]);
        }
      })
      return Object.assign({}, state, { parkingSpotDetailsSearch: {cardData: cardDataNew, pinData: pinDataNew} });
    case Types.SET_PARKING_SPOT_DETAILS_SEARCH:
      let cardData = {...state.parkingSpotDetailsSearch.cardData};
      let newCardData = {...state.tempSearchResults.cardData};
      let pinData = null;
      let newPinData = null;
      if (state.searchResultsLoader > 1) {
        if (newCardData && !newCardData[`${action.tag}`]){
          newCardData[`${action.tag}`] = action.payload;
        }
        newPinData = [...state.tempSearchResults.pinData, action.payload]
      }
      else {
        if (cardData && !cardData[`${action.tag}`]){
          cardData[`${action.tag}`] = action.payload;
        }
        for (let tag in state.tempSearchResults.cardData) {
          cardData[`${tag}`] = state.tempSearchResults.cardData[`${tag}`];
        }
        pinData = [...state.parkingSpotDetailsSearch.pinData, ...state.tempSearchResults.pinData, action.payload]
        newCardData = {};
        newPinData = [];
      }
      return Object.assign({}, state, {
        parkingSpotDetailsSearch: {cardData, pinData: pinData ? pinData : state.parkingSpotDetailsSearch.pinData},
        tempSearchResults: {cardData: newCardData, pinData: newPinData ? newPinData : state.tempSearchResults.pinData}
       });
    case Types.SET_REVIEW_FOR_BOOKING:
      return Object.assign({}, state, { reviewForBooking: action.payload });
    case Types.SET_SEARCH_TIMESTAMP:
      return Object.assign({}, state, { searchTimestamp: Date.now() });
    case Types.SET_SEARCH_MAP_BOUNDS:
      return Object.assign({}, state, { searchMapBounds: action.value });
    case Types.SET_EXTERNAL_SEARCH_LOCATION:
      return Object.assign({}, state, { externalSearchLocation: action.value });
    case Types.SET_USER_LOCATION:
      return Object.assign({}, state, { userLocation: action.value });
    case Types.SET_CENTER_MARKER_COORDS:
      return Object.assign({}, state, { centerMarkerCoords: action.value });
    case Types.SET_SEARCH_MAP_CENTER_AND_ZOOM:
      return Object.assign({}, state, { searchMapCenterAndZoom: action.value });
    case Types.SET_SEARCH_RESULTS_LOADER:
      let initialSearchResultsLoaderValue = (state.filteredParkingSpots.parking_spots && state.parkingSpotDetailsSearch.pinData) ? Math.max(state.filteredParkingSpots.parking_spots.length - state.parkingSpotDetailsSearch.pinData.length, 0) : 0;
      return Object.assign({}, state, { searchResultsLoader: action.value ? action.value : initialSearchResultsLoaderValue });
    case Types.DECREMENT_SEARCH_RESULTS_LOADER:
      return Object.assign({}, state, { searchResultsLoader: Math.max(state.searchResultsLoader - 1, 0) });
    case Types.SET_CONTINUING:
      return Object.assign({}, state, { continuing: action.value });
    case Types.SET_FINISHING:
      return Object.assign({}, state, { finishing: action.value });
    default:
      return state;
  };
};
