import { makeStyles, Typography, withStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  mb4: {
    "& + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const InputLabel = withStyles((theme) => ({
  root: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}))(({ children, bottomGutter, className, required, ...props }) => {
  const classes = useStyles();
  return (
    <Typography
      variant="h5"
      component="label"
      {...props}
      className={clsx(
        {
          [classes.mb4]: bottomGutter,
        },
        className
      )}
    >
      {children} {required ? "*" : ""}
    </Typography>
  );
});

export default InputLabel;
