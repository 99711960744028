import * as Types from './constants';

export const fetchFinancials = (token, requestUrl) => {
  return {
    type: Types.FETCH_FINANCIALS,
    token,
    requestUrl
  };
};

export const fetchGuestPayments = (token) => {
  return {
    type: Types.FETCH_GUEST_PAYMENTS,
    token
  };
}

export const fetchGuestPaymentsFulfilled = (data, error) => {
  return {
    type: Types.FETCH_GUEST_PAYMENTS_FULFILLED,
    data,
    error,
  };
};

export const fetchGuestWithdrawals = (token) => {
  return {
    type: Types.FETCH_GUEST_WITHDRAWALS,
    token,
  };
};

export const fetchGuestWithdrawalsFulfilled = (data, error) => {
  return {
    type: Types.FETCH_GUEST_WITHDRAWALS_FULFILLED,
    data,
    error,
  };
};

export const withdrawGuestUserBalance = (token) => {
  return {
    type: Types.WITHDRAW_GUEST_USER_BALANCE,
    token,
  };
};

export const withdrawGuestUserBalanceFulfilled = (data, error) => {
  return {
    type: Types.WITHDRAW_GUEST_USER_BALANCE_FULFILLED,
    data,
    error,
  };
};

export const setFinancials = (payload) => {
  return {
    type: Types.SET_FINANCIALS,
    payload,
  };
};

export const postPaymentSchedule = (payoutSchedule, token) => {
  return {
    type: Types.POST_PAYMENT_SCHEDULE,
    payoutSchedule,
    token,
  };
};

export const postWithdrawBalance = (token) => {
  return {
    type: Types.POST_WITHDRAW_BALANCE,
    token,
  };
};

export const setRepaymentFailedDialogOpen = (value) => {
  return {
    type: Types.SET_REPAYMENT_FAILED_DIALOG_OPEN,
    value,
  };
};

export const setFile = (documentType, payload) => {
  return {
    type: Types.SET_FILE,
    documentType,
    payload,
  };
};

export const fetchGuestPaymentDetails = (token) => {
  return {
    type: Types.FETCH_GUEST_PAYMENT_DETAILS,
    token,
  }
};

export const setGuestPaymentDetails = (payload) => {
  return {
    type: Types.SET_GUEST_PAYMENT_DETAILS,
    payload,
  };
};

export const setWithdrawing = (value) => {
  return {
    type: Types.SET_WITHDRAWING,
    value,
  };
};
export const fetchStripeStatus = (token) => {
  return {
    type: Types.FETCH_STRIPE_STATUS,
    token,
  };
};

export const setStripeStatus = (payload) => {
  return {
    type: Types.SET_STRIPE_STATUS,
    payload,
  };
};

export const fetchSingleFinancialsReceipt = (token, id, name) => {
  return {
    type: Types.FETCH_SINGLE_FINANCIALS_RECEIPT,
    token,
    id,
    name
  };
}

export const fetchAllFinancialsReceipt = (token, printCSV=false) => {
  return {
    type: Types.FETCH_ALL_FINANCIALS_RECEIPT,
    token,
    printCSV
  };
}

export const setFetchingFinancialsReceipt = (id, payload, printCSV=false) => {
  return {
    type: Types.SET_FETCHING_FINANCIALS_RECEIPT,
    id,
    payload,
    printCSV
  };
}

export const setCardPaymentFailed = (value) => {
  return {
    type: Types.SET_CARD_PAYMENT_FAILED,
    value
  };
}

export const postPaymentConnect = (token, businessType) => {
  return {
    type: Types.POST_PAYMENT_CONNECT,
    businessType,
    token,
  };
};

export const guestPaymentConnect = (token, country) => {
  return {
    type: Types.GUEST_PAYMENT_CONNECT,
    token,
    country,
  };
};

export const patchPaymentDetails = (token) => {
  return {
    type: Types.PATCH_PAYMENT_DETAILS,
    token,
  };
};

export const setPaymentDetails = (payload) => {
  return {
    type: Types.SET_PAYMENT_DETAILS,
    payload,
  };
};

export const fetchPaymentSecret = (token) => {
  return {
    type: Types.FETCH_PAYMENT_SECRET,
    token,
  };
};

export const setPaymentSecret = (value) => {
  return {
    type: Types.SET_PAYMENT_SECRET,
    value,
  };
};

export const resetHostPaymentDetails = (token) => {
  return {
    type: Types.RESET_HOST_PAYMENT_DETAILS,
    token,
  };
};

export const resetHostPaymentDetailsFulfilled = (value, error) => {
  return {
    type: Types.RESET_HOST_PAYMENT_DETAILS_FULFILLED,
    value,
    error,
  };
};

export const resetHostPaymentDetailsInitialData = () => {
  return {
    type: Types.RESET_HOST_PAYMENT_DETAILS_INITIAL_DATA,
  };
};