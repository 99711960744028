import { Box, Card, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'block',
    width: "100%",
    padding: theme.spacing(3),
    textDecoration: "none",
    overflow: "hidden",
  },
  icon: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.0rem",
    },
  },
}));

function DashboardLinkCard({ Icon, title, description, link }) {
  const classes = useStyles();
  return (
    <Card className={classes.card} component={Link} to={link} elevation={0}>
      <Box display="flex" flexDirection="row">
        <Icon className={classes.icon} />
      </Box>
      <Box pt={1} display="flex" style={{ height: "2.5rem" }}>
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Typography variant="caption">{description}</Typography>
    </Card>
  );
}

export default DashboardLinkCard;
