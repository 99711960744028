import React, { useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Divider, Box, CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useForm, Controller } from "react-hook-form";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import OutlinedFormField from '../../common/outlinedFormField';
import { resetPassword } from '../../../data/actions/auth';
import { useStyles } from '../common/styles';
import { useTranslation } from 'react-i18next';
import { setUpdatingUserPassword } from '../../../data/actions/user';

const defaultValues = {
  password: "",
};

const ResetPassword = ({ resetPassword, setUpdatingUserPassword, updatingUserPassword }) => {
  const classes = useStyles();
  const location = useLocation();
  const { handleSubmit, control, setValue } = useForm({ defaultValues });
  const { t } = useTranslation();

  const fullName = qs.parse(location.search).full_name;
  const email = qs.parse(location.search).email;

  useEffect(() => {
    if (fullName && email) {
      setValue("fullName", fullName);
      setValue("email", email);
    }
  }, [fullName, email])

  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onSubmit = (data) => {
    const passwordResetToken = qs.parse(location.search).reset_password_token;
    const params = {
      ...data,
      passwordResetToken,
    };
    setUpdatingUserPassword(true);
    resetPassword(params, t("on-boarding.password-reset-success"));
  };

  return (
    <Card className={classes.onboardingCard}>
      <Box px={3} py={3} display="flex" className={classes.onboardingTitle}>
        <Typography variant="h4" color="textPrimary">
          { t('on-boarding.enter-new-password') }
        </Typography>
      </Box>
      <CardContent style={{padding: "0.125rem 1.5rem 1.125rem"}} className={classes.onboardingContent}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.root} autoComplete="off">
          { (fullName && email) && (
            <React.Fragment>
              <OutlinedFormField name="fullName" type="text" control={control} disabled />
              <OutlinedFormField name="email" type="email" control={control} disabled />
            </React.Fragment>
          )}
          <OutlinedFormField label={ t('common.labels.password') } name="password" type="password" control={control} required />
          <Box display="flex" flexDirection="row-reverse" width="100%" pt={1.5}>
            <Typography variant="caption" color="secondary">{ t('common.labels.password-length') }: 10</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button color='secondary' to="/login" component={Link} className={classes.secondaryButton}>
              <Typography variant="body1">{ t('on-boarding.click-to-login') }</Typography>
            </Button>
            <Box className={classes.buttonWrapperNoMarginBottom}>
              <Button variant="contained" color="primary" type="submit" className={classes.actionBox} disabled={updatingUserPassword}>
                { t('common.btn.continue') }
              </Button>
              { updatingUserPassword && <CircularProgress size={24} className={classes.buttonProgress} /> }
            </Box>
          </Box>
        </form>
      </CardContent>
    </Card>
  )
};

const mapStateToProps = (state) => {
  return {
    updatingUserPassword: state.user.updatingUserPassword,
  };
};

export default connect(mapStateToProps, { resetPassword, setUpdatingUserPassword })(ResetPassword);
