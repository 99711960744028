import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { en, de, it, es, fr, pl } from '@pns/translations'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "de",
    fallbackLng: "en",
    debug: true,
    react: {useSuspense: false},
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: en
      },
      de: {
        translation: de
      },
      it: {
        translation: it
      },
      fr: {
        translation: fr
      },
      es: {
        translation: es
      },
      pl: {
        translation: pl
      }
    }
  });

export default i18n;
