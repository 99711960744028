import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  backButton: {
    borderRadius: "0.65rem",
    padding: "0.3rem",
    backgroundColor: "#fff",
    width: "2rem",
    height: "2rem",
    minWidth: "1rem",
    marginRight: "1rem",
    border: "1px solid #E7F0F0",
    fontSize: "0.5rem",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  backArrow: {
    color: theme.palette.primary.main,
    width: "70%",
    height: "70%",
  },
  sectionLink: {
    textDecoration: "none",
  },
  caption2: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0em",
  },
  breadcrumbs: {
    marginLeft: "3.9rem",
  },
  pageTitle: {
    marginLeft: "1rem",
  },
}));

