import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Box, CircularProgress } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import { ReactComponent as UploadPictureIcon } from "../../../../src/assets/images/icons/upload-picture.svg";
import SvgIcon from '@material-ui/core/SvgIcon';

import { useStyles } from './styles';

export const PhotoPreview = ({ deleteLocalHandler, deleteAction, previewPhotos, loading, largePreview, max, placeholderIcon: Icon, successIcon: SuccessIcon, success, isProfilePicture, isCustomPlaceholder }) => {
  const classes = useStyles();
  const previewClasses = clsx(classes.preview, isCustomPlaceholder && "custom", largePreview && "large", (max > 1 ? "multiple" : "single"));
  const imageClasses = clsx(classes.uploadedImage, isCustomPlaceholder && "custom", largePreview && "large", (max > 1 ? "multiple" : "single"));
  const placeholderClasses = clsx(classes.photoIcon, largePreview && "large", (max > 1 ? "multiple" : "single"));
  const successIconClasses = clsx(classes.photoIcon, largePreview && "successIcon");
  const imageWrapperClasses = clsx(classes.imageWrapper, isCustomPlaceholder && "custom", largePreview && "large", (max > 1 ? "multiple" : "single"));
  const photos = previewPhotos.filter((p) => p);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const previewsPerRow = isDesktop ? 4 : 2;
  let spacers = previewsPerRow - (Math.max(photos.length, max) % previewsPerRow);
  const { t } = useTranslation();

  if (previewsPerRow === spacers || max === 1) {
    spacers = 0;
  }

  const previews = [
    ...(photos.map((image, index) => {
      return !isProfilePicture ? (
        <Box className={imageWrapperClasses} key={`img-preview-${index}`}>
          <CardMedia
            component="img"
            image={image.url}
            classes={{ img: imageClasses }}
          />
          {
            deleteAction &&
            image.id &&
            <CancelIcon className={classes.cancelIcon} onClick={() => {
              deleteAction(image.id)
            }} />
          }
          {
            image.url &&
            !image.id &&
            <CancelIcon className={classes.cancelIcon} onClick={() => {
              deleteLocalHandler(index)
            }} />
          }
        </Box>
      ) : (
        <Box className={classes.profilePictureWrapper} key={`img-preview-${index}`}>
          <CardMedia
            component="img"
            image={image.url}
            classes={{ img: imageClasses }}
          />
        </Box>
      )
    })),
    ...([...Array(Math.max(max - photos.length, 0))].map((i, index) => {
      if (!isCustomPlaceholder) {
        return (
          <Box className={imageWrapperClasses} key={`img-placeholder-${index}`}>
            {!success ? <Icon className={placeholderClasses} /> :  <SuccessIcon className={successIconClasses} />}
          </Box>
        )
      } else { return [] }
    })),
  ];

  if (isCustomPlaceholder) {
    return (
      <Card className={classes.customPreview}>
        { loading ? <CircularProgress className={classes.loader} /> : previews }
      </Card>
    );
  }

  return (
    <Card className={!isProfilePicture ? previewClasses : classes.profilePicturePreview}>
      { loading ? <CircularProgress className={classes.loader} /> : previews }
    </Card>
  );
};

const getColor = (props) => {
  if (props.isDragAccept) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isDragActive) {
      return '#2196f3';
  }
  return '#eeeeee';
}

const DropzoneContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.75rem 1rem !important;
  margin: 1.25rem 0;
  border-radius: 0.75rem;
  background-color: #F2F9FA;
  color: #008A94;
  font-weight: 400;
  outline: none;
  transition: border .24s ease-in-out;
`;

export const PhotoFormSection = ({ previewPhotos, largePreview, postPhotoAction, deleteAction, uploadPath, uploadHandler, loading, setLoader, max, placeholderIcon, purpose, filePath, success, successIcon, dragAndDrop, isProfilePicture, isCustomPlaceholder }) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [uploadedFromButton, setUploadedFromButton] = useState(false);
  let fileTooBig = false;
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleImageChange = e => {
    let images = null;
    if (acceptedFiles[0]){
      images = acceptedFiles;
    }
    if (e && e.target) {
      images = e.target.files;
      setUploadedFromButton(true);
    }
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        if (images[i].size > 10485760){
          fileTooBig = true;
        }
      }
      if(!fileTooBig){
        if (uploadHandler) { // Delegate upload to form
          const photoUrls = [...images].map((file) => {
            return URL.createObjectURL(file)
          });
          if (max > 1) {
            setPhotos([
              ...photos,
              ...(photoUrls.map(photo => ({url: photo}))),
            ]);
            uploadHandler([
              ...files,
              ...(images),
            ]);
            setFiles([
              ...files,
              ...(images),
            ]);
          } else {
            setPhotos([
              ...(photoUrls.map(photo => ({url: photo}))),
            ]);
            uploadHandler([
              ...(images),
            ]);
            setFiles([
              ...(images),
            ]);
          }
        } else { // Upload directly one by one
          const formDataPath = uploadPath;
          [...images].forEach(file => {
            const photoData = new FormData();
            if (purpose) {
              photoData.append('purpose', purpose)
            }
            if (filePath) {
              photoData.append('file', file)
            }
            else {
              photoData.append(formDataPath, file);
            }
            setLoader(true);
            postPhotoAction(photoData);
          })
          if (e && e.target){
            e.target.value = null;
          }
        }
      }
      else {
        enqueueSnackbar(
          {
            text: "File size too large (> 10MB)",
            variant: "error",
          },
          {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        );
        fileTooBig = false;
      }
    }
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'image/png, image/jpeg, image/jpg, image/webp, image/raw, image/bmp'
  });

  useEffect(() => {
    if (acceptedFiles.length > 0){
      handleImageChange();
    }
  }, [acceptedFiles])

  const handleDelete = (index) => {
    setPhotos([
      ...photos.slice(0, index),
      ...photos.slice(index + 1)
    ]);
    uploadHandler([
      ...files.slice(0, index),
      ...files.slice(index + 1)
    ]);
    setFiles([
      ...files.slice(0, index),
      ...files.slice(index + 1)
    ]);
  };

  return (
    <React.Fragment>
      { (!dragAndDrop || acceptedFiles.length > 0 || uploadedFromButton) ? (
        <PhotoPreview
          previewPhotos={photos.length ? photos : (previewPhotos || [])}
          loading={loading}
          largePreview={largePreview}
          max={max || 1}
          placeholderIcon={placeholderIcon}
          success={success}
          successIcon={successIcon}
          deleteAction={deleteAction}
          deleteLocalHandler={handleDelete}
          isProfilePicture={isProfilePicture}
          isCustomPlaceholder={isCustomPlaceholder}
        />
        ) : (
          <div className="container">
            <DropzoneContainer {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
              <input {...getInputProps()} />
              <Box height="5rem" display="flex" alignItems="center" justifyContent="center">
                <p>{ t("common.texts.click-to-upload") }</p>
              </Box>
            </DropzoneContainer>
          </div>
        )
      }
      <Box display="flex" flexDirection="row" className={isCustomPlaceholder ? classes.customImageButtonWrapper : ""}>
        <Button
          variant="outlined"
          component="label"
          className={isCustomPlaceholder ? classes.customImageButtonWrapper : classes.imageButtonWrapper}
        >
          <SvgIcon component={UploadPictureIcon} className={classes.imageIcon} viewBox="0 -2 19 19" />
          { t("common.btn.upload") }
          <input
            type="file"
            name="photo"
            accept="image/png, image/jpeg, image/jpg, image/webp, image/raw, image/bmp"
            hidden
            onChange={handleImageChange}
            disabled={loading}
            multiple={max && max > 1}
          />
        </Button>
      </Box>
    </React.Fragment>
  );
};
