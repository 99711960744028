import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Select, MenuItem, TextField, Typography, CircularProgress, InputAdornment } from '@material-ui/core';
import { Controller, useWatch } from 'react-hook-form';
import InputMask from 'react-input-mask';
import {ReactComponent as DropdownArrow} from "../../../assets/images/dropdown-arrow.svg";

const useStyles = makeStyles((theme) => ({
  adornmentText: {
    marginTop: "0.125rem",
    marginRight: "0.5rem",
    fontSize: "12px",
  },
  textField: {
    width: "100%",
  },
  compactFieldWrapper: {
    marginTop: "0.75rem",
    marginRight: "1em",
    width: "100%",
    "&:last-child": {
      marginRight: "0em",
    },
  },
  textFieldWrapper: {
    marginTop: "1.25rem",
    marginRight: "1rem",
    width: "100%",
    "&:last-child": {
      marginRight: "0rem",
    },
  },
  telCodeSelect: {
    width:"30%",
    marginRight: "0.5rem"
  },
  progressBottom: {
    color: theme.palette.grey[300],
  },
  progressTop: {
    position: "absolute",
    left: 0,
  },
  multilineCounter: {
    marginTop: "-2rem",
    marginRight: "0.8rem",
    marginBottom: "2.1rem",
  }
}));

const FormField = ({ name, control, flex, phone, limit, type, compact, ...rest }) => {
  let textCap = null;
  if (type === "text") {
    if (limit) {
      textCap = limit;
    } else if (rest.multiline) {
      textCap = 1000;
    } else {
      textCap = 150;
    }
  }

  const classes = useStyles();
  const [telMask, setTelMask] = React.useState("+41999999999")
  const value = useWatch({
    control,
    name: name,
  });

  const handleTelChange = (value) => {
    let val = value.target.value;
    if (val === "Switzerland"){
      setTelMask("+41999999999");
    }
    else if (val === "Germany"){
      setTelMask("+4\\999999999999");
    }
    else if (val === "France"){
      setTelMask("+33999999999");
    }
    else if (val === "Italy"){
      setTelMask("+3\\99999999999");
    }
    else if (val === "Austria"){
      setTelMask("+4399999999999");
    }
    else if (val === "Australia"){
      setTelMask("+6199999999");
    }
    else {
      setTelMask("+41999999999");
    }
  }

  return (
    <Box position="relative" flex={flex} className={compact ? classes.compactFieldWrapper : classes.textFieldWrapper}>
      <Controller as={
        phone ? (
          <Box display="flex" alignItems="center">
            <Select
              labelId="country-tel-select-label"
              required
              onChange={(event) => handleTelChange(event)}
              className={classes.telCodeSelect}
              IconComponent={(props) => (<DropdownArrow {...props}/>)}
            >
              <MenuItem value="Switzerland">Switzerland</MenuItem>
              <MenuItem value="Germany">Germany</MenuItem>
              <MenuItem value="Austria">Austria</MenuItem>
              <MenuItem value="France">France</MenuItem>
              <MenuItem value="Italy">Italy</MenuItem>
            </Select>
            <InputMask
              mask={telMask}
              disabled={false}
              maskChar=" "
            >
              { () => <TextField className={classes.textField} variant="outlined" {...rest} /> }
            </InputMask>
          </Box>
        ) : (
          <TextField
            className={classes.textField}
            InputProps={{
              ...(!!textCap && !rest.multiline ? { endAdornment: (
                <InputAdornment position="end">
                  <Box position="relative" display="flex" flexDirection="row-reverse" alignItems="center">
                    <Box position="relative" display="flex" flexDirection="column" alignItems="center">
                      <CircularProgress variant="determinate" size={20} value={100} className={classes.progressBottom} />
                      <CircularProgress variant="determinate" size={20} value={Number((value ? value.length : 0)/textCap * 100)} className={classes.progressTop} />
                    </Box>
                    <Typography variant="caption" className={classes.adornmentText}>{`${(value ? value.length : 0)} / ${textCap}`}</Typography>
                  </Box>
                </InputAdornment>
              ) } : {}),
            }}
            {...(!!textCap ? {inputProps: { maxLength: textCap }} : {})}
            variant="outlined"
            {...(!!type ? {type} : {})}
            {...rest}
          />
        )
      } name={name} control={control} />
      { !!textCap && rest.multiline && (
        <Box position="relative" display="flex" flexDirection="row-reverse" alignItems="center" className={classes.multilineCounter}>
          <Box position="relative" display="flex" flexDirection="column" alignItems="center">
            <CircularProgress variant="determinate" size={20} value={100} className={classes.progressBottom} />
            <CircularProgress variant="determinate" size={20} value={Number((value ? value.length : 0)/textCap * 100)} className={classes.progressTop} />
          </Box>
          <Typography variant="caption" className={classes.adornmentText}>{`${(value ? value.length : 0)} / ${textCap}`}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default FormField;
