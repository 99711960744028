import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import { ReactComponent as ChevronBackSVG } from "../../../assets/images/chevron-back.svg";

import { useStyles } from "./styles";

const TitleSection = ({ routes, title, after }) => {
  const classes = useStyles();
  return (
    <Box
      pb={1.5}
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Box>
        {routes.length ? (
          <Box pb={1} display="flex">
            <Button
              variant="outlined"
              size="small"
              component={Link}
              className={classes.filterButton}
              to={routes[routes.length - 1].path}
            >
              <ChevronBackSVG className={classes.filterButtonIcon} />
            </Button>
            <Typography variant="h1" className={classes.pageTitle}>
              {title}
            </Typography>
          </Box>
        ) : null}
        <Breadcrumbs
          separator="›"
          aria-label="breadcrumb"
          className={classes.breadcrumbs}
        >
          {routes.map((route) => (
            <Link
              key={`linkTo${route.path}`}
              to={route.path}
              className={classes.sectionLink}
            >
              <Typography
                variant="caption"
                className={classes.caption2}
                color="secondary"
              >
                {route.name}
              </Typography>
            </Link>
          ))}
          <Typography
            variant="caption"
            color="secondary"
            className={classes.caption2}
          >
            {title}
          </Typography>
        </Breadcrumbs>
      </Box>
      {after}
    </Box>
  );
};

export default TitleSection;
