import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 80,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: "3px 4px",
    '&$checked': {
      transform: 'translateX(53px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.grey[300],
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    [theme.breakpoints.up("sm")]: {
      '&:before': {
        content: '"active"',
        height: "100%",
        top: "-100%",
        paddingTop: "38%",
        position: "absolute",
        left: "0.8rem",
        color: "#fff",
        opacity: 0,
      },
      '&:after': {
        content: '"paused"',
        height: "100%",
        top: "-100%",
        paddingTop: "38%",
        position: "absolute",
        right: "0.5rem",
        color: "#000",
      },
    },
    [theme.breakpoints.down("xs")]: {
      '&:before': {
        content: '"active"',
        height: "100%",
        top: "-130%",
        paddingTop: "50%",
        position: "absolute",
        left: "0.8rem",
        color: "#fff",
        opacity: 0,
      },
      '&:after': {
        content: '"paused"',
        height: "100%",
        top: "-130%",
        paddingTop: "50%",
        position: "absolute",
        right: "0.8rem",
        color: "#000",
      },
    }
  },
  checked: {
    '& + $track': {
      '&:before': {
        opacity: 1,
      },
      '&:after': {
        opacity: 0,
      }
    },
  },
  focusVisible: {},
}));

const CustomSwitch = (props) => {
  const classes = useStyles();
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};

export default CustomSwitch;
