import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Card, useTheme, useMediaQuery, Divider, Typography, Chip, SvgIcon, Checkbox, CardActionArea, CircularProgress, Dialog, DialogTitle, DialogContent, Badge, Link as MuiLink } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import CloseIcon from '@material-ui/icons/Close';
import StarIcon from '@material-ui/icons/Star';
import { connect, useDispatch, useSelector } from 'react-redux';
import Geosuggest from 'react-geosuggest';
import CheckIcon from '@material-ui/icons/Check';
import './search.css';
import { useStyles } from '../common/styles';
import { ReactComponent as PinSVG } from '../../../assets/images/location-pin.svg';
import { ReactComponent as UnCheckedIcon } from '../../../assets/images/un-checked-icon.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/images/checked-icon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/search-icon.svg';
import { ReactComponent as ListIcon } from '../../../assets/images/list-icon.svg';
import { ReactComponent as FilterIcon } from '../../../assets/images/filter-icon.svg';
import { formatCountry } from '../../../lib/utils';
import {
  fetchPriceFilters,
  searchParkingSpots,
  setPriceRangeFilter,
  fetchParkingSpotAttributes,
  fetchCurrentCheckIns,
  setCategoryOptionFilters,
  setLocationFilters,
  setTooltipCardMobile,
  setCurrentCheckIns,
  setIsSearching,
  setParkingSpotDetails,
  setCarouselState,
  setSearchStringGlobal,
  setShowMap,
  setFocusedSpot,
  setSearchMapBounds,
  cancelSearch,
  setExternalSearchLocation,
  setFilteredParkingSpots,
  setSearchResultsLoader,
  setCenterMarkerCoords,
  setSearchMapCenterAndZoom,
  initializeParkingSpotDetailsSearch,
  fetchParkingSpotDetailsSearch
} from '../../../data/actions/parkingSpot';
import { getSVGForIcon } from '../../../helpers/assets';
import { getAttributeName } from '../../../helpers/attributeMap';
import ReplayIcon from '@material-ui/icons/Replay';
import mapLoader from '../../../assets/images/map-loader.gif';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import MapPinDynamic from "./mapPinDynamic"
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addLoader } from '../../../data/actions/config';
import SearchPagination from '../../common/searchPagination';
import ResultItem from './resultItem.js';
import { getAttributes } from '../../../helpers/mainAttributes';
import PriceFilterButton from './components/PriceFilterButton';

function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals).toFixed(decimals);
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const getClassForSVG = (icon, classes) => {
  if (icon.startsWith("host_type_")) {
    return classes.resultOutlinedItemSvgIcon;
  }
  else if (icon.startsWith("host_autarchy_not")) {
    return classes.resultLineItemSvgIcon;
  }
  return classes.resultItemSvgIcon;
}
const MapPin = ({ lat, lng, spot, isCheckedIn }) => {
  const classes = useStyles();
  const theme = useTheme();
  let color;
  if (!!spot.available_today && !!spot.spot_active){
    if (spot.capacity > 0 && (spot.occupied/spot.capacity) < 0.66){
      color = "green";
    }
    else if (spot.capacity > 0 && (spot.occupied/spot.capacity) < 1){
      color = "yellow";
    }
    else {
      color = "red";
    }
  }
  else{
    color = "grey";
  }
  return(
    <MapPinDynamic key={spot.id} color={color} spot={spot} isCheckedIn={isCheckedIn}>
      <Card className={classes.tooltipCard}>
        <CardActionArea
          component={Link}
          to={`/ps/${spot.tag}`}
        >
          {(spot.main_photo_url || spot.main_photo_thumbnail_url) && (
            <Box>
              <img className={classes.tooltipCardPicture} src={spot.main_photo_thumbnail_url ? spot.main_photo_thumbnail_url : spot.main_photo_url} alt="card spot" />
            </Box>
          )}
          <Box px={2} py={2} className={classes.tooltipCardContent}>
            <Box display="flex">
              <Box flex="auto">
                <Typography variant="h4">{spot.name}</Typography>
                {
                  (!!spot.location) &&
                  <Box display="flex" alignItems="center">
                    <PinSVG style={{fill: "rgb(142, 142, 153)", marginRight: "0.375rem"}} />
                    <Typography variant="caption" style={{color: theme.palette.secondary.main }}>
                      {formatCountry(spot.location)}
                    </Typography>
                  </Box>
                }
              </Box>
              <Box display={isCheckedIn ? "flex" : "none"} className={classes.cardCheckedInChip} justifyContent="center" alignItems="center">
                <CheckIcon className={classes.checkIcon} />
              </Box>
            </Box>
            <Box py={1}>
              {spot && getAttributes(spot, true) && getAttributes(spot, true).map((option) => {
                const Icon = getSVGForIcon(option.icon);
                return !!Icon && <Icon fill={theme.palette.primary.main} className={clsx(classes.resultIcon, option.icon.startsWith("host_type_campsite") ? "campsiteIcon" : "")} viewBox={option.icon.startsWith("host_type_campsite") ? "-2 -5 78 78" : "0 0 24 24"} />
              })}
            </Box>
            <Box display="flex">
              <Box flex="auto" display="flex" alignItems="center">
                <StarIcon className={classes.starIcon} />
                <Box mt={0.25}>
                  <Typography variant="caption">{spot.review_count > 0 ? (Math.round(spot.avg_rating * 10) / 10).toFixed(1) : "N/A"}</Typography>
                </Box>
              </Box>
              <Box>
                {
                  spot && !!spot.available_today && !!spot.spot_active
                  ?
                  <Chip label={`${spot.capacity - spot.occupied} / ${spot.capacity}`} classes={{label: classes.chipLabel}} variant="outlined" className={(spot.occupied === spot.capacity) ? classes.chipFull : (color === "green") ? classes.chip : classes.chipYellow} />
                  :
                  <Chip label={`0 / ${spot.capacity}`} classes={{label: classes.chipLabel}} variant="outlined" className={classes.chipUnavailable} />
                }
              </Box>
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </MapPinDynamic>
  )
};

const FiltersMap = React.memo(({classes}) => {
  const categoriesOptionFilters = useSelector(state => state.parkingSpot.categoryOptionFilters)
  const parkingSpotAttributes = useSelector(state => state.parkingSpot.parkingSpotAttributes)
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const returnIcon = (iconName, color="primary") => {
    if (iconName.startsWith("surrounding_protected")){
      iconName = "surrounding_protected_area.svg";
    }
    const icon = getSVGForIcon(iconName);
    return icon && <SvgIcon component={icon} fill={color === "primary" ? theme.palette.primary.main : "#333"} viewBox={iconName.startsWith("host_type_campsite") ? "-2 -5 78 78" : "0 0 24 24"} />
  }

  let psAttributeMap = [{},{},{},{},{},{},{},{},{}];
  [psAttributeMap[0]] = parkingSpotAttributes.filter(att => att.name === "Place Type");
  [psAttributeMap[1]] = parkingSpotAttributes.filter(att => att.name === "Behaviour");
  [psAttributeMap[2]] = parkingSpotAttributes.filter(att => att.name === "Autarchy");
  [psAttributeMap[3]] = parkingSpotAttributes.filter(att => att.name === "Car size");
  [psAttributeMap[4]] = parkingSpotAttributes.filter(att => att.name === "Approach");
  [psAttributeMap[5]] = parkingSpotAttributes.filter(att => att.name === "Terrain");
  [psAttributeMap[6]] = parkingSpotAttributes.filter(att => att.name === "Season");
  [psAttributeMap[7]] = parkingSpotAttributes.filter(att => att.name === "Service");
  [psAttributeMap[8]] = parkingSpotAttributes.filter(att => att.name === "Surrounding");

  const handleOptionChange = (event, id) => {
    dispatch(setCategoryOptionFilters({
      ...categoriesOptionFilters,
      [id]: !categoriesOptionFilters[id],
    }))
  };

  return (
    <Box style={{marginLeft: "1rem"}}>
    {
      psAttributeMap && psAttributeMap.map((category, index, array) => {
        let boxTitle = "";
        let attributeCategory = "";
        if (category && category.name){
          switch(category.name.replaceAll(" ", "-").toLowerCase()) {
            case "place-type":
              attributeCategory = "host-information";
              boxTitle = "host";
              break;
            case "behaviour":
              attributeCategory = "host-information";
              boxTitle = "behaviour";
              break;
            case "autarchy":
              attributeCategory = "host-information";
              boxTitle = "autarchy";
              break;
            case "car-size":
              attributeCategory = "place-properties";
              boxTitle = "vehicle";
              break;
            case "approach":
              attributeCategory = "place-properties";
              boxTitle = "approach";
              break;
            case "terrain":
              attributeCategory = "place-properties";
              boxTitle = "terrain";
              break;
            case "season":
              attributeCategory = "place-properties";
              boxTitle = "season";
              break;
            case "service":
              attributeCategory = "service";
              boxTitle = "services";
              break;
            case "surrounding":
              attributeCategory = "surrounding";
              boxTitle = "surroundings";
              break;
            case "binary-options":
              attributeCategory = "binary-options-old";
              boxTitle = "binary";
              break;
            case "availability":
              attributeCategory = "availability";
              boxTitle = "availability";
              break;
            default:
              attributeCategory = "host-information";
              boxTitle = "host";
              break;
          }
        }
        return (category && category.name) ? (
          <Box className={classes.filterBoxCategoryWrapper}>
            <Box button={false} className={classes.filterBoxCategory}>
              <Box className={classes.filterBoxCategoryName}>
                <Typography variant="h4" className={classes.filterSubCategoryName}>{t(`spot-attributes.${attributeCategory}-category.box.${boxTitle}.box-title`)}</Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" className={classes.fliterBoxOptions}>
                { category.parking_spot_category_options.map((option) => {
                  return (`${option.text.replaceAll(" ", "-").toLowerCase()}` !== "only-offroad-vehicles" && `${option.text.replaceAll(" ", "-").toLowerCase()}` !== "caravan" && `${option.text.replaceAll(" ", "-").toLowerCase()}` !== "parking-spot-is-winter-friendly" && `${option.text.replaceAll(" ", "-").toLowerCase()}` !== "parking-spot-has-a-toilet-that-can-be-used" && `${option.text.replaceAll(" ", "-").toLowerCase()}` !== "summer-place") ? (
                    <Box className={!!categoriesOptionFilters[option.id] ? classes.filterIconBoxFocused : clsx(classes.filterIconBox, option.icon.startsWith("host_type_campsite") ? "campsiteIcon" : "")} onClick={(event) => handleOptionChange(event, option.id)}>
                      { option && returnIcon(option.icon) }
                      <Typography variant="caption" className={classes.filterText}>
                        { option ? t(getAttributeName(option)) : "" }
                      </Typography>
                    </Box>
                  )
                  :
                  (<Box display="none" />)
                }) }
              </Box>
              <Box pr={isMobile ? 2 : 3} display={(array.length - 1 !== index) ? "initial" : "none"}>
                <Divider className={classes.filterBoxDivider} />
              </Box>
            </Box>
          </Box>
        )
        :
        (null)
      })
    }
    </Box>
  )
})

const mapStyles = [
  {
    "featureType": "landscape.natural",
    "elementType": "labels.icon",
    "stylers": [
        {
          "visibility": "off"
        }
    ]
  },
  {
      "featureType": "administrative",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#444444"
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
          {
              "color": "#f2f2f2"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#e9e9e9"
          }
      ]
  },
  {
      "featureType": "poi.park",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#deebd8"
          },
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "all",
      "stylers": [
          {
              "saturation": -100
          },
          {
              "lightness": 45
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "road.arterial",
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
          {
              "color": "#c4e5f3"
          },
          {
              "visibility": "on"
          }
      ]
  }
];


const debounce = (func, timeout = 300) => {
  return (...args) => {
    const prevTimerId = parseInt(localStorage.getItem('timer'));
    clearTimeout(prevTimerId);
    const timer = setTimeout(() => { func.apply(this, args); }, timeout);
    localStorage.setItem('timer', timer);
  };
}

const clearPreviousSearch = (isSearching, cancelSearch, setSearchResultsLoader) => {
  const timer = localStorage.getItem('timer');
  if(timer) {
    clearTimeout(parseInt(timer));
  }
  if (isSearching) {
    cancelSearch();
    setSearchResultsLoader(0);
  }
}

const SearchMap = ({
  spots,
  checkIns,
  locationFilters,
  setLocationFilters,
  categoryOptionFilters,
  fetchPriceFilters,
  searchParkingSpots,
  priceRangeFilter,
  setPriceRangeFilter,
  isFilteredByOption,
  currentPage,
  isSearchOpen,
  searchMapBounds,
  setSearchMapBounds,
  cancelSearch,
  isSearching,
  externalSearchLocation,
  setExternalSearchLocation,
  setFilteredParkingSpots,
  isFocused,
  userLocation,
  externalSearchFlag,
  setExternalSearchFlag,
  searchResultsLoader,
  setSearchResultsLoader,
  searchTimestamp,
  searchGeolocationChanged,
  setSearchGeolocationChanged,
  gpsLocationTriggered,
  setGpsLocationTriggered,
  showMap,
  searchOnMapMove,
  setSearchOnMapMove,
  searchHere,
  setSearchHere,
  prevSearchOnMapMove,
  setPrevSearchOnMapMove,
  prevSearchHere,
  setPrevSearchHere,
  setCenterMarkerCoords,
  centerMarkerCoords,
  searchMapCenterAndZoom,
  setSearchMapCenterAndZoom,
  setTooltipCardMobile,
  tooltipCardMobile,
  isDesktop,
  currentCardData,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  let isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [center, setCenter] = useState((isFocused != null && searchMapCenterAndZoom && searchMapCenterAndZoom.center) ? searchMapCenterAndZoom.center : externalSearchLocation ? [...externalSearchLocation] : (userLocation && userLocation.latitude && userLocation.longitude) ? [userLocation.latitude, userLocation.longitude] : []);
  const [zoom, setZoom] = useState(10);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const intercomLinksLang = (() => {
    if(i18n.language === 'en' || i18n.language === 'it' || i18n.language === 'pl') {
      return 'en'
    } else {
      return i18n.language
    }
  })()

  useEffect(() => {
    if (externalSearchLocation){
      setCenter([...externalSearchLocation]);
    }
    else if (userLocation && userLocation.latitude && userLocation.longitude) {
      if (!externalSearchFlag){
        if (!isFocused && spots.length === 0){
          setCenter([userLocation.latitude, userLocation.longitude]);
        }
      }
      else {
        setExternalSearchFlag(false);
      }
    }
    else {
      setCenter([]);
    }
    return () => {
      if (externalSearchLocation) {
        setExternalSearchLocation(null);
      }
    }
  }, [userLocation])

  useEffect(() => {
    if (isFocused != null && searchMapCenterAndZoom && searchMapCenterAndZoom.center) {
      setCenter(searchMapCenterAndZoom.center);
      setZoom(searchMapCenterAndZoom.zoom);
    }

    if (currentCenter) {
      setCenter(currentCenter.toString().split(',').map(item => Number(item)))
    }

    navigator.geolocation.getCurrentPosition(function(position) {
      setCenterMarkerCoords({lat: position.coords.latitude, lng: position.coords.longitude});
    });
  }, []);

  useEffect(() => {
    if (locationFilters && locationFilters.length > 0 && isFocused == null) {
      setCenter([...locationFilters]);
      setZoom(10);
    }
  }, [locationFilters])

  useEffect(() => {
    if (isFocused == null) {
      if (externalSearchLocation){
        setCenter([...externalSearchLocation]);
      }
      setZoom(10);
    }
  }, [externalSearchLocation])

  useEffect(() => {
    if (!searchMapCenterAndZoom?.center?.length && !isSearchOpen && isFocused == null) {
      if (userLocation && userLocation.latitude && userLocation.longitude) {
        setCenter([userLocation.latitude, userLocation.longitude]);
      }
      else {
        setCenter([]);
      }
      setZoom(10);
    }
  }, [isSearchOpen, userLocation, isFocused])

  const pins = spots && spots.length > 0 && spots.map((spot) => {
    const lat = spot && spot.geolocation ? parseFloat(spot.geolocation.substring(spot.geolocation.lastIndexOf(" ") + 1, spot.geolocation.lastIndexOf(")"))) : 0;
    const lng = spot && spot.geolocation ? parseFloat(spot.geolocation.substring(spot.geolocation.indexOf("(") + 1, spot.geolocation.lastIndexOf(" "))) : 0;

    const [booking] = checkIns.filter(checkin => checkin.parking_spot.tag === spot.tag);
    if (!!lat && !!lng) {
      return(
        <MapPin
          lat={lat}
          lng={lng}
          spot={spot}
          isCheckedIn={!!booking}
        />
      )
    }
    return null;
  }).filter(pin => pin);

  const [prevMapBounds, setPrevMapBounds] = useState(null);
  const [gpsLocationTriggeredFlag, setGpsLocationTriggeredFlag] = useState(false);
  
  let currentCenter = new URLSearchParams(useLocation().search).get('currentCenter')

  const handleMapChange = (data, dragEnd=false) => {
    if (gpsLocationTriggeredFlag){
      setGpsLocationTriggeredFlag(false);
      setGpsLocationTriggered(false);
    }
    else if (gpsLocationTriggered) {
      setGpsLocationTriggeredFlag(true);
    }
    if (data.bounds !== prevMapBounds && !(data.bounds.nw.lat === data.bounds.se.lat && data.bounds.nw.lng === data.bounds.se.lng)) {
      setSearchMapBounds({
        "top_left": [data.bounds.nw.lat, data.bounds.nw.lng],
        "bottom_right": [data.bounds.se.lat, data.bounds.se.lng],
        "center": [data.center.lat, data.center.lng]
      })
      setIsSearching(true);
      if (isSearching) {
        cancelSearch();
        setSearchResultsLoader(0);
      }
      if (!isFocused && (searchOnMapMove || searchGeolocationChanged)) {
        setSearchResultsLoader(20);
        fetchPriceFilters(categoryOptionFilters, {
          "top_left": [data.bounds.nw.lat, data.bounds.nw.lng],
          "bottom_right": [data.bounds.se.lat, data.bounds.se.lng],
          "center": [data.center.lat, data.center.lng]
        });
        searchParkingSpots(categoryOptionFilters, {
          "top_left": [data.bounds.nw.lat, data.bounds.nw.lng],
          "bottom_right": [data.bounds.se.lat, data.bounds.se.lng],
          "center": [data.center.lat, data.center.lng]
        }, priceRangeFilter);
        if (searchGeolocationChanged) {
          setSearchGeolocationChanged(false);
        }
      }
      else if (!searchOnMapMove) {
        setSearchHere(true);
      }
      setPrevMapBounds({...data.bounds});
    }

    if (!isFocused && !currentCenter){
      if (center[0] !== data.center.lat || center[1] !== data.center.lng) {
        setCenter([data.center.lat, data.center.lng]);
      }
      setZoom(data.zoom);
    }

    const urlParams = new URLSearchParams({currentCenter: center})
    window.history.pushState(null, null, `?${urlParams}`)
  };

  const handleSearchCheckboxChange = (e) => {
    setSearchOnMapMove(e.target.checked);
  }

  const handleSearchHere = () => {
    if (searchHere) {
      setSearchResultsLoader(20);
      setIsSearching(true);
      fetchPriceFilters(categoryOptionFilters, searchMapBounds);
      searchParkingSpots(categoryOptionFilters, searchMapBounds, priceRangeFilter);
      setSearchHere(false);
    }
  }

  const handleLocationCall = () => {
    navigator.geolocation.getCurrentPosition(function(position) {
      setCenter([position.coords.latitude, position.coords.longitude]);
      setZoom(10);
      setCenterMarkerCoords({lat: position.coords.latitude, lng: position.coords.longitude});
    },
    function(error) {
      setGpsLocationTriggered(false);
      if (error.code == error.PERMISSION_DENIED) {
        enqueueSnackbar(
          {
            text: "Parknsleep does not have permission to use your accurate location.",
            variant: "error",
          },
          {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        );
      }
    });
  }

  useEffect(() => {
    if (gpsLocationTriggered) {
      handleLocationCall();
    }
  }, [gpsLocationTriggered])

  useEffect(() => {
    return () => {
      setSearchMapCenterAndZoom({center, zoom})
    }
  }, [center, zoom])

  const handleMapDrag = (data) => {
    const center = data.getCenter();
    const options = {
      center: {
        lat: center.lat(),
        lng: center.lng()
      },
    };
    if (center[0] !== options.center.lat || center[1] !== options.center.lng) {
      setCenter([options.center.lat, options.center.lng]);
    }
  };

  const debouncedHandler = debounce(handleMapDrag, 500);

  const mapOptions = {
    styles: mapStyles,
    disableDefaultUI: true,
    gestureHandling: showMap ? "greedy" : "none",
  };

  const CenterMapPin = () => <div className={classes.mapCenterDot} />;

  return (
    <React.Fragment>
      { center?.length === 2 && (
        <GoogleMapReact
          onChange={handleMapChange}
          onDragEnd={debouncedHandler}
          onZoomAnimationStart={() => clearPreviousSearch(isSearching, cancelSearch, setSearchResultsLoader)}
          onDrag={() => clearPreviousSearch(isSearching, cancelSearch, setSearchResultsLoader)}
          center={center}
          zoom={zoom}
          options={mapOptions}
          onClick={() => {
            !isDesktop && tooltipCardMobile && currentCardData != null && setTooltipCardMobile(null, false, null)}
          }
        >
          {/* All children with lat and lng props are rendered as markers */}
          { pins }
          <CenterMapPin lat={centerMarkerCoords ? centerMarkerCoords.lat : 0} lng={centerMarkerCoords ? centerMarkerCoords.lng : 0} />          
        </GoogleMapReact>
      )}
      <Box className={classes.mapLoaderContainer} visibility={(searchResultsLoader > 0 || center?.length !== 2) ? "visible" : "hidden"}>
        <img src={mapLoader} alt="map-loader" className={classes.mapLoader} />
      </Box>
      <Box className={classes.mapSearchCheckboxContainer} display="flex" alignItems="center" visibility={(searchResultsLoader <= 0) ? "visible" : "hidden"}>
        {
          !searchHere ?
          <Box display="flex" alignItems="center">
            <Checkbox
              className={classes.mapSearchCheckbox}
              checked={searchOnMapMove}
              onChange={handleSearchCheckboxChange}
              icon={<UnCheckedIcon />}
              checkedIcon={<CheckedIcon />}
              defaultChecked
              color="primary"
              size="small"
            />
            <Typography variant="caption" className={classes.caption2}>{t('search.map.on-move')}</Typography>
          </Box>
          :
          <Box display="flex" alignItems="center" className={classes.mapSearchHereBox} onClick={() => {handleSearchHere()}}>
            <ReplayIcon className={classes.mapSearchHereButton} />
            <Typography variant="caption" className={classes.caption2}>{t('search.map.search-here')}</Typography>
          </Box>
        }
      </Box>
      {
        !isSM &&
        <Box className={classes.mapLocationButtonContainer} onClick={() => {setGpsLocationTriggered(!gpsLocationTriggered)}} >
          <GpsFixedIcon className={gpsLocationTriggered ? clsx(classes.mapLocationButton, classes.mapLocationButtonSelected) : classes.mapLocationButton} />
        </Box>
      }
      <Box className={classes.legalNotesContainer}>
        <MuiLink
          href={`https://intercom.help/parknsleep/${intercomLinksLang}/articles/6001599-impressum`}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.legalNotesLink}
        >
          {t('search.map.legal-notes')}
        </MuiLink>
      </Box>
    </React.Fragment>
  );
};

const initSearch = ({
  searchMapBounds,
  externalSearchLocation,
  categoryOptionFilters,
  setSearchResultsLoader,
  setIsSearching,
  fetchPriceFilters,
  searchParkingSpots,
  priceRangeFilter,
  setExternalSearchLocation,
  setExternalSearchFlag,
}) => {
  if (searchMapBounds && searchMapBounds.top_left && searchMapBounds.top_left.length > 0) {
    if (!externalSearchLocation){
      setSearchResultsLoader(20);
      setIsSearching(true);
      fetchPriceFilters(categoryOptionFilters, searchMapBounds);
      searchParkingSpots(categoryOptionFilters, searchMapBounds, priceRangeFilter);
    }
    else {
      setExternalSearchLocation(null);
      setExternalSearchFlag(true);
    }
  }
  setSearchStringGlobal(null);
};

const Search = ({
  token,
  userType,
  currentCheckIns,
  categoryOptionFilters,
  locationFilters,
  filteredParkingSpots,
  pagy,
  fetchPriceFilters,
  searchParkingSpots,
  priceRangeFilter,
  setPriceRangeFilter,
  setCategoryOptionFilters,
  setLocationFilters,
  parkingSpotAttributes,
  fetchParkingSpotAttributes,
  fetchCurrentCheckIns,
  setCurrentCheckIns,
  tooltipCardMobile,
  setTooltipCardMobile,
  addLoader,
  setIsSearching,
  isSearching,
  setParkingSpotDetails,
  setCarouselState,
  setSearchStringGlobal,
  searchString,
  showMap,
  setShowMap,
  focusedSpot,
  setFocusedSpot,
  pinData,
  searchTimestamp,
  setSearchMapBounds,
  searchMapBounds,
  cancelSearch,
  externalSearchLocation,
  setExternalSearchLocation,
  setFilteredParkingSpots,
  userLocation,
  resultsTimestamp,
  searchResultsLoader,
  setSearchResultsLoader,
  setCenterMarkerCoords,
  centerMarkerCoords,
  setSearchMapCenterAndZoom,
  searchMapCenterAndZoom,
  initializeParkingSpotDetailsSearch,
  cardData,
  fetchParkingSpotDetailsSearch
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [mobileFlitersOpen, setMobileFiltersOpen] = React.useState(false);
  const [filtersReset] = React.useState(false);
  const { t } = useTranslation();
  const geosuggestEl = React.useRef(null);
  const query = useQuery();
  const searchQueryString = query ? query.get('search') : null;
  const [closeSearch, setCloseSearch] = React.useState(!!searchQueryString ? (locationFilters && locationFilters.length > 0) ? true : false : false);
  const [externalSearchFlag, setExternalSearchFlag] = React.useState(false);
  const [searchGeolocationChanged, setSearchGeolocationChanged] = React.useState(false);
  const [gpsLocationTriggered, setGpsLocationTriggered] = useState(false);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);

  const [mobileCardImageSrc, setMobileCardImageSrc] = useState(null);
  const [mobileCardImageShow, setMobileCardImageShow] = useState(true);
  const [currentCardData, setCurrentCardData] = useState(null);

  useEffect(() => {
    return () => {
      initializeParkingSpotDetailsSearch()
    }
  }, []);

  useEffect(() => {
    if (!isDesktop && tooltipCardMobile && currentCardData) {
      setMobileCardImageShow(false);
      if (currentCardData.main_photo_thumbnail_url != null) {
        setMobileCardImageSrc(currentCardData.main_photo_thumbnail_url);
      }
      else if (currentCardData.main_photo_url != null) {
        setMobileCardImageSrc(currentCardData.main_photo_url);
      }
      else {
        setMobileCardImageSrc(null);
      }
    }
  }, [tooltipCardMobile, currentCardData])

  useEffect(() => {
    setParkingSpotDetails(null, true);
    setCarouselState("default", "", true);
    if (focusedSpot && pinData && pinData.length === 0 && searchMapBounds && searchMapBounds.center && searchMapBounds.center.length === 0){
      setFocusedSpot(null)
    }
    return () => {
      setCarouselState("default", "", true);
    }
  }, [])

  useEffect(() => {
    if (parkingSpotAttributes.length === 0) {
      fetchParkingSpotAttributes();
    }
  }, [
    parkingSpotAttributes,
    fetchParkingSpotAttributes,
  ]);

  useEffect(() => {
    if (tooltipCardMobile?.spotId && searchTimestamp === resultsTimestamp && !cardData[`${tooltipCardMobile.spotId}`]) {
      setTimeout(() => fetchParkingSpotDetailsSearch(tooltipCardMobile.spotId), 500);
    }
  }, [fetchParkingSpotDetailsSearch, tooltipCardMobile?.spotId, resultsTimestamp])

  useEffect(() => {
    if (tooltipCardMobile) {
      setCurrentCardData(cardData ? cardData[`${tooltipCardMobile.spotId}`] ? cardData[`${tooltipCardMobile.spotId}`] : null : null)
    }
  }, [cardData, tooltipCardMobile?.spotId])

  useEffect(() => {
    if (token != null && userType === "Guest"){
      fetchCurrentCheckIns(token);
    }
    else {
      setCurrentCheckIns([]);
    }
  }, [
    userType,
    setCurrentCheckIns,
    fetchCurrentCheckIns,
    token
  ]);

  useEffect(() => {
    if(!isDesktop) {
      if (!focusedSpot || !pagy) {
        initSearch({
          searchMapBounds,
          externalSearchLocation,
          categoryOptionFilters,
          setSearchResultsLoader,
          setIsSearching,
          fetchPriceFilters,
          searchParkingSpots,
          priceRangeFilter,
          setExternalSearchLocation,
          setExternalSearchFlag,
        });
      } else {
        setTimeout(() => setFocusedSpot(null), 500);
      }
    }
  }, [])

  useEffect(() => {
    if(isDesktop) {
      initSearch({
        searchMapBounds,
        externalSearchLocation,
        categoryOptionFilters,
        setSearchResultsLoader,
        setIsSearching,
        fetchPriceFilters,
        searchParkingSpots,
        priceRangeFilter,
        setExternalSearchLocation,
        setExternalSearchFlag,
      });
      setFocusedSpot(null);
    }
  }, [
    categoryOptionFilters,
    setIsSearching,
    filtersReset,
    priceRangeFilter
  ]);

  const handleFilterReset = () => {
    if(Object.keys(categoryOptionFilters).length)
      setCategoryOptionFilters({})
  }

  const handleMobileFilterOpen = (open) => {
    setMobileFiltersOpen(open)
    if (!focusedSpot || !pagy) {
      initSearch({
        searchMapBounds,
        externalSearchLocation,
        categoryOptionFilters,
        setSearchResultsLoader,
        setIsSearching,
        fetchPriceFilters,
        searchParkingSpots,
        priceRangeFilter,
        setExternalSearchLocation,
        setExternalSearchFlag,
      });
    } else {
      setTimeout(() => setFocusedSpot(null), 500);
    }
  }

  React.useEffect(() => {
    if (!isDesktop) {
      handleMobileFilterOpen(false);
    }
  }, [priceRangeFilter])

  const [description, setDescription] = useState(null);

  const handleLocationChange = (location, description) => {
    setLocationFilters([location.lat, location.lng]);
    setDescription(description);
    setSearchStringGlobal(geosuggestEl.current && geosuggestEl.current.state.userInput);
    setSearchGeolocationChanged(true);
  };

  const handleSuggestInputChange = (value) => {
    if (value === ""){
      setLocationFilters(null);
      setCloseSearch(false);
    }
    else if (!closeSearch) {
      setCloseSearch(true);
    }
    setSearchStringGlobal(value);
  }

  const handleCloseSearch = () => {
    setLocationFilters(null);
    geosuggestEl.current.clear()
    setSearchStringGlobal(null);
    setDescription(null);
    setCloseSearch(false);
  }

  const handleIsFilteredByOption = () => {
    let isFiltered = false;
    parkingSpotAttributes.forEach((category, index) => {
      category.parking_spot_category_options.forEach((option) => {
        if (categoryOptionFilters[option.id]){
          isFiltered = true;
        }
      });
    });
    return isFiltered;
  };

  const dispatch = useDispatch();

  const isPaginated = pagy && pagy.pages && pagy.pages > 1
  const [searchOnMapMove, setSearchOnMapMove] = useState(true);
  const [searchHere, setSearchHere] = useState(false);
  const [prevSearchOnMapMove, setPrevSearchOnMapMove] = useState(true);
  const [prevSearchHere, setPrevSearchHere] = useState(false);
  const listRef = useRef(null);

  const [categoryOptionFilterKeys, setCategoryOptionFilterKeys] = useState(Object.keys(categoryOptionFilters));

  const [disableMobileFilter, setDisableMobileFilter] = useState();

  useEffect(() => {
    setCategoryOptionFilterKeys(Object.keys(categoryOptionFilters).filter(op => !!categoryOptionFilters[op]));
  }, [categoryOptionFilters])

  useEffect(() => {
    if (disableMobileFilter && Object.values(categoryOptionFilters).filter(option => option === false).length >= 1){
      handleMobileFilterOpen(false);
      setDisableMobileFilter(false);
    }
  }, [categoryOptionFilters])

  const noOfActiveFilters = Object.values(categoryOptionFilters).filter(i => i).length;

  return(
    <Box display="flex" className={classes.searchContainer} overflow={isDesktop || !showMap ? "visible" : "hidden"}>
      { !isDesktop && (
        <React.Fragment>
          <Box display="flex" justifyContent="space-between" className={clsx(classes.mobileFilters, showMap ? "map" : (!!categoryOptionFilterKeys.length || !!priceRangeFilter) ? "filterchips" : "nomap")} px={3} style={(!showMap) ? {background:"white"} : null}>
            <Box className={classes.searchBox}>
              <Geosuggest
                placeholder={t("location")}
                className={classes.geo}
                inputClassName={classes.geoInput}
                suggestsClassName={classes.geoSuggests}
                suggestsHiddenClassName={classes.geoSuggestsHidden}
                suggestItemClassName={classes.geoSuggestItem}
                suggestItemActiveClassName={classes.geoSuggestItemActive}
                onChange={(value) => handleSuggestInputChange(value)}
                ref={geosuggestEl}
                autoComplete="off"
                fixtures={[
                  {label: 'Zürich, Schweiz'},
                  {label: 'Basel, Schweiz'},
                  {label: 'Bern, Schweiz'},
                  {label: 'Genf, Schweiz'},
                  {label: 'Luzern, Schweiz'},
                  {label: 'Lugano, Schweiz'},
                ]}
                onSuggestSelect={(suggestion) => {
                  suggestion && suggestion.location && handleLocationChange(suggestion.location, suggestion.gmaps.formatted_address)
                  setCloseSearch(true);
                }}
                initialValue={searchQueryString ? searchQueryString : null}
              />
              {
                (closeSearch)
                ? <CloseIcon className={classes.searchContainerIcon} onClick={() => {handleCloseSearch()}} />
                : <SearchIcon className={classes.searchContainerIcon} />
              }
            </Box>
            {
              (!!showMap && !(!isDesktop && tooltipCardMobile !== null && currentCardData !== null)) &&
              <Button variant="outlined" size="small" className={classes.filterButton} style={{background: "#fff", position: 'fixed', bottom: '1rem', right: '1rem'}} onClick={() => {setGpsLocationTriggered(!gpsLocationTriggered)}} >
                <GpsFixedIcon className={gpsLocationTriggered ? clsx(classes.filterButtonIcon, classes.mapLocationButtonSelected) : classes.filterButtonIcon} />
              </Button>
            }
            <Button variant="outlined" size="small" className={classes.filterButton} onClick={() => {
              const goingToList = !!showMap;
              if(!goingToList) {
                setSearchOnMapMove(prevSearchOnMapMove);
                setSearchHere(prevSearchHere);
              }
              setShowMap(!showMap)
              if(goingToList) {
                setPrevSearchOnMapMove(searchOnMapMove);
                setPrevSearchHere(searchHere);
                setSearchOnMapMove(false);
                setSearchHere(false);
              }
            }}>
              { showMap ? (
                <ListIcon className={classes.filterButtonIcon} />
              ) : (
                <PinSVG className={classes.filterButtonIcon} />
              )}
            </Button>
            <Box mt='1.5rem' mb='0.5rem' mr='0.875rem'>
              <PriceFilterButton  />
            </Box>
            <Button variant="outlined" size="small" style={{position: "relative"}} className={classes.filterButton} onClick={() => { setMobileFiltersOpen(true) }}>
              <Badge badgeContent={noOfActiveFilters} classes={{
                badge: classes.badgeClass,
              }} color="error">
                <FilterIcon className={classes.filterButtonIcon} />
              </Badge>
            </Button>
          </Box>
        </React.Fragment>
      )}
      { mobileFlitersOpen && (
        <Box className={classes.mobileFilterBox} pb={2}>
          <Box display="flex" alignItems="center" className={classes.resetFilterBox}>
            <Box flex="auto">
              <Button variant="outlined" className={classes.resetFilters} onClick={handleFilterReset}>
                <Typography variant="button">
                  {t("reset-filters")}
                </Typography>
              </Button>
            </Box>
            <Box>
              <Button variant="outlined" className={classes.resetFilters} onClick={() => { handleMobileFilterOpen(false) }}>
                <Typography variant="button">
                  {t("apply-filters")}
                </Typography>
              </Button>
            </Box>
          </Box>
          <FiltersMap classes={classes} />
        </Box>
      )}
      <Box className={((!isDesktop && !showMap && (!!filteredParkingSpots && filteredParkingSpots.length > 0)) && !isSearching) ? filteredParkingSpots.length === 1 ? classes.listBoxWithSingleResult : classes.listBoxWithResult : classes.listBox }>
        { isDesktop && (
          <Box className={classes.desktopFilters}>
            <Box pt={3} pr={4} paddingLeft="2.0rem" fullWidth={true} display="flex" alignItems="center">
              <Box className={classes.searchBox}>
                <Geosuggest
                  placeholder={t("location")}
                  className={classes.geo}
                  inputClassName={classes.geoInput}
                  suggestsClassName={classes.geoSuggests}
                  suggestsHiddenClassName={classes.geoSuggestsHidden}
                  suggestItemClassName={classes.geoSuggestItem}
                  suggestItemActiveClassName={classes.geoSuggestItemActive}
                  onChange={(value) => handleSuggestInputChange(value)}
                  ref={geosuggestEl}
                  autoComplete="off"
                  fixtures={[
                    {label: 'Zürich, Schweiz'},
                    {label: 'Basel, Schweiz'},
                    {label: 'Bern, Schweiz'},
                    {label: 'Genf, Schweiz'},
                    {label: 'Luzern, Schweiz'},
                    {label: 'Lugano, Schweiz'},
                  ]}
                  onSuggestSelect={(suggestion) => {
                    suggestion && suggestion.location && handleLocationChange(suggestion.location, suggestion.gmaps.formatted_address)
                    setCloseSearch(true);
                  }}
                  initialValue={searchQueryString ? searchQueryString : null}
                />
                {
                  (closeSearch)
                  ? <CloseIcon className={classes.searchContainerIcon} onClick={() => {handleCloseSearch()}} />
                  : <SearchIcon className={classes.searchContainerIcon} />
                }
              </Box>
              {/* FILTER BUTTON DESKTOP */}
              <Button variant="outlined" size="small" style={{position: "relative", marginTop: 0}} className={classes.filterButton} onClick={() => setFilterDialogOpen(true)}>
                <Badge badgeContent={noOfActiveFilters} classes={{
                  badge: classes.badgeClass,
                }} color="error">
                  <FilterIcon className={classes.filterButtonIcon} />
                </Badge>
              </Button>
              <PriceFilterButton />
            </Box>
          </Box>
        )}
        {(!!categoryOptionFilterKeys.length || !!priceRangeFilter) &&
          <React.Fragment>
          <Box paddingLeft={isDesktop ? "2.0rem" : 3} pt={isDesktop ? 1 : 0} pr={3} display="flex" className={classes.filterChips} mb={2}>
            { !!categoryOptionFilterKeys.length && categoryOptionFilterKeys.filter(opt => !!categoryOptionFilters[opt]).map(selectedFilterId => {
              const option = parkingSpotAttributes.reduce((prev, curr) => prev.concat(curr.parking_spot_category_options), []).filter(attr => attr.id === selectedFilterId)[0];
              const Icon = getSVGForIcon(option.icon);
              return (
                <Box className={classes.filterChip}>
                  <Icon fill={theme.palette.primary.main} className={clsx(classes.filterChipIcon, option.icon.startsWith("host_type_campsite") ? "campsiteIcon" : "")} viewBox={option.icon.startsWith("host_type_campsite") ? "-2 -5 78 78" : "0 0 24 24"} />
                  <Typography variant="caption" color="primary" style={{marginRight: "1rem"}}>{option ? t(getAttributeName(option)) : ""}</Typography>
                  <CloseIcon colorPrimary className={classes.clickable} onClick={() => {
                    setDisableMobileFilter(!isDesktop);
                    dispatch(setCategoryOptionFilters({
                      ...categoryOptionFilters,
                      [selectedFilterId]: false,
                    }))
                  }} />
                </Box>
              )
            }) }
            { !!priceRangeFilter && (
              <Box className={classes.filterChip}>
                <Typography variant="caption" color="primary" style={{marginRight: "1rem"}}>EURO {priceRangeFilter.from} {t("parking-spot.to")} EURO {priceRangeFilter.to === 100 ? "100+" : priceRangeFilter.to}</Typography>
                <CloseIcon colorPrimary className={classes.clickable} onClick={() => {
                  setPriceRangeFilter(null)
                }} />
              </Box>
            )}
          </Box>
          { !isDesktop && !showMap && (<div className={classes.searchDividerFiltersSticky} /> )}
          </React.Fragment>
        }
        <Box paddingLeft={isDesktop? "2.0rem" : 0} py={isDesktop ? 2 : 2} pr={isDesktop ? 2 : 0} mt={isDesktop ? 3 : 0} className={classes.listContent}>
          <Box ref={listRef} className={classes.scrollToDiv}></Box>
          { isSearching && (
            <Box display="flex" pl={isDesktop ? 0 : 7} py={7} pr={7} width="100%" justifyContent="center">
              <CircularProgress size={24} />
            </Box>
          )}
          { !isSearching && filteredParkingSpots && filteredParkingSpots.length === 0 && (
            <Box display="flex" pl={isDesktop ? 0 : 7} py={7} pr={7} width="100%" justifyContent="center">
              <Typography variant="caption">{t("no-results")}</Typography>
            </Box>
          )}
          { !isSearching && pagy && pagy.count > 0 && (
            <Box pb={isDesktop ? 0.5 : 1} pt={isDesktop ? 1.5 : 0} pr={isDesktop ? 2 : 3} pl={isDesktop ? 0 : 3} width="100%" justifyContent="center">
              { !!searchQueryString || (closeSearch && locationFilters && locationFilters.length > 0) ? (
                <Typography variant="h4">{t("common.texts.parking-spots-near")} {description ? description : searchString ? searchString : searchQueryString ? searchQueryString : "your search"}</Typography>
              ) : (
                <Typography variant="h4">{t("common.texts.parking-spots-all")}</Typography>
              )}
              <Typography variant="h6" color="secondary" style={{paddingTop: "0.25rem"}}>{pagy ? pagy.count : ""} {t("common.texts.results")}</Typography>
            </Box>
          )}
          { !isSearching && filteredParkingSpots && filteredParkingSpots.map((tag, index) =>
            <ResultItem
              key={`result-item-${tag}-${searchTimestamp}`}
              focus={tag === focusedSpot}
              showMap={showMap}
              tag={tag}
              isDesktop={isDesktop}
              divider={
                (index < filteredParkingSpots.length -1) || isPaginated
              }
              checkIns={currentCheckIns}
              resultsTimestamp={resultsTimestamp}
            />
          ) }
          {
            (isPaginated && !isSearching) && (
              <Box pt={2} pb={!isDesktop ? 4 : 1.5} pr={2} pl={isDesktop ? 0 : 2} display="flex" justifyContent="center" alignItems='center'>
                <SearchPagination pagy={pagy} epic={searchParkingSpots} fetchPriceFilters={fetchPriceFilters} options={categoryOptionFilters} location={searchMapBounds} priceRangeFilter={priceRangeFilter} listRef={listRef} />
              </Box>
            )
          }
        </Box>
      </Box>

      <Box overflow="auto" zIndex={isDesktop || showMap ? 1001 : 999} className={classes.mapBox} position="relative">
        <Box style={{width: '100%', height: isDesktop ? 'calc(100vh - 70px)' : '100%', backgroundColor: '#fff', userSelect: "none"}}>
          <SearchMap
            spots={pinData}
            checkIns={currentCheckIns}
            locationFilters={locationFilters}
            setLocationFilters={setLocationFilters}
            categoryOptionFilters={categoryOptionFilters}
            fetchPriceFilters={fetchPriceFilters}
            searchParkingSpots={searchParkingSpots}
            priceRangeFilter={priceRangeFilter}
            setPriceRangeFilter={setPriceRangeFilter}
            isFilteredByOption={handleIsFilteredByOption}
            currentPage={pagy ? pagy.page : 0}
            isSearchOpen={closeSearch}
            searchMapBounds={searchMapBounds}
            setSearchMapBounds={setSearchMapBounds}
            cancelSearch={cancelSearch}
            isSearching={isSearching}
            externalSearchLocation={externalSearchLocation}
            setExternalSearchLocation={setExternalSearchLocation}
            isFocused={focusedSpot}
            userLocation={userLocation}
            externalSearchFlag={externalSearchFlag}
            setExternalSearchFlag={setExternalSearchFlag}
            searchResultsLoader={searchResultsLoader}
            setSearchResultsLoader={setSearchResultsLoader}
            searchTimestamp={searchTimestamp}
            searchGeolocationChanged={searchGeolocationChanged}
            setSearchGeolocationChanged={setSearchGeolocationChanged}
            gpsLocationTriggered={gpsLocationTriggered}
            setGpsLocationTriggered={setGpsLocationTriggered}
            showMap={!!showMap}
            searchOnMapMove={searchOnMapMove}
            setSearchOnMapMove={setSearchOnMapMove}
            searchHere={searchHere}
            setSearchHere={setSearchHere}
            prevSearchOnMapMove={prevSearchOnMapMove}
            setPrevSearchOnMapMove={setPrevSearchOnMapMove}
            prevSearchHere={prevSearchHere}
            setPrevSearchHere={setPrevSearchHere}
            setCenterMarkerCoords={setCenterMarkerCoords}
            centerMarkerCoords={centerMarkerCoords}
            setSearchMapCenterAndZoom={setSearchMapCenterAndZoom}
            searchMapCenterAndZoom={searchMapCenterAndZoom}
            setTooltipCardMobile={setTooltipCardMobile}
            tooltipCardMobile={tooltipCardMobile}
            isDesktop={isDesktop}
            currentCardData={currentCardData}
          />
        </Box>
        {
          (!isDesktop && tooltipCardMobile !== null && currentCardData !== null) && (
            <Box position="absolute" className={classes.mobileCardContainer}>
              <Card className={classes.mobileCard} >
                <CardActionArea
                  component={Link}
                  to={`/ps/${currentCardData.tag}`}
                  style={{height: "100%"}}
                >
                  <Box display="flex" padding="0.5rem">
                    <Box className={classes.pinCardImage} position="relative">
                      {(mobileCardImageSrc != null) && (
                        <img className={clsx(classes.mobileCardPicture, mobileCardImageShow ? "show" : "hide")} onLoad={() => {setMobileCardImageShow(true)}} src={mobileCardImageSrc} alt="Parking Spot" />
                      )}
                      <Box position="absolute" className={classes.mobileCardPicture} style={{background: "#E7F0F0", zIndex: 1}} />
                    </Box>
                    <Box pl="0.5rem" display="flex" flexDirection="column" flex={1} justifyContent="space-between" className={classes.pinCardDetails}>
                      <Box width="100%" flex="auto" display="flex">
                        <Box>
                          <Box flex="auto">
                            <Typography variant="h3" style={{fontSize: "14px", lineHeight: "20px"}}>{currentCardData.name}</Typography>
                          </Box>
                          <Box>
                            {
                              (!!currentCardData.location) &&
                              <Box display="flex" alignItems="center" className={classes.pinPriceWrapper}>
                                <PinSVG style={{fill: "rgb(142, 142, 153)", marginRight: "0.25rem", marginBottom: "0.125rem"}} />
                                <Typography variant="caption" className={classes.caption2} style={{lineHeight: "16px", color: theme.palette.secondary.main }}>
                                  {formatCountry(currentCardData.location)}
                                </Typography>
                              </Box>
                            }
                          </Box>
                        </Box>
                      </Box>
                      <Box display="flex" width="100%">
                        <Box flex="auto">
                          <Box>
                            <Box display="flex">
                              { (tooltipCardMobile && currentCardData && currentCardData.price) &&
                                <Box className={classes.singleLine} flex="auto">
                                  <Typography variant="caption" className={classes.caption2}>
                                  {currentCardData.currency} {round((Number(currentCardData.price) + Number(currentCardData.tax_amount)), 2)} { t("common.texts.day") }
                                  </Typography>
                                </Box>
                              }
                              <Box display={!!tooltipCardMobile.isCheckedIn ? "flex" : "none"} className={classes.cardCheckedInChip} justifyContent="center" alignItems="center" ml={1} style={{width: "1.3rem", minWidth: "1.3rem", height: "1.3rem", minHeight: "1.3rem"}}>
                                <CheckIcon className={classes.checkIcon} />
                              </Box>
                            </Box>
                            <Box py={0.5} display="flex">
                              <Box flex="auto" display="flex" alignItems="center">
                                {tooltipCardMobile && currentCardData && getAttributes(currentCardData, true) && getAttributes(currentCardData, true).map((option) => {
                                  const Icon = getSVGForIcon(option.icon);
                                  return !!Icon && <Icon fill={theme.palette.primary.main} className={clsx(classes.resultIcon, option.icon.startsWith("host_type_campsite") ? "campsiteIcon" : "")} viewBox={option.icon.startsWith("host_type_campsite") ? "-2 -5 78 78" : "0 0 24 24"} />
                                })}
                              </Box>
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                              <Box display="flex" alignItems="center">
                                <StarIcon className={classes.mobileCardStarIcon} style={{width: "13px", width: "13px"}} />
                                <Box>
                                  <Typography variant="caption" style={{fontSize: "12px"}}>{(Math.round(currentCardData.avg_rating * 10) / 10).toFixed(1)}</Typography>
                                </Box>
                              </Box>
                              <Box display="flex" alignItems="center">
                                {
                                  tooltipCardMobile && !!currentCardData.available_today && !!currentCardData.spot_active
                                  ?
                                  <Typography variant="caption" style={{fontSize: "12px"}} className={ (currentCardData.capacity === currentCardData.occupied) ? classes.freeSpacePillFull : (tooltipCardMobile.color === "green") ? classes.freeSpacePill : (tooltipCardMobile.color === "yellow") ? classes.freeSpacePillYellow: classes.freeSpacePillRed }>
                                    {currentCardData.capacity - currentCardData.occupied} / {currentCardData.capacity}
                                  </Typography>
                                  :
                                  <Typography variant="caption" style={{fontSize: "12px"}} className={ classes.freeSpacePillUnavailable }>
                                    {(!currentCardData.spot_active) ? currentCardData.capacity - currentCardData.occupied : 0 } / {currentCardData.capacity}
                                  </Typography>
                                }
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </CardActionArea>
              </Card>
            </Box>
          )
        }
      </Box>

      { isDesktop && filterDialogOpen &&
        <Dialog open={filterDialogOpen} onClose={
          () => setFilterDialogOpen(false)
        } aria-labelledby="mobile-filters-dialog-title" maxWidth="md">
          <Box mx={0} mb={!isDesktop ? 2 : 0} minWidth={isDesktop ? "54rem" : "initial"}>
            <DialogTitle
              id="mobile-filters-dialog-title"
              className={classes.dialogTitleBorderBottom}
              classes={{
                root: classes.dialogTitleRoot,
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h2">{t("filters")}</Typography>
                <Button variant="outlined" size="small" onClick={() => {setFilterDialogOpen(false)}}>
                  <CloseIcon flex="auto" color="primary" />
                </Button>
              </Box>
            </DialogTitle>

            <DialogContent className={classes.dialogContent} style={{paddingTop: "1.5rem", paddingBottom: 0}}>
              <FiltersMap classes={classes} />
            </DialogContent>
          </Box>
        </Dialog>
      }
    </Box>
  )
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userType: state.user.userType,
    currentCheckIns: state.parkingSpot.currentCheckIns,
    categoryOptionFilters: state.parkingSpot.categoryOptionFilters,
    priceRangeFilter: state.parkingSpot.priceRangeFilter,
    locationFilters: state.parkingSpot.locationFilters,
    filteredParkingSpots: state.parkingSpot.filteredParkingSpots.parking_spots,
    pagy: state.parkingSpot.filteredParkingSpots.pagy,
    resultsTimestamp: state.parkingSpot.filteredParkingSpots.timestamp,
    parkingSpotAttributes: state.parkingSpot.parkingSpotAttributes,
    tooltipCardMobile: state.parkingSpot.tooltipCardMobile,
    isSearching: state.parkingSpot.isSearching,
    showMap: state.parkingSpot.showMap,
    focusedSpot: state.parkingSpot.focusedSpot,
    searchString: state.parkingSpot.searchString,
    pinData: state.parkingSpot.parkingSpotDetailsSearch.pinData,
    searchTimestamp: state.parkingSpot.searchTimestamp,
    searchMapBounds: state.parkingSpot.searchMapBounds,
    externalSearchLocation: state.parkingSpot.externalSearchLocation,
    userLocation: state.parkingSpot.userLocation,
    searchResultsLoader: state.parkingSpot.searchResultsLoader,
    centerMarkerCoords: state.parkingSpot.centerMarkerCoords,
    searchMapCenterAndZoom: state.parkingSpot.searchMapCenterAndZoom,
    cardData: state.parkingSpot.parkingSpotDetailsSearch.cardData,
  }
};

export default connect(mapStateToProps, {
  fetchPriceFilters,
  searchParkingSpots,
  setPriceRangeFilter,
  fetchParkingSpotAttributes,
  fetchCurrentCheckIns,
  setCurrentCheckIns,
  setCategoryOptionFilters,
  setLocationFilters,
  setTooltipCardMobile,
  addLoader,
  setIsSearching,
  setParkingSpotDetails,
  setCarouselState,
  setSearchStringGlobal,
  setShowMap,
  setFocusedSpot,
  setSearchMapBounds,
  cancelSearch,
  setExternalSearchLocation,
  setFilteredParkingSpots,
  setSearchResultsLoader,
  setCenterMarkerCoords,
  setSearchMapCenterAndZoom,
  initializeParkingSpotDetailsSearch,
  fetchParkingSpotDetailsSearch,
})(Search);
