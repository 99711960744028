import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import { fetchAuthByRefreshToken } from '../data/actions/auth';
import logo from '../assets/images/logo.svg';
import { addLoaderForItem } from '../data/actions/config';
import Header from '../components/common/header';
import Footer from '../components/common/footer';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { pushToRoot, setEnableReview, setCurrentBookingId, setReviewSubmissionDialogOpen, fetchReviewForBooking } from '../data/actions/parkingSpot';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  main: {
    minHeight: "70vh",
    padding: "4rem 0rem",
  },
  noPadding: {
    minHeight: "70vh",
    padding: "0",
  },
  mobile: {
    minHeight: "70vh",
    padding: "3rem 0rem",
  },
  rootAlt: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "100vh",
  },
  mainAlt: {
    flex: "auto",
    padding: "0rem",
  },
}));

const PublicLayout = ({ spacingTopAndBottom, currentURL, children, isDetailsPage, isSearchPage,  ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (spacingTopAndBottom === true) ? (!isMobile) ?
  (
    <div className={classes.root}>
      <Header currentURL={currentURL} isSearchPage={isSearchPage}/>
      <main className={ classes.main }>
        { children }
      </main>
      <Footer />
    </div>
  ) :
  (
    <div className={classes.root}>
      <Header currentURL={currentURL} isSearchPage={isSearchPage}/>
      <main className={ classes.mobile }>
        { children }
      </main>
      <Footer />
    </div>
  )
  : (spacingTopAndBottom === "search") ?
  (
    <div className={classes.rootAlt}>
      <Header currentURL={currentURL} isSearchPage={isSearchPage}/>
      <main className={classes.mainAlt}>
        { children }
      </main>
    </div>
  )
  :
  (
    <div className={classes.root}>
      <Header currentURL={currentURL} expanded={true} isDetailsPage={isDetailsPage} isSearchPage={isSearchPage}/>
      <main className={ classes.noPadding }>
        { children }
      </main>
      <Footer expanded={true} />
    </div>
  );
};


const isAuthenticated = ({ auth, fetchAuthByRefreshToken, pushToRoot }) => {
  const expiry = moment(parseInt(auth.expiry)*1000).diff(moment(), "minutes");
  if (auth.token && auth.expiry && expiry > 5) {
    return true;
  }
  return false;
};

const PublicLayoutRoute = ({page: Component, auth, token, fetchAuthByRefreshToken, pushToRoot, addLoaderForItem, isLoggingOut, setEnableReview, setCurrentBookingId, setReviewSubmissionDialogOpen, fetchReviewForBooking, reviewForBooking, userType, ...rest}) => {
  const authenticated = isAuthenticated({ auth });
  useEffect(() => {
    if (!authenticated && !isLoggingOut) {
      addLoaderForItem("token");
      fetchAuthByRefreshToken(rest.location.pathname, "public");
    }
  }, [auth, authenticated, fetchAuthByRefreshToken, rest.location.pathname, addLoaderForItem]);
  return <Route {...rest} render={(props) => {
    if (rest.location.search.startsWith("?submit_review=true&booking_id=")) {
      let searchParams = rest.location.search.substring(1, rest.location.search.length).split("&");
      let submitReview = searchParams[0].split("=")[1];
      let bookingId = searchParams[1].split("=")[1];
      (submitReview === "true") && setEnableReview(true);
      (!!bookingId) && setCurrentBookingId(bookingId);
      if (!!authenticated && userType==="Guest") {
        fetchReviewForBooking(token, bookingId);
      }
      if (!!authenticated && userType==="Guest" && reviewForBooking !== null && reviewForBooking.id == null) {
        setReviewSubmissionDialogOpen(true);
      }
      else {
        setReviewSubmissionDialogOpen(false);
      }
    }

    const urlParts = rest.location.pathname.split("/");
    const urlParam = urlParts[urlParts.length - 1];
    const urlResource = urlParts[urlParts.length - 2];
    let isDetailsPage = null;
    let isSearchPage = null;
    if (urlParts.length > 0 && urlResource === "ps" && !Number.isNaN(Number(urlParam))){
      isDetailsPage = true;
    }
    if (urlParam === "" || urlParam === "search"){
      isSearchPage = true;
    }
    if(urlResource && !(urlResource === "ps" && urlParam && !Number.isNaN(Number(urlParam)))){
      pushToRoot();
      return null;
    }
    return ((
      urlResource && !(urlResource === "ps" && urlParam && !Number.isNaN(Number(urlParam)))) &&
      urlParam !== 'search' && urlParam !== ''
    )
    ? (
      <PublicLayout spacingTopAndBottom={true} currentURL={rest.location.pathname} isDetailsPage={isDetailsPage} isSearchPage={isSearchPage}>
        <Component {...props} />
      </PublicLayout>
    )
    :
    (urlResource && (urlResource === "ps" && urlParam && !Number.isNaN(Number(urlParam)))) ?
      (
        <PublicLayout spacingTopAndBottom={false} currentURL={rest.location.pathname} isDetailsPage={isDetailsPage} isSearchPage={isSearchPage}>
          <Component {...props} />
        </PublicLayout>
      )
      : (urlParam === "cookies") ?
      (
        <PublicLayout spacingTopAndBottom={true} currentURL={rest.location.pathname} isDetailsPage={isDetailsPage} isSearchPage={isSearchPage}>
          <Component {...props} />
        </PublicLayout>
      )
      :
      (
        <PublicLayout spacingTopAndBottom="search" currentURL={rest.location.pathname} isDetailsPage={isDetailsPage} isSearchPage={isSearchPage}>
          <Component {...props} />
        </PublicLayout>
      )
  }} />
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    auth: state.auth,
    isLoggingOut: state.user.loggingOutUser,
    userType: state.user.userType,
    reviewForBooking: state.parkingSpot.reviewForBooking,
  };
}

export default connect(mapStateToProps, { fetchAuthByRefreshToken, pushToRoot, addLoaderForItem, setEnableReview, setCurrentBookingId, setReviewSubmissionDialogOpen, fetchReviewForBooking })(PublicLayoutRoute);
