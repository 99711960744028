// AUTH ACTIONS
export const FETCH_AUTH_BY_REFRESH_TOKEN = "FETCH_AUTH_BY_REFRESH_TOKEN";
export const SET_AUTH = "SET_AUTH";
export const SIGN_UP = "SIGN_UP";
export const LOGIN = "LOGIN";
export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const LOGOUT = "LOGOUT";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SET_PASSWORD_RESET_REQUEST_SENT = "SET_PASSWORD_RESET_REQUEST_SENT";

// USER ACTIONS
export const UPDATE_HOST = "UPDATE_HOST";
export const UPDATE_HOST_LOCALE = "UPDATE_HOST_LOCALE";
export const UPDATE_GUEST = "UPDATE_GUEST";
export const UPDATE_GUEST_LOCALE = "UPDATE_GUEST_LOCALE";
export const SET_USER_TYPE = "SET_USER_TYPE";
export const POST_PROFILE_PIC = "POST_PROFILE_PIC";
export const SET_PROFILE_PIC = "SET_PROFILE_PIC";
export const SET_UPDATING_PROFILE_PIC = "SET_UPDATING_PROFILE_PIC";
export const SET_USER = "SET_USER";
export const FETCH_HOST_PROFILE = "FETCH_HOST_PROFILE";
export const FETCH_GUEST_PROFILE = "FETCH_GUEST_PROFILE";
export const SET_REGISTERING_USER = "SET_REGISTERING_USER";
export const SET_LOGGING_IN_USER = "SET_LOGGING_IN_USER";
export const SET_LOGGING_OUT_USER = "SET_LOGGING_OUT_USER";
export const SET_UPDATING_USER = "SET_UPDATING_USER";
export const SET_UPDATING_USER_PASSWORD = "SET_UPDATING_USER_PASSWORD";
export const SET_IS_NEW_PROFILE = "SET_IS_NEW_PROFILE";
export const FETCH_SUB_HOSTS = "FETCH_SUB_HOSTS";
export const SET_SUB_HOSTS = "SET_SUB_HOSTS";
export const POST_NEW_SUB_HOST = "POST_NEW_SUB_HOST";
export const DELETE_SUB_HOST = "DELETE_SUB_HOST";
export const UPDATE_SUB_HOST = "UPDATE_SUB_HOST";
export const SET_FETCHING_SUB_HOSTS = "SET_FETCHING_SUB_HOSTS";
export const UPDATE_SUB_HOST_PROFILE = "UPDATE_SUB_HOST_PROFILE";
export const UPDATE_SUB_HOST_LOCALE = "UPDATE_SUB_HOST_LOCALE";

// PARKING SPOT ACTIONS
export const POST_PARKING_SPOT = "POST_PARKING_SPOT";
export const PATCH_PARKING_SPOT = "PATCH_PARKING_SPOT";
export const FETCH_PARKING_SPOT_ATTRIBUTES = "FETCH_PARKING_SPOT_ATTRIBUTES";
export const FETCH_PRICE_FILTERS = "FETCH_PRICE_FILTERS";
export const FETCH_PRICE_FILTERS_FULFILLED = "FETCH_PRICE_FILTERS_FULFILLED";
export const INIT_PARKING_SPOT_STATE = "INIT_PARKING_SPOT_STATE";
export const SET_PARKING_SPOT_ATTRIBUTES = "SET_PARKING_SPOT_ATTRIBUTES";
export const FETCH_PARKING_SPOT = "FETCH_PARKING_SPOT";
export const FETCH_PARKING_SPOTS = "FETCH_PARKING_SPOTS";
export const FETCH_PARKING_SPOTS_FOR_GUESTS = "FETCH_PARKING_SPOTS_FOR_GUESTS";
export const SET_PARKING_SPOT = "SET_PARKING_SPOT";
export const SET_PARKING_SPOTS = "SET_PARKING_SPOTS";
export const SET_FETCHING_PARKING_SPOT_ATTRIBUTES = "SET_FETCHING_PARKING_SPOT_ATTRIBUTES";
export const SET_PRICE_RANGE_FILTER = "SET_PRICE_RANGE_FILTER";
export const FETCH_PARKING_SPOT_CARS = "FETCH_PARKING_SPOT_CARS";
export const SET_PARKING_SPOT_CARS = "SET_PARKING_SPOT_CARS";
export const POST_GALLERY_IMAGE = "POST_GALLERY_IMAGE";
export const ADD_GALLERY_IMAGE = "ADD_GALLERY_IMAGE";
export const INCREMENT_GALLERY_IMAGE_LOADING_COUNTER = "INCREMENT_GALLERY_IMAGE_LOADING_COUNTER";
export const DECREMENT_GALLERY_IMAGE_LOADING_COUNTER = "DECREMENT_GALLERY_IMAGE_LOADING_COUNTER";
export const FETCHING_PARKING_SPOTS = "FETCHING_PARKING_SPOTS";
export const FETCH_PARKING_SPOTS_GUESTS = "FETCH_PARKING_SPOTS_GUESTS";
export const FETCH_REVIEWS_BY_ID_FOR_GUESTS = "FETCH_REVIEWS_BY_ID_FOR_GUESTS";
export const FETCH_PHOTOS_BY_ID_FOR_GUESTS = "FETCH_PHOTOS_BY_ID_FOR_GUESTS";
export const FETCH_REVIEWS_BY_ID_FOR_GUESTS_NEXT_PAGE = "FETCH_REVIEWS_BY_ID_FOR_GUESTS_NEXT_PAGE";
export const SET_REVIEWS_BY_ID = "SET_REVIEWS_BY_ID";
export const SET_PHOTOS_BY_ID = "SET_PHOTOS_BY_ID";
export const RESET_REVIEWS = "RESET_REVIEWS";
export const SET_REVIEWS_BY_ID_PUSH = "SET_REVIEWS_BY_ID_PUSH";
export const SET_MORE_REVIEWS_BY_ID = "SET_MORE_REVIEWS_BY_ID";
export const FETCH_CURRENT_CHECK_INS = "FETCH_CURRENT_CHECK_INS";
export const FETCH_PAST_CHECK_INS = "FETCH_PAST_CHECK_INS";
export const SET_CURRENT_CHECK_INS = "SET_CURRENT_CHECK_INS";
export const SET_PAST_CHECK_INS = "SET_PAST_CHECK_INS";
export const FETCH_CARS_FOR_GUESTS = "FETCH_CARS_FOR_GUESTS";
export const SET_CARS_FOR_GUESTS = "SET_CARS_FOR_GUESTS";
export const POST_CHECK_IN = "POST_CHECK_IN";
export const SET_IS_CHECKED_IN = "SET_IS_CHECKED_IN";
export const POST_CHECK_OUT = "POST_CHECK_OUT";
export const POST_REVIEW = "POST_REVIEW"
export const SET_CURRENT_BOOKING_ID = "SET_CURRENT_BOOKING_ID";
export const SET_CHECK_OUT_DIALOG_OPEN = "SET_CHECK_OUT_DIALOG_OPEN";
export const SET_CHECK_IN_DIALOG_OPEN = "SET_CHECK_IN_DIALOG_OPEN";
export const SET_REVIEW_DIALOG_OPEN = "SET_REVIEW_DIALOG_OPEN";
export const SET_REVIEW_SUBMISSION_DIALOG_OPEN = "SET_REVIEW_SUBMISSION_DIALOG_OPEN";
export const SET_BOOKING_REVIEW_SUBMISSION_DIALOG_OPEN = "SET_BOOKING_REVIEW_SUBMISSION_DIALOG_OPEN";
export const FETCH_PARKING_SPOT_DETAILS_FOR_GUESTS = "FETCH_PARKING_SPOT_DETAILS_FOR_GUESTS";
export const FETCH_PARKING_SPOT_DETAILS_FOR_USERS = "FETCH_PARKING_SPOT_DETAILS_FOR_USERS";
export const SET_PARKING_SPOT_DETAILS= "SET_PARKING_SPOT_DETAILS";
export const DELETE_GALLERY_IMAGE = "DELETE_GALLERY_IMAGE";
export const REMOVE_GALLERY_IMAGE = "REMOVE_GALLERY_IMAGE";
export const SEARCH_PARKING_SPOTS = "SEARCH_PARKING_SPOTS";
export const SET_CATEGORY_OPTION_FILTERS = "SET_CATEGORY_OPTION_FILTERS";
export const SET_LOCATION_FILTERS = "SET_LOCATION_FILTERS";
export const SET_FILTERED_PARKING_SPOTS = "SET_FILTERED_PARKING_SPOTS";
export const SET_TOOLTIP_CARD_MOBILE = "SET_TOOLTIP_CARD_MOBILE";
export const ENABLE_PARKING_SPOT = "ENABLE_PARKING_SPOT";
export const DISABLE_PARKING_SPOT = "DISABLE_PARKING_SPOT";
export const SET_GEOLOCATION_EDIT_PARKING_SPOT = "SET_GEOLOCATION_EDIT_PARKING_SPOT";
export const SET_ADDRESS_EDIT_PARKING_SPOT = "SET_ADDRESS_EDIT_PARKING_SPOT";
export const SET_CREATING_PARKING_SPOT = "SET_CREATING_PARKING_SPOT";
export const SET_UPDATING_PARKING_SPOT = "SET_UPDATING_PARKING_SPOT";
export const SET_CHECKING_OUT = "SET_CHECKING_OUT";
export const SET_CHECKING_IN = "SET_CHECKING_IN";
export const SET_CHECKING_IN_DETAILS_DIALOG_OPEN = "SET_CHECKING_IN_DETAILS_DIALOG_OPEN";
export const SET_IS_SEARCHING = "SET_IS_SEARCHING";
export const FETCH_BOOKINGS_HOST = "FETCH_BOOKINGS_HOST";
export const FETCH_FILTERED_BOOKINGS_HOST = "FETCH_FILTERED_BOOKINGS_HOST";
export const FETCH_SINGLE_BOOKING_RECEIPT = "FETCH_SINGLE_BOOKING_RECEIPT";
export const FETCH_MULTIPLE_BOOKINGS_RECEIPT = "FETCH_MULTIPLE_BOOKINGS_RECEIPT";
export const SET_BOOKINGS_HOST = "SET_BOOKINGS_HOST";
export const SET_FETCHING_BOOKINGS_RECEIPT = "SET_FETCHING_BOOKINGS_RECEIPT";
export const ADD_CAROUSEL_STATE = "ADD_CAROUSEL_STATE";
export const SET_CAROUSEL_STATE = "SET_CAROUSEL_STATE";
export const SET_SEARCH_STRING = "SET_SEARCH_STRING";
export const SET_MOBILE_BOTTOM_PANEL = "SET_MOBILE_BOTTOM_PANEL";
export const SET_ENABLE_REVIEW = "SET_ENABLE_REVIEW";
export const SET_SHOW_MAP = "SET_SHOW_MAP";
export const SET_FOCUSED_SPOT = "SET_FOCUSED_SPOT";
export const FETCH_PARKING_SPOT_DETAILS_SEARCH = "FETCH_PARKING_SPOT_DETAILS_SEARCH";
export const SET_PARKING_SPOT_DETAILS_SEARCH = "SET_PARKING_SPOT_DETAILS_SEARCH";
export const INITIALIZE_PARKING_SPOT_DETAILS_SEARCH = "INITIALIZE_PARKING_SPOT_DETAILS_SEARCH";
export const PRUNE_PARKING_SPOT_DETAILS_SEARCH = "PRUNE_PARKING_SPOT_DETAILS_SEARCH";
export const SET_SEARCH_TIMESTAMP = "SET_SEARCH_TIMESTAMP";
export const FETCH_REVIEW_FOR_BOOKING = "FETCH_REVIEW_FOR_BOOKING";
export const SET_REVIEW_FOR_BOOKING = "SET_REVIEW_FOR_BOOKING";
export const SET_SEARCH_MAP_BOUNDS = "SET_SEARCH_MAP_BOUNDS";
export const CANCEL_SEARCH = "CANCEL_SEARCH";
export const SET_EXTERNAL_SEARCH_LOCATION = "SET_EXTERNAL_SEARCH_LOCATION";
export const FETCH_USER_LOCATION = "FETCH_USER_LOCATION";
export const SET_USER_LOCATION = "SET_USER_LOCATION";
export const SET_SEARCH_RESULTS_LOADER = "SET_SEARCH_RESULTS_LOADER";
export const DECREMENT_SEARCH_RESULTS_LOADER = "DECREMENT_SEARCH_RESULTS_LOADER";
export const SET_CENTER_MARKER_COORDS = "SET_CENTER_MARKER_COORDS";
export const SET_SEARCH_MAP_CENTER_AND_ZOOM = "SET_SEARCH_MAP_CENTER_AND_ZOOM";
export const POST_PARKED_CAR_CHECK = "POST_PARKED_CAR_CHECK";
export const SET_PARKED_CAR_CHECK_DISABLED = "SET_PARKED_CAR_CHECK_DISABLED";
export const SET_CONTINUING = "SET_CONTINUING";
export const SET_FINISHING = "SET_FINISHING";
export const SET_PRICE = "SET_PRICE";

// CONFIG ACTIONS
export const CHANGE_I18N_LANGUAGE = "CHANGE_I18N_LANGUAGE";
export const SET_ACTIVE_LANGUAGE = "SET_ACTIVE_LANGUAGE";
export const SET_APP_VERSION = "SET_APP_VERSION";
export const ADD_LOADER = "ADD_LOADER";
export const REMOVE_LOADER = "REMOVE_LOADER";
export const ADD_LOADER_FOR_ITEM = "ADD_LOADER_FOR_ITEM";
export const ADD_LOADER_FOR_PHOTOS = "ADD_LOADER_FOR_PHOTOS";
export const REMOVE_LOADER_FOR_ITEM = "REMOVE_LOADER_FOR_ITEM";
export const REMOVE_LOADER_FOR_PHOTOS = "REMOVE_LOADER_FOR_PHOTOS";
export const PUSH_TO_ROOT = "PUSH_TO_ROOT";

//MYCARS ACTION
export const FETCH_CARS = "FETCH_CARS";
export const SET_CARS = "SET_CARS";
export const PUT_CAR = "PUT_CAR";
export const POST_CAR = "POST_CAR";
export const DELETE_CAR = "DELETE_CAR";
export const SET_CAR_EDITABLE = "SET_CAR_EDITABLE";
export const UPDATE_PRIMARY_CAR = "UPDATE_PRIMARY_CAR";

// PAYMENT ACTIONS
export const FETCH_FINANCIALS = "FETCH_FINANCIALS";
export const SET_FINANCIALS = "SET_FINANCIALS";
export const POST_WITHDRAW_BALANCE = "POST_WITHDRAW_BALANCE";
export const SET_FILE = "SET_FILE";
export const FETCH_GUEST_PAYMENT_DETAILS = "FETCH_GUEST_PAYMENT_DETAILS";
export const SET_GUEST_PAYMENT_DETAILS = "SET_GUEST_PAYMENT_DETAILS";
export const SET_WITHDRAWING = "SET_WITHDRAWING";
export const FETCH_STRIPE_STATUS = "FETCH_STRIPE_STATUS";
export const SET_STRIPE_STATUS = "SET_STRIPE_STATUS";
export const SET_UPDATING_HOST_BANK_DETAILS = "SET_UPDATING_HOST_BANK_DETAILS";
export const FETCH_SINGLE_FINANCIALS_RECEIPT = "FETCH_SINGLE_FINANCIALS_RECEIPT";
export const FETCH_ALL_FINANCIALS_RECEIPT = "FETCH_ALL_FINANCIALS_RECEIPT";
export const SET_FETCHING_FINANCIALS_RECEIPT = "SET_FETCHING_FINANCIALS_RECEIPT";
export const SET_CARD_PAYMENT_FAILED = "SET_CARD_PAYMENT_FAILED";
export const SET_REPAYMENT_FAILED_DIALOG_OPEN = "SET_REPAYMENT_FAILED_DIALOG_OPEN";
export const POST_PAYMENT_CONNECT = "POST_PAYMENT_CONNECT";
export const PATCH_PAYMENT_DETAILS = "PATCH_PAYMENT_DETAILS";
export const SET_PAYMENT_DETAILS = "SET_PAYMENT_DETAILS";
export const FETCH_PAYMENT_SECRET = "FETCH_PAYMENT_SECRET";
export const SET_PAYMENT_SECRET = "SET_PAYMENT_SECRET";
export const GUEST_PAYMENT_CONNECT = "GUEST_PAYMENT_CONNECT";
export const FETCH_GUEST_WITHDRAWALS = "FETCH_GUEST_WITHDRAWALS";
export const FETCH_GUEST_PAYMENTS = "FETCH_GUEST_PAYMENTS";
export const FETCH_GUEST_PAYMENTS_FULFILLED = "FETCH_GUEST_PAYMENTS_FULFILLED";
export const FETCH_GUEST_WITHDRAWALS_FULFILLED = "FETCH_GUEST_WITHDRAWALS_FULFILLED";
export const WITHDRAW_GUEST_USER_BALANCE = "WITHDRAW_GUEST_USER_BALANCE";
export const WITHDRAW_GUEST_USER_BALANCE_FULFILLED = "WITHDRAW_GUEST_USER_BALANCE_FULFILLED";
export const RESET_HOST_PAYMENT_DETAILS = "RESET_HOST_PAYMENT_DETAILS";
export const RESET_HOST_PAYMENT_DETAILS_FULFILLED = "RESET_HOST_PAYMENT_DETAILS_FULFILLED";
export const RESET_HOST_PAYMENT_DETAILS_INITIAL_DATA = "RESET_HOST_PAYMENT_DETAILS_INITIAL_DATA";
export const POST_PAYMENT_SCHEDULE = "POST_PAYMENT_SCHEDULE";

// GLOBAL ACTION
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const SET_LAST_PAGY_URL = 'SET_LAST_PAGY_URL';

// REFERRAL ACTIONS
export const FETCH_REFERRALS = "FETCH_REFERRALS";
export const FETCH_REFERRALS_SUCCESS = "FETCH_REFERRALS_SUCCESS";
export const FETCH_REFERRALS_FAILURE = "FETCH_REFERRALS_FAILURE";
export const CREATE_REFERRAL = "CREATE_REFERRAL";
export const CREATE_REFERRAL_SUCCESS = "CREATE_REFERRAL_SUCCESS";
export const CREATE_REFERRAL_FAILURE = "CREATE_REFERRAL_FAILURE";
export const CREATE_REFERRAL_RESET = "CREATE_REFERRAL_RESET";

// VOUCHER ACTIONS
export const FETCH_VOUCHER_DETAILS = "FETCH_VOUCHER_DETAILS";
export const SET_APPLIED_VOUCHER = "SET_APPLIED_VOUCHER";
export const SET_APPLYING_VOUCHER = "SET_APPLYING_VOUCHER";
