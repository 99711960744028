import initialState from '../store/initialState';
import * as Types from '../actions/constants';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_USER_TYPE:
      return Object.assign({}, state, { userType: action.userType });
    case Types.SET_PROFILE_PIC:
      return Object.assign({}, state, { photo: action.photo });
    case Types.SET_UPDATING_PROFILE_PIC:
      return Object.assign({}, state, { updatingProfilePic: action.status });
    case Types.SET_REGISTERING_USER:
      return Object.assign({}, state, { registeringUser: action.status });
    case Types.SET_LOGGING_IN_USER:
      return Object.assign({}, state, { loggingInUser: action.status });
    case Types.SET_LOGGING_OUT_USER:
      return Object.assign({}, state, { loggingOutUser: action.status });
    case Types.SET_UPDATING_USER:
      return Object.assign({}, state, { updatingUser: action.status });
    case Types.SET_UPDATING_USER_PASSWORD:
      return Object.assign({}, state, { updatingUserPassword: action.status });
    case Types.SET_IS_NEW_PROFILE:
      return Object.assign({}, state, { isNewProfile: action.value });
    case Types.SET_SUB_HOSTS:
      return Object.assign({}, state, { subHosts: action.payload });
    case Types.SET_FETCHING_SUB_HOSTS:
      return Object.assign({}, state, { fetchingSubHosts: action.value });
    case Types.SET_USER:
      const test = Object.assign({}, state, { ...action.payload });
      return test;
    default:
      return state;
  };
};

export default reducer;
