export const getAttributes = (parkingSpot, isSearchCard=false) => {
  let host_type = null;
  let host_autarchy = null;
  let place_carsize = null;
  let host_behaviour = null;
  if (parkingSpot && parkingSpot.parking_spot_category_options){
    let returnArray = [];
    [host_type] = parkingSpot.parking_spot_category_options.filter(op => (op.icon.substring(0,9) === "host_type"));
    if (host_type != null){
      returnArray.push(host_type);
    }
    [host_behaviour] = parkingSpot.parking_spot_category_options.filter(op => (op.icon.substring(0,14) === "host_behaviour"));
    if (host_behaviour != null){
      returnArray.push(host_behaviour);
    }
    [host_autarchy] = parkingSpot.parking_spot_category_options.filter(op => (op.icon === "host_autarchy.svg" || op.icon === "host_autarchy_not.svg"));
    if (host_autarchy != null){
      host_autarchy.icon = (host_autarchy.text.replaceAll(" ", "-").toLowerCase() === "no-onboard-toilet-required") ? "host_autarchy_not.svg" : "host_autarchy.svg";
      returnArray.push(host_autarchy);
    } else if (isSearchCard) {
      returnArray.push({icon: "host_autarchy.svg", text: "Onboard Toilet Required"});
    }
    [place_carsize] = parkingSpot.parking_spot_category_options.filter(op => (op.icon.substring(0,13) === "place_carsize"));
    if (place_carsize != null){
      returnArray.push(place_carsize);
    }
    if (returnArray.length < 1){
      returnArray.push({text: "No attributes found", icon:"place_season_snowflake.svg"})
    }
    return returnArray;
  }
  return null;
}
