import { Box, Card, Container, Divider, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BarChartIcon from "@material-ui/icons/BarChart";
import BookIcon from "@material-ui/icons/Book";
import CloseIcon from "@material-ui/icons/Close";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DescriptionIcon from "@material-ui/icons/Description";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import QueueIcon from "@material-ui/icons/Queue";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Progress1Icon } from "../../../assets/images/progress-1.svg";
import { ReactComponent as Progress2Icon } from "../../../assets/images/progress-2.svg";
import { ReactComponent as Progress3Icon } from "../../../assets/images/progress-3.svg";
import { ReactComponent as Progress4Icon } from "../../../assets/images/progress-4.svg";
import { ReactComponent as Progress5Icon } from "../../../assets/images/progress-5.svg";
import { addLoader, addLoaderForItem } from "../../../data/actions/config";
import { fetchStripeStatus } from "../../../data/actions/payment";
import { setUpdatingUser, updateHost } from "../../../data/actions/user";
import { generateCacheBusterKey } from "../../../lib/utils";
import ReferralIcon from "../../common/icons/Referral";

const useStyles = makeStyles((theme) => ({
  progressItemText: {
    fontSize: "0.875rem",
    paddingTop: "0.5rem",
  },
  itemWrapper: {
    flex: "1 0 1",
    minWidth: "33.333%",
    padding: "0em 1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "33.333%",
    },
  },
  dashboardItem: {
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textDecoration: "none",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  itemsWrapper: {
    marginLeft: "-1em",
    marginRight: "-1em",
    marginBottom: 0 - theme.spacing(4),
  },
  progressWrapper: {},
  icon: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.0rem",
    },
  },
  paymentNotification: {
    background: theme.palette.primary.main,
    height: "3.375rem",
    borderRadius: "0.875rem",
    marginTop: "2.5rem",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      height: "7rem",
      marginTop: "1.5rem",
    },
  },
  dashboardNotification: {
    background: theme.palette.primary.main,
    height: "4.25rem",
    borderRadius: "0.875rem",
    marginTop: "2.5rem",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      height: "7rem",
      marginTop: "1.5rem",
    },
  },
  notificationIcon: {
    color: "white",
    fontSize: "1.75rem",
    marginLeft: "1rem",
    marginTop: "-0.125rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.0rem",
      marginLeft: "0.75rem",
    },
  },
  notification: {
    color: "white",
    marginLeft: "0.675rem",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
      marginLeft: "0.75rem",
    },
  },
  progressItemActive: {
    padding: "1em 1em",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    width: "20%",
    alignItems: "center",
    background: theme.palette.primary.main,
    display: "flex",
    textDecoration: "none",
    justifyContent: "start",
    color: "#fff",
    fill: "#fff",
    marginLeft: "1em",
    "&:first-child": {
      marginLeft: "0em",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "1em",
      marginLeft: "0em",
      width: "100%",
    },
  },
  progressItem: {
    padding: "1em 1em",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    width: "20%",
    alignItems: "center",
    display: "flex",
    textDecoration: "none",
    justifyContent: "start",
    fill: theme.palette.secondary.main,
    marginLeft: "1em",
    "&:first-child": {
      marginLeft: "0em",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "1em",
      marginLeft: "0em",
      width: "100%",
    },
  },

  helpText: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const IconForRoute = ({ route, classes }) => {
  switch (route) {
    case "/profile":
      return <AccountCircleIcon className={classes.icon} />;
    case "/security":
      return <VpnKeyIcon className={classes.icon} />;
    case "/parking-controllers":
      return <VpnKeyIcon className={classes.icon} />;
    case "/payment":
      return <CreditCardIcon className={classes.icon} />;
    case "/payment-details":
      return <CreditCardIcon className={classes.icon} />;
    case "/check-ins":
      return <DescriptionIcon className={classes.icon} />;
    case "/my-cars":
      return <DriveEtaIcon className={classes.icon} />;
    case "/ps":
      return <QueueIcon className={classes.icon} />;
    case "/financials":
      return <BarChartIcon className={classes.icon} />;
    case "/bookings":
      return <BookIcon className={classes.icon} />;
    case "/referrals":
      return <ReferralIcon className={classes.icon} />;
    default:
      return null;
  }
};

const userTypeLocalized = (userType, t) => {
  if (userType === "Guest") {
    return t("common.texts.camper");
  } else if (userType === "Host") {
    return t("common.texts.host");
  } else if (userType === "SubHost") {
    return t("common.texts.host");
  }
};

const Dashboard = ({
  user,
  userType,
  token,
  stripeStatus,
  fetchStripeStatus,
  addLoader,
  addLoaderForItem,
  paymentDetails,
  updateHost,
  setUpdatingUser,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let shortcuts = [];
  const isGuestProfileComplete =
    user && user.userType === "Guest" && user.verified && user.completedProfile;

  const hideProgressCard = () => {
    setUpdatingUser(true);
    if (userType === "Host") {
      updateHost(
        {
          profileProgress: 1,
          hostDetailId: user.hostDetails.id,
        },
        token,
        null,
        "/dashboard"
      );
    }
  };

  useEffect(() => {
    if (userType === "Host") {
      addLoaderForItem("stripe-status");
      fetchStripeStatus(token);
    }
  }, [userType, token]);

  if (userType === "Guest") {
    shortcuts = [
      {
        label: t("profile.title"),
        description: t("profile.sub-text"),
        route: "/profile",
      },
      {
        label: t("security.title"),
        description: t("security.sub-text"),
        route: "/security",
      },
      {
        label: t("payment.title"),
        description: t("payment.sub-text"),
        route: "/payment",
      },
      {
        label: t("check-ins.sub-title"),
        description: t("check-ins.sub-text"),
        route: "/check-ins",
      },
      {
        label: t("car.title"),
        description: t("car.sub-text"),
        route: "/my-cars",
      },
      {
        label: t("referrals.title"),
        description: t("referrals.sub-text"),
        route: "/referrals",
      },
    ];
  } else if (userType === "Host") {
    shortcuts = [
      {
        label: t("profile.title"),
        description: t("profile.sub-text"),
        route: "/profile",
      },
      {
        label: t("security.title"),
        description: t("security.sub-text"),
        route: "/security",
      },
      {
        label: t("payment.details"),
        description: t("payment.sub-text"),
        route: "/payment-details",
      },
      {
        label: t("parking-spot.title"),
        description: t("parking-spot.sub-text"),
        route: "/ps",
      },
      {
        label: t("financial.title"),
        description: t("financial.sub-text"),
        route: "/financials",
      },
      {
        label: t("bookings.title"),
        description: t("bookings.sub-text"),
        route: "/bookings",
      },
      {
        label: t("parking-controller.title"),
        description: t("parking-controller.sub-text"),
        route: "/parking-controllers",
      },
      {
        label: t("referrals.title"),
        description: t("referrals.sub-text"),
        route: "/referrals",
      },
    ];
  } else if (userType === "SubHost") {
    shortcuts = [
      {
        label: t("profile.title"),
        description: t("profile.sub-text"),
        route: "/profile",
      },
      {
        label: t("security.title"),
        description: t("security.sub-text"),
        route: "/security",
      },
      {
        label: t("parking-spot.title"),
        description: t("parking-spot.sub-text"),
        route: "/ps",
      },
      {
        label: t("bookings.title"),
        description: t("bookings.sub-text"),
        route: "/bookings",
      },
    ];
  }

  const paymentsEnabled = stripeStatus && stripeStatus.payouts_enabled;
  const showPaymentNotification = userType === "Host" && !paymentsEnabled;
  const showDashboardNotification =
    userType === "Guest" && !isGuestProfileComplete;
  const [cacheBuster, setCacheBuster] = useState(null);
  const query = useQuery();

  useEffect(() => {
    if (!cacheBuster) {
      setCacheBuster(generateCacheBusterKey());
    }
  }, []);

  if (query.get("error")) {
    throw new Error("This is a test");
  }
  return (
    <Container maxWidth="md">
      {userType === "Host" && !!query.get("id") && cacheBuster && (
        <Box key={cacheBuster} display="none">
          <img
            src={`https://www.google-analytics.com/collect?v=1&tid=UA-198820811-1&cid=555&aip=1&t=event&ec=5_completed&ea=5_completed&el=${query.get(
              "id"
            )}&;dp=&;dt=&;z=${cacheBuster}`}
          />
        </Box>
      )}
      <Box pt={isMobile ? 0 : 2} pb={2}>
        <Typography variant="h1">
          {userTypeLocalized(userType, t)} {t("common.texts.dashboard")}
        </Typography>
      </Box>
      <Divider />
      {showPaymentNotification && (
        <Box
          className={classes.paymentNotification}
          alignItems="center"
          display="flex"
          component={Link}
          to={"/payment-details"}
        >
          <ErrorOutlineIcon className={classes.notificationIcon} />
          <Typography
            variant={isMobile ? "body2" : "subtitle"}
            className={classes.notification}
          >
            {t("host-dashboard.notification.host-payment")}
          </Typography>
        </Box>
      )}
      {showDashboardNotification && (
        <Box
          className={classes.dashboardNotification}
          alignItems="center"
          display="flex"
          component={Link}
          to={"/completePaymentDetails"}
        >
          <ErrorOutlineIcon className={classes.notificationIcon} />
          <Typography
            variant={isMobile ? "body2" : "subtitle"}
            className={classes.notification}
          >
            {t("host-dashboard.notification.guest-profile-incomplete")}
          </Typography>
        </Box>
      )}
      {userType === "Host" && user.hostDetails.profile_progress !== "complete" && (
        <Box
          display="flex"
          pt={
            isMobile
              ? 3
              : showPaymentNotification || showDashboardNotification
              ? "2.5rem"
              : 8
          }
          flexWrap="wrap"
          className={classes.progressWrapper}
        >
          <Card className={classes.dashboardItem}>
            <div className={classes.helpText}>
              <div></div>
              {user.stripeConnectStatus === "complete" &&
                !!user.spotExists &&
                !!user.completedSpotExists &&
                !!user.verifiedSpotExists &&
                !!user.activeSpotExists &&
                user.hostDetails.profile_progress !== "complete" && (
                  <div>
                    <CloseIcon
                      color="primary"
                      cursor="pointer"
                      onClick={hideProgressCard}
                    />
                  </div>
                )}
            </div>
            <Box
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              justifyContent="space-between"
              pt={isMobile ? 0 : 2}
            >
              <Card
                className={classes.progressItemActive}
                component={Link}
                to={"/profile"}
              >
                <Progress1Icon />
                <Typography
                  color="inherit"
                  className={classes.progressItemText}
                >
                  {t("host-dashboard.progress.registered")}
                </Typography>
              </Card>

              <Card
                className={
                  !!user.spotExists && !!user.completedSpotExists
                    ? classes.progressItemActive
                    : classes.progressItem
                }
                component={Link}
                to={"/ps"}
              >
                <Progress3Icon />
                <Typography
                  color="inherit"
                  className={classes.progressItemText}
                >
                  {t("host-dashboard.progress.spot-exists")}
                </Typography>
              </Card>
              <Card
                className={
                  !!user.verifiedSpotExists
                    ? classes.progressItemActive
                    : classes.progressItem
                }
                component={Link}
                to={"/ps"}
              >
                <Progress4Icon />
                <Typography
                  color="inherit"
                  className={classes.progressItemText}
                >
                  {t("host-dashboard.progress.verified-spot-exists")}
                </Typography>
              </Card>
              {!!user.activeSpotExists ? (
                <Card
                  className={classes.progressItemActive}
                  component={Link}
                  to={"/ps"}
                >
                  <Progress5Icon />
                  <Typography
                    color="inherit"
                    className={classes.progressItemText}
                  >
                    {t("host-dashboard.progress.active-spot-exists")}
                  </Typography>
                </Card>
              ) : (
                <Card
                  className={classes.progressItem}
                  component={Link}
                  to={"/ps"}
                >
                  <Progress5Icon />
                  <Typography
                    color="inherit"
                    className={classes.progressItemText}
                  >
                    {t("host-dashboard.progress.active-spot-not-exist")}
                  </Typography>
                </Card>
              )}
              <Card
                className={
                  user.stripeConnectStatus === "complete"
                    ? classes.progressItemActive
                    : classes.progressItem
                }
                component={Link}
                to={"/payment-details"}
              >
                <Progress2Icon />
                <Typography
                  color="inherit"
                  className={classes.progressItemText}
                >
                  {t("host-dashboard.progress.payment-verified")}
                </Typography>
              </Card>
            </Box>
          </Card>
        </Box>
      )}
      <Box
        display="flex"
        pt={
          isMobile
            ? 3
            : showPaymentNotification || showDashboardNotification
            ? "2.5rem"
            : 8
        }
        flexWrap="wrap"
        className={classes.itemsWrapper}
      >
        {shortcuts.map((shortcut) => {
          return (
            <Box
              key={`shortcutTo${shortcut.route}`}
              display="flex"
              mb={4}
              className={classes.itemWrapper}
            >
              <Card
                className={classes.dashboardItem}
                component={Link}
                to={shortcut.route}
                elevation={0}
              >
                <Box display="flex" flexDirection="row">
                  <IconForRoute route={shortcut.route} classes={classes} />
                  {shortcut.label === t("payment.details") && paymentsEnabled && (
                    <React.Fragment>
                      &nbsp;
                      <Typography variant="body1" color="primary">
                        {t("payment.verified")}
                      </Typography>
                    </React.Fragment>
                  )}
                  {shortcut.label === t("payment.details") && !paymentsEnabled && (
                    <React.Fragment>
                      &nbsp;
                      <Typography variant="body1" color="secondary">
                        {t("payment.not-verified")}
                      </Typography>
                    </React.Fragment>
                  )}
                </Box>
                <Box pt={1} mb={0.75} display="flex">
                  <Typography
                    variant="h3"
                    color={
                      shortcut.label === t("payment.details") &&
                      !paymentsEnabled
                        ? "secondary"
                        : "black"
                    }
                  >
                    {t(shortcut.label)}
                  </Typography>
                </Box>
                <Typography
                  variant="caption"
                  color={
                    shortcut.label === t("payment.details") && !paymentsEnabled
                      ? "secondary"
                      : "black"
                  }
                >
                  {t(`${shortcut.description}`)}
                </Typography>
              </Card>
            </Box>
          );
        })}
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.token,
    userType: state.user.userType,
    stripeStatus: state.payment.stripeStatus,
    paymentDetails: state.payment.paymentDetails,
  };
};

export default connect(mapStateToProps, {
  fetchStripeStatus,
  addLoader,
  addLoaderForItem,
  updateHost,
  setUpdatingUser,
})(Dashboard);
