import initialState from '../store/initialState';
import * as Types from '../actions/constants';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_CARS:
      const allCars = action.payload;
      allCars && allCars.length > 0 && allCars.forEach(c => {
        c.editable = false;
      });
      return Object.assign({}, state, { cars: allCars});
    case Types.SET_CAR_EDITABLE:
        let newCarArray = [];
        state.cars && state.cars.forEach(car => {
          if(car.id === action.id){
            car.editable = action.editable
          }
          newCarArray.push(car);
        })
        return Object.assign({}, state, { cars: [...newCarArray]});
    default:
      return state;
  };
};
