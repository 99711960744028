import { ofType } from "redux-observable";
import { mergeMap, catchError, concatMap, delay } from "rxjs/operators";
import * as Types from "../actions/constants";
import { BACKEND_API } from "./hosts";
import API from "../../lib/api";
import {
  createReferralFailure,
  createReferralSuccess,
  fetchReferrals,
  fetchReferralsFailure,
  fetchReferralsSuccess,
} from "data/actions/referral";

export const fetchReferralsEpic = (action$) =>
  action$.pipe(
    ofType(Types.FETCH_REFERRALS),
    mergeMap((action) => {
      // console.log("fetchReferralsEpic", { action });
      return API.get("/users/referrals", {
        token: action.token,
        host: BACKEND_API,
      }).pipe(
        // * For loading indicator
        // delay(5000),
        concatMap((response) => {
          // console.log("fetch referrals epic response", { response });
          return [fetchReferralsSuccess(response.data)];
        }),
        catchError((error) => {
          // console.log("fetch referrals epic error", { error });
          return [
            ...API.notificationsForErrorResponse(error),
            fetchReferralsFailure(),
          ];
        })
      );
    })
  );

export const createReferralsEpic = (action$) =>
  action$.pipe(
    ofType(Types.CREATE_REFERRAL),
    mergeMap((action) => {
      return API.post(
        "/users/referrals",
        {
          referral: action.data,
        },
        { token: action.token, host: BACKEND_API }
      ).pipe(
        // * For loading indicator
        // delay(5000),
        concatMap((response) => {
          // console.log("create referrals epic response", { response });
          return [
            fetchReferrals(action.token),
            createReferralSuccess(response.data),
          ];
        }),
        catchError((error) => {
          // console.log("create referrals epic error", { error });
          return [
            ...API.notificationsForErrorResponse(error),
            createReferralFailure(),
          ];
        })
      );
    })
  );
