import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useStyles } from '../../onboarding/common/styles';
import { OnboardingLayout } from '../../../layouts/onboarding';

const Fallback = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <OnboardingLayout>
      <Card className={classes.onboardingCard}>
        <Box px={3} py={3} display="flex" justifyContent="center" className={classes.onboardingTitle}>
          <Typography variant="h4" color="textPrimary">
            { t('something-went-wrong') }
          </Typography>
        </Box>
        <CardContent style={{padding: "1.5rem", display: "flex", justifyContent: "center"}}>
          { t('our-team-notified') }
        </CardContent>
      </Card>
    </OnboardingLayout>
  )
};

export default Fallback;
