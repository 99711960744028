import { ofType } from 'redux-observable';
import { mergeMap, flatMap, catchError } from 'rxjs/operators';
import { push } from 'connected-react-router'

import * as Types from '../actions/constants';
import { BACKEND_API } from './hosts';
import API from '../../lib/api';
import * as UserActions from '../actions/user';
import * as PaymentActions from '../actions/payment';
import { removeLoader, setAppVersion, removeLoaderForItem } from '../actions/config';

export const updateHost = action$ =>
  action$.pipe(
    ofType(Types.UPDATE_HOST),
    mergeMap((action) => {
      const detail = sanitize({
        id: action.payload.hostDetailId,
        company_name: action.payload.companyName,
        iban: action.payload.iban,
        bio: action.payload.bio,
        dob: action.payload.dob,
        currency: action.payload.currency,
        profile_progress: action.payload.profileProgress,
      });

      detail.accommodation_id = action.payload.accommodationId;
      if (!detail.company_name && action.payload.companyName === ""){
        detail.company_name = action.payload.companyName;
      }
      if (!detail.bio && action.payload.bio === ""){
        detail.bio = action.payload.bio
      }

      const host = {
        first_name: action.payload.name,
        last_name: action.payload.surname,
        email: action.payload.email,
        phone: action.payload.phone && !action.payload.phone.startsWith('+') ? `+${action.payload.phone}` : null,
        correspondence_language: action.payload.correspondenceLanguage,
      };

      const address = sanitize({
        line1: action.payload.companyStreet,
        street_number: action.payload.companyStreetNumber,
        zip: action.payload.companyZip,
        city: action.payload.companyCity,
        country: action.payload.companyCountry,
        // place_id: action.payload.placeId, /* Disable until autocomplete is higher priority again */
      });

      if (!!address.place_id && !!action.payload.completeAddress) {
        address.complete_address = action.payload.completeAddress;
      }
      const data = {
        host: {
          completed_profile: true,
          ...sanitize(host),
        }
      };

      if(Object.keys(address).length) {
        data.host["address_attributes"] = address;
      }

      if(Object.keys(detail).length) {
        data.host["host_detail_attributes"] = detail;
      }

      return API.patch('/hosts/users/update_profile', data, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            UserActions.setUser({
              id: response.data.id,
              fullName: response.data.full_name,
              name: response.data.first_name,
              surname: response.data.last_name,
              email: response.data.email,
              verified: response.data.verified,
              dob: response.data.dob,
              photo: response.data.profile_pic_url,
              phone: response.data.phone,
              hostDetails: response.data.host_details,
              completeAddress: response.data.complete_address,
              completedProfile: response.data.completed_profile,
              parkingSpots: response.data.parking_spots,
              correspondenceLanguage: response.data.correspondence_language,
            }),
            push(action.path ? action.path : `/dashboard${action.tag ? `?tag=${action.tag}` : ""}`),
            UserActions.setUpdatingUser(false),
          ];
        }),
        catchError(error => {
          console.log(`Could not update host: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            UserActions.setUpdatingUser(false),
          ];
        })
      )
    })
  );

const sanitize = (entry) => {
  const sanitizedEntry = Object.assign({}, entry);
  Object.keys(sanitizedEntry).forEach(
    (key) => (sanitizedEntry[key] === undefined || sanitizedEntry[key] === null || sanitizedEntry[key] === "") && delete sanitizedEntry[key]
  );
  return sanitizedEntry;
};

export const updateGuest = action$ =>
  action$.pipe(
    ofType(Types.UPDATE_GUEST),
    mergeMap((action) => {
      const detail = sanitize({
        id: action.payload.guestDetailId,
        stripe_payment_method_id: action.payload.stripePaymentMethodId,
      });

      const guest = {
        first_name: action.payload.name,
        last_name: action.payload.surname,
        phone: action.payload.phone && !action.payload.phone.startsWith('+') ? `+${action.payload.phone}` : null,
        email: action.payload.email,
        dob: action.payload.dob,
      };

      const car = sanitize({
        name: action.payload.carName,
        number_plate: action.payload.numberPlate,
      });

      const data = {
        guest: {
          ...sanitize(guest),
        }
      };

      if(car.name) {
        data.guest["cars_attributes"] = [car];
      }

      if(Object.keys(detail).length) {
        data.guest["guest_detail_attributes"] = detail;
      }

      return API.patch('/guests/users/update_profile', data, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            UserActions.setUser({
              id: response.data.id,
              fullName: response.data.full_name,
              name: response.data.first_name,
              surname: response.data.last_name,
              email: response.data.email,
              verified: response.data.verified,
              photo: response.data.profile_pic_url,
              phone: response.data.phone,
              guestDetails: response.data.guest_details,
              completedProfile: response.data.completed_profile,
              status: response.data.status,
            }),
            push(action.path ? action.path : `/completePaymentDetails${action.tag ? `?tag=${action.tag}` : ""}`),
            UserActions.setUpdatingUser(false),
          ];
        }),
        catchError(error => {
          console.log(`Could not update guest: ${error.message}`);
          const errorActions = [
            UserActions.setUpdatingUser(false),
          ];
          if (action.payload.stripeTokenId && error.response.status === 402) {
            errorActions.push(PaymentActions.setRepaymentFailedDialogOpen(true));
          }
          else {
            errorActions.push(...API.notificationsForErrorResponse(error));
          }
          return errorActions;
        })
      )
    })
  );

export const postProfilePic = action$ =>
  action$.pipe(
    ofType(Types.POST_PROFILE_PIC),
    mergeMap((action) => {
      return API.post('/users/registrations/upload_profile_pic', action.photo, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            UserActions.setProfilePic(response.data.profile_pic_url),
            UserActions.setUpdatingProfilePic(false),
          ];
        }),
        catchError(error => {
          console.log(`Could not update profile picture: ${error.message}`);
          return [
            UserActions.setUpdatingProfilePic(false),
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const fetchHostProfile = action$ =>
  action$.pipe(
    ofType(Types.FETCH_HOST_PROFILE),
    mergeMap((action) => {
      return API.get(`/hosts/users/profile`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          const locale = response.data.locale;
          if (locale) {
            localStorage.setItem('lang', locale);
          }
          return [
            UserActions.setUser({
              id: response.data.id,
              fullName: response.data.full_name,
              email: response.data.email,
              verified: response.data.verified,
              dob: response.data.dob,
              photo: response.data.profile_pic_url,
              phone: response.data.phone,
              hostDetails: response.data.host_details,
              address: response.data.address,
              completeAddress: response.data.complete_address,
              completedProfile: response.data.completed_profile,
              correspondenceLanguage: response.data.correspondence_language,
              locale,
              status: response.data.status,
              stripeConnectStatus: response.data.stripe_connect_status,
              spotExists: response.data['spot_exists?'],
              verifiedSpotExists: response.data['verified_spot_exists?'],
              completedSpotExists: response.data['completed_spot_exists?'],
              activeSpotExists: response.data['active_spot_exists?'],
            }),
            removeLoader(),
            setAppVersion(response.headers["app-version"] || "1"),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch host profile: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            removeLoader()
          ];
        })
      )
    })
  );

export const fetchGuestProfile = action$ =>
  action$.pipe(
    ofType(Types.FETCH_GUEST_PROFILE),
    mergeMap((action) => {
      return API.get(`/guests/users/profile`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          const locale = response.data.locale;
          if (locale) {
            localStorage.setItem('lang', locale);
          }
          return [
            UserActions.setUser({
              id: response.data.id,
              fullName: response.data.full_name,
              email: response.data.email,
              verified: response.data.verified,
              photo: response.data.profile_pic_url,
              phone: response.data.phone,
              guestDetails: response.data.guest_details,
              completedProfile: response.data.completed_profile,
              locale,
              status: response.data.status,
              stripeConnectStatus: response.data.stripe_connect_status,
              spotExists: response.data['spot_exists?'],
              verifiedSpotExists: response.data['verified_spot_exists?'],
              activeSpotExists: response.data['active_spot_exists?'],
            }),
            removeLoaderForItem("profile"),
            setAppVersion(response.headers["app-version"] || "1"),
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch guest profile: ${error.message}`);
          return [
            removeLoaderForItem("profile"),
            ...API.notificationsForErrorResponse(error),
          ];
        })
      )
    })
  );

export const updateHostLocale = action$ =>
  action$.pipe(
    ofType(Types.UPDATE_HOST_LOCALE),
    mergeMap((action) => {
      const data = {
        host: {
          locale: action.payload.locale,
        }
      };

      return API.patch('/hosts/users/update_profile', data, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            UserActions.setUser({
              id: response.data.id,
              fullName: response.data.full_name,
              parkingSpots: response.data.parking_spots,
              email: response.data.email,
              verified: response.data.verified,
              dob: response.data.dob,
              photo: response.data.profile_pic_url,
              phone: response.data.phone,
              hostDetails: response.data.host_details,
              completedProfile: response.data.completed_profile,
              locale: response.data.locale
            }),
            UserActions.setUpdatingUser(false),
          ];
        }),
        catchError(error => {
          console.log(`Could not update host locale: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            UserActions.setUpdatingUser(false),
          ];
        })
      )
    })
  );

export const updateGuestLocale = action$ =>
  action$.pipe(
    ofType(Types.UPDATE_GUEST_LOCALE),
    mergeMap((action) => {
      const data = {
        guest: {
          locale: action.payload.locale,
        }
      };

      return API.patch('/guests/users/update_profile', data, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            UserActions.setUser({
              id: response.data.id,
              fullName: response.data.full_name,
              email: response.data.email,
              verified: response.data.verified,
              photo: response.data.profile_pic_url,
              phone: response.data.phone,
              guestDetails: response.data.guest_details,
              locale: response.data.locale,
              status: response.data.status
            }),
            UserActions.setUpdatingUser(false),
          ];
        }),
        catchError(error => {
          console.log(`Could not update guest locale: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            UserActions.setUpdatingUser(false),
          ];
        })
      )
    })
  );

export const fetchSubHosts = action$ =>
  action$.pipe(
    ofType(Types.FETCH_SUB_HOSTS),
    mergeMap((action) => {
      return API.get((action.requestUrl) ? action.requestUrl : `/hosts/sub_hosts`, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            UserActions.setSubHosts(response.data),
            UserActions.setFetchingSubHosts(false)
          ];
        }),
        catchError(error => {
          console.log(`Could not fetch sub hosts: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            UserActions.setFetchingSubHosts(false)
          ];
        })
      )
    })
  );

export const postNewSubHost = action$ =>
  action$.pipe(
    ofType(Types.POST_NEW_SUB_HOST),
    mergeMap((action) => {
      return API.post(`/hosts/sub_hosts`, { ...action.payload }, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            UserActions.fetchSubHosts(action.token),
          ];
        }),
        catchError(error => {
          console.log(`Could not create new sub host: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            UserActions.setFetchingSubHosts(false)
          ];
        })
      )
    })
  );

export const deleteSubHost = action$ =>
  action$.pipe(
    ofType(Types.DELETE_SUB_HOST),
    mergeMap((action) => {
      return API.delete(`/hosts/sub_hosts/${action.id}`, {}, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            UserActions.fetchSubHosts(action.token),
          ];
        }),
        catchError(error => {
          console.log(`Could not delete sub host: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            UserActions.setFetchingSubHosts(false)
          ];
        })
      )
    })
  );

export const updateSubHost = action$ =>
  action$.pipe(
    ofType(Types.UPDATE_SUB_HOST),
    mergeMap((action) => {
      return API.patch(`/hosts/sub_hosts/${action.id}`, { ...action.payload }, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            UserActions.fetchSubHosts(action.token, action.url),
          ];
        }),
        catchError(error => {
          console.log(`Could not update sub host: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            UserActions.setFetchingSubHosts(false)
          ];
        })
      )
    })
  );

export const updateSubHostProfile = action$ =>
  action$.pipe(
    ofType(Types.UPDATE_SUB_HOST_PROFILE),
    mergeMap((action) => {
      const subHost = {
        full_name: action.payload.fullName,
        email: action.payload.email,
      };

      const data = {
        sub_host: {
          completed_profile: true,
          ...sanitize(subHost),
        }
      };

      return API.patch('/sub_hosts/users/update_profile', data, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            UserActions.setUser({
              id: response.data.id,
              fullName: response.data.full_name,
              email: response.data.email,
              verified: response.data.verified,
              phone: response.data.phone,
              hostDetails: response.data.host_details,
              completedProfile: response.data.completed_profile,
              locale: response.data.locale,
            }),
            push(`/dashboard`),
            UserActions.setUpdatingUser(false),
          ];
        }),
        catchError(error => {
          console.log(`Could not update sub host profile: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            UserActions.setUpdatingUser(false),
          ];
        })
      )
    })
  );

  export const updateSubHostLocale = action$ =>
  action$.pipe(
    ofType(Types.UPDATE_SUB_HOST_LOCALE),
    mergeMap((action) => {

      const data = {
        sub_host: {
          locale: action.payload.locale
        }
      };

      return API.patch('/sub_hosts/users/update_profile', data, { token: action.token, host: BACKEND_API }).pipe(
        flatMap((response) => {
          return [
            UserActions.setUser({
              id: response.data.id,
              fullName: response.data.full_name,
              email: response.data.email,
              verified: response.data.verified,
              phone: response.data.phone,
              hostDetails: response.data.host_details,
              completedProfile: response.data.completed_profile,
              locale: response.data.locale,
            }),
            UserActions.setUpdatingUser(false),
          ];
        }),
        catchError(error => {
          console.log(`Could not update sub host locale: ${error.message}`);
          return [
            ...API.notificationsForErrorResponse(error),
            UserActions.setUpdatingUser(false),
          ];
        })
      )
    })
  );
