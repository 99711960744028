import React from 'react';
import { TextField, Box, Typography, CircularProgress, InputAdornment } from '@material-ui/core';
import { Controller, useWatch } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { useStyles } from '../common/styles';

const FormField = ({ name, control, phone, mask, margin=true, getValues=null, limit, type, ...rest }) => {
  let textCap = null;
  if (type === "text") {
    if (limit) {
      textCap = limit;
    } else if (rest.multiline) {
      textCap = 1000;
    } else {
      textCap = 150;
    }
  }

  const classes = useStyles();
  const value = useWatch({
    control,
    name: name,
  });

  return (
    <Box position="relative" className={margin ? classes.textFieldWrapper : classes.textFieldWrapperNoMargin}>
      <Controller as={
        phone ? (
          <InputMask
            mask={mask}
            disabled={false}
            maskChar=" "
          >
            { () => <TextField
              className={classes.textField}
              InputProps={{
                className: classes.input,
                classes: {
                  focused: classes.focused,
                  disabled: classes.disabled,
                },
                disableUnderline: true,
              }}
              variant="filled"
              {...rest}
            /> }
          </InputMask>
        ) : (
          <TextField
            className={classes.textField}
            InputProps={{
              className: classes.input,
              classes: {
                focused: classes.focused,
                disabled: classes.disabled,
              },
              disableUnderline: true,
              ...(!!textCap && !rest.multiline ? { endAdornment: (
                <InputAdornment position="end">
                  <Box position="relative" display="flex" flexDirection="row-reverse" alignItems="center">
                    <Box position="relative" display="flex" flexDirection="column" alignItems="center">
                      <CircularProgress variant="determinate" size={20} value={100} className={classes.progressBottom} />
                      <CircularProgress variant="determinate" size={20} value={Number((value ? value.length : 0)/textCap * 100)} className={classes.progressTop} />
                    </Box>
                    <Typography variant="caption" className={classes.adornmentText}>{`${(value ? value.length : 0)} / ${textCap}`}</Typography>
                  </Box>
                </InputAdornment>
              ) } : {}),
            }}
            {...(!!textCap ? {inputProps: { maxLength: textCap }} : {})}
            variant="filled"
            {...(!!type ? {type} : {})}
            {...rest}
          />
        )
      } name={name} control={control} />
      { !!textCap && rest.multiline && (
        <Box position="relative" display="flex" flexDirection="row-reverse" alignItems="center" className={classes.multilineCounter}>
          <Box position="relative" display="flex" flexDirection="column" alignItems="center">
            <CircularProgress variant="determinate" size={20} value={100} className={classes.progressBottom} />
            <CircularProgress variant="determinate" size={20} value={Number((value ? value.length : 0)/textCap * 100)} className={classes.progressTop} />
          </Box>
          <Typography variant="caption" className={classes.adornmentText}>{`${(value ? value.length : 0)} / ${textCap}`}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default FormField;
