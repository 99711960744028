import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router'
import { createEpicMiddleware } from 'redux-observable';

import state from './initialState';
import createRootReducer from '../reducers';
import rootEpic from '../epics';

const epicMiddleware = createEpicMiddleware();
export const history = createBrowserHistory()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  createRootReducer(history),
  state,
  composeEnhancers(
    applyMiddleware(epicMiddleware, routerMiddleware(history))
  )
);
epicMiddleware.run(rootEpic);

export default store;
