import React from "react";
import { fade, useTheme } from "@material-ui/core/styles";
import { TextField, Box, Typography } from '@material-ui/core';

import { useStyles } from '../common/styles';

const StripeInput = (props) => {
  const {
    component: Component,
    inputRef,
    options,
    ...rest
  } = props;
  const theme = useTheme();
  const [mountNode, setMountNode] = React.useState(null);

  React.useImperativeHandle(
    inputRef,
    () => ({
      focus: () => mountNode.focus()
    }),
    [mountNode]
  );

  return (
    <Component
      onReady={setMountNode}
      options={{
        ...options,
        style: {
          base: {
            color: theme.palette.text.primary,
            fontSize: theme.typography.body2.fontSize,
            fontFamily: theme.typography.fontFamily,
            "::placeholder": {
              color: fade(theme.palette.text.primary, 0.42)
            }
          },
          invalid: {
            color: "red"
          }
        }
      }}
      {...rest}
    />
  );
};

export const StripeTextField = ({
  InputLabelProps,
  labelErrorMessage,
  stripeElement,
  InputProps = {},
  inputProps,
  error,
  label,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.textFieldWrapper}>
      <TextField
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          ...InputProps,
          inputProps: {
            ...inputProps,
            ...InputProps.inputProps,
            component: stripeElement
          },
          inputComponent: StripeInput,
          className: classes.inputStripe,
          classes: {
            focused: classes.focused,
            disabled: classes.disabled,
          },
          disableUnderline: true,
        }}
        variant="outlined"
        {...rest}
      />
      { error && (
        <Typography variant="body1" color="secondary">{labelErrorMessage}</Typography>
      )}
    </Box>
  );
};
