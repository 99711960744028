import { combineReducers } from 'redux';
import authReducer from './auth';
import configReducer from './config';
import userReducer from './user';
import parkingSpotReducer from './parkingSpot';
import carsReducer from './cars'
import paymentReducer from './payment';
import globalReducer from './global';
import referralReducer from './referral';
import voucherReducer from './voucher';
import { connectRouter } from 'connected-react-router'

const createRootReducer = (history) => combineReducers({
  auth: authReducer,
  config: configReducer,
  user: userReducer,
  parkingSpot: parkingSpotReducer,
  cars: carsReducer,
  payment: paymentReducer,
  global: globalReducer,
  router: connectRouter(history),
  referral: referralReducer,
  voucher: voucherReducer,
});

export default createRootReducer
