import React from "react";
import MuiTimeline from "@material-ui/lab/Timeline";
import MuiTimelineItem from "@material-ui/lab/TimelineItem";
import MuiTimelineSeparator from "@material-ui/lab/TimelineSeparator";
import MuiTimelineConnector from "@material-ui/lab/TimelineConnector";
import MuiTimelineContent from "@material-ui/lab/TimelineContent";
import MuiTimelineDot from "@material-ui/lab/TimelineDot";
import MuiTimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import ReferralIcon from "components/common/icons/Referral";
import AccountIcon from "components/common/icons/Account";
import CoinsIcon from "components/common/icons/Coins";
import HandAndCoinsIcon from "components/common/icons/HandAndCoins";
import { withStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const referralHistoryItems = (t) => {
  return [
    {
      key: "1",
      title: t("referrals.timeline.refer-a-host"),
      icon: <ReferralIcon fontSize="large" />,
    },
    {
      key: "2",
      title: t("referrals.timeline.wait-for-days"),
      icon: <AccountIcon fontSize="large" />,
    },
    {
      key: "3",
      title: t("referrals.timeline.receive-reward"),
      icon: <CoinsIcon fontSize="large" />,
    },
    {
      key: "4",
      title: t("referrals.timeline.withdraw-balance"),
      icon: <HandAndCoinsIcon fontSize="large" />,
    },
  ];
};

const Timeline = withStyles((theme) => ({
  root: {
    padding: 0,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
}))(MuiTimeline);

const TimelineItem = withStyles((theme) => ({
  root: {
    "&:last-child": {
      minHeight: "unset",
      "& .MuiTimelineConnector-root": {
        display: "none",
        [theme.breakpoints.up("sm")]: {
          display: "block",
        },
      },
      "& .MuiTimelineContent-root": {
        paddingBottom: 0,
        [theme.breakpoints.up("sm")]: {
          paddingRight: 0,
          textAlign: "right",
        },
      },
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      minHeight: "unset",
      minWidth: 100,
      flex: 1,
      "&:last-child": {
        alignItems: "flex-end",
        "& .MuiTimelineSeparator-root": {
          flexDirection: "row-reverse",
        },
      },
      "&:not(:first-child):not(:last-child)": {
        alignItems: "center",
        flex: 2,
        "& .MuiTimelineSeparator-root": {
          position: "relative",
          minHeight: 24,
          "& .MuiTimelineDot-root": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          },
        },
        "& .MuiTimelineContent-root": {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          textAlign: "center",
        },
      },
    },
  },
}))(MuiTimelineItem);

const TimelineOppositeContent = withStyles((theme) => ({
  root: {
    flex: "0 0 auto",
    padding: 0,
    paddingRight: theme.spacing(2),
    color: "#010028",
    [theme.breakpoints.up("sm")]: {
      paddingRight: 0,
      marginLeft: -4,
      paddingBottom: theme.spacing(3),
      marginRight: "unset",
      textAlign: "left",
    },
  },
}))(MuiTimelineOppositeContent);

const TimelineDot = withStyles((theme) => ({
  root: {
    padding: 10,
    margin: 0,
    backgroundColor: theme.palette.primary.main,
  },
}))(MuiTimelineDot);

const TimelineConnector = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "2px",
    },
  },
}))(MuiTimelineConnector);

const TimelineContent = withStyles((theme) => ({
  root: {
    ...theme.typography.body1,
    paddingTop: 0,
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      padding: 0,
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(3),
      maxWidth: 350,
    },
  },
}))(MuiTimelineContent);

const TimelineSeparator = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      flexDirection: "row",
    },
  },
}))(MuiTimelineSeparator);

function ReferralTimeline() {
  const { t } = useTranslation();

  return (
    <Timeline>
      {referralHistoryItems(t).map((item) => (
        <TimelineItem key={item.key}>
          <TimelineOppositeContent>{item.icon}</TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>{item.title}</TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}

export default ReferralTimeline;
