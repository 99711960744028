import { createMuiTheme } from '@material-ui/core/styles';

let theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#fff",
          fontSize: "0.7rem",
        },
      },
    },
    MuiDialog: {
      root: {
        zIndex: "9999 !important"
      },
    },
    MuiPopover: {
      root: {
        zIndex: "2000 !important"
      },
      paper: {
        minWidth: "90vw",
        borderRadius: "12px",
        marginTop: "2rem",
        border: "1px solid #E7F0F0",
      },
    },
    MuiButton: {
      root: {
        minWidth: "1rem",
        borderRadius: "12px",
        padding: "14px 16px",
        "&:hover": {
          backgroundColor: "#00737A"
        },
        "&:disabled": {
          backgroundColor: "#E0E0E0",
          color: "#8E8E99",
        },
        "&:disabled svg": {
          fill: "#8E8E99 !important"
        },
      },
      startIcon: {
        marginLeft: "0rem",
      },
      label: {
        fontSize: "14px",
      },
      outlinedSizeSmall: {
        backgroundColor: "#FFF",
        padding: "12px",
        "& svg": {
          height: "1.2rem",
          width: "1.2rem",
        }
      },
      outlined: {
        backgroundColor: "#FFF",
        border: "1px solid #E7F0F0",
        borderRadius: "12px",
        padding: "14px 16px",
        "&:hover": {
          backgroundColor: "#F2F9FA"
        },
        "&:disabled": {
          border: "1px solid #8E8E99",
        },
        "& svg": {
          height: "16px",
          width: "16px",
        }
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: "10px",
        padding: "10px 12px",
        paddingTop: "10px",
        paddingBottom: "10px",
        "&:hover": {
          backgroundColor: "#fff"
        },
      },
    },
    MuiCard: {
      root: {
        border: "1px solid #E7F0F0",
        borderRadius: "12px",
      }
    },
    MuiContainer: {
      root: {
      }
    },
    MuiInputBase: {
      root: {
        height: "2.8rem",
      },
      multiline: {
        height: "auto",
        minHeight: "10rem",
        alignItems: "flex-start",
        paddingBottom: "3rem !important",
      }
    },
    MuiOutlinedInput: {
      root: {
        borderColor: "#000",
        borderRadius: "12px",
        "& fieldset": {
          borderColor: "#ddd",
        },
      },
    },
    MuiRadio: {
      root: {
        padding: "9px 4px 9px 9px",
        fontSize: "0.5em"
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
      },
    },
    MuiTableRow: {
      root: {
        borderBottom: "1px solid #E7F0F0",
        "&:last-child": {
          borderBottom: "none",
        }
      },
      head: {
        borderBottom: "1px solid #E7F0F0 !important",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "0.8rem",
      }
    },
    MuiTabs: {
      root: {
        borderRadius: "2.0rem",
        background: "#F2F9FA",
        maxHeight: "2.375rem !important",
        minHeight: "2.375rem !important",
        padding: "0.1875rem"
      },
      indicator: {
        visibility: "hidden"
      }
    },
    MuiSelect: {
      icon: {
        top: "calc(50% - 8px)",
      },
      focus: {
        background: "none",
      },
    },
    MuiTab: {
      root: {
        flex: "auto",
        maxWidth: "50% !important",
        maxHeight: "2rem !important",
        minHeight: "2rem !important",
        "&.Mui-selected": {
          backgroundColor: "#008A94 !important",
          color: "#fff !important",
          borderRadius: "2.0rem",
        }
      },
      wrapper: {
        fontWeight: 400,
        fontSize: "0.8rem"
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: "#E7F0F0"
      }
    }
  },
  typography: {
    fontFamily: [
      'Poppins'
    ].join(','),
    body1: {
      color: "#010028",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
    },
    body2: {
      color: "#010028",
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.6
    },
    button: {
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.01em",
      fontWeight: "500",
      textTransform: "none",
      textAlign: "center"
    },
    subtitle1: {
      color: "#010028",
      fontSize: "0.875rem",
    },
    caption: {
      color: "#010028",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "400",
    },
    h4: {
      color: "#010028",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "400",
    },
    h5: {
      color: "#010028",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "400",
    },
    h3: {
      color: "#010028",
      fontSize: "20px",
      lineHeight: "32px",
      fontWeight: "400",
    },
    h2: {
      color: "#010028",
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: "500",
    },
    h1: {
      color: "#010028",
      fontSize: "27px",
      lineHeight: "46px",
      fontWeight: "400",
    },
    h6: {
      color: "#010028",
      fontSize: "1.0rem",
      fontWeight: "400",
    },
    subtitle2: {
      color: "#010028",
      fontSize: "0.7rem",
      fontWeight: "400",
    }
  },
  palette: {
    text: {
      primary: "#010028",
      secondary: "#333",
    },
    primary: {
      main: "#008A94",
      light: "#a2ce92",
      mainVeryLight: '#F2F9FA'
    },
    secondary: {
      main: "#999",
      light: "#E7F0F0",
      dark: "#333",
      warn: "#FFF7F8"
    },
    action: {
      disabledBackground: '#E0E0E0',
      disabled: '#8E8E99'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      mobile: 390,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shadows: Array(25).fill('none'),
});

theme = {
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiContainer: {
      ...theme.overrides.MuiContainer,
      root: {
        ...theme.overrides.MuiContainer.root,
        paddingLeft: "5.7rem !important",
        paddingRight: "5.7rem !important",
        maxWidth: "unset !important",
        [theme.breakpoints.down("sm")]: {
          paddingLeft: "24px !important",
          paddingRight: "24px !important",
        }
      }
    },
    MuiToolbar: {
      ...theme.overrides.MuiToolbar,
      gutters: {
        padding: "1rem 5.7rem !important",
        [theme.breakpoints.down("md")]: {
          padding: "0rem 1rem !important",
        }
      }
    },
    MuiPopover: {
      ...theme.overrides.MuiPopover,
      paper: {
        ...theme.overrides.MuiPopover.paper,
        [theme.breakpoints.up("md")]: {
          marginTop: "1em",
          minWidth: "1em",
        },
        [theme.breakpoints.down("xs")]: {
          maxWidth: "calc(100vw - 32px)",
          width: "100vw",
        }
      }
    },
    MuiCardContent: {
      root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      }
    },
    MuiInputLabel: {
      root: {
        marginTop: "-0.2rem",
        color: "#888",
        fontSize: "0.75rem",
      }
    },
    MuiFormControlLabel: {
      root: {
        color: theme.palette.secondary.dark,
        fontSize: "0.9rem",
      }
    },
    MuiTabs: {
      ...theme.overrides.MuiTabs,
      root: {
        ...theme.overrides.MuiTabs.root,
        [theme.breakpoints.down("sm")]: {
          maxHeight: "2.75rem !important",
          minHeight: "2.75rem !important",
          padding: "0.1875rem"
        },
      }
    },
    MuiButton: {
      ...theme.overrides.MuiButton,
      startIcon: {
        ...theme.overrides.MuiButton.startIcon,
        "& svg": {
          fill: theme.palette.primary.main,
        }
      },
    },
    MuiTab: {
      ...theme.overrides.MuiTab,
      root: {
        ...theme.overrides.MuiTab.root,
        [theme.breakpoints.down("sm")]: {
          maxHeight: "2.375rem !important",
          minHeight: "2.375rem !important",
        },
      },
      wrapper: {
        ...theme.overrides.MuiTab.wrapper,
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.9rem",
          lineHeight: "1.0rem"
        },
        [theme.breakpoints.down(400)]: {
          fontSize: "0.75rem"
        }
      }
    },
  },
  typography: {
    ...theme.typography,
    body1: {
      ...theme.typography.body1,
      color: theme.palette.secondary.dark,
    },
    body2: {
      ...theme.typography.body2,
      color: theme.palette.secondary.dark,
      [theme.breakpoints.up("md")]: {
        fontSize: "0.8rem",
        fontWeight: 500,
        lineHeight: 1.6
      }
    },
    h6: {
      ...theme.typography.h6,
      color: theme.palette.secondary.dark,
      [theme.breakpoints.up("md")]: {
        fontSize: "0.875rem",
      }
    },
    h1: {
      ...theme.typography.h1,
      [theme.breakpoints.up("md")]: {
        fontSize: "28px",
      }
    },
    button: {
      ...theme.typography.button,
    },
    button2: {
      ...theme.typography.button,
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '28px',
    },
    subtitle1: {
      ...theme.typography.subtitle1,
      [theme.breakpoints.up("md")]: {
        fontSize: "0.9rem",
      }
    },
    subtitle2: {
      ...theme.typography.subtitle2,
      [theme.breakpoints.up("md")]: {
        fontSize: "0.625rem",
      }
    }
  },
};

export default theme;
