import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import styles from '../header/styles';
import flags from '../flags';
import { changeI18nLanguage } from '../../../data/actions/config';
import {fetchHostProfile, fetchGuestProfile, updateHostLocale, updateGuestLocale, setUpdatingUser, updateSubHostLocale} from "../../../data/actions/user";
import { useMediaQuery, useTheme, Menu, Button, Divider, MenuItem } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import i18n from "i18n";

const useStyles = makeStyles((theme) => styles(theme));

const StyledPopover = withStyles({
})((props) => (
  <Menu
    MenuListProps={{ disablePadding: true }}
    elevation={1}
    anchorEl={props.buttonRef.currentTarget}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LanguageSelector = ({
  token,
  user,
  changeI18nLanguage,
  fetchHostProfile,
  fetchGuestProfile,
  updateHostLocale,
  updateGuestLocale,
  updateSubHostLocale,
  setUpdatingUser,
  activeLanguage,
  languages,
  menuState,
  extended,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [langAnchorEl, setLangAnchorEl] = useState(null);
  const [langHover, setLangHover] = useState(false);
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const query = useQuery();

  const { userType, locale } = user;

  useEffect(() => {
    if (token != null && userType === "Guest") {
      fetchGuestProfile(token);
    } else if (token != null && userType === "Host") {
      fetchHostProfile(token);
    }
  }, [userType, fetchGuestProfile, fetchHostProfile, token]);


  const handlePopoverOpen = (event) => {
    if(menuState === "closed" || !menuState) {
      setLangAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = (event) => {
    setLangAnchorEl(null);
  };

  const handleLangMouseEnter = () => {
    setLangHover(true);
  };

  const handleLangMouseLeave = () => {
    setLangHover(false);
    setLangAnchorEl(null);
  };

  const handleLanguageClick = (event, language) => {
    handleLangMouseLeave();
    query.delete('lang')
    history.replace({
      search: query.toString(),
    })
    if (token != null && userType === "Guest") {
      if (activeLanguage !== language){
        setUpdatingUser(true);
        updateGuestLocale({
          ...user,
          locale: language,
        }, token)
      }
    } else if (token != null && userType === "Host") {
      if (activeLanguage !== language){
        setUpdatingUser(true);
        updateHostLocale({
          ...user,
          locale: language,
        }, token)
      }
    } else if (token != null && userType === "SubHost") {
      if (activeLanguage !== language){
        setUpdatingUser(true);
        updateSubHostLocale(token, {
          locale: language,
        })
      }
    }
    changeI18nLanguage(i18n, language);
    localStorage.setItem('lang', language);
  }
  const langMenuOpen = Boolean(langAnchorEl);
  const buttonRef = useRef(null);
  return (
    <>
      <Button
        ref={buttonRef}
        variant="outlined"
        aria-owns={langMenuOpen ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
        style={{marginRight: extended ? "0" : "1rem"}}
        classes={{
          startIcon: classes.languageStartIcon,
        }}
        startIcon={flags(activeLanguage)}
      >
        {!isMobile && activeLanguage.toUpperCase()}
      </Button>
      <StyledPopover
        className={`${classes.popover} ${extended ? classes.popoverExtendedSpace : ""}`}
        anchorEl={langAnchorEl}
        keepMounteds
        buttonRef={buttonRef}
        open={menuState !== "open" && (langMenuOpen || langHover)}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {
          languages.map((language, i) => (
            <div key={`language-option-${i}`}>
              <MenuItem
                onClick={(event) => handleLanguageClick(event, language.code)}
                classes={{
                  root: classes.languageMenuItem,
                }}
              >
                { flags(language.code) }
                {!isMobile && (<div className={classes.optLanguages}>
                  { language.code }
                </div> )}
              </MenuItem>
              { i < (languages.length - 1) && <Divider /> }
            </div>
          ))
        }
      </StyledPopover>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.token,
    activeLanguage: state.config.activeLanguage,
    languages: state.config.languages,
  }
};

export default connect(mapStateToProps, { changeI18nLanguage, updateHostLocale, updateGuestLocale, fetchHostProfile, fetchGuestProfile, setUpdatingUser, updateSubHostLocale })(LanguageSelector);
