import { Box, Button, Tooltip } from "@material-ui/core";
import BalanceWithdrawConfirmDialog from "components/common/balanceWithdrawConfirmDialog/BalanceWithdrawConfirmDialog";
import { withdrawGuestUserBalance } from "data/actions/payment";
import { authTokenSelect } from "data/selectors/auth";
import {
  guestWithdrawalsQuerySelect,
  guestWithdrawBalanceMutationSelect,
} from "data/selectors/payment";
import { guestUserIBANSelect } from "data/selectors/user";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch} from "react-redux";

function WithdrawGuestBalanceButton() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const token = useSelector(authTokenSelect);
  const withdrawGuestUserBalanceMutation = useSelector(
    guestWithdrawBalanceMutationSelect
  );
  const withdrawGuestUserBalanceQuery = useSelector(
    guestWithdrawalsQuerySelect
  );

  const IBAN = useSelector(guestUserIBANSelect);

  const balance =
    withdrawGuestUserBalanceQuery?.data?.balance_status?.available_balance || 0;

  const isSuccess = withdrawGuestUserBalanceMutation?.isSuccess;

  React.useEffect(() => {
    if (isSuccess) {
      setOpen(false);
    }
  }, [isSuccess]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    dispatch(withdrawGuestUserBalance(token));
  };

  if (balance < 50) {
    return (
      <Tooltip title={t("financial.btn-insufficient-tooltip")}>
        <Box display="flex">
          <Button variant="contained" size="small" disabled style={{minWidth: "100%"}}>
            {t("financial.btn-insufficient-balance")}
          </Button>
        </Box>
      </Tooltip>
    );
  }

  return (
    <>
      <BalanceWithdrawConfirmDialog
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
        iban={IBAN}
        withdrawing={withdrawGuestUserBalanceMutation?.isLoading}
      />
      {/* <Tooltip title={t("financial.btn-disabled")}>
        <Box display="flex">
          <Button variant="contained" size="small" disabled style={{minWidth: "100%"}}>
            { t('financial.btn-withdraw-balance') }
          </Button>
        </Box>
      </Tooltip> */}
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        {t("financial.btn-withdraw-balance")}
      </Button>
    </>
  );
}

export default WithdrawGuestBalanceButton;
