import * as Types from './constants';

export const fetchAuthByRefreshToken = (path, layout) => {
  return {
    type: Types.FETCH_AUTH_BY_REFRESH_TOKEN,
    path,
    layout,
  };
};

export const setAuth = (payload) => {
  return {
    type: Types.SET_AUTH,
    payload
  };
};

export const signUp = (payload, path, mode) => {
  return {
    type: Types.SIGN_UP,
    payload,
    path,
    mode,
  };
};

export const login = (payload, path) => {
  return {
    type: Types.LOGIN,
    payload,
    path
  };
};

export const logout = (token) => {
  return {
    type: Types.LOGOUT,
    token,
  };
};

export const recoverPassword = (email, subHost, successText) => {
  return {
    type: Types.RECOVER_PASSWORD,
    email,
    subHost,
    successText
  };
};

export const resetPassword = (payload, successText) => {
  return {
    type: Types.RESET_PASSWORD,
    payload,
    successText
  };
};

export const changePassword = (payload, token) => {
  return {
    type: Types.CHANGE_PASSWORD,
    payload,
    token,
  };
};

export const setPasswordResetRequestSent = (value) => {
  return {
    type: Types.SET_PASSWORD_RESET_REQUEST_SENT,
    value,
  };
}
