import { Chip, makeStyles } from "@material-ui/core";
import React from "react";
import { getReferralStatusColor } from "../utils/misc";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) => props.backgroundColor,
  },
  label: {
    ...theme.typography.caption,
    color: (props) => props.color,
  },
}));

function ReferralStatusChip({ label, ...props }) {
  const { t } = useTranslation();
  const styleProps = React.useMemo(() => {
    return getReferralStatusColor(label);
  }, [label]);

  const classes = useStyles(styleProps);

  return (
    <Chip
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      label={t("referrals.states." + label?.replace("_", "-"))}
      {...props}
    />
  );
}

export default ReferralStatusChip;
