import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Card, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StripeTextField } from '../stripeTextFields';
import { useStyles } from '../common/styles';
import poweredByStripe from '../../../assets/images/powered-by-stripe.svg';
import refreshPayment from '../../../assets/images/refresh-failed-payment.svg';


const PaymentFields = ({ attached, change, setChange, setaddCardPaymentDialogOpen, last4, exp_month, exp_year, dontShowChangeCard, paymentDetails, onboarding }) => {
  const classes = useStyles();
  const [creditCardNumberComplete, setCreditCardNumberComplete] = useState(false);
  const [expirationDateComplete, setExpirationDateComplete] = useState(false);
  const [cvcComplete, setCvcComplete] = useState(false);
  const [cardNumberError, setCardNumberError] = useState(false);
  const [expiredError, setExpiredError] = useState(false);
  const [cvcError, setCvcError] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  useEffect(() => {
    if ((paymentDetails && paymentDetails.status === "suspended")){
      setPaymentFailed(true);
    }
    else {
      setPaymentFailed(false);
    }
  }, [paymentDetails])

  const onElementChange = (field, errorField) => ({
    complete,
    error = { message: null }
  }) => {
    switch (field) {
      case "creditCardNumberComplete":
        setCreditCardNumberComplete(complete);
        setCardNumberError(error.message);
        break;
      case "expirationDateComplete":
        setExpirationDateComplete(complete);
        setExpiredError(error.message);
        break;
      case "cvcComplete":
        setCvcComplete(complete);
        setCvcError(error.message);
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      { attached && !change ? (
        <React.Fragment>
          <Box pt={3} display="flex" flexDirection="column">
            <Card elevation={0}>
              <Box p={2} pt={2}>
                <Box>
                  { (isMobile) ? (
                    <React.Fragment>
                      <Box display="flex" alignItems="center">
                        <Box flex="auto">
                          <Typography variant="h4">{t("payment.credit-card")}</Typography>
                        </Box>
                      </Box>
                      <Box pt={1}>
                        <Typography variant="h4">(**** **** **** {last4})</Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" pt={1.5}>
                        { exp_month && exp_year && (
                          !(paymentFailed && paymentDetails.error_message) ? (
                            <Typography variant="body1" style={{color: `${theme.palette.secondary.main}`, paddingTop: "0.5rem", marginBottom: "0.7rem"}}>{t("payment.expiry")}: {exp_month}/{exp_year}</Typography>
                          ) : (
                            <Typography variant="body1" style={{color: `${theme.palette.secondary.main}`, paddingTop: "0.5rem", marginBottom: "0.7rem"}}>{paymentDetails.error_message}</Typography>
                          )
                        )}
                        { !!paymentFailed &&
                        <Box>
                          <Box className={classes.paymentFailedBubbleMobile}>
                            <Typography variant="body2" style={{fontSize: "0.7rem", color: "white"}}>{t("payment.payment-failed-bubble")}</Typography>
                          </Box>
                          <Box className={classes.refreshPaymentBtnMobile} onClick={()=> setaddCardPaymentDialogOpen(true)}>
                            <img src={refreshPayment} style={{height:"0.8rem", paddingRight:"0.4rem"}} />
                            <Typography variant="body2" className={classes.refreshPaymentBtnText} >{t("payment.refresh-payment")} </Typography>
                          </Box>
                        </Box>
                        }
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Box display="flex" alignItems="center">
                        <Box>
                          <Typography variant="h4">Credit Card (**** **** **** {last4})</Typography>
                        </Box>
                      </Box>
                      <Box display="flex" alignItems={"center"} pt={1.5}>
                        { exp_month && exp_year && (
                          !(paymentFailed && paymentDetails.failed_payment_details) ? (
                            <Typography variant="body1" style={{color: `${theme.palette.secondary.main}`}}>Expiry: {exp_month}/{exp_year}</Typography>
                          ) : (
                            <Typography variant="body1" style={{color: `${theme.palette.secondary.main}`}}>{paymentDetails.failed_payment_details}</Typography>
                          )
                        )}
                        { !!paymentFailed &&
                        <React.Fragment>
                          <Box className={classes.paymentFailedBubble}>
                              <Typography variant="body2" style={{fontSize: "0.7rem", color: "white"}}>Payment Failed</Typography>
                          </Box>
                          <Box className={classes.refreshPaymentBtn} onClick={()=> setaddCardPaymentDialogOpen(true)}>
                            <img src={refreshPayment} style={{height:"0.8rem", paddingRight:"0.4rem", marginTop: "0.3rem"}} />
                            <Typography variant="body2" className={classes.refreshPaymentBtnText} >Refresh Payment </Typography>
                          </Box>
                        </React.Fragment>
                        }
                      </Box>
                    </React.Fragment>
                  )}
                </Box>
              </Box>
            </Card>
          </Box>
          { !dontShowChangeCard && (
            <Box pt={3} display="flex">
              <Button variant="contained" color="primary" type="submit" onClick={() => setChange(true)}>
              { attached ? t("payment.change-card") : t("payment.add-card") }
              </Button>
            </Box>
          )}
        </React.Fragment>
      ) : ( !!change || onboarding ? (
        <Box>
          <Box mb={-1} mt={2}>
          <Typography variant="h5" style={{fontWeight: "500"}}>{ t("payment.fields.card-info") }</Typography>
          </Box>
          <Box className={classes.fieldRow}>
            <Box flex={4}>
              <StripeTextField
                label={ t("payment.fields.card-info") }
                stripeElement={CardNumberElement}
                error={Boolean(cardNumberError)}
                labelErrorMessage={cardNumberError}
                onChange={onElementChange(
                  "creditCardNumberComplete",
                  "cardNumberError"
                )}
              />
            </Box>
            <Box flex={2} ml={ isMobile ? 0 : 2}>
              <StripeTextField
                label="Expiration date"
                stripeElement={CardExpiryElement}
                error={Boolean(expiredError)}
                labelErrorMessage={expiredError}
                onChange={onElementChange(
                  "expirationDateComplete",
                  "expiredError"
                )}
              />
            </Box>
            <Box flex={2} ml={ isMobile ? 0 : 2}>
              <StripeTextField
                label="Security code"
                stripeElement={CardCvcElement}
                error={Boolean(cvcError)}
                labelErrorMessage={cvcError}
                onChange={onElementChange(
                  "cvcComplete",
                  "cvcError"
                )}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box pt={4}>
          <Typography variant="body1">
            {t("payment.no-card")}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};


export default PaymentFields;
