import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation, Trans } from "react-i18next";
import TitleSection from "components/common/titleSection";
import ReferralTimeline from "../components/ReferralTimeline";
import AccountCard from "../components/AccountCard";
import accountCardMedia from "assets/images/individual-account.jpg";
import RouterLink from "react-router-dom/Link";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import iso3311a2 from 'iso-3166-1-alpha-2';
import {
  fetchGuestPayments,
  fetchGuestWithdrawals,
  guestPaymentConnect,
} from "data/actions/payment";
import { addLoaderForItem } from "data/actions/config";
import { authTokenSelect } from "data/selectors/auth";
import { fetchReferrals } from "data/actions/referral";
import { getReferralsSelect } from "data/selectors/referral";
import { userTypeSelect } from "data/selectors/user";
import { USER_TYPES } from "config/user";
import { guestPaymentDetailsQuerySelect } from "data/selectors/payment";
import ReferralsTable from "../components/ReferralsTable";
import DashboardLinkCard from "components/common/dashboardLinkCard/DashboardLinkCard";
import BarChartIcon from "@material-ui/icons/BarChart";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import GuestBalanceGrid from "../components/GuestBalanceGrid";
import WithdrawGuestBalanceButton from "../components/WithdrawGuestBalanceButton";
import { fetchGuestProfile } from "data/actions/user";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mb4: {
    marginBottom: theme.spacing(4),
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  actionsContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: theme.spacing(2),
    "& > *": {
      minWidth: "max-content",
      flex: 1,
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  headerActionsContainer: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "column",
      "& > *:not(:last-child)": {
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(3),
        marginBottom: 0,
      },
    },
  },
  historyReferHostButton: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  balanceHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  balanceHeaderActions: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    width: "100%",
    "& > *": {
      minWidth: "max-content",
      flex: 1,
    },
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  dialogContent: {
    paddingTop: "0.75rem",
    paddingBottom: "0.5rem",
  },
  dialogTitle: {
    paddingTop: "1.6rem",
  },
  dialogNoForm: {
    [theme.breakpoints.up("md")]: {
      width: "auto",
    }
  },
  dialogTitleRoot: {
    '& h2': {
      fontWeight: "500",
      fontSize: theme.typography.h3.fontSize,
    },
    margin: "0.5rem 1.5rem",
    padding: "1rem 0 !important"
  },
  dialogActions: {
    paddingBottom: "1.5rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },
  dialogActionRoot: {
    justifyContent: "flex-start",
  },
  buttonWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    position: 'relative',
  },

}));

function Referrals() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector(authTokenSelect);
  const fetchReferralsResult = useSelector(getReferralsSelect);
  if (!!fetchReferralsResult) {
    // debugger
  }
  const userType = useSelector(userTypeSelect);
  const paymentDetailsResult = useSelector(guestPaymentDetailsQuerySelect);
  const isGuestUser = userType === USER_TYPES.GUEST;
  const isHostUser = userType === USER_TYPES.HOST;
  const [shareLinkDialogOpen, setShareLinkDialogOpen] = React.useState(false)
  const [copiedToClipboard, setCopiedToClipboard] = React.useState(false)

  const isLoading =
    fetchReferralsResult.isLoading ||
    fetchReferralsResult.isError ||
    (isGuestUser && paymentDetailsResult.isLoading);

  const isReferralResultEmpty = !fetchReferralsResult.data?.referrals?.length;

  const isPaymentAccountConnected =
    isGuestUser &&
    !paymentDetailsResult.isError &&
    !!paymentDetailsResult?.data?.details_submitted;

  const handlePaymentConnect = React.useCallback((country) => {
    if (isGuestUser) {
      dispatch(addLoaderForItem("stripe-status"));
      dispatch(guestPaymentConnect(token, iso3311a2.getCode(country)));
    }
  }, [dispatch, isGuestUser, token]);

  React.useEffect(() => {
    if (isGuestUser) {
      dispatch(fetchGuestPayments(token));
    }
  }, [dispatch, isGuestUser, token]);

  React.useEffect(() => {
    dispatch(fetchReferrals(token));
  }, [dispatch, token]);

  React.useEffect(() => {
    if (isGuestUser) {
      dispatch(addLoaderForItem("profile"));
      dispatch(fetchGuestProfile(token));
    }
  }, [dispatch, token]);

  React.useEffect(() => {
    if (isGuestUser) {
      // * Results used in the `GuestBalanceGrid` component
      dispatch(fetchGuestWithdrawals(token));
    }
  }, [dispatch, isGuestUser, token]);

  return (
    <Container>
      <Dialog open={shareLinkDialogOpen} onClose={
        () => {
          setShareLinkDialogOpen(false)
          setCopiedToClipboard(false)
        }
      } aria-labelledby="share-link-dialog-title" >
        <Box px={1} className={classes.dialogNoForm} >
          <Box pt={2.5} style={{
            position: 'relative'
          }}>
            <DialogTitle
              id="check-out-form-dialog-title"
              className={classes.dialogTitle}
              classes={{
                root: classes.dialogTitleRoot,
              }}
            >
            {t("referrals.share.title")}
          </DialogTitle>
          <Button variant="outlined" size="small" onClick={() => {
            setShareLinkDialogOpen(false)
            setCopiedToClipboard(false)
          }} 
            style={{
              position: 'absolute',
              height: "2.5rem",
              width: "2.5rem",
              right: "0.5rem", 
              top: "1rem",
            }}>
              <CloseIcon flex="auto" color="primary" />
            </Button>
          </Box>

          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              <Typography variant="body2">
              {t("referrals.share.subtitle")}
              </Typography>
            </DialogContentText>
          </DialogContent>

          <DialogActions
          className={classes.dialogActions}
          classes={{
                root: classes.dialogActionRoot,
              }}
          >
            <Box width="100%">
              <Box fullWidth>
                <TextField
                  fullWidth
                  id="share-link"
                  type="text"
                  name="share-link"
                  variant="outlined"
                  value={`${window.location.origin}/signup-host?referral_code=${fetchReferralsResult.data?.referral_code}`}
                  readonly
                />
              </Box>
              <Box className={classes.buttonWrapper} style={{
                display: "flex",
              }}>
                <Button variant="contained" color="primary" onClick={
                  () => {
                    navigator.clipboard.writeText(`${window.location.origin}/signup-host?referral_code=${fetchReferralsResult.data?.referral_code}`)
                    setCopiedToClipboard(true)
                  }
                } disabled={copiedToClipboard} fullWidth={true}>
                  <Typography variant="button">
                  {t("referrals.share.copy-btn")}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
      <TitleSection
        routes={[
          {
            name: t("common.texts.dashboard"),
            path: "/dashboard",
          },
        ]}
        title={t("referrals.title")}
        after={
          <Box className={classes.headerActionsContainer}>
            <Button
              component="a"
              href="https://www.parknsleep.eu/forms/scout-bewerbung"
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
            >
              {t("referrals.buttons.become-a-scout")}
            </Button>
            <Button
              component={RouterLink}
              to="/referrals/new"
              variant="contained"
              color="primary"
            >
              {t("referrals.buttons.refer-new-host")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setShareLinkDialogOpen(true)
              }}
            >
              {t("referrals.share.btn")}
            </Button>
          </Box>
        }
      />
      <Divider />
      {isLoading && (
        <Box mt={4} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <Box mt={4}>
          <Box mb={4} className={classes.actionsContainer}>
            <Button
              component="a"
              href="https://www.parknsleep.eu/forms/scout-bewerbung"
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
            >
              {t("referrals.buttons.become-a-scout")}
            </Button>
            <Button
              component={RouterLink}
              to="/referrals/new"
              variant="contained"
              color="primary"
            >
              {t("referrals.buttons.refer-new-host")}
            </Button>
          </Box>
         
          <Box mb={7}>
            {isReferralResultEmpty && (
              <>
                <Box
                  className={classes.mb4}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography component="h3" variant="h3">
                    {t("referrals.history.title")}
                  </Typography>
                </Box>
                <ReferralTimeline />
              </>
            )}
            {!isReferralResultEmpty && (
              <>
                <Typography className={classes.mb3} variant="h3" component="h3">
                  {t("referrals.history.title")}
                </Typography>
                <ReferralsTable
                  referrals={fetchReferralsResult.data?.referrals}
                  isLoading={
                    fetchReferralsResult.isLoading ||
                    fetchReferralsResult.isError
                  }
                />
              </>
            )}
          </Box>
          {isGuestUser && !isPaymentAccountConnected && (
            <AccountCard
              title={t("referrals.account.title")}
              description={<Trans i18nKey="referrals.account.description" />}
              image={accountCardMedia}
              action={{
                onClick: handlePaymentConnect,
                text: t("referrals.account.action.add"),
              }}
            />
          )}
          {isGuestUser && isPaymentAccountConnected && (
            <Box>
              <Box className={classes.balanceHeader}>
                <Typography variant="h3" component="h3">
                  {t("referrals.balance.history-title")}
                </Typography>
                <div className={classes.balanceHeaderActions}>
                  <Button
                    variant="outlined"
                    color="default"
                    onClick={handlePaymentConnect}
                  >
                    {t("referrals.account.action.update")}
                  </Button>
                  <WithdrawGuestBalanceButton />
                </div>
              </Box>
              <GuestBalanceGrid />
            </Box>
          )}
          {isHostUser && (
            <>
              <Typography className={classes.mb3} variant="h3" component="h3">
                {t("financial.title")}
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <DashboardLinkCard
                    title={t("financial.title")}
                    description={t("financial.sub-text")}
                    Icon={BarChartIcon}
                    link="/financials"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DashboardLinkCard
                    title={t("payment.details")}
                    description={t("payment.sub-text")}
                    Icon={CreditCardIcon}
                    link="/payment-details"
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      )}
    </Container>
  );
}

export default Referrals;
