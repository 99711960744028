import React, { useEffect, useState } from 'react';
import { Divider, Container, Box, Typography, Card, Button, Grid } from '@material-ui/core';
import { useStyles } from '../common/styles';
import TitleSection from '../../common/titleSection';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { connect } from 'react-redux';
import { fetchCars, putCar, postCar, deleteCar, setCarEditable, updatePrimaryCar } from './../../../data/actions/cars'
import FormField from '../../common/formField';
import FormFieldWithoutLabel from '../../common/formFieldWithoutLabel';
import OutlinedFormField from 'components/common/outlinedFormField';
import { useForm, Controller } from "react-hook-form";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MissingDataPlaceholder from '../../common/missingDataPlaceholder';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { addLoader } from '../../../data/actions/config';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {ReactComponent as EditIconSVG} from "../../../assets/images/icons/edit-icon.svg";
import {ReactComponent as MarkPrimaryIconSVG} from "../../../assets/images/icons/home-icon.svg";
import {ReactComponent as RemoveIconSVG} from "../../../assets/images/icons/remove-icon.svg";

const MyCars = ({token, cars, fetchCars, putCar, postCar, deleteCar, setCarEditable, updatePrimaryCar, addLoader }) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const defaultValues = { carNamePrimary: "N/A", numberPlatePrimary: "N/A" };
  const { handleSubmit, control, setValue } = useForm({ defaultValues });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuId, setopenMenuId] = React.useState(null);
  const [editCarDialog, setEditCarDialog] = useState(false);
  const [updateCarId, setUpdateCarId] = React.useState(null);

  const handleClickMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setopenMenuId(id);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setopenMenuId(null);
  };

  const onSubmit = (data) => {
    //Add set to primary via newCarPrimary
    if (data.newCarName !== undefined && data.newCarName.length !== 0) {
      let newCar = {
        name: data.newCarName,
        number_plate: data.newNumberPlate,
      }
      addLoader();
      postCar(token, newCar);
      setNewCarDialog(false);
    }

    const secondary = cars.filter(car => car.is_primary === false);
    secondary.forEach(car => {
      if (data[`carNameSecondary${car.id}`] && data[`numberPlateSecondary${car.id}`]){
        addLoader();
        putCar(token, car.id, {name: data[`carNameSecondary${car.id}`], number_plate: data[`numberPlateSecondary${car.id}`]});
        toggleEditable(car.id);
      }
    })

    const [primary] = cars.filter(car => car.is_primary === true);
    if (data.carNamePrimary && data.numberPlatePrimary){
      addLoader();
      putCar(token, primary.id, {name: data.carNamePrimary, number_plate: data.numberPlatePrimary});
      toggleEditable(primary.id);
    }
  }

  const onSubmitUpdateCar = (data) => {
    if (updateCarId) {
      putCar(token, updateCarId, {name: data[`updateCarName`], number_plate: data[`updateCarNumberPlate`]});
    }
    setEditCarDialog(false);
    setUpdateCarId(null);
  }

  useEffect(() => {
    addLoader();
    fetchCars(token);
  }, []);

  useEffect(() => {
    if (cars.length) {
      const [primary] = cars.filter(car => car.is_primary === true);
      setValue("carNamePrimary", primary.name);
      setValue("numberPlatePrimary", primary.number_plate);
      const secondary = cars.filter(car => car.is_primary === false);
      for (let car of secondary){
        setValue(`carNameSecondary${car.id}`, car.name);
        setValue(`numberPlateSecondary${car.id}`, car.number_plate);
      }
    }
  }, [cars, setValue]);

  const [primary] = cars.filter(car => car.is_primary === true);
  const secondary = cars.filter(car => car.is_primary === false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(-1);
  const { t } = useTranslation();

  const [newCarDialog, setNewCarDialog] = useState(false);
  const handleAddCar = () => {
    setNewCarDialog(true);
    setNewCarPrimary(false);
  }
  const [newCarPrimary, setNewCarPrimary] = useState(false);
  const toggleNewPrimary = () => {
    setNewCarPrimary(!newCarPrimary);
  }

  const toggleEditable = (id) => {
    const [editable] = cars.filter(c => c.editable === true);
    if(editable){
      setCarEditable(editable.id, false);
    }
    const car = cars.filter(c => c.id === id);
    setCarEditable(id, !car.editable)
  }

  const handleDeletePrimary = () => {
    if (secondary.length!== 0){
      addLoader();
      deleteCar(token, idToDelete);
    }
  }

  const handleDeleteSecondary = () => {
    addLoader();
    deleteCar(token, idToDelete);
  }

  return (
    <Container maxWidth="md">
      <TitleSection
        routes={[{
          name: t("common.texts.dashboard"),
          path: "/dashboard",
        }]}
        title={ t("car.title") }
      />
      <Divider />
      <Box pt={5}>
        <Box display="flex" alignItems="center">
          <Box flex="auto">
            <Typography variant="h3">
              { t("car.info") }
            </Typography>
          </Box>
        </Box>

        <Box pt={4} pb={3}>
          {cars.length > 0 ? (
            <Grid container spacing={3}>
              {
                (cars.length > 0) && (
                  <Grid item sm={isMobile ? 12 : 6} xs={12}>
                    <Card elevation={0}>
                      <Box p={3} position="relative" minHeight={isMobile? "initial" : "7.5rem"}>
                        {
                          !primary.editable ? (
                            <React.Fragment>
                              <Box display="flex" alignItems="flex-start">
                                <Box display="flex" flexDirection={isXs ? "column-reverse" : "row"} justifyContent="center" alignItems={isXs ? "flex-start" : "center"} flex="auto">
                                  <Box className={classes.carPrimaryContainer}>
                                    <StarIcon className={classes.carPrimaryStarIcon} />
                                    <Box mx={0.5} >
                                      <Typography variant="caption" className={classes.carPrimaryText}>
                                        {t("car.primary")}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box flex="auto">
                                    <Typography variant="h3" flex="1 1 auto">
                                      {primary.name}
                                    </Typography>
                                  </Box>
                                </Box>
                                <div>
                                  <Button
                                    id="basic-button"
                                    aria-controls="basic-menu"
                                    aria-haspopup="true"
                                    className={classes.actionsButton}
                                    onClick={(event) => handleClickMenu(event, primary.id)}
                                  >
                                    <MoreHorizIcon className={classes.moreHorizIcon} />
                                  </Button>
                                  <Menu
                                      id="basic-menu"
                                      anchorEl={anchorEl}
                                      open={ openMenuId === primary.id }
                                      onClose={handleCloseMenu}
                                      MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                        style: {
                                          paddingBottom: 0,
                                          paddingTop: 0
                                        }
                                      }}
                                      PaperProps={{
                                        style: {
                                          borderRadius: "12px",
                                          padding: 0,
                                          marginTop: "1em",
                                          overflow: "hidden",
                                          minWidth: "unset",
                                          boxShadow: "0 3px 7px rgb(0 0 0 / 13%), 0 0.6px 2px rgb(0 0 0 / 10%)"
                                        }
                                      }}
                                  >
                                    <MenuItem className={classes.menuItem} onClick={() => {
                                      handleCloseMenu();
                                      setEditCarDialog(true);
                                      setUpdateCarId(primary.id);
                                    }}>
                                      <EditIconSVG className={classes.menuItemIcon} />
                                      { t('common.btn.edit') }
                                    </MenuItem>
                                  </Menu>
                                </div>
                              </Box>
                              <Box pt={1} display="flex" alignItems="center">
                                <Box flex="auto">
                                  <Typography variant="body1" className={classes.carNumberPlate}>
                                    {t("car.plate")}: {primary.number_plate}
                                  </Typography>
                                </Box>
                              </Box>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Box display="flex" alignItems="center">
                                <Box flex="auto">
                                  <Typography variant="subtitle1" flex="auto">
                                    {t("car.edit-primary")}
                                  </Typography>
                                </Box>
                              </Box>
                              <form onSubmit={handleSubmit(onSubmit)} className={classes.root} autoComplete="off" autoCorrect="off">
                                <Box pt={1.25} display="flex" alignItems="center" height={isXs ? "initial" : "3.25rem"}>
                                  <Box display="flex" alignItems="center" flexDirection={isXs ? "column" : "row"} mb={isXs ? 0.25 : 3} width="100%">
                                    <Box width={isXs ? "100%" : "50%"} pr={isXs ? 0 : 0.75} mt={isXs ? 0.25 : 0}>
                                      <FormField type="text" label={ t("car.name") } name="carNamePrimary" control={control} margin={!isXs} required />
                                    </Box>
                                    <Box width={isXs ? "100%" : "50%"} pl={isXs ? 0 : 0.75} mt={isXs ? 1.25 : 0}>
                                      <FormField type="text" label={ t("car.plate") } name="numberPlatePrimary" control={control} margin={!isXs} required />
                                    </Box>
                                    <Box display={!isXs ? "none" : "initial"} width="100%" mt={1.25}>
                                      <Button variant="contained" color="primary" type="submit" fullWidth={true}>
                                        { t("common.btn.save-changes") }
                                      </Button>
                                    </Box>
                                  </Box>
                                  <Box display={isXs ? "none" : "flex"} alignItems="center">
                                    <Button variant="outlined" size="small" className={classes.carButton} type="submit">
                                      <CheckIcon className={classes.carButtonIcon} style={{margin: 0}} />
                                    </Button>
                                  </Box>
                                </Box>
                              </form>
                            </React.Fragment>
                          )
                        }
                      </Box>
                    </Card>
                  </Grid>
                )
              }
              {
                (cars.length > 0) && (secondary.length > 0) && secondary.map(car => {
                  let carname = `carNameSecondary${car.id}`;
                  let numberplate = `numberPlateSecondary${car.id}`;
                  return (
                    <Grid item sm={isMobile ? 12 : 6} xs={12}>
                      <Card elevation={0}>
                        <Box p={3} minHeight={isMobile? "initial" : "7.5rem"}>
                          {
                            !car.editable ? (
                              <React.Fragment>
                                <Box display="flex" alignItems="flex-start">
                                  <Box flex="auto">
                                    <Typography variant="h3" flex="1 1 auto">
                                      {car.name}
                                    </Typography>
                                  </Box>
                                  <div>
                                    <Button
                                        id="basic-button"
                                        aria-controls="basic-menu"
                                        aria-haspopup="true"
                                        className={classes.actionsButton}
                                        onClick={(event) => handleClickMenu(event, car.id)}
                                    >
                                      <MoreHorizIcon className={classes.moreHorizIcon} />
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={ openMenuId === car.id }
                                        onClose={handleCloseMenu}
                                        MenuListProps={{
                                          'aria-labelledby': 'basic-button',
                                          style: {
                                            paddingBottom: 0,
                                            paddingTop: 0
                                          }
                                        }}
                                        PaperProps={{
                                          style: {
                                            borderRadius: "12px",
                                            padding: 0,
                                            marginTop: "1em",
                                            overflow: "hidden",
                                            minWidth: "unset",
                                            boxShadow: "0 3px 7px rgb(0 0 0 / 13%), 0 0.6px 2px rgb(0 0 0 / 10%)"
                                          }
                                        }}
                                    >
                                      <MenuItem className={classes.menuItem} onClick={() => {
                                        handleCloseMenu();
                                        addLoader();
                                        updatePrimaryCar(token, car.id)
                                      }}>
                                        <MarkPrimaryIconSVG className={classes.menuItemIcon} />
                                        { t('car.make-primary') }
                                      </MenuItem>
                                      <Divider/>
                                      <MenuItem className={classes.menuItem} onClick={() => {
                                        handleCloseMenu();
                                        setEditCarDialog(true);
                                        setUpdateCarId(car.id);
                                      }}>
                                        <EditIconSVG className={classes.menuItemIcon} />
                                        { t('common.btn.edit') }
                                      </MenuItem>
                                      <Divider/>
                                      <MenuItem className={classes.menuItemRemove} onClick={() => {
                                        handleCloseMenu();
                                        setDeleteConfirmOpen(true);
                                        setIdToDelete(car.id);
                                      }}>
                                        <RemoveIconSVG className={classes.menuItemIcon} />
                                        { t('security.remove') }
                                      </MenuItem>
                                    </Menu>
                                  </div>
                                </Box>
                                <Box pt={1} display="flex" alignItems="center">
                                  <Box flex="auto">
                                    <Typography variant="body1" className={classes.carNumberPlate}>
                                      {t("car.plate")}: {car.number_plate}
                                    </Typography>
                                  </Box>
                                </Box>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <Box display="flex" alignItems="center">
                                  <Box flex="auto">
                                    <Typography variant="subtitle1" flex="auto">
                                      {t("car.edit-secondary")}
                                    </Typography>
                                  </Box>
                                </Box>
                                <form onSubmit={handleSubmit(onSubmit)} className={classes.root} autoComplete="off" autoCorrect="off">
                                  <Box pt={1.25} display="flex" alignItems="center" height={isXs ? "initial" : "3.25rem"}>
                                    <Box display="flex" alignItems="center" flexDirection={isXs ? "column" : "row"} mb={isXs ? 0.25 : 3} width="100%">
                                      <Box width={isXs ? "100%" : "50%"} pr={isXs ? 0 : 0.75} mt={isXs ? 0.25 : 0}>
                                        <FormField type="text" label={ t("car.name") } name={carname} control={control} margin={!isXs} required />
                                      </Box>
                                      <Box width={isXs ? "100%" : "50%"} pl={isXs ? 0 : 0.75} mt={isXs ? 1.25 : 0}>
                                        <FormField type="text" label={ t("car.plate") } name={numberplate} control={control} margin={!isXs} required />
                                      </Box>
                                      <Box display={!isXs ? "none" : "initial"} width="100%" mt={1.25}>
                                        <Button variant="contained" color="primary" type="submit" fullWidth={true}>
                                          { t("common.btn.save-changes") }
                                        </Button>
                                      </Box>
                                    </Box>
                                    <Box display={isXs ? "none" : "flex"} alignItems="center">
                                      <Button variant="outlined" size="small" className={classes.carButton} type="submit">
                                        <CheckIcon className={classes.carButtonIcon} style={{margin: 0}} />
                                      </Button>
                                    </Box>
                                  </Box>
                                </form>
                              </React.Fragment>
                            )
                          }
                        </Box>
                      </Card>
                    </Grid>
                  )
                })
              }
            </Grid>
          ) : (
            <MissingDataPlaceholder />
          )}
        </Box>
        {/* New Car dialog */}
        <Box>
          <Dialog open={newCarDialog} onClose={
            () => setNewCarDialog(false)
          } aria-labelledby="new-car-dialog" >
            <Box px={isXs ? 0 : 1} className={classes.carNewDialog}>
              <DialogTitle
                id="new-car-dialog-title"
                className={classes.dialogTitle}
                classes={{
                  root: classes.dialogTitleRoot,
                }}
              >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h2">{ t("car.add-car") }</Typography>
                  <Button variant="outlined" size="small" onClick={() => {setNewCarDialog(false)}}>
                    <CloseIcon flex="auto" color="primary" />
                  </Button>
                </Box>
              </DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <Box mb={1} mt={0.5}>
                  <Box p={0}>
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.root} autoComplete="off" autoCorrect="off">
                      <Box display="flex" alignItems="center">
                        <Box display="flex" alignItems="center" flexDirection="column" mb={0.25} width="100%">
                          <Box width="100%" pr={0} >
                            <OutlinedFormField type="text" label={ t("car.name") } name="newCarName" control={control} required />
                          </Box>
                          <Box width="100%" pl={0} mt={2.5}>
                            <OutlinedFormField type="text" label={ t("car.plate") } name="newNumberPlate" control={control} required />
                          </Box>
                        </Box>
                      </Box>
                      <Box flex="auto" mt={2.5}>
                        <Button variant="contained" color="primary" type="submit" fullWidth>
                          { t("common.btn.save-changes") }
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </DialogContent>
            </Box>
          </Dialog>
        </Box>

        {/* Edit Car dialog */}
        <Box>
          <Dialog open={editCarDialog} onClose={
            () => {
              setEditCarDialog(false);
              setUpdateCarId(null);
            }
          } aria-labelledby="new-car-dialog" onEntered={() => {
            let carToBeUpdatedIndex = cars.findIndex((car => car.id === updateCarId));
            setValue('updateCarName', cars[carToBeUpdatedIndex].name);
            setValue('updateCarNumberPlate', cars[carToBeUpdatedIndex].number_plate);
          }} >
            <Box px={isXs ? 0 : 1} className={classes.carNewDialog}>
              <DialogTitle
                  id="new-car-dialog-title"
                  className={classes.dialogTitle}
                  classes={{
                    root: classes.dialogTitleRoot,
                  }}
              >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h2">{ t("car.edit-car") }</Typography>
                  <Button variant="outlined" size="small" onClick={() => {
                    setEditCarDialog(false);
                    setUpdateCarId(null);
                  }}>
                    <CloseIcon flex="auto" color="primary" />
                  </Button>
                </Box>
              </DialogTitle>
              <DialogContent className={classes.dialogContent} style={{paddingTop: "0"}}>
                <Box mb={1} mt={0.5}>
                  <Box p={0}>
                    <form onSubmit={handleSubmit(onSubmitUpdateCar)} className={classes.root} autoComplete="off" autoCorrect="off">
                      <Box display="flex" alignItems="center">
                        <Box display="flex" alignItems="center" flexDirection="column" mb={0.25} width="100%">
                          <Box width="100%" pr={0}>
                            <FormFieldWithoutLabel type="text" placeholder={ t("car.name") } name="updateCarName" control={control} required />
                          </Box>
                          <Box width="100%" pl={0}>
                            <FormFieldWithoutLabel type="text" placeholder={ t("car.plate") } name="updateCarNumberPlate" control={control} required />
                          </Box>
                        </Box>
                      </Box>
                      <Box flex="auto" mt={2.5} ml={0} display="flex">
                        <Button variant="outlined" onClick={() => {
                          setEditCarDialog(false);
                          setUpdateCarId(null);
                        }} fullWidth>
                          <Typography variant="button">
                            { t("common.btn.cancel") }
                          </Typography>
                        </Button>
                        <Button variant="contained" color="primary" type="submit" fullWidth style={{marginLeft: "1rem", color: "#fff"}} >
                          <Typography variant="button">
                            { t("common.btn.save-changes") }
                          </Typography>
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </DialogContent>
            </Box>
          </Dialog>
        </Box>

        <Box>
          <Dialog open={deleteConfirmOpen} onClose={
            () => setDeleteConfirmOpen(false)
          } aria-labelledby="delete-confirm-dialog" >
            <Box px={1} className={classes.dialogNoForm}>
              <DialogTitle
                id="delete-confirm-dialog-title"
                className={classes.dialogTitle}
                classes={{
                  root: classes.dialogTitleRoot,
                }}
              >
                { t("car.btn-delete") }
              </DialogTitle>
              <DialogContent className={classes.dialogContent} style={{paddingBottom: "0.375rem"}}>
                <DialogContentText>
                  <Typography variant="body2">
                    { t("car.delete-confirmation-message") }
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Box flex="auto">
                  <Button variant="contained" color="primary" onClick={
                    () => {
                      setDeleteConfirmOpen(false);
                      if (idToDelete === primary.id){
                        handleDeletePrimary();
                      }
                      else {
                        handleDeleteSecondary();
                      }
                    }
                  } fullWidth={true}>
                    { t("common.btn.yes") }
                  </Button>
                </Box>
                <Box flex="auto" pl={1}>
                  <Button variant="outlined" onClick={
                    () => {
                      setDeleteConfirmOpen(false);
                    }
                  } fullWidth={true}>
                    <Typography variant="button">
                      { t("common.btn.no") }
                    </Typography>
                  </Button>
                </Box>
              </DialogActions>
            </Box>
          </Dialog>
        </Box>
      </Box>
      <Button variant="contained" color="primary" className={classes.listButtonExtraPadding} onClick={handleAddCar} >
        <AddCircleIcon className={classes.buttonIcon} />
        <Typography variant="button"  style={{marginLeft: '0.5rem', color: '#fff'}}>
          { t("car.add-car") }
        </Typography>
      </Button>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    cars: state.cars.cars,
  };
};

export default connect(mapStateToProps, { fetchCars, putCar, postCar, deleteCar, setCarEditable, updatePrimaryCar, addLoader })(MyCars);
