import iso3311a2 from 'iso-3166-1-alpha-2';
import { v4 as uuidv4 } from 'uuid';

export const formatCountry = (location = "") => {
  const locationParts = location.split(",");
  const country = locationParts[locationParts.length - 1].trim();
  const countyIfCZ = country === 'Czechia' ? 'Czech Republic' : country;
  const formattedCountry = iso3311a2.getCode(countyIfCZ);
  const formattedLocation = location.replace(country, formattedCountry);
  return formattedLocation;
}

export const generateCacheBusterKey = () => {
  const timestamp = Math.round(new Date().getTime() / 1000);
  const generatedKey = `${uuidv4()}-${timestamp}`;
  return generatedKey;
}