import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  makeStyles,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TitleSection from "components/common/titleSection";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import FormField from "components/common/formFieldWithSeparateLabel";
import PhoneInput from "components/common/phoneInput/PhoneInput";
import rawCountries from "rawCountries";
import LanguageSelect from "components/common/languageSelect/LanguageSelect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createReferral, createReferralReset } from "data/actions/referral";
import ContactSelect from "../components/form/ContactSelect";

const useStyles = makeStyles((theme) => ({
  fieldLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    display: "inline-block",
  },
  mb4: {
    marginBottom: theme.spacing(4),
  },
  mb4AllChild: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(4),
    },
  },
  smallField: {
    maxWidth: "400px",
  },
  largeField: {
    maxWidth: "700px",
  },
}));

const initialValues = {
  name: "",
  email: "",
  phone: "",
  language: "",
  location_name: "",
  address: "",
  contact: "",
  remarks: "",
};

function NewReferral() {
  const { t } = useTranslation();
  const token = useSelector((state) => state.auth.token);
  const [accepted, setAccepted] = useState(false);
  const history = useHistory();
  const createReferralResult = useSelector(
    (state) => state.referral.createReferral
  );
  const dispatch = useDispatch();

  const classes = useStyles();
  const { handleSubmit, control, reset } = useForm({
    defaultValues: initialValues,
  });

  const countriesToExclude = rawCountries.filter((country) => country[0] !== 'Israel' &&  country[1].includes('middle-east')).map((country) => country[2])

  const isCreateReferralSuccess = createReferralResult.isSuccess;

  React.useEffect(() => {
    if (isCreateReferralSuccess) {
      reset();
      dispatch(createReferralReset());
      history.push("/referrals");
    }
  }, [isCreateReferralSuccess, history, reset, dispatch]);

  const onSubmit = (data) => {
    dispatch(createReferral(token, data));
  };

  return (
    <Container>
      <TitleSection
        routes={[
          {
            name: t("common.texts.dashboard"),
            path: "/dashboard",
          },
          {
            name: t("referrals.title"),
            path: "/referrals",
          },
        ]}
        title={t("referrals.new.title")}
      />
      <Divider />
      <Box mt={4}>
        <Box mb={1}>
          <Typography variant="h3" component="h2">
            {t("referrals.new.heading")}
          </Typography>
        </Box>
        <Typography className={classes.mb4} variant="body2" style={{fontWeight: 400}}>
          {t("referrals.new.sub-text")}
        </Typography>
        <form className={classes.mb4AllChild} onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.smallField}>
            <FormField
              name="name"
              label={t("referrals.form.name.label")}
              placeholder={t("referrals.form.name.placeholder")}
              required
              control={control}
              controllerProps={{
                rules: {
                  required: true,
                },
              }}
            />
          </Box>
          <Box className={classes.smallField}>
            <FormField
              name="email"
              label={t("referrals.form.email.label")}
              type="email"
              placeholder={t("referrals.form.email.placeholder")}
              required
              control={control}
              controllerProps={{
                rules: {
                  required: true,
                },
              }}
            />
          </Box>
          <Box className={classes.smallField}>
            <Box mb={0.5}>
              <Typography variant="h5" className={classes.fieldLabel}>
                { t("referrals.form.phone.label") } *
              </Typography>
            </Box>
            <Box mb={0.5}>
              <Typography variant="body2" style={{fontWeight: 400}}>
                { t("referrals.form.phone.sub-text") }
              </Typography>
            </Box>
            <Controller
              render={({ onChange, onBlur, value }) => (
                <PhoneInput
                  country={"ch"}
                  preferredCountries={['ch','de','fr','it','li','at','es','pt', 'be', 'bg', 'mt', 'nl', 'dk', 'pl', 'ee', 'fi', 'ro', 'se', 'gr', 'sk', 'cz', 'lv', 'hu', 'lt', 'cy', 'lu', 'no', 'gb', 'ie', 'si','us']}
                  regions={["europe", "middle-east", "america"]}
                  excludeCountries={countriesToExclude}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  required
                />
              )}
              name="phone"
              control={control}
              controllerProps={{
                rules: {
                  required: true,
                },
              }}
            />
          </Box>
          <Box className={classes.smallField}>
            <Box mb={0.5}>
              <Typography variant="h5" className={classes.fieldLabel}>
                { t("referrals.form.language.label") } *
              </Typography>
            </Box>
            <Box mb={0.5}>
              <Typography variant="body2" style={{fontWeight: 400}}>
                { t("referrals.form.language.sub-text") }
              </Typography>
            </Box>
            <Controller
              as={
                <LanguageSelect
                  required
                />
              }
              name="language"
              control={control}
              controllerProps={{
                rules: {
                  required: true,
                },
              }}
              defaultValue="Switzerland"
            />
          </Box>
          <Box className={classes.smallField}>
            <FormField
              name="location_name"
              label={t("referrals.form.location_name.label")}
              placeholder={t("referrals.form.location_name.placeholder")}
              control={control}
            />
          </Box>
          <Box className={classes.smallField}>
            <FormField
              name="address"
              label={t("referrals.form.address.label")}
              placeholder={t("referrals.form.address.placeholder")}
              control={control}
            />
          </Box>
          <Box className={classes.smallField}>
            <Box mb={0.5}>
              <Typography variant="h5" className={classes.fieldLabel}>
                { t("referrals.form.contact.label") }
              </Typography>
            </Box>
            <Box mb={0.5}>
              <Typography variant="body2" style={{fontWeight: 400}}>
                { t("referrals.form.contact.sub-text") }
              </Typography>
            </Box>
            <Controller
              as={<ContactSelect />}
              name="contact"
              control={control}
              placeholder={t("referrals.form.contact.placeholder")}
            />
          </Box>
          <Box className={classes.largeField}>
            <FormField
              name="remarks"
              type="text"
              label={t("referrals.form.remarks.label")}
              placeholder={t("referrals.form.remarks.placeholder")}
              control={control}
              multiline
            />
          </Box>
          <Box className={classes.largeField}>
            <Box mb={2}>
              <Typography variant="body2">
                { t("referrals.form.info") }
              </Typography>
            </Box>
            <Box mb={2}>
              <Box display="flex" alignItems="center">
                <Checkbox
                  checked={accepted}
                  onChange={() => setAccepted(!accepted)}
                  color="primary"
                />
                <Typography variant="body2">{t('referrals.form.acceptance')}</Typography>
              </Box>
            </Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={createReferralResult.isLoading || !accepted}
              endIcon={
                createReferralResult.isLoading ? (
                  <CircularProgress size={20} />
                ) : undefined
              }
            >
              {t("referrals.form.submit")}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
}

export default NewReferral;
