import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center"
  },
  pageNumberCurrent: {
    color: "white",
    background: theme.palette.secondary.dark,
    border:  `0.125rem solid ${theme.palette.secondary.dark}`,
    height: "1.75rem",
    width: "1.75rem",
    borderRadius:"1.75rem",
    padding: "0.5px 0 0 0.25px !important",
    [theme.breakpoints.down("sm")]: {
      height: "2.25rem",
      width: "2.25rem",
      borderRadius:"2.25rem",
      padding: "2.25px 0 0 0.25px !important",
    }
  },
  pageNumber: {
    color: theme.palette.secondary.main,
    border:  `0.125rem solid ${theme.palette.secondary.main}`,
    height: "1.75rem",
    width: "1.75rem",
    borderRadius:"1.75rem",
    cursor: "pointer",
    padding: "0.5px 0 0 0.25px !important",
    [theme.breakpoints.down("sm")]: {
      height: "2.25rem",
      width: "2.25rem",
      borderRadius:"2.25rem",
      padding: "2.25px 0 0 0.25px !important",
    }
  },
  pageNumberText: {
    textAlign: "center",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0.125rem",
      fontSize: "1.125rem",
    }
  },
  pageNumberTextCurrent: {
    textAlign: "center",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0.125rem",
      fontSize: "1.125rem",
    }
  },
  arrow: {
    fontSize: "1rem",
    marginTop: "0.4rem"
  },
  clickable: {
    cursor: "pointer"
  }
}));

const SearchPagination = ({ pagy, epic, fetchPriceFilters, options, priceRangeFilter, location, listRef, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let isSmallMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobile));
  const handleRequest = (url) => {
    fetchPriceFilters(options, location);
    epic(options, location, priceRangeFilter, url);
    if (listRef && listRef.current) {
      listRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
  if (pagy && (!pagy.first_url || !pagy.prev_url || !pagy.page_url || !pagy.next_url || !pagy.last_url)) {
    if (!pagy.first_url){
      pagy.first_url = "/users/parking_spots/search_ids?page=1";
    }
    if (!pagy.prev_url){
      pagy.prev_url = (Number(pagy.page) - 1 > 0) ? "/users/parking_spots/search_ids?page=" + (Number(pagy.page) - 1) : "/users/parking_spots/search_ids?page=";
    }
    if (!pagy.page_url){
      pagy.page_url = "/users/parking_spots/search_ids?page=" + pagy.page;
    }
    if (!pagy.next_url){
      pagy.next_url = (Number(pagy.page) + 1 <= pagy.last) ?  "/users/parking_spots/search_ids?page=" + (Number(pagy.page) + 1) : "/users/parking_spots/search_ids?page=";
    }
    if (!pagy.last_url){
      pagy.last_url = "/users/parking_spots/search_ids?page=" + pagy.last;
    }
  }

  return pagy ? (
    <Box className={classes.container} display="flex" flexDirection="column" alignItems='center' justifyContent="center">
      <Box display="flex" alignItems="center">
        <Box onClick={() => (pagy.prev_url.split("=")[1].length > 0) ? handleRequest(pagy.prev_url) : null} className={(pagy.prev_url.split("=")[1].length > 0) ? classes.clickable : ""} mx={0.5}>
          <ArrowBackIosIcon className={classes.arrow} />
        </Box>
        <Box onClick={() => handleRequest(pagy.first_url)} display={(pagy.first_url.split("=")[1] === pagy.prev_url.split("=")[1] || pagy.first_url.split("=")[1] === pagy.page_url.split("=")[1]) ? "none" : "initial"} className={classes.pageNumber} mx={isMobile ? 0.5 : 1}>
          <Typography className={classes.pageNumberText}>
            {pagy.first_url.split("=")[1]}
          </Typography>
        </Box>
        <Box display={(pagy.first_url.split("=")[1] === pagy.prev_url.split("=")[1] || pagy.first_url.split("=")[1] === pagy.page_url.split("=")[1]) ? "none" : isSmallMobile ? "none" : "initial"} variant="body2" mx={isMobile ? 0.875 : 1} mt={isMobile ? -1.25 : -1}>
          <Typography>
            . . .
          </Typography>
        </Box>
        <Box display={(pagy.first_url.split("=")[1] === pagy.prev_url.split("=")[1] || pagy.first_url.split("=")[1] === pagy.page_url.split("=")[1]) ? "none" : isSmallMobile ? "initial" : "none"} variant="body2" mx={isMobile ? 0.875 : 1} mt={isMobile ? -1.25 : -1}>
          <Typography>
            ..
          </Typography>
        </Box>
        <Box onClick={() => handleRequest(pagy.prev_url)} display={(pagy.prev_url.split("=")[1].length > 0) ? "initial" : "none"} className={classes.pageNumber} mx={isMobile ? 0.5 : 1}>
          <Typography className={classes.pageNumberText}>
            {pagy.prev_url.split("=")[1]}
          </Typography>
        </Box>
        <Box className={classes.pageNumberCurrent} mx={isMobile ? 0.5 : 1}>
          <Typography className={classes.pageNumberTextCurrent}>
            {pagy.page_url.split("=")[1]}
          </Typography>
        </Box>
        <Box onClick={() => handleRequest(pagy.next_url)} display={(pagy.next_url.split("=")[1].length > 0) ? "initial" : "none"} className={classes.pageNumber} mx={isMobile ? 0.5 : 1}>
          <Typography className={classes.pageNumberText}>
            {pagy.next_url.split("=")[1]}
          </Typography>
        </Box>
        <Box display={(pagy.last_url.split("=")[1] === pagy.next_url.split("=")[1] || pagy.last_url.split("=")[1] === pagy.page_url.split("=")[1]) ? "none" : isSmallMobile ? "none" :  "initial"} variant="body2" mx={isMobile ? 0.875 : 1} mt={isMobile ? -1.25 : -1}>
          <Typography>
            . . .
          </Typography>
        </Box>
        <Box display={(pagy.last_url.split("=")[1] === pagy.next_url.split("=")[1] || pagy.last_url.split("=")[1] === pagy.page_url.split("=")[1]) ? "none" : isSmallMobile ? "initial" :  "none"} variant="body2" mx={isMobile ? 0.875 : 1} mt={isMobile ? -1.25 : -1}>
          <Typography>
            ..
          </Typography>
        </Box>
        <Box onClick={() => handleRequest(pagy.last_url)} display={(pagy.last_url.split("=")[1] === pagy.next_url.split("=")[1] || pagy.last_url.split("=")[1] === pagy.page_url.split("=")[1]) ? "none" : "initial"} className={classes.pageNumber} mx={isMobile ? 0.5 : 1}>
          <Typography className={classes.pageNumberText}>
            {pagy.last_url.split("=")[1]}
          </Typography>
        </Box>
        <Box onClick={() => (pagy.next_url.split("=")[1].length > 0) ? handleRequest(pagy.next_url) : null} className={(pagy.next_url.split("=")[1].length > 0) ? classes.clickable : ""} mx={1}>
          <ArrowForwardIosIcon className={classes.arrow} />
        </Box>
      </Box>
      <Box mt={isMobile ? 1.75 : 1}>
        <Typography variant="h6">
          {pagy.from}-{pagy.to} of {pagy.count}
        </Typography>
      </Box>
    </Box>
  ) : null;
};

export default SearchPagination;
