import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { fetchAuthByRefreshToken } from '../data/actions/auth';
import Header from '../components/common/header';
import Footer from '../components/common/footer';
import { addLoaderForItem } from '../data/actions/config';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import {pushToRoot} from "../data/actions/parkingSpot";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  main: {
    minHeight: "70vh",
    padding: "4rem 0rem",
  },
  noPadding: {
    minHeight: "70vh",
    padding: "0",
  },
  mobile: {
    minHeight: "70vh",
    padding: "3rem 0rem",
  },
  rootAlt: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "100vh",
  },
  mainAlt: {
    flex: "auto",
    padding: "0rem",
  },
}));

const DashboardLayout = ({ spacingTopAndBottom, children,  ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (spacingTopAndBottom === true) ? (!isMobile) ?
  (
    <div className={classes.root}>
      <Header />
      <main className={ classes.main }>
        { children }
      </main>
      <Footer />
    </div>
  ) :
  (
    <div className={classes.root}>
      <Header />
      <main className={ classes.mobile }>
        { children }
      </main>
      <Footer />
    </div>
  )
  : (spacingTopAndBottom === "search") ?
  (
    <div className={classes.rootAlt}>
      <Header />
      <main className={classes.mainAlt}>
        { children }
      </main>
    </div>
  )
  :
  (
    <div className={classes.root}>
      <Header />
      <main className={ classes.noPadding }>
        { children }
      </main>
      <Footer />
    </div>
  );
};

const isAuthenticated = ({ auth, fetchAuthByRefreshToken, addLoaderForItem, origin, isLoggingOut }) => {
  const expiry = moment(parseInt(auth.expiry)*1000).diff(moment(), "minutes");
  if (auth.token && auth.expiry && expiry > 5) {
    return true;
  }
  if (!isLoggingOut){
    addLoaderForItem("token");
    fetchAuthByRefreshToken(origin, "dashboard");
  }
  return false;
};

const DashboardLayoutRoute = ({page: Component, auth, fetchAuthByRefreshToken, addLoaderForItem, isLoggingOut, userType, pushToRoot, ...rest}) => {
  return <Route {...rest} render={(props) => {
    const urlParts = rest.location.pathname.split("/");
    const urlParam = urlParts[urlParts.length - 1];
    const urlResource = urlParts[urlParts.length - 2];

    if (urlParts[1] === "ps" && urlParts[2] === "new" && userType === "SubHost") {
      pushToRoot();
      return null;
    }

    return isAuthenticated({ auth, fetchAuthByRefreshToken, addLoaderForItem, origin: `${rest.location.pathname}${rest.location.search}`, isLoggingOut })
    ? ((
        urlResource && (urlResource === "ps" && urlParam && !Number.isNaN(Number(urlParam)))) ||
        urlParam === 'search'
      )
      ? (urlParam === 'search') ?
        (
          <DashboardLayout spacingTopAndBottom="search">
            <Component {...props} />
          </DashboardLayout>
        )
        :
        (
          <DashboardLayout spacingTopAndBottom={false}>
            <Component {...props} />
          </DashboardLayout>
        )
      :
      (
        <DashboardLayout spacingTopAndBottom={true}>
          <Component {...props} />
        </DashboardLayout>
      )
    : null
  }} />
}

const mapStateToProps = (state) => {
  return { auth: state.auth, isLoggingOut: state.user.loggingOutUser, userType: state.user.userType };
}

export default connect(mapStateToProps, { fetchAuthByRefreshToken, addLoaderForItem, pushToRoot })(DashboardLayoutRoute);
