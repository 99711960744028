import React, { useState } from 'react';
import { useStyles } from '../common/styles';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { connect } from 'react-redux';
import { setCarouselState, addCarouselState } from '../../../data/actions/parkingSpot';
import { Box, Typography, Button, useTheme, Dialog, DialogTitle, DialogContent, DialogContentText, useMediaQuery } from '@material-ui/core'
import {ReactComponent as ShowAllIcon} from "../../../assets/images/leading-icon.svg";
import {ReactComponent as ChevronLeft} from "../../../assets/images/chevron-left.svg";
import {ReactComponent as ChevronRight} from "../../../assets/images/chevron-right.svg";
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import AnimatedBg from "react-animated-bg";
import clsx from 'clsx';

const Carousel = ({ items, label, wrapper=false, carouselState, setCarouselState, addCarouselState, showControls, loading, galleryDialogProps = {} }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = React.useState(1);
  const carousel = React.useRef(null);
  const theme = useTheme();
  const { t } = useTranslation();

  const handleGalleryBack = (event) => {
    event.preventDefault();
    carousel.current.slidePrev();
  }

  const handleGalleryForward = (event) => {
    event.preventDefault();
    carousel.current.slideNext();
  }
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return !wrapper ? (
    <React.Fragment>
      <AliceCarousel
        disableButtonsControls
        disableDotsControls
        touchTracking
        infinite
        items={items}
        ref={carousel}
        onInitialized={(event) => {addCarouselState(label, 1)}}
        onSlideChanged={(event) => {setCarouselState(label, event.item + 1)}}
      />
      {
        carousel.current && (
          <Box className={classes.galleryCounter}>
            <Typography variant="body1" style={{color: "white"}}>
              {carouselState[`${label}`]}/{items.length}
            </Typography>
          </Box>
        )
      }
      <ArrowBackIos className={classes.galleryButtonBack} onClick={event => handleGalleryBack(event)} />
      <ArrowForwardIos className={classes.galleryButtonForward} onClick={event => handleGalleryForward(event)} />
    </React.Fragment>
  ) : (
    <>
      <Box className={showControls ? classes.controlledImageWrapper : classes.parkingSpotImageWrapper} position="relative" width="100%">
      { loading ?
        <div className={classes.galleryCols}>
          <AnimatedBg colors={["#E7F0F0", "#d5d5d5"]} duration={1.0} delay={0.1} timingFunction="linear" className={classes.photoPlaceholder}>
            <div />
          </AnimatedBg>
        </div>
      : (
        <>
          {isMobile ? (
            (items && items.length > 1) ? (
              <AliceCarousel
                touchMoveDefaultEvents={false}
                disableButtonsControls
                disableDotsControls
                touchTracking
                infinite
                items={items}
                ref={carousel}
                onInitialized={(event) => {setCurrentSlide(1)}}
                onSlideChanged={(event) => {setCurrentSlide(event.item + 1)}}
              />
            ) : (
              (items && items.length > 0) ? items[0] : null
            )
          ) : (
            (items && items.length < 5) ? (
              items && items.length < 3 ? (
                (items && items.length > 0) ? (
                  <div className={classes.galleryCols}>
                    <div className={classes.galleryCol}>{items[0]}</div>
                  </div>
                ) : null
              ) : (
                (items && items.length > 0) ? (
                  <div className={classes.galleryCols}>
                    <div className={`${classes.galleryCol} ${classes.galleryColMedium}`}>{items[0]}</div>
                    <div className={`${classes.galleryCol} ${classes.galleryColMedium}`}>{items[1]}</div>
                    <div className={`${classes.galleryCol} ${classes.galleryColMedium}`}>{items[2]}</div>
                  </div>
                ) : null
              )
            ) : (
              (items && items.length > 0) ? (
                <div className={classes.galleryCols}>
                  <div className={`${classes.galleryCol} ${classes.galleryColMedium} ${classes.galleryColCompact}`}>{items[0]}</div>
                  <div className={`${classes.galleryCol} ${classes.galleryColSmall}`}>{items[1]}</div>
                  <div className={`${classes.galleryCol} ${classes.galleryColSmall}`}>{items[2]}</div>
                  <div className={`${classes.galleryCol} ${classes.galleryColSmall} ${classes.galleryColCompact}`}>{items[3]}</div>
                  <div className={`${classes.galleryCol} ${classes.galleryColSmall} ${classes.galleryColCompact}`}>{items[4]}</div>
                </div>
              ) : null
            )
          )}
          {
            carousel.current && (
              <Box className={classes.galleryCounter}>
                <Typography variant="body1" style={{color: "white"}}>
                  {currentSlide}/{items.length}
                </Typography>
              </Box>
            )
          }
          {
            items && items.length > 1 && (
              <Box className={classes.galleryControlsDesktop}>
                <Button variant="outlined" className={classes.expandButton} onClick={() => {
                  setOpen(true);
                }} >
                  <ShowAllIcon fill={theme.palette.primary.main}/>
                </Button>
              </Box>
            )
          }
        </>
      )}
      </Box>

      {/* Gallery dialog */}
      <Dialog {...galleryDialogProps} open={open} onClose={
        () => setOpen(false)
      } aria-labelledby="gallery-dialog-title" >
        <Box>
          <DialogTitle
            id="gallery-dialog-title"
            className={clsx(classes.dialogTitleBorderBottom, {
              [classes.dialogTitleFullScreen]: !!galleryDialogProps.fullScreen
            })}
            classes={{
              root: classes.dialogTitleRoot,
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h2">{t("parking-spot.gallery")}</Typography>
              <Button variant="outlined" size="small" onClick={() => {setOpen(false)}}>
                <CloseIcon flex="auto" color="primary" />
              </Button>
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogImages}>
            { items.map(img => img)}
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    carouselState: state.parkingSpot.carouselState,
    loading: state.config.loadingPhotos,
  }
};

export default connect(mapStateToProps, { setCarouselState, addCarouselState })(Carousel);
