import React from "react";
import InputLabel from "components/common/inputLabel/InputLabel";
import { MenuItem, Select, makeStyles } from "@material-ui/core";
import { ReactComponent as DropdownArrow } from "assets/images/dropdown-arrow.svg";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  placeholder: {
    color: theme.palette.text.disabled,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
  },
}));

function ContactSelect({ label, required, placeholder, ...selectProps }, ref) {
  const { t } = useTranslation();
  const classes = useStyles();

  const options = React.useMemo(
    () => [
      {
        value: "phone",
        label: t("referrals.form.contact.options.phone"),
      },
      {
        value: "email",
        label: t("referrals.form.contact.options.email"),
      },
      {
        value: "on-the-spot",
        label: t("referrals.form.contact.options.on-the-spot"),
      },
    ],
    [t]
  );

  return (
    <div>
      { label &&
        <InputLabel bottomGutter required={required} id="Referral-ContactSelect">
          {label}
        </InputLabel>
      }
      <Select
        ref={ref}
        IconComponent={(props) => <DropdownArrow {...props} />}
        variant="outlined"
        labelId="Referral-ContactSelect"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              // ! .MuiPopover-paper have margin-top property
              marginTop: 0,
            },
          },
        }}
        renderValue={(value) => {
          if (!value) {
            return <span className={classes.placeholder}>{placeholder}</span>;
          }
          const option = options.find((option) => option.value === value);
          return option ? option.label : value;
        }}
        fullWidth
        required={required}
        displayEmpty
        {...selectProps}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default React.forwardRef(ContactSelect);
