import React from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeSnackbar } from '../../../data/actions/global';

let displayed = [];

const Notifier = ({ notifications, removeSnackbar }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // Dismiss snackbar
        closeSnackbar(key);
        return;
      }

      // Return if snackbar is displayed
      if (displayed.includes(key)) return;

      // Display snackbar
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // Remove snackbar from store
          removeSnackbar(myKey);
          removeDisplayed(myKey);
        },
      });

      // Track displayed snackbars
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, removeSnackbar, enqueueSnackbar]);

  return null;
};

const mapStateToProps = (state) => {
  return {
    notifications: state.global.notifications,
  };
};

export default connect(mapStateToProps, { removeSnackbar })(Notifier);
