import React, { useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import { Divider, Container, Typography, Box, Button, CircularProgress, MenuItem, Select, InputLabel, useTheme, useMediaQuery } from '@material-ui/core';
import UserPhotoIcon from '@material-ui/icons/Person';
import PhoneInput from 'react-phone-input-2';
import rawCountries from "rawCountries";
import 'react-phone-input-2/lib/material.css';
import FormField from '../../common/formFieldWithSeparateLabel';
import TextField from '@material-ui/core/TextField';
import { useStyles } from '../common/styles';
import { PhotoFormSection } from '../../common/imageUploader';
import TitleSection from '../../common/titleSection';
import { useTranslation } from 'react-i18next';
import { updateHost, updateGuest, postProfilePic, setUpdatingProfilePic, fetchHostProfile, fetchGuestProfile, setUpdatingUser, updateSubHostProfile } from '../../../data/actions/user';
import {ReactComponent as DropdownArrow} from "../../../assets/images/dropdown-arrow.svg";
import { enqueueSnackbar } from '../../../data/actions/global';
import { ListItemIcon } from "@material-ui/core";
import flags from "../../common/flags";

const initialValues = (values) => {
  return {
    name: values.name || "",
    surname: values.surname || "",
    email: values.email || "",
    phone: values.phone || "",
    dob: values.dob || "",
    bio: values.bio || "",
    accommodationId: values.accommodationId || "",
    correspondenceLanguage: values.correspondenceLanguage || "De",
    companyName: values.companyName || "",
    companyStreet: values.companyStreet || "",
    companyStreetNumber: values.companyStreetNumber || "",
    companyZip: values.companyZip || "",
    companyCity: values.companyCity || "",
    companyCountry: values.companyCountry || "",
  };
};

const Profile = ({ user, updateHost, updateGuest, token, postProfilePic, updatingProfilePic, setUpdatingProfilePic, fetchHostProfile, fetchGuestProfile, setUpdatingUser, updatingUser, updateSubHostProfile, enqueueSnackbar }) => {
  const classes = useStyles();
  const { userType, photo, name, surname, email, phone, hostDetails, address, correspondenceLanguage } = user;

  const defaultValues = initialValues({
    name,
    surname,
    email,
    phone,
    correspondenceLanguage,
    dob: hostDetails && hostDetails.dob,
    bio: hostDetails && hostDetails.bio,
    accommodationId: hostDetails && hostDetails.accommodation_id,
    companyName: hostDetails && hostDetails.company_name,
    companyStreet: address && address.line1,
    companyStreetNumber: address && address.street_number,
    companyZip: address && address.zip,
    companyCity: address && address.city,
    companyCountry: address && address.country,
  });

  const { handleSubmit, control, reset, setValue, getValues, watch } = useForm({ defaultValues });
  const { t } = useTranslation();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  let isXs = useMediaQuery(theme.breakpoints.down("xs"));
  let isSmallMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobile));

  const countriesToExclude = rawCountries.filter((country) => country[0] !== 'Israel' &&  country[1].includes('middle-east')).map((country) => country[2])

  useEffect(() => {
    if (userType === "Guest") {
      fetchGuestProfile(token);
    } else if (userType === "Host") {
      fetchHostProfile(token);
    }
  }, [userType, fetchGuestProfile, fetchHostProfile, token]);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [reset, name, surname, email, phone, hostDetails, address]);

  const onSubmit = (data) => {
    if (!data.phone || data.phone === "") {
      if (userType === "Host" || userType === "Guest") {
        enqueueSnackbar({
          message: {
            text: t("on-boarding.errors.phone-required"),
            variant: "error",
          },
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        });
        return false;
      }
    }
    setUpdatingUser(true);
    userType === "Host" ? updateHost({
      ...data,
      hostDetailId: user.hostDetails.id,
    }, token, null, "/dashboard")
    : userType === "Guest" ? updateGuest({
      ...data,
      guestDetailId: user.guestDetails.id,
    }, token, null, "/dashboard")
    :
    userType === "SubHost" && updateSubHostProfile( token, {
      ...data,
      id: user.id,
    });
  }

  return (
    <Container>
      <TitleSection
        routes={[{
          name: t("common.texts.dashboard"),
          path: "/dashboard",
        }]}
        title={ t("profile.title") }
      />
      <Divider />
      <Box className={classes.profileWrapper}>
        { (userType === "Host" || userType === "Guest") && (
          <Box display="flex" pt={8} flexDirection="column" className={classes.uploadWrapper}>
            <Box>
              <Typography variant="h3">{ t("profile.your-photo") }</Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems={isXs ? "center" : "flex-start"} width={isXs ? "initial" : "200px"}>
              <PhotoFormSection
                uploadPath="user[profile_pic]"
                previewPhotos={photo && [{
                  url: photo,
                }]}
                postPhotoAction={(photo) => postProfilePic(photo, token)}
                loading={updatingProfilePic}
                setLoader={setUpdatingProfilePic}
                placeholderIcon={UserPhotoIcon}
                isCustomPlaceholder={true}
              />
            </Box>
          </Box>
        )}
        <Box display="flex" pt={ (userType === "Host" || userType === "Guest") ? 8 : 5} flexDirection="column" className={classes.itemsWrapper}>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.root} autoComplete="off">
            <Box>
              <Typography variant="h3">{ t("profile.component-heading") }</Typography>
            </Box>
            <FormField type="text" label={ t("common.labels.name") } name="name" control={control} required />
            <FormField type="text" label={ t("common.labels.surname") } name="surname" control={control} required />
            {
              userType === "Host" && (
                <FormField type="text" label={ t("common.labels.company-name") } name="companyName" control={control} />
              )
            }
            {
              userType === "Host" && (
                <FormField type="text" label={ t('on-boarding.host.accommodation-id') } name="accommodationId" control={control} />
              )
            }
            <FormField label={ t("common.labels.email") } type="email" name="email" control={control} required disabled={userType === "Host" || userType === "SubHost"} />
            { (userType === "Host" || userType === "Guest") && (
              <Box mt={3}>
                <Typography variant="h5" style={{fontWeight: "500"}}>{ t("common.labels.phone") } *</Typography>
                <Controller
                  as={
                    <PhoneInput
                      country={'ch'}
                      preferredCountries={['ch','de','fr','it','li','at','es','pt', 'be', 'bg', 'mt', 'nl', 'dk', 'pl', 'ee', 'fi', 'ro', 'se', 'gr', 'sk', 'cz', 'lv', 'hu', 'lt', 'cy', 'lu', 'no', 'gb', 'ie', 'si', 'au', 'us']}
                      regions={["europe", "middle-east", "oceania", 'america']}
                      excludeCountries={countriesToExclude}
                      containerClass={classes.phoneContainer}
                      inputClass={classes.phoneInput}
                      buttonClass={classes.phoneButton}
                      dropdownClass={classes.phoneDropdown}
                    />
                  }
                  name="phone"
                  control={control}
                />
              </Box>
            )}
            {user.userType === 'Host' && 
            <Box display="flex" flexDirection="column" pt={2}>
              <Typography variant="h4">{ t('profile.language') }</Typography>
              <Box mt={1.5}>
                <Controller
                  as={
                    <Select
                      className={classes.languageSelect}
                      IconComponent={(props) => (<DropdownArrow {...props}/>)}
                      variant= "outlined"
                      required
                    >
                      <MenuItem value="de">
                        <Box display="flex" alignItems="center">
                          <ListItemIcon className={classes.flagIcon}>
                            {flags("de")}
                          </ListItemIcon>
                          <span>DE</span>
                        </Box>
                      </MenuItem>
                      <MenuItem value="en">
                        <Box display="flex" alignItems="center">
                          <ListItemIcon className={classes.flagIcon}>
                            {flags("en")}
                          </ListItemIcon>
                          <span>EN</span>
                        </Box>
                      </MenuItem>
                      <MenuItem value="fr">
                        <Box display="flex" alignItems="center">
                          <ListItemIcon className={classes.flagIcon}>
                            {flags("fr")}
                          </ListItemIcon>
                          <span>FR</span>
                        </Box>
                      </MenuItem>
                      <MenuItem value="es">
                        <Box display="flex" alignItems="center">
                          <ListItemIcon className={classes.flagIcon}>
                            {flags("es")}
                          </ListItemIcon>
                          <span>ES</span>
                        </Box>
                      </MenuItem>
                      <MenuItem value="it">
                        <Box display="flex" alignItems="center">
                          <ListItemIcon className={classes.flagIcon}>
                            {flags("it")}
                          </ListItemIcon>
                          <span>IT</span>
                        </Box>
                      </MenuItem>
                    </Select>
                  }
                  name="correspondenceLanguage"
                  control={control}
                />
              </Box>
            </Box>
          }
            { userType === "Host" && (
                <FormField label={ t("profile.dob") } type="date" name="dob" id="date" InputLabelProps={{ shrink: true }} control={control} required />
            )}
            { userType === "Host" && (
              <FormField type="text" label={ t("profile.biography") } name="bio" control={control} multiline getValues={getValues} watch={watch} />
            )}
            { userType === "Host" && (
              <Box display="flex" mt={6} flexDirection="column" className={classes.itemsWrapper}>
                <Box>
                  <Typography variant="h3">{ t("profile.address-info") }</Typography>
                </Box>
                <Box className={classes.fieldRow}>
                  <Box flex={2}>
                    <FormField type="text" label={ t("address.street") } name="companyStreet" control={control} required />
                  </Box>
                  <Box flex={1} ml={ isMobile ? 0 : 2}>
                    <FormField label={ t("address.street-number") } name="companyStreetNumber" control={control} required />
                  </Box>
                  <Box flex={1} ml={ isMobile ? 0 : 2}>
                    <FormField label={ t("address.zip") } name="companyZip" control={control} required />
                  </Box>
                </Box>
                <Box className={classes.fieldRow}>
                  <Box flex={1}>
                    <FormField type="text" label={ t("address.city") } name="companyCity" control={control} required />
                  </Box>
                  <Box flex={1} marginTop="1.6rem" ml={ isMobile ? 0 : 2}>
                    <Typography variant="h5" style={{fontWeight: "500"}}>{ t('address.country') } </Typography>
                    <Controller
                      as={
                        <Select
                          className={classes.countrySelect}
                          IconComponent={(props) => (<DropdownArrow {...props}/>)}
                          variant= "outlined"
                          required
                        >
                          <MenuItem value="Switzerland">{t("countries.switzerland")}</MenuItem>
                          <MenuItem value="Germany">{t("countries.germany")}</MenuItem>
                          <MenuItem value="Austria">{t("countries.austria")}</MenuItem>
                          <MenuItem value="Australia">{t("countries.australia")}</MenuItem>
                          <MenuItem value="France">{t("countries.france")}</MenuItem>
                          <MenuItem value="Italy">{t("countries.italy")}</MenuItem>
                          <MenuItem value="Liechtenstein">{t("countries.liechtenstein")}</MenuItem>
                          <MenuItem value="Belgium">{t("countries.belgium")}</MenuItem>
                          <MenuItem value="Malta">{t("countries.malta")}</MenuItem>
                          <MenuItem value="Bulgaria">{t("countries.bulgaria")}</MenuItem>
                          <MenuItem value="Netherlands">{t("countries.netherlands")}</MenuItem>
                          <MenuItem value="Denmark">{t("countries.denmark")}</MenuItem>
                          <MenuItem value="Poland">{t("countries.poland")}</MenuItem>
                          <MenuItem value="Estonia">{t("countries.estonia")}</MenuItem>
                          <MenuItem value="Portugal">{t("countries.portugal")}</MenuItem>
                          <MenuItem value="Finland">{t("countries.finland")}</MenuItem>
                          <MenuItem value="Romania">{t("countries.romania")}</MenuItem>
                          <MenuItem value="Sweden">{t("countries.sweden")}</MenuItem>
                          <MenuItem value="Greece">{t("countries.greece")}</MenuItem>
                          <MenuItem value="Slovakia">{t("countries.slovakia")}</MenuItem>
                          <MenuItem value="Ireland">{t("countries.ireland")}</MenuItem>
                          <MenuItem value="Slovenia">{t("countries.slovenia")}</MenuItem>
                          <MenuItem value="Spain">{t("countries.spain",)}</MenuItem>
                          <MenuItem value="Czech Republic">{t("countries.czechrepublic")}</MenuItem>
                          <MenuItem value="Latvia">{t("countries.latvia")}</MenuItem>
                          <MenuItem value="Hungary">{t("countries.hungary")}</MenuItem>
                          <MenuItem value="Lithuania">{t("countries.lithuania")}</MenuItem>
                          <MenuItem value="Cyprus">{t("countries.cyprus")}</MenuItem>
                          <MenuItem value="Luxembourg">{t("countries.luxembourg")}</MenuItem>
                          <MenuItem value="United Kingdom">{t("countries.unitedkingdom")}</MenuItem>
                          <MenuItem value="Norway">{t("countries.norway")}</MenuItem>
                          <MenuItem value="United States">{t("countries.unitedstates")}</MenuItem>
                        </Select>
                      }
                      name="companyCountry"
                      control={control}
                      defaultValue="Switzerland"
                      disabled={!!(userType==="Host" && user.verified)}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            <Box pt={3} display="flex">
              <Box className={classes.buttonWrapper}>
                <Button variant="contained" color="primary" fullWidth={isMobile} type="submit" className={classes.primaryButton} disabled={updatingUser}>
                  { t("common.btn.save-changes") }
                </Button>
                { updatingUser && <CircularProgress size={24} className={classes.buttonProgress} /> }
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.token,
    updatingProfilePic: state.user.updatingProfilePic,
    updatingUser: state.user.updatingUser
  };
};

export default connect(mapStateToProps, { updateHost, updateGuest, postProfilePic, setUpdatingProfilePic, fetchHostProfile, fetchGuestProfile, setUpdatingUser, updateSubHostProfile, enqueueSnackbar })(Profile);
