import React, { useState, useEffect } from 'react';
import { Box, Button, useTheme, useMediaQuery, Typography, SvgIcon} from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';
import { ReactComponent as MapPinSVG } from '../../../assets/images/map-pin.svg';
import { useStyles } from '../common/styles';
import clsx from 'clsx';
import { setGeolocationEditParkingSpot, setAddressEditParkingSpot } from '../../../data/actions/parkingSpot';
import { connect } from 'react-redux'

const MapPin = ({lat, lng, color, ...rest}) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return(
    <Box display="none">
      <MapPinSVG className={clsx(classes.greenPin, classes.pin)} />
    </Box>
  )
};

const mapStateToProps = (state) => {
  return {
    editParkingSpot: state.parkingSpot.editParkingSpot,
  }
};

const GeoMap = connect(mapStateToProps, { setGeolocationEditParkingSpot, setAddressEditParkingSpot } )(
  ({geo, setGeolocationEditParkingSpot, setAddressEditParkingSpot, editParkingSpot, ...rest}) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let lat = 0;
  let lng = 0;
  if (geo != undefined){
    lat = parseFloat(geo.substring(geo.lastIndexOf(" ") + 1, geo.lastIndexOf(")")));
    lng = parseFloat(geo.substring(geo.indexOf("(") + 1, geo.lastIndexOf(" ")));
  }
  if (isNaN(lat)){
    lat=0;
    lng=0;
  }
  const mapCenter = {
    lat,
    lng,
  };
  const [zoom, setZoom] = useState(13);

  const handleChange = ({ center, zoom, lat_current, lng_current }) => {
    if (
      center.lat() !== mapCenter.lat  ||
      center.lng() !== mapCenter.lng
    ) {
      setGeolocationEditParkingSpot(`POINT(${center.lng()} ${center.lat()})`);
    }
    setZoom(zoom);
  };

  const [ mapApi, setMapApi ] = useState(null);
  const [ address, setAddress ] = useState([]);
  const [ prevCenter, setPrevCenter ] = useState(null);
  const [ prevAddress, setPrevAddress ] = useState(null);
  const handleGenerateAddress = () => {
    if (mapApi && mapApi !== null){
      const geocoder = new mapApi.Geocoder();
      geocoder.geocode({ 'location': { lat: mapCenter.lat, lng: mapCenter.lng } }, (results, status) => {
        if (status === 'OK') {
            if (results[0]) {
                setPrevAddress(address);
                setAddress(results[0]);
                setPrevCenter(mapCenter);
            } else {
                console.log('No results found');
            }
        } else {
            console.log('Geocoder failed due to: ' + status);
        }
      });
    }
  }

  useEffect(() => {
    if (mapCenter != undefined && mapApi !== null && prevCenter !== mapCenter){
      handleGenerateAddress();
    }
    if (!isNaN(mapCenter.lng) && !isNaN(mapCenter.lat)){
      setGeolocationEditParkingSpot(`POINT(${mapCenter.lng} ${mapCenter.lat})`);
    }
    if (mapCenter != undefined && editParkingSpot.address && editParkingSpot.address.length < 1 && editParkingSpot.geolocation !== null ){
      handleGenerateAddress();
      setAddressEditParkingSpot(address);
    }
  }, [geo]);

  useEffect(() => {
    if (editParkingSpot.address !== address){
      setAddressEditParkingSpot(address);
    }
  }, [editParkingSpot, address]);

  const mapStyles = [
    {
      "featureType": "landscape.natural",
      "elementType": "labels.icon",
      "stylers": [
          {
            "visibility": "off"
          }
      ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#e9e9e9"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#deebd8"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#c4e5f3"
            },
            {
                "visibility": "on"
            }
        ]
    }
  ];

  return (
    <GoogleMapReact
      center={mapCenter}
      zoom={zoom}
      onDragEnd={handleChange}
      draggable={true}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        setMapApi(maps);
      }}
      onTilesLoaded={() => {
        if (!prevAddress || (prevAddress.length < 1 && prevAddress.formatted_address)){
          handleGenerateAddress();
          setAddressEditParkingSpot(address);
        }
      }}
      options={{styles: mapStyles, disableDefaultUI: true}}
    >
      <MapPin
        lat={lat}
        lng={lng}
      />
    </GoogleMapReact>
  );
}
);

const FormMap = ({geolocation, editParkingSpot, ...rest}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  return(
    <Box display="flex" className={classes.mapContainerForm}>
      <Box className={classes.mapBoxDetails} position="relative">
        <Box width='100%' height={isDesktop ? '19rem' : '24rem'}>
          <GeoMap geo={geolocation} />
        </Box>
        <Box position="absolute" top="50%" left="50%">
          <RoomRoundedIcon className={clsx(classes.greenPin, classes.pin)} />
        </Box>
      </Box>
    </Box>
  )
};

export default connect(mapStateToProps, { setGeolocationEditParkingSpot, setAddressEditParkingSpot } )( FormMap );
