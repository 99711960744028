import React, { useEffect } from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import { fetchAuthByRefreshToken } from '../data/actions/auth';
import logo from '../assets/images/logo-white.svg';
import background from "../assets/images/camping-cta.png";
import { addLoaderForItem } from '../data/actions/config';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  title: {
    width: "9.5rem",
    paddingBottom: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "12.0rem",
      paddingBottom: "1.75rem",
    }
  },
  titleLogo: {
    width: "100%",
  },
  cardContainer: {
    paddingTop: "3.5rem !important",
    paddingBottom: "3.5rem !important",
    maxWidth: "32rem",
    width: "32rem",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  fullHeightContainer: {
    backgroundColor: "#151a3c",
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: "fixed",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    zIndex: -1
  }
}));

const LoggedInOnboardingLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return !isMobile ? (
    <React.Fragment>
      <Box className={classes.fullHeightContainer}></Box>
      <Box pb={2}>
        <Container maxWidth="sm" className={classes.cardContainer}>
          <Box flexGrow="1" display="flex" justifyContent="center">
            <Box className={classes.title} component={Link} to="/">
              <img src={logo} alt="logo" className={classes.titleLogo} />
            </Box>
          </Box>
          { children }
        </Container>
      </Box>
    </React.Fragment>
  )
  :
  (
    <React.Fragment>
      <Box className={classes.fullHeightContainer}></Box>
      <Box pb={2}>
        <Box display="flex" justifyContent="center" pt={4}>
          <Box className={classes.title} component={Link} to="/">
            <img src={logo} alt="logo" className={classes.titleLogo} />
          </Box>
        </Box>
        { children }
      </Box>
    </React.Fragment>
  )
};

const isAuthenticated = ({ auth, fetchAuthByRefreshToken }) => {
  const expiry = moment(parseInt(auth.expiry)*1000).diff(moment(), "minutes");
  if (auth.token && auth.expiry && expiry > 5) {
    return true;
  }
  return false;
};

const LoggedInOnboardingLayoutRoute = ({page: Component, auth, fetchAuthByRefreshToken, addLoaderForItem, ...rest}) => {
  const authenticated = isAuthenticated({ auth });
  const urlParts = rest.location.pathname.split("/");
  useEffect(() => {
    if (!authenticated) {
      addLoaderForItem("token");
      fetchAuthByRefreshToken(`${rest.location.pathname}${rest.location.search}`, "loggedInOnboarding");
    }
  }, [auth, authenticated, fetchAuthByRefreshToken, addLoaderForItem, rest.location.pathname]);
  return <Route {...rest} render={(props) => {
    return isAuthenticated({ auth, fetchAuthByRefreshToken })
    ? (
      <LoggedInOnboardingLayout>
        <Component {...props} />
      </LoggedInOnboardingLayout>
    )
    : null
  }} />
}

const mapStateToProps = ({ auth}) => {
  return { auth };
}

export default connect(mapStateToProps, { fetchAuthByRefreshToken, addLoaderForItem })(LoggedInOnboardingLayoutRoute);
