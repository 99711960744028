const state = {
  auth: {
    token: null,
    expiry: null,
    passwordResetRequestSent: null,
  },
  config: {
    loading: false,
    loadingPhotos: false,
    waitingForToken: 0,
    languages: [
      {
        index: 0,
        code: "de"
      },
      {
        index: 1,
        code: "en"
      },
      {
        index: 2,
        code: "fr"
      },
      {
        index: 3,
        code: "it"
      },
      {
        index: 4,
        code: "pl"
      },
      {
        index: 4,
        code: "es"
      },
    ].sort((i,j) => i.index - j.index),
    emailLanguages: [
      {
        index: 0,
        code: "de"
      },
      {
        index: 1,
        code: "en"
      },
      {
        index: 2,
        code: "fr"
      },
      {
        index: 3,
        code: "it"
      },
      {
        index: 4,
        code: "pl"
      },
      {
        index: 5,
        code: "es"
      },
      {
        index: 6,
        code: "pt"
      },
      {
        index: 7,
        code: "nl"
      },
      {
        index: 8,
        code: "sv"
      },
      {
        index: 9,
        code: "cs"
      },
    ].sort((i,j) => i.index - j.index),
    activeLanguage: "de",
    appVersion: "1",
  },
  user: {
    id: null,
    fullName: null,
    email: null,
    verified: false,
    userType: null,
    photo: null,
    completedProfile: false,
    updatingProfilePic: false,
    hostDetails: {},
    guestDetails: {},
    address: {},
    registeringUser: false,
    loggingInUser: false,
    loggingOutUser: false,
    updatingUser: false,
    updatingUserPassword: false,
    locale: null,
    isNewProfile: false,
    subHosts: {
      sub_hosts: []
    },
    fetchingSubHosts: false,
    status: null,
    parkingSpots: [],
  },
  parkingSpot: {
    continuing: false,
    finishing: false,
    showMap: true,
    focusedSpot: null,
    isSearching: false,
    searchString: null,
    carouselState: {},
    checkingOut: false,
    checkingIn: false,
    checkInDetailsDialogOpen: false,
    creatingParkingSpot: false,
    updatingParkingSpot: false,
    currentParkingSpot: null,
    parkingSpotAttributes: [],
    parkingSpotAttributesMap: null,
    fetchingParkingSpotAttributes: true,
    categoryOptionFilters: [],
    locationFilters: null,
    priceRangeFilter: null,
    price: null,
    priceFilters: null,
    externalSearchLocation: null,
    filteredParkingSpots: {
      parking_spots: [],
      pagy: null,
      timestamp: null
    },
    parkedCars: {
      cars: [],
      capacity: 0,
      booked: 0,
    },
    updatingGalleryImage: 0,
    parkingSpots: [],
    currentCheckIns: [],
    pastCheckIns: [],
    carsForGuests: [],
    isCheckedIn: {
      parking_spot_id: null,
      checkIn: false,
    },
    currentBookingId: null,
    checkOutDialogOpen: false,
    checkInDialogOpen: false,
    reviewDialogOpen: false,
    mobileBottomPanel: false,
    enableReview: false,
    reviewSubmissionDialogOpen: false,
    bookingReviewSubmissionDialogOpen: {},
    parkingSpotDetails: null,
    profilePicturePlaceholder: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
    tooltipCardMobile: null,
    editParkingSpot: {
      geolocation: null,
      address: [],
    },
    reviews: {
      id: null,
      response: {
        reviews: [],
        pagy: null,
      },
    },
    photos: {
      id: null,
      response: null,
    },
    moreReviews: {
      reviews: [],
      pagy: null,
    },
    parkingSpotDetailsSearch: {
      cardData: {},
      pinData: []
    },
    tempSearchResults: {
      cardData: {},
      pinData: []
    },
    searchResultsLoader: 0,
    searchTimestamp: 0,
    reviewForBooking: null,
    userLocation: null,
    searchMapBounds: {
      "top_left": [],
      "bottom_right": [],
      "center": []
    },
    fetchingBookingsReceipt: {id: null, status: false, csv: false},
    centerMarkerCoords: null,
    searchMapCenterAndZoom: {
      center: null,
      zoom: null
    }
  },
  payment: {
    paymentDetails: null,
    resetHostPaymentDetails: {
      isLoading: false,
      isSuccess: false,
      isError: false,
      error: null,
      data: null,
    },
    paymentData: {
      current_balance: {
        parking_spots: [],
        total_balance: 0,
      },
      payout_history: [],
      stripeStatus: null,
    },
    guestPaymentDetails: {},
    updatingIdBackPhoto: false,
    updatingIdFrontPhoto: false,
    updatingAdditionalBackPhoto: false,
    updatingAdditionalFrontPhoto: false,
    updatingHostBankDetails: false,
    withdrawing: false,
    files: {},
    bookingsHost: {
      payload: null,
    },
    fetchingFinancialsReceipt: {id: null, status: false},
    cardPaymentFailed: false,
    repaymentFailedDialogOpen: false,
    stripeStatus: null,
    guests: {
      payments: {
        isLoading: true,
        data: null,
        isSuccess: false,
        isError: false,
      },
      withdrawals: {
        fetch: {
          isLoading: true,
          data: null,
          isSuccess: false,
          isError: false,
        },
        post: {
          isLoading: false,
          data: null,
          isSuccess: false,
          isError: false,
        }
      },
    }
  },
  cars: {
    cars: [],
  },
  referral: {
    myReferrals: {
      isLoading: true,
      data: null,
      isSuccess: false,
      isError: false,
    },
    createReferral: {
      isLoading: false,
      isSuccess: false,
      isError: false,
      data: null,
    },
  },
  voucher: {
    applyingVoucher: false,
    appliedVoucher: null,
  },
  global: {
    notifications: [],
    lastPagyUrl: null,
  }
};

export default state;
