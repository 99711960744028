import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { setLastPagyUrl } from '../../../data/actions/global';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  pageNumberCurrent: {
    color: "white",
    background: theme.palette.secondary.dark,
    border:  `0.125rem solid ${theme.palette.secondary.dark}`,
    height: "1.75rem",
    width: "1.75rem",
    borderRadius:"1.75rem",
    // padding: "0.5px 0 0 0.25px !important",
    [theme.breakpoints.down("sm")]: {
      height: "2.25rem",
      width: "2.25rem",
      borderRadius:"2.25rem",
      padding: "2.25px 0 0 0.25px !important",
    }
  },
  pageNumber: {
    color: theme.palette.secondary.main,
    border:  `0.125rem solid ${theme.palette.secondary.main}`,
    height: "1.75rem",
    width: "1.75rem",
    borderRadius:"1.75rem",
    cursor: "pointer",
    // padding: "0.5px 0 0 0.25px !important",
    [theme.breakpoints.down("sm")]: {
      height: "2.25rem",
      width: "2.25rem",
      borderRadius:"2.25rem",
      padding: "2.25px 0 0 0.25px !important",
    }
  },
  pageNumberText: {
    textAlign: "center",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0.125rem",
      fontSize: "1.125rem",
    }
  },
  pageNumberTextCurrent: {
    textAlign: "center",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0.125rem",
      fontSize: "1.125rem",
    }
  }
}));

const Pagination = ({ pagy, token, epic, query, setLastPagyUrl, lastPagyUrl, setLoader, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleRequest = (url) => {
    setLoader && setLoader(true);
    query ? epic(token, url, query) : epic(token, url);
    setLastPagyUrl(url);
  }

  useEffect(() => {
    return () => {
      setLastPagyUrl(null);
    }
  }, [])

  return (
    <Box className={classes.container} display="flex" justifyContent={isMobile ? "center" : "flex-end"} flexDirection={isMobile ? "column-reverse" : "row"}>
      <Box mr={isMobile ? 0 : 2} mt={isMobile ? 0.875 : 0.25}>
        <Typography variant="h6">
          {pagy.from}-{pagy.to} of {pagy.count}
        </Typography>
      </Box>
      <Box display="flex" style={{resize: "none"}}>
        <Box onClick={() => handleRequest(pagy.first_url)} display={(pagy.first_url.split("=")[1] === pagy.prev_url.split("=")[1] || pagy.first_url.split("=")[1] === pagy.page_url.split("=")[1]) ? "none" : "initial"} className={classes.pageNumber} mx={0.5}>
          <Typography className={classes.pageNumberText}>
            {pagy.first_url.split("=")[1]}
          </Typography>
        </Box>
        <Box display={(pagy.first_url.split("=")[1] === pagy.prev_url.split("=")[1] || pagy.first_url.split("=")[1] === pagy.page_url.split("=")[1]) ? "none" : "initial"} variant="body2" mx={1} mb={isMobile ? 0.625 : 0.875}>
          <Typography>
            . . .
          </Typography>
        </Box>
        <Box onClick={() => handleRequest(pagy.prev_url)} display={(pagy.prev_url.split("=")[1].length > 0) ? "initial" : "none"} className={classes.pageNumber} mx={0.5}>
          <Typography className={classes.pageNumberText}>
            {pagy.prev_url.split("=")[1]}
          </Typography>
        </Box>
        <Box className={classes.pageNumberCurrent} mx={0.5}>
          <Typography className={classes.pageNumberTextCurrent}>
            {pagy.page_url.split("=")[1]}
          </Typography>
        </Box>
        <Box onClick={() => handleRequest(pagy.next_url)} display={(pagy.next_url.split("=")[1].length > 0) ? "initial" : "none"} className={classes.pageNumber} mx={0.5}>
          <Typography className={classes.pageNumberText}>
            {pagy.next_url.split("=")[1]}
          </Typography>
        </Box>
        <Box display={(pagy.last_url.split("=")[1] === pagy.next_url.split("=")[1] || pagy.last_url.split("=")[1] === pagy.page_url.split("=")[1]) ? "none" : "initial"} variant="body2" mx={1} mb={isMobile ? 0.625 : 0.875}>
          <Typography>
            . . .
          </Typography>
        </Box>
        <Box onClick={() => handleRequest(pagy.last_url)} display={(pagy.last_url.split("=")[1] === pagy.next_url.split("=")[1] || pagy.last_url.split("=")[1] === pagy.page_url.split("=")[1]) ? "none" : "initial"} className={classes.pageNumber} mx={0.5}>
          <Typography className={classes.pageNumberText}>
            {pagy.last_url.split("=")[1]}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    lastPagyUrl: state.global.lastPagyUrl,
  };
};

export default connect(mapStateToProps, { setLastPagyUrl })(Pagination);
