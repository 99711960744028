import React from "react";
import { Switch, Route } from "react-router-dom";

/** Layouts **/
import OnboardingLayoutRoute from "./layouts/onboarding";
import DashboardLayoutRoute from "./layouts/dashboard";
import LoggedInOnboardingLayoutRoute from "./layouts/loggedInOnboarding";
import PublicLayoutRoute from "./layouts/public";

/** Components **/
import Dashboard from "./components/dashboard/main";
import Profile from "./components/dashboard/profile";
import Login from "./components/onboarding/login";
import SignUp from "./components/onboarding/signup";
import ForgotPassword from "./components/onboarding/forgotPassword";
import CompleteProfile from "./components/onboarding/completeProfile";
import CompletePaymentDetails from "./components/onboarding/completePaymentDetails";
import ResetPassword from "./components/onboarding/resetPassword";
import Security from "./components/dashboard/security";
import CheckIns from "./components/dashboard/checkIns";
import Financials from "./components/dashboard/financials";
import Bookings from "./components/dashboard/bookings";
import MyCars from "./components/dashboard/myCars";
import ParkingSpots from "./components/dashboard/parkingSpots";
import ParkingSpotDetails from "./components/dashboard/parkingSpotDetails";
import CreateParkingSpot from "./components/dashboard/parkingSpots/form";
import ParkedCars from "./components/dashboard/parkingSpots/cars";
import Payment from "./components/dashboard/payment";
import PaymentDetails from "./components/dashboard/paymentDetails";
import Search from "./components/dashboard/search";
import PrivacyPolicy from "./components/dashboard/privacyPolicy";
import { NewReferral, Referrals } from "components/dashboard/referral";
import ParkingControllers from "components/dashboard/parkingControllers";

const parkingSpotRoutes = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.url} component={ParkingSpots} />
      <Route path={match.url + "/:id/edit"} component={CreateParkingSpot} />
      <Route path={match.url + "/:id/cars"} component={ParkedCars} />
    </Switch>
  );
};

const routes = (
  <Switch>
    <PublicLayoutRoute exact path="/" page={Search} />
    <PublicLayoutRoute path="/search" page={Search} />
    <OnboardingLayoutRoute path="/signup-camper" page={SignUp} />
    <OnboardingLayoutRoute path="/signup-host" page={SignUp} />
    <OnboardingLayoutRoute path="/signup-host-v2" page={SignUp} mode="v2" />
    <PublicLayoutRoute path="/cookies" page={PrivacyPolicy} />
    <DashboardLayoutRoute path="/profile" page={Profile} />
    <DashboardLayoutRoute path="/security" page={Security} />
    <DashboardLayoutRoute path="/parking-controllers" page={ParkingControllers} />
    <DashboardLayoutRoute path="/check-ins" page={CheckIns} />
    <DashboardLayoutRoute path="/financials" page={Financials} />
    <DashboardLayoutRoute path="/bookings" page={Bookings} />
    <DashboardLayoutRoute path="/my-cars" page={MyCars} />
    <DashboardLayoutRoute exact path="/ps" page={ParkingSpots} />
    <DashboardLayoutRoute path="/ps/new" page={CreateParkingSpot} />
    <PublicLayoutRoute exact path="/ps/:tag" page={ParkingSpotDetails} />
    <DashboardLayoutRoute path="/ps" page={parkingSpotRoutes} />
    <DashboardLayoutRoute path="/payment" page={Payment} />
    <DashboardLayoutRoute path="/payment-details" page={PaymentDetails} />
    <OnboardingLayoutRoute path="/login" page={Login} />
    <OnboardingLayoutRoute path="/forgotPassword" page={ForgotPassword} />
    <OnboardingLayoutRoute path="/user" page={ResetPassword} />
    <LoggedInOnboardingLayoutRoute
      path="/completeProfile"
      page={CompleteProfile}
    />
    <LoggedInOnboardingLayoutRoute
      path="/completePaymentDetails"
      page={CompletePaymentDetails}
    />
    <DashboardLayoutRoute path="/dashboard" page={Dashboard} />
    <DashboardLayoutRoute path="/referrals" exact page={Referrals} />
    <DashboardLayoutRoute path="/referrals/new" exact page={NewReferral} />
    <PublicLayoutRoute path="*" page={Search} />
  </Switch>
);

export default routes;
