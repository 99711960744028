import { SvgIcon } from "@material-ui/core";
import React from "react";

function RightArrowIcon(props) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M10.7814 7.33312L7.67669 4.22845C7.41638 3.96814 7.41638 3.5461 7.67669 3.28579C7.937 3.02548 8.35904 3.02548 8.61935 3.28579L12.6262 7.29268C13.0168 7.6832 13.0168 8.31637 12.6262 8.70689L8.61935 12.7138C8.35904 12.9741 7.937 12.9741 7.67669 12.7138C7.41638 12.4535 7.41638 12.0314 7.67669 11.7711L10.7814 8.66645H3.33335C2.96516 8.66645 2.66669 8.36798 2.66669 7.99979C2.66669 7.6316 2.96516 7.33312 3.33335 7.33312H10.7814Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default RightArrowIcon;
