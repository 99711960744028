let stripePublishableKey = "";

if (process.env.REACT_APP_STAGE === "prod") {
  stripePublishableKey = "pk_live_51IYpvIBozxdizzoRQuJdbMLwkxxJ2NzJsMTr5b2c33OitgktqOFJYLyolL9EVlEt5NIuEnCUCDcSL1tS5kDGsnLt00H9P74MFH";
} else if (process.env.REACT_APP_STAGE === "stage") {
  stripePublishableKey = "pk_test_51IYpvIBozxdizzoRA2JaT0CjtMoYIoItWv1JeePHqJzfqiSMEakfvVOBfQnQhQ6yxklvoZO9QyEbsvx5E1Ii9czz00tuTCpRaT";
} else {
  stripePublishableKey = "pk_test_51IYpvIBozxdizzoRA2JaT0CjtMoYIoItWv1JeePHqJzfqiSMEakfvVOBfQnQhQ6yxklvoZO9QyEbsvx5E1Ii9czz00tuTCpRaT";
  // stripePublishableKey = "pk_test_51IXMXrHmHpwLODZNtsdgo2B1DvEJwgkyn0hcEeuzgrrKES9JAYSRb7g8aqqyNJPSPC54TAg83lDr4HuBKkA3ppAz00xqIeAaF1";
}

export const STRIPE_PUBLISHABLE_KEY = stripePublishableKey;
