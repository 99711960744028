import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Tabs, Tab, Box, CircularProgress, Divider, Select, MenuItem, useMediaQuery, ListItemIcon } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { generateCacheBusterKey } from "../../../lib/utils";
import OutlinedFormField from "../../common/outlinedFormField";
import {
  updateHost,
  updateGuest,
  setUpdatingProfilePic,
  fetchGuestProfile,
  fetchHostProfile,
  setUpdatingUser,
  setIsNewProfile,
} from "../../../data/actions/user";
import { useStyles } from "../common/styles";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import rawCountries from "rawCountries";
import { fetchCarsForGuests } from "../../../data/actions/parkingSpot";
import { enqueueSnackbar } from "../../../data/actions/global";
import AutocompleteLocationInput from "components/common/autocompleteLocationInput";
import flags from "../../common/flags";
import FormField from "components/common/formFieldWithSeparateLabel";
import {ReactComponent as DropdownArrow} from "../../../assets/images/dropdown-arrow.svg";
import theme from "theme";

import countriesList from '../../../countriesList';

const initialValues = (values) => {
  return {
    email: values.email || "",
    carName: values.carName || "",
    numberPlate: values.numberPlate || "",
    cardName: values.cardName || "",
    companyName: values.companyName || "",
    placeId: values.placeId || "",
    correspondenceLanguage: values.correspondenceLanguage || "de",
    completeAddress: values.completeAddress || "",
    searchInput: values.completeAddress || "",
    phone: values.phone || "",
    iban: values.iban || "",
    companyStreet: values.companyStreet || "",
    companyStreetNumber: values.companyStreetNumber || "",
    companyZip: values.companyZip || "",
    companyCity: values.companyCity || "",
    companyCountry: values.companyCountry || "",
  };
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CompleteProfile = ({
  user,
  token,
  updateHost,
  updateGuest,
  updatingProfilePic,
  setUpdatingProfilePic,
  fetchGuestProfile,
  fetchHostProfile,
  setUpdatingUser,
  updatingUser,
  setIsNewProfile,
  isNewProfile,
  fetchCarsForGuests,
  carsForGuests,
  enqueueSnackbar,
  userParkingSpots,
  ...rest
}) => {
  const classes = useStyles();
  const query = useQuery();
  const [cacheBuster, setCacheBuster] = useState(null);
  let isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  useEffect(() => {
    if (!cacheBuster) {
      setCacheBuster(generateCacheBusterKey());
    }
  }, []);

  const { userType, email, phone, hostDetails, address, completeAddress, correspondenceLanguage, locale } = user;

  const getPrimaryCar = () => {
    const [primary] = carsForGuests.filter((c) => c.is_primary === true);
    return primary;
  };

  const defaultValues = initialValues({
    email,
    phone,
    iban: hostDetails && hostDetails.iban,
    companyName: hostDetails && hostDetails.company_name,
    placeId: address && address.place_id,
    completeAddress,
    correspondenceLanguage: locale && locale,
    searchInput: completeAddress,
    companyStreet: address && address.line1,
    companyStreetNumber: address && address.street_number,
    companyZip: address && address.zip,
    companyCity: address && address.city,
    companyCountry: address && address.country,
  });
  const { handleSubmit, control, reset, setValue, register, getValues } = useForm({
    defaultValues,
  });

  const countriesToExclude = rawCountries.filter((country) => country[0] !== 'Israel' &&  country[1].includes('middle-east')).map((country) => country[2])

  useEffect(() => {
    
    if (userType === "Host") {
      register("placeId", {});
      register("completeAddress", {});
      register("searchInput", {});
    }
  }, [register, userType]);

  useEffect(() => {
    if (userType === "Guest") {
      fetchGuestProfile(token);
      if (!isNewProfile) {
        fetchCarsForGuests(token);
      } else {
        setIsNewProfile(false);
      }
    } else if (userType === "Host") {
      fetchHostProfile(token);
    }
  }, [userType, fetchGuestProfile, fetchHostProfile, token]);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [reset, email, phone, hostDetails, address]);

  const { t } = useTranslation();
  const tag = query.get("tag");

  useEffect(() => {
    if (userType === "Guest" && carsForGuests.length > 0) {
      let primary = getPrimaryCar();
      setValue("carName", primary.name);
      setValue("numberPlate", primary.number_plate);
    }
  }, [carsForGuests]);

  const onSubmit = (data) => {
    const tag = query.get("tag");
    const completeAddr = getValues()['completeAddress'];
    const inputAddr = getValues()['searchInput'];
    if (!data.phone || data.phone === "") {
      if (userType === "Host" || userType === "Guest") {
        enqueueSnackbar({
          message: {
            text: t("on-boarding.errors.phone-required"),
            variant: "error",
          },
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        });
      }
    }

    if (userType === "Host") {
      if (
        !inputAddr ||
        ((!data.placeId || data.placeId === "") && completeAddr !== inputAddr)
      ) {
        enqueueSnackbar({
          message: {
            text: t("on-boarding.errors.address-required"),
            variant: "error",
          },
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        });
      }
    }

    if (
      !data.phone ||
      data.phone === "" ||
      (userType === "Host" && (
        !inputAddr ||
        ((!data.placeId || data.placeId === "") && completeAddr !== inputAddr)
      ))
    ) {
      return false;
    }

    if (userType === "Guest") {
      if (tag == null) {
        setUpdatingUser(true);
        updateGuest(
          {
            ...data,
          },
          token,
          null,
          query.get("redirect")
        );
      } else {
        setUpdatingUser(true);
        updateGuest(
          {
            ...data,
          },
          token,
          tag,
          query.get("redirect")
        );
      }
    } else if (userType === "Host") {
      setUpdatingUser(true);
      const psPath = `/ps/${userParkingSpots?.length ? `${userParkingSpots[0]}/edit` : "new"}`
      if (tag == null) {
        updateHost(
          {
            ...data,
            hostDetailId: user.hostDetails.id,
          },
          token,
          null,
          `${query.get("redirect") || psPath}?product_tour_id=339965`
        );
      } else {
        updateHost(
          {
            ...data,
            hostDetailId: user.hostDetails.id,
          },
          token,
          tag,
          `${query.get("redirect") || psPath}?product_tour_id=339965`
        );
      }
    }
  };

  const [activeTab, setActiveTab] = useState("Credentials");

  return (
    <Card className={classes.onboardingCard}>
      {userType === "Host" && !!query.get("id") && cacheBuster && (
        <Box key={cacheBuster} display="none">
          <img
            src={`https://www.google-analytics.com/collect?v=1&tid=UA-198820811-1&cid=555&aip=1&t=event&ec=3_hostinfos&ea=3_hostinfos&el=${query.get(
              "id"
            )}&;dp=&;dt=&;z=${cacheBuster}`}
          />
        </Box>
      )}
      {userType === "Guest" && (
        <Box
          display="flex"
          alignItems="center"
          className={classes.completeProfileTitle}
        >
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            textColor="primary"
            indicatorColor="primary"
            className={classes.completeProfileTabs}
          >
            <Tab value="Credentials" label={t("on-boarding.credentials")} />
            <Tab
              value="Payment"
              label={t("on-boarding.payment")}
              disabled
              component={Link}
              to="/completePaymentDetails"
            />
          </Tabs>
        </Box>
      )}
      <CardContent className={classes.completeProfileContent}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.root}
          autoComplete="off"
        >
          {userType === "Host" && (
            <>
              <Typography pb={2} component={Box} variant="h2">
                {t("on-boarding.complete-profile.host.title")}
              </Typography>
              <Typography pb={2} component={Box} variant="body1">
                {t("on-boarding.complete-profile.host.subtitle")}
              </Typography>
              <Divider />
            </>
          )}

          {
            userType === "Host" && (
              <React.Fragment>
                <Box display="flex" flexDirection="column" pt={3}>
                  <Typography variant="h4">
                    {t("profile.company-info")} *
                  </Typography>
                  <Box mt={0.75}>
                    <Typography variant="caption" className={classes.caption2}>
                      {t("profile.company-info-subtext")}
                    </Typography>
                  </Box>
                  <Box mt="12px">
                    <AutocompleteLocationInput
                      fixtures={[]}
                      placeholder={t("on-boarding.host.address-input-placeholder")}
                      onInputChange={(input) => {
                        setValue("searchInput", input);
                      }}
                      onSuggestSelect={(suggestion) => {
                        setValue("companyZip", suggestion?.gmaps?.address_components.find((component) => component.types.includes("postal_code"))?.long_name);

                        setValue("companyStreet", suggestion?.gmaps?.address_components.find((component) => component.types.includes("route"))?.long_name);

                        setValue("companyStreetNumber", suggestion?.gmaps?.address_components.find((component) => component.types.includes("street_number"))?.long_name);


                        const parts = suggestion?.gmaps?.address_components
                        const sublocalityLevel4 = parts.find((part) => part.types.includes("sublocality_level_4"));
                        const sublocalityLevel3 = parts.find((part) => part.types.includes("sublocality_level_3"));
                        const sublocalityLevel2 = parts.find((part) => part.types.includes("sublocality_level_2"));
                        const sublocalityLevel1 = parts.find((part) => part.types.includes("sublocality_level_1"));
                        const sublocality = parts.find((part) => part.types.includes("sublocality"));
                        const locality = parts.find((part) => part.types.includes("locality"));

                        const city = locality?.long_name
                        || sublocality?.long_name
                        || sublocalityLevel1?.long_name
                        || sublocalityLevel2?.long_name
                        || sublocalityLevel3?.long_name
                        || sublocalityLevel4?.long_name

                        setValue("companyCity", city);

                        const country = countriesList.filter((item) => suggestion?.gmaps?.address_components.find((component) => component.types.includes("country"))?.short_name.toLowerCase() === item.alpha2 )

                        setValue("companyCountry", country[0]?.en);

                        setValue("placeId", suggestion?.gmaps?.place_id);
                        setValue("completeAddress", suggestion?.gmaps?.formatted_address);

                      }}
                      initialValue={completeAddress}
                    />
                  </Box>
                </Box>
                <Box className={classes.fieldRow}>
                  <Box flex={2}>
                    <FormField label={ t("address.street") } name="companyStreet" control={control} required />
                  </Box>
                  <Box flex={2} ml={ isMobile ? 0 : 2}>
                    <FormField label={ t("address.street-number") } name="companyStreetNumber" control={control} required />
                  </Box>
                  <Box flex={3/2} ml={ isMobile ? 0 : 2}>
                    <FormField label={ t("address.zip") } name="companyZip" control={control} required />
                  </Box>
                </Box>
                <Box className={classes.fieldRow}>
                  <Box flex={1}>
                    <FormField label={ t("address.city") } name="companyCity" control={control} required />
                  </Box>
                  <Box flex={1} marginTop="1.6rem" ml={ isMobile ? 0 : 2}>
                    <Typography variant="h5" style={{fontWeight: "500"}}>{ t('address.country') } </Typography>
                    <Controller
                      as={
                        <Select
                          className={classes.countrySelect}
                          IconComponent={(props) => (<DropdownArrow {...props}/>)}
                          variant= "outlined"
                          displayEmpty
                          required
                        >
                          <MenuItem value="Switzerland">{t("countries.switzerland")}</MenuItem>
                          <MenuItem value="Germany">{t("countries.germany")}</MenuItem>
                          <MenuItem value="Austria">{t("countries.austria")}</MenuItem>
                          <MenuItem value="Australia">{t("countries.australia")}</MenuItem>
                          <MenuItem value="France">{t("countries.france")}</MenuItem>
                          <MenuItem value="Italy">{t("countries.italy")}</MenuItem>
                          <MenuItem value="Liechtenstein">{t("countries.liechtenstein")}</MenuItem>
                          <MenuItem value="Belgium">{t("countries.belgium")}</MenuItem>
                          <MenuItem value="Malta">{t("countries.malta")}</MenuItem>
                          <MenuItem value="Bulgaria">{t("countries.bulgaria")}</MenuItem>
                          <MenuItem value="Netherlands">{t("countries.netherlands")}</MenuItem>
                          <MenuItem value="Denmark">{t("countries.denmark")}</MenuItem>
                          <MenuItem value="Poland">{t("countries.poland")}</MenuItem>
                          <MenuItem value="Estonia">{t("countries.estonia")}</MenuItem>
                          <MenuItem value="Portugal">{t("countries.portugal")}</MenuItem>
                          <MenuItem value="Finland">{t("countries.finland")}</MenuItem>
                          <MenuItem value="Romania">{t("countries.romania")}</MenuItem>
                          <MenuItem value="Sweden">{t("countries.sweden")}</MenuItem>
                          <MenuItem value="Greece">{t("countries.greece")}</MenuItem>
                          <MenuItem value="Slovakia">{t("countries.slovakia")}</MenuItem>
                          <MenuItem value="Ireland">{t("countries.ireland")}</MenuItem>
                          <MenuItem value="Slovenia">{t("countries.slovenia")}</MenuItem>
                          <MenuItem value="Spain">{t("countries.spain",)}</MenuItem>
                          <MenuItem value="Czech Republic">{t("countries.czechrepublic")}</MenuItem>
                          <MenuItem value="Latvia">{t("countries.latvia")}</MenuItem>
                          <MenuItem value="Hungary">{t("countries.hungary")}</MenuItem>
                          <MenuItem value="Lithuania">{t("countries.lithuania")}</MenuItem>
                          <MenuItem value="Cyprus">{t("countries.cyprus")}</MenuItem>
                          <MenuItem value="Luxembourg">{t("countries.luxembourg")}</MenuItem>
                          <MenuItem value="United Kingdom">{t("countries.unitedkingdom")}</MenuItem>
                          <MenuItem value="Norway">{t("countries.norway")}</MenuItem>
                          <MenuItem value="United States">{t("countries.unitedstates")}</MenuItem>
                        </Select>
                      }
                      name="companyCountry"
                      control={control}
                      defaultValue="Switzerland"
                      disabled={!!(userType==="Host" && user.verified)}
                    />
                  </Box>
                </Box>
              
              </React.Fragment>
            )
          }
          {user.userType === 'Host' && 
            <Box display="flex" flexDirection="column" pt={2}>
              <Typography variant="h4">{ t('profile.language') }</Typography>
              <Box mt={0.75}>
                <Typography variant="caption" className={classes.caption2}>
                  {t("profile.language-subtext")}
                </Typography>
              </Box>
              <Box mt={1.5}>
                <Controller
                  as={
                    <Select
                      className={classes.languageSelect}
                      IconComponent={(props) => (<DropdownArrow {...props}/>)}
                      variant= "outlined"
                      required
                    >
                      <MenuItem value="de">
                        <Box display="flex" alignItems="center">
                          <ListItemIcon className={classes.flagIcon}>
                            {flags("de")}
                          </ListItemIcon>
                          <span>DE</span>
                        </Box>
                      </MenuItem>
                      <MenuItem value="en">
                        <Box display="flex" alignItems="center">
                          <ListItemIcon className={classes.flagIcon}>
                            {flags("en")}
                          </ListItemIcon>
                          <span>EN</span>
                        </Box>
                      </MenuItem>
                      <MenuItem value="fr">
                        <Box display="flex" alignItems="center">
                          <ListItemIcon className={classes.flagIcon}>
                            {flags("fr")}
                          </ListItemIcon>
                          <span>FR</span>
                        </Box>
                      </MenuItem>
                      <MenuItem value="es">
                        <Box display="flex" alignItems="center">
                          <ListItemIcon className={classes.flagIcon}>
                            {flags("es")}
                          </ListItemIcon>
                          <span>ES</span>
                        </Box>
                      </MenuItem>
                      <MenuItem value="it">
                        <Box display="flex" alignItems="center">
                          <ListItemIcon className={classes.flagIcon}>
                            {flags("it")}
                          </ListItemIcon>
                          <span>IT</span>
                        </Box>
                      </MenuItem>
                    </Select>
                  }
                  name="correspondenceLanguage"
                  control={control}
                />
              </Box>
            </Box>
          }
          <Box display="flex" flexDirection="column" pt={2}>
            <Typography variant="h4">{t("profile.phone-number")} *</Typography>
            <Box mt={1.5}>
              <Controller
                as={
                  <PhoneInput
                    country={"ch"}
                    preferredCountries={[
                      "ch",
                      "de",
                      "fr",
                      "it",
                      "li",
                      "at",
                      "es",
                      "pt",
                      "be",
                      "bg",
                      "mt",
                      "nl",
                      "dk",
                      "pl",
                      "ee",
                      "fi",
                      "ro",
                      "se",
                      "gr",
                      "sk",
                      "cz",
                      "lv",
                      "hu",
                      "lt",
                      "cy",
                      "lu",
                      "no",
                      "gb",
                      "us",
                      "ie",
                      "si",
                      "au",
                    ]}
                    regions={["europe", "middle-east", "oceania", "america"]}
                    excludeCountries={countriesToExclude} 
                    containerClass={classes.phoneContainer}
                    inputClass={classes.phoneInput}
                    buttonClass={classes.phoneButton}
                    dropdownClass={classes.phoneDropdownOnboarding}
                  />
                }
                name="phone"
                control={control}
              />
            </Box>
          </Box>
          {userType === "Guest" && (
            <React.Fragment>
              <Box display="flex" flexDirection="column" pt={2.5}>
                <Typography variant="h4">{t("car.info")}</Typography>
                <Box className={classes.fieldRow}>
                  <OutlinedFormField
                    type="text"
                    compact={true}
                    flex={1}
                    label={t("car.name")}
                    name="carName"
                    control={control}
                    required
                    style = {{
                      marginTop: isMobile? "0.5rem" : "",
                    }}
                  />
                  <OutlinedFormField
                    type="text"
                    compact={true}
                    flex={1}
                    label={t("car.plate").length <= 12 ? t("car.plate") : t("car.plate").substring(0, 12) + '...'}
                    name="numberPlate"
                    control={control}
                    required
                    style = {{
                      marginTop: isMobile? "0.5rem" : "",
                    }}
                  />
                </Box>
              </Box>
            </React.Fragment>
          )}

          <Box pt={3} display="flex" className={classes.actionBox}>
            <Box position="relative" ml={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.primaryButton}
                disabled={updatingUser}
              >
                {t("common.btn.continue")}
              </Button>
              {updatingUser && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Box>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.token,
    updatingProfilePic: state.user.updatingProfilePic,
    updatingUser: state.user.updatingUser,
    isNewProfile: state.user.isNewProfile,
    carsForGuests: state.parkingSpot.carsForGuests,
    userParkingSpots: state.user.parkingSpots
  };
};

export default connect(mapStateToProps, {
  updateHost,
  updateGuest,
  setUpdatingProfilePic,
  fetchGuestProfile,
  fetchHostProfile,
  setUpdatingUser,
  setIsNewProfile,
  fetchCarsForGuests,
  enqueueSnackbar,
})(CompleteProfile);
