import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  lineAround: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    overflow: "hidden",
    "&::before, &::after": {
      content: '""',
      flex: "1 1",
      minWidth: "1.5rem",
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: "auto",
    },
    "&::before": {
      marginRight: "0.5em",
    },
    "&::after": {
      marginLeft: "0.5em",
    },
  },
}));

function TextDivider({ className, ...props }) {
  const classes = useStyles();

  return (
    <Typography {...props} className={clsx(classes.lineAround, className)} />
  );
}

export default TextDivider;
