import { Box, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  bars: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  bar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0.5rem",
  },
  barZero: {
  },
}));

function FrequencyBarChart({ data, range }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const parsedData = Object.values(data);
  const transformedData = [...parsedData].concat(Array(21 - parsedData.length).fill(0))
  const maxFrequency = Math.max(...transformedData);
  let marginRight = 0;

  if (transformedData.length > 10) {
    marginRight = isMobile ? 0.5 : 1;
  } else if (transformedData.length > 5) {
    marginRight = isMobile ? 1.2 : 2.4;
  } else {
    marginRight = isMobile ? 6 : 6;
  }

  const bars = transformedData.map((count, index) => {
    return (
      <Box
        flex={1}
        width="100%"
        marginRight={index === data.length - 1 ? 0 : marginRight}
        height={
          count > 0 ? `${((count / maxFrequency) * 100).toFixed(0)}%` : "2%"
        }
        key={index}
        className={clsx(classes.bar, {
          [classes.barZero]: !count,
        })}
      />
    );
  });

  return (
    <Box height={55} overflow="hidden" className={classes.bars}>
      {bars}
    </Box>
  );
}

export default FrequencyBarChart;
