import React, { useEffect } from 'react';
import { Divider, Container, Typography, Box, Button, useTheme, Card, CardMedia, CardActionArea, CardContent, Link, CircularProgress } from '@material-ui/core';
import { useStyles } from '../common/styles';
import TitleSection from '../../common/titleSection';
import { connect } from 'react-redux';
import {
  fetchStripeStatus,
  postPaymentConnect,
  patchPaymentDetails,
  resetHostPaymentDetails,
  resetHostPaymentDetailsInitialData,
} from '../../../data/actions/payment';
import { addLoader, addLoaderForItem } from '../../../data/actions/config';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from '../../../data/actions/global';
import { useLocation } from 'react-router-dom';

import individualBg from "../../../assets/images/individual-account-host.png";
import businessBg from "../../../assets/images/business-account.png";
import nonProfitBg from "../../../assets/images/non-profit-account.png";
import { ReactComponent as RightArrow } from '../../../assets/images/SVG/right-arrow.svg';
import TextDivider from 'components/common/textDivider/TextDivider';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const titleForType = (t, accountType) => {
  switch (accountType) {
    case 'individual':
      return t("payment.personal-account");
    case 'company':
      return t("payment.business-account");
    case 'non_profit':
      return t("payment.non-profit-account");
    default:
      break;
  }
}

const PaymentDetails = ({
  token,
  addLoaderForItem,
  paymentDetails,
  postPaymentConnect,
  patchPaymentDetails,
  resetHostPaymentDetailsMutationResult,
  resetHostPaymentDetails,
  resetHostPaymentDetailsInitialData
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const query = useQuery();
  const refresh = query.get('refresh');

  useEffect(() => {
    addLoaderForItem("stripe-status");
    patchPaymentDetails(token);
  }, [token]);

  useEffect(() => {
    if (refresh === "true") {
      addLoaderForItem("stripe-status");
      postPaymentConnect(token);
    }
  }, [refresh, token]);

  useEffect(() => {
    if (resetHostPaymentDetailsMutationResult.isSuccess) {
      resetHostPaymentDetailsInitialData();
    }
  }, [resetHostPaymentDetailsInitialData, resetHostPaymentDetailsMutationResult])

  const { t } = useTranslation();

  const handleConnectFor = (businessType) => {
    addLoaderForItem("stripe-status");
    postPaymentConnect(token, businessType);
  };

  const complete = !!paymentDetails?.details_submitted;
  const oldAccount = paymentDetails?.connected_account_type === "custom";
  const rejected = complete && !paymentDetails?.account_details?.charges_enabled && !!paymentDetails?.account_details?.disabled_reason;
  const isAccountResetAllowed = !!paymentDetails?.allow_reset;

  return (
    <Container maxWidth="md">
      <TitleSection
        routes={[{
          name: t("common.texts.dashboard"),
          path: "/dashboard",
        }]}
        title={ t("payment.details") }
      />
      <Divider />
      { !!paymentDetails?.business_type ? (
        <Box mt={3}>
          <Box display="flex" alignItems="center">
            <Typography variant="h3" className={classes.paymentAttrText}>
              {t("payment.status")}:
            </Typography>
            <Box className={classes.statusChip}>
              {rejected ? t("payment.rejected") : (complete ? t("payment.completed") : t("payment.incomplete"))}
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="h3" className={classes.paymentAttrText}>
            {t("payment.account-type")}:
            </Typography>
            <Typography variant="body1">
              {titleForType(t, paymentDetails?.business_type)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="h3" className={classes.paymentAttrText}>
            {t("payment.fields.iban")}:
            </Typography>
            <Typography variant="body1">
              **** {paymentDetails?.iban}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="h3" className={classes.paymentAttrText}>
              {t("payment.currency")}:
            </Typography>
            <Typography variant="body1" style={{textTransform: "uppercase"}}>
              {paymentDetails?.currency}
            </Typography>
          </Box>
          <Box className={classes.paymentDetailsActionsContainer} mt={2}>
            <Button variant={oldAccount ? "outlined" : "contained"} color={oldAccount ? "" : "primary"} onClick={() => handleConnectFor()}>{
              oldAccount ? t("payment.switch-details") : complete ? t("payment.edit-details") : t("payment.complete-details")
            }</Button>
            { isAccountResetAllowed && (
              <>
                <TextDivider variant="body1" color='secondary' className={classes.paymentDetailsActionsDivider}>
                  {t("payment.reset-account.divider-text")}
                </TextDivider>
                <Button
                  endIcon={resetHostPaymentDetailsMutationResult.isLoading ? <CircularProgress size={20} /> : undefined}
                  onClick={() => {
                    resetHostPaymentDetails(token);
                  }}
                  variant="outlined"
                  color="default">
                  {t("payment.reset-account.button-text")}
                </Button>
              </>
            ) }
          </Box>
        </Box>
      ) : (
        <Box display="flex" mx={-2} my={2} flexWrap="wrap">
          <Box className={classes.accountsCard}>
            <Card elevation={0} className={classes.accountsCardChildCard}>
              <CardMedia
                component="img"
                image={individualBg}
                alt="individual account"
              />
              <CardContent className={classes.accountsCardContent}>
                <Typography variant="h2" component="div">
                  {titleForType(t, 'individual')}
                </Typography>
                <Typography variant="body1" className={classes.cardBodyText}>
                  {t("payment.individual-subtext")}
                </Typography>
                <Link underline="none" variant="body1" className={classes.cardBodyLink} onClick={() => handleConnectFor('individual')}>
                  {t("payment.add-details")} &nbsp;<RightArrow />
                </Link>
              </CardContent>
            </Card>
          </Box>
          <Box className={classes.accountsCard}>
            <Card elevation={0} className={classes.accountsCardChildCard}>
              <CardMedia
                component="img"
                image={businessBg}
                alt="business account"
              />
              <CardContent className={classes.accountsCardContent}>
                <Typography variant="h2" component="div">
                  {titleForType(t, 'company')}
                </Typography>
                <Typography variant="body1" className={classes.cardBodyText}>
                  {t("payment.business-subtext")}
                </Typography>
                <Link underline="none" variant="body1" className={classes.cardBodyLink} onClick={() => handleConnectFor('company')}>
                  {t("payment.add-details")} &nbsp;<RightArrow />
                </Link>
              </CardContent>
            </Card>
          </Box>
          <Box className={classes.accountsCard}>
            <Card elevation={0} className={classes.accountsCardChildCard}>
              <CardMedia
                component="img"
                image={nonProfitBg}
                alt="non profit account"
              />
              <CardContent className={classes.accountsCardContent}>
                <Typography variant="h2" component="div">
                  {titleForType(t, 'non_profit')}
                </Typography>
                <Typography variant="body1" className={classes.cardBodyText}>
                  {t("payment.non-profit-subtext")}
                </Typography>
                <Link underline="none" variant="body1" className={classes.cardBodyLink} onClick={() => handleConnectFor('non_profit')}>
                  {t("payment.add-details")} &nbsp;<RightArrow />
                </Link>
              </CardContent>
            </Card>
          </Box>
        </Box>
      )
    }
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.token,
    stripeStatus: state.payment.stripeStatus,
    paymentDetails: state.payment.paymentDetails,
    resetHostPaymentDetailsMutationResult: state.payment.resetHostPaymentDetails,
  }
}

export default connect(mapStateToProps, {
  // fetchHostProfile,
  enqueueSnackbar,
  fetchStripeStatus,
  addLoader,
  addLoaderForItem,
  postPaymentConnect,
  patchPaymentDetails,
  resetHostPaymentDetails,
  resetHostPaymentDetailsInitialData,
})(PaymentDetails);
